import useAsyncControl from '@onepercentio/one-ui/dist/hooks/useAsyncControl'
import { OfferType } from 'core/logic/drop/drop.types'
import { useTenant } from 'core/logic/tenant/tenant.hook'
import {
  PaymentProviders,
  PaymentsProvider,
} from 'core/logic/tenant/tenant.types'
import { doPurchase } from 'core/modules/firebase/service'
import { CurrenciesEnum } from 'core/types/CurrenciesEnum'
import { PaymentMethods } from 'pages/Authenticated/Payment/Payment.types'
import { useCallback, useMemo } from 'react'

export type MethodNProvider = [
  method: PaymentMethods,
  provider: PaymentsProvider
]

const parsePaymentMethods = (
  paymentProviders?: PaymentProviders
): MethodNProvider[] => {
  if (!paymentProviders) {
    return []
  }
  return Object.entries(paymentProviders).reduce<MethodNProvider[]>(
    (methods, [provider, props]) => {
      if (props.active) {
        return [
          ...methods,
          ...Object.entries(props.methods || {}).reduce<MethodNProvider[]>(
            (m, [method, activeOrObj]) => [
              ...m,
              ...((
                typeof activeOrObj === 'object'
                  ? activeOrObj.active
                  : activeOrObj
              )
                ? [[method, provider] as MethodNProvider]
                : []),
            ],
            []
          ),
        ]
      }
      return methods
    },
    []
  )
}

export function usePaymentMethods() {
  const { tenant } = useTenant()
  const paymentMethods = useMemo(
    () => parsePaymentMethods(tenant?.paymentProviders),
    [tenant?.paymentProviders]
  ).sort()
  return paymentMethods
}

export function useDoPayment() {
  const doPayment = useCallback(async function doPayment(
    offer: OfferType,
    method: PaymentMethods,
    provider: PaymentsProvider,
    currency: CurrenciesEnum,
    user: {
      email: string
      name: string
      document: string
      isForeign: boolean
    }
  ) {
    const sanitizedCpf = user.document
      ? user.document.replace(/[^0-9]/g, '')
      : ''
    return await doPurchase({
      offerId: offer.id,
      tenantId: process.env.REACT_APP_TENANT_IDENTITY_ID,
      amount: 1,
      paymentType: method as 'PIX',
      provider,
      currency: currency,
      buyer: {
        name: user.name,
        isForeigner: user.isForeign,
        document: sanitizedCpf,
        email: user.email,
      },
    })
  }, [])

  const control = useAsyncControl({
    doPayment,
  })

  return control
}
