import Control from 'components/RarumAdaptiveSidebar/Control'
import RarumAdaptiveSidebar from 'components/RarumAdaptiveSidebar/RarumAdaptiveSidebar'
import Styles from './OfferPaymentBanner.module.scss'
import RarumHeader from 'components/RarumHeader'
import SafetyLabel from 'pages/Authenticated/Payment/SafetyLabel'
import { PaymentsProvider } from 'core/logic/tenant/tenant.types'
import Spacing from '@onepercentio/one-ui/dist/components/Spacing'
import DestinatedOfferInfo from 'components/DestinatedOfferInfo'
import { AssetType } from 'core/logic/asset/asset.types'

/**
 * The information about the offer payment
 **/
export default function OfferPaymentBanner({
  paymentProvider,
  asset,
}: {
  paymentProvider: PaymentsProvider | undefined
  asset: AssetType | undefined
}) {
  return (
    <div>
      <RarumAdaptiveSidebar
        breakInto={1200}
        visibilityControlComponent={Control}
        className={Styles.offer}>
        <div>
          <RarumHeader disableRarumText />
          <SafetyLabel typeOrProvider={paymentProvider} />
          <Spacing size='32' />
          <DestinatedOfferInfo
            direction='v'
            assetOrOffer={asset}
            isSale={false}
          />
        </div>
      </RarumAdaptiveSidebar>
    </div>
  )
}
