import { makeStyles } from '@material-ui/core'

const style = makeStyles((theme) => ({
  tag: {
    marginRight: '10px',
    padding: '10px',
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: theme.palette.text.primary,
      color: theme.palette.background.default,
    },
  },
}))

export default style
