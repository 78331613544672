import Button from '@onepercentio/one-ui/dist/components/Button'
import IconRarum, { IconRarumTitle } from 'components/IconRarum'
import React, { ComponentProps, ReactElement } from 'react'
import useStyle from './BackButton.style'

export type BackButtonViewProps = {
  text: string | ReactElement
  icon?: IconRarumTitle
  iconColor?: string
  iconOrientation?: 'LEFT' | 'RIGHT'
  className?: string
  disabled?: boolean
} & Required<Pick<ComponentProps<typeof Button>, 'onClick'>>

const BackButtonView: React.FC<BackButtonViewProps> = ({
  icon = 'arrow',
  iconColor,
  iconOrientation = 'LEFT',
  onClick,
  text,
  className,
  ...buttonProps
}) => {
  const classes = useStyle({ icon })

  const Icon = <IconRarum icon={icon} color={iconColor} size={24} />
  return (
    <Button
      variant='transparent'
      className={`${className} ${classes.root}`}
      onClick={onClick}
      {...buttonProps}>
      {iconOrientation === 'LEFT' ? Icon : undefined}
      &nbsp;{text}&nbsp;
      {iconOrientation === 'RIGHT' ? Icon : undefined}
    </Button>
  )
}

export default BackButtonView
