import { Tooltip, useTheme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { memo } from 'react'
import { i18n } from 'translate/i18n'
import IconRarum from '../IconRarum'
import useStyles from './ApprovalStatus.style'

interface ApprovalStatusProps {
  approved: boolean
}
export const ApprovalStatus: React.FC<ApprovalStatusProps> = memo(
  ({ approved }) => {
    const theme = useTheme()
    const classes = useStyles()

    return (
      <Tooltip
        title={
          approved
            ? `${i18n.t('components.approvalStatus.approvedTooltip')}`
            : `${i18n.t('components.approvalStatus.notApprovedTooltip')}`
        }
        placement='top'>
        <Box className={classes.box}>
          <IconRarum
            icon={approved ? 'checkmark' : 'alert'}
            size={10}
            color={
              approved ? theme.palette.success.light : theme.palette.grey[300]
            }
            className={classes.icon}
          />
          <Typography
            variant='caption'
            component='p'
            style={{
              color: theme.palette.grey[300],
            }}>
            {approved
              ? `${i18n.t('components.approvalStatus.approved')}`
              : `${i18n.t('components.approvalStatus.notApproved')}`}
          </Typography>
        </Box>
      </Tooltip>
    )
  }
)
