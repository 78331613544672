import {
  ElementRef,
  Fragment,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { AvatarMenuOptions, TopBarViewProps } from './TopBar.types'
import Styles from './TopBar.module.scss'
import AdaptiveSidebar from '@onepercentio/one-ui/dist/components/AdaptiveSidebar'
import Select from '@onepercentio/one-ui/dist/components/Select'
import { i18n, t, tE, tO } from 'translate/i18n'
import Button from '@onepercentio/one-ui/dist/components/Button'
import Loader from '@onepercentio/one-ui/dist/components/Loader'
import ProfileAvatar from 'components/ProfileAvatar'
import { TopBarTestIDs } from './TopBar.e2e'
import AdaptiveContainer from '@onepercentio/one-ui/dist/components/AdaptiveContainer'
import Collapsable from '@onepercentio/one-ui/dist/components/Collapsable'
import IconRarum from 'components/IconRarum'
import MutableHamburgerButton from '@onepercentio/one-ui/dist/components/MutableHamburgerButton'
import Tabs from '@onepercentio/one-ui/dist/components/Tabs'
import { useOneUIConfig } from '@onepercentio/one-ui/dist/context/OneUIProvider'
import { flagByCountryCode } from 'components/offer/OfferAuctionBidModal/countryByCode'
import useLanguage from 'openspace/hooks/useLanguage'
import UncontrolledTransition from '@onepercentio/one-ui/dist/components/UncontrolledTransition'
import { TransitionAnimationTypes } from '@onepercentio/one-ui/dist/components/Transition/Transition'
import WalletDisplayButton from 'components/WalletDisplayButton'
import { AnchoredTooltipAlignment } from '@onepercentio/one-ui/dist/components/AnchoredTooltip/AnchoredTooltip'
import { useClientConfig } from 'context/ClientContext'
import { ENABLED_MENU_OPTIONS } from './TopBar.data'

const BREAK_INTO = Number(Styles.breakInto)

const avatarMenu: {
  [a in AvatarMenuOptions]: {
    title: TranslationCodesByTenant[keyof TranslationCodesByTenant]
    icon: ReactElement
  }
} = {
  [AvatarMenuOptions.PROFILE]: {
    title: 'components.topBar.myData',
    icon: <IconRarum icon='user' />,
  },
  [AvatarMenuOptions.COLLECTION]: {
    title: 'components.topBar.myCollection',
    icon: <IconRarum icon='menu' />,
  },
  [AvatarMenuOptions.LOGOUT]: {
    title: 'components.topBar.exit',
    icon: <IconRarum icon='arrow' />,
  },
}

let unmounted = false

/**
 * The top bar styled with openspace style
 **/
export default function TopBarView({
  tenant,
  onSignIn,
  onSignUp,
  onClickMenu,
  user,
  currentOption,
  onChangeLanguage,
}: TopBarViewProps) {
  const { Logotype } = useClientConfig()
  const lng = useLanguage()
  const menuOptions = useMemo(() => {
    const values = ENABLED_MENU_OPTIONS
    return values.map((a) => ({
      menuEnumCode: a,
    }))
  }, [])
  const adaptiveSidebarRef = useRef<ElementRef<typeof AdaptiveSidebar>>(null)
  function delayedDismiss() {
    setTimeout(() => {
      adaptiveSidebarRef.current!.dismiss()
    }, 100)
  }
  const availableLanguageOptions = useMemo(() => {
    return (
      tenant?.languages.map((a) => {
        const [language, countryCode] = a.split('-')
        return {
          label: `${flagByCountryCode[countryCode]} ${language.toUpperCase()}`,
          value: a as LanguageCodes,
        }
      }) || []
    )
  }, [tenant?.languages])

  useEffect(
    () => () => {
      unmounted = true
    },
    []
  )

  return (
    <div
      className={`${Styles.parentRoot} ${unmounted ? Styles.remounted : ''}`}>
      <UncontrolledTransition
        className={Styles.root}
        transitionType={TransitionAnimationTypes.COIN_FLIP}>
        <Fragment key={`${lng}`}>
          <div
            className={Styles.logo}
            data-testid={TopBarTestIDs.LOGO}
            onClick={() => onClickMenu('home')}>
            <Logotype />
          </div>
          <AdaptiveSidebar
            onClick={() => delayedDismiss()}
            ref={(ref) =>
              ((adaptiveSidebarRef as any).current =
                ref || adaptiveSidebarRef.current)
            }
            className={Styles.content}
            breakInto={BREAK_INTO}
            visibilityControlComponent={({ open }) => (
              <MutableHamburgerButton
                className={Styles.control}
                data-testid={TopBarTestIDs.MENU_BTN}
                state={open ? 'closed' : 'hamburger'}
                size={32}
              />
            )}>
            <Tabs
              className={Styles.tabs}
              itemClassName={Styles.tabLabel}
              options={menuOptions.map((a) => ({
                id: a.menuEnumCode,
                label: tE(`topbar.menus.${a.menuEnumCode}`),
              }))}
              onSelect={(option) => onClickMenu(option)}
              selected={currentOption}
            />
            {availableLanguageOptions.length > 1 ? (
              <Select
                rootClassName={Styles.select}
                items={availableLanguageOptions}
                selected={i18n.language as LanguageCodes}
                data-testid={TopBarTestIDs.LANGUAGE_SELECTOR}
                onClick={(i) => {
                  onChangeLanguage(i.value)
                }}
              />
            ) : (
              <span className={Styles.select} />
            )}
            <AdaptiveContainer
              direction='h'
              onClick={(e: UIEvent) => e.stopPropagation()}>
              {user === null ? (
                <div className={Styles.login} key='a'>
                  <Button
                    variant='transparent'
                    data-testid={TopBarTestIDs.LOGIN}
                    onClick={onSignIn}>
                    {tO('topbar.auth.login')}
                  </Button>
                  <Button
                    variant='outline'
                    data-testid={TopBarTestIDs.SIGNUP}
                    onClick={onSignUp}>
                    {tO('topbar.auth.signup')}
                  </Button>
                </div>
              ) : user === undefined ? (
                <Loader key='l' data-testid={TopBarTestIDs.LOADER} />
              ) : (
                <div className={`${Styles.login}`} key='u'>
                  <span className='dark'>
                    <WalletDisplayButton />
                  </span>
                  <Dropdown
                    onClickMenu={(opt) => {
                      onClickMenu(opt)
                      delayedDismiss()
                    }}
                    currentOption={currentOption}
                  />
                </div>
              )}
            </AdaptiveContainer>
          </AdaptiveSidebar>
          {/* <div className={Styles.themeChanger}>
            <ThemeChanger />
          </div> */}
        </Fragment>
      </UncontrolledTransition>
    </div>
  )
}

function Dropdown({
  onClickMenu,
  currentOption,
}: {
  onClickMenu: (option: AvatarMenuOptions) => void
} & Pick<TopBarViewProps, 'currentOption'>) {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const className = useOneUIConfig('component.select.className.dropdown')

  return (
    <Collapsable
      alignTo={AnchoredTooltipAlignment.RIGHT}
      key={'u'}
      title={
        <div className={Styles.row} data-testid={TopBarTestIDs.PROFILE_BTN}>
          <ProfileAvatar hideUsername />
          <MutableHamburgerButton
            size={24}
            state={!dropdownOpen ? 'arrow-down' : 'arrow-up'}
          />
        </div>
      }
      open={dropdownOpen}
      mode='float'
      onToggleOpen={setDropdownOpen}
      onClickOut={() => setDropdownOpen(false)}
      contentClassName={`${Styles.dropdown}`}
      onTransitionEnd={({ propertyName, target }) => {
        if (propertyName === 'height' && dropdownOpen) {
          const el = target as HTMLDivElement
          el.classList.add(Styles.overflow)
          el.addEventListener('transitionstart', ({ propertyName }) => {
            if (propertyName === 'height') el.classList.remove(Styles.overflow)
          })
        }
      }}>
      <div className={className}>
        {Object.entries(avatarMenu).map(([code, item]) => (
          <div
            key={code}
            className={`${Styles.avatarMenuItem} ${
              code === currentOption ? Styles.selected : ''
            } `}
            onClick={() => {
              onClickMenu(code as unknown as AvatarMenuOptions)
              setDropdownOpen(false)
            }}>
            {item.icon}
            {t(item.title)}
          </div>
        ))}
      </div>
    </Collapsable>
  )
}
