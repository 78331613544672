import { TransitionAnimationTypes } from '@onepercentio/one-ui/dist/components/Transition/Transition'
import UncontrolledTransition from '@onepercentio/one-ui/dist/components/UncontrolledTransition'
import clsx from 'clsx'
import { PropsWithChildren } from 'react'
import useStyles from './Transitions.styles'

export default function Transitions(
  props: PropsWithChildren<{ center?: boolean }>
) {
  const classes = useStyles({ center: props.center })
  return (
    <UncontrolledTransition
      className={clsx(classes.root)}
      transitionType={TransitionAnimationTypes.FADE}>
      {props.children as any}
    </UncontrolledTransition>
  )
}
