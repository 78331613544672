import useAsyncControl from '@onepercentio/one-ui/dist/hooks/useAsyncControl'
import { getAddressData } from 'core/helpers/cep'
import { useEffect, useMemo, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

type FetchData = {
  estado?: string
  cidade?: string
  logradouro?: string
  bairro?: string
}

export const useCEPFetcher = ({
  cep,
  enable = false,
}: {
  cep?: string
  enable: boolean
}) => {
  const {
    loading,
    error,
    getAddressData: getCep,
  } = useAsyncControl({ getAddressData })
  const [data, setData] = useState<FetchData>()
  const sanitized = useMemo(() => (cep || '').replace(/[^0-9]/g, ''), [cep])

  const getAddressDataFromCEP = async ({ cep }: { cep: string }) => {
    const { state, city, street, neighborhood } = await getCep(cep)
    setData({
      estado: state,
      cidade: city,
      logradouro: street || "",
      bairro: neighborhood || "",
    })
  }

  const debouncedGet = useDebouncedCallback(
    async (sanitized) => {
      await getAddressDataFromCEP({
        cep: sanitized,
      })
    },
    2000,
    {
      leading: true,
    }
  )

  const lockFields = useMemo(() => {
    const hasCep = cep?.length === 9
    return {
      state: (hasCep && !!data?.estado) || loading,
      city: (hasCep && !!data?.cidade) || loading,
      address: (hasCep && !!data?.logradouro) || loading,
      neighbourhood: (hasCep && !!data?.bairro) || loading,
    }
  }, [data, cep, loading])

  useEffect(() => {
    if (cep && enable) {
      if (sanitized.length === 8) {
        debouncedGet(sanitized)
      }
    }
  }, [debouncedGet, cep, enable, sanitized])

  return [
    lockFields,
    loading,
    data,
    error,
    () => debouncedGet(sanitized),
  ] as const
}
