import { makeStyles, Theme } from '@material-ui/core'

const style = makeStyles((theme: Theme) => ({
  valueLabel: {
    overflow: 'hidden',
  },
  outerContainer: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  container: {
    padding: theme.spacing(2),
    width: '302px',
    marginRight: theme.spacing(3),
    height: '100%',
    backgroundColor: 'var(--collection-item-bg)',
    borderRadius: '16px',
  },
  containerClosed: {
    width: '302px',
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row !important',
      marginRight: 0,
      width: '100%',
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
  },
  containerHorizontal: {
    flexDirection: 'column',
  },
  containerVertical: {
    flexDirection: 'column',
  },
  disabledPointer: {
    pointerEvents: 'none',
  },
  pointer: {
    cursor: 'pointer',
  },
  figure: {
    width: '100%',
    marginTop: 0,
    marginBottom: theme.spacing(2.5),
    marginLeft: 0,
    marginRight: 0,
    [theme.breakpoints.down('sm')]: {
      margin: `0 auto ${theme.spacing(2.5)}px`,
    },
  },
  image: {
    maxHeight: '302px',
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '100%',
      maxWidth: '100%',
      display: 'flex',
    },
  },
  chipBox: {
    width: '100%',
    minHeight: theme.spacing(5),
  },
  chip: {
    cursor: 'pointer',
    '& > *': {
      color: `${theme.palette.primary.contrastText} !important`,
    },
    [theme.breakpoints.down('sm')]: {
      height: 'initial',
      '& .MuiChip-label': {
        padding: 4,
      },
      '& > *': {
        fontSize: '0.6rem',
      },
    },
  },
  descriptionContainer: {
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  descriptionContainerClosed: {
    display: 'flex',
    flexDirection: 'column',
    height: '176px',
    background: '#0D0D0D',
    justifyContent: 'space-between',
  },
  descriptionBox: {
    justifyContent: 'unset',
    alignContent: 'center',
    flexDirection: 'row',
  },
  descriptionBoxPadding: {
    paddingTop: theme.spacing(7),
    [theme.breakpoints.up('md')]: {
      justifyContent: 'unset',
      alignContent: 'center',
      flexDirection: 'row',
      paddingTop: 'initial',
    },
  },
  descriptionBoxClosed: {
    justifyContent: 'space-evenly',
    alignContent: 'space-between',
    flexDirection: 'column',
    flex: 1,
  },
  chipClosed: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    background: '#43484D',
    color: '#E4E5E9',
    maxWidth: '150px',
  },
  descriptionTextClosed: {
    color: 'white !important',
    fontWeight: 600,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  description: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      flexGrow: 1,
    },
  },
  openButton: {
    background: '#5031D4',
    height: '56px',
    paddingRight: theme.spacing(2),
  },
  divider: {
    display: 'none',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
    },
  },
  price: {
    fontSize: '1.4rem',
  },
  '@keyframes greenPulse': {
    from: { boxShadow: '0 0 1px #AFE30E' },
    '50%': { boxShadow: '0 0 18px #91bd09' },
    to: { boxShadow: '0 0 9px #8FB518' },
  },
}))

export default style
