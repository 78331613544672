import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    actionsWrapper: {
      display: 'flex',
      flexDirection: 'column-reverse',
      flexGrow: 1,
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
    },
    actionButton: {
      [theme.breakpoints.down('sm')]: {
        alignSelf: 'stretch',
        marginBottom: theme.spacing(5),
      },
    },
    cancelButton: {
      alignSelf: 'flex-start',
      marginRight: theme.spacing(5),
      [theme.breakpoints.up('md')]: {
        alignSelf: 'initial',
        marginBottom: 0,
      },
    },
    actionsSection: {
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-start',
      },
    },
    title: {
      textTransform: 'uppercase',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    card: {
      backgroundColor: 'var(--secondary-background)',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    divider: {
      backgroundColor: theme.palette.grey[200],
      alignSelf: 'stretch',
      height: 2,
    },
    bottomSpacing: {
      marginBottom: theme.spacing(3),
    },
    figure: {
      display: 'flex',
      justifyContent: 'center',
      margin: 0,
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        margin: 0,
        paddingRight: theme.spacing(2),
      },
    },
    image: {
      maxHeight: 400,
      maxWidth: '100%',
    },
    userData: {
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  })

export default makeStylesWrapper(style)
