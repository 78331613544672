import { PaymentMethods } from 'pages/Authenticated/Payment/Payment.types'
import { ClaimAuctionStatus } from '../../components/offer/OfferAuctionRace/ClaimAuctionItem/ClaimAuctionItem.types'
import { KYCRequiredStatus } from '../../components/offer/OfferAuctionRace/KYCActionWrapper/KYCActionWrapper.types'
import { OfferTypeParam } from '../../core/logic/drop/drop.types'
import { RarumUserProfile } from '../../core/logic/user/user.types'
import { PREDEFINED_DEADLINES } from '../../pages/Authenticated/Marketplace/CreateOffer/CreateOffer.types'
import { CurrenciesEnum } from 'core/types/CurrenciesEnum'

const messages = {
  'pt-BR': {
    translations: {
      features: {
        challenge: {
          claim: {
            claiming: {
              title: 'Aguarde um momento',
              description: 'Estamos efetuando a operação de resgate do item',
            },
            confirmation: {
              actions: {
                viewDetails: `Ver dados de resgate`,
                goToClaim: `Ir para o resgate`,
                approve: 'Aprovar',
              },
              approve_gallery: `É necessário autorizar a manipulação dos tokens da galeria {{galleryName}}.`,
              title_claiming: `Resgatando item`,
              deplete: `O item {{itemName}} não vai mais estar disponível na sua coleção, pois todas as unidades estão sendo utilizadas para o resgate.`,
              disclaimer: `Ao confirmar o resgate, a operação não poderá ser desfeita.`,
              warning: `Os itens selecionados para o resgate serão transformados em um novo item e a quantidade disponível será reduzida em "Minha coleção"`,
              title: 'Confirmação de resgate',
            },
            authRequired: `Para ver o seu progresso, é necessário entrar com sua conta`,
            requirement: {
              available: `Existem {{available}} de {{required}} itens disponíveis para resgate`,
              status: {
                incomplete: `Resgate pendente`,
                complete: 'Completo',
              },
            },
            loading: {
              description: `Estamos carregando as informações de resgate`,
              title: 'Aguarde um momento',
            },
            follow_instructions: `O que é preciso para resgatar essa Recompensa:`,
            title: 'Resgate do item',
            reward_rules: 'Regras da recompensa',
            requirements: 'Requisitos para resgate',
            more_details: 'Mais detalhes',
            actions: {
              seeItem: `Ver item`,
              status: {
                claimable: `Resgatar`,
                unavailable: 'Complete os itens para resgatar',
                claiming: 'Resgatando',
              },
            },
            status: {
              claimable: 'Disponível para resgate',
              unavailable: 'Indisponível para resgate',
            },
          },
          section: {
            title: 'Recompensas',
          },
          banner: {
            amount_of_type: '{{count}} itens {{itemType}}',
            to_claim: 'Para resgatar essa recompensa, você precisa de:',
          },
        },
      },
      walletRequiredControl: {
        connectWallet: 'Conectar carteira',
        connectWalletDescription:
          'Para iniciar esse processo, primeiro você precisa conectar sua carteira Metamask.',
        connectWalletLink: 'Para aprender como fazer, acesse aqui.',
        transferWallet: {
          intro: {
            actions: {
              cancel: 'Cancelar',
              startOperation: 'Iniciar operação',
            },
          },
          waitingConfirmation: {
            title: 'Confirmação necessária',
            description:
              'Para seguir o processo, confirme o link que acabamos de enviar para seu email. É necessária a confirmação para conectar seus NFTs à carteira conectada em seu perfil.',
            actions: {
              understood: 'Entendi',
            },
          },
          fromEmailLink: {
            title: 'Confirmar operação',
            description:
              'Vamos conectar todos os seus NFTs para sua carteira conectada:',
            actions: {
              understoodLimitations:
                'Estou ciente de que esse processo será feito somente uma vez e não poderá ser desfeito.',
              understoodDanger:
                'Estou ciente dos riscos e benefícios de usar uma carteira externa e de que a Rarum não terá mais acesso aos meus NFTs após a conclusão da migração.',
              cancel: 'Cancelar',
              confirm: 'Confirmar',
            },
          },
          operationStarted: {
            title: 'Operação confirmada',
            description:
              'A operação está em andamento e em breve seus itens serão transferidos para sua carteira.',
            actions: {
              understood: 'Entendi',
            },
          },
        },
        wrongWallet: {
          title: 'Erro ao conectar carteira',
          description1: 'Não foi possível conectar com a carteria:',
          description2:
            'Tente novamente com a carteria associada a essa conta:',
        },
        migrationRequired: {
          title: 'Conectar NFTs à carteira',
          description:
            'Para seguir com essa operação, você precisa, em primeiro lugar, conectar os itens da sua coleção com a sua carteira.',
          actions: {
            goToMigration: 'Seguir para migração',
          },
        },
        actions: {
          connectWalletMetamask: 'Conectar carteira Metamask',
          reloadWindow: 'Verificar instalação',
          switchNetwork: 'Conectar na rede {{name}}',
        },
        switchNetwork: 'Trocar para rede {{name}}',
        switchNetworkDescription:
          'Certifique-se de que está na rede {{name}} em sua carteira Metamask ({{currency}}). Caso não esteja, troque antes de iniciar o processo.',
        connecting: 'Conectando',
        connected: 'Conectado',
        metamaskGuideTarget: '',
      },
      generic: {
        knowMore: `Saiba mais`,
        retryBtn: 'Tentar novamente',
        soon: 'em breve!',
        buy: 'COMPRAR',
        cancel: 'Cancelar',
        continue: 'Continuar',
        errorMsg: 'Algo deu errado',
        contactUs: 'Entre em contato conosco',
        share: 'Compartilhe:',
        close: 'Fechar',
        attention: 'Atenção!',
        goBack: 'Voltar',
        next: 'Próximo',
        notNow: 'Agora não',
        optional: 'Opcional',
        or: 'ou',
        understood: 'Entendi',
        notFound: {
          title: 'Recurso não encontrado.',
          actions: {
            backToHome: 'Voltar a página principal',
          },
        },
        save: 'Salvar',
        fieldRequired: 'O campo é obrigatório',
        invalidPhone: 'Número inválido',
        invalidDate: 'Data inválida',
        day: '{{count}} dia',
        day_plural: '{{count}} dias',
        today: 'Hoje',
        actions: {
          edit: 'Editar',
        },
      },
      marketplace: {
        buyOffer: {
          whatIsRarum: 'O que é a Rarum?',
          details: 'Detalhes da compra',
          destinatary: 'Destinatário (você):',
          itemName: 'Nome do item:',
          value: 'Valor:',
          balanceWarning:
            'O seu saldo em {{currency}} ({{amount}}) é insuficiente',
          boughtItem: 'Compra efetuada com sucesso!',
          approveTokens: 'Aprovar movimentação dos tokens',
          final: 'Confirmar compra',
          title: 'Finalizar compra',
          description: 'Siga os passos a seguir para concluir a compra',
          actions: {
            seeItem: 'Ver item',
            retry: 'Tentar novamente',
            approve: 'Aprovar',
            confirm: 'Confirmar',
            cancel: 'Cancelar compra',
          },
        },
        assetDetails: {
          available: '{{count}} oferta disponível',
          available_plural: '{{count}} ofertas disponíveis',
          offers: 'Ofertas',
          lowestPriceOffer: 'Oferta mais barata',
          marketplaceValue: 'Valor no mercado',
          seller: 'Vendedor',
          you: 'Você',
          noOffers: 'Não há ofertas para a moeda selecionada ({{currency}})',
          actions: {
            listOffers: 'Ver ofertas',
            manageMyITem: 'Gerenciar anúncio',
          },
        },
        offerCreation: {
          title: 'Anunciar item',
          description:
            'Configure os parâmetros de venda a seguir para criar o anúncio.',
          actions: {
            backToHome: 'Voltar para o item',
            cancel: 'Cancelar',
            confirm: 'Confirmar e anunciar',
          },
          sections: {
            preview: {
              title: 'Prévia do anúncio:',
            },
            type: {
              title: 'Tipo de venda',
              direct: 'Venda direta',
              auction: 'Leilão',
            },
            currency: {
              title: 'Moeda e valor do item',
              description: 'Escolha o valor desejado para venda',
              description_plural:
                'Escolha a moeda e o valor desejado para venda',
              chooseYourCurrency: 'Escolha a moeda',
              defineAmount: 'Informe o valor de venda',
              disclaimer: {
                youReceive: '(você recebe: {{ amount }})',
                tax: '(Taxa: {{ amount }}%)',
              },
            },
            itemAmount: {
              title: 'Quantidade de item',
              description: 'Informe a quantidade do mesmo item',
            },
            deadline: {
              title: 'Prazo',
              description: 'Informe o prazo máximo que o anúncio ficará ativo',
              disclaimer: 'mínimo: 1 mês',
              predefined: {
                one_month: '1 mês',
                two_months: '2 meses',
                three_months: '3 meses',
              } satisfies {
                [k in PREDEFINED_DEADLINES]: string
              },
            },
          },
          creation: {
            error: {
              title: 'Oops, algo deu errado',
              description:
                'Tente novamente. Caso persista o erro, nos ajude a melhorar, entre em contato conosco pelo contact@onepercent.io',
            },
            inProgress: {
              title: 'Criando pedido de oferta',
              description:
                'Em breve, seu anúncio estará pronto.\nSucesso em suas vendas!',
              actions: {
                wait: 'Aguarde',
              },
            },
            finished: {
              title: 'Pedido de oferta criado',
              description: 'O seu anúncio está pronto',
              actions: {
                seeItem: 'Ver item',
              },
            },
          },
          edition: {
            error: {
              title: 'Oops, algo deu errado',
              description:
                'Tente novamente. Caso persista o erro, nos ajude a melhorar, entre em contato conosco pelo contact@onepercent.io',
            },
            inProgress: {
              title: 'Atualizando sua oferta',
              description: 'Em breve, seu anúncio estará atualizado.',
              actions: {
                wait: 'Aguarde',
              },
            },
            finished: {
              title: 'Oferta atualizada',
              description: 'A sua oferta foi atualizada com sucesso.',
              actions: {
                seeItem: 'Ver item',
              },
            },
          },
          removal: {
            error: {
              title: 'Oops, algo deu errado',
              description:
                'Tente novamente. Caso persista o erro, nos ajude a melhorar, entre em contato conosco pelo contact@onepercent.io',
            },
            inProgress: {
              title: 'Remover anúncio',
              description:
                'Tem certeza que deseja remover esse anúncio? Após a confirmação a operação não poderá ser desfeita.',
              actions: {
                wait: 'Confirmar e remover',
              },
            },
            finished: {
              title: 'Seu anúncio foi removido com sucesso!',
              description:
                'Estamos efetuando a transação. Em breve ela estará concluída.',
              actions: {
                seeItem: 'Ok',
              },
            },
          },
          confirmation: {
            title: 'Finalizar anúncio',
            description:
              'Siga os passos a seguir para concluir a criação do anúncio',
            configure: 'Configurar anúncio',
            approve: 'Aprovar sua carteira Metamask',
            confirm: 'Confirmar operação',

            actions: {
              approve: 'Aprovar carteira',
              confirm: 'Confirmar',
              cancel: 'Cancelar criação do anúncio',
            },
          },
        },
        offerEdition: {
          actions: {
            confirm: 'Confirmar e atualizar',
          },
        },
        banner: {
          header: {
            title: 'Mercado',
            description:
              'Conheça os itens à venda e todos os cards que podem fazer parte da sua Coleção',
            connectedWallet: 'Carteira conectada',
            actions: {
              connectWallet: 'Conectar carteira',
              disconnectWallet: 'Desconectar',
              dismiss: 'Continuar',
            },
          },
          faq: {
            title:
              '<b>Veja como vender</b> os itens disponíveis na sua coleção',
            action: 'Saiba mais',
            targetGuide: '',
          },
        },
        selectUnitsOnSale: 'Selecione a quantidade de itens',
        unitsOnSale: '{{count}} unidade',
        unitsOnSale_plural: '{{count}} unidades',
        salePrice: 'Valor anunciado',
        totalUnits: 'unidades: {{units}}',
        remainingUnits: 'restam: {{units}}',
        remainingHighlight: '{{count}} item à venda agora',
        remainingHighlight_plural: '{{count}} itens à venda agora',
        lowestPrice: 'Menor valor',
        buyNow: 'Comprar agora',
        filters: {
          order: {
            lowestToHighest: 'Menor ao maior',
            highestToLowest: 'Maior ao menor',
          },
          assets: {
            showAll: 'Todos os cards',
            cardsOnSale: 'Cards a venda',
          },
          currency: 'Moeda',
          clear: 'Limpar filtros',
          rarity: {
            label: 'Raridade',
            types: {
              COMMON: 'Comum',
              EPIC: 'Épico',
              LEGENDARY: 'Legendária',
              RARE: 'Rara',
            } satisfies {
              [k in typeof import('core/constants')['RARITIES'][number]]: string
            },
          },
        },
      },

      highlights: {
        marketplace: {
          title:
            'Agora você pode comprar e vender cards de outros colecionadores',
          description:
            'Aumente a sua Coleção, venda cards repetidos e complete sua coleção.',
          action: 'Ver mercado',
        },
        marketplaceSoon: {
          title:
            'Em breve você poderá comprar e vender cards de outros colecionadores',
          action: 'Avise-me',
        },
      },
      app: {
        cookiesConsent: {
          understand: 'Eu entendo e aceito',
          message: 'Este site usa cookies para melhorar a sua experiência.',
        },
      },
      wallet: {
        connect: 'Conectar carteira',
        switchingAccount: {
          warning: 'Ao trocar de conta você precisa conectar novamente.',
        },
      },
      unauthenticated: {
        terms: {
          termOfUse: 'Termos de uso e política de privacidade',
          return: 'Voltar',
        },
        risks: {
          title: 'Termo de Ciência de Risco',
          description:
            'TERMO DE CIÊNCIA E RISCO PARA FINS DO INCISO IV DO ARTIGO 26 DA RESOLUÇÃO CVM 88',
          list: [
            'Antes de realizar investimentos em startups por meio da Plataforma, é imprescindível que você, o investidor, tenha ciência e concorde com todos os riscos envolvidos. Elaboramos, portanto, o presente Termo de Ciência de Risco (“Termo”), por meio do qual você declara não apenas ter ciência dos riscos abaixo detalhados e de seus alertas, como também do conteúdo das informações essenciais da oferta pública em que você quer investir.',
            'A principal preocupação da Plataforma é que os investidores tenham consciência acerca dos riscos inerentes ao investimento em Crowdfunding, ou seja, em aportes realizados em empresas em estágio inicial de desenvolvimento e que, naturalmente, apresentam alto risco. Em vista disso, a Plataforma recomenda a leitura atenta da Resolução da Comissão de Valores Mobiliários nº 88, de 27 de abril de 2022, conforme alterada (“CVM” e “Resolução CVM 88”, respectivamente). Esta é a norma que regula o mercado de Crowdfunding e que, portanto, fundamenta todo o material disponibilizado em nosso site, inclusive o material didático sobre as ofertas públicas via plataformas de Crowdfunding.',
            'Cumpre-nos destacar também que as startups que ofertam valores mobiliários por meio da Plataforma são sociedades empresárias de pequeno porte (“EPPs”) não registradas na CVM. Isso pode significar, dentre outras coisas, (i) que há chances descontinuidade da prestação de informações pela sociedade uma vez realizada a oferta e (ii) que não há obrigação legal ou regulamentar das EPPs se transformarem em sociedades anônimas, quando não constituídas como tal.',
            'Adicionalmente, para que os riscos relacionados aos investimentos em Crowdfunding fiquem mais claros, seguem abaixo alguns apontamentos relevantes, sempre considerando que toda atividade empresarial apresenta riscos:',
            '<b>(i) Risco de Perda do Capital</b>',
            'Considerando que startups podem não desenvolver com sucesso suas atividades, existe a possibilidade de perda da totalidade do capital investido.',
            '<b>(ii) Riscos de Aquisição ou Conversão em Participação</b>',
            'A conversão do investimento realizado por você em participação da startup pode gerar riscos ao seu patrimônio pessoal, dependendo do tipo societário adotado, em razão do não reconhecimento de limitação de responsabilidade por decisões judiciais nas esferas trabalhistas, previdenciária e tributária, dentre outras. Existe, assim, o risco de você, investidor, ser considerado sócio e responsável por dívidas da startup investida.',
            '<b>(iii) Riscos de Posição Minoritária</b>',
            'Ao realizar investimentos por meio da Plataforma, você poderá tornar-se titular de participação societária minoritária. Em outros termos, o investidor de Crowdfunding não terá ingerência na startup, dependo integralmente das decisões tomadas pelos controladores, inclusive no que se refere emissão adicional de valores mobiliários, alienação do controle ou de ativos, e transações com partes relacionadas.',
            '<b>(iv) Riscos de Crédito</b>',
            'A startup poderá precisar de crédito para financiar as suas atividades, gerando obrigações de pagamento futuras (inclusive, os títulos em que você investiu podem ser representativos de dívidas!). Considerando o risco da atividade, existe a chance de a startup não honrar os pagamentos das dívidas contraídas.',
            '<b>(v) Riscos de Liquidez</b>',
            'Os investimentos em startups correspondem a ativos de baixa liquidez e não admitidos à negociação em mercados regulamentados. Em outros termos, isso significa que o investidor poderá enfrentar dificuldades caso deseje alienar a terceiros os valores mobiliários decorrentes dos investimentos em Crowdfunding, pois não há mercado secundário desenvolvido para tal.',
            'Por fim, conforme exposto, cumpre-nos reforçar que o investimento em startups envolve riscos financeiros com a possibilidade de perda total do capital investido. A Plataforma não garante o retorno do investimento efetuado, de modo que investimentos desta natureza devem compor apenas uma pequena parte de uma carteira diversificada de investimentos. Apenas invista em startups disponíveis na Plataforma caso possua conhecimento suficiente para a compreensão dos riscos e para a tomada consciente de suas próprias decisões de investimento. Os investimentos realizados através da Plataforma não contam com garantia da própria plataforma, de mecanismos de seguro, ou ainda, do Fundo Garantidor de Crédito – FGC.',
            'Considerando as informações acima, é essencial que você busque sempre suporte para a realização de seus investimentos e os faça com cautela. Assim, havendo qualquer dúvida quanto às informações deste Termo, fique à vontade para nos contatar.',
          ],
        },
        galleryCards: {
          title: 'Cards',
          text1: 'Todos os cards disponíveis na plataforma!',
          filter: {
            COMMON: 'Comum',
            RARE: 'Rara',
            EPIC: 'Épica',
            LEGENDARY: 'Lendária',
            all: 'Todas',
          },
          filterNoResult: 'O filtro não encontrou resultados',
          actions: {
            clearFilters: 'Limpar filtros',
          },
        },
        socialSign: {
          rarumInscription:
            'Ao se inscrever na Rarum, você reconhece que leu e concorda com todos os',
          termOfUse: 'Termos de Uso e Política de Privacidade',
          alert:
            'O e-mail já foi utilizado em uma conta {{existingPlatform}}, por favor entre com esta plataforma primeiro para concluir a conexão',
          continue: 'Continuar',
          continueWith: 'Continue com o',
          dataConsent:
            'Eu aceito compartilhar meus dados com {{tenantName, uppercase}} para ações de marketing.',
          noAuth: {
            title: 'Olá',
            description:
              'Por favor, acesse nosso site utilizando seu Navegador Web para uma experiência completa.',
            goBack: 'Voltar',
          },
        },
        identification: {
          signIn: 'ENTRAR',
          signInUsing: 'Ou faça login usando:',
          signUp: 'Cadastre-se',
          forgotPassword: 'Esqueceu sua senha?',
          cancel: 'CANCELAR',
        },
        galleryDetail: {
          backToCards: 'Voltar para Cards',
          itemsDetail: 'Detalhes do item',
          description: 'Descrição',
          drawnIn: 'Presente em',
        },
        galleries: {
          title: 'Galerias em destaque',
          description: 'Acesse as galerias temáticas para conhecer as ofertas',
        },
        drops: {
          list: {
            collectablePackages: 'Itens colecionáveis',
            featuredOffers: 'Ofertas em destaque',
            featuredDescription:
              'Aqui você encontra os melhores itens para compra',
            description:
              'Aqui você encontra todos os itens disponíveis para compra na loja',
            notAvailable:
              'Estes itens não estão mais disponíveis mas não se preocupe, em breve você poderá procurar itens específicos no\nmarketplace.',
            previousPackages: 'Itens anteriores',
            stickyDrop: {
              saleNow: 'compre agora!',
            },
            soonDropItem: {
              comingSoon: 'Em breve!',
            },
            dropItem: {
              liveBadge: {
                liveNow: 'ao vivo agora!',
              },
            },
          },
          details: {
            info: {
              bookPackage: 'Comprar item',
              notAvailable:
                'Não há mais unidades disponíveis desse item. Selecione outro para colecionar mais itens com a Rarum.',
              waitEvent: 'Aguarde o início do evento!',
              ctaLabelBuy: 'Comprar',
              ctaLabelApprove: 'Aprovar',
              missingProvider:
                'Para comprar este NFT, você precisa de uma carteira tipo o Metamask. Por favor instale uma carteira.',
              approvalFailMessage:
                'Não foi possível confirmar sua transação, por gentileza, atualize essa tela, verifique seu histórico de transações e tente novamente',
              approvalSuccessMessage:
                'Sucesso! Agora você já pode comprar este NFT.',
              purchaseFailMessage:
                'Não foi possível confirmar sua transação, verifique seu histórico de transações antes de tentar novamente.',
              purchaseSuccessMessage:
                'Sucesso! Em breve seu NFT vai aparecer na galeria, aguarde alguns minutos.',
            },
            availability: {
              packageDetail: 'Detalhes do item',
              packageAvailable:
                'Este item está disponível para venda no período:',
            },
            packages: 'Itens',
          },
        },
        deletion: {
          return: 'Voltar',
          remotionInstruction:
            'Instruções para remoção de dados pessoais da aplicação',
        },
        connect: {
          createAccount: 'Crie sua conta na Rarum',
          login: 'Faça login na Rarum',
        },
        faqview: {
          faq: {
            1: '<b>Cadastre-se </b> usando sua conta Google ou Facebook',
            2: `Conheça as coleções disponíveis. Cada coleção possui um determinado número de itens, que variam de quantidade entre as coleções e estão organizados em três níveis de raridade: <b>Hero, Premium e Legend</b>.
            Os cards foram distribuídos e nomeados conforme seu nível de raridade.`,
            3: `Os cards são oferecidos de maneira randômica, através de pacotes. Escolha um dos pacotes das coleções disponíveis. Cada pacote contém um determinado número de cards aleatórios daquela coleção. Os pacotes se diferenciam por sua raridade e desejo. Quanto mais raro, maior o desejo de obter e em menor quantidade estará disponível.`,
            4: `Após selecionar um pacote, você será direcionado para a tela de compra. Escolha uma forma de pagamento e insira os dados solicitados. Assim que o pagamento for aprovado, nosso sistema entrega os NFTs para sua coleção de forma randômica e de acordo com a raridade escolhida: hero, premium e legend.`,
            5: `Pronto, você receberá seu pacote na sua coleção. Clique em ”abrir pacote” e revele seus cards. E já é possível, verificar sua propriedade na blockchain, com transparência e imutabilidade. Os cards ficarão armazenados em sua coleção e, em breve, você poderá negociá-los com outros colecionadores.`,
            accordion: {
              title: {
                1: 'O que é NFT?',
                2: 'Por que o NFT é um item colecionável?',
                3: 'O que posso fazer com meu NFT?',
                4: 'Quanto vale meu NFT?',
                5: 'O que é um token? ',
                6: 'O que é criptoarte?',
                7: 'O que é uma carteira digital de criptoativos?',
                8: 'O que é blockchain?',
                9: 'Em que blockchain foram criados os NFTs?',
                10: 'Por que escolheram a {{name}}?',
                11: 'Como faço para verificar meu NFT na rede {{name}}?',
                12: 'O que são metadados e onde essas artes digitais estão armazenadas?',
                13: 'Como funciona o leilão?',
                14: 'Como funciona a transferência do NFT?',
                last: 'Mais dúvidas?',
              },
              content: {
                1: `A sigla NFT significa (em inglês) Non Fungible Tokens ou, em português: "tokens não fungíveis", ou seja, não substituíveis. Não podem ser trocados por outro item equivalente.
                Assim, NFT é um tipo de token com a característica de ser único, registrado em uma cadeia de blocos (Blockchain), representando um ativo nativamente digital ou físico no mundo digital.

                Aqui existe um diferencial bem importante. Somos a primeira plataforma com NFTs sustentáveis, ou seja, Carbono Neutro. Todas as emissões de gases de efeito estufa originados ao criar os NFTs são compensados utilizando tokens de carbono de diversos projetos do ecossistema cripto.`,
                2: `Imagine uma valiosa obra de arte que está em exibição em um museu famoso.

                Essa obra de arte pode ser vista por milhares de visitantes deste museu. Também é possível tirar fotos e até comprar reproduções desta obra para exibi-la em sua casa.

                Não importa quantas reproduções ou cópias exatas da obra sejam feitas, o valor estará sempre na obra original.

                Essa mesma lógica chega ao ambiente digital com o surgimento dos NFTs, esses tokens únicos que estão revolucionando o mundo das artes e dos apaixonados por algum tipo de item colecionável, pois eles permitem certificar a propriedade original do item único seja físico ou 100% digital. `,
                3: `A partir disso, as possibilidades são infinitas, podendo vender, guardar, exibir sempre garantindo o registro de propriedade a partir da Blockchain, conferindo confiança no processo, rastreabilidade, segurança e transparência no processo.
                <br />
                <br />
                Por exemplo :
                <br />
                1. Guardá-lo como uma lembrança;
                2. Presentear um membro da família ou amigo.
                3. Vender diretamente a algum interessado em comprar esse ativo limitado.
                4. Colocá-lo à venda em uma das plataformas de compra e venda da NFT (por exemplo, Rarum)
                `,
                4: `O valor de um NFT é basicamente ditado pelo mercado, não é possível prever com antecedência se esse ativo digital terá um valor monetário em algum momento no futuro. Dependerá exclusivamente da oferta e da demanda do item.

                O movimento de mercado recente tem apresentado grandes oportunidades aos colecionáveis e aos proprietários de NFTs ao redor do mundo. `,
                5: `Quando falamos em token do ponto de vista financeiro, em um ambiente
                Blockchain, estamos falando sobre a representação de um ativo
                financeiro no mundo digital.
                <br />
                <br />
                Essa representação pode ocorrer de duas maneiras: <br />
                <br />
                1. Pode ser um token que representa um ativo que tem uma versão no
                mundo físico: eles são chamados de “asset-backed tokens”
                <br />
                2. Ou também podem ser tokens que não têm relação com os ativos
                tradicionais a que estamos acostumados e que representam apenas um
                ativo nativo em um ambiente digital, como é o caso deste NFT que
                estamos utilizando nesta ação.`,
                6: `Criptoarte é um conceito de obra de arte que tem sua representação em um Blockchain e passou pelo processo de transformar-se em um token, assim como são as criptomoedas para o mercado financeiro.

                A partir da possibilidade da tokenização de ativos, surgiram tokens colecionáveis que ficaram famosos através da sigla: NFT (Non Fungible Tokens).

                Esses tokens têm a característica de serem únicos no ambiente digital, portanto, têm a capacidade de também serem colecionáveis.

                O movimento da criptoarte começou a ganhar alguma relevância em 2017, mas explodiu nos últimos anos com o amadurecimento da tecnologia e o surgimento de diversas plataformas de compra e venda bem como de artistas focados neste mercado e consumidores interessados em colecionar.
                `,
                7: `Essas carteiras digitais são aplicativos nos quais os ativos podem ser gerenciados de maneira simples e autônoma por seu proprietário.

                Nesse ambiente, você pode armazenar seus ativos criptográficos, aqui representada como “Minha coleção” ou transferi-los para outra carteira de sua propriedade.

                Sua carteira é o ambiente ao qual você deve ter muita atenção ao lidar com seus ativos pois são a sua segurança de acesso e propriedade desses ativos.

                Ao transferir a outra carteira, sugerimos que verifique a reputação da empresa que realiza a intermediação desta carteira e siga as recomendações de segurança ao abrir uma nova carteira.
                `,
                8: `Um blockchain é essencialmente um livro-razão digital de transações que é duplicado e distribuído em toda a rede de sistemas de computador no blockchain.
                Blockchain é um termo que tem sido amplamente utilizado quando nos referimos a uma nova infraestrutura tecnológica da web3 (nova fase da internet) que tem como principais características ser: descentralizada, segura e transparente. E também que possibilita a criação e a troca de ativos digitais entre pessoas ao redor do mundo, sem a necessidade de um intermediário.

                Essa tecnologia permitiu a criação das criptomoedas, como por exemplo o Bitcoin.

                O tema vem ganhando cada vez mais relevância nos últimos anos com o amadurecimento de outras redes de Blockchain como o Ethereum, com o surgimento dos smart contracts.

                Os principais usos dessas tecnologias são para soluções de meios de pagamento, para tokenização de ativos de diversos tipos (obras de arte, ações, imóveis, créditos de carbono entre outros), registros e autenticação de contratos e documentos em geral até o surgimento dos NFTs, como os que estamos vendo nessa plataforma.`,
                9: `Os tokens NFTs foram criados na rede {{name}}.`,
                10: '',
                11: `Existem várias ferramentas que permitem a verificação das informações em um Blockchain, essas ferramentas são conhecidas como Block Explorer.

Um dos mais populares na rede {{name}} é o <a href="{{explorerUrl}}" target="_blank">{{explorerName}}</a>.

Copiando e colando o endereço do seu NFT no {{explorerName}}, você poderá acessar os metadados que compõem o seu NFT.`,
                12: `Metadados são todas as informações que descrevem o que esse ativo digital representa.

                Para garantir o armazenamento dos arquivos também de forma descentralizada, utilizamos os serviços <a href="https://ipfs.io/" target="_blank">IPFS</a> para armazenar tanto a arte em si quanto o arquivo que contém essas informações.

                Ao contrário dos formatos de armazenamento em nuvem mais populares, onde escolhemos uma única empresa para "cuidar" da guarda dessas informações, com o IPFS essa guarda também é descentralizada, proporcionando mais resiliência e segurança às informações.

                Dessa forma, mesmo se este site saia do ar, você poderá ter acesso ao seu NFT para sempre, desde que você tenha feito o resgate da maneira correta.`,
                13: `Você poderá ter acesso a um item único em formato de NFT (um ativo digital) a partir do leilão. Basta seguir alguns critérios para o sucesso do seu lance. Acompanhe a seguir:
                O valor do lance precisa ser maior do que 1%;
                Ser menor que 10% em relação ao último lance já realizado, caso haja;
                Informar seu número de celular válido;
                Enviar documentações que serão solicitadas a partir de um contato da equipe da plataforma para validar como ganhador do leilão;
                Realizar o pagamento, escolhendo um dos formatos disponíveis na plataforma: PIX, Cartão de Crédito ou Criptomoeda;
                Após aprovação da documentação e do pagamento. Pronto! Você receberá o NFT exclusivo em sua Coleção.`,
                14: `Para pagamentos em PIX ou criptomoeda, a transferência estará disponível automaticamente. Já para pagamentos em cartão de crédito, a transferência só estará disponível após 30 dias do cadastro dos dados do mesmo cartão de crédito. Isso para cuidar da segurança da plataforma e da segurança da sua experiência conosco. Caso você troque de cartão de crédito, esse processo passa a contar novamente os 30 dias de segurança do sistema.<br/><br/>

                Para realizar essa operação, você precisará seguir alguns passos. Vamos às instruções?! Preencha os campos a seguir com a quantidade de NFTs a transferir, e com o endereço de recebimento de sua carteira digital para fazer a transferência. <br/>
                Passo 1 - Baixe, instale e crie uma conta da carteira <a href="https://metamask.io" target="_blank">MetaMask</a> em seu celular, disponível na Play Store ou Apple Store, ou em seu computador;<br/>
                Passo 2 - Ao abrir sua carteira, você encontrará seu endereço de recebimento abaixo do nome da sua conta. Copie esse endereço. Você irá utilizá-lo para o processo indicado na Rarum.<br/><br/>

                Visualizando meu NFT:<br/>
                Pronto, você já está com seu NFT em sua carteira digital. Agora, você poderá visualizar em outra plataforma de sua preferência.
                `,
                last: `Caso precise de mais informações ou tenha dúvidas sobre o processo, faça sua solicitação via email: contact@onepercent.io
                Em breve, retornaremos o atendimento por este canal de comunicação.
                Agradecemos a confiança!
                `,
              },
            },
          },
        },
        claim: {
          signup: {
            main: {
              title:
                '<b>Informe seu melhor email</b> para receber o acesso ao seu NFT exclusivo',
            },
            result: {
              title:
                '<b>You rock!</b><br/>Com sua participação, você e mais centenas de pessoas receberam um NFT exclusivo!',
              description:
                '<b>Agora é só aguardar</b><br/>Em breve, você receberá orientações em seu e-mail de como acessar seu NFT exclusivo do Curta Consciente!',
            },
          },
          toClaim: {
            title: 'Resgate seu NFT!',
          },
          claiming: {
            title: 'Resgatando',
          },
          soldOff: {
            title: 'Item esgotado',
            description:
              'Lamentamos, mas este item já atingiu o número máximo de resgates.',
          },
          errors: {
            alreadyClaimed: {
              title: 'Este item já foi resgatado',
              actions: {
                showItem: 'Mostrar na coleção',
              },
            },
          },
          actions: {
            claim: 'RESGATAR',
          },
        },
      },
      authenticated: {
        userProfile: {
          kyc: {
            pending: {
              title: 'Finalize seu cadastro e fique seguro!',
              description:
                'Para sua segurança e da plataforma, é necessário finalizar o cadastro com a etapa de reconhecimento e confirmação da sua identidade. Clique no botão para prosseguir.',
            },
            awaiting_metamap: {
              title: 'Validando seus dados',
              description:
                'Estamos validando os seus dados e logo você estará apto a participar dos leilões',
            },
            rejected: {
              title: 'Puxa, algo deu errado',
              description:
                'Entre em contato conosco através do botão ao lado para verificar-mos o que aconteceu',
            },
            active: {
              title: 'Tudo certo com os seus dados',
              description:
                'Validamos seus dados com sucesso e agora você já pode participar do leilão',
            },
          } satisfies {
            [k in
              | Extract<
                  NonNullable<RarumUserProfile['kycStatus']>,
                  'awaiting_metamap' | 'rejected' | 'active'
                >
              | 'pending']: {
              title: string
              description: string
            }
          },
          myData: 'MEUS DADOS',
          purchases: '{{count}} compra',
          purchases_zero: 'Ainda não foram feitas compras',
          purchases_plural: '{{count}} compras',
          digitalWallet: 'Carteira digital',
          digitalWalletNotYetConfigured:
            'Carteira indisponível, aguardando primeira compra ou vincular carteira Metamask',
          profileUpdate: 'Atualização de cadastro',
          actions: {
            history: 'Ver histórico',
            seeStore: 'Voltar para loja',
            setup: 'Configurar',
            complete: 'Completo',
            incomplete: 'Incompleto',
            changePersonalData: 'Alterar dados pessoais',
            changeDocuments: 'Alterar documentos',
            changeInvestorProfile: 'Alterar perfil de investidor',
            changeAdditionalData: 'Alterar dados adicionais',
          },
          sections: {
            personalData: [
              'Meus dados pessoais',
              'Gerencie seus dados pessoais.',
            ],
            myDocuments: [
              'Meus Documentos',
              'Gerencie e atualize seus documentos',
            ],
            investorProfile: [
              'Perfil de Investidor',
              'Atualize seu perfil de investidor',
            ],
            paymentData: [
              'Dados de Pagamento',
              'Adicione ou altere seus dados de pagamento e da sua carteira digital.',
            ],
            communication: [
              'Comunicação e Privacidade',
              'Gerencie o uso das suas comunicações e das suas informações pessoais.',
            ],
          },
          communication: {
            sections: ['Comunicação', 'Privacidade'],
            dataUsage:
              'Utilizar dados pessoais para comunicações e publicidade',
            acceptCookies: 'Aceitar cookies',
            cancel: {
              title: 'Cancelar conta',
              description:
                'Cancele sua conta e exclua suas informações da plataforma Rarum',
              actions: {
                cancel: 'Cancelar conta',
              },
            },
          },
          dataBelow:
            'Os dados abaixo são referentes ao titular do meio de pagamento que será utilizado para realizar compras na loja. As informações podem ser armazenadas aqui para agilizar suas próximas compras.',
          saveData: 'Salvar dados',
          deleteData: 'Apagar meus dados',
          wantToDelete: 'Deseja apagar os dados?',
          sureDelete:
            'Tem certeza que deseja apagar todos os seus dados de pagamento? Ao realizar sua próxima compra será necessário informá-los novamente.',
          eraseData: 'APAGAR DADOS',
          cancel: 'Cancelar',
          sucess: 'Seus dados foram salvos com sucesso!',
          sucessClear: 'Seus dados foram removidos com sucesso!',
          error: 'Ocorreu um erro, por favor tente novamente.',
          profileForm: {
            identification: 'Identificação',
            fullName: 'Nome completo',
            cpf: 'CPF',
            address: 'Endereço',
            cep: 'CEP',
            state: 'Estado',
            city: 'Cidade',
            place: 'Rua/Avenida',
            number: 'Número',
            neighbourhood: 'Bairro',
            complement: 'Complemento',
          },
        },
        checkout: {
          dropDetails: {
            details: 'Detalhes da compra',
            recipient: 'Destinatário (você):',
            itemName: 'Nome do item:',
            value: 'Valor:',
            cvm: {
              footer: `Qualquer investimento em pequenas empresas (startups) envolve riscos financeiros e desafios significativos. Investimentos em startups devem compor apenas uma pequena parte de uma carteira diversificada de investimentos. Você correrá o risco de perder parte ou todo o dinheiro investido em empresas através da plataforma {{company}}. Não há qualquer garantia de retorno do investimento efetuado. Somente invista em negócios publicados na plataforma {{company}} se você possui conhecimento suficiente para entender esses riscos e para tomar suas próprias decisões de investimento. Veja o nosso <a href="/risk" target="_blank">Aviso de Risco</a> antes de tomar uma decisão de investimento.

As sociedades empresárias de pequeno porte e as ofertas apresentadas nesta plataforma estão automaticamente dispensadas de registro pela Comissão de Valores Mobiliários - CVM.
A CVM não analisa previamente as ofertas.
As ofertas realizadas não implicam por parte da CVM a garantia da veracidade das informações prestadas, de adequação à legislação vigente ou julgamento sobre a qualidade da sociedade empresária de pequeno porte.
Antes de aceitar uma oferta leia com atenção as informações essenciais da oferta, em especial a seção de alertas sobre riscos.

A {{company}} disponibiliza canal de comunicação, por meio do endereço <a href="mailto:{{cvmMail}}">{{cvmMail}}</a>, no qual o seu encarregado pela proteção de dados pessoais receberá quaisquer requisições, solicitações, comunicações e/ou manifestações dos titulares de dados pessoais para exercício dos direitos estipulados na Lei 13.709/2018 (Lei Geral de Proteção de Dados), em consonância a sua <a href="/terms" target="_blank">Política de Privacidade e de Cookies</a>.`,
            },
          },
          continue: 'Continuar',
          cancel: 'Cancelar',
        },
        myCollection: {
          open: 'Abrir item',
          cancel: 'Cancelar',
          timeLeft: 'Tempo restante:',
          proceed: 'Clique aqui para prosseguir com o pagamento da sua compra',
          clickToOpen: 'Clique aqui para abrir sua compra',
          clickToClaim: 'Clique aqui para resgatar seu item',
          myCollection: 'MINHA COLEÇÃO',
          accessDetails:
            'Acesse e consulte os detalhes dos itens da sua coleção.',
          wantToProceed: 'Deseja prosseguir com a abertura da sua compra?',
          wantToProceedWithClaim:
            'Deseja prosseguir com o resgate do seu item?',
          collectionItem: {
            unitsNew: '{{count}} unidade',
            unitsNew_plural: '{{count}} unidades',
            unity: 'unidade',
            units: 'unidades',
            announcePrice: 'Valor anunciado',
            active: 'Anúncio ativo',
          },
          collectionItemDetails: {
            goBack: 'Voltar para meus itens',
            fileFormatImpossibleToView:
              'O formato de arquivo não pode ser visualizado. Por favor baixe o arquivo para visualizá-lo em seu dispositivo.',
            download: 'Baixar arquivo',
            itemDetails: 'Detalhes do item',
            owner: 'Proprietário',
            description: 'Descrição',
            noBalance: {
              title: 'Parece que você não tem este item na carteira',
              action: {
                backToMyCollection: 'Ver minha coleção',
              },
            },
            nftInfo: {
              blockchainRecords: 'Registros em Blockchain',
              metadate: 'Metadados do IPFS:',
              wallet: 'Carteira de {{name}}: ',
            },
            purchaseData: {
              itemDetails: 'Detalhes do item',
              date: 'Data da compra:',
            },
            enabledOffer: {
              title: 'Item com anúncio ativo!',
              description:
                'Atenção! Este item poderá ser vendido a qualquer momento',
              actions: {
                seeOffer: 'Ver anúncio',
                options: 'Opções',
                subActions: {
                  edit: 'Editar',
                  remove: 'Remover anúncio',
                },
              },
            },
            sellItem: 'Anunciar item',
            transferDisabled:
              'Para sua segurança, a transferência de NFTs para carteiras externas estará disponível 30 dias após a confirmação da compra. Seu NFT estará disponível em:',
            sellDisabledExplanation:
              'Item não disponível para venda. Aguarde o período de análise do seu último cartão de crédito cadastrado (período equivale a 30 dias desde a última compra com ele na plataforma). Assim que finalizado esse processo, você poderá vender o item!',
          },
          emptyCollection: {
            empty: 'Você ainda não possui itens na sua coleção.',
            goToStore: 'Ir para loja',
          },
          filter: {
            showAll: 'Todos os cards ({{amount}})',
            cardsOnSale: 'Anúncios ativos',
          },
        },
        openDrop: {
          close: 'Sair',
          openNow: 'Abrir agora',
          dropDetails: {
            success: 'Compra efetuada com sucesso!',
            recipient: 'Destinatário (você):',
            itemName: 'Nome do item:',
            value: 'Valor:',
          },
        },
        openingDrop: {
          title1: 'Criando certificado de origem...',
          message1:
            'Isso pode demorar um pouco mais do que o esperado. Se precisar, sugerimos que volte mais tarde para conferir a operação',
          title2: 'Compensando a pegada de carbono...',
          message2:
            'Nos preocupamos com a sustentabilidade por aqui. Por isso, nós compensamos toda pegada de carbono da produção dos itens da loja',
          title3: 'Transmitindo os dados...',
          message3:
            'Você pode visualizar todos os itens da sua coleção diretamente na blockchain. Se ficar com dúvidas sobre onde buscar os dados, visite nosso FAQ ou entre em contato',
          inProgress: {
            exit: 'Sair',
            opening: 'Abrindo item',
            claiming: 'Resgatando item',
          },
          modals: {
            errorModal: {
              myCollection: 'Minha Coleção',
              openingError: 'Algo deu errado na abertura do item.',
              tryAgain:
                'Por favor tente novamente mais tarde, ou entre em contato com a Rarum através de contact@rarum.io.',
              openingErrorInfoModal: 'Erro na abertura do item',
            },
            exitModal: {
              confirm: 'Confirmar',
              cancel: 'Cancelar',
              confirmToExit: 'Confirme para sair da abertura do item.',
              confirmToExitMessage:
                'Após o término do processo de carregamento os itens do item estarão disponíveis em Minha Coleção.',
              wantExit: 'Deseja sair?',
            },
            timeOutModal: {
              myCollection: 'VOLTAR À MINHA COLEÇÃO',
              waitMore: 'Aguardar mais',
              waitingMessage1:
                'Fique tranquilo(a), seus itens estarão disponíveis em sua coleção em breve.',
              waitingMessage2:
                'Por que essa demora pode acontecer? O processo de abertura é executado na blockchain e pode variar de acordo com o volume de transações na rede neste momento. Aguarde alguns minutos ou volte à sua coleção em breve.',
              timeOutMessage:
                'Oops! A abertura está demorando mais que o previsto!',
            },
          },
          claimed: {
            claimed: 'Seu item foi resgatado',
          },
          opened: {
            opened: 'Sua compra está disponível {{ packTitle }}',
            checkOut: 'Confira o item a seguir:',
            checkOut_plural: 'Confira os itens a seguir:',
            myCollection: 'Minha Coleção',
          },
        },
        payment: {
          exitProptMessage: 'Tem certeza que deseja cancelar a compra?',
          confirm: 'Confirmar',
          cancel: 'Cancelar',
          goBack: 'Voltar',
          purchaseData: 'Dados da compra',
          fillInTheData: 'Preencha os dados a seguir para concluir a compra.',
          confirmTheData: 'Confirmar os dados',
          continue: 'Continuar',
          problemMessage: 'Ocorreu um problema',
          wantExit: 'Deseja sair?',
          logic: {
            error:
              'Ocorreu um erro: verifique seus dados, ou tente novamente mais tarde',
          },
          data: {
            pix: 'Pix',
            creditCard: 'Cartão de crédito',
            cryptoCoin: 'Criptomoedas (somente rede Ethereum)',
            personalData: 'Dados pessoais',
            revision: 'Revisão',
            payment: 'Pagamento',
            personal: 'Pessoal',
            address: 'Endereço',
            coinbaseStep: 'Coinbase',
            nameIsMandatory: 'Nome é obrigatório',
            invalidEmail: 'E-mail inválido',
            emailIsMandatory: 'E-mail é obrigatório',
            cpf: 'cpf',
            invalidCpf: 'CPF inválido',
            invalidId: 'Documento Inválido',
            cep: 'cep',
            invalidCep: 'CEP inválido',
            stateIsMandatory: 'Estado é obrigatório',
            cityIsMandatory: 'Cidade é obrigatório',
            patioIsMandatory: 'Logradouro é obrigatório',
            numberIsMandatory: 'Número é obrigatório',
            confirmAndPay: 'Confirmar e Pagar',
            creditCardSubtitle:
              ' Preencha os dados de pagamento para efetuar a compra',
            countryIsMandatory: 'País é obrigatório',
            zipIsMandatory: 'Zip code é obrigatório',
          },
          dropInfoMobile: {
            purchaseDetails: 'Detalhes da compra',
          },
          review: {
            dataReview: 'Revise e confirme os dados da compra',
            holderData: 'Dados do titular para pagamento:',
            cpf: 'CPF',
            addressData: 'Dados do endereço',
            cep: 'CEP',
            formOfPayment: 'Forma de pagamento',
            title: 'Revise e confirme os dados da compra',
            description: `Ao confirmar os seus dados você será redirecionado para a tela de pagamento.
Em caso de erros nos dados abaixo, será necessário refazer a compra.`,
            useDataForFuture: 'Utilizar dados de cadastro para compras futuras',
          },
          safetyLabel: {
            safePurchase: 'Compra segura',
            safePurchaseBy: 'Compra segura via',
          },
          steps: {
            stepAddress: {
              addressData: 'Preencha os dados do endereço',
              cep: 'CEP',
              state: 'Estado',
              city: 'Cidade',
              patio: 'Rua/Avenida',
              number: 'Número',
              nonResident: 'Não resido no Brasil',
              postalCode: 'Código postal',
              country: 'País',
            },
            stepMethod: {
              selectFormOfPayment: 'Selecione a forma de pagamento',
              pixBRL: 'Pix',
              creditCard: 'Cartão de crédito',
              creditCardBRL: 'Cartão de Crédito (emitido no Brasil)',
              creditCardUSD: 'Cartão de Crédito (emitido no exterior)',
              creditCardAny: 'Credit Card ({{currency}})',
              cryptoBRL: 'Criptomoedas (somente rede Ethereum)',
              cryptoUSD: 'Criptomoedas (em dólares)',
              cryptoCoin: 'Criptomoedas (somente rede Ethereum)',
            },
            methodName: {
              'PIX-usd': 'PIX',
              'PIX-brl': 'PIX',
              'BOLETO_PIX-brl': 'Boleto',
              'BOLETO_PIX-usd': 'Boleto',
              'CREDIT_CARD-brl': 'Cartão de Crédito (emitido no Brasil)',
              'CREDIT_CARD-usd': 'Cartão de Crédito (emitido no exterior)',
              'CRYPTO-brl': 'Criptomoedas (somente rede Ethereum)',
              'CRYPTO-usd': 'Criptomoedas (em dólares)',
            } satisfies {
              [m in `${PaymentMethods}-${CurrenciesEnum}`]: string
            },
            stepReview: {
              dataReview: 'Revise e confirme os dados da compra',
              confirmMessage1:
                'Ao confirmar os seus dados você será redirecionado para a tela de pagamento.',
              confirmMessage2:
                'Em caso de erros nos dados abaixo, será necessário refazer a compra.',
              holderData: 'Dados do titular para a compra:',
              cpf: 'CPF',
              foreignerId: 'Identificação',
              edit: 'editar',
              addressData: 'Dados do endereço:',
              cep: 'CEP',
              formOfPayment: 'Forma de pagamento:',
              update: 'Atualizar dados de cadastro para compras futuras',
            },
            stepUserData: {
              personalData: 'Preencha os dados pessoais',
              name: 'Nome',
              cpf: 'CPF',
              foreignerId: 'Identificação',
              foreigner: 'Sou estrangeiro',
            },
          },
          finished: {
            title: 'O item já é quase seu.',
            description:
              'Estamos lhe encaminhando para a finalização do pagamento.',
            actions: {
              goNow: 'Ir agora',
            },
          },
        },
        pendingPayment: {
          expiredPayment: 'Pagamento expirado',
          expiredPurchase: 'Desculpe, esta compra expirou.',
          tryAgain: 'Por favor, tente realizar a compra novamente.',
          timeLeft: 'tempo restante:',
          myCollection: 'Minha Coleção',
          problem: 'Ocorreu um problema',
          payWithPix: 'Pague com o Pix',
          payWithCreditCard: 'Pague com o cartão de crédito',
          payWithCryptoCoin: 'Pague com criptomoedas',
          data: {
            mandatoryNumber: 'Número obrigatório',
            invalidNumber: 'Número inválido',
            mandatoryCode: 'Código obrigatório',
            invalidCode: 'Código inválido',
            mandatoryShelfLife: 'Validade obrigatória',
            invalidShelfLife: 'Validade inválida',
            nameIsMandatory: 'Nome é obrigatório',
          },
          methods: {
            creditCard: {
              creditCardData: 'Preencha os dados do cartão de crédito',
              creditCardNumber: 'Número do cartão',
              holderName: 'Nome do titular',
              securityCode: 'Código de segurança',
              shelfLife: 'Validade (mês/ano)',
              finishPurchase: 'Finalizar compra',
            },
            pix: {
              copyCode: 'Copiar código',
              pixCodeCopied: 'Código Pix copiado',
              success: 'Código Pix copiado com sucesso!',
            },
          },
          pixInfo: {
            howToPay: 'Como pagar com o Pix',
            qrCode:
              'Utilize o app do seu banco para escanear o código QR gerado ou copie o código.',
            checkOutData:
              'Confira os dados e confirme o pagamento pelo aplicativo do seu banco ou carteira digital.',
            waitingMessage:
              'Caso tudo esteja certo, seu pagamento será aprovado em alguns instantes.',
          },
        },
        transfer: {
          errors: {
            amountRequired: 'Quantidade é obrigatória',
            invalidAmount: 'Quantidade inválida',
            walletRequired: 'Um endereço de carteira é obrigatório',
            invalidWallet: 'Carteira inválida',
            unexpected:
              'Ocorreu um erro durante o processo. Por favor tente novamente, ou entre em contato conosco',
          },
          title: 'Transferir NFT',
          request: {
            ongoing: {
              loading: 'Uma transferência está finalizando, aguarde',
            },
            description:
              'Essa opção permite que você envie seu NFT para uma carteira externa. Isso fará com que seu item saia da Rarum e passe a ser controlado totalmente por essa carteira. Preencha os campos abaixo ou saiba mais no link a seguir.',
            more: 'Saiba mais sobre transferência do NFT',
            amount: {
              description:
                '1. Informe a quantidade dos itens que deseja transferir',
              label: 'Quantidade',
              of: 'de',
            },
            wallet: {
              description: '2. Informe o endereço da carteira de destino',
              label: 'Carteira',
            },
            warning:
              'AVISO: Para sua segurança, confirme se a carteira de destino aceita tokens no padrão ERC-1155 na rede {{name}}. Caso tokens sejam enviados para uma carteira que não aceita ERC-1155, você poderá perder o valor enviado.',
            confirm: {
              label: 'Confirmo que li e entendi os riscos exibidos acima',
              modal: {
                title: 'Iniciar transferência de NFT',
                description:
                  'Ao clicar em "Prosseguir", um e-mail será enviado para {{email}}, onde você confirmará a transferência de {{amount}} token{{plural}} para a carteira {{wallet}}.',
                proceed: 'Prosseguir',
                cancel: 'Cancelar',
                metamask: {
                  description:
                    'Ao clicar em "Prosseguir", será necessário aprovar a transferência no seu provedor de carteira. Lembre-se de que esta transferência requererá a utilização de GAS e você precisará ter MATIC em sua carteira para realizar a operação. Assim que confirmada, serão transferidos {{amount}} token{{plural}} para a carteira {{wallet}}.',
                  finished:
                    'A transferência de {{amount}} token{{plural}} para a carteira {{wallet}} foi concluída',
                  actions: {
                    close: 'Concluir',
                  },
                },
              },
            },
            submit: {
              label: 'Transferir',
            },
          },
          waiting: {
            title: 'Aguardando confirmação por e-mail',
            description:
              'Um e-mail foi enviado para {{email}} contendo um link para continuar com o processo de transferência.',
          },
          confirmation: {
            warning:
              'A transferência pode levar alguns minutos. Não é necessário aguardar o término, você receberá um e-mail comunicando o término do processo.',
            submit: 'Transferir',
            cancel: 'Cancelar',
            description: {
              part1:
                'Ao clicar em Confirmar, a transferência solicitada será realizada. O processo pode demorar alguns minutos.',
              part2:
                'Os tokens enviados ao endereço serão debitados da sua coleção assim que a transação for confirmada na blockchain.',
              part3: 'Esta operação não poderá ser desfeita.',
            },
          },
          finished: {
            overview: 'Seu NFT estará disponível em sua carteira',
            attention: 'Atenção:',
            waitTime:
              'É possível que o item ainda demore alguns minutos para entrar em sua conta, devido ao tempo de processamento da Blockchain (clique',
            here: 'aqui',
            forMore: 'para saber mais);',
            wheneverAvailable:
              'Quando disponível, você poderá visualizá-lo em sua',
            openseaAccount: 'conta OpenSea',
            submit: 'Confirmar transferência',
            finished: 'NFT transferido com sucesso',
          },
          pendingExternalTransfer: {
            subtitle: 'Você ainda não transferiu os saldos da sua conta',
            description: `Você conectou sua carteira, mas ainda não transferiu seus saldos da sua conta. Para fazê-lo, por favor, inicie o processo de migração clicando no botão abaixo. Você receberá um link em seu e-mail para confirmar a migração.
            Note que todos os saldos em sua conta Rarum serão transferidos, então por favor verifique se você tem acesso a sua carteira conectada. Uma vez transferidos, não poderemos movê-los em seu nome.`,
            actions: {
              goToTransfer: 'Iniciar migração',
            },
          },
        },
        waitingRoom: {
          descriptionMessage:
            'Falta pouco para você obter o item que você deseja. Essa sala é um espaço especial para você receber o link de compra assim que ele estiver disponível.',
          waitingRoom: 'Sala de Espera',
          eventStart: ' O evento terá início às 14:00',
          lastUpdate: 'Última atualização de status: 13:55 ',
          id: 'ID da fila: 785639418e2-40bft-655eea88ad08',
          exit: 'Sair',
          media: {
            waitingRoom: 'Sala de espera',
          },
        },
      },
      components: {
        offerRequirements: {
          regulation: {
            check:
              'Antes de {{offer_type_action}}, por favor, revise o regulamento desta oferta',
            checkTypes: {
              auction: 'participar do leilão',
              off_chain_auction: 'participar do leilão',
              onchain: 'comprar o pacote',
              pack: 'comprar o pacote',
              single: 'comprar o item',
              cvm88: 'investir',
            } satisfies {
              [k in OfferTypeParam]: string
            },
            action: {
              check:
                'Já revisei o regulamento e estou de acordo com as regras definidas',
              continue: {
                auction: 'Continuar para o leilão',
                off_chain_auction: 'Continuar para o leilão',
                onchain: 'Continuar para a compra',
                pack: 'Continuar para a compra',
                single: 'Continuar para a compra',
                cvm88: 'Continuar para o investimento',
              } satisfies {
                [k in OfferTypeParam]: string
              },
              viewRegulation: 'Ver regulamento',
            },
          },
        },
        offerPrice: {
          noPrice: '--',
        },
        offerCountdown: {
          remaningTime:
            'Tempo restante: {{days}}d {{hours}}h {{minutes}}min {{seconds}}s',
        },
        kycActionWrapper: {
          [KYCRequiredStatus.LOADING_KYC]: 'Processando KYC',
          [KYCRequiredStatus.PENDING_KYC]: 'Realizar KYC',
          [KYCRequiredStatus.ERROR_KYC]: 'Falha no KYC, contate suporte',
          [KYCRequiredStatus.AUTHENTICATE_REQUIRED]: 'Entrar',
        },
        offerAuctionRace: {
          initialBid: 'Lance inicial',
          lastBid: 'Último lance',
          bid: 'Fazer um lance',
          actions: {
            yourLastBid: 'Seu lance está vencendo',
          },
          result: {
            status: {
              [ClaimAuctionStatus.CLAIMABLE]: 'Resgatar',
              [ClaimAuctionStatus.NOT_WINNER]: 'O item já foi vendido',
              [ClaimAuctionStatus.PENDING_ALLOWANCE]: 'Aprovar',
              [ClaimAuctionStatus.CLAIMED]: 'Você já resgatou o item',
              [ClaimAuctionStatus.DISCONNECTED_WALLET]: 'Conectar carteira',
            } satisfies {
              [k in Exclude<
                ClaimAuctionStatus,
                ClaimAuctionStatus.LOADING
              >]: string
            },
          },
        },
        offerAuctionBidList: {
          totalBids: 'Total de lances',
          bids: 'Lances',
          date: 'Data',
          user: 'Meus lances',
          wallet: 'Carteira',
          myBid: 'Meu lance',
        },
        offerAuctionPhoneConfirmation: {
          confirmPhone: 'Confirmação de telefone',
          whyConfirmPhone:
            'Para sua segurança e confirmação do lance é necessário validar o seu telefone. Siga os passos a seguir para continuar com o lance.',
          phoneInputLabel: '1. Insira o seu número de celular',
          sendCodeViaSMS: 'Enviar código por SMS',
          informCodeLabel: '2. Informe o código de verificação',
          whyInformCode:
            'Informe o código de 6 dígitos que você recebeu por SMS',
          errors: {
            invalidConfirmationCode: 'Código de validação incorreto',
            genericError: 'Algo deu errado',
            action: {
              tryAgain: 'Tente novamente',
            },
          },
          actions: {
            cancel: 'Cancelar',
            validateAndContinue: 'Validar e continuar',
          },
        },
        offerAuctionBidModal: {
          bidSettleConfirmation: {
            onChain: {
              title: 'Definir lance',
              approveTokens: 'Aprovar movimentação dos tokens',
              confirmBid: 'Concluir lance',
              disclaimer:
                'Eu concordo com os termos e condições e estou disposto a provar minha identidade em caso de ser declarado como vencedor',
              errors: {
                insufficientTokenBalance:
                  'The amount {{amount}} is higher than the available balance ({{available}})',
              },
              actions: {
                approve: 'Aprovar',
                confirm: 'Confirmar',
              },
            },
          },
          newBid: 'Novo lance',
          bidAmount: 'Insira o valor do seu lance',
          instruction:
            'O valor do lance precisa ser maior do que {{min_pct}}% e menor que {{max_pct}}% em relação ao último lance.',
          phone: 'Insira o seu número de celular',
          itemDetail: 'Detalhes do item',
          minimumBid: 'Lance mínimo',
          tipMinimumAndMaximumBid:
            'Lance minimo de {{min}} e máximo de {{max}}',
          lowerThanMin: 'Lance não pode ser menor que o mínimo ({{min}})',
          biggerThanMax: 'Lance é maior que o permitido ({{max}})',
          required: 'O valor do lance é obrigatório',
          incorrectPhone: 'Telefone fora do padrão',
          currentBid: 'Lance atual',
          initialBid: 'Lance inicial',
          confirmBidTitle: 'Confirme o lance',
          youtBid: 'Seu lance',
          agreeWithTerms:
            'Confirmo que li e estou de acordo com as regras do leilão',
          success: 'Lance efetuado com sucesso!',
          congratulation:
            'Parabéns! Você pode acompanhar a evolução do leilão pela tela principal do item.',
          error: 'Algo deu errado.',
          tryLater:
            'Houve um erro durante o processamento do seu lance. Aguarde um momento ou tente novamente mais tarde.',
          gotIt: 'Entendi',
          continue: 'Continuar',
          cancel: 'Cancelar',
          errorMap: {
            failed_sequential_bid:
              'Usuários não podem enviar múltiplos lances em sequência, é necessário aguardar um outro usuário enviar um lance para enviar outro',
          },
        },
        approvalStatus: {
          approved: 'Aprovado',
          approvedTooltip:
            'Você aprovou saldo suficiente para efetuar a compra de ao menos um NFT.',
          notApproved: 'Aguardando aprovação',
          notApprovedTooltip:
            'Ante de efetuar a compra você precisa approvar o consumo do saldo da sua carteira.',
        },
        footer: {
          view: {
            sustainableNFT: 'Colecionáveis ​​NFT Sustentáveis',
            oneAndMoss: 'Feito com 🤘🏾 pela OnePercent.',
            officialProduct:
              'Produto oficial licenciado, todos os direitos reservados.',
          },
          data: {
            collection: 'Coleção',
            termOfUse: 'Termos de uso e politica de privacidade',
            conductCode: 'Código de conduta',
            material: 'Material didático',
          },
        },
        availability: {
          remaining: 'restam',
          units: 'unidades',
        },
        topBar: {
          signUp: 'CADASTRE-SE',
          signIn: 'ACESSAR CONTA',
          myData: 'Meus dados',
          myCollection: 'Minha Coleção',
          marketplace: 'Mercado',
          exit: 'Sair',
          data: {
            packages: 'Loja',
            cards: 'Itens',
          },
        },
        certificate:
          'Cada item na plataforma tem seu consumo de carbono (emissões de dióxido de carbono, que são extremamente poluentes, geradas durante a produção e entrega dos itens) automaticamente compensado, utilizando Carbon-Pegged Crypto Tokens.',
        rarumHeader: {
          whatIsRarum: 'O que é a Rarum?',
        },
        chip: {
          chipLabelSoon: 'em breve!',
          chipLabelSale: 'compre agora!',
          chipLabelSaleFinished: 'venda finalizada',
          dateScheduled: '{{day}} de {{month}} às {{hours}}',
          dateExpired: '{{day}} de {{month}}',
          of: 'de',
          at: 'às',
        },
        credentials: {
          agree: 'Eu concordo com o',
          serviceTerms: 'Termos de serviço',
        },
        dropCard: {
          isOn: 'ESTÁ LIGADO',
          details: 'DETALHES',
        },
        dropUnavailable: {
          notAvailable: 'Este item não está mais disponível.',
        },
      },
      core: {
        constants: {
          regulament: '',
          rarumDescription: [
            'A Rarum é uma plataforma para colecionáveis digitais que permite que marcas possam ter um espaço próprio para contato com seu público. Nossa plataforma gerencia desde a publicação dos itens até o processo de compra feito pelos fãs.',
            'Cada item na plataforma tem seu consumo de carbono (emissões de dióxido de carbono, que são extremamente poluentes, geradas durante a produção e entrega dos itens) automaticamente compensado, utilizando Carbon-Pegged Crypto Tokens.',
            'Nossa plataforma também se responsabiliza pela segurança dos seus dados e dos seus ativos. Por esse motivo, usamos a tecnologia blockchain para garantir a validade dos itens comercializados na plataforma. Cada item gerado corresponde a um registro único, que vale como uma espécie de certificado de origem, com uma assinatura criptográfica de quem criou cada item. Dessa forma, você pode ter certeza que colocamos o que há de mais moderno para certificar que os dados são válidos e as transações são seguras.',
            'Caso queira saber mais sobre a rarum, você pode entrar em contato conosco pelo e-mail contact@rarum.io',
          ],
          dataDeletionInstructions: [
            'Se quiser excluir seus dados de nossos servidores, você pode removê-las seguindo as instruções a seguir:',
            '1. Vá em "Configurações e privacidade" > "Configurações";',
            '2. Em seguida vá em "Aplicativos e sites";',
            '3. Remova "Rarum" dos seus aplicativos;',
            '4. Envie um e-mail com assunto "DELETAR MEUS DADOS" para contact@onepercent.io a partir do e-mail com o qual você criou a sua conta. Seus dados serão removidos de nossos servidores dentro de 1 dia útil.',
          ],
          externalEnvironmentAlert:
            'Você será redirecionado para um ambiente externo, do provedor de pagamentos parceiro da Rarum. Siga as instruções do meio de pagamento selecionado na próxima tela.',
          shareText: 'Momentos memoráveis agora são colecionáveis',
          shareTitle: 'Rarum - NFTs Colecionáveis',
          longDateFormat: "EEEE, dd 'de' MMMM 'às' H:mm",
          scheduledDateFormat: "dd MMM 'às' H:mm",
          expiredDateFormat: "dd 'de' MMMM",
        },
      },
    },
  },
}

export type TranslationShape = (typeof messages)['pt-BR']
export { messages }
