import useRouteGalleryOrDefault from 'openspace/hooks/useRoutePathGallery'
import { createContext, PropsWithChildren, useContext, useMemo } from 'react'

type FeaturesContextShape = {
  kyc: boolean
  hasGalleries: boolean
} & Required<Required<EntityFeatureFlag['features']>>

/** Enabled granular control over globlal features inside a tenant/gallery */
export type EntityFeatureFlag = {
  features?: {
    marketplace?: boolean
    challenges?: boolean
  }
}

export const FeatureContext = createContext<FeaturesContextShape>(null as any)

export function getFeatures(): FeaturesContextShape {
  return {
    marketplace: false,
    challenges: false,
    kyc: !!process.env.REACT_APP_METAMAP_CONFIG,
    hasGalleries:
      String(process.env.REACT_APP_FEATURE_HAS_GALLERIES) === 'true',
  }
}

export default function FeatureProvider({ children }: PropsWithChildren<{}>) {
  const featureMap = useMemo(() => getFeatures(), [])
  const galleryForRoute = useRouteGalleryOrDefault()

  const features = useMemo(() => {
    if (!galleryForRoute) return featureMap
    else {
      const entityFlag = galleryForRoute.features
      if (entityFlag) {
        const flags = Object.keys(entityFlag)
        for (const _flag of flags) {
          const flag = _flag as keyof EntityFeatureFlag['features']
          featureMap[flag] = entityFlag[flag]
        }
      }
      return {
        ...featureMap,
      }
    }
  }, [galleryForRoute, featureMap])

  return (
    <FeatureContext.Provider value={features}>
      {children}
    </FeatureContext.Provider>
  )
}

export function useFeatures() {
  return useContext(FeatureContext)
}
