import { usePersistentAddress } from 'core/logic/wallet'
import {IdenticonView} from './Identicon.view'

export type IdenticonProps = {
  diameter?: number
}

export const Identicon: React.FC<IdenticonProps> = ({diameter}) => {
  const {address} = usePersistentAddress()

  return <IdenticonView address={address} diameter={diameter} />
}
