import { makeStyles } from "@material-ui/core";

const useDropStepperStyles = makeStyles(theme => ({
    float: {
        paddingLeft: "24px",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: theme.palette.background.paper,
        paddingTop: theme.spacing(3),
        "& > h3": {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        paddingBottom: theme.spacing(3),
    }
}));

export default useDropStepperStyles;