/* eslint-disable react-hooks/rules-of-hooks */
import React, {
  ElementRef,
  Fragment,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useInsertionEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  AnswerAction,
  AnswerByField,
  FormFieldView,
  FormMode,
  FormStep,
  FormViewProps,
} from './Form.types'
import Styles from './Form.module.scss'
import Text from 'openspace/components/Text/Text'
import { FigmaTypo } from 'containers/OneUIProvider/OneUIProvider.data'
import Spacing from '@onepercentio/one-ui/dist/components/Spacing/Spacing'
import OneText from '@onepercentio/one-ui/dist/components/Text'
import Select from '@onepercentio/one-ui/dist/components/Select/Select'
import UncontrolledTransition from '@onepercentio/one-ui/dist/components/UncontrolledTransition'
import { t, tO } from 'translate/i18n'
import Input from '@onepercentio/one-ui/dist/components/Input'
import Button from '@onepercentio/one-ui/dist/components/Button'
import Radio from '@onepercentio/one-ui/dist/components/Radio'
import InputMask from 'react-input-mask'
import useModule from '@onepercentio/one-ui/dist/hooks/utility/useModule'
import { AnchoredTooltipAlignment } from '@onepercentio/one-ui/dist/components/AnchoredTooltip/AnchoredTooltip'
import { currencyFormatterFactory } from 'core/helpers/formatter'
import AddressFormLogic from 'features/Form/AddressForm/AddressForm.logic'
import {
  Address,
  AddressFormMode,
} from 'features/Form/AddressForm/AddressForm.types'
import FileInput from '@onepercentio/one-ui/dist/components/FileInput/FileInput'
import { debounce } from 'lodash'
import IconRarum from 'components/IconRarum/IconRarum.view'
import CheckBox from '@onepercentio/one-ui/dist/components/CheckBox/CheckBox'
import { TransitionAnimationTypes } from '@onepercentio/one-ui/dist/components/Transition/Transition'
import { downloadLink } from 'utility/file'
import Card from 'components/Card/Card'
import { getCVMDocumentLink } from 'core/modules/firebase/service'
import SectionLoader from 'openspace/components/SectionLoader/SectionLoader'
import Step from './Step'
import { StepsWrapper } from './Step/Step'
import { CVM_FORM_TEST_IDS } from './Form.e2e'
import AdaptiveContainer from '@onepercentio/one-ui/dist/components/AdaptiveContainer'

const Collapsable = ({
  children,
  hidden,
}: PropsWithChildren<{ hidden: boolean }>) => (
  <AdaptiveContainer direction='v'>
    {hidden ? (
      <Fragment key='hidden'></Fragment>
    ) : (
      <Fragment key='visible'>{children}</Fragment>
    )}
  </AdaptiveContainer>
)

export function FormFieldInput<Q extends FormFieldView>({
  config: c,
  onAnswer,
  value,
  error,
  mode,
  readOnlyView = 'card',
}: {
  config: Q
  value: AnswerByField<Q>
  error?: string | string[]
  onAnswer: <T extends Q['type']>(
    questionType: T,
    questionId: string,
    answer:
      | AnswerAction<{
          type: T
        }>
      | undefined
  ) => void
  mode: FormMode
  readOnlyView?: 'card' | 'short'
}) {
  const val = <T extends FormFieldView['type']>() =>
    value as unknown as
      | AnswerByField<{
          type: T
        }>
      | undefined
  const ans = <T extends FormFieldView['type']>(
    answer:
      | AnswerAction<{
          type: T
        }>
      | undefined
  ) =>
    answer as unknown as AnswerAction<{
      type: T
    }>
  const type = c.type
  const { Wrapper, textCls, titleCls } = useMemo(() => {
    switch (readOnlyView) {
      case 'card':
        return {
          Wrapper: Card,
          titleCls: FigmaTypo.H4,
          textCls: FigmaTypo.P16,
        } as const
      case 'short':
        return {
          Wrapper: ({ children }: PropsWithChildren) => children,
          titleCls: 'p12',
          textCls: 'p16',
        } as const
    }
  }, [readOnlyView])
  if (mode === FormMode.READ_ONLY) {
    switch (type) {
      case 'check':
        const checks = val<typeof type>()! || []
        return (
          <>
            <Wrapper>
              <OneText type={titleCls}>{c.title}</OneText>
              {checks.includes(true) ? (
                checks.map((checked, i) =>
                  checked ? (
                    <OneText type={textCls}>{c.options[i].label}</OneText>
                  ) : null
                )
              ) : (
                <OneText type={textCls}>-</OneText>
              )}
            </Wrapper>
            <Spacing size='16' />
          </>
        )
      case 'radio':
        const selectedRadio = val<typeof type>()! || ''
        return (
          <>
            <Wrapper>
              <OneText type={titleCls}>{c.title}</OneText>
              {selectedRadio ? (
                <OneText type={textCls}>
                  {c.options.find((el) => el.value === selectedRadio)!.label}
                </OneText>
              ) : (
                '-'
              )}
            </Wrapper>
            <Spacing size='16' />
          </>
        )

      case 'address':
        const [address] = val<typeof type>()! || [{}]
        return (
          <>
            <AddressFormLogic
              address={address}
              mode={AddressFormMode.READ_ONLY}
            />
            <Spacing size='16' />
          </>
        )
      case 'file':
        const file = val<typeof type>()!
        const firstExtensions = c.extensions
          .slice(0, c.extensions.length - 1)
          .map((a) => a.toUpperCase())
          .join(',')
        const lastExtension = c.extensions.slice(-1)[0]
        return (
          <>
            <FileInput
              states={{
                fileProvided: {
                  title: c.title,
                  description: tO(
                    `cvm88.form.disclaimers.${c.id as 'identification'}`
                  ),
                },
                waitingFile: {
                  title: c.title,
                  description: tO(
                    `cvm88.form.disclaimers.${c.id as 'identification'}`
                  ),
                },
              }}
              footer={`${firstExtensions} ${t(
                'generic.or'
              )} ${lastExtension.toUpperCase()}`}
              onFile={(file) => {
                onAnswer(type, c.id, ans<typeof type>(file))
              }}
              file={file === true ? ({} as File) : undefined}
              progress={file === true ? 100 : undefined}
              disabled={true}
            />
            <Spacing size='16' />
            {file === true && (
              <Button
                variant='transparent-link'
                color='primary'
                onClick={() => {
                  getCVMDocumentLink(c.id as 'document').then((link) => {
                    window.open(link, '_blank')
                  })
                }}>
                <IconRarum icon='download' size={24} />
                &nbsp;&nbsp;&nbsp;
                {tO('cvm88.form.sections.documents.actions.seeDocument')}
              </Button>
            )}
            <Spacing size='32' />
          </>
        )
      case 'select':
        const selectedOption = val<typeof type>()
        return (
          <>
            <Wrapper>
              <OneText type={titleCls}>{c.title}</OneText>
              <OneText type={textCls}>
                {tO(selectedOption as any) || '-'}
              </OneText>
            </Wrapper>
            <Spacing size='16' />
          </>
        )
      default:
        const answer = val<typeof type>()
        return (
          <>
            <Wrapper>
              <OneText type={titleCls}>{c.title}</OneText>
              <OneText type={textCls}>{answer || '-'}</OneText>
            </Wrapper>
            <Spacing size='16' />
          </>
        )
    }
  }
  switch (type) {
    case 'accept':
      const checks = val<typeof type>() || []
      return (
        <>
          <div className={Styles.acceptInput}>
            <div>
              <OneText type={FigmaTypo.H4}>{c.title}</OneText>
              <br />
              <Button
                variant='transparent-link'
                color='primary'
                onClick={() => {
                  switch (c.link.type) {
                    case 'file':
                      downloadLink(c.link.to, c.title)
                      break
                    case 'route':
                      window.open(c.link.to, '_blank')
                      break
                  }
                }}>
                <span>
                  <IconRarum icon='file' />
                </span>
                {c.link.type === 'file'
                  ? tO('cvm88.form.sections.investmentAmount.download', {
                      type: c.title,
                    })
                  : tO('cvm88.form.sections.investmentAmount.goTo', {
                      type: c.title,
                    })}
              </Button>
            </div>
            {error && (
              <div>
                <OneText type='caption' color='error'>
                  {error}
                </OneText>
                <br />
              </div>
            )}
            <div>
              {c.accept.map((a, i) => (
                <OneText type={FigmaTypo.P16}>
                  <CheckBox
                    label={
                      <span>
                        <>
                          {a.label}
                          {a.optional ? (
                            <>
                              <br />
                              <OneText type='caption'>
                                {tO('cvm88.form.optional')}
                              </OneText>
                            </>
                          ) : (
                            ''
                          )}
                        </>
                      </span>
                    }
                    checked={checks[i]}
                    onToggle={(checked) => {
                      const clone = [...checks]
                      clone[i] = checked
                      onAnswer(type, c.id, ans<typeof type>(clone))
                    }}
                    groupId={c.id}
                    value={a.label}
                  />
                </OneText>
              ))}
            </div>
          </div>
          <Spacing size='16' />
        </>
      )
    case 'file':
      const upload = val<typeof type>()
      const firstExtensions = c.extensions
        .slice(0, c.extensions.length - 1)
        .map((a) => a.toUpperCase())
        .join(',')
      const lastExtension = c.extensions.slice(-1)[0]
      const [progress, setProgress] = useState<number | undefined>(() => {
        if (!upload) return undefined
        if (upload === true) return 100
        const s = upload.snapshot
        return (s.bytesTransferred * 100) / s.totalBytes
      })
      useEffect(() => {
        if (!upload) return setProgress(undefined)
        if (upload === true) {
          setProgress(100)
          return
        }
        const throttleSetProgress = debounce(setProgress, 250)
        const unsubscribe = upload.on('state_changed', (s) => {
          const progress = (s.bytesTransferred * 100) / s.totalBytes
          throttleSetProgress(progress)
        })

        return () => {
          unsubscribe()
        }
      }, [upload])
      return (
        <>
          <FileInput
            states={{
              fileProvided: {
                title: c.title,
                description: tO(
                  `cvm88.form.disclaimers.${c.id as 'identification'}`
                ),
              },
              waitingFile: {
                title: c.title,
                description: tO(
                  `cvm88.form.disclaimers.${c.id as 'identification'}`
                ),
              },
            }}
            footer={`${firstExtensions} ${t(
              'generic.or'
            )} ${lastExtension.toUpperCase()}`}
            onFile={(file) => {
              onAnswer(type, c.id, ans<typeof type>(file))
            }}
            file={upload as unknown as File}
            progress={upload ? progress || 0 : undefined}
            accept={c.extensions.map((x) => `.${x}`).join(',')}
          />
          {error ? (
            <OneText type='caption' color='error'>
              {error}
            </OneText>
          ) : null}
          <Spacing size='32' />
        </>
      )
    case 'radio':
      const selected = val<typeof type>()
      return (
        <>
          <OneText type='p12'>{c.title}</OneText>
          {error && (
            <OneText type='caption' color='error'>
              {error}
            </OneText>
          )}
          <br />
          {c.options.map((el) => (
            <>
              <OneText type={FigmaTypo.P16}>
                <Radio
                  checked={selected === el.value}
                  label={el.label}
                  onToggle={() => onAnswer(type, c.id, el.value)}
                  groupId={c.id}
                  value={el.value}
                />
              </OneText>
              <br />
            </>
          ))}
          {c.footer && selected && (
            <>
              <UncontrolledTransition
                transitionType={TransitionAnimationTypes.COIN_FLIP}>
                {c.footer(selected as string)}
              </UncontrolledTransition>
              <br />
            </>
          )}
        </>
      )
    case 'check':
      const checkError =
        typeof error === 'string' ? c.options.map(() => error) : error
      const checkmarks = val<typeof type>() || []
      return (
        <>
          <OneText type='p12'>{c.title}</OneText>
          <br />
          {c.options.map((el, i) => (
            <>
              <OneText type={FigmaTypo.P16}>
                <CheckBox
                  checked={checkmarks[i]}
                  label={
                    <>
                      {el.label}
                      {checkError?.[i] ? (
                        <OneText type='caption' color='error'>
                          {checkError[i]}
                        </OneText>
                      ) : null}
                    </>
                  }
                  onToggle={(checked) => {
                    const clone = [...checkmarks]
                    clone[i] = checked
                    onAnswer(type, c.id, clone)
                  }}
                  groupId={c.id}
                  value={el.value}
                />
              </OneText>
              <br />
            </>
          ))}
        </>
      )
    case 'rawcheck':
      const rawCheckmarks = val<typeof type>() || []
      return (
        <>
          {error && (
            <>
              <OneText type='caption' color='error'>
                {error}
              </OneText>
              <br />
            </>
          )}
          {c.options.map((el, i) => (
            <>
              <Card className={Styles.card}>
                {el.header && (
                  <>
                    <Text code={el.header.title} type={FigmaTypo.H4} />
                    <Text code={el.header.description} type={FigmaTypo.P14} />
                    <Spacing size='32' />
                  </>
                )}
                <OneText type={FigmaTypo.P16}>
                  <CheckBox
                    checked={rawCheckmarks[i]}
                    label={el.label}
                    onToggle={(checked) => {
                      const clone = [...rawCheckmarks]
                      clone[i] = checked
                      onAnswer(type, c.id, clone)
                    }}
                    groupId={c.id}
                    value={el.value}
                  />
                </OneText>
              </Card>
              <br />
            </>
          ))}
        </>
      )
    case 'address':
      return (
        <AddressFormLogic
          address={val<typeof type>()?.[0] || ({} as Address)}
          onAddress={(address, isComplete, errors) => {
            onAnswer(
              type,
              c.id,
              ans<typeof type>([
                address,
                isComplete,
                !isComplete ? t('generic.fieldRequired') : false,
              ])
            )
          }}
          mode={AddressFormMode.WRITE}
          showErrors={!!error}
        />
      )
    case 'currency':
      const formatter = useMemo(() => currencyFormatterFactory('BRL'), [])
      const amount = val<typeof type>()
      const number = useMemo(
        () => Number(amount?.replace(/[^0-9]/g, '') || 0) / 100,
        [amount]
      )
      const moneyFormat = useMemo(() => {
        return formatter.format(number)
      }, [number, formatter])

      const lastPosition = useRef(0)
      const inputRef = useRef<HTMLInputElement>(null)
      useLayoutEffect(() => {
        const el = inputRef.current!
        const putCaretOn = el.value.length - lastPosition.current
        el.setSelectionRange(putCaretOn, putCaretOn)

        onAnswer(type, c.id, ans<typeof type>(moneyFormat))
      }, [moneyFormat])

      return (
        <>
          <OneText type='p12'>{c.title}</OneText>
          <Input
            ref={inputRef}
            placeholder={c.title}
            value={moneyFormat}
            error={error as string}
            onChange={({
              target: { value, selectionStart = value.length },
            }) => {
              const diffFromEndToStart = value.length - selectionStart!
              lastPosition.current = diffFromEndToStart
              onAnswer(type, c.id, ans<typeof type>(value))
            }}
          />
        </>
      )
    case 'select':
      return (
        <>
          <OneText type='p12'>{c.title}</OneText>
          <Select
            data-testid={CVM_FORM_TEST_IDS.SELECT(c.id)}
            alignTo={AnchoredTooltipAlignment.LEFT}
            items={c.options}
            selected={val<typeof type>()!}
            onClick={(i) => {
              onAnswer(type, c.id, i.value)
            }}
            filter={c.filter}
            error={error as string}
          />
        </>
      )
    case 'date':
      return (
        <>
          <OneText type='p12'>{c.title}</OneText>
          <InputMask
            mask={'99/99/9999'}
            value={val<typeof type>()}
            onChange={({ target: { value } }) => {
              const answer = ans<typeof type>(value)
              onAnswer(type, c.id, answer)
            }}>
            {
              ((inputProps: any) => (
                <Input {...inputProps} placeholder={c.title} error={error} />
              )) as unknown as ReactNode
            }
          </InputMask>
        </>
      )
    case 'phone':
      const [phone] = val<typeof type>() || ['', false]
      const phoneFormatter = useModule(() =>
        import('google-libphonenumber').then((mod) => {
          return mod.PhoneNumberUtil.getInstance()
        })
      )
      const {
        phoneFormat,
        valid,
        error: phoneError,
      } = useMemo(() => {
        const country = 'BR'
        if (!phoneFormatter)
          return {
            phoneFormat: phone,
            valid: false,
            error: '',
          }
        try {
          const phoneInstance = phoneFormatter.parseAndKeepRawInput(
            phone,
            country
          )
          const isPhoneValid = phoneFormatter.isValidNumber(phoneInstance)
          return {
            phoneFormat: phoneFormatter.format(phoneInstance, 2),
            valid: isPhoneValid,
            error: isPhoneValid ? '' : t('generic.invalidPhone'),
          }
        } catch (e) {
          return {
            phoneFormat: phone,
            valid: false,
            error: t('generic.invalidPhone'),
          }
        }
      }, [phone, phoneFormatter])

      useEffect(() => {
        onAnswer(type, c.id, ans<typeof type>([phoneFormat, valid, phoneError]))
      }, [phoneFormat, valid])

      return (
        <>
          <OneText type='p12'>{c.title}</OneText>
          <Input
            placeholder={c.title}
            value={phoneFormat}
            onChange={({ target: { value } }) => {
              onAnswer(type, c.id, ans<typeof type>([value, false, phoneError]))
            }}
            error={error as string}
          />
        </>
      )
    case 'text':
    case 'number':
      const filter =
        type === 'number'
          ? (val: string) => val.replace(/[^0-9]+/g, '')
          : (val: string) => val
      return (
        <>
          <OneText type='p12'>{c.title}</OneText>
          {type === 'text' && c.mask ? (
            <InputMask
              mask={c.mask}
              value={val<typeof type>()}
              onChange={({ target: { value } }) => {
                const answer = ans<typeof type>(value)
                onAnswer(type, c.id, answer)
              }}>
              {
                ((inputProps: any) => (
                  <Input {...inputProps} placeholder={c.title} error={error} />
                )) as unknown as ReactNode
              }
            </InputMask>
          ) : (
            <Input
              placeholder={c.title}
              value={val<typeof type>()}
              error={error as string}
              onChange={({ target: { value } }) => {
                onAnswer(type, c.id, filter(value))
              }}
              data-testid={CVM_FORM_TEST_IDS.QUESTION(c.id as any)}
            />
          )}
        </>
      )
    default:
      throw new Error('Not implemented yet')
  }
}

/**
 * The form for the user to upload CVM88 information
 **/
export default function FormView({
  headings,
  sections,
  step,
  onBack,
  next,
  answers,
  onAnswer,
  errors,
}: FormViewProps) {
  if (step === undefined) return <SectionLoader />

  const [showErrors, setShowErrors] = useState(false)
  useEffect(() => {
    setShowErrors(false)
  }, [step])

  const errorsToShow = useMemo(() => {
    const requiredText = t('generic.fieldRequired')
    if (showErrors) return errors
    else
      return Object.entries(errors)
        .filter(([_, errorText]) => errorText !== requiredText)
        .reduce(
          (map, [k, v]) => ({
            ...map,
            [k]: v,
          }),
          {}
        )
  }, [showErrors, errors])

  return (
    <>
      <Text type={FigmaTypo.H2} code='cvm88.form.title' />
      <Spacing size='16' />
      <StepsWrapper currStep={step} steps={headings.length}>
        {headings.map((props, idx) => (
          <Step {...props} stepNumber={idx + 1} key={String(idx)} />
        ))}
      </StepsWrapper>
      <Spacing size='32' />
      <FormQuestions
        sections={sections}
        onAnswer={onAnswer}
        answers={answers}
        errors={errorsToShow}
        mode={FormMode.WRITE}
        step={step}
      />
      <div className={Styles.actions}>
        <Button key='action' variant='outline' color='primary' onClick={onBack}>
          {t('generic.goBack')}
        </Button>
        <Button
          variant='filled'
          color='primary'
          onClick={() => {
            if (next.disabled) setShowErrors(true)
            else next.action()
          }}>
          {next.label}
        </Button>
      </div>
    </>
  )
}

export function FormQuestions({
  sections,
  onAnswer,
  errors,
  answers,
  mode,
  step,
}: Pick<FormViewProps, 'sections' | 'onAnswer' | 'errors' | 'answers'> & {
  mode: FormMode
  step: FormStep | number
}) {
  const key = useMemo(
    () => Math.random().toFixed(10),
    [sections.map((s) => s.title).join('')]
  )
  const unRef = useRef<ElementRef<typeof UncontrolledTransition>>(null)
  const prevStep = useRef<number>()
  useInsertionEffect(() => {
    if (prevStep.current)
      if (prevStep.current > step) unRef.current!.setOrientation('backward')
      else unRef.current!.setOrientation('forward')
    return () => {
      prevStep.current = step
    }
  }, [step])

  return (
    <UncontrolledTransition ref={unRef} className={Styles.transitioner}>
      <Fragment key={key}>
        {sections.map(({ title, questions, description }) => (
          <section
            className={`${Styles.formSection} ${Styles[FormMode[mode]] || ''}`}>
            <OneText type={FigmaTypo.H3}>{title}</OneText>
            {description && (
              <>
                <br />
                <OneText type={FigmaTypo.P16}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: description,
                    }}
                  />
                </OneText>
              </>
            )}
            <Spacing size='32' />
            <div>
              {questions.map((q) => {
                const Wrapper = 'hidden' in q ? Collapsable : Fragment

                return (
                  <Wrapper key={q.id} hidden={q.hidden!}>
                    <FormFieldInput
                      config={q}
                      onAnswer={onAnswer as any}
                      value={answers[q.id] as any}
                      error={errors[q.id]}
                      mode={q.readonly ? FormMode.READ_ONLY : mode}
                      readOnlyView={q.readonly ? 'short' : 'card'}
                    />
                  </Wrapper>
                )
              })}
            </div>
          </section>
        ))}
      </Fragment>
    </UncontrolledTransition>
  )
}
