import Container from '@material-ui/core/Container'
import SimpleTopBar from 'components/SimpleTopBar'
import React from 'react'
import useStyles from './Connect.style'

const ConnectView = ({ children }: React.PropsWithChildren<{}>) => {
  const classes = useStyles()
  return (
    <>
      <SimpleTopBar />
      <main className={classes.root}>
        <Container maxWidth='md'>
          <>{children}</>
        </Container>
      </main>
    </>
  )
}

export default ConnectView
