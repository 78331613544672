import Rare from 'assets/rarity/rare.png'
import { Rarity } from './DropImage.types'

type RarityMapping = { [key in Rarity]: string }

export const RARITY_OVERLAY: RarityMapping = {
  rare: Rare,
  epic: Rare,
  legendary: Rare,
}

export const DEFAULT_KEEP_PROPORTIONS = true
