import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) =>
  createStyles({
    divider: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    title: {
      marginBottom: theme.spacing(3),
      fontWeight: 700,
    },
    line: {
      marginBottom: theme.spacing(4),
    },
    description: {
      '& img, & video': {
        maxWidth: '100%',
      },
    },
  })
export default makeStylesWrapper(style)
