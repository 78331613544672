import { ChallengeDTO } from 'core/logic/challenges/challenges.types'

export default class Challenge {
  id!: string
  title!: string
  description!: string
  requirements!: {
    amount: number
    assetId: string
  }[]
  output!: {
    assetId: string
  }
  cover!: string
  rules!: string
  requirementsText!: string
  recipeId!: string
  galleryId: ChallengeDTO['galleryId']
  status!: ChallengeDTO['status']
  highlight!: boolean

  static fromChallengeDTO(
    data: ChallengeDTO,
    language: LanguageCodes
  ): Challenge {
    return {
      recipeId: data.blockchain.recipeId,
      cover: data.metadata.imageUrl,
      title: data.metadata.language[language].title,
      description: data.metadata.language[language].description,
      requirementsText: data.metadata.language[language].requirements,
      rules: data.metadata.language[language].rules,
      output: data.outputItem,
      id: data.id,
      requirements: data.inputItems.map((item) => {
        if ('assetId' in item)
          return {
            amount: item.amount,
            type: 'item',
            assetId: item.assetId,
          } as Challenge['requirements'][number]
        throw new Error("Couldn't build the challenge")
      }),
      galleryId: data.galleryId,
      status: data.status,
      highlight: data.highlight
    }
  }
}
