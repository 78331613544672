import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import React from 'react'
import useStyles from './SimpleTopBar.style'

const SimpleTopBarView: React.FC = () => {
  const classes = useStyles()

  return (
    <Box>
      <AppBar elevation={0} className={classes.appBar}></AppBar>
    </Box>
  )
}

export default SimpleTopBarView
