import AdaptiveContainer from '@onepercentio/one-ui/dist/components/AdaptiveContainer'
import { useHighlightedChallenges } from 'context/Challenge'
import { useFeatures } from 'context/Feature'
import { SectionView } from 'openspace/components/Section/Section'
import { Fragment } from 'react'
import { ChallengeSectionTestIds } from './ChallengeSection.e2e'
import Container from 'openspace/components/Container'
import ChallengeBannerAlternator from 'openspace/components/ChallengeBannerAlternator'
import { t } from 'translate/i18n'
import { GalleryType } from 'core/logic/gallery/gallery.types'

export default function ChallengeSection({
  galleryId,
}: {
  galleryId?: GalleryType['id']
}) {
  const features = useFeatures()
  const { challenges: homeFeaturedChallenges } =
    useHighlightedChallenges(galleryId)

  /**
   * If I have the challenges enabled
   *
   * If I don't know if there are challenges available, load from a previous state
   */
  const showChallenges =
    features.challenges &&
    (homeFeaturedChallenges === undefined || homeFeaturedChallenges.length)

  return (
    <AdaptiveContainer direction='v'>
      {showChallenges ? (
        <Fragment key='challenges'>
          <SectionView
            data-testid={ChallengeSectionTestIds.CHALLENGE_SECTION}
            title={t('features.challenge.section.title')}>
            <Container>
              <ChallengeBannerAlternator galleryId={galleryId} />
            </Container>
          </SectionView>
        </Fragment>
      ) : (
        <Fragment key='no-challenges' />
      )}
    </AdaptiveContainer>
  )
}
