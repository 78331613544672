import { actions as authActions } from 'core/logic/authentication/auth.reducer'
import { auth, functions } from 'core/modules/firebase'
import { AUTHENTICATE_WITH_SIGNATURE_FUNCTION } from 'core/modules/firebase/service'
import { RootState } from 'core/modules/redux'
import {browserSessionPersistence} from 'firebase/auth'
import React from 'react'
import { UseMutationOptions, useMutation } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import {
  useChangeChain,
  useConnect,
  usePersistentAddress,
  useSignMessage,
  useWalletConnector,
} from '../wallet'
import { AuthState, AuthWithPOS } from './auth.types'

export const useAuthentication = () => {
  const dispatch = useDispatch()

  return {
    authentication: useSelector<RootState, AuthState>(
      ({ authentication }) => authentication
    ),
    login: (email: string, password: string) =>
      dispatch(authActions.loginAttemptWEP({ email, password })),
    register: (email: string, password: string) =>
      dispatch(authActions.registerAttemptWEP({ email, password })),
    gmail: () => dispatch(authActions.loginAttemptGmail()),
    facebook: () => dispatch(authActions.loginAttemptFacebook()),
    authenticated: () => dispatch(authActions.authenticated()),
    unauthenticated: () => dispatch(authActions.unauthenticated()),
    resetError: () => dispatch(authActions.resetError()),
  }
}

export const useGetAuthTokenFromPOS = (
  options?: UseMutationOptions<any, Error, AuthWithPOS>
) => {
  const onlyFn = React.useCallback(
    async ({ message, signature }: AuthWithPOS) => {
      const { data: customToken } = await functions.httpsCallable(
        AUTHENTICATE_WITH_SIGNATURE_FUNCTION
      )({
        message,
        signature,
      })
      await auth.setPersistence(browserSessionPersistence.type)
      await auth.signInWithCustomToken(customToken)
    },
    []
  )

  const mutation = useMutation<any, Error, AuthWithPOS>({
    mutationFn: onlyFn,
    ...options,
  })

  return {
    ...mutation,
    getAuthTokenFromPOS: mutation.mutate,
    getAuthTokenFromPOSFn: onlyFn,
  }
}

export const useSignInWithPOS = (
  options?: UseMutationOptions<any, Error, string>
) => {
  const { chainId } = useWalletConnector()
  const { changeChainFn } = useChangeChain()
  const { address } = usePersistentAddress()
  const { connected } = useWalletConnector()
  const { connectFn } = useConnect()
  const { signMessageFn } = useSignMessage()
  const { authentication } = useAuthentication()
  const { getAuthTokenFromPOSFn } = useGetAuthTokenFromPOS()
  const mutation = useMutation<any, Error, string>({
    mutationFn: async (desiredChainId?: string) => {
      const message = {
        account: address,
        timestamp: new Date().toISOString(),
      }
      const messageStr = JSON.stringify(
        message,
        undefined,
        2
      )

      const askUserToSignAndSignIn = async () => {
        const signature = await signMessageFn({ address, message: messageStr })
        await getAuthTokenFromPOSFn({
          message,
          signature,
        })
      }

      const askUserToChangeChain = async () => {
        if (chainId !== desiredChainId && desiredChainId) {
          await changeChainFn(desiredChainId)
        }
        await askUserToSignAndSignIn()
      }

      const askUserToConnect = async () => {
        if (!connected) {
          await connectFn()
        }
        await askUserToChangeChain()
      }

      if (!authentication.authenticated) {
        await askUserToConnect()
      } else {
        await askUserToChangeChain()
      }
    },
    ...options,
  })

  return {
    ...mutation,
    signInWithPOS: mutation.mutate,
  }
}

export default useAuthentication
