import { Box } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import React from 'react'
import useStyles from './InfoShimmer.style'

interface InfoShimmerProps {}
const InfoShimmer: React.FC<InfoShimmerProps> = () => {
  const classes = useStyles()

  return (
    <>
      <Box display='flex' flexDirection='column'>
        {/* Top row */}
        <Skeleton variant='rect' height={45} className={classes.block} />
        <Skeleton variant='text' width='75%' height={45} className={classes.block} />
        <Skeleton variant='text' width='80%' height={45} className={classes.block} />
        <Skeleton variant='text' width='60%' height={60} className={classes.block} />
        <Skeleton variant='rect' width={250} height={65} className={classes.block} />
      </Box>
    </>
  )
}

export default InfoShimmer
