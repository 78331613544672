import LazyBadge from 'openspace/components/LazyBadge/LazyBadge'
import React, { memo } from 'react'

export interface CarbonNeutralBadgeProps {
  /**
   * How large should the badge be?
   */
  size?: number
}

const CarbonNeutralBadge: React.FC<
  CarbonNeutralBadgeProps & React.SVGProps<SVGSVGElement>
> = ({ size, ...props }) => {
  return <LazyBadge {...props} />
}

export default memo(CarbonNeutralBadge)
