import { ReactElement, RefObject } from 'react'
import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'
import BackButton from 'components/BackButton'
import RarumAdaptiveSidebar from 'components/RarumAdaptiveSidebar/RarumAdaptiveSidebar'
import AssetItem from 'pages/Unauthenticated/Asset/AssetItem'
import { AssetType } from 'core/logic/asset/asset.types'
import useDropStepperStyles from './DropStepper.style'
import Button from '@onepercentio/one-ui/dist/components/Button'
import Control from 'components/RarumAdaptiveSidebar/Control'

export default function DropStepper({
  assetInfo,
  asset,
  loading,
  onBack,
  steps,
  label,
  rootRef,
  ...stepperProps
}: {
  asset: AssetType
  assetInfo: {
    price: number
    currency: string
    quantity: number
  }
  loading: boolean
  onBack: () => void
  steps: ReactElement
  label: {
    [k in 'back' | 'title' | 'description' | 'cancel' | 'preview']: string
  }
  rootRef?: RefObject<HTMLDivElement>
  'data-fab-testid'?: string
}) {
  const classes = useDropStepperStyles()
  return (
    <>
      <Container maxWidth='lg'>
        <BackButton onClick={onBack} text={label.back} />
        <Grid container ref={rootRef}>
          <Grid
            item
            sm={10}
            container
            alignItems='center'
            justifyContent='flex-start'
            direction='column'>
            <Box
              alignSelf={'center'}
              display='flex'
              flexDirection='column'
              alignItems={'flex-start'}
              flex='1'
              width='100%'
              maxWidth={729}>
              <Typography variant='h1'>{label.title}</Typography>
              <Typography variant='body1'>{label.description}</Typography>
              <Box marginTop={2} marginBottom='auto' flex='1'>
                {steps}
              </Box>
              <Box marginTop={'auto'} alignSelf='stretch' marginBottom={2}>
                <Divider />
              </Box>
              <Button onClick={onBack} disabled={loading} variant='transparent'>
                <Box marginRight={2} marginLeft={2}>
                  {label.cancel}
                </Box>
              </Button>
            </Box>
          </Grid>
          <Grid item sm={2} justifyContent={'flex-end'} container>
            <RarumAdaptiveSidebar
              className={classes.float}
              visibilityControlComponent={(props) => (
                <Control
                  {...props}
                  data-testid={stepperProps['data-fab-testid']}
                />
              )}>
              <Typography variant='h3'>{label.preview}</Typography>
              <AssetItem
                asset={asset}
                last
                enableInteraction={false}
                offerInfo={{
                  currency: assetInfo.currency,
                  price: assetInfo.price,
                  supply: assetInfo.quantity,
                }}
              />
            </RarumAdaptiveSidebar>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
