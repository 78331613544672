import { Box, Typography } from '@material-ui/core'
import Button from '@onepercentio/one-ui/dist/components/Button'
import { shortenWallet } from 'core/helpers/wallet'
import { t } from 'translate/i18n'
import { WalletDisplayButtonTestIds } from './WalletDisplayButton.e2e'
import useStyles from './WalletDisplayButton.style'

export default function WalletDisplayButton({
  username,
  wallet,
  onClick,
}: {
  username: string
  wallet?: string
  onClick: () => void
}) {
  const classes = useStyles()
  return (
    <>
      <Button
        data-testid={WalletDisplayButtonTestIds.BUTTON}
        className={`${classes.btn}`}
        variant='highlight'
        onClick={onClick}>
        {`${username}
${!wallet ? t('walletRequiredControl.connectWallet') : shortenWallet(wallet)}`}
      </Button>
    </>
  )
}

export function WalletConnectedView({
  onDisconnect,
  onClose,
  wallet,
}: {
  onDisconnect: () => void
  onClose: () => void
  wallet: string
}) {
  const classes = useStyles()
  return (
    <div className={classes.modal}>
      <Typography variant='h2'>
        {t('marketplace.banner.header.connectedWallet')}
      </Typography>
      <br />
      <Typography variant='body1'>{wallet}</Typography>
      <br />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button variant='filled' onClick={onDisconnect}>
          {t('marketplace.banner.header.actions.disconnectWallet')}
        </Button>
        <Button variant='outline' onClick={onClose}>
          {t('marketplace.banner.header.actions.dismiss')}
        </Button>
      </Box>
    </div>
  )
}
