import { mapDropData, scaleUnitPrice } from 'core/helpers/drop'
import { service } from 'core/modules/firebase'
import { PurchaseType } from './purchase.types'

export const fetchPurchases = ({ emitter }: { emitter: (input: PurchaseType[]) => void }) => {
  return service.fetchPurchases({
    onSnapshot: (snap) => {
      const purchasesUpdated = snap.docs.map((c) => {
        const data = c.data()
        const id = c.id
        return {
          ...(data as PurchaseType),
          ...(data.totalPrice ? { totalPrice: scaleUnitPrice(data.totalPrice) } : {}),
          offer: mapDropData(data.offer),
          id,
          created: new Date(data.created),
          expireAt: new Date(data.expireAt),
        } as PurchaseType
      })
      emitter(purchasesUpdated)
    },
  })
}

export const fetchPurchase = ({ purchaseId, emitter }: { purchaseId: string; emitter: (input: PurchaseType) => void }) => {
  return service.fetchPurchase({
    purchaseId,
    onSnapshot: (snap) => {
      const data = snap.data()
      const id = snap.id
      if (data) {
        const purchaseUpdated = {
          ...(data as PurchaseType),
          ...(data.totalPrice ? { totalPrice: scaleUnitPrice(data.totalPrice) } : {}),
          offer: mapDropData(data.offer),
          id,
          created: new Date(data.created),
          expireAt: new Date(data.expireAt),
        } as PurchaseType
        emitter(purchaseUpdated)
      }
    },
  })
}
