import { groupDrops, organizeWithCounters } from 'core/helpers/drop'
import { actions as dropActions } from 'core/logic/drop/drop.reducer'
import { RootState } from 'core/modules/redux'
import isNil from 'lodash/isNil'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useCounters } from '../counters/counters.hook'
import { CountersState } from '../counters/counters.types'
import { DropState, OfferType } from './drop.types'
import { useItemFromListOrSingle } from '../shared/shared.hook'

export const useDrops = () => {
  const { drops } = useSelector<RootState, DropState>(({ drop }) => drop)
  const { active, activeIds, inactive } = useMemo(() => {
    const now = new Date()
    if (!drops) return { active: null, activeIds: null, inactive: null }
    return groupDrops({ drops, relativeTo: now })
  }, [drops])

  // Fetching counters for active offers
  const { counters } = useCounters({ dropIds: activeIds || [] })

  const { finalActive, finalInactive } = useMemo(() => {
    if (!active || !inactive)
      return { finalActive: null, finalInactive: null, highlight: null }

    return organizeWithCounters({ active, inactive, counters })
  }, [active, inactive, counters])

  return {
    active: finalActive,
    inactive: finalInactive,
    highlight: finalActive?.[0] || null,
  }
}

export const useDrop = ({ dropId }: { dropId?: string }) => {
  const dispatch = useDispatch()
  const { drop, drops } = useSelector<RootState, DropState>(({ drop }) => drop)
  const dropItem = useItemFromListOrSingle(dropId, drop, drops, () => {
    dispatch(dropActions.emitterDropSubscribe(dropId!))
    return () => {
      dispatch(dropActions.emitterDropUnsubscribe())
    }
  })

  return { drop: dropItem }
}

export const useDropBids = ({ dropId }: { dropId?: string }) => {
  const dispatch = useDispatch()
  const { dropBids } = useSelector<RootState, DropState>(({ drop }) => drop)
  useEffect(() => {
    if (dropId) {
      dispatch(dropActions.emitterDropBidsSubscribe(dropId))
      return () => {
        dispatch(dropActions.emitterDropBidUnsubscribe())
      }
    }
  }, [dispatch, dropId])

  return { dropBids }
}

export function useDropRemainingCount(drop: OfferType) {
  const { counters } = useSelector<RootState, CountersState>(
    ({ counters }) => counters
  )

  const hasCounter = !isNil(counters[drop?.id])

  const remainingSupply = hasCounter
    ? drop.supply - counters[drop.id]!
    : undefined

  return remainingSupply
}
