import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

interface HeadersProps {
  screenTitle?: string
}

const Headers: React.FC<HeadersProps> = ({ screenTitle }) => {
  const location = useLocation<Location>()
  const { pathname } = location

  return (
    <Helmet>
      <link rel='canonical' href={pathname} />
      {/* { screenTitle ? <title>{screenTitle}</title> : null } */}
    </Helmet>
  )
}

export default Headers
