import { builder } from 'core/helpers'
import * as GalleryTypes from './gallery.types'

// == TYPES ==
const GALLERY_EMITTER_SUBSCRIBE = 'gallery/emitter/SUBSCRIBE'
const GALLERY_EMITTER_UNSUBSCRIBE = 'gallery/emitter/UNSUBSCRIBE'
const GALLERY_FETCH_SUCCESS = 'gallery/fetch/SUCCESS'
const GALLERY_FETCH_FAILED = 'gallery/fetch/FAILED'

const GALLERIES_FETCH_SUCCESS = 'galleries/fetch/SUCCESS'
const GALLERIES_FETCH_FAILED = 'galleries/fetch/FAILED'

export const types = Object.freeze({
  GALLERY_EMITTER_SUBSCRIBE: GALLERY_EMITTER_SUBSCRIBE,
  GALLERY_EMITTER_UNSUBSCRIBE: GALLERY_EMITTER_UNSUBSCRIBE,
  GALLERY_FETCH_SUCCESS: GALLERY_FETCH_SUCCESS,
  GALLERY_FETCH_FAILED: GALLERY_FETCH_FAILED,
  FETCH_SUCCESS: GALLERIES_FETCH_SUCCESS,
  FETCH_FAILED: GALLERIES_FETCH_FAILED,
})

// == ACTIONS ==
const emitterGallerySubscribe = (galleryId: string) => builder.actionCreator<GalleryTypes.GalleryActionsTypes, string>(GALLERY_EMITTER_SUBSCRIBE, galleryId)

const emitterGalleryUnsubscribe = () => builder.actionCreator<GalleryTypes.GalleryActionsTypes>(GALLERY_EMITTER_UNSUBSCRIBE)

const fetchGallerySuccess = (gallery: GalleryTypes.GalleryType) =>
  builder.actionCreator<GalleryTypes.GalleryActionsTypes, GalleryTypes.GalleryType>(GALLERY_FETCH_SUCCESS, gallery)

const fetchGalleryFailed = (errorKey: string) => builder.actionCreator<GalleryTypes.GalleryActionsTypes, string>(GALLERY_FETCH_FAILED, errorKey)

const fetchGalleriesSuccess = (galleries: GalleryTypes.GalleryType[]) =>
  builder.actionCreator<GalleryTypes.GalleryActionsTypes, GalleryTypes.GalleryType[]>(GALLERIES_FETCH_SUCCESS, galleries)

const fetchGalleriesFailed = (errorKey: string) => builder.actionCreator<GalleryTypes.GalleryActionsTypes, string>(GALLERIES_FETCH_FAILED, errorKey)

export const actions = Object.freeze({
  emitterGallerySubscribe,
  emitterGalleryUnsubscribe,
  fetchGallerySuccess,
  fetchGalleryFailed,
  fetchGalleriesSuccess,
  fetchGalleriesFailed,
})

export const mapActions = Object.freeze({
  [GALLERY_EMITTER_SUBSCRIBE]: emitterGallerySubscribe,
  [GALLERY_EMITTER_UNSUBSCRIBE]: emitterGalleryUnsubscribe,
  [GALLERY_FETCH_FAILED]: fetchGalleryFailed,
  [GALLERY_FETCH_SUCCESS]: fetchGallerySuccess,
  [GALLERIES_FETCH_SUCCESS]: fetchGalleriesSuccess,
  [GALLERIES_FETCH_FAILED]: fetchGalleriesFailed,
})

// == REDUCER ==
export const initialState: Readonly<GalleryTypes.GalleryState> = Object.freeze({
  gallery: null,
  galleries: null,
  errorKey: '',
})

export const reducer = (
  state: Readonly<GalleryTypes.GalleryState> = initialState,
  action: Readonly<GalleryTypes.GalleryAction>
): GalleryTypes.GalleryState => {
  const { type, payload } = action
  switch (type) {
    case GALLERY_EMITTER_UNSUBSCRIBE:
      return { ...state, gallery: null }
    case GALLERY_FETCH_SUCCESS:
      return { ...state, gallery: payload as GalleryTypes.GalleryType }
    case GALLERY_FETCH_FAILED:
      return { ...state, errorKey: payload as string }
    case GALLERIES_FETCH_SUCCESS:
      return { ...state, galleries: payload as GalleryTypes.GalleryType[] }
    case GALLERIES_FETCH_FAILED:
      return { ...state, errorKey: payload as string }
    default:
      return state
  }
}
