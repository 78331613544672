import { watchAuthenticated, watchLoginSuccess } from 'core/logic/analytics/analytics.sagas'
import { watchAssetFetch, watchAssetsByTokenFetch, watchAssetsFetch } from 'core/logic/asset/asset.sagas'
import {
  watchAutoLogout,
  watchLogin,
  watchLoginWEP,
  watchLoginWithFacebook,
  watchLoginWithGmail,
  watchRegister,
} from 'core/logic/authentication/auth.sagas'
import { watchCountersSubscribe } from 'core/logic/counters/counters.sagas'
import { dropsSyncGenerator, watchDropSubscribe, watchDropBidsSubscribe } from 'core/logic/drop/drop.sagas'
import { galleriesSyncGenerator, watchGallerySubscribe } from 'core/logic/gallery/gallery.sagas'
import { watchDeliveriesSubscribe, watchDeliverySubscribe } from 'core/logic/delivery/delivery.sagas'
import { watchPurchasesSubscribe, watchPurchaseSubscribe } from 'core/logic/purchase/purchase.sagas'
import { startTenantFetch } from 'core/logic/tenant/tenant.sagas'
import { watchManualLogout, watchRemove } from 'core/logic/user/user.sagas'
import { startTermsFetch } from 'core/logic/terms/terms.sagas'
import { all, call, spawn } from 'redux-saga/effects'

function* rootSaga() {
  const sagas = [
    // Auth
    watchLogin,
    watchLoginWEP,
    watchAutoLogout,
    watchManualLogout,
    watchLoginSuccess,
    watchAuthenticated,
    watchRegister,
    watchLoginWithGmail,
    watchLoginWithFacebook,
    watchRemove,
    // Drops
    dropsSyncGenerator,
    watchDropSubscribe,
    watchDropBidsSubscribe,
    // Counters
    watchCountersSubscribe,
    // Purchase
    watchPurchasesSubscribe,
    watchPurchaseSubscribe,
    // Deliveries
    watchDeliveriesSubscribe,
    watchDeliverySubscribe,
    // Assets
    watchAssetsByTokenFetch,
    watchAssetsFetch,
    watchAssetFetch,
    // Galleries
    galleriesSyncGenerator,
    watchGallerySubscribe,
    // Tenant
    startTenantFetch,
    // Terms
    startTermsFetch,
  ] // imported sagas

  // yielding sagas as spawn process will separate one logic to another
  // and prevents that one part of system breaks it all
  // but the errors must be handled per module
  // and the circuit breaker must be aware of each part is failing
  // here it will auto recover - cb must deal with it
  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga)
            break
          } catch (e:any) {
            console.error(e)
            console.error(e.stack)
          }
        }
      })
    )
  )
}

export default rootSaga
