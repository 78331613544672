import { flatten } from '@onepercentio/one-ui/dist/utils/flatten'
import { ChainConfig, chainConfig } from 'core/constants'
import { useTenant } from 'core/logic/tenant/tenant.hook'
import useLanguage from 'openspace/hooks/useLanguage'
import { createContext, useContext, useMemo } from 'react'
import { i18n } from 'translate/i18n'
import chainEn from 'translate/languages/chain.en'
import chainEs from 'translate/languages/chain.es'
import chainPt from 'translate/languages/chain.pt'

export type Chains = 'POLYGON' | 'MOONBEAM'

export type ChainContextShape = ChainConfig | undefined

export const ChainContext = createContext<ChainContextShape>(null as any)

const ChainProvider = ({ children }: { children: React.ReactNode }) => {
  const { tenant } = useTenant()
  const lang = useLanguage()

  const tenantChain = useMemo(() => {
    if (tenant) return tenant.chain ?? 'POLYGON'
    else return undefined
  }, [tenant])

  const registerTranslations = (
    lang: LanguageCodes,
    translations: typeof chainPt,
    chain: Chains
  ) => {
    const translationsObject =
      process.env.NODE_ENV === 'development'
        ? translations[chain] ?? translations.POLYGON
        : translations[chain]
    const flatTranslations = flatten(translationsObject) as any
    for (const key in flatTranslations) {
      if (!i18n.getResource(lang, 'translations', key))
        i18n.addResource(lang, 'translations', key, flatTranslations[key])
    }
  }

  const currentChain = useMemo(() => {
    if (tenantChain) {
      switch (lang) {
        case 'pt-BR':
          registerTranslations('pt-BR', chainPt, tenantChain)
          break
        case 'en-US':
          registerTranslations('en-US', chainEn, tenantChain)
          break
        case 'es-ES':
          registerTranslations('es-ES', chainEs, tenantChain)
          break
        default:
          break
      }
    }
    return tenantChain ? chainConfig[tenantChain] : undefined
  }, [tenant, lang])

  return (
    <ChainContext.Provider value={currentChain}>
      {children}
    </ChainContext.Provider>
  )
}

export function useChain() {
  return useContext(ChainContext)
}

export function useChainForIntl(): Pick<
  ChainConfig,
  'name' | 'currency' | 'explorerUrl' | 'explorerName'
> {
  return useContext(ChainContext)!
}

export function useStable() {
  return useContext(ChainContext)?.stableTokens ?? []
}

export default ChainProvider
