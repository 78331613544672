import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const BOTTOM_MENU_HEIGHT = 140

const style = (theme: Theme) =>
  createStyles({
    title: {
      display: 'inline-block',
      fontSize: 'clamp(1.25rem, 5vw, 2.4rem)',
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        display: 'block',
      },
    },
    subtitle: {
      textAlign: 'center',
      [theme.breakpoints.down('md')]: {
        display: 'block',
      },
    },
    container: {
      marginTop: theme.spacing(4),
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(2),
      },
      paddingBottom: BOTTOM_MENU_HEIGHT - theme.spacing(2),
      [theme.breakpoints.only('sm')]: {
        paddingBottom: BOTTOM_MENU_HEIGHT - theme.spacing(3),
      },
      [theme.breakpoints.only('md')]: {
        paddingBottom: BOTTOM_MENU_HEIGHT - theme.spacing(5),
      },
      [theme.breakpoints.up('lg')]: {
        paddingBottom: BOTTOM_MENU_HEIGHT - theme.spacing(10),
      },
    },
    grid: {
      display: 'grid',
      justifyContent: 'center',
      gridGap: theme.spacing(4),
      gridTemplateColumns: 'repeat(auto-fit, minmax(max(160px,20vh), 1fr))',
    },
    figure: {
      position: 'relative',
      zIndex: 0,
      margin: 0,
      width: '100%',
      maxWidth: 410,
    },
    image: {
      maxWidth: '100%',
      borderRadius: 2,
    },
    dropDescription: {
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
      },
    },
    spacing: {
      marginTop: theme.spacing(2),
    },
    chip: {
      color: theme.palette.common.white,
      borderColor: theme.palette.common.white,
      textTransform: 'uppercase',
    },
    menu: {
      display: 'flex',
      justifyContent: 'center',
      position: 'fixed',
      left: 0,
      bottom: 0,
      width: '100%',
      height: BOTTOM_MENU_HEIGHT,
      textAlign: 'center',
      alignItems: 'center',
    },
    button: {
      alignSelf: 'center',
    },
  })

export default makeStylesWrapper(style)
