import { i18n } from 'translate/i18n'
import { validateCEP, validateCPF } from 'core/helpers/validators'
import { object as Yobject, string as Ystring, boolean as Ybool, AnySchema } from 'yup'
import { PaymentFlow, PaymentMapping } from './Payment.types'
import StepAddress from './Steps/StepAddress.logic'
import StepReview from './Steps/StepReview.view'
import StepUserData from './Steps/StepUserData.view'
import { CurrenciesEnum } from 'core/types/CurrenciesEnum'
import * as Profile from "core/logic/user/user.types"

const Ycep = Ystring().test(
  i18n.t('authenticated.payment.data.cep'),
  i18n.t('authenticated.payment.data.invalidCep'),
  (value) => !value || validateCEP(value ?? '')
)

export const mapPaymentMethodToName: PaymentMapping<string> = {
  BOLETO_PIX: i18n.t('authenticated.payment.data.pix'),
  PIX: i18n.t('authenticated.payment.data.pix'),
  CREDIT_CARD: i18n.t('authenticated.payment.data.creditCard'),
  CRYPTO: i18n.t('authenticated.payment.data.cryptoCoin'),
}

export const mapPaymentMethodToCurrency: PaymentMapping<CurrenciesEnum> = {
  BOLETO_PIX: CurrenciesEnum.BRL,
  PIX: CurrenciesEnum.BRL,
  CREDIT_CARD: CurrenciesEnum.BRL,
  CRYPTO: CurrenciesEnum.BRL,
}

export const steps: PaymentMapping<PaymentFlow[]> = {
  BOLETO_PIX: [
    {
      title: i18n.t('authenticated.payment.data.personalData'),
      step: 1,
      Component: StepUserData,
    },
    {
      title: i18n.t('authenticated.payment.data.revision'),
      step: 2,
      Component: StepReview,
    },
  ],
  PIX: [
    {
      title: i18n.t('authenticated.payment.data.personalData'),
      step: 1,
      Component: StepUserData,
    },
    {
      title: i18n.t('authenticated.payment.data.revision'),
      step: 2,
      Component: StepReview,
    },
  ],
  CREDIT_CARD: [
    {
      title: i18n.t('authenticated.payment.data.personal'),
      step: 1,
      Component: StepUserData,
    },
    {
      title: i18n.t('authenticated.payment.data.address'),
      step: 2,
      Component: StepAddress,
    },
    {
      title: i18n.t('authenticated.payment.data.revision'),
      step: 3,
      Component: StepReview,
    },
  ],
  CRYPTO: [],
}

export const paymentStepUserDataSchema = Yobject().shape({
  userName: Ystring().required(
    i18n.t('authenticated.payment.data.nameIsMandatory')
  ),
  email: Ystring()
    .email(i18n.t('authenticated.payment.data.invalidEmail'))
    .required(i18n.t('authenticated.payment.data.emailIsMandatory')),
  foreigner: Ybool(),
  cpf: Ystring().when('foreigner', {
    is: true,
    then: Ystring().required(i18n.t('authenticated.payment.data.invalidId')),
    otherwise: Ystring().test(
      i18n.t('authenticated.payment.data.cpf'),
      i18n.t('authenticated.payment.data.invalidCpf'),
      (value) => validateCPF(value ?? '')
    ),
  }),
})

export const paymentStepAddressSchema = Yobject().shape({
  nonResident: Ybool(),
  country: Ystring().when('nonResident', {
    is: true,
    then: Ystring().required(
      i18n.t('authenticated.payment.data.countryIsMandatory')
    ),
  }),
  cep: Ystring().when('nonResident', {
    is: true,
    then: Ystring().required(
      i18n.t('authenticated.payment.data.zipIsMandatory')
    ),
    otherwise: Ystring().test(
      i18n.t('authenticated.payment.data.cep'),
      i18n.t('authenticated.payment.data.invalidCep'),
      (value) => validateCEP(value ?? '')
    ),
  }),
  estado: Ystring().required(
    i18n.t('authenticated.payment.data.stateIsMandatory')
  ),
  cidade: Ystring().required(
    i18n.t('authenticated.payment.data.cityIsMandatory')
  ),
  logradouro: Ystring().required(
    i18n.t('authenticated.payment.data.patioIsMandatory')
  ),
  numero: Ystring().required(
    i18n.t('authenticated.payment.data.numberIsMandatory')
  ),
})

export const accountUserDataSchema = Yobject().shape({
  userName: Ystring(),
  email: Ystring().email(i18n.t('authenticated.payment.data.invalidEmail')),
  cpf: Ystring().test(
    i18n.t('authenticated.payment.data.cpf'),
    i18n.t('authenticated.payment.data.invalidCpf'),
    (value) => !value || validateCPF(value ?? '')
  ),
})
/**
 * This validates the shape defined by {@link Profile.RarumUserProfile.personalData}
 */
export const profilePersonalDataSchema = Yobject().shape<{
  [k in keyof Pick<NonNullable<Profile.RarumUserProfile['personalData']>, "name" | "email" | "cpf" | "state" | "city" | "address" | "cep" | "addressNumber">]: AnySchema
}>({
  name: Ystring(),
  email: Ystring().email(i18n.t('authenticated.payment.data.invalidEmail')),
  cpf: Ystring().test(
    i18n.t('authenticated.payment.data.cpf'),
    i18n.t('authenticated.payment.data.invalidCpf'),
    (value) => !value || validateCPF(value ?? '')
  ),
  state: Ystring(),
  city: Ystring(),
  address: Ystring(),
  cep: Ycep,
  addressNumber: Ystring(),
})

export const accountAddressSchema = Yobject().shape({
  cep: Ycep,
  estado: Ystring(),
  cidade: Ystring(),
  logradouro: Ystring(),
  numero: Ystring(),
})
