import { BaseWalletConnectionWrapper } from '@onepercentio/one-ui/dist/components/WalletConnectionWrapper'
import { WalletConnectionProps } from '@onepercentio/one-ui/dist/components/WalletConnectionWrapper/WalletConnectionWrapper'
import {
  createContext,
  PropsWithChildren,
  ReactElement,
  useContext,
  useMemo,
} from 'react'
import { useChain } from './Chain'
import { useUser } from 'core/logic/user'

export type WalletContextShape = {
  isConnected: boolean
  wallet?: string
  mode: 'provider' | 'custody' | undefined
}

export const WalletContext = createContext<WalletContextShape>(null as any)

function Wrapper({
  children,
  isConnected,
  wallet,
}: PropsWithChildren<WalletConnectionProps>) {
  const { profile } = useUser()
  const walletMode = useMemo<WalletContextShape['mode']>(() => {
    if (!profile) return undefined
    if (profile.walletConnected) return 'provider'
    else return 'custody'
  }, [profile])
  return (
    <WalletContext.Provider value={{ isConnected, wallet, mode: walletMode }}>
      {children as ReactElement}
    </WalletContext.Provider>
  )
}

export function WalletProvider({ children }: PropsWithChildren<{}>) {
  const chain = useChain()
  return (
    <BaseWalletConnectionWrapper chain={chain} Content={Wrapper}>
      {children}
    </BaseWalletConnectionWrapper>
  )
}

export function useWallet() {
  return useContext(WalletContext)
}

/** Returns if the wallet is at our custody or it's a provider */
export function useIsWalletCustody() {
  const mode = useWallet().mode!

  return mode === 'custody'
}

/**
 * Prepares a function that decides the invokation based on the ownership of the
 * wallet (custody or provider/metamask)
 */
export function useWalletOwnershipOperation<
  F extends WalletOwnershipBasedOperation<(...args: any[]) => any>
>(funcs: {
  custody: F extends WalletOwnershipBasedOperation<infer A> ? A : never
  provider: F extends WalletOwnershipBasedOperation<infer A> ? A : never
}) {
  const isWalletCustody = useIsWalletCustody()

  return ((...params: Parameters<F>) => {
    if (isWalletCustody) return funcs.custody(...params)
    else return funcs.provider(...params)
  }) as WalletOwnershipBasedOperation<
    F extends WalletOwnershipBasedOperation<infer A> ? A : never
  >
}
