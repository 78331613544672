import CircularProgress from '@material-ui/core/CircularProgress'
import IconRarum, { IconRarumTitle } from 'components/IconRarum'
import { ComponentProps, memo, MouseEventHandler, ReactElement } from 'react'
import useStyles from './ActionButton.style'
import Button from '@onepercentio/one-ui/dist/components/Button'

export interface ActionButtonViewProps {
  text: string | ReactElement
  onClick?: MouseEventHandler<HTMLButtonElement>
  className?: string
  light?: boolean
  filled?: boolean
  disabled?: boolean
  icon?: IconRarumTitle | 'none'
  showSpinner?: boolean
}

const ActionButtonView: React.FC<
  ComponentProps<typeof Button> & ActionButtonViewProps
> = ({
  text,
  onClick,
  light = false,
  filled = false,
  disabled,
  icon = 'arrow',
  showSpinner,
  ...props
}) => {
  const { progress, oneui } = useStyles()

  return (
    <Button
      {...props}
      onClick={onClick}
      color='primary'
      variant={!light ? 'filled' : 'outline'}
      className={`${icon !== 'none' ? 'icon right' : ''} ${oneui}`}
      disabled={disabled}>
      <span>{text}</span>
      <span>
        {showSpinner ? (
          <CircularProgress className={progress} size={24} />
        ) : icon !== 'none' ? (
          <IconRarum icon={icon} size={24} />
        ) : undefined}
      </span>
    </Button>
  )
}

export default memo(ActionButtonView)
