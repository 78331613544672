import { currencyNumberFormatter } from 'core/helpers/formatter'
import MutableHamburgerButton from '@onepercentio/one-ui/dist/components/MutableHamburgerButton'
import Fab from '@material-ui/core/Fab'
import useStyles from './CreateOffer.style'
import AssetItem from 'pages/Unauthenticated/Asset/AssetItem'
import { AssetType } from 'core/logic/asset/asset.types'
import {
  Container,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Divider,
  FormLabel as Label,
  TableHead,
  Radio,
  Box,
} from '@material-ui/core'
import { t } from 'translate/i18n'
import CurrencyLabel from 'components/CurrencyLabel/CurrencyLabel.view'
import {
  OfferForm,
  PREDEFINED_DEADLINES,
  CreateOfferFormMode,
} from './CreateOffer.types'
import { ToggleButton } from '@material-ui/lab'
import { Fragment, PropsWithChildren, useRef } from 'react'
import Skeleton from '@material-ui/lab/Skeleton'
import BackButton from 'components/BackButton'
import RarumAdaptiveSidebar from 'components/RarumAdaptiveSidebar/RarumAdaptiveSidebar'
import { CreateOfferTestIds } from './CreateOffer.e2e'
import Button from '@onepercentio/one-ui/dist/components/Button'

function LoaderWrapper({
  children,
  loading,
}: PropsWithChildren<{ loading: boolean }>) {
  return loading ? (
    <Skeleton style={{ minWidth: 170 }} height={100} />
  ) : (
    (children as any)
  )
}

function CompactWrapper({
  children,
  compact,
}: PropsWithChildren<{ compact: boolean }>) {
  return !compact ? <Fragment>{children}</Fragment> : null
}

export default function CreateOfferView({
  baseAsset,
  loading,
  onGoBack,
  canContinue,
  form,
  currencies,
  onUpdateCurrency,
  onUpdateDeadline,
  onUpdateQuantity,
  tax = 0,
  howManyItemsICanSell,
  onSell,
  mode,
  compact,
}: {
  baseAsset?: AssetType | null
  loading: boolean
  onGoBack: () => void
  canContinue: boolean
  currencies: CryptoCurrencySymbol[]
  form: OfferForm
  tax?: number
  howManyItemsICanSell?: number
  onUpdateCurrency: (
    currency: CryptoCurrencySymbol,
    active: boolean,
    amount: number
  ) => void
  onUpdateDeadline: (predef: PREDEFINED_DEADLINES) => void
  onUpdateQuantity: (quantity: number) => void
  onSell: () => void
  mode: CreateOfferFormMode
  compact: boolean
}) {
  const quantitySelectRef = useRef<HTMLElement>(null)
  const classes = useStyles()
  const aCurrency =
    Object.keys(form.amount).find(
      (a) => form.amount[a]?.checked && form.amount[a]!.amount
    ) || ''

  return (
    <>
      <CompactWrapper compact={compact}>
        <Container className={classes.backButton} maxWidth='lg'>
          <BackButton
            onClick={onGoBack}
            text={t('marketplace.offerCreation.actions.backToHome')}
          />
        </Container>
      </CompactWrapper>
      <Container maxWidth='lg' className={classes.row}>
        <Grid>
          <CompactWrapper compact={compact}>
            <Typography variant='h1'>
              {t('marketplace.offerCreation.title')}
            </Typography>
            <Typography variant='body1'>
              {t('marketplace.offerCreation.description')}
            </Typography>
            <Divider />
            <Typography variant='h6'>
              {t('marketplace.offerCreation.sections.type.title')}
            </Typography>
            <Grid>
              <ToggleButton selected value='direct'>
                {t('marketplace.offerCreation.sections.type.direct')}
              </ToggleButton>
              <ToggleButton disabled value='auction'>
                <Typography>
                  {t('marketplace.offerCreation.sections.type.auction')}
                </Typography>
                <Typography variant='caption'>{t('generic.soon')}</Typography>
              </ToggleButton>
            </Grid>
            <Divider />
          </CompactWrapper>
          <Typography variant='h6'>
            {t('marketplace.offerCreation.sections.currency.title')}
          </Typography>
          <CompactWrapper compact={compact}>
            <Typography variant='body1'>
              {t('marketplace.offerCreation.sections.currency.description', {
                count: currencies.length,
              })}
            </Typography>
          </CompactWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant='body2'>
                    {t(
                      'marketplace.offerCreation.sections.currency.chooseYourCurrency'
                    )}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant='body2'>
                    {t(
                      'marketplace.offerCreation.sections.currency.defineAmount'
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currencies.map(
                (curr) =>
                  ((compact &&
                    form.amount[curr] &&
                    form.amount[curr]!.checked) ||
                    !compact) && (
                    <TableRow
                      data-testid={CreateOfferTestIds.CURRENCY_SELECTOR}
                      key={curr}>
                      <TableCell>
                        <LoaderWrapper loading={loading}>
                          <FormControlLabel
                            control={
                              currencies.length === 1 ? (
                                <Box width={12} height={42} />
                              ) : (
                                <Radio
                                  color='primary'
                                  disabled={
                                    mode === CreateOfferFormMode.EDITION
                                  }
                                  onClick={() =>
                                    onUpdateCurrency(
                                      curr,
                                      true,
                                      form.amount[curr]?.amount || 0
                                    )
                                  }
                                  checked={
                                    !!(
                                      form.amount[curr] &&
                                      form.amount[curr]!.checked
                                    )
                                  }
                                />
                              )
                            }
                            label={<CurrencyLabel currency={curr} />}
                          />
                        </LoaderWrapper>
                      </TableCell>
                      <TableCell>
                        <LoaderWrapper loading={loading}>
                          <TextField
                            InputProps={{
                              startAdornment: <span>{curr}&nbsp;</span>,
                            }}
                            inputProps={{
                              defaultValue:
                                form.amount[curr] && form.amount[curr]!.amount,
                            }}
                            onChange={({ target }) => {
                              target.value = target.value.replace(
                                /[^0-9.,]/g,
                                ''
                              )
                              const { value } = target
                              const valueAsNumber = Number(
                                value.replace(',', '.')
                              )

                              onUpdateCurrency(
                                curr,
                                !!form.amount[curr]?.checked,
                                Number.isNaN(valueAsNumber) ? 0 : valueAsNumber
                              )
                            }}
                            helperText={
                              <>
                                <Typography
                                  className={classes.disclaimer}
                                  variant='body2'>
                                  {t(
                                    'marketplace.offerCreation.sections.currency.disclaimer.youReceive',
                                    {
                                      amount: currencyNumberFormatter(
                                        (form.amount[curr]?.amount || 0) *
                                          ((100 - tax) / 100)
                                      ),
                                    }
                                  )}
                                  <br />
                                  {t(
                                    'marketplace.offerCreation.sections.currency.disclaimer.tax',
                                    {
                                      amount: currencyNumberFormatter(tax || 0),
                                    }
                                  )}
                                </Typography>
                              </>
                            }
                          />
                        </LoaderWrapper>
                      </TableCell>
                    </TableRow>
                  )
              )}
            </TableBody>
          </Table>
          <Divider />
          <Typography variant='h6'>
            {t('marketplace.offerCreation.sections.itemAmount.title')}
          </Typography>
          <Typography variant='body2'>&nbsp;</Typography>
          <LoaderWrapper loading={loading}>
            <CompactWrapper compact={compact}>
              <Label>
                {t('marketplace.offerCreation.sections.itemAmount.description')}
              </Label>
            </CompactWrapper>
            <Select
              onChange={({ target: { value } }) =>
                onUpdateQuantity(Number(value) as number)
              }
              data-testid={CreateOfferTestIds.SELECT_QUANTITY}
              value={form.selectedQuantity}
              ref={quantitySelectRef}>
              {new Array(
                howManyItemsICanSell && howManyItemsICanSell < 0
                  ? 0
                  : howManyItemsICanSell || 0
              )
                .fill(undefined)
                .map((_, i) => (
                  <MenuItem
                    data-testid={CreateOfferTestIds.SELECT_QUANTITY_ITEM}
                    value={i + 1}
                    key={i + 1}>
                    {t('authenticated.myCollection.collectionItem.unitsNew', {
                      count: i + 1,
                    })}
                  </MenuItem>
                ))}
            </Select>
          </LoaderWrapper>
          {mode === CreateOfferFormMode.CREATION && (
            <>
              <Divider />
              <Typography variant='h6'>
                {t('marketplace.offerCreation.sections.deadline.title')}
              </Typography>
              <Typography variant='body1'>
                {t('marketplace.offerCreation.sections.deadline.description')}
              </Typography>
              <LoaderWrapper loading={loading}>
                <Label>
                  {t('marketplace.offerCreation.sections.deadline.title')}
                </Label>
                <Select
                  onChange={({ target: { value } }) =>
                    onUpdateDeadline(value as PREDEFINED_DEADLINES)
                  }
                  data-testid={CreateOfferTestIds.SELECT_DEADLINE}
                  value={form.selectedDeadline}>
                  {Object.values(PREDEFINED_DEADLINES).map((predef) => (
                    <MenuItem value={predef} key={predef}>
                      {t(
                        `marketplace.offerCreation.sections.deadline.predefined.${predef}`
                      )}
                    </MenuItem>
                  ))}
                </Select>
              </LoaderWrapper>
            </>
          )}
        </Grid>
        <CompactWrapper compact={compact}>
          <RarumAdaptiveSidebar
            className={classes.float}
            visibilityControlComponent={({ open }) => (
              <Fab
                className={classes.fab}
                data-testid={CreateOfferTestIds.PREVIEW_BTN}>
                <MutableHamburgerButton
                  state={open ? 'closed' : 'search'}
                  size={32}
                />
              </Fab>
            )}>
            <Typography variant='h3'>
              {t('marketplace.offerCreation.sections.preview.title')}
            </Typography>
            <AssetItem
              asset={!loading ? baseAsset : undefined}
              last
              enableInteraction={false}
              offerInfo={{
                currency: aCurrency,
                price: form.amount[aCurrency]?.amount || 0,
                supply: form.selectedQuantity,
              }}
            />
          </RarumAdaptiveSidebar>
        </CompactWrapper>
      </Container>
      <Container maxWidth='lg'>
        <Grid className={classes.buttons}>
          <Button variant='transparent' onClick={onGoBack}>
            {t('marketplace.offerCreation.actions.cancel')}
          </Button>
          <Button variant='filled' disabled={!canContinue} onClick={onSell}>
            {t(
              mode === CreateOfferFormMode.CREATION
                ? 'marketplace.offerCreation.actions.confirm'
                : 'marketplace.offerEdition.actions.confirm'
            )}
          </Button>
        </Grid>
      </Container>
    </>
  )
}
