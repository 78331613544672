import { useDrop } from 'core/logic/drop/drop.hook'
import { useHistory } from 'react-router-dom'
import WaitingRoomView from './WaitingRoom.view'

export interface WaitingRoomLogicProps {
  dropId: string
}

const WaitingRoomLogic: React.FC<WaitingRoomLogicProps> = ({ dropId }) => {
  const history = useHistory()
  const { drop } = useDrop({ dropId })

  const onBackClickHandler = () => history.push('/')

  return drop ? <WaitingRoomView drop={drop} onBack={onBackClickHandler} /> : null
}

export default WaitingRoomLogic
