import { ClaimProvider } from 'context/Claim'
import { MarketplaceProvider } from 'context/Marketplace'
import { MarketplaceUserProvider } from 'context/MarketplaceUser'
import { RealLocationProvider } from 'context/RealLocation'
import { useMain } from 'core/logic/main'
import { useOffline } from 'core/logic/offline'
import {
  CHECKOUT,
  DELETION,
  MY_COLLECTION,
  OPEN,
  OPENING,
  PAYMENT,
  SIGN_IN,
  SIGN_UP,
  TERMS,
  WAITING_ROOM,
} from 'core/modules/router'
import { Location } from 'history'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import MainRouterView from './MainRouter.view'

const animatePaths = [
  CHECKOUT,
  DELETION,
  MY_COLLECTION,
  OPEN,
  OPENING,
  PAYMENT,
  SIGN_IN,
  SIGN_UP,
  TERMS,
  WAITING_ROOM,
]
const shouldAnimate = (location: Location) => {
  return animatePaths.findIndex((path) =>
    location.pathname.includes(`/${path}`)
  )
}

function Wrapper() {
  const { startLoading, isLoading } = useMain()
  const { redirect } = useOffline()
  const { pathname } = useLocation()
  useEffect(() => {
    const redirectStatus = redirect.get()
    if (redirectStatus.ok && redirectStatus.data) startLoading()
  })

  return (
    <RealLocationProvider currentLocation={pathname}>
      <MainRouterView isLoading={isLoading} animateRoot={shouldAnimate} />
    </RealLocationProvider>
  )
}

const MainRouterLogic: React.FC = () => {
  return (
    <MarketplaceProvider>
      <MarketplaceUserProvider>
        <ClaimProvider>
          <Wrapper />
        </ClaimProvider>
      </MarketplaceUserProvider>
    </MarketplaceProvider>
  )
}

export default MainRouterLogic
