import { currencyFormatterFactory } from 'core/helpers/formatter'
import { OfferType } from 'core/logic/drop/drop.types'
import { useMemo } from 'react'
import { i18n } from 'translate/i18n'
import { IPOViewProps } from './IPO.types'
import BigNumber from 'bignumber.js'

export function useIPODetails(
  offer: OfferType,
  [totalQuotas, goalQuotas, reservedQuotas]: IPOViewProps['quotas']
) {
  const intl = offer[i18n.language]
  const c = useMemo(() => currencyFormatterFactory('brl'), [])

  const quotasProgress = useMemo(() => {
    const x = reservedQuotas !== undefined ? reservedQuotas * 100 : undefined
    return {
      toTotal: x !== undefined ? x / totalQuotas : undefined,
      toGoal: (goalQuotas * 100) / totalQuotas,
      toReserved: x !== undefined ? x / goalQuotas : undefined,
    }
  }, [reservedQuotas, totalQuotas, goalQuotas])

  return {
    currencyFormatter: c,
    intl,
    quotasProgress,
  }
}

export function useIPOInformation(offer: OfferType) {
  const unitPrice = offer.prices!.brl!
  const goal = offer.cvm88.unitsGoal

  return useMemo(() => {
    return {
      goalUnits: goal,
      goal: new BigNumber(goal)
        .multipliedBy(new BigNumber(unitPrice))
        .toNumber(),
      unitPrice: new BigNumber(unitPrice).toNumber(),
    }
  }, [offer])
}
