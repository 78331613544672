import * as Y from 'yup'

export const createBidSchema = (
  min: number,
  max: number,
  messages: { min: string; max: string; required: string }
) =>
  Y.object({
    amount: Y.number()
      .required(messages.required)
      .min(min, messages.min)
      .max(max, messages.max),
  })
