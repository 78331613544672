import { Channel, Task } from '@redux-saga/types'
import { actions as purchaseActions, types as purchaseActionTypes } from 'core/logic/purchase/purchase.reducer'
import { eventChannel } from 'redux-saga'
import { call, cancel, fork, put, take, takeLatest } from 'redux-saga/effects'
import * as purchaseService from './purchase.service'
import { PurchaseType } from './purchase.types'

// PURCHASES LIST ========

export async function purchasesUpdatesEmitter() {
  return eventChannel<PurchaseType[]>((emitter) => {
    const unsubscribe = purchaseService.fetchPurchases({ emitter })
    return () => {
      unsubscribe()
    }
  })
}

export function* purchasesSyncGenerator() {
  const emitterInterface: Channel<any> = yield call(purchasesUpdatesEmitter)
  try {
    while (true) {
      const purchasesChanged: PurchaseType[] = yield take(emitterInterface)
      yield put(purchaseActions.fetchPurchasesSuccess(purchasesChanged))
    }
  } catch (_e) {
    yield put(purchaseActions.fetchPurchasesFailed('purchases.subscribe'))
  } finally {
    emitterInterface.close()
  }
}

function* startPurchasesSyncChannel({ payload }: ReturnType<typeof purchaseActions.emitterPurchasesSubscribe>) {
  const purchasesSyncTask: Task = yield fork(purchasesSyncGenerator)
  yield take(purchaseActionTypes.PURCHASES_EMITTER_UNSUBSCRIBE)
  yield cancel(purchasesSyncTask)
}

export function* watchPurchasesSubscribe() {
  yield takeLatest(purchaseActionTypes.PURCHASES_EMITTER_SUBSCRIBE, startPurchasesSyncChannel)
}

// SINGLE PURCHASE ========

export async function purchaseUpdatesEmitter({ purchaseId }: { purchaseId: string }) {
  return eventChannel<PurchaseType>((emitter) => {
    const unsubscribe = purchaseService.fetchPurchase({ purchaseId, emitter })
    return () => {
      unsubscribe()
    }
  })
}

export function* purchaseSyncGenerator({ purchaseId }: { purchaseId: string }) {
  const emitterInterface: Channel<any> = yield call(purchaseUpdatesEmitter, { purchaseId })
  try {
    while (true) {
      const purchaseChanged: PurchaseType = yield take(emitterInterface)
      yield put(purchaseActions.fetchPurchaseSuccess(purchaseChanged))
    }
  } catch (_e) {
    yield put(purchaseActions.fetchPurchaseFailed('purchase.subscribe'))
  } finally {
    emitterInterface.close()
  }
}

function* startPurchaseSyncChannel({ payload }: ReturnType<typeof purchaseActions.emitterPurchaseSubscribe>) {
  if (!payload) throw new Error('Purchase ID missing')
  const purchaseSyncTask: Task = yield fork(purchaseSyncGenerator, { purchaseId: payload })
  yield take(purchaseActionTypes.PURCHASE_EMITTER_UNSUBSCRIBE)
  yield cancel(purchaseSyncTask)
}

export function* watchPurchaseSubscribe() {
  yield takeLatest(purchaseActionTypes.PURCHASE_EMITTER_SUBSCRIBE, startPurchaseSyncChannel)
}
