import { DropType } from 'core/logic/drop/drop.types'
import { useUser } from 'core/logic/user'
import DropDetails from './DropDetails.view'

interface DropDetailsLogicProps {
  drop?: DropType | null
}
const DropDetailsLogic = ({ drop, children }: React.PropsWithChildren<DropDetailsLogicProps>) => {
  const { detail } = useUser()

  return <DropDetails user={detail} drop={drop} children={children} />
}

export default DropDetailsLogic
