import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const style = (_theme: Theme) =>
  createStyles({
    noPaddingHorizontal: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    topRight: {
      marginRight: -12,
      marginTop: -10,
    },
    fullWidth: {
      width: '100%',
    },
  })
export default makeStylesWrapper(style)
