import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import { validateCEP } from 'core/helpers/validators'
import InputMask, {
  InputState,
  Props as InputMaskProps,
} from 'react-input-mask'

const beforeMaskedValueChange = (
  newState: InputState,
  oldState: InputState,
  userInput: string | null,
  opt: any
) => {
  // Workaround for mask cutting last char when autocomplete is used.
  if (userInput && validateCEP(userInput)) {
    return { ...newState, selection: { start: 8, end: 8 } }
  }
  return { ...newState }
}
const MaskedTextInput = ({
  mask,
  maskChar,
  alwaysShowMask,
  value,
  onChange,
  label,
  className,
  error,
  helperText,
  inputProps: externalInputProps,
  disabled,
}: InputMaskProps & TextFieldProps) => (
  <InputMask
    alwaysShowMask={alwaysShowMask}
    mask={mask}
    maskChar={maskChar}
    value={value}
    onChange={onChange}
    beforeMaskedValueChange={beforeMaskedValueChange}
    disabled={disabled}>
    {
      ((inputProps: TextFieldProps) => (
        <TextField
          label={label}
          className={className}
          error={error}
          helperText={helperText}
          inputProps={externalInputProps}
          disabled={disabled}
          {...inputProps}
        />
      )) as any
    }
  </InputMask>
)

export default MaskedTextInput
