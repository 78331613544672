import React, { useEffect, useMemo, useState } from 'react'
import View from './AddressForm.view'
import { Address, AddressFormMode, AddressFormProps } from './AddressForm.types'
import { useCEPFetcher } from 'core/logic/cep/cep.hook'
import { validateCEP } from 'core/helpers/validators'
import { useFieldErrors } from 'features/CVM88/Form/Form.hook'
import { AddressFormField } from './AddressForm.types'
import { t } from 'translate/i18n'

export const FormFields: (
  lockFields: ReturnType<typeof useCEPFetcher>[0],
  control: {
    error?: any
    loading: boolean
    retry: () => void
  }
) => AddressFormField[] = (lockFields, control) => [
  {
    id: 'cep',
    type: 'text',
    title: t('authenticated.userProfile.profileForm.cep'),
    mask: '99999-999',
    disabled: false,
    error: control.error
      ? {
          label: t('authenticated.userProfile.error'),
          retry: control.retry,
        }
      : undefined,
  },
  {
    id: 'state',
    type: 'select',
    title: t('authenticated.userProfile.profileForm.state'),
    disabled: lockFields.state,
    options: [
      ['Acre', 'AC'],
      ['Alagoas', 'AL'],
      ['Amapá', 'AP'],
      ['Amazonas', 'AM'],
      ['Bahia', 'BA'],
      ['Ceará', 'CE'],
      ['Espírito Santo', 'ES'],
      ['Goiás', 'GO'],
      ['Maranhão', 'MA'],
      ['Mato Grosso', 'MT'],
      ['Mato Grosso do Sul', 'MS'],
      ['Minas Gerais', 'MG'],
      ['Pará', 'PA'],
      ['Paraíba', 'PB'],
      ['Paraná', 'PR'],
      ['Pernambuco', 'PE'],
      ['Piauí', 'PI'],
      ['Rio de Janeiro', 'RJ'],
      ['Rio Grande do Norte', 'RN'],
      ['Rio Grande do Sul', 'RS'],
      ['Rondônia', 'RO'],
      ['Roraima', 'RR'],
      ['Santa Catarina', 'SC'],
      ['São Paulo', 'SP'],
      ['Sergipe', 'SE'],
      ['Tocantins', 'TO'],
      ['Distrito Federal', 'DF'],
    ].map(([name, code]) => ({
      label: name,
      value: code,
    })),
  },
  {
    id: 'city',
    type: 'text',
    title: t('authenticated.userProfile.profileForm.city'),
    disabled: lockFields.city,
  },
  {
    id: 'neighbourhood',
    type: 'text',
    title: t('authenticated.userProfile.profileForm.neighbourhood'),
    disabled: lockFields.neighbourhood,
  },
  {
    id: 'address',
    type: 'text',
    title: t('authenticated.userProfile.profileForm.address'),
    disabled: lockFields.address,
  },
  {
    id: 'addressNumber',
    type: 'text',
    title: t('authenticated.userProfile.profileForm.number'),
    disabled: control.loading,
  },
  {
    id: 'complement',
    type: 'text',
    title: t('authenticated.userProfile.profileForm.complement'),
    disabled: false,
    optional: true,
  },
]

export default function AddressFormLogic({
  address: predefAddress,
  ...props
}: AddressFormProps) {
  const [address, setAddress] = useState<Address>(predefAddress)
  const { cep } = address

  const [lockFields, fetchingCEP, cepResult, cepFetchError, fetchCepAgain] =
    useCEPFetcher({
      cep,
      enable: props.mode === AddressFormMode.WRITE && !!validateCEP(cep),
    })

  useEffect(() => {
    if (cepResult) {
      setAddress((prev) => ({
        ...prev,
        city: cepResult.cidade!,
        state: cepResult.estado!,
        address: cepResult.logradouro! || prev.address,
        neighbourhood: cepResult.bairro! || prev.neighbourhood,
      }))
    }
  }, [cepResult])

  const isAddressComplete = useMemo(() => {
    return (
      !!address.cep &&
      !!address.state &&
      !!address.city &&
      !!address.address &&
      !!address.addressNumber &&
      !!address.neighbourhood
    )
  }, [address])

  const errors = useFieldErrors(
    [
      { id: 'cep', type: 'number' },
      { id: 'address', type: 'text' },
      { id: 'addressNumber', type: 'text' },
      { id: 'city', type: 'text' },
      { id: 'complement', type: 'text', optional: true },
      { id: 'neighbourhood', type: 'text' },
      { id: 'state', type: 'text' },
    ] as const,
    address
  )

  useEffect(() => {
    if (props.mode === AddressFormMode.WRITE)
      props.onAddress(address, isAddressComplete, errors)
  }, [isAddressComplete, address, props.mode])

  const IntlFormFields = useMemo(() => {
    return FormFields(lockFields, {
      loading: fetchingCEP,
      error: cepFetchError,
      retry: fetchCepAgain,
    })
  }, [lockFields, fetchingCEP, cepFetchError])

  return (
    <View
      onAddressFieldChange={(_t, id, val) =>
        setAddress((prev) => ({
          ...prev,
          [id]: val,
        }))
      }
      address={address}
      mode={props.mode}
      errors={'showErrors' in props && props.showErrors ? errors : {}}
      fields={IntlFormFields}
    />
  )
}
