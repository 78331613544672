import type { AuthProviders } from 'core/app/types'
import routes from 'core/modules/router'
import SocialSignIn from 'pages/Unauthenticated/SocialSignIn'
import { useParams, useRouteMatch } from 'react-router-dom'
import ConnectView from './Connect.view'
import { i18n } from 'translate/i18n'
import MetamaskView from 'components/Metamask'
type RouterParams = {
  provider: AuthProviders
}

const ConnectLogic = () => {
  const { provider } = useParams<RouterParams>()
  const isSignUp = useRouteMatch(routes.unauthenticated.signUp)
  const isSignIn = useRouteMatch(routes.unauthenticated.signIn)
  switch (provider) {
    case 'metamask':
      return (
        <ConnectView>
          <MetamaskView />
        </ConnectView>
      )
    case 'firebase':
    default:
      if (isSignUp) {
        return (
          <ConnectView>
            <SocialSignIn
              type='sign-up'
              heading={i18n.t('unauthenticated.connect.createAccount')}
            />
          </ConnectView>
        )
      } else if (isSignIn) {
        return (
          <ConnectView>
            <SocialSignIn
              type='sign-in'
              heading={i18n.t('unauthenticated.connect.login')}
            />
          </ConnectView>
        )
      }
      break
  }
  return null
}

export default ConnectLogic
