import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import ActionButton from 'components/ActionButton'
import { OfferAuctionBidModal } from 'components/offer/OfferAuctionBidModal'
import { OfferCountdown } from 'components/offer/OfferCountdown'
import OfferPrice, { offerPriceParameters } from 'components/offer/OfferPrice'
import { DropBidType } from 'core/logic/drop/drop.types'
import { TenantType } from 'core/logic/tenant/tenant.types'
import { Fragment, useMemo } from 'react'
import { i18n, t } from 'translate/i18n'
import { OfferAuctionBidList } from '../OfferAuctionBidList/OfferAuctionBidList'
import ClaimAuctionItem from './ClaimAuctionItem'
import KYCActionWrapper from './KYCActionWrapper'
import useStyle from './styles'
import { OfferAuctionRaceViewProps } from './types'

function SingleBidComp({
  label,
  initialBid,
  currencies,
}: {
  label: string
  initialBid: Pick<DropBidType, 'amount' | 'currency'>
  currencies: TenantType['currencies']
}) {
  return (
    <>
      <Typography variant='caption' component='p'>
        {label}
      </Typography>
      <OfferPrice
        {...offerPriceParameters({
          unitPrice: initialBid.amount,
          currency: initialBid.currency || currencies?.preferredCurrency,
        })}
        acceptedCurrencies={currencies?.accepted}
        preferredCurrency={initialBid.currency || currencies?.preferredCurrency}
      />
    </>
  )
}

export const OfferAuctionRaceView: React.FC<OfferAuctionRaceViewProps> = ({
  offer,
  bids,
  initialBid,
  currentBid,
  isAvailable,
  begin,
  expire,
  minBid,
  currency,
  tenant: { currencies },
  modalOpen,
  handleModal,
  alreadyGaveBid,
  loading,
  isExpired,
}) => {
  const classes = useStyle()

  const Action = useMemo(() => {
    if (offer.requirements?.kycForBid)
      return () => (
        <KYCActionWrapper
          disabled={alreadyGaveBid || loading}
          onAction={handleModal(true)}
          loading={false}
          text={
            <Fragment key={alreadyGaveBid ? 'w' : 'b'}>
              {t(
                alreadyGaveBid
                  ? 'components.offerAuctionRace.actions.yourLastBid'
                  : 'components.offerAuctionRace.bid'
              )}
            </Fragment>
          }
        />
      )
    return () => (
      <ActionButton
        text={t(
          alreadyGaveBid
            ? 'components.offerAuctionRace.actions.yourLastBid'
            : 'components.offerAuctionRace.bid'
        )}
        onClick={handleModal(true)}
        disabled={alreadyGaveBid || loading}
      />
    )
  }, [alreadyGaveBid, loading, offer.requirements?.kycForBid])

  return expire && begin ? (
    <>
      <Grid container direction='column' className={classes.grid}>
        <Paper>
          <Box className={classes.box}>
            {currentBid && initialBid ? (
              currentBid.amount === initialBid.amount ? (
                <SingleBidComp
                  currencies={currencies}
                  initialBid={initialBid}
                  label={i18n.t('components.offerAuctionRace.initialBid')}
                />
              ) : (
                <Grid container className={classes.offerAndBid} wrap='wrap'>
                  <Grid item>
                    <Typography variant='caption' component='p'>
                      {i18n.t('components.offerAuctionRace.lastBid')}
                    </Typography>
                    <OfferPrice
                      {...offerPriceParameters({
                        unitPrice: currentBid.amount,
                        currency:
                          currentBid.currency || currencies?.preferredCurrency,
                      })}
                      acceptedCurrencies={currencies?.accepted}
                      preferredCurrency={
                        currentBid.currency || currencies?.preferredCurrency
                      }
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant='caption' component='p'>
                      {i18n.t('components.offerAuctionRace.initialBid')}
                    </Typography>
                    <OfferPrice
                      {...offerPriceParameters({
                        unitPrice: initialBid.amount,
                        currency:
                          initialBid.currency || currencies?.preferredCurrency,
                      })}
                      acceptedCurrencies={currencies?.accepted}
                      preferredCurrency={
                        initialBid.currency || currencies?.preferredCurrency
                      }
                      size='md'
                    />
                  </Grid>
                </Grid>
              )
            ) : (
              <SingleBidComp
                currencies={currencies}
                initialBid={{
                  amount: minBid!,
                  currency: currency || currencies?.preferredCurrency,
                }}
                label={t('components.offerAuctionBidModal.minimumBid')}
              />
            )}
          </Box>
          <OfferCountdown expire={expire} begin={begin} />
        </Paper>
        {offer.type === 'auction' && isExpired && (
          <Box>
            <ClaimAuctionItem />
          </Box>
        )}
        {isAvailable && (
          <>
            {modalOpen && (
              <OfferAuctionBidModal
                offer={offer}
                open={modalOpen}
                handleModal={handleModal}
                onClose={handleModal(false)}
                initialBid={initialBid}
                currentBid={currentBid}
              />
            )}
            <Box>
              <Action />
            </Box>
            <OfferAuctionBidList offer={offer} bids={bids} />
          </>
        )}
      </Grid>
    </>
  ) : (
    <></>
  )
}
