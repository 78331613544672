import { builder } from 'core/helpers'
import * as TermsTypes from './terms.types'

// == TYPES ==
const TERMS_FETCH = 'terms/fetch/FETCH'
const TERMS_FETCH_SUCCESS = 'terms/fetch/SUCCESS'
const TERMS_FETCH_FAILED = 'terms/fetch/FAILED'

export const types = Object.freeze({
  TERMS_FETCH: TERMS_FETCH,
  TERMS_FETCH_SUCCESS: TERMS_FETCH_SUCCESS,
  TERMS_FETCH_FAILED: TERMS_FETCH_FAILED,
})

// == ACTIONS ==
const fetchTerms = () => builder.actionCreator<TermsTypes.TermsActionsTypes>(TERMS_FETCH)

const fetchTermsSuccess = (terms: TermsTypes.TermsType) => {
  return builder.actionCreator<TermsTypes.TermsActionsTypes, TermsTypes.TermsType>(TERMS_FETCH_SUCCESS, terms)
}

const fetchTermsFailed = (errorKey: string) => builder.actionCreator<TermsTypes.TermsActionsTypes, string>(TERMS_FETCH_FAILED, errorKey)

export const actions = Object.freeze({
  fetchTerms,
  fetchTermsSuccess,
  fetchTermsFailed,
})

export const mapActions = Object.freeze({
  [TERMS_FETCH]: fetchTerms,
  [TERMS_FETCH_SUCCESS]: fetchTermsSuccess,
  [TERMS_FETCH_FAILED]: fetchTermsFailed,
})

// == REDUCER ==
export const initialState: Readonly<TermsTypes.TermsState> = Object.freeze({
  terms: null,
  errorKey: '',
})

export const reducer = (
  state: Readonly<TermsTypes.TermsState> = initialState,
  action: Readonly<TermsTypes.TermsAction>
): TermsTypes.TermsState => {
  const { type, payload } = action
  switch (type) {
    case TERMS_FETCH:
      return { ...state, terms: initialState.terms }
    case TERMS_FETCH_SUCCESS:
      return { ...state, terms: payload as TermsTypes.TermsType }
    case TERMS_FETCH_FAILED:
      return { ...state, errorKey: payload as string }
    default:
      return state
  }
}
