import { i18n, t } from 'translate/i18n'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { AssetType } from 'core/logic/asset/asset.types'
import { TO_MY_COLLECTION } from 'core/modules/router'
import ActionButton from 'components/ActionButton'
import DropImage from 'components/DropImage'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import useStyles from './Opened.style'

export interface OpenedProps {
  packTitle: string
  assets?: AssetType[] | null
  onBackIntent: () => void
  mode?: 'purchase' | 'claim'
}

const Opened: React.FC<OpenedProps> = ({
  packTitle,
  assets,
  mode = 'purchase',
}) => {
  const {
    grid,
    menu,
    title,
    image,
    button,
    figure,
    spacing,
    subtitle,
    container,
    dropDescription,
  } = useStyles()
  const history = useHistory()
  const goToCollectionHandler = () => {
    history.push(TO_MY_COLLECTION())
  }

  const labels = useMemo<{
    title: TranslationCodesByTenant[keyof TranslationCodesByTenant]
  }>(() => {
    switch (mode) {
      case 'purchase':
        return { title: 'authenticated.openingDrop.opened.opened' }
      case 'claim':
        return { title: 'authenticated.openingDrop.claimed.claimed' }
    }
  }, [mode])

  return (
    <>
      <Box mb={4}>
        <Typography variant='h1' component='h1' className={title}>
          {t(labels.title, {
            packTitle: packTitle,
          })}
        </Typography>
      </Box>
      <Box mb={4}>
        <Typography variant='body1' component='span' className={subtitle}>
          {i18n.t('authenticated.openingDrop.opened.checkOut', {
            count: assets?.length
          })}
        </Typography>
      </Box>
      <Container maxWidth='lg' disableGutters className={container}>
        <div className={grid}>
          {assets &&
            assets.map((item, idx) => (
              <Box
                key={`pack-item-${idx}`}
                display='flex'
                flexDirection='column'
                alignItems='center'>
                <DropImage
                  src={item.media.coverUrl || item.media.imageUrl}
                  figureClass={figure}
                  imageClass={image}
                  alt={item?.name}
                />
                <Box className={dropDescription}>
                  <Typography variant='h4' component='h2' className={spacing}>
                    {item.name}
                  </Typography>
                </Box>
              </Box>
            ))}
        </div>
      </Container>
      <Box className={menu}>
        <ActionButton
          light
          text={i18n.t('authenticated.openingDrop.opened.myCollection')}
          onClick={goToCollectionHandler}
          className={button}
        />
      </Box>
    </>
  )
}

export default Opened
