import clsx from 'clsx'
import React, { useState, useRef, useEffect } from 'react'
import { PlayerTestIds } from './Player.e2e'
import useStyles from './Player.style'

export interface PlayerViewProps {
  autoplay: boolean
  controls: boolean
  muted: boolean
  loop: boolean
  poster?: string
  src: string
  className?: any
}
const PlayerView: React.FC<PlayerViewProps> = ({
  autoplay,
  controls,
  muted,
  loop,
  poster,
  src,
  className,
}) => {
  const classes = useStyles()
  const hideControls = !controls ? 'hide' : ''
  const videoPlayer = useRef<HTMLVideoElement>(null)
  const containerVideoPlayer = useRef(null)
  const [playing, setPlaying] = useState(false)
  const totalRef = useRef<HTMLDivElement>(null)
  const currentRef = useRef<HTMLDivElement>(null)
  const progressRef = useRef<HTMLDivElement>(null)
  const [totalTime, setTotalTime] = useState('0:00')
  const [progressBar] = useState('0%')
  const [volume, setVolume] = useState(0)
  const [loopVideo] = useState(loop ?? false)
  const [videoDone, setVideoDone] = useState(false)

  const startVideo = () => {
    if (videoPlayer != null && videoPlayer !== undefined) {
      setPlaying(true)
      setVideoDone(false)
      videoPlayer!.current!.play()
    }
  }
  const stopVideo = () => {
    if (videoPlayer != null && videoPlayer !== undefined) {
      setPlaying(false)
      videoPlayer!.current!.pause()
    }
  }
  const toogleVideo = (videoRunning: boolean) => {
    videoRunning ? stopVideo() : startVideo()
  }
  const updateCurrentTime = (e: any) => {
    let videoDuration = videoPlayer!.current!.duration
    let progressWidthVal = e.currentTarget.clientWidth
    let offsetX = e.nativeEvent.offsetX

    let resultPositionProgress = (offsetX / progressWidthVal) * videoDuration

    videoPlayer!.current!.currentTime = resultPositionProgress

    let totalMin = Math.floor(resultPositionProgress / 60)
    let totalSec = Math.floor(resultPositionProgress % 60)

    totalSec < 10
      ? (currentRef.current!.innerHTML = `${totalMin}:0${totalSec}`)
      : (currentRef.current!.innerHTML = `${totalMin}:${totalSec}`)
  }
  const changeVolume = (e: any) => {
    videoPlayer!.current!.volume = e.target.value / 100
    setVolume(e.target.value)
  }
  const toogleVolume = () => {
    if (volume === 0) {
      videoPlayer!.current!.volume = 0.5
      setVolume(50)
    } else {
      videoPlayer!.current!.volume = 0
      setVolume(0)
    }
  }

  useEffect(() => {
    if (videoPlayer != null) {
      if (videoPlayer!.current!.volume > 0 && volume === 0 && !muted) {
        setVolume(videoPlayer!.current!.volume)
      } else if (muted && videoPlayer!.current!.volume > 0 && volume === 0) {
        videoPlayer!.current!.volume = volume
      }
      /**
       * @description função quando carregou a primeira informação do vídeo
       */
      videoPlayer?.current?.addEventListener('loadeddata', (e: any) => {
        let videoDuration = e.target.duration
        let totalMin = Math.floor(videoDuration / 60)
        let totalSec = Math.floor(videoDuration % 60)

        totalSec < 10
          ? setTotalTime(`${totalMin}:0${totalSec}`)
          : setTotalTime(`${totalMin}:${totalSec}`)

        if (autoplay) {
          videoPlayer!.current!.play()
          setPlaying(true)
        }
      })
      /**
       * @description função quando vídeo esta rodando para atualizar o timer de execução
       */
      videoPlayer?.current?.addEventListener('timeupdate', (e: any) => {
        if (!currentRef.current || !progressRef.current) return
        let videoCurrentTime = e.target.currentTime
        let videoDuration = e.target.duration
        let totalMin = Math.floor(videoCurrentTime / 60)
        let totalSec = Math.floor(videoCurrentTime % 60)

        totalSec < 10
          ? (currentRef.current!.innerHTML = `${totalMin}:0${totalSec}`)
          : (currentRef.current!.innerHTML = `${totalMin}:${totalSec}`)

        let progressWidth = (videoCurrentTime / videoDuration) * 100
        progressRef.current!.style.width = `${progressWidth}%`
      })
      /**
       * @description função de stop do vídeo
       */
      videoPlayer?.current?.addEventListener('pause', () => {
        setPlaying(false)
      })
      /**
       * @description função de rodar/play do vídeo
       */
      videoPlayer?.current?.addEventListener('play', () => {
        setPlaying(true)
      })
      /**
       * @description função quando vídeo acaba
       */
      videoPlayer?.current?.addEventListener('ended', () => {
        if (loopVideo) {
          videoPlayer?.current?.play()
          setPlaying(true)
          setVideoDone(false)
        } else {
          setVideoDone(true)
        }
      })
    }
  }, [videoPlayer, loopVideo, volume, muted, autoplay])

  return (
    <section className={clsx(classes.containerGeral, className)}>
      <div className={classes.container}>
        <div className={classes.videoPlayer} ref={containerVideoPlayer}>
          <video
            ref={videoPlayer}
            poster={poster}
            src={src}
            autoPlay={autoplay}
            playsInline={autoplay}
            className={classes.video}></video>

          <div
            className={
              classes.playPause +
              (playing ? ' playing' : ' paused') +
              ' ' +
              hideControls
            }>
            <i
              className={
                'material-icons ' +
                (playing ? 'pause' : 'play') +
                ' ' +
                hideControls
              }
              onClick={() => toogleVideo(playing)}
              data-testid={PlayerTestIds.PLAY_PAUSE_CONTROL}>
              {videoDone ? 'replay' : playing ? 'pause' : 'play_arrow'}
            </i>
          </div>
          <div
            className={classes.overlayPlayer}
            onClick={() => toogleVideo(playing)}
            data-testid={PlayerTestIds.OVERLAY_CONTROL}></div>
          <div className={classes.controls + ' ' + hideControls}>
            <div
              className={classes.progressArea}
              onClick={updateCurrentTime}
              data-testid={PlayerTestIds.PROGRESS_BAR_CONTROL}>
              <div
                className={classes.progressBar}
                style={{ width: progressBar }}
                ref={progressRef}>
                <span></span>
              </div>
            </div>
            <div className={classes.controlsList + ' ' + hideControls}>
              <div className={classes.controlsLeft + ' ' + hideControls}>
                <span className='icon'>
                  <i
                    className='material-icons volume'
                    onClick={toogleVolume}
                    data-testid={PlayerTestIds.VOLUME_CONTROL}>
                    {volume < 1
                      ? 'volume_off'
                      : volume < 45
                      ? 'volume_down'
                      : 'volume_up'}
                  </i>
                  <input
                    type='range'
                    min='0'
                    max='100'
                    value={volume}
                    className={
                      classes.volumeRange + ' volume-range' + hideControls
                    }
                    onChange={changeVolume}
                  />
                </span>
                <div className={classes.timer + ' ' + hideControls}>
                  <span className='current' ref={currentRef}>
                    {'0:00'}
                  </span>{' '}
                  /{' '}
                  <span className='duration' ref={totalRef}>
                    {totalTime}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PlayerView
