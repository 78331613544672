import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) =>
  createStyles({
    offerAndBid: {
      '& > :first-child': {
        minWidth: 'calc(50% - 16px)',
        marginRight: '16px'
      },
    },
    grid: {
      '&>*': {
        marginBottom: theme.spacing(2),
      },
      '&>*:last-child': {
        marginBottom: 0,
      },
    },
    box: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  })

export default makeStylesWrapper(style)
