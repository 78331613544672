import { i18n } from 'translate/i18n'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'
import { DropType } from 'core/logic/drop/drop.types'
import DropImage from 'components/DropImage'
import ProfileAvatar from 'components/ProfileAvatar'
import React from 'react'
import { FirebaseReducer } from 'react-redux-firebase'
import useStyles from './DropDetails.style'
import OfferPrice, { offerPriceParameters } from 'components/offer/OfferPrice'
import { useTenant } from 'core/logic/tenant/tenant.hook'

interface DropDetailsProps {
  drop?: DropType | null
  user?: FirebaseReducer.AuthState
}
const DropDetails = ({ drop, user, children }: React.PropsWithChildren<DropDetailsProps>) => {
  const classes = useStyles()
  const {tenant} = useTenant()
  return (
    <>
      <Typography variant='subtitle2' paragraph>
        {i18n.t('authenticated.openDrop.dropDetails.success')}
      </Typography>
      <Grid container spacing={0}>
        <Grid item xs={12} md={6}>
          <DropImage
            src={drop?.image}
            imageClass={classes.image}
            figureClass={classes.figure}
          />
          {children}
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant='body2'>
            {i18n.t('authenticated.openDrop.dropDetails.recipient')}
          </Typography>
          <Box
            display='flex'
            alignItems='center'
            className={classes.bottomSpacing}>
            <Box mr={1}>
              <ProfileAvatar showUserName={false} />
            </Box>
            <Typography variant='body2' className={classes.userData}>
              {user ? (
                <>
                  <b>{user.displayName}</b>
                  <br />
                  <b>{user.email}</b>
                </>
              ) : (
                <>
                  <Skeleton variant='text' width='100%' height={25} />
                  <Skeleton variant='text' width='100%' height={25} />
                </>
              )}
            </Typography>
          </Box>
          <Typography variant='body2'>
            {i18n.t('authenticated.openDrop.dropDetails.itemName')}
          </Typography>
          <Typography variant='body2' className={classes.bottomSpacing}>
            {drop ? (
              <b>{drop?.title}</b>
            ) : (
              <Skeleton variant='text' width='80%' height={45} />
            )}
          </Typography>
          <Typography variant='body2'>
            {i18n.t('authenticated.openDrop.dropDetails.value')}
          </Typography>
          <OfferPrice
            {...offerPriceParameters({
              currency: drop?.currency,
              unitPrice: drop?.unitPrice,
              prices: drop?.prices,
            })}
            size='sm'
            acceptedCurrencies={tenant?.currencies?.accepted}
            preferredCurrency={tenant?.currencies?.preferredCurrency}
          />
        </Grid>
      </Grid>
      <Box textAlign='center'></Box>
    </>
  )
}
export default DropDetails
