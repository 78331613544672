import IAuctionFacade from 'service/IAuctionFacade'
import { RARUM_NFT_Auction } from 'core/constants/RARUMNFTAuction'
import { DropBidType, OfferType } from 'core/logic/drop/drop.types'
import { convertToDecimal, convertToInteger } from 'core/helpers/convertNumbers'
import { ERC20ABI } from 'core/constants'
import { CurrenciesEnum } from 'core/types/CurrenciesEnum'
import { sendAndWaitForConfirmation } from 'core/helpers/contract'
import { RarumUserProfile } from 'core/logic/user/user.types'
import Web3 from 'web3'

export default class OnChainAuctionFacade extends IAuctionFacade {
  private tokenDecimals?: string
  private tokenSymbol?: CurrenciesEnum
  private wallet!: string

  private tokenContract!: Contract.IERC20
  private userTokenContract!: Contract.IERC20

  private contract: Contract.Auction
  private userContract: Contract.Auction

  private async loadProperty<T>(
    propertyName: string,
    fallbackLoad: () => Promise<T>
  ): Promise<T> {
    return (
      (this as any)[propertyName] ||
      ((this as any)[propertyName] = (await fallbackLoad()) as any)
    )
  }

  constructor(
    drop: OfferType,
    address: string,
    web3ForRead: Web3,
    web3ForWrite: Web3
  ) {
    super(drop, web3ForRead, web3ForWrite)
    this.web3ForRead.eth.handleRevert = true
    this.contract = new this.web3ForRead.eth.Contract(
      RARUM_NFT_Auction,
      address
    ) as any
    this.userContract = new this.web3ForWrite.eth.Contract(
      RARUM_NFT_Auction,
      address
    ) as any
  }
  setWallet(wallet: string) {
    this.wallet = wallet
  }
  getAuctionPeriod: IAuctionFacade['getAuctionPeriod'] = () => {
    return Promise.all([
      this.contract.methods
        .start()
        .call()
        .then(Number)
        .then((time) => new Date(time * 1000)),
      this.contract.methods
        .end()
        .call()
        .then(Number)
        .then((time) => new Date(time * 1000)),
    ] as const)
  }
  getInformation: IAuctionFacade['getInformation'] = async () => {
    if (!this.tokenDecimals) {
      const tokenAddress = await this.contract.methods.paymentToken().call()
      this.tokenContract = new this.web3ForRead.eth.Contract(
        ERC20ABI,
        tokenAddress
      ) as any
      this.tokenDecimals = await this.tokenContract.methods.decimals().call()
      this.tokenSymbol = (await this.tokenContract.methods
        .symbol()
        .call()) as CurrenciesEnum

      this.userTokenContract = new this.web3ForWrite.eth.Contract(
        ERC20ABI,
        tokenAddress
      ) as any
    }

    const minPrice = await this.loadProperty('minPrice', async () =>
      Number(
        convertToDecimal(
          await this.contract.methods.minPrice().call(),
          this.tokenDecimals!
        )
      )
    )

    const MIN_RATE = await this.loadProperty('minIncrease', () =>
      this.contract.methods.BID_MIN_INCREASE_FACTOR().call().then(Number)
    )
    const MAX_RATE = await this.loadProperty('maxIncrease', () =>
      this.contract.methods.BID_MAX_INCREASE_FACTOR().call().then(Number)
    )

    const getBidRange: Awaited<
      ReturnType<IAuctionFacade['getInformation']>
    >['getBidRange'] = (latestBid) => {
      return this.calculateRange(minPrice, latestBid?.amount, {
        max: MAX_RATE,
        min: MIN_RATE,
      })
    }

    return {
      getBidRange,
      currency: this.tokenSymbol!,
      getBidPercentRange: () => {
        return [MIN_RATE * 100, MAX_RATE * 100]
      },
    }
  }
  watchBids: IAuctionFacade['watchBids'] = (onBids) => {
    const refreshBids = () => {
      if (!this.tokenDecimals) return
      this.contract.methods
        .bidList(String(this.offerType.asset.tokenId))
        .call()
        .then((bidList) => {
          const _bidList = bidList
          const bids = _bidList
            .map(
              (bid: any) =>
                ({
                  amount: Number(
                    convertToDecimal(bid.amount, this.tokenDecimals!)
                  ),
                  tenantId: '',
                  offerId: '',
                  user: {
                    wallet: bid.bidder.toLowerCase(),
                  },
                  currency: this.tokenSymbol,
                } as DropBidType)
            )
            .sort((a, b) => b.amount - a.amount)
          onBids(bids)
        })
    }
    if (!this.tokenDecimals) {
      this.getInformation().then(() => refreshBids())
    } else {
      refreshBids()
    }

    const watchBidsInterval = setInterval(refreshBids, 5000)
    return () => {
      clearInterval(watchBidsInterval)
    }
  }
  createBid: IAuctionFacade['createBid'] = async (amount) => {
    return await sendAndWaitForConfirmation(
      this.userContract.methods
        .bid(
          String(this.offerType.asset.tokenId),
          convertToInteger(String(amount), this.tokenDecimals!)
        )
        .send({
          from: this.wallet,
          ...(await this.calculateGas()),
        })
    )
  }
  async getTokenAllowance() {
    if (!this.tokenDecimals) await this.getInformation()
    return Number(
      convertToDecimal(
        await this.tokenContract.methods
          .allowance(this.wallet, this.contract.options.address)
          .call(),
        this.tokenDecimals!
      )
    )
  }
  async approveAllowance(howMuch: number) {
    return await sendAndWaitForConfirmation(
      this.userTokenContract.methods
        .approve(
          this.contract.options.address,
          convertToInteger(String(howMuch), this.tokenDecimals!)
        )
        .send({
          from: this.wallet,
          ...(await this.calculateGas()),
        })
    )
  }
  async getBalance(): Promise<number> {
    const tokens = await this.tokenContract.methods
      .balanceOf(this.wallet)
      .call()
    return Number(convertToDecimal(tokens, this.tokenDecimals!))
  }
  isBidOwned(
    user: RarumUserProfile | undefined,
    bid: Pick<DropBidType<'auction'>, 'user'>
  ): boolean {
    return bid.user.wallet.toLowerCase() === user?.wallet?.toLowerCase()
  }

  /**
   * Is offer settled
   */
  async isOfferSettled() {
    return await this.contract.methods
      .auctionSettled(String(this.offerType.asset.tokenId))
      .call()
  }

  /**
   * The user claims the token
   */
  async claim(): Promise<void> {
    return await this.userContract.methods
      .settle(String(this.offerType.asset.tokenId))
      .send({
        from: this.wallet,
        ...(await this.calculateGas()),
      })
  }

  /**
   * Returns the current winning bid
   */
  async getWinningBid(): Promise<{
    bidder: string
    amount: number
  } | null> {
    if (!this.tokenDecimals) await this.getInformation()
    try {
      const [bidder, amount] = await this.contract.methods
        .currentValidBid(String(this.offerType.asset.tokenId))
        .call()
      return {
        bidder: bidder.toLowerCase(),
        amount: Number(convertToDecimal(amount, this.tokenDecimals!)),
      }
    } catch (e) {
      throw e
    }
  }
}
