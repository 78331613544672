import { service } from 'core/modules/firebase'
import { TenantType } from './tenant.types'
import { Chains } from 'context/Chain';

/** @dev missing sepolia */
type AllChains = "amoy" | "polygon" | "moonbeam" | 'moonbase';

const chainMap = (chain: AllChains): Chains => {
  return ({
    amoy: 'POLYGON',
    polygon: 'POLYGON',
    moonbeam: 'MOONBEAM',
    moonbase: 'MOONBEAM',
  } as const)[chain]
}

export const fetchTenant = async (): Promise<TenantType | null> => {
  const doc = await service.fetchTenant()
  const data = doc.data()

  if (!data) return null

  return {
    ...(data as TenantType),
    created: new Date(data.created),
    chain: chainMap(data.chain as AllChains),
  }
}
