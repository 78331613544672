import { Box, Card, CardContent, Divider, Typography } from "@material-ui/core"
import BackButton from "components/BackButton"
import RarumHeader from "components/RarumHeader"
import { useHistory } from "react-router-dom"
import { t } from "translate/i18n"
import useStyles from './NoAuth.styles'

const NoAuthView = () => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Card className={classes.card}>
        <CardContent>
          <RarumHeader />
        </CardContent>
        <Divider className={classes.divider} />
        <CardContent>
          <Box display='flex' flexDirection='row' alignItems='center'>
            <Box className={classes.figure} />
            <Box
              display='flex'
              flexDirection='column'
              alignItems='start'
              mt={5.5}
              mb={5.5}>
                <Typography variant='h2'>{t('unauthenticated.socialSign.noAuth.title')}</Typography>
                <Typography variant='body1'>{t('unauthenticated.socialSign.noAuth.description')}</Typography>
            </Box>
          </Box>
        </CardContent>
        <Divider className={classes.divider} />
        <CardContent>
          <Box
            mt={3}
            mb={2.5}
            display='flex'
            flexDirection='column'
            alignItems='start'>
              <BackButton
                text={t('unauthenticated.socialSign.noAuth.goBack')}
                onClick={() => history.push('/')}
              />
          </Box>
        </CardContent>
      </Card>
  )
}

export default NoAuthView
