import { Avatar, Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import IconRarum from 'components/IconRarum/IconRarum.view'
import RarumAdaptiveSidebar from 'components/RarumAdaptiveSidebar/RarumAdaptiveSidebar'
import { currencyNumberFormatter } from 'core/helpers/formatter'
import { AssetType, Sale } from 'core/logic/asset/asset.types'
import { useUser } from 'core/logic/user'
import { i18n, t } from 'translate/i18n'
import Styles from './DestinatedOfferInfo.module.scss'
import Spacing from '@onepercentio/one-ui/dist/components/Spacing/Spacing'
import Text from '@onepercentio/one-ui/dist/components/Text'
import PingPongText from '@onepercentio/one-ui/dist/components/PingPongText'
import { useMemo } from 'react'
import Control from 'components/RarumAdaptiveSidebar/Control'
import { OfferType } from 'core/logic/drop/drop.types'

export default function DestinatedOfferInfo({
  assetOrOffer,
  direction = 'h',
  ...props
}: {
  assetOrOffer?: AssetType | OfferType
  'data-testid'?: string
  direction?: 'v' | 'h'
} & (
  | {
      isSale?: false
    }
  | {
      isSale: true
      sale?: Sale
      userBalance?: number
      insuficientBalance?: boolean
    }
)) {
  const { profile: user, detail } = useUser()

  const Wrapper = useMemo<typeof RarumAdaptiveSidebar>(() => {
    if (direction === 'h') {
      return (props) => <RarumAdaptiveSidebar {...props} />
    } else {
      return ({ children }) => <div>{children}</div>
    }
  }, [direction])

  const info = useMemo(() => {
    if (!assetOrOffer) return
    if ('asset' in assetOrOffer) {
      const offer = assetOrOffer as OfferType
      return {
        name: offer[i18n.language].title,
        image: offer.image,
      }
    } else {
      const asset = assetOrOffer as AssetType
      return {
        name: asset[i18n.language].name,
        image: assetOrOffer.media.imageUrl,
      }
    }
  }, [assetOrOffer])

  return (
    <div className={`${Styles[direction]} ${Styles.root}`}>
      <Wrapper className={Styles.float} visibilityControlComponent={Control}>
        {!assetOrOffer ? (
          <Skeleton
            height={'30rem'}
            width={'20rem'}
            style={{ transform: 'initial', marginTop: '24px' }}
          />
        ) : (
          <img
            alt={info?.name}
            src={info?.image}
            style={{ maxWidth: '300px', maxHeight: '90vh' }}
          />
        )}
      </Wrapper>
      {direction === 'v' && <Spacing size='32' />}
      <Grid item className={Styles.info}>
        <Text type='small'>{t('marketplace.buyOffer.destinatary')}</Text>
        {!user ? (
          <Skeleton
            height={'3rem'}
            style={{ transform: 'initial', marginTop: '24px' }}
          />
        ) : (
          <div className={`${Styles.root}`}>
            <div className={Styles.avatar}>
              <Avatar src={detail?.photoURL!}>
                {<IconRarum icon='user' />}
              </Avatar>
            </div>
            <div className={Styles.userInfo}>
              <Text type='smallBold'>
                <b>{user.name}</b>
              </Text>
              <PingPongText type='smallBold'>
                <b>{user.email}</b>
              </PingPongText>
            </div>
          </div>
        )}
        {direction === 'v' && <Spacing size='32' />}
        <Text type='small'>{t('marketplace.buyOffer.itemName')}</Text>
        {!assetOrOffer ? (
          <Skeleton
            height={'2rem'}
            style={{ transform: 'initial', marginTop: '24px' }}
          />
        ) : (
          <Text type='smallBold'>{info?.name}</Text>
        )}
        {props.isSale && (
          <>
            <Text type='small'>{t('marketplace.buyOffer.value')}</Text>
            {!props.sale || props.userBalance === undefined ? (
              <Skeleton
                height={'2rem'}
                style={{ transform: 'initial', marginTop: '24px' }}
              />
            ) : (
              <>
                <Text type='smallBold'>
                  <b>
                    {props.sale.currency}{' '}
                    {currencyNumberFormatter(props.sale.price)}
                  </b>
                </Text>
                {props.insuficientBalance ? (
                  <Text type='smallBold' color='error'>
                    {t('marketplace.buyOffer.balanceWarning', {
                      currency: props.sale.currency,
                      amount: props.userBalance,
                    })}
                  </Text>
                ) : null}
              </>
            )}
          </>
        )}
      </Grid>
    </div>
  )
}
