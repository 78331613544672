import Web3 from 'web3'

export default class Web3Factory {
  /** This **requires metamask or other provider to be installed** */
   web3ForWrite!: Web3

  /** This is used for querying without requiring metamask or other providers to be installed */
   web3ForRead!: Web3

  constructor(web3ForRead: Web3, web3ForWrite: Web3) {
    this.web3ForRead = web3ForRead
    this.web3ForWrite = web3ForWrite
  }
}
