import { COLLECTIBLES_ABI } from 'core/constants/CollectiblesABI'
import ERC1155AbstractFacade from './ERC1155AbstractFacade'

export default class ERC1155CollectiblesFacade extends ERC1155AbstractFacade<Contract.Collectibles> {
  async getNormalizedBalance(forWallet: string) {
    const result = (await this.nftContractForRead.methods
      .collectionOf(forWallet)
      .call()) as any

    return {
      balances: result[1],
      tokens: result[0],
    }
  }
  getContractInstance() {
    return new this.web3ForRead.eth.Contract(
      COLLECTIBLES_ABI,
      this.contractAddress
    ) as unknown as Contract.Collectibles
  }
}
