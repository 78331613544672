import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useUser } from './User'
import {
  ADDRESS_FORM_ANSWERS,
  ALL_POSSIBLE_ANSWERS,
  PERSONAL_DATA_FORM_ANSWERS,
} from 'features/FlowPrerequirements/StandalonePrereq/StandalonePrereq.types'
import { RarumUserProfile } from 'core/logic/user/user.types'
import { GalleryType } from 'core/logic/gallery/gallery.types'
import { FormFieldView, FormMode } from 'features/CVM88/Form/Form.types'
import {
  useFieldErrors,
  useFormAnswers,
  useFormState,
} from '@onepercentio/one-ui/dist/components/Form/v2/Form.hook'
import { EscapedTranslation, t, tO } from 'translate/i18n'
import { FormField } from '@onepercentio/one-ui/dist/components/Form/v2/FormField/FormField.types'
import { merge, pick } from 'lodash'
import { DOCUMENT_FIELD, cleanFormObject } from 'utility/fields'
import useAsyncControl from '@onepercentio/one-ui/dist/hooks/useAsyncControl'

export const ADDRESS_FORM_FIELDS = (required: boolean) =>
  [
    { type: 'address', optional: !required, id: 'address' },
  ] as const satisfies FormField[]

export const PERSONAL_DATA_FORM_FIELDS = (isForeign: boolean) =>
  [
    {
      type: 'text',
      id: 'name',
    },
    {
      type: 'text',
      id: 'email',
    },
    {
      type: 'phone',
      id: 'phone',
    },
    isForeign
      ? ({
          type: 'text',
          id: 'document',
        } as const satisfies FormField)
      : DOCUMENT_FIELD('document'),
    {
      type: 'check',
      id: 'isForeign',
      options: [
        {
          label: <>{t('authenticated.payment.steps.stepUserData.foreigner')}</>,
          labelStr: t('authenticated.payment.steps.stepUserData.foreigner'),
          value: 'foreign',
        },
      ],
    },
  ] as const satisfies FormField[]

export const REQUIRE_DATA_SHARE_FIELD = (
  gallery: GalleryType,
  isRequired: boolean = true
) =>
  [
    {
      type: 'check',
      id: 'confirmedDataSharing',
      options: [
        {
          label: (
            <EscapedTranslation
              code='flowRequirements.steps.personalData.questions.confirm'
              props={{
                gallery: gallery['pt-BR'].title,
              }}
            />
          ),
          labelStr: t('flowRequirements.steps.personalData.questions.confirm', {
            gallery: gallery['pt-BR'].title,
          }),
          value: 'dataSharing',
        },
      ],
      optional: !isRequired,
    },
  ] as const satisfies FormField[]

export enum PrereqStep {
  PERSONAL_DATA = 'personalData',
  ADDRESS = 'address',
}

export declare type DeepRequired<T> = T extends Object
  ? Required<{
      [K in keyof T]: DeepRequired<T[K]>
    }>
  : NonNullable<T>

export type PrerequireableShape = DeepRequired<Prerequireable>

/**
 * Details that the related entity can have prerequirements set
 */
export type Prerequireable = {
  // The document will be able to have the requirements defined
  // Keep in mind that the requirement could be undefined when the requirements are not required (Did you notice how many "requires" are in this line? lol)
  // If the key exists, the form will be presented
  requirements?: Partial<{
    // These fields will define which fields are required inside the personal data step
    [PrereqStep.PERSONAL_DATA]: ('document' | 'name' | 'email' | 'phone')[]

    // This property will indicate if the address step is required or not
    [PrereqStep.ADDRESS]: boolean
  }>
}

export const FlowPrerequirementsContext = createContext<{
  entity?: Prerequireable
}>(null as any)

export default function FlowPrerequirementsProvider({
  requirement,
  children,
}: PropsWithChildren<{
  requirement?: Prerequireable
}>) {
  return (
    <FlowPrerequirementsContext.Provider value={{ entity: requirement }}>
      {children}
    </FlowPrerequirementsContext.Provider>
  )
}

function _useCtx() {
  return useContext(FlowPrerequirementsContext)
}

export function useSteps() {
  const ctx = _useCtx()
  return ctx.entity
    ? (Object.keys(ctx.entity.requirements ?? {}) as PrereqStep[])
    : undefined
}

export function usePendingSteps(
  galleryToShareDataWith: GalleryType | undefined
) {
  const { profile } = useUser()
  const ctx = _useCtx()
  const requiredSteps = useSteps()
  return useMemo(() => {
    const galleryConsents =
      galleryToShareDataWith === undefined
        ? true
        : profile?.personalData?.tenants?.[process.env.REACT_APP_TENANT]
            .galleries?.[galleryToShareDataWith.id]?.dataConsent
    return requiredSteps!.filter((step) => {
      switch (step) {
        case PrereqStep.ADDRESS:
          const addressAnswer = answersForStep(
            step,
            profile!
          ) as ADDRESS_FORM_ANSWERS

          const filledAddress = addressAnswer.address?.[0]

          /** Shows address if some of the address fields are not defined */
          return (
            !galleryConsents ||
            filledAddress === undefined ||
            Object.keys(filledAddress).some(
              (field) =>
                filledAddress[field as keyof typeof filledAddress] === undefined
            )
          )
        case PrereqStep.PERSONAL_DATA:
          const filledPersonalData = answersForStep(step, profile!)
          const requiredFields =
            ctx.entity!.requirements![PrereqStep.PERSONAL_DATA]!
          const isMissingField = requiredFields.some(
            (field) =>
              filledPersonalData[field as keyof typeof filledPersonalData] ===
              undefined
          )

          /** Show the personal data if:
           * - the user has not accepted data share yet or;
           * - there is a required field that has not been filled yet */
          return !galleryConsents || isMissingField
      }
    })
  }, [requiredSteps])
}

export function useStepConfig(step: PrereqStep) {
  const ctx = _useCtx()

  return ctx.entity!.requirements![step]
}

export function useAnswersForStep(step: PrereqStep) {
  const { profile } = useUser()
  return useMemo(() => answersForStep(step, profile!), [step])
}

function answersForStep(step: PrereqStep, profile: RarumUserProfile) {
  switch (step) {
    case PrereqStep.ADDRESS:
      return {
        address: [
          {
            cep: profile.personalData?.cep,
            state: profile.personalData?.state,
            city: profile.personalData?.city,
            address: profile.personalData?.address,
            addressNumber: profile.personalData?.addressNumber,
            neighbourhood: profile.personalData?.neighbourhood,
          },
          true,
          false,
        ],
      } as NonNullable<ADDRESS_FORM_ANSWERS>
    case PrereqStep.PERSONAL_DATA:
      return {
        name: profile.personalData?.name,
        email: profile.personalData?.email,
        phone: profile.personalData?.phoneNumber
          ? [profile.personalData?.phoneNumber, true, null]
          : undefined,
        document: profile.personalData?.cpf,
      } satisfies Omit<PERSONAL_DATA_FORM_ANSWERS, 'confirmedDataSharing'>
  }
}

export function useFormForStep(
  prefilledAnswers: ALL_POSSIBLE_ANSWERS,
  step: PrereqStep,
  isFirstStep: boolean,
  galleryToShareDataWith: {
    gallery: GalleryType
    isRequired: boolean
  }
) {
  const { profile } = useUser()
  const config = useStepConfig(step)
  const _defaultAnswers = useAnswersForStep(step)
  const defaultAnswers = useMemo(() => {
    if (isFirstStep) {
      const consentsUserHaveGiven =
        profile?.personalData?.tenants?.[process.env.REACT_APP_TENANT]
          ?.galleries?.[galleryToShareDataWith.gallery.id]?.dataConsent?.[0]

      ;(_defaultAnswers as ALL_POSSIBLE_ANSWERS).confirmedDataSharing = [
        consentsUserHaveGiven === undefined
          ? false
          : consentsUserHaveGiven.value,
      ]
    }
    return _defaultAnswers
  }, [isFirstStep])
  const answersState = useFormAnswers<ALL_POSSIBLE_ANSWERS>(
    { ...defaultAnswers, ...prefilledAnswers },
    FormMode.WRITE
  )
  const stepQuestions = useMemo(() => {
    switch (step) {
      case PrereqStep.ADDRESS:
        const isRequired =
          config as PrerequireableShape['requirements']['address']
        return ADDRESS_FORM_FIELDS(isRequired).map((a) => a) as FormFieldView[]
      case PrereqStep.PERSONAL_DATA:
        const tX = (a: keyof ALL_POSSIBLE_ANSWERS) => {
          switch (a) {
            case 'document':
              if (!!answersState.answers.isForeign?.[0])
                return tO(
                  `flowRequirements.steps.personalData.questions.documentForeign`
                )
              else
                return tO(
                  `flowRequirements.steps.personalData.questions.document`
                )
            default:
              return tO(
                `flowRequirements.steps.personalData.questions.${a as 'name'}`
              )
          }
        }

        const isFieldOptional = (
          field: PrerequireableShape['requirements']['personalData'][number]
        ) => {
          const requiredFields =
            config as PrerequireableShape['requirements']['personalData']
          const isFieldRequired = requiredFields?.includes(field) || false

          return isFieldRequired === false ? true : undefined
        }
        return [
          ...PERSONAL_DATA_FORM_FIELDS(
            !!answersState.answers.isForeign?.[0]
          ).map((a) => ({
            ...a,
            title: tX(a.id),
            optional: isFieldOptional(a.id as 'document'),
          })),
        ] as FormFieldView[]
    }
  }, [
    step,
    galleryToShareDataWith.gallery,
    answersState.answers.isForeign?.[0],
  ])!

  const [showErrors, setShowErrors] = useState(false)
  useEffect(() => setShowErrors(false), [step])

  const questions = useMemo(() => {
    if (isFirstStep)
      return [
        ...stepQuestions,
        ...REQUIRE_DATA_SHARE_FIELD(
          galleryToShareDataWith.gallery,
          galleryToShareDataWith.isRequired
        ),
      ]
    else return stepQuestions
  }, [stepQuestions])
  const { isQuestionsAnswered, answers, onAnswerAction } = useFormState(
    questions as any,
    answersState as any
  )
  const errors = useFieldErrors(questions, answers, showErrors)

  const sections = useMemo(
    () => [
      {
        title: tO(`flowRequirements.steps.${step}.title`),
        questions,
      },
    ],
    [questions]
  )

  return {
    sections,
    errors,
    isQuestionsAnswered,
    onAnswerAction,
    setShowErrors,
    questions,
    answers,
  }
}

export function usePrerequirements(...prereqs: Prerequireable[]) {
  return useMemo(() => {
    const result = { requirements: {} } as Prerequireable
    for (let prereq of prereqs) merge(result, pick(prereq, 'requirements'))

    return result
  }, [...prereqs])
}

export function useUpdatePrereqAnswers(gallery: GalleryType) {
  const { setAcceptDataUsage, updateProfile } = useUser()

  const savePrereq = useAsyncControl({
    updateProfile: async (
      stepsToUpdate: PrereqStep[],
      answers: ALL_POSSIBLE_ANSWERS
    ) => {
      await setAcceptDataUsage(answers.confirmedDataSharing![0], gallery)

      if (stepsToUpdate.includes(PrereqStep.ADDRESS) && answers.address?.[1]) {
        const address = answers.address[0]
        cleanFormObject(address)
        await updateProfile({
          personalData: {
            ...address,
          },
        })
      }
      if (stepsToUpdate.includes(PrereqStep.PERSONAL_DATA)) {
        const personalDataAnswers = answers as PERSONAL_DATA_FORM_ANSWERS
        const fieldsToUpdate: Partial<RarumUserProfile['personalData']> = {
          name: personalDataAnswers.name ?? '',
          email: personalDataAnswers.email ?? '',
          cpf: personalDataAnswers.document ?? '',
          phoneNumber: personalDataAnswers.phone?.[0] ?? '',
        }
        fieldsToUpdate.phoneNumber = fieldsToUpdate.phoneNumber!.replace(
          /[^0-9]/g,
          ''
        )
        cleanFormObject(fieldsToUpdate)
        await updateProfile({
          personalData: fieldsToUpdate,
        })
      }
    },
  })

  return savePrereq
}
