import { MenuOptions } from '../../components/TopBar/TopBar.types'
import { RarumIntlShape } from './pt'

const Styles = (() => {
  try {
    return require('openspace/assets/styles/TextStyles.module.scss')
  } catch (e) {
    return {}
  }
})()

const messages = {
  'en-US': {
    translations: {
      flowRequirements: {
        steps: {
          personalData: {
            title: 'Personal Information',
            questions: {
              name: 'Full Name',
              email: 'Email',
              phone: 'Phone',
              document: 'CPF',
              documentForeign: 'Document',
              isForeign: '',
              confirm:
                'I accept sharing my data with <b>({{gallery}})</b> for marketing purposes',
            },
            error: {
              invalidCPF: 'Invalid document',
            },
          },
          address: {
            title: 'Address',
          },
        },
        standalone: {
          hi: 'Hello, {{name}}',
          to: {
            claim: {
              title: 'Complete the registration to proceed with the claim',
              action: 'Claim',
              loading: 'Please wait, we are proceeding to the claim',
            },
          },
        },
      },
      forum: {
        postedAt: 'Posted {{time}} ago',
        noItems:
          'Have questions? \nUse the field above and be the first to ask',
        post: {
          placeholder: 'Write your question here',
          cancelConfirmation: 'Do you want to discard the written message?',
          loggedOutUser: 'Hello, login is required to interact',
          actions: {
            cancel: 'Cancel',
            post: 'Post',
          },
        },
        actions: {
          reply: 'Reply',
        },
      },
      offerDetails: {
        goal: 'Goal',
        minInvestment: 'Minimum Investment',
        minInvestmentShort: 'Min. Investment',
        investors: 'Investors',
        reachedPrice: 'Total raised',
        beTheFirstToInvest: 'Be the first to invest',
        receivable: {
          soldUnits: 'Total sold',
          availability: 'Availability',
          expiration: 'Expiration',
          sold: 'Sold',
        },
        cvm: {
          performance: '{{performance}} per month',
          types: {
            equity: 'Equity',
            fixed: 'Fixed income',
            receivable: 'Receivables',
          },
        },
        goalReached: '{{percent}}% of the goal reached',
        goalReached_0: 'No shares reserved at the moment',
        goalReached_beyond: 'Goal achieved',
        sold: '{{percent}}% sold',
        sold_0: 'No sales',
        sold_beyond: 'Goal reached',
        limit: 'Limit',
        timeRemaining: 'TIME REMAINING',
        offer: 'OFFER',
        preMoneyValuation: 'VALUATION',
        companyParticipation: 'company participation',
        sections: {
          ABOUT: 'About the Investment',
          DOCUMENTS: 'Documents',
          KPI: "KPI's",
          FOUNDERS: 'Founders',
          FORUM: 'Forum',
        },
        forum: {
          description:
            'Access the forum to chat and ask questions with other investors and experts',
          posts: 'Posts',
          actions: {
            access: 'Access Forum',
          },
        },
        actions: {
          invest: 'Invest',
          seeProfile: 'View profile on {{social}}',
          moreInfo: 'More information',
        },
      },
      components: {
        drop: {
          remaining: 'Last item available',
          remaining_plural: '{{count}} items remaining',
          soldout: 'All items have been sold',
        },
        benefits: {
          title: 'Benefits',
          subtitle: 'Check and manage your benefits',
          claim: 'Redeem',
          expired: 'Benefit expired',
          claimed: 'Benefit redeemed',
          claiming: 'Redeeming benefit',
          expireIn: 'Expires in:',
          days_plural: '{{count}} days',
          days: '{{count}} day',
          remaining: '{{count}} item available',
          remaining_plural: '{{count}} items available',
          claimConfirmation: {
            title: 'Redeem benefit',
            actions: {
              claim: 'Redeem benefit',
            },
            success: {
              title: 'Benefit redeemed!',
              description:
                'Soon you will receive instructions and more information about this collection in your registered email {{email}}',
              actions: {
                understood: 'I understand',
              },
              subtitle:
                'Are you sure you want to claim this benefit? <br/>This action cannot be reversed',
              pending:
                'This transaction could take a few minutes. As soon as the claim has been confirmed, you will receive instructions and more information at you registered email <b>{{email}}</b>',
            },
            details: {
              title: 'Benefit details',
              details: 'More details',
              actions: {
                claim: 'Claim benefit',
              },
            },
          },
        },
        form: {
          dataStatus: {
            COMPLETE: 'Completed',
            INCOMPLETE: 'Incomplete',
            PENDING: 'Pending',
            ERROR: 'Error',
          },
        },
        galleryCard: {
          tokenEmitter: {
            totalVolume: 'Total volume',
            projects: '{{count}} project',
            projects_plural: '{{count}} projects',
          },
        },
      },
      generic: {
        knowMore: 'Know more',
        noItemsFound: 'No items found',
        brandMotto: 'This is rare. This is Rarum.',
      },
      home: {
        banner: {
          v2: {
            brand: 'rarum',
            brandMotto: 'Community Engagement as a Service',
            brandBenefit: 'We appreciate what is rare: your community.',
            actions: {
              features: 'Learn about our Whitelabel platform',
            },
          },
          title: `DIGITAL ASSETS <br/><span class="${Styles.gradient}">SUSTAINABLE AND FOR <br/>EVERYONE</span> `,
          subtitle:
            'Have a digital asset marketplace solution customized for your community',
          action: {
            create: 'Create your store!',
          },
        },
        sections: {
          brand: {
            title: '100% Carbon Neutral',
            description: `We offset all carbon emissions from the platform and traded assets.

Rarum also operates on blockchains that are efficient in their greenhouse gas emissions.`,
          },
          gallery: {
            heading: "Discover the Rarum's galleries",
            title: 'Latest galleries',
            action: {
              seeAll: 'All galleries',
            },
          },
          latest: {
            title: 'Highlighted items',
            action: {
              seeAll: 'All items',
            },
          },
          store: {
            title: 'Stores',
          },
          create: {
            title: 'Create your own store and start selling digital assets.',
            actions: {
              knowMore: 'More info',
            },
            highlight: {
              create: 'Create your own items',
              easy: 'Easy to buy and sell',
              secure: 'Secure, transparent and carbon free',
            },
          },
          partnership: {
            title: 'Carbon Neutral',
            description:
              'In our pursuit of more sustainable practices, we have significantly reduced our carbon emissions and implemented low-impact environmental blockchain technologies.',
            actions: {
              knowMore: 'More info',
            },
          },
          news: {
            title: 'On the news',
          },
          highlights: {
            title: 'Open offers',
          },
        },
      },
      allGalleries: {
        title: 'GALLERIES',
        sections: {
          highlight: {
            title: 'Highlighted galleries',
          },
          createYourOwn: {
            title: 'Create your own store and start selling digital assets!',
            action: {
              knowMore: 'More info',
            },
          },
        },
      },
      myCollection: {
        actions: {
          seeAllGallery: 'See all',
          hideAllGallery: 'Back to list',
        },
      },
      galleryDetails: {
        noMarket: 'Gallery items',
        noAvailable: `Keep watching!
Soon there will be new offers for this gallery`,
        actions: {
          seeAllMarketplace: 'See all on marketplace',
          seeAll: 'See all items',
        },
        expiredItems: 'Closed offers',
        market: 'Marketplace items',
        items: 'Items',
      },
      topbar: {
        menus: {
          [MenuOptions.ABOUT]: 'ABOUT RARUM',
          [MenuOptions.FAQ]: 'FAQ',
          [MenuOptions.GALLERIES]: 'GALLERIES',
          [MenuOptions.MARKET]: 'MARKETPLACE',
          [MenuOptions.HOW_IT_WORKS]: 'HOW IT WORKS',
          [MenuOptions.BRAND]: 'BRANDS',
          [MenuOptions.CREATORS]: 'CREATORS',
        },
        auth: {
          login: 'Sign in',
          signup: 'Sign up',
        },
      },
    },
  },
} satisfies {
  'en-US': RarumIntlShape
}

export default messages
