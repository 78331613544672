import { useEffect, useMemo } from 'react'

export function useItemFromListOrSingle<I extends { id: string }>(
  /** The id to search for */
  itemId: I['id'] | undefined,
  /**The single item in store */
  singleItem: I | undefined | null,
  /** The item list in store */
  itemList: I[] | undefined | null,
  /** The subscribe and unsubscribe effect for searching for an item */
  singleRequestEffect: () => (() => void) | void
) {
  const existingItem = useMemo(() => {
    if (singleItem?.id === itemId) return singleItem
    return itemList?.find((d) => d.id === itemId)
  }, [itemList, singleItem, itemId])

  useEffect(() => {
    if (itemId && !existingItem) {
      return singleRequestEffect()
    }
  }, [itemId, existingItem])

  return existingItem
}
