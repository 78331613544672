import { AssetType } from "core/logic/asset/asset.types";

export enum PREDEFINED_DEADLINES {
    A_MONTH = "one_month",
    TWO_MONTHS = "two_months",
    THREE_MONTHS = "three_months",
}
export type OfferForm = {
    selectedQuantity: number
    tokenId?: AssetType['tokenId'],
    amount: {
        [k: CryptoCurrencySymbol]:
        | { checked: boolean; amount: number }
        | undefined
    }
    selectedDeadline: PREDEFINED_DEADLINES
}
export enum CreateOfferFormMode {
    CREATION,
    EDITION
}