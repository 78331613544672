import { isRedirectingLogin } from 'core/modules/firebase/service'
import service from 'core/modules/localstorage'
import { LS_KEYS } from 'core/modules/localstorage/service'

export const redirect = {
  get: () => service(LS_KEYS.REDIRECT).getString(),
  set: (link: string) => service(LS_KEYS.REDIRECT).setString(link),
  reset: () => service(LS_KEYS.REDIRECT).remove(),
  isLogin: () => isRedirectingLogin(),
}
