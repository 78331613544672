import { Box, Divider } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import React from 'react'
import useStyles from './CollectionItemShimmer.style'

interface CollectionItemShimmerProps {
  last?: boolean
}
const CollectionItemShimmer: React.FC<CollectionItemShimmerProps> = ({ last = false }) => {
  const classes = useStyles()

  return (
    <>
      <Box display='flex' flexDirection='column' className={classes.container}>
        {/* Img row */}
        <Skeleton variant='rect' height={265} className={classes.block} />
        {/* Bottom row */}
        <Skeleton variant='text' width='60%' height={27} className={classes.block} />
        <Skeleton variant='text' width='90%' height={20} />
      </Box>
      {!last && <Divider className={classes.divider} />}
    </>
  )
}

export default CollectionItemShimmer
