import { keccak256 } from 'js-sha3'
// https://github.com/reactivando/validations-br/blob/master/src/validations/validateCPF.ts
/**
 * Calcula o MOD11 para o número informado
 * @param valor Número base para o calculo
 * @param limite Limite da casa de multiplicação
 * Para CPF assume o valor de 12, para CNPJ o valor de 9.
 * @returns Retorna o DV calculado
 */
export const mod11 = (clearValue: string, limite: number): number => {
  const valor = String(clearValue).replace(/\D/g, '')
  let sum = 0
  let mult = 2

  for (let i = valor.length - 1; i >= 0; i--) {
    sum += mult * +valor[i]
    if (++mult > limite) {
      mult = 2
    }
  }
  const dv = ((sum * 10) % 11) % 10
  return dv
}

/**
 * Função que valida se a string é apenas
 * números repetidos.
 *
 * @param ref String númerica
 * @returns True se for contida por apenas caracteres repetidos,
 * false caso contrário
 */
const isRepeated = (ref: string) => {
  const ret = ref.replace(new RegExp(ref[0], 'g'), '').trim().length === 0
  return ret
}

/**
 * Valida o CPF. A entrada pode ser com ou sem máscaras.
 * O tamanho deve ser respeitado como em '000.000.000-00' ou '00000000000'.
 * @param value
 */
export function validateCPF(value: string): boolean {
  // Campo sem máscara
  const clearValue = String(value).replace(/\D/g, '')
  // O CPF possui 2 DVs, excluíndo para validar
  const valWithoutDvs = clearValue.substring(0, clearValue.length - 2)
  // Valida se está vazio ou é valor repetido
  if (!clearValue || isRepeated(clearValue)) {
    return false
  }
  // Calcula o primeiro DV
  const dv1 = mod11(valWithoutDvs, 12)
  // Calcula o segundo DV2
  const dv2 = mod11(valWithoutDvs + dv1, 12)
  // Compara com a informação passada como paramêtro
  return valWithoutDvs + dv1 + dv2 === clearValue
}

/**
 * Valida o CEP. A entrada pode ser com ou sem máscaras.
 * O tamanho deve ser respeitado como em '00000-000' ou '00000000'.
 * @param value
 */
export function validateCEP(value: string): boolean {
  // Campo sem máscara
  const clearValue = String(value).replace(/\D/g, '')
  return clearValue.length === 8
}

/**
 * Valida uma carteira Ethereum. Baseada em: https://github.com/technologiespro/wallet-validator/blob/master/src/ethereum_validator.js
 * @param address Endereço da carteira Ethereum
 * @returns True caso seja válida
 */
export const isValidEthereumWallet = (address: string) => {
  if (!/^0x[0-9a-fA-F]{40}$/.test(address)) {
    // Check if it has the basic requirements of an address
    return false
  }

  if (/^0x[0-9a-f]{40}$/.test(address) || /^0x?[0-9A-F]{40}$/.test(address)) {
    // If it's all small caps or all all caps, return true
    return true
  }

  // Otherwise check each case
  return verifyChecksum(address)
}

const verifyChecksum = (address: string) => {
  // Check each case
  address = address.replace('0x', '')

  var addressHash = keccak256(address.toLowerCase())

  for (var i = 0; i < 40; i++) {
    // The nth letter should be uppercase if the nth digit of casemap is 1
    if (
      (parseInt(addressHash[i], 16) > 7 && address[i].toUpperCase() !== address[i]) ||
      (parseInt(addressHash[i], 16) <= 7 && address[i].toLowerCase() !== address[i])
    ) {
      return false
    }
  }

  return true
}
