import { yupResolver } from '@hookform/resolvers/yup'
import { useUser } from 'core/logic/user'
import {
  doConfirmWithdrawal,
  doWithdrawal,
  waitForTransferToEnd,
} from 'core/modules/firebase/service'
import { ComponentProps, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import { i18n } from 'translate/i18n'
import { TransferFormInputs, transferFormSchema } from './Transfer.data'
import TransferView from './Transfer.view'
import ROUTES from 'core/modules/router'
import { useProcessTrigger } from 'context/ProcessPool'
import { dispatchAndWait } from 'core/helpers/contract'
import { waitForConfirmation } from 'core/helpers/contract'
import { TransferProcess } from 'core/logic/transfer/transfer.types'
import { useMyCollectionItems } from 'context/MyCollection'
import useRouteGalleryOrDefault from 'openspace/hooks/useRoutePathGallery'
import { useContractForGallery } from 'context/Gallery'
import { useWalletOwnershipOperation } from 'context/Wallet'

interface TransferLogicProps {
  balance: number
  assetId: string
  tokenId: number
}
const TransferLogicImpl: React.FC<
  TransferLogicProps & {
    onFinishTransfer: () => void
  }
> = ({ balance, assetId, tokenId, onFinishTransfer }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<TransferFormInputs>({
    defaultValues: { qty: 1, recipient: '' },
    resolver: yupResolver(transferFormSchema(balance)),
    reValidateMode: 'onChange',
    mode: 'onSubmit',
  })
  const history = useHistory()
  const { detail, profile } = useUser()
  const email = detail?.email
  const [showError, setShowError] = useState(false)
  const { refreshCollection } = useMyCollectionItems()
  const gallery = useRouteGalleryOrDefault()
  const galleryContract = useContractForGallery(gallery)

  /**
   * =================================
   * FIRST PHASE:
   * User creates the withdrawal order
   * =================================
   */
  const [acceptanceCheckbox, setAcceptanceCheckbox] = useState(false)
  const [isCreatingWithdrawal, setIsCreatingWithdrawal] = useState(false)
  const [isMailSent, setIsMailSent] = useState(false)

  async function _waitForTransferToEnd(
    transferType: 'internal' | 'external',
    transferIdOrTrxHash: string
  ) {
    switch (transferType) {
      case 'external':
        await waitForConfirmation(transferIdOrTrxHash).then(refreshCollection)
        break
      case 'internal':
        await waitForTransferToEnd(transferIdOrTrxHash).then(refreshCollection)
        break
    }
  }
  const { trigger, ...ongoingTransfer } = useProcessTrigger<TransferProcess>(
    `transfer_token_${tokenId}`
  )(_waitForTransferToEnd, (recoveryData) => {
    const id =
      recoveryData.data.type === 'external'
        ? recoveryData.data.txHash
        : recoveryData.data.transferId
    return _waitForTransferToEnd(recoveryData.data.type, id)
  })

  const createWithdrawalHandler = useWalletOwnershipOperation<
    ComponentProps<typeof TransferView>['createWithdrawal']
  >({
    async custody(data) {
      const { recipient, qty } = data
      if (recipient && qty) {
        setIsCreatingWithdrawal(true)
        try {
          await doWithdrawal({
            addressTo: recipient,
            amount: Number(qty),
            assetId,
            tenantId: process.env.REACT_APP_TENANT_IDENTITY_ID!,
            language: i18n.language,
          })
          setIsMailSent(true)
        } catch (error) {
          setShowError(true)
        } finally {
          setIsCreatingWithdrawal(false)
        }
      }
    },
    async provider(data) {
      const contract = galleryContract.contract! as Contract.ERC1155
      const sentTransaction = contract.methods
        .safeTransferFrom(
          profile!.wallet!,
          data.recipient!,
          String(tokenId),
          String(data.qty!),
          '0x'
        )
        .send({
          from: profile!.wallet!,
        })
      const tHash = await dispatchAndWait(sentTransaction)
      await trigger(
        {
          type: 'external',
          txHash: tHash,
        },
        'external',
        tHash
      )
    },
  })

  /**
   * ========================
   * SECOND PHASE:
   * User confirms withdrawal
   * ========================
   */
  const [isFinishing, setIsFinishing] = useState(false)
  const [transferDone, setTransferDone] = useState(false)

  const query = new URLSearchParams(useLocation().search)
  const transferId = query.get('w')
  const securityCode = query.get('s')

  let finishTransfer: (() => void) | undefined = undefined
  if (transferId && securityCode)
    finishTransfer = async () => {
      setIsFinishing(true)
      try {
        await doConfirmWithdrawal({
          transferId,
          securityCode,
          tenantId: process.env.REACT_APP_TENANT_IDENTITY_ID!,
          language: i18n.language,
        })
        trigger(
          {
            type: 'internal',
            transferId: transferId,
          },
          'internal',
          transferId
        )
        setTransferDone(true)
      } catch (error) {
        setShowError(true)
      } finally {
        setIsFinishing(false)
      }
    }

  return (
    <TransferView
      email={email}
      balance={balance}
      formSubmitHandler={handleSubmit}
      formControl={control}
      formErrors={errors}
      acceptanceCheckbox={acceptanceCheckbox}
      setAcceptanceCheckbox={setAcceptanceCheckbox}
      createWithdrawal={createWithdrawalHandler}
      creatingWithdrawal={isCreatingWithdrawal}
      mailSent={isMailSent}
      finishTransfer={finishTransfer}
      finishingTransfer={isFinishing}
      transferDone={transferDone}
      showError={showError}
      closeError={() => setShowError(false)}
      profile={profile}
      onGoToTransferTokens={() => {
        history.push(ROUTES.authenticated.transferTokens)
      }}
      ongoingTransfer={ongoingTransfer as any}
      onFinishTransfer={onFinishTransfer}
    />
  )
}

export function TransferLogic(props: TransferLogicProps) {
  const [key, setKey] = useState(() => Date.now())
  return (
    <TransferLogicImpl
      key={key}
      {...props}
      onFinishTransfer={() => setKey(Date.now())}
    />
  )
}

export default TransferLogic
