import { ReactElement } from 'react'

export enum KYCRequiredStatus {
  /** Used when an action is pending */
  LOADING = 'l',
  /** The KYC has not been made yet */
  PENDING_KYC = 'pk',
  /** The KYC is in progress */
  LOADING_KYC = 'lk',
  /** Some error with KYC has ocurred */
  ERROR_KYC = 'ek',
  /** User completed KYC */
  KYC_OK = 'fk',
  /** User is not logged in */
  AUTHENTICATE_REQUIRED = 'a'
}

export type KYCActionWrapperProps = {
  loading: boolean
  error?: Error
  onAction: () => void
  text: ReactElement
  disabled: boolean
}
