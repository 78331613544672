import {
  Benefit,
  BenefitClaimTrxShape,
  BenefitWithStatus,
} from '../Benefits.types'

export type ClaimConfirmationProps = {
  benefit: BenefitWithStatus | Benefit
  onClaimed: () => void
  onCancel: () => void
  confirmation?: BenefitClaimTrxShape
  startingMode: ClaimConfirmationViewProps['mode']
  open: boolean
}
export type ClaimConfirmationViewProps = {
  onCancel: () => void
  onAction: () => void
  loading: boolean
  error: any
  claimed: boolean
  mode?: ClaimConfirmationMode
  onClosed: () => void
} & Pick<ClaimConfirmationProps, 'benefit' | 'open'>

export enum ClaimConfirmationMode {
  DETAILS,
  CLAIM,
  READ_ONLY,
}
