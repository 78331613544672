import {
  useAuction,
  useAuctionPeriod,
  useBidInformation,
  useBids,
} from 'context/Auction'
import { dropStatus } from 'core/helpers/drop'
import { useTenant } from 'core/logic/tenant/tenant.hook'
import { useUser } from 'core/logic/user'
import { useEffect, useMemo, useState } from 'react'
import { OfferAuctionRaceView } from './OfferAuctionRaceView'
import { OfferAuctionRaceProps } from './types'

export const OfferAuctionRace: React.FC<OfferAuctionRaceProps> = ({
  offer,
}) => {
  const { tenant } = useTenant()
  const { profile } = useUser()
  const dropBids = useBids()
  const auction = useAuction()

  const period = useAuctionPeriod()
  const availability = dropStatus(
    {
      drop: offer,
    },
    period
  )
  const [now, setNow] = useState(() => Date.now())
  const [modalOpen, setModalOpen] = useState(false)
  const { information, loading } = useBidInformation()
  const handleModal = (open: boolean) => () => {
    setModalOpen(open)
  }
  const isAvailable = availability === 'queue' || availability === 'open'
  const alreadyGaveBid = useMemo(() => {
    const latestBid = dropBids?.[0]
    return !!(
      auction &&
      latestBid &&
      profile &&
      auction.isBidOwned(profile, latestBid)
    )
  }, [dropBids, auction, profile])

  useEffect(() => {
    if (!period) return
    const [_start, end] = period
    const timeRemainingToEnd = end.getTime() - Date.now()
    if (timeRemainingToEnd <= 0) return
    const i = setTimeout(() => {
      setNow(Date.now())
    }, timeRemainingToEnd)
    return () => clearTimeout(i)
  }, [period])

  const isExpired = !!period && period[1].getTime() <= now

  return (
    <OfferAuctionRaceView
      offer={offer}
      bids={dropBids}
      minBid={information?.getBidRange(undefined)[0]}
      currentBid={dropBids?.[0]}
      currency={information?.currency!}
      initialBid={dropBids?.[dropBids?.length - 1]}
      expire={period?.[1]}
      begin={period?.[0]}
      isAvailable={isAvailable && !isExpired}
      modalOpen={modalOpen}
      handleModal={handleModal}
      tenant={tenant!}
      alreadyGaveBid={alreadyGaveBid}
      loading={loading}
      isExpired={isExpired}
    />
  )
}
