import { Theme } from '@material-ui/core'
import createStyles from '@material-ui/styles/createStyles'
import makeStylesWrapper from 'core/helpers/styles'

const BORDER_RADIUS = 4

const style = (theme: Theme) => {
  return createStyles({
    wrapper: {
      borderRadius: BORDER_RADIUS,
    },
    button: {
      display: 'flex',
      border: `1.5px solid ${theme.palette.primary.main}`,
      borderRadius: BORDER_RADIUS,
      cursor: 'pointer',
      padding: theme.spacing(0.8),
      transition: `all ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}`,
    },
    active: {
      backgroundColor: theme.palette.common.white,
      border: `1.5px solid ${theme.palette.common.white}`,
    },
    hover: {
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        border: `1.5px solid ${theme.palette.primary.main}`,
        '& svg': {
          '--svg-color': theme.palette.common.white,
        },
      },
    },
  })
}

export default makeStylesWrapper(style)
