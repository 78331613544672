import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import ActionButton from 'components/ActionButton'
import BackButton from 'components/BackButton'
import InfoModal from 'components/InfoModal'
import useStyles from '../Transfer.style'
import { i18n, t } from 'translate/i18n'
import useAsyncControl from '@onepercentio/one-ui/dist/hooks/useAsyncControl'
import { useState } from 'react'
import { TransferInfoModalTestIds } from './TransferInfoModal.e2e'

interface ActionsProps {
  onBack: () => void
  onTransfer: () => void
  loading: boolean
}

// Error modal actions area component
const Actions: React.FC<ActionsProps> = ({ onBack, onTransfer, loading }) => {
  const { actionsWrapper, cancelButton, actionButton } = useStyles()
  return (
    <Box className={actionsWrapper}>
      {loading && (
        <Box mr={17.5}>
          <CircularProgress />
        </Box>
      )}
      {!loading && (
        <>
          <ActionButton
            text={i18n.t(
              'authenticated.transfer.request.confirm.modal.proceed'
            )}
            className={actionButton}
            onClick={onTransfer}
            data-testid={TransferInfoModalTestIds.MAIN_ACTION}
          />
          <BackButton
            icon='close'
            text={i18n.t('authenticated.transfer.request.confirm.modal.cancel')}
            className={cancelButton}
            onClick={onBack}
            data-testid={TransferInfoModalTestIds.SECONDARY_ACTION}
          />
        </>
      )}
    </Box>
  )
}
interface TransferInfoProps {
  email: string
  wallet: string
  quantity: number
  handleSubmit: WalletOwnershipBasedOperation<() => Promise<void>>
  isOpen: boolean
  handleClose: (finishedTransfer: boolean) => void
  loading: boolean
  descriptionKey: TranslationCodesByTenant[keyof TranslationCodesByTenant]
  mode: 'metamask' | 'internal'
}

const TransferInfoModal: React.FC<TransferInfoProps> = ({
  email,
  wallet,
  quantity,
  handleSubmit,
  isOpen,
  handleClose,
  loading,
  descriptionKey,
  mode,
}) => {
  const classes = useStyles()
  const { loading: localLoading, handleSubmit: _handleSubmit } =
    useAsyncControl({
      handleSubmit,
    })
  const [finishedTransfer, setFinishedTransfer] = useState(false)

  const submit = async () => {
    await _handleSubmit()
    if (mode === 'metamask') setFinishedTransfer(true)
  }

  const content = (
    <>
      <Typography
        variant='body1'
        component='p'
        className={clsx(
          classes.formGutter,
          classes.smallSpacing,
          classes.contentText
        )}>
        {i18n.t(
          finishedTransfer
            ? 'authenticated.transfer.request.confirm.modal.metamask.finished'
            : (descriptionKey as 'authenticated.transfer.request.confirm.modal.metamask.finished'),
          {
            email,
            amount: quantity,
            wallet,
            plural: quantity > 1 ? 's' : '',
          }
        )}
      </Typography>
    </>
  )
  return (
    <InfoModal
      title={i18n.t('authenticated.transfer.request.confirm.modal.title')}
      open={isOpen}
      handleClose={() => handleClose(finishedTransfer)}
      content={content}
      actions={
        finishedTransfer ? (
          <ActionButton
            text={t(
              'authenticated.transfer.request.confirm.modal.metamask.actions.close'
            )}
            data-testid={TransferInfoModalTestIds.MAIN_ACTION}
            onClick={() => {
              handleClose(finishedTransfer)
            }}
          />
        ) : (
          <Actions
            onBack={() => handleClose(finishedTransfer)}
            onTransfer={submit}
            loading={loading || localLoading}
          />
        )
      }
      disableBackdropClick
      disableTopCloseButton={loading}
    />
  )
}
export default TransferInfoModal
