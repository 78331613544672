import React, { ComponentProps, useMemo } from 'react'
import View from './AssetDisplay.view'
import { AssetDisplayMode, AssetDisplayProps } from './AssetDisplay.types'

export default function AssetDisplayLogic({
  asset,
  uniqueId: _uniqueId,
  zoomable
}: AssetDisplayProps) {
  const config = useMemo<ComponentProps<typeof View>>(() => {
    const uniqueId = _uniqueId || String(asset.tokenId) + String(asset.id)
    const medias = asset.media
    if (AssetDisplayMode.IMAGE in medias)
      return {
        mode: AssetDisplayMode.IMAGE,
        src: medias[AssetDisplayMode.IMAGE],
        uniqueId,
      }
    if (AssetDisplayMode.ANIMATION in medias)
      return {
        mode: AssetDisplayMode.ANIMATION,
        src: medias[AssetDisplayMode.ANIMATION],
        uniqueId,
      }
    if (AssetDisplayMode.VIDEO in medias)
      return {
        mode: AssetDisplayMode.VIDEO,
        src: medias[AssetDisplayMode.VIDEO],
        thumbnail: medias.coverUrl,
      }

    const mediasDeprec = medias as any
    /** @deprecated Modes that have been since deprecated, kept for retrocomptibility */
    if ('animationUrl' in mediasDeprec) {
      return {
        mode: AssetDisplayMode.ANIMATION,
        src: mediasDeprec.animationUrl!,
        uniqueId,
      }
    }
    if ('imageUrl' in mediasDeprec || 'coverUrl' in mediasDeprec)
      return {
        mode: AssetDisplayMode.IMAGE,
        src: mediasDeprec.imageUrl || mediasDeprec.coverUrl!,
        uniqueId,
      }
    console.error(`Something has gone very wrong, there is no animation or image for this asset
      
Asset.medias: ${JSON.stringify(mediasDeprec)}`)
    return { mode: AssetDisplayMode.IMAGE, src: '', uniqueId }
  }, [asset])
  return <View {...config} zoomable={zoomable}/>
}
