import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) =>
  createStyles({
    section: {
      display: 'flex',
      flexDirection: 'column',
    },
    spacing: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    bottomSpacing: {
      marginBottom: theme.spacing(4),
    },
    ellipsis: {
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  })

export default makeStylesWrapper(style)
