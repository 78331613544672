import { builder } from 'core/helpers'
import { initialState } from './block.data'
import * as BlockTypes from './block.types'

// == TYPES ==
const BLOCK_UPDATE = 'block/UPDATE'

export const types = Object.freeze({
  BLOCK_UPDATE: BLOCK_UPDATE,
})

// == ACTIONS ==
const blockUpdate = (blockState: BlockTypes.BlockType) =>
  builder.actionCreator<BlockTypes.BlockActionsTypes, BlockTypes.BlockType>(
    BLOCK_UPDATE,
    blockState
  )

export const actions = Object.freeze({
  blockUpdate,
})

export const mapActions = Object.freeze({
  [BLOCK_UPDATE]: blockUpdate,
})

// == REDUCER ==
export function reducer(
  state: Readonly<BlockTypes.BlockType> = initialState,
  action: Readonly<BlockTypes.BlockAction>
): BlockTypes.BlockType {
  const { type, payload } = action
  switch (type) {
    case BLOCK_UPDATE:
      return {
        ...state,
        ...payload,
      }
    default:
      return state
  }
}
