import { messages as portugueseMessages } from './pt.proxy'
import { messages as englishMessages } from './en.proxy'
import { messages as spanishMessages } from './es.proxy'
import { flatten } from '@onepercentio/one-ui/dist/utils/flatten'

const messages = {
  ...portugueseMessages,
  ...englishMessages,
  ...spanishMessages,
}

let flatTranslations: {
  [l in LanguageCodes]?: {
    [k in TranslationCodesByTenant['openspace']]: string
  }
} = {}
export function getFlatTranslations(
  lang: LanguageCodes
): NonNullable<(typeof flatTranslations)['pt-BR']> {
  return (
    flatTranslations[lang] ||
    (flatTranslations[lang] = flatten(messages[lang].translations) as any)
  )
}

export { messages }
