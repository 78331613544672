import MutableHamburgerButton from '@onepercentio/one-ui/dist/components/MutableHamburgerButton'
import DropStepper from 'components/DropStepper'
import {
  ActionStep,
  MultiStepOperation,
  StaticStep,
} from 'components/MultiStepOperation/MultiStepOperation.view'
import { AssetType, Sale } from 'core/logic/asset/asset.types'
import { ComponentProps, RefObject, useMemo } from 'react'
import { t } from 'translate/i18n'
import { OfferForm } from '../CreateOffer.types'

export enum CONFIRMATION_STATES {
  CHECKING_APPROVAL, // Checking if the user already approved the nfts movement
  READY_FOR_CONFIRMING, // User already approved and now is waiting for the user to confirm sale creation
}

export default function ConfirmView({
  offer,
  state,
  loading,
  error,
  asset,
  onApprove,
  onCreate,
  onGoBack,
  onGoToOffer,
  onRetry,
  offerId,
  rootRef,
}: {
  offer: OfferForm & { offerId?: string }
  state: CONFIRMATION_STATES
  loading: boolean
  error: boolean
  onApprove: () => void
  onCreate: () => void
  onGoBack: () => void
  onGoToOffer: () => void
  onRetry: () => void
  asset: AssetType
  offerId?: Sale['offerId']
  rootRef?: RefObject<HTMLDivElement>
}) {
  const currentStep = useMemo(() => {
    switch (state) {
      case CONFIRMATION_STATES.CHECKING_APPROVAL:
        return 2
      case CONFIRMATION_STATES.READY_FOR_CONFIRMING:
        return 3
    }
  }, [state])
  const status = useMemo<
    ComponentProps<typeof MutableHamburgerButton>['state']
  >(() => {
    return loading ? 'loading' : error ? 'closed' : 'checkmark'
  }, [loading, error])
  return (
    <>
      <DropStepper
        rootRef={rootRef}
        steps={
          <MultiStepOperation currentStep={currentStep}>
            <StaticStep
              stepNum={1}
              stepName={t('marketplace.offerCreation.confirmation.configure')}
            />
            <ActionStep
              stepNum={2}
              stepName={t('marketplace.offerCreation.confirmation.approve')}
              error={currentStep === 2 && error}
              status={
                currentStep === 2
                  ? status
                  : currentStep === 3
                  ? 'checkmark'
                  : 'arrow-down'
              }
              action={{
                label: t(
                  currentStep === 2 && error
                    ? 'generic.retryBtn'
                    : 'marketplace.offerCreation.confirmation.actions.approve'
                ),
                onClick: error ? onRetry : onApprove,
                disabled: loading || currentStep !== 2,
              }}
            />
            <ActionStep
              stepNum={3}
              stepName={t('marketplace.offerCreation.confirmation.confirm')}
              error={currentStep === 3 && error}
              status={currentStep === 3 ? status : 'arrow-down'}
              action={{
                label: t(
                  currentStep === 3 && error
                    ? 'generic.retryBtn'
                    : offerId
                    ? 'marketplace.offerCreation.creation.finished.actions.seeItem'
                    : 'marketplace.offerCreation.confirmation.actions.confirm'
                ),
                onClick: error ? onRetry : offerId ? onGoToOffer : onCreate,
                disabled: loading || currentStep !== 3,
              }}
            />
          </MultiStepOperation>
        }
        asset={asset}
        assetInfo={{
          currency: Object.keys(offer.amount)[0],
          price: Object.values(offer.amount)[0]!.amount,
          quantity: offer.selectedQuantity,
        }}
        label={{
          back: t('marketplace.offerCreation.actions.backToHome'),
          title: t('marketplace.offerCreation.confirmation.title'),
          description: t('marketplace.offerCreation.confirmation.description'),
          cancel: t('marketplace.offerCreation.confirmation.actions.cancel'),
          preview: t('marketplace.offerCreation.sections.preview.title'),
        }}
        loading={loading}
        onBack={onGoBack}
      />
    </>
  )
}
