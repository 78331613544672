import useAsyncControl from '@onepercentio/one-ui/dist/hooks/useAsyncControl'
import {
  Benefit,
  BenefitClaimTrxShape,
  BenefitWithStatus,
} from 'components/Benefits/Benefits.types'
import {
  AssetType,
  AssetWithBalance,
  BaseAssetType,
} from 'core/logic/asset/asset.types'
import { useGalleries } from 'core/logic/gallery/gallery.hook'
import { useTenant } from 'core/logic/tenant/tenant.hook'
import { useUser } from 'core/logic/user'
import { auth, firestore } from 'core/modules/firebase'
import useRouteGalleryOrDefault, {
  useRouteGallery,
} from 'openspace/hooks/useRoutePathGallery'
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import IMyCollectionFacade, {
  Balances,
  BalancesOfType,
} from 'service/IMyCollectionFacade'
import ERC1155RarumNFTFacade from 'service/IMyCollectionFacade/ERC1155RarumNFTFacade'
import ERC721OnchainFacade from 'service/IMyCollectionFacade/ERC721OnchainFacade'
import { useWeb3Providers } from 'hooks/useWeb3Provider'
import { GalleryType } from 'core/logic/gallery/gallery.types'
import {
  DeprecatedTenantType,
  TenantType,
} from 'core/logic/tenant/tenant.types'
import { useAssetsBy } from './Asset'
import { ContractType } from 'core/logic/contract/types/types'
import { useGalleriesForAssets } from './Gallery'
import { useContextControl } from '@onepercentio/one-ui/dist/context/ContextAsyncControl'
import ERC20OnchainFacade from 'service/IMyCollectionFacade/ERC20OnchainFacade'
import ERC1155CollectiblesFacade from 'service/IMyCollectionFacade/ERC1155CollectiblesFacade'

export type MyCollectionContextShape = {
  // The owned items it can be unknown until it fully loads
  ownedBalances: {
    [address: string]: Balances[] | undefined
  }

  updateOwnedBalances: Dispatch<
    SetStateAction<{
      [address: string]: Balances[] | undefined
    }>
  >
  facades?: IMyCollectionFacade[]
  defaultFacade?: IMyCollectionFacade

  initFacades: () => void
}

export enum CollectionFeature {
  /** When there are NFT's that have claimable benefits */
  BENEFITS,
  /** For NFT's that can be bound to mettered metrics */
  PERFORMANCE,
  /** For default NFT's */
  COLLECTIBLES,
}

export const MyCollectionContext = createContext<MyCollectionContextShape>(
  null as any
)

type NFT = NonNullable<GalleryType['smartContract']>

/**
 * This context manages the management of the items owned by the user
 */
export default function MyCollectionProvider({
  children,
}: PropsWithChildren<{ nftAddress?: string }>) {
  const { readWeb3, writeWeb3, loaded } = useWeb3Providers()
  const galleries = useGalleries().galleries
  const { tenant } = useTenant()
  const nftAddresses = useMemo<[NFT['address'], NFT['type']][]>(() => {
    if (!tenant || !galleries) return []
    const typeMap: {
      [address: string]: NonNullable<GalleryType['smartContract']>['type']
    } = {}

    for (let gallery of galleries || [])
      if (gallery?.smartContract?.address!)
        typeMap[gallery?.smartContract?.address!] =
          gallery?.smartContract?.type!

    const deprecatedTenant = tenant as TenantType | DeprecatedTenantType
    if ('smartContract' in deprecatedTenant)
      typeMap[deprecatedTenant.smartContract!.address] =
        deprecatedTenant.smartContract!.type

    const addressesSet = new Set<NFT['address']>(Object.keys(typeMap))

    return Array.from(addressesSet)
      .filter(([address]) => Boolean(address))
      .map((address) => [address, typeMap[address]])
  }, [galleries, tenant])

  const [ownedBalances, updateOwnedBalances] = useState<
    MyCollectionContextShape['ownedBalances']
  >({})
  const [facades, setFacades] = useState<IMyCollectionFacade[]>()

  const initialized = useRef(false)

  const initFacade = useCallback(async () => {
    if (initialized.current || !nftAddresses.length || !loaded) return
    initialized.current = true
    const facades: IMyCollectionFacade[] = []
    for (let [nftAddress, type] of nftAddresses) {
      switch (type) {
        case ContractType.Collectibles:
          facades.push(
            new ERC1155CollectiblesFacade(nftAddress!, readWeb3!, writeWeb3!)
          )
          break
        case ContractType.RarumNFT:
        case ContractType.ERC1155:
          facades.push(
            new ERC1155RarumNFTFacade(nftAddress!, readWeb3!, writeWeb3!)
          )
          break
        case ContractType.Loyalty:
          facades.push(
            new ERC721OnchainFacade(nftAddress!, readWeb3!, writeWeb3!)
          )
          break
        case ContractType.Fungible:
          facades.push(
            new ERC20OnchainFacade(nftAddress!, readWeb3!, writeWeb3!)
          )
          break
      }
    }

    setFacades(facades)
  }, [nftAddresses, readWeb3, writeWeb3, loaded])

  const defaultFacade = useMemo(() => facades?.[0], [facades, tenant])

  return (
    <MyCollectionContext.Provider
      value={{
        ownedBalances,
        updateOwnedBalances,
        facades,
        defaultFacade,
        initFacades: initFacade,
      }}>
      {children}
    </MyCollectionContext.Provider>
  )
}

export function useMyCollectionContext() {
  const { initFacades, ...ctx } = useContext(MyCollectionContext)

  useEffect(() => {
    initFacades()
  }, [initFacades])

  return ctx
}

/**
 * Select the relevant facades (The implementation for querying a balance)
 * @returns
 */
export function useMyCollectionItems() {
  const { facades } = useMyCollectionContext()
  const specificGallery = useRouteGallery()
  const specificFacade = useMemo(() => {
    if (specificGallery)
      return facades?.find(
        (f) => f.contractAddress === specificGallery.smartContract?.address
      )
  }, [specificGallery, facades])

  const facadesToQuery = useMemo(() => {
    return specificFacade ? [specificFacade] : facades || []
  }, [facades, specificFacade])

  const { balancesOfFacades, ...balances } =
    _useBalancesForFacades(facadesToQuery)

  const cidTokens = useMemo(() => {
    return filterBalanceBy(balancesOfFacades, ContractType.Loyalty).map(
      (balance) => balance.cid
    )
  }, [balancesOfFacades])
  const cidAssets = useAssetsBy('cid', cidTokens)

  const tokenIdTokens = useMemo(() => {
    return filterBalanceBy(balancesOfFacades, ContractType.RarumNFT).map(
      (balance) => balance.tokenId
    )
  }, [balancesOfFacades])
  const tokenIdAssets = useAssetsBy('token', tokenIdTokens)

  const ownedItems = useMemo(() => {
    const { mapOfCids, mapOfTokenIds } = (balancesOfFacades || []).reduce(
      (map, balance) => {
        switch (balance.type) {
          case ContractType.RarumNFT:
            return {
              ...map,
              mapOfTokenIds: {
                ...map.mapOfTokenIds,
                [balance.tokenId]: balance.balance,
              },
            }
          case ContractType.Loyalty:
            return {
              ...map,
              mapOfCids: {
                ...map.mapOfCids,
                [balance.cid]: [
                  ...(map.mapOfCids[balance.cid] ?? []),
                  balance.tokenId,
                ],
              },
            }
          case ContractType.Fungible:
            return map
        }
      },
      {
        mapOfCids: {} as { [k: string]: [...tokenIds: number[]] },
        mapOfTokenIds: {} as { [k: number]: number },
      }
    )

    const cidAssetsWithBalance = Object.entries(mapOfCids).reduce(
      (all, [cid, tokenIds]) => {
        const relatedAsset = cidAssets.assets.find(
          (a) => a.ipfs.jsonHash === cid
        )
        if (!relatedAsset) return all
        return [
          ...all,
          ...tokenIds.map((tokenId) => ({
            ...relatedAsset,
            tokenId,
            balance: 1,
          })),
        ]
      },
      [] as AssetWithBalance[]
    )

    return [
      ...cidAssetsWithBalance,
      ...tokenIdAssets.assets.map((a) => ({
        ...a,
        balance: mapOfTokenIds[a.tokenId],
      })),
    ]
  }, [cidAssets.assets, tokenIdAssets.assets])

  return {
    ...balances,
    ownedItems,
    facadeType: specificFacade?.facadeType,
  }
}

export function useUpToDateMyCollectionItems() {
  const ctx = useMyCollectionItems()

  useEffect(() => {
    ctx.refreshCollection()
  }, [ctx.refreshCollection])

  return ctx
}

function _useBalancesForFacades(specifiedFacades: IMyCollectionFacade[]) {
  const { facades, ownedBalances, updateOwnedBalances } =
    useMyCollectionContext()
  const { profile } = useUser()
  const balancesOfFacades = useMemo(() => {
    const balances = [] as (Balances & { address: string })[]
    for (let facade of specifiedFacades) {
      balances.push(
        ...(ownedBalances[facade.contractAddress]?.map((b) => ({
          ...b,
          address: facade.contractAddress,
        })) || [])
      )
    }
    return balances
  }, [specifiedFacades, ownedBalances])

  const _refreshCollection = useCallback(
    /**
     * @param forceRefresh Cleans up the previous assets for the facades
     * @returns
     */
    async () => {
      if (!facades || !facades.length || !profile?.wallet) return

      for (let facade of specifiedFacades!)
        facade.setWallets(profile.wallet, profile.internalWallet!)

      const balanceEntriesForFacade = await Promise.all(
        specifiedFacades!.map((facade, i) => facade.getOwnedItems())
      )

      updateOwnedBalances((prev) =>
        balanceEntriesForFacade.reduce(
          (acc, balances, i) => ({
            ...acc,
            [specifiedFacades![i].contractAddress]: balances,
          }),
          prev
        )
      )
    },
    [
      facades,
      profile?.wallet,
      profile?.internalWallet,
      updateOwnedBalances,
      specifiedFacades,
    ]
  )

  const { error, loading, refreshCollection } = useAsyncControl({
    refreshCollection: _refreshCollection,
  })

  const collectionFeatures = useMemo<CollectionFeature[]>(() => {
    if (!!facades?.find((f) => f instanceof ERC721OnchainFacade))
      return [CollectionFeature.COLLECTIBLES, CollectionFeature.BENEFITS]
    return [CollectionFeature.COLLECTIBLES]
  }, [facades])

  const loadedBalancesForAllFacades = useMemo(() => {
    const someFacadeIsMissingBalances = specifiedFacades.some(
      (facade) => !ownedBalances[facade.contractAddress]
    )

    return !someFacadeIsMissingBalances
  }, [ownedBalances, specifiedFacades])

  return {
    balancesOfFacades,
    refreshCollection,
    collectionFeatures,
    loadedBalancesForAllFacades,
  }
}

export function useBalanceForGallery(galleries: GalleryType[]) {
  const { facades } = useMyCollectionContext()
  const galleryContracts = useMemo(() => {
    return galleries.map((a) => a.smartContract?.address)
  }, [galleries])
  const facadesToQuery = useMemo(() => {
    return (
      facades?.filter((f) => galleryContracts.includes(f.contractAddress)) || []
    )
  }, [facades, galleryContracts])

  const balances = _useBalancesForFacades(facadesToQuery)

  return {
    ...balances,
  }
}

export function useBalanceForAssets(assets: AssetType[]) {
  const { galleries: _galleries } = useGalleriesForAssets(assets)
  const galleries = useMemo(() => _galleries || [], [_galleries])
  const { balancesOfFacades, refreshCollection, loadedBalancesForAllFacades } =
    useBalanceForGallery(galleries)

  const control = useContextControl(
    `galleries-${galleries.map((g) => g.id).join(',')}-balances`,
    {
      refreshCollection,
    }
  )

  useEffect(() => {
    if (!loadedBalancesForAllFacades && !control.loading)
      control.refreshCollection()
  }, [loadedBalancesForAllFacades, galleries])

  const info = useMemo(() => {
    return assets
      .map((asset) => {
        const gallery = galleries.find((g) => g.id === asset.galleryId)
        const balance = (() => {
          if (!gallery) return undefined
          const balancesOfGallery: Balances[] = balancesOfFacades.filter(
            (b) => b.address === gallery.smartContract!.address
          )
          switch (gallery.smartContract!.type) {
            case ContractType.RarumNFT:
              return balancesOfGallery.find(
                (b) =>
                  (b as BalancesOfType<ContractType.RarumNFT>).tokenId ===
                  asset.tokenId
              )?.balance
            case ContractType.Loyalty:
              return balancesOfGallery
                .filter(
                  (b): b is BalancesOfType<ContractType.Loyalty> =>
                    (b as BalancesOfType<ContractType.Loyalty>).cid ===
                    asset.ipfs.jsonHash
                )
                ?.map((a) => a.tokenId)
            case ContractType.Fungible:
              return balancesOfGallery[0]?.balance
            default:
              throw new Error(
                `Can't compute challenge for type ${
                  gallery.smartContract!.type
                } yet`
              )
          }
        })()
        return {
          asset,
          balance: loadedBalancesForAllFacades ? balance || 0 : balance,
        }
      })
      .filter(({ balance }) => balance !== undefined)
      .map(
        ({ asset, balance }) =>
          ({
            ...asset,
            balance: Array.isArray(balance) ? balance.length : balance,
            tokenIds: Array.isArray(balance) ? balance : undefined,
          } as AssetWithBalance)
      )
  }, [galleries, assets, balancesOfFacades])

  return {
    loading: control.loading,
    error: control.error,
    retry: () => control.refreshCollection(),
    assetsWithBalance: info,
  }
}

export function useBenefits(asset: BaseAssetType) {
  const { facades } = useMyCollectionContext()
  const benefitClaims = useClaimTransactions(asset.tokenId, asset.id)
  const [benefits, setBenefits] = useState<Benefit[]>()

  const _getBenefits = useCallback(async () => {
    const benefits = await Promise.all(
      facades!.map((facade) => {
        if (facade && facade instanceof ERC721OnchainFacade) {
          return facade.getBenefits(asset)
        }
        return undefined
      })
    )
    setBenefits(benefits.reduce((r, b = []) => [...r!, ...b], [] as Benefit[]))
  }, [facades, asset])

  useEffect(() => {
    _getBenefits()
  }, [_getBenefits])

  const benefitsWithStatus = useMemo(() => {
    return benefits?.map((b) => {
      const status = benefitClaims
        ?.filter((c) => c.benefit === b.code)
        .reduce((r, claim) => {
          if (
            r === 'AWAITING_CONFIRMATION' ||
            claim.status === 'AWAITING_CONFIRMATION'
          )
            return 'AWAITING_CONFIRMATION'
          return r
        }, undefined as BenefitClaimTrxShape['status'] | undefined)

      return {
        ...b,
        status: status,
      } as BenefitWithStatus
    })
  }, [benefitClaims, benefits])

  return {
    benefits: benefitsWithStatus,
    refreshBenefits: _getBenefits,
    claims: benefitClaims,
  }
}

export function useClaimBenefit() {
  const { facades, defaultFacade } = useMyCollectionContext()
  const relatedGallery = useRouteGalleryOrDefault()

  const facade = useMemo(() => {
    return (
      relatedGallery
        ? facades!.find(
            (f) => f.contractAddress === relatedGallery!.smartContract!.address
          )
        : defaultFacade
    ) as ERC721OnchainFacade
  }, [facades, relatedGallery, defaultFacade])

  const { claimBenefit, error, loading } = useAsyncControl({
    claimBenefit: facade!.claimBenefit,
  })
  return {
    claimBenefit,
    error,
    loading,
  }
}

export const CLAIM_TRX_PATH = (assetId: string) =>
  `/tenants/${process.env.REACT_APP_TENANT_IDENTITY_ID}/assets/${assetId}/redeemables`

function useClaimTransactions(tokenId: number, assetId: string) {
  const [benefitClaimState, setBenefitClaimState] =
    useState<BenefitClaimTrxShape[]>()
  useEffect(() => {
    return firestore
      .collection(CLAIM_TRX_PATH(assetId))
      .where('tokenId', '==', String(tokenId))
      .where('user.id', '==', auth.currentUser?.uid!)
      .orderBy('created', 'desc')
      .onSnapshot((snap) => {
        if (snap.docs.length)
          setBenefitClaimState(
            snap.docs.map((d) => d.data() as BenefitClaimTrxShape).reverse()
          )
      })
  }, [assetId, tokenId])
  return benefitClaimState
}

function filterBalanceBy<T extends ContractType>(
  ownedBalances: Balances[] = [],
  type: T
) {
  return ownedBalances.filter((balance): balance is BalancesOfType<T> => {
    return balance.type === type
  })
}
