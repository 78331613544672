import React, { useLayoutEffect, useMemo } from 'react'
import Styles from './NoOffersAvailable.module.scss'
import { ReactComponent as Incognito } from 'assets/components/gallery/incognitoCard.svg'
import useTilt from '@onepercentio/one-ui/dist/hooks/ui/useTilt'
import Text from '@onepercentio/one-ui/dist/components/Text'
import { t } from 'translate/i18n'
import { FigmaTypo } from 'containers/OneUIProvider/OneUIProvider'

/**
 * To show a warning saying no offers are available but will be in the future
 **/
export default function NoOffersAvailable() {
  const { tilt, relativeTo } = useTilt(true, -6, undefined)
  useLayoutEffect(() => {
    ;(relativeTo as any).current = document.body
  }, [])
  const scaledTilt = useMemo(() => {
    return {
      tiltA: `translateX(${tilt.x * 0.05 * 0.5}px) translateY(${
        tilt.y * 0.1 * 0.5
      }px)`,
      tiltB: `translateX(${tilt.x * 0.1 * 0.5}px) translateY(${
        tilt.y * 0.2 * 0.5
      }px)`,
      tiltC: `translateX(${tilt.x * 0.1 * 0.5}px)`,
    }
  }, [tilt])
  const opacity = useMemo(() => {
    const val = tilt.y + 100

    return (val / 200) * 0.7
  }, [tilt])
  return (
    <div className={Styles.incognito}>
      <Incognito
        style={
          {
            '--move-incognito': scaledTilt.tiltA,
            '--move-incognito-2': scaledTilt.tiltB,
            '--move-incognito-3': scaledTilt.tiltC,
            '--opacity-incognito': opacity,
          } as any
        }
      />
      <Text type={FigmaTypo.H4}>{t('galleryDetails.noAvailable')}</Text>
    </div>
  )
}
