import { dropStatus } from 'core/helpers/drop'
import { useOnce } from 'core/helpers/useOnce.hook'
import { useDrop } from 'core/logic/drop/drop.hook'
import { analyticsEvent } from 'core/modules/analytics/events'
import { useParams } from 'react-router-dom'
import DetailsView from './Details.view'
import useCustomHistory from '@onepercentio/one-ui/dist/hooks/useCustomHistory'
import { AuctionProvider, useAuctionPeriod } from 'context/Auction'
import { DropType, OfferType } from 'core/logic/drop/drop.types'
import ResourceNotFound from 'components/ResourceNotFound/ResourceNotFound'
import IPOLogic from './Models/IPO/IPO.logic'

function Logic({
  drop,
  onBack,
}: {
  drop?: DropType | null
  onBack: () => void
}) {
  const now = new Date()
  const period = useAuctionPeriod()
  const availability = drop && dropStatus({ drop, relativeTo: now }, period)
  return <DetailsView drop={drop} availability={availability} onBack={onBack} />
}

const DetailsLogic = () => {
  const history = useCustomHistory()
  const onBackClickHandler = () => history.goBackWithFallback('/')

  const { dropId } = useParams<{ dropId: string }>()
  const { drop } = useDrop({ dropId })

  useOnce(() => {
    if (drop) analyticsEvent.viewOffer({ offer: drop })
  }, !!drop)

  switch (drop?.type) {
    case 'cvm88':
      return (
        <>
          <IPOLogic offer={drop as OfferType} />
        </>
      )
    case 'auction':
    case 'off_chain_auction':
      return (
        <AuctionProvider offer={drop! as OfferType}>
          <Logic drop={drop} onBack={onBackClickHandler} />
        </AuctionProvider>
      )
    default:
      if (drop === null)
        return <ResourceNotFound onGoToMainPage={onBackClickHandler} />
      else return <Logic drop={drop} onBack={onBackClickHandler} />
  }
}

export default DetailsLogic
