import { i18n, t } from 'translate/i18n'
import { Chip, Grid, MenuItem, Popover } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import BackButton from 'components/BackButton'
import Certificate from 'components/Certificate'
import Footer from 'components/Footer'
import ProfileAvatar from 'components/ProfileAvatar'
import Headers from 'containers/Headers'
import { useState, useRef } from 'react'
import 'react-medium-image-zoom/dist/styles.css'
import useStyles from './CollectionItemDetail.style'
import NftInfo from './NftInfo'
import Transfer from './Transfer'
import QuickResumeBanner from 'components/QuickResumeBanner'
import QuickResumeBannerBanner from 'components/QuickResumeBanner/QuickResumeBanner.view'
import { Decoration } from 'pages/Unauthenticated/Marketplace/Banner/FAQ/FAQ.template'
import { CollectionItemDetailViewProps } from './CollectionItemDetail.types'
import { FacadeTypes } from 'service/IMyCollectionFacade'
import Benefits from 'components/Benefits'
import AssetDisplay from 'openspace/components/AssetDisplay'
import Button from '@onepercentio/one-ui/dist/components/Button'
import FeaturesDisplay from 'components/Asset/FeaturesDisplay/FeaturesDisplay'

function isMP4(path: string) {
  return /\.mp4/.test(path)
}

enum CollectionViewMode {
  COLLECTION,
  MARKETPLACE_ITEM,
  OFFER_DETAILS_ITEM,
}

const CollectionItemDetailView: React.FC<CollectionItemDetailViewProps> = ({
  assetId,
  type,
  title,
  description,
  features,
  onBack,
  onDownload,
  ipfs,
  balance,
  hideTransferForm,
  userAllowedToTransfer = false,
  transferBaseDate,
  asset,
  collectionItemType,
  ...otherProps
}) => {
  const marketplace = otherProps.marketplace
  const classes = useStyles()

  const isQuarantined =
    transferBaseDate && transferBaseDate.getTime() > Date.now()

  const mode = !otherProps.marketplace
    ? CollectionViewMode.COLLECTION
    : otherProps.offerDetails
    ? CollectionViewMode.OFFER_DETAILS_ITEM
    : CollectionViewMode.MARKETPLACE_ITEM

  return (
    <>
      <Headers screenTitle={title} />
      <main className={classes.main}>
        <div className={classes.upperContainer}>
          <Container>
            <Box pt={4} mb={4}>
              <BackButton
                onClick={onBack}
                text={i18n.t(
                  'authenticated.myCollection.collectionItemDetails.goBack'
                )}
              />
            </Box>
            {marketplace &&
              [
                CollectionViewMode.MARKETPLACE_ITEM,
                CollectionViewMode.OFFER_DETAILS_ITEM,
              ].includes(mode) &&
              typeof otherProps.availableForSale === 'number' && (
                <Grid container justifyContent='center'>
                  <Chip
                    variant='outlined'
                    label={t(
                      'authenticated.myCollection.collectionItem.unitsNew',
                      {
                        count: otherProps.availableForSale,
                      }
                    )}
                  />
                </Grid>
              )}
            <Typography
              variant='h2'
              component='h1'
              color='textPrimary'
              align='center'
              className={clsx(classes.title, classes.spacing)}>
              {title}
            </Typography>
            {description && (
              <Typography
                variant='body1'
                component='p'
                color='textPrimary'
                align='center'
                className={classes.spacing}>
                {description}
              </Typography>
            )}
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
              className={classes.itemWrapper}>
              <AssetDisplay asset={asset} zoomable />
            </Box>
          </Container>
        </div>
        <Container maxWidth='lg'>
          <Box textAlign='center' className={classes.bottomSpacing}>
            <BackButton
              iconOrientation='RIGHT'
              icon='download'
              text={i18n.t(
                'authenticated.myCollection.collectionItemDetails.download'
              )}
              onClick={onDownload}
            />
            {marketplace && (
              <Button
                onClick={otherProps.actions.onSell}
                variant='filled'
                color='primary'
                disabled={
                  isQuarantined ||
                  !otherProps.availableForSale ||
                  otherProps.availableForSale < 0
                }>
                {t('authenticated.myCollection.collectionItemDetails.sellItem')}
              </Button>
            )}
            {marketplace && otherProps.latestOfferId && (
              <>
                <ItemOnSaleBanner
                  isSpecificOffer={otherProps.isSpecificOffer}
                  onEditOffer={otherProps.actions.onEditOffer}
                  onRemoveOffer={otherProps.actions.onRemoveOffer}
                  onSeeOffer={otherProps.actions.onSeeOffer}
                />
              </>
            )}
          </Box>

          <Container maxWidth='md'>
            <>
              {marketplace &&
                mode === CollectionViewMode.MARKETPLACE_ITEM &&
                isQuarantined && (
                  <>
                    <QuickResumeBanner>
                      <Typography variant='body1'>
                        {t(
                          'authenticated.myCollection.collectionItemDetails.sellDisabledExplanation'
                        )}
                      </Typography>
                    </QuickResumeBanner>
                  </>
                )}
            </>
          </Container>
          <Divider className={classes.divider} />

          <Container maxWidth='md'>
            <Typography
              variant='h2'
              component='h2'
              color='textPrimary'
              className={clsx(classes.title, classes.spacing)}>
              {i18n.t(
                'authenticated.myCollection.collectionItemDetails.itemDetails'
              )}
            </Typography>
            <FeaturesDisplay features={features} />
            <Box
              display='flex'
              flexDirection='column'
              alignItems='flex-start'
              className={classes.spacing}>
              <Typography variant='body1' component='h3' color='textPrimary'>
                <strong>
                  {i18n.t(
                    'authenticated.myCollection.collectionItemDetails.owner'
                  )}
                </strong>
              </Typography>
              <ProfileAvatar
                flexDirection='row-reverse'
                className={classes.avatar}
              />
            </Box>
          </Container>

          <Divider className={classes.divider} />

          {asset?.media?.additionalAssets?.[0] && (
            <>
              <Container maxWidth='md'>
                <Box className={classes.preview}>
                  {isMP4(asset?.media?.additionalAssets[0]) ? (
                    <video
                      controls={false}
                      autoPlay
                      playsInline
                      muted
                      loop
                      src={asset?.media?.additionalAssets[0]}
                    />
                  ) : (
                    <img
                      src={asset?.media?.additionalAssets[0]}
                      alt='preview'
                    />
                  )}
                </Box>
              </Container>
              <Divider className={classes.divider} />
            </>
          )}

          {collectionItemType === FacadeTypes.BENEFITS && <Benefits />}

          <Container maxWidth='md'>
            <Box display='flex' justifyContent='center'>
              <Certificate />
            </Box>
          </Container>
          <Divider className={classes.divider} />
          <Container maxWidth='md'>
            <NftInfo ipfs={ipfs} />
          </Container>
          <Divider className={classes.divider} />
          <Container maxWidth='md'>
            <>
              {!hideTransferForm ? (
                balance && userAllowedToTransfer ? (
                  <Transfer
                    balance={balance}
                    assetId={assetId}
                    tokenId={asset.tokenId}
                  />
                ) : (
                  <Typography variant='body1' component='p' color='textPrimary'>
                    {i18n.t(
                      'authenticated.myCollection.collectionItemDetails.transferDisabled'
                    )}{' '}
                    <strong>
                      {transferBaseDate?.toLocaleDateString(i18n.language)}
                    </strong>
                  </Typography>
                )
              ) : null}
            </>
          </Container>
        </Container>
      </main>
      <Footer />
    </>
  )
}

function ItemOnSaleBanner({
  onSeeOffer,
  onRemoveOffer,
  onEditOffer,
  isSpecificOffer,
}: {
  onSeeOffer: () => void
  onRemoveOffer: () => void
  onEditOffer: () => void
  isSpecificOffer: boolean
}) {
  const [open, setOpen] = useState(false)
  const elRef = useRef<HTMLElement>(null)
  return (
    <>
      <Box mt={4}>
        <QuickResumeBannerBanner
          DecorationComp={Decoration}
          actions={[
            {
              action: onSeeOffer,
              label: t(
                'authenticated.myCollection.collectionItemDetails.enabledOffer.actions.seeOffer'
              ),
              type: 'filled',
            },
            ...(!isSpecificOffer
              ? []
              : ([
                  {
                    action: () => setOpen(true),
                    label: t(
                      'authenticated.myCollection.collectionItemDetails.enabledOffer.actions.options'
                    ),
                    type: 'outline',
                    ref: elRef,
                  },
                ] as const)),
          ]}>
          <Box>
            <Typography align='left' variant='body1'>
              <b>
                {t(
                  'authenticated.myCollection.collectionItemDetails.enabledOffer.title'
                )}
              </b>
            </Typography>
            <Typography align='left' variant='body1'>
              {t(
                'authenticated.myCollection.collectionItemDetails.enabledOffer.description'
              )}
            </Typography>
          </Box>
        </QuickResumeBannerBanner>
      </Box>
      <Popover
        onClose={() => setOpen(false)}
        anchorEl={elRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        open={open}>
        <MenuItem
          onClick={() => {
            onRemoveOffer()
            setOpen(false)
          }}>
          {t(
            'authenticated.myCollection.collectionItemDetails.enabledOffer.actions.subActions.remove'
          )}
        </MenuItem>{' '}
      </Popover>
    </>
  )
}

export default CollectionItemDetailView
