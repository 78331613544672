import { takeLatest } from 'redux-saga/effects'
import { types as userActionTypes } from './user.reducer'
import * as service from './user.service'

// watch logout
export function* watchManualLogout() {
  yield takeLatest(userActionTypes.LOGOUT, service.logout)
}

// watch logout
export function* watchRemove() {
  yield takeLatest(userActionTypes.REMOVE, service.remove)
}
