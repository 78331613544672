import React, {
  Fragment,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react'
import UncontrolledTransition from '@onepercentio/one-ui/dist/components/UncontrolledTransition'
import { TransitionAnimationTypes } from '@onepercentio/one-ui/dist/components/Transition/Transition'
import { t } from 'translate/i18n'
import { Grid, Typography } from '@material-ui/core'
import IconRarum from 'components/IconRarum'
import s from './WalletRequiredWrapper.style'
import Loading from 'components/Loading'
import {
  BaseSettleOperationModal,
  BaseSettleOperationView,
} from 'pages/Authenticated/Marketplace/SettleOperation/SettleOperation.view'
import Button from '@onepercentio/one-ui/dist/components/Button'
import Spacing from '@onepercentio/one-ui/dist/components/Spacing'
import useAsyncControl from '@onepercentio/one-ui/dist/hooks/useAsyncControl'
import LoaderDotsIndicator from '@onepercentio/one-ui/dist/components/LoaderDotsIndicator'
import { useChainForIntl } from 'context/Chain'
import AdaptiveButton from '@onepercentio/one-ui/dist/components/AdaptiveButton'

export enum WalletConnectionStatusEnum {
  NO_PROVIDER,
  NOT_CONNECTED,
  WRONG_NETWORK,
  CONNECTING,
  CONNECTED,
  SHOW_CONTENT,
  INVALID_WALLET,
  MIGRATION_REQUIRED,
}

export default function WalletRequiredWrapperView({
  status,
  children,
  links,
  onCancel,
  onConnectToMetamask,
  onSwitchNetwork,
  onConnectedDisplayed,
  onDetectMetamask,
  onDisconnect,
  onMigrate,
  connectedWallet,
  expectedWallet,
}: PropsWithChildren<{
  status: WalletConnectionStatusEnum
  onCancel: () => void
  onConnectToMetamask: () => void
  onSwitchNetwork: () => void
  onConnectedDisplayed: () => void
  onDetectMetamask: () => void
  onDisconnect: () => void
  onMigrate: () => void
  links: {
    guideMetamask: string
  }
  connectedWallet?: string
  expectedWallet?: string
}>) {
  const classes = s()
  const chain = useChainForIntl()
  const content = useMemo(() => {
    const statusKey = status
    switch (status) {
      case WalletConnectionStatusEnum.NO_PROVIDER:
      case WalletConnectionStatusEnum.NOT_CONNECTED:
        const ActionButton = () => {
          const { onConnectToMetamask: onConnect, loading } = useAsyncControl({
            onConnectToMetamask,
          })
          const [clickedInstall, setClickedInstall] = useState(false)
          return (
            <Button
              variant='filled'
              disabled={loading}
              onClick={() => {
                if (status === WalletConnectionStatusEnum.NO_PROVIDER)
                  setClickedInstall(true)
                if (!clickedInstall) onConnect()
                else onDetectMetamask()
              }}>
              <IconRarum size={28} icon='metamask' />
              &nbsp;
              {loading ? (
                <>
                  {t('walletRequiredControl.connecting')}
                  <LoaderDotsIndicator dotsCount={5} />
                </>
              ) : (
                t(
                  !clickedInstall
                    ? 'walletRequiredControl.actions.connectWalletMetamask'
                    : 'walletRequiredControl.actions.reloadWindow'
                )
              )}
            </Button>
          )
        }
        return (
          <React.Fragment key={statusKey}>
            <Typography variant={'h2'}>
              {t('walletRequiredControl.connectWallet')}
            </Typography>
            <Typography variant={'body1'}>
              {t('walletRequiredControl.connectWalletDescription')}
            </Typography>
            {links.guideMetamask && (
              <a href={links.guideMetamask} target='_blank' rel='noreferrer'>
                <Typography variant='caption'>
                  {t('walletRequiredControl.connectWalletLink')}
                </Typography>
              </a>
            )}
            <Spacing size='16' />
            <Grid item className={classes.innerContent}>
              <Button variant='transparent' color='primary' onClick={onCancel}>
                {t('generic.cancel')}
              </Button>
              <ActionButton />
            </Grid>
          </React.Fragment>
        )
      case WalletConnectionStatusEnum.WRONG_NETWORK:
        function Action() {
          const control = useAsyncControl({ onSwitchNetwork })
          return (
            <AdaptiveButton
              variant='filled'
              color={!!control.error ? 'error' : undefined}
              onClick={control.onSwitchNetwork}>
              {control.error ? (
                <Fragment key='error'>{t('generic.retryBtn')}</Fragment>
              ) : control.loading ? (
                <Fragment key='loading'>
                  <div>
                    {t('walletRequiredControl.connecting')}
                    <LoaderDotsIndicator dotsCount={5} lockSize />
                  </div>
                </Fragment>
              ) : (
                <div key='idle' className={classes.btn}>
                  <IconRarum size={28} icon='metamask' />
                  &nbsp;
                  {t('walletRequiredControl.actions.switchNetwork', chain)}
                </div>
              )}
            </AdaptiveButton>
          )
        }
        return (
          <React.Fragment key={statusKey}>
            <Typography variant={'h2'}>
              {t('walletRequiredControl.switchNetwork', chain)}
            </Typography>
            <Typography variant={'body1'}>
              {t('walletRequiredControl.switchNetworkDescription', chain)}
            </Typography>
            {links.guideMetamask && (
              <a href={links.guideMetamask} target='_blank' rel='noreferrer'>
                <Typography variant='caption'>
                  {t('walletRequiredControl.connectWalletLink')}
                </Typography>
              </a>
            )}

            <Grid item className={classes.innerContent}>
              <Button variant='transparent' onClick={onCancel}>
                {t('generic.cancel')}
              </Button>
              <Action />
            </Grid>
          </React.Fragment>
        )
      case WalletConnectionStatusEnum.CONNECTING:
        return (
          <React.Fragment key={statusKey}>
            <Typography variant={'h2'}>
              {t('walletRequiredControl.connecting')}
            </Typography>
            <div style={{ transform: 'translate(0,0)', width: '100%' }}>
              <Loading />
            </div>
          </React.Fragment>
        )
      case WalletConnectionStatusEnum.CONNECTED:
        return (
          <React.Fragment key={statusKey}>
            <Typography variant={'h2'}>
              {t('walletRequiredControl.connected')}
            </Typography>
          </React.Fragment>
        )
      case WalletConnectionStatusEnum.INVALID_WALLET:
        const baseConfig = {
          action: [
            {
              label: t('marketplace.banner.header.actions.disconnectWallet'),
              onClick: async () => onDisconnect(),
            },
            {
              label: t('generic.cancel'),
              onClick: async () => onCancel(),
            },
          ],
          description: (
            <>
              <p>
                {t('walletRequiredControl.wrongWallet.description1')}
                <br />
                <b>{connectedWallet}</b>
              </p>
              <p>
                {t('walletRequiredControl.wrongWallet.description2')}
                <br />
                <b>{expectedWallet}</b>
              </p>
            </>
          ),
          title: t('walletRequiredControl.wrongWallet.title'),
        }
        return (
          <React.Fragment key={statusKey}>
            <BaseSettleOperationModal onBack={onCancel}>
              <BaseSettleOperationView
                key={'view'}
                data={baseConfig}
                error={false}
                loading={false}
              />
            </BaseSettleOperationModal>
          </React.Fragment>
        )
      case WalletConnectionStatusEnum.MIGRATION_REQUIRED:
        return (
          <Fragment key={'migrate'}>
            <Typography variant={'h2'}>
              {t('walletRequiredControl.migrationRequired.title')}
            </Typography>
            <Typography variant={'body1'}>
              {t('walletRequiredControl.migrationRequired.description')}
            </Typography>
            <Spacing size='16' />
            <Grid item className={classes.innerContent}>
              <Button variant='transparent' color='primary' onClick={onCancel}>
                {t('generic.cancel')}
              </Button>
              <Button
                variant='filled'
                onClick={() => {
                  onMigrate()
                }}>
                {t(
                  'walletRequiredControl.migrationRequired.actions.goToMigration'
                )}
              </Button>
            </Grid>
          </Fragment>
        )
      default:
        return <React.Fragment key={'content'}>{children}</React.Fragment>
    }
  }, [status, children])

  useEffect(() => {
    if (status === WalletConnectionStatusEnum.CONNECTED)
      setTimeout(() => {
        onConnectedDisplayed()
      }, 5000)
  }, [status])

  return (
    <>
      <UncontrolledTransition
        className={classes.root}
        contentClassName={
          status !== WalletConnectionStatusEnum.SHOW_CONTENT
            ? classes.content
            : undefined
        }
        transitionType={TransitionAnimationTypes.FADE}>
        {content}
      </UncontrolledTransition>
    </>
  )
}
