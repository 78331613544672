import { useAuthentication } from 'core/logic/authentication'
import { SIGN_IN, TO_FIREBASE } from 'core/modules/router'
import BlockLoading from 'components/BlockLoading'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { ReactNode } from 'react'

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const {
    authentication: { authenticated },
  } = useAuthentication()

  return (
    <Route
      {...rest}
      render={() => {
        return authenticated ? (
          children as ReactNode
        ) : authenticated === false ? (
          <Redirect to={TO_FIREBASE[SIGN_IN]} />
        ) : (
          <BlockLoading isOpen={authenticated === null} />
        )
      }}
    />
  )
}
export default PrivateRoute
