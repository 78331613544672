import { Channel, Task } from '@redux-saga/types'
import {
  actions as dropActions,
  types as dropActionTypes,
} from 'core/logic/drop/drop.reducer'
import { eventChannel } from 'redux-saga'
import { call, cancel, fork, put, take, takeLatest } from 'redux-saga/effects'
import * as dropService from './drop.service'
import { DropBidListType, DropType } from './drop.types'

// DROPS LIST ========

export async function dropsUpdatesEmitter() {
  return eventChannel<DropType[]>((emitter) => {
    const unsubscribe = dropService.fetch({ emitter })
    return () => {
      unsubscribe()
    }
  })
}

export function* dropsSyncGenerator() {
  const emitterInterface: Channel<any> = yield call(dropsUpdatesEmitter)
  try {
    while (true) {
      const dropsChanged: DropType[] = yield take(emitterInterface)
      yield put(dropActions.fetchSuccess(dropsChanged))
    }
  } catch (_e) {
    yield put(dropActions.fetchFailed('drops.subscribe'))
  } finally {
    emitterInterface.close()
  }
}

/// DROP/OFFER BIDS

export async function dropBidsUpdatesEmitter({ dropId }: { dropId: string }) {
  return eventChannel<DropBidListType>((emitter) => {
    const unsubscribe = dropService.fetchBidsDrop({ dropId, emitter })
    return () => {
      unsubscribe()
    }
  })
}

export function* dropBidsSyncGenerator({ dropId }: { dropId: string }) {
  const emitterInterface: Channel<any> = yield call(dropBidsUpdatesEmitter, {
    dropId,
  })
  try {
    while (true) {
      const dropBidChanged: DropBidListType = yield take(emitterInterface)
      yield put(dropActions.fetchDropBidSuccess(dropBidChanged))
    }
  } catch (_e) {
    yield put(dropActions.fetchDropBidFailed('dropBids.subscribe'))
  } finally {
    emitterInterface.close()
  }
}

function* startDropBidsSyncChannel({
  payload,
}: ReturnType<typeof dropActions.emitterDropSubscribe>) {
  if (!payload) throw new Error('Drop ID missing')
  const dropBidsSyncTask: Task = yield fork(dropBidsSyncGenerator, {
    dropId: payload,
  })
  yield take(dropActionTypes.DROP_EMITTER_UNSUBSCRIBE)
  yield cancel(dropBidsSyncTask)
}
export function* watchDropBidsSubscribe() {
  yield takeLatest(
    dropActionTypes.DROP_BID_EMITTER_SUBSCRIBE,
    startDropBidsSyncChannel
  )
}

// SINGLE DROP ========

export async function dropUpdatesEmitter({ dropId }: { dropId: string }) {
  return eventChannel<{
    drop: DropType | null
  }>((emitter) => {
    const unsubscribe = dropService.fetchDrop({ dropId, emitter })
    return () => {
      unsubscribe()
    }
  })
}

export function* dropSyncGenerator({ dropId }: { dropId: string }) {
  const emitterInterface: Channel<{ drop: DropType | null }> = yield call(
    dropUpdatesEmitter,
    { dropId }
  )
  try {
    while (true) {
      const dropChanged: { drop: DropType | null } = yield take(
        emitterInterface
      )
      yield put(dropActions.fetchDropSuccess(dropChanged.drop))
    }
  } catch (_e) {
    yield put(dropActions.fetchDropFailed('drop.subscribe'))
  } finally {
    emitterInterface.close()
  }
}

function* startDropSyncChannel({
  payload,
}: ReturnType<typeof dropActions.emitterDropSubscribe>) {
  if (!payload) throw new Error('Drop ID missing')
  const dropSyncTask: Task = yield fork(dropSyncGenerator, { dropId: payload })
  yield take(dropActionTypes.DROP_EMITTER_UNSUBSCRIBE)
  yield cancel(dropSyncTask)
}

export function* watchDropSubscribe() {
  yield takeLatest(dropActionTypes.DROP_EMITTER_SUBSCRIBE, startDropSyncChannel)
}
