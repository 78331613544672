import { i18n } from 'translate/i18n'
import { useTheme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import stripeLogo from 'assets/images/stripe.svg'
import junoLogo from 'assets/images/juno-logo.png'
import coinbaseLogo from 'assets/images/coinbase.svg'
import IconRarum from 'components/IconRarum'
import useStyles from './SafetyLabel.style'
import MercadoPagoLogo from 'assets/icons/mercado_pago.svg'
import { PaymentsProvider } from 'core/logic/tenant/tenant.types'
import UncontrolledTransition from '@onepercentio/one-ui/dist/components/UncontrolledTransition'
import { TransitionAnimationTypes } from '@onepercentio/one-ui/dist/components/Transition/Transition'
import { Fragment } from 'react'

interface SafetyLabelViewProps {
  typeOrProvider?: string | PaymentsProvider
  className?: React.ReactNode
}

const SafetyLabelView: React.FC<SafetyLabelViewProps> = ({
  className,
  typeOrProvider: type,
}) => {
  const classes = useStyles()
  const theme = useTheme()

  const getLogoBasedPaymentType = (paymentType: string | undefined) => {
    switch (paymentType) {
      case 'pix':
        return junoLogo
      case 'pix_mp':
      case PaymentsProvider.MERCADO_PAGO:
        return MercadoPagoLogo
      case 'credit':
      case PaymentsProvider.STRIPE:
        return stripeLogo
      case 'crypto':
      case PaymentsProvider.COINBASE:
        return coinbaseLogo

      default:
        return ''
    }
  }

  return (
    <UncontrolledTransition transitionType={TransitionAnimationTypes.COIN_FLIP}>
      <Box
        className={clsx(classes.wrapper, className)}
        key={type ? 'by' : 'def'}>
        <IconRarum
          icon='security'
          size={22}
          color={theme.palette.grey[500]}
          className={classes.icon}
        />
        {type ? (
          <>
            <Typography
              variant='body2'
              component='span'
              className={classes.text}>
              {i18n.t('authenticated.payment.safetyLabel.safePurchaseBy')}
            </Typography>
            <figure className={classes.figure}>
              <img src={getLogoBasedPaymentType(type)} alt='' />
            </figure>
          </>
        ) : (
          <Typography variant='body2' component='span' className={classes.text}>
            {i18n.t('authenticated.payment.safetyLabel.safePurchase')}
          </Typography>
        )}
      </Box>
    </UncontrolledTransition>
  )
}

export default SafetyLabelView
