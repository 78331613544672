import { actions as purchaseActions } from 'core/logic/purchase/purchase.reducer'
import { RootState } from 'core/modules/redux'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PurchaseState } from './purchase.types'
import { getCompletedPurchasesCount } from 'core/modules/firebase/service'
import { DropType } from '../drop/drop.types'
import { useHistory } from 'react-router-dom'
import {
  SIGN_IN,
  TO_DROP_CHECKOUT,
  TO_FIREBASE,
} from 'core/modules/router'
import { auth } from 'core/modules/firebase'

export const usePurchases = () => {
  const dispatch = useDispatch()
  const { purchases } = useSelector<RootState, PurchaseState>(
    ({ purchase }) => purchase
  )
  useEffect(() => {
    dispatch(purchaseActions.emitterPurchasesSubscribe())
    return () => {
      dispatch(purchaseActions.emitterPurchasesUnsubscribe())
    }
  }, [dispatch])

  return { purchases }
}
export const useCompletedPurchases = () => {
  const [purchasesCount, setPurchasesCount] = useState<number>()

  useEffect(() => {
    getCompletedPurchasesCount().then((c) => setPurchasesCount(c))
  }, [])

  return purchasesCount
}

export const usePurchase = ({ purchaseId }: { purchaseId: string }) => {
  const dispatch = useDispatch()
  const { purchase } = useSelector<RootState, PurchaseState>(
    ({ purchase }) => purchase
  )
  useEffect(() => {
    dispatch(purchaseActions.emitterPurchaseSubscribe(purchaseId))
    return () => {
      dispatch(purchaseActions.emitterPurchaseUnsubscribe())
    }
  }, [dispatch, purchaseId])

  return { purchase }
}

export function usePurchaseAction(drop?: DropType) {
  const history = useHistory()
  const purchaseHandler = async () => {
    if (!auth.currentUser) return history.push(TO_FIREBASE[SIGN_IN])
    else history.push(TO_DROP_CHECKOUT(drop!.id))
  }
  return purchaseHandler
}
