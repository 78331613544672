import {
  Checkbox,
  FormControlLabel,
  Snackbar,
  useTheme,
} from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Alert } from '@material-ui/lab'
import clsx from 'clsx'
import ActionButton from 'components/ActionButton'
import FormField from 'components/FormFields/FormField.view'
import IconRarum from 'components/IconRarum'
import {
  ComponentProps,
  Dispatch,
  SetStateAction,
  useMemo,
  useState,
} from 'react'
import {
  Control,
  DeepMap,
  FieldError,
  UseFormHandleSubmit,
} from 'react-hook-form'
import FinishTransferModal from './FinishTransferModal/FinishTransferModal.view'
import MoreInfoModal from './MoreInfo/MoreInfo.view'
import { TransferFormInputs } from './Transfer.data'
import useStyles from './Transfer.style'
import TransferInfoModal from './TransferInfoModal/TransferInfoModal.view'
import { i18n, t } from 'translate/i18n'
import { TransferTestIds } from './Transfer.e2e'
import { RarumUserProfile } from 'core/logic/user/user.types'
import Loader from '@onepercentio/one-ui/dist/components/Loader/Loader'
import useAsyncControl from '@onepercentio/one-ui/dist/hooks/useAsyncControl'
import WalletRequiredWrapperLogic from 'components/WalletRequiredWrapper/WalletRequiredWrapper.logic'
import { useChainForIntl } from 'context/Chain'
import { useWalletOwnershipOperation } from 'context/Wallet'

const QUANTITY_MASK = '9999'
const WALLET_MASK = '*'.repeat(42)

interface TransferProps {
  email?: string | null
  balance: number
  formSubmitHandler: UseFormHandleSubmit<TransferFormInputs>
  formControl: Control<TransferFormInputs>
  formErrors: DeepMap<TransferFormInputs, FieldError>
  acceptanceCheckbox: boolean
  setAcceptanceCheckbox: Dispatch<SetStateAction<boolean>>
  createWithdrawal: WalletOwnershipBasedOperation<
    (data: TransferFormInputs) => Promise<void>
  >
  creatingWithdrawal: boolean
  mailSent: boolean
  finishTransfer?: () => void
  finishingTransfer: boolean
  showError: boolean
  closeError: () => void
  transferDone: boolean
  profile?: RarumUserProfile
  onGoToTransferTokens: () => void
  ongoingTransfer: ReturnType<typeof useAsyncControl>
  onFinishTransfer: () => void
}

const Transfer: React.FC<TransferProps> = ({
  profile,
  onGoToTransferTokens,
  ongoingTransfer,
  onFinishTransfer,
  ...props
}) => {
  const chain = useChainForIntl()
  // User e-mail and balance props
  const { email, balance } = props

  // Form-related props
  const { formSubmitHandler, formControl, formErrors } = props

  // First phase props: create withdrawal order
  const {
    acceptanceCheckbox,
    setAcceptanceCheckbox,
    createWithdrawal,
    creatingWithdrawal,
    mailSent,
  } = props

  // Second phase props: confirm the withdrawal
  const { finishTransfer, finishingTransfer, transferDone } = props

  // Error alert
  const { showError, closeError } = props
  const theme = useTheme()
  const classes = useStyles()

  // Transfer information modal state
  const [infoModal, setInfoModal] = useState(false)
  const handleInfoModalOpen = () => setInfoModal(true)
  const handleInfoModalClose = () => setInfoModal(false)

  // Create withdrawal modal state
  const [withdrawalModal, setWithdrawalModal] = useState(false)
  const handleWithdrawalModalClose = (finishedTransfer: boolean) => {
    setWithdrawalModal(false)
    if (finishedTransfer) onFinishTransfer()
  }

  // Withdrawal confirmation modal state
  const [confirmModal, setConfirmModal] = useState(finishTransfer !== undefined)
  const handleConfirmModalClose = () => setConfirmModal(false)

  // Form data state, to be sent during withdrawal creation
  const [formData, setFormData] = useState<TransferFormInputs>({})
  const onFormSubmit = (formData: TransferFormInputs) => {
    setFormData(formData)
    setWithdrawalModal(true)
  }

  // Handler for withdrawal creation
  const withdrawalHandler = useWalletOwnershipOperation<
    ComponentProps<typeof TransferInfoModal>['handleSubmit']
  >({
    async custody() {
      await createWithdrawal(formData)
      handleWithdrawalModalClose(false)
    },
    async provider() {
      await createWithdrawal(formData)
    },
  })

  const modal = useMemo(() => {
    if (!profile?.migrated) {
      return {
        description: 'authenticated.transfer.request.confirm.modal.description',
        mode: 'internal',
      } as const
    } else {
      return {
        description:
          'authenticated.transfer.request.confirm.modal.metamask.description',
        mode: 'metamask',
      } as const
    }
  }, [profile])

  let content
  const lockUI = ongoingTransfer.loading
  if (!profile) return <Loader />
  else if (profile.walletConnected && !profile.migrated) {
    content = (
      <>
        <div>
          <Typography variant='h3' className={classes.formGutter}>
            <b>{t('generic.attention')}</b>
          </Typography>
          <br />
          <Typography variant='h4' className={classes.formGutter}>
            {t('authenticated.transfer.pendingExternalTransfer.subtitle')}
          </Typography>
          <br />
          <br />
          <Typography variant='body2' className={classes.formGutter}>
            {t('authenticated.transfer.pendingExternalTransfer.description')}
          </Typography>
          <br />
          <ActionButton
            type='submit'
            text={t(
              'authenticated.transfer.pendingExternalTransfer.actions.goToTransfer'
            )}
            onClick={onGoToTransferTokens}
          />
        </div>
      </>
    )
  } else if (!mailSent) {
    /**
     * ==========================
     * FIRST PHASE:
     * User fills withdrawal info
     * ==========================
     */
    content = (
      <Box
        display='flex'
        flexDirection='column'
        alignItems='flex-start'
        mt={2}
        mb={2}>
        <Typography variant='body2' className={classes.formGutter}>
          {i18n.t('authenticated.transfer.request.description')}
        </Typography>
        <Link
          component='button'
          variant='body2'
          onClick={handleInfoModalOpen}
          className={clsx(classes.formGutter, classes.largeSpacing)}>
          <b>{i18n.t('authenticated.transfer.request.more')}</b>
        </Link>
        <form id='transfer-form' onSubmit={formSubmitHandler(onFormSubmit)}>
          <Typography variant='body2' className={classes.formGutter}>
            <strong>
              {i18n.t('authenticated.transfer.request.amount.description')}
            </strong>
          </Typography>
          <Box display='flex' alignItems='flex-start' flexDirection='row'>
            <FormField
              control={formControl}
              errors={formErrors}
              fieldPath='qty'
              label={i18n.t('authenticated.transfer.request.amount.label')}
              inputMode='numeric'
              mask={QUANTITY_MASK}
              min={1}
              max={balance || 1}
              required
              hideMaskPlaceholder
              className={clsx(classes.qty)}
              disabled={lockUI}
            />
            <Box display='flex' marginTop='25px'>
              {i18n.t('authenticated.transfer.request.amount.of')}&nbsp;
              {balance}
            </Box>
          </Box>

          <Typography variant='body2' className={classes.formGutter}>
            <strong>
              {i18n.t('authenticated.transfer.request.wallet.description')}
            </strong>
          </Typography>
          <FormField
            control={formControl}
            errors={formErrors}
            fieldPath='recipient'
            label={i18n.t('authenticated.transfer.request.wallet.label')}
            mask={WALLET_MASK}
            hideMaskPlaceholder
            required
            className={clsx(classes.recipient)}
            disabled={lockUI}
          />
          <Typography
            variant='body2'
            component='p'
            color='error'
            className={classes.formGutter}>
            <b>{i18n.t('authenticated.transfer.request.warning', chain)}</b>
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={acceptanceCheckbox}
                onChange={() => setAcceptanceCheckbox((a) => !a)}
                name='accept'
                color='primary'
                className={classes.formGutter}
                disabled={lockUI}
              />
            }
            label={i18n.t('authenticated.transfer.request.confirm.label')}
          />
          <ActionButton
            type='submit'
            form='transfer-form'
            data-testid={TransferTestIds.SUBMIT}
            text={
              lockUI ? (
                <>
                  <Loader />
                  &nbsp;&nbsp;
                  {t('authenticated.transfer.request.ongoing.loading')}
                </>
              ) : (
                i18n.t('authenticated.transfer.request.submit.label')
              )
            }
            disabled={!acceptanceCheckbox || lockUI}
          />
        </form>
      </Box>
    )
  } else {
    /**
     * ==========================
     * FIRST PHASE (post-create):
     * User should check e-mail
     * ==========================
     */
    content = (
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        className={classes.smallSpacing}>
        <IconRarum icon='email' size={64} className={classes.emailIcon} />
        <Typography
          variant='body1'
          align='center'
          className={clsx(classes.formGutter, classes.smallSpacing)}>
          <b>{i18n.t('authenticated.transfer.waiting.title')}</b>
        </Typography>
        <Typography
          variant='body2'
          align='center'
          className={classes.formGutter}>
          {i18n.t('authenticated.transfer.waiting.description', { email })}
        </Typography>
      </Box>
    )
  }

  /**
   * =============================
   * Main content area (accordion)
   * =============================
   */
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showError}
        onClose={closeError}
        autoHideDuration={4000}>
        <Alert
          onClose={closeError}
          severity='error'
          classes={{ root: classes.alert }}
          icon={
            <IconRarum
              icon='alert'
              color={theme.palette.error.main}
              size={24}
            />
          }>
          {i18n.t('authenticated.transfer.errors.unexpected')}
        </Alert>
      </Snackbar>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='transfer-nft-content'
          id='transfer-nft-header'
          data-testid={TransferTestIds.ACCORDION}>
          <Typography variant='body1' component='span'>
            <b>{i18n.t('authenticated.transfer.title')}</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {modal.mode === 'internal' ? (
            content
          ) : (
            <WalletRequiredWrapperLogic
              requiresMigration={false}
              allowCustody={true}>
              {content}
            </WalletRequiredWrapperLogic>
          )}
        </AccordionDetails>
      </Accordion>
      <MoreInfoModal isOpen={infoModal} handleClose={handleInfoModalClose} />
      <TransferInfoModal
        email={email || ''}
        wallet={formData.recipient || ''}
        quantity={formData.qty || 0}
        isOpen={withdrawalModal}
        handleClose={handleWithdrawalModalClose}
        handleSubmit={withdrawalHandler}
        loading={creatingWithdrawal}
        descriptionKey={modal.description}
        mode={modal.mode}
      />
      {/* =======================
     SECOND PHASE:
     User confirms the withdrawal
     ============================ */}
      <FinishTransferModal
        isOpen={confirmModal}
        handleClose={handleConfirmModalClose}
        handleSubmit={finishTransfer}
        loading={finishingTransfer}
        transferDone={transferDone}
      />
    </>
  )
}
export default Transfer
