import axios from 'axios'
import { ChainConfig } from 'core/constants'

interface PolygonGasFeeType {
  estimatedBaseFee: number
  standard: {
    maxPriorityFee: number
    maxFee: number
  }
}

interface estimateGasFee {
  (mode: 'provider', _endpoint: undefined): Promise<number>
  (mode: 'api', endpoint: string): Promise<number>
}

export const estimateGasFee: estimateGasFee = async (
  mode,
  endpointOrProvider
) => {
  if (mode === 'api') {
    const fees = (
      await axios.get<PolygonGasFeeType>(endpointOrProvider as string)
    ).data
    const estimatedMaxGassFee = Math.trunc(fees.standard.maxPriorityFee * 1e9)

    return estimatedMaxGassFee
  } else {
    return 2500000000
  }
}

export const estimateGasFeeFromEnv = (
  endpoint: ChainConfig['gasFeeEndpoint']
) => {
  if (endpoint) return estimateGasFee('api', endpoint)
  else return estimateGasFee('provider', undefined)
}
