import { PurchaseType } from 'core/logic/purchase/purchase.types'
import { Elements } from '@stripe/react-stripe-js'
import CheckoutForm from './Checkout.form'
import { REACT_APP_STRIPE_API_KEY } from 'core/constants'
import { useMemo } from 'react'
import useLanguage from 'openspace/hooks/useLanguage'
import { StripeElementsOptions } from '@stripe/stripe-js'
interface StripeContainerProps {
  purchase: PurchaseType
}

const StripeContainer: React.FC<StripeContainerProps> = ({ purchase }) => {
  const stripePromise = useMemo(
    () =>
      import('@stripe/stripe-js').then(({ loadStripe }) =>
        loadStripe(REACT_APP_STRIPE_API_KEY)
      ),
    []
  )
  const clientSecret = purchase.charge.client_secret
  const language = useLanguage()
  const options = useMemo(() => {
    let stripeLanguage: StripeElementsOptions['locale']
    switch (language) {
      case 'en-US':
        stripeLanguage = 'en'
        break
      case 'pt-BR':
        stripeLanguage = 'pt-BR'
        break
      case 'es-ES':
        stripeLanguage = 'es-ES'
        break
    }
    return {
      clientSecret: purchase.charge.client_secret,
      locale: stripeLanguage!,
    }
  }, [])

  return (
    <>
      {clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm purchaseId={purchase.id} />
        </Elements>
      )}
    </>
  )
}

export default StripeContainer
