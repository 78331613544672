import { builder } from 'core/helpers'
import * as DropTypes from './drop.types'

// == TYPES ==
const DROP_EMITTER_SUBSCRIBE = 'drop/emitter/SUBSCRIBE'
const DROP_EMITTER_UNSUBSCRIBE = 'drop/emitter/UNSUBSCRIBE'
const DROP_FETCH_SUCCESS = 'drop/fetch/SUCCESS'
const DROP_FETCH_FAILED = 'drop/fetch/FAILED'

const DROPS_FETCH_SUCCESS = 'drops/fetch/SUCCESS'
const DROPS_FETCH_FAILED = 'drops/fetch/FAILED'

const DROP_BID_EMITTER_SUBSCRIBE = 'drop/bid/emitter/SUBSCRIBE'
const DROP_BID_EMITTER_UNSUBSCRIBE = 'drop/bid/emitter/UNSUBSCRIBE'
const DROP_BID_FETCH_SUCCESS = 'drop/bid/fetch/SUCCESS'
const DROP_BID_FETCH_FAILED = 'drop/bid/fetch/FAILED'

export const types = Object.freeze({
  DROP_EMITTER_SUBSCRIBE: DROP_EMITTER_SUBSCRIBE,
  DROP_EMITTER_UNSUBSCRIBE: DROP_EMITTER_UNSUBSCRIBE,
  DROP_FETCH_SUCCESS: DROP_FETCH_SUCCESS,
  DROP_FETCH_FAILED: DROP_FETCH_FAILED,
  FETCH_SUCCESS: DROPS_FETCH_SUCCESS,
  FETCH_FAILED: DROPS_FETCH_FAILED,
  DROP_BID_EMITTER_SUBSCRIBE: DROP_BID_EMITTER_SUBSCRIBE,
  DROP_BID_EMITTER_UNSUBSCRIBE: DROP_BID_EMITTER_UNSUBSCRIBE,
  DROP_BID_FETCH_SUCCESS: DROP_BID_FETCH_SUCCESS,
  DROP_BID_FETCH_FAILED: DROP_BID_FETCH_FAILED,
})

// == ACTIONS ==
// Drop Bid
const emitterDropBidsSubscribe = (dropId: string) =>
  builder.actionCreator<DropTypes.DropActionsTypes, string>(
    DROP_BID_EMITTER_SUBSCRIBE,
    dropId
  )
const emitterDropBidUnsubscribe = () =>
  builder.actionCreator<DropTypes.DropActionsTypes>(
    DROP_BID_EMITTER_UNSUBSCRIBE
  )
const fetchDropBidSuccess = (bids: DropTypes.DropBidListType) =>
  builder.actionCreator<DropTypes.DropActionsTypes, DropTypes.DropBidListType>(
    DROP_BID_FETCH_SUCCESS,
    bids
  )
const fetchDropBidFailed = (errorKey: string) =>
  builder.actionCreator<DropTypes.DropActionsTypes, string>(
    DROP_BID_FETCH_FAILED,
    errorKey
  )

// Drop document
const emitterDropSubscribe = (dropId: string) =>
  builder.actionCreator<DropTypes.DropActionsTypes, string>(
    DROP_EMITTER_SUBSCRIBE,
    dropId
  )
const emitterDropUnsubscribe = () =>
  builder.actionCreator<DropTypes.DropActionsTypes>(DROP_EMITTER_UNSUBSCRIBE)

const fetchDropSuccess = (drop: DropTypes.DropType | null) =>
  builder.actionCreator<DropTypes.DropActionsTypes, DropTypes.DropType | null>(
    DROP_FETCH_SUCCESS,
    drop
  )

const fetchDropFailed = (errorKey: string) =>
  builder.actionCreator<DropTypes.DropActionsTypes, string>(
    DROP_FETCH_FAILED,
    errorKey
  )

// Drop list
const fetchSuccess = (drops: DropTypes.DropType[]) =>
  builder.actionCreator<DropTypes.DropActionsTypes, DropTypes.DropType[]>(
    DROPS_FETCH_SUCCESS,
    drops
  )

const fetchFailed = (errorKey: string) =>
  builder.actionCreator<DropTypes.DropActionsTypes, string>(
    DROPS_FETCH_FAILED,
    errorKey
  )

export const actions = Object.freeze({
  emitterDropBidsSubscribe,
  emitterDropBidUnsubscribe,
  fetchDropBidSuccess,
  fetchDropBidFailed,
  emitterDropSubscribe,
  emitterDropUnsubscribe,
  fetchDropSuccess,
  fetchDropFailed,
  fetchSuccess,
  fetchFailed,
})

export const mapActions = Object.freeze({
  [DROP_BID_EMITTER_SUBSCRIBE]: emitterDropBidsSubscribe,
  [DROP_BID_EMITTER_UNSUBSCRIBE]: emitterDropBidUnsubscribe,
  [DROP_BID_FETCH_SUCCESS]: fetchDropBidSuccess,
  [DROP_BID_FETCH_FAILED]: fetchDropBidFailed,
  [DROP_EMITTER_SUBSCRIBE]: emitterDropSubscribe,
  [DROP_EMITTER_UNSUBSCRIBE]: emitterDropUnsubscribe,
  [DROP_FETCH_FAILED]: fetchDropFailed,
  [DROP_FETCH_SUCCESS]: fetchDropSuccess,
  [DROPS_FETCH_SUCCESS]: fetchSuccess,
  [DROPS_FETCH_FAILED]: fetchFailed,
})

// == REDUCER ==
export const initialState: Readonly<DropTypes.DropState> = Object.freeze({
  drop: undefined,
  dropBids: undefined,
  drops: null,
  errorKey: '',
})

export const reducer = (
  state: Readonly<DropTypes.DropState> = initialState,
  action: Readonly<DropTypes.DropAction>
): DropTypes.DropState => {
  const { type, payload } = action
  switch (type) {
    case DROP_EMITTER_UNSUBSCRIBE:
      return { ...state }
    case DROP_BID_EMITTER_UNSUBSCRIBE:
      return { ...state, dropBids: undefined }
    case DROP_BID_FETCH_SUCCESS:
      return { ...state, dropBids: payload as DropTypes.DropBidListType }
    case DROP_FETCH_SUCCESS:
      return { ...state, drop: payload as DropTypes.DropType }
    case DROP_EMITTER_SUBSCRIBE:
      return { ...state, drop: undefined }
    case DROP_FETCH_FAILED:
      return { ...state, errorKey: payload as string }
    case DROPS_FETCH_SUCCESS:
      return { ...state, drops: payload as DropTypes.DropType[] }
    case DROPS_FETCH_FAILED:
      return { ...state, errorKey: payload as string }
    default:
      return state
  }
}
