import { types as authActionTypes } from 'core/logic/authentication/auth.reducer'
import { analyticsEvent } from 'core/modules/analytics/events'
import { analytics, auth } from 'core/modules/firebase'
import { put, takeEvery } from 'redux-saga/effects'
import { actions as analyticsActions } from './analytics.reducer'

export function* _processLoginSuccess() {
  const userId = auth.currentUser?.uid

  if (userId) {
    analyticsEvent.login({ method: 'user_password' })
    analytics.setUserId(userId)
    yield put(analyticsActions.loginSuccess())
  }
}

export function* _processAuthenticated() {
  const userId = auth.currentUser?.uid

  if (userId) {
    analytics.setUserId(userId)
    yield put(analyticsActions.setUserId())
  }
}

// watch login success
export function* watchLoginSuccess() {
  yield takeEvery(authActionTypes.LOGIN_SUCCESS, _processLoginSuccess)
}

// watch authentication success
export function* watchAuthenticated() {
  yield takeEvery(authActionTypes.AUTHENTICATED, _processAuthenticated)
}
