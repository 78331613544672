import { Fragment, useEffect, useState } from 'react'
import { RequirementViews } from './OfferRequirements.view'
import {
  OfferRequirementsProps,
  IRequirementInterface,
  RequirementTypes,
} from './OfferRequirements.types'
import Loader from '@onepercentio/one-ui/dist/components/Loader'
import { auth } from 'core/modules/firebase'
import { useUser } from 'core/logic/user'

const RequirementImplementations: {
  [k in RequirementTypes]: IRequirementInterface
} = {
  cvm88: {
    isFullfilled: async () => false,
    onFullfill: async () => {},
    requirementView: RequirementViews.cvm88,
  },
  regulation: {
    isFullfilled: async (offer) =>
      localStorage.getItem(
        `regulation-${offer.id}-${auth.currentUser?.uid}`
      ) === 'true',
    onFullfill: async (offer) =>
      localStorage.setItem(
        `regulation-${offer.id}-${auth.currentUser?.uid}`,
        'true'
      ),
    requirementView: RequirementViews.regulation,
  },
}

export default function OfferRequirementsLogic({
  auction,
  children,
  onCancel,
}: OfferRequirementsProps) {
  const { profile } = useUser()

  const [requirementsToBeFullFilled, setRequirementsToBeFullfilled] =
    useState<IRequirementInterface[]>()
  useEffect(() => {
    async function filterRequirements() {
      if (!auction) return
      const possibleRequirements: IRequirementInterface[] = []
      if (auction.requirements?.approveRegulation)
        possibleRequirements.push(RequirementImplementations.regulation)
      if (auction.requirements?.cvm88)
        possibleRequirements.push(RequirementImplementations.cvm88)

      for (let requirement of possibleRequirements) {
        const isNeeded = !(await requirement.isFullfilled(auction, profile!))
        if (!isNeeded)
          possibleRequirements.splice(
            possibleRequirements.indexOf(requirement),
            1
          )
      }
      setRequirementsToBeFullfilled(possibleRequirements)
    }
    filterRequirements()
  }, [auction, profile])

  const currentRequirement = requirementsToBeFullFilled?.[0]!
  const content =
    requirementsToBeFullFilled === undefined || auction === undefined ? (
      <Loader key='l' />
    ) : requirementsToBeFullFilled[0] === undefined ? (
      <Fragment key={'child'}>{children}</Fragment>
    ) : (
      <currentRequirement.requirementView
        key={requirementsToBeFullFilled!.length}
        auction={auction}
        onCancel={onCancel}
        onFullfill={() => {
          currentRequirement
            .onFullfill(auction)
            .then(() =>
              setRequirementsToBeFullfilled(
                ([_toDiscard, ...prev] = []) => prev || []
              )
            )
        }}></currentRequirement.requirementView>
    )

  return content
}
