import { RootState } from 'core/modules/redux'
import { useSelector } from 'react-redux'
import { GalleryState } from './gallery.types'
import { useMemo } from 'react'

export const useGallery = ({ id }: { id?: string }) => {
  const { gallery } = useSelector<RootState, GalleryState>(
    ({ gallery }) => gallery
  )

  return { gallery }
}

/**
 * @improvement When we get to a huge number of galleries, it will be necessary to require them on demand, so a logic that loads the missing galleries will be required
 * @param ids Ids to filter by
 * @returns
 */
export const useGalleries = (ids?: string[]) => {
  const { galleries } = useSelector<RootState, GalleryState>(
    ({ gallery }) => gallery
  )

  const selectedGalleries = useMemo(() => {
    if (!galleries) return galleries
    if (ids) return galleries.filter((g) => ids.includes(g.id))
    return galleries
  }, [ids, galleries])

  const hasLoadedRequestedGalleries = useMemo(() => {
    return ids?.length === galleries?.length
  }, [ids, galleries])

  return { galleries: selectedGalleries, hasLoadedRequestedGalleries }
}
