import { useTheme } from '@material-ui/core'
import {
  initFirebaseKYCIntent,
  registerKYCIntent,
} from 'core/modules/firebase/service'
import {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react'
import { i18n } from 'translate/i18n'

let injectScript: any
const METAMAP_TAG = 'metamap-button' as any
function KYCLogic(
  {
    visible = true,
    ...props
  }: {
    config: {
      clientId: string
      flowId: string
    }
    extraInformation: { [k: string]: string }
    onCancel: () => void
    onFinish: () => void
    visible?: boolean
  },
  ref: ForwardedRef<{
    element: HTMLElement & {
      language: string
    }
  }>
) {
  const metamapRef = useRef<
    HTMLElement & {
      language: string
    }
  >(null)
  const intentDoc = useMemo(() => initFirebaseKYCIntent(), [])
  useImperativeHandle(
    ref,
    () => ({
      element: metamapRef.current!,
    }),
    []
  )

  useEffect(() => {
    if (!injectScript) {
      const scriptTag = document.createElement('script')
      scriptTag.src = `https://web-button.mati.io/button.js`
      document.head.appendChild(scriptTag)
    }
    const mainLanguage = i18n.language.slice(0, 2)
    metamapRef.current!.language = mainLanguage
    metamapRef.current!.setAttribute(
      'metadata',
      JSON.stringify({
        ...props.extraInformation,
        fixedLanguage: mainLanguage,
        kycDocumentId: intentDoc.id,
      })
    )
  })

  useEffect(() => {
    const onFinishCb = () => {
      props.onFinish()
      unbindListeners()
    }
    const metamap = metamapRef.current!
    metamap.addEventListener('metamap:userFinishedSdk', onFinishCb)
    metamap.addEventListener('metamap:exitedSdk', props.onCancel)
    const unbindListeners = () => {
      metamap.removeEventListener('metamap:userFinishedSdk', onFinishCb)
      metamap.removeEventListener('metamap:exitedSdk', props.onCancel)
    }
    return unbindListeners
  }, [])

  function _registerKYCIntent() {
    registerKYCIntent(intentDoc)
  }

  const theme = useTheme()
  return (
    <>
      <METAMAP_TAG
        ref={metamapRef}
        style={{ display: visible ? 'initial' : 'none' }}
        onClick={() => _registerKYCIntent()}
        clientId={props.config.clientId} // from your Metamap dashboard
        flowId={props.config.flowId} // from your Metamap dashboard
        color={theme.palette.primary.main} // to setup main color of buttons in your metamap
        textcolor={theme.palette.background.default} // to setup text color of buttons in your metamap
      />
    </>
  )
}

export default forwardRef(KYCLogic)
