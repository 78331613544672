import { makeStyles } from '@material-ui/core'

const style = makeStyles((theme) => ({
  orderable: {
    width: '100%',
    '& > *': {
      display: 'inline-flex',
      maxWidth: '100%',
    },
  },
  errorModal: {
    '& > *': {
      margin: '10px',
    },
  },
  main: {
    marginBottom: theme.spacing(20),
  },
  container: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '& p': {
      marginLeft: '0px',
    },
    marginBottom: '2rem',
  },
  wrap: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  tag: {
    marginRight: '10px',
    padding: '10px',
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: theme.palette.text.primary,
      color: theme.palette.background.default,
    },
  },
  pushToRight: {
    marginLeft: 'auto',
  },
  itemContainer: {
    flexDirection: 'column',
  },
  title: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8rem',
      marginTop: theme.spacing(4),
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
  },
  description: {
    marginBottom: theme.spacing(7),

    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
      fontSize: '0.8rem',
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  boxList: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    transform: 'translate(0,0)',
  },
  filters: {
    alignItems: 'stretch',
    paddingLeft: 20,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-around',
    },
    flexDirection: 'column',
    '& > *': {
      display: 'flex',
    },
    '& a': {
      textDecoration: 'underline',
      cursor: 'pointer',
      marginRight: 'auto',
    },
  },
  noResults: {
    margin: 'auto',
    textAlign: 'center',
    '& h2': {
      marginBottom: '5rem',
    },
  },
}))

export default style
