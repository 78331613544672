import { currencyFormatter } from 'core/helpers/formatter'
import { useAuthentication } from 'core/logic/authentication'
import { OfferType } from 'core/logic/drop/drop.types'
import { useTenant } from 'core/logic/tenant/tenant.hook'
import { SIGN_IN, TO_FIREBASE } from 'core/modules/router'
import {
  ComponentProps,
  PropsWithChildren,
  ReactElement,
  useMemo,
  useState,
} from 'react'
import { Redirect } from 'react-router-dom'
import { useTranslateDocument } from 'translate/useTranslateDocument'
import { OfferAuctionBidModalView } from './OfferAuctionBidModalView'
import { orderStepsMap } from './stepMap'
import { BidStepsType, OfferAuctionBidModalProps } from './types'
import { MAX_BID_RATIO, MIN_BID_RATIO } from './constants'
import { useUser } from 'core/logic/user'
import WalletRequiredWrapper from 'components/WalletRequiredWrapper'
import AdaptiveDialog from '@onepercentio/one-ui/dist/components/AdaptiveDialog'

const OfferAuctionBidModalImpl: React.FC<OfferAuctionBidModalProps> = ({
  initialBid,
  currentBid,
  minBidRatio = MIN_BID_RATIO,
  maxBidRatio = MAX_BID_RATIO,
  handleModal,
  ...props
}) => {
  const [phone, setPhone] = useState<string>()
  const [amount, setAmount] = useState<number>()
  // get tenant config
  const { tenant } = useTenant()
  // translate offer
  const offer = useTranslateDocument<OfferType>({
    data: props.offer,
  }) as OfferType
  // create formatter by tenant or offer currency
  const formatter = currencyFormatter(tenant?.currencies?.preferredCurrency)
  // event submit
  const userData = useUser()
  const [currentStep, setCurrentStep] = useState<BidStepsType>(() => {
    if (
      userData.profile?.phoneConfirmed ||
      tenant!.phoneValidation === false ||
      !!props.offer.requirements?.kycForBid
    ) {
      return orderStepsMap[1]
    }
    return orderStepsMap[0]
  })
  const handleMoveSteps =
    (move: number) =>
    (...params: any[]) => {
      let next = orderStepsMap.indexOf(currentStep) + move
      if (next < 0) next = 0
      if (next >= orderStepsMap.length) next = orderStepsMap.length - 1
      switch (currentStep) {
        case 'phoneConfirmation':
          setPhone(params[0])
          break
        case 'form':
          setAmount(params[0])
          break
        case 'finish':
          // const isSuccess = params[0] as boolean
          handleModal(false)()
      }
      setCurrentStep(orderStepsMap[next])
    }
  const handleAmountChange = (
    value: string,
    callback: (value: number) => void
  ) => {
    const amount: number = parseInt(value?.match(/\d/gi)?.join('') || '0', 10)
    const decimals = amount / 100
    callback(decimals)
  }
  // phone mask
  const handlePhoneChange = (
    value: string,
    callback: (value: string) => void
  ) => {
    let digits: string = value?.match(/\d/gi)?.join('') || ''
    if (value.match(/^\+/)) {
      // is international number mask
      setPhone(`+${digits}`)
    } else {
      digits = digits.slice(0, 11)
      if (digits.length === 11) {
        setPhone(
          `(${digits.slice(0, 2)}) ${digits.slice(2, 7)}-${digits.slice(
            7,
            11
          )}`.replace(/\D*$/gi, '')
        )
      } else {
        setPhone(
          `(${digits.slice(0, 2)}) ${digits.slice(2, 6)}-${digits.slice(
            6,
            10
          )}`.replace(/\D*$/gi, '')
        )
      }
    }
    callback(digits)
  }
  // redirect to sigin when user not autenticated
  return (
    <OfferAuctionBidModalView
      {...props}
      handleModal={handleModal}
      offer={offer}
      handleAmountChange={handleAmountChange}
      amount={amount!}
      phone={phone || ''}
      handlePhoneChange={handlePhoneChange}
      initialBid={initialBid ? formatter(initialBid.amount) : ''}
      currentBid={currentBid ? formatter(currentBid.amount) : ''}
      currentStep={currentStep}
      onPrev={handleMoveSteps(-1)}
      onNext={handleMoveSteps(1)}
      onFinish={handleModal(false)}
      onCancel={handleModal(false)}
    />
  )
}

export const OfferAuctionBidModal = function (
  props: ComponentProps<typeof OfferAuctionBidModalImpl>
) {
  // chek for authenticated user
  const {
    authentication: { authenticated },
  } = useAuthentication()

  const { offer, handleModal } = props

  const Wrapper = useMemo<
    (children: PropsWithChildren<{}>) => ReactElement
  >(() => {
    if (offer.type === 'auction') {
      const goBack = handleModal(false)
      return ({ children }) => (
        <WalletRequiredWrapper onBack={goBack}>
          {children}
        </WalletRequiredWrapper>
      )
    } else return ({ children }) => children as ReactElement
  }, [offer.type])

  return props.open && authenticated === false ? (
    <Redirect to={TO_FIREBASE[SIGN_IN]} /> || <></>
  ) : (
    <AdaptiveDialog open={props.open}>
      <Wrapper>
        <OfferAuctionBidModalImpl {...props} />
      </Wrapper>
    </AdaptiveDialog>
  )
}
