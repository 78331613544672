import { ConceptTranslations } from "openspace/translate/languages/pt"
import { useMemo } from "react"
import { t } from "translate/i18n"
import { AvailableConcepts } from "./Features"
import * as Icons from './Features.images'

export function useFeatures() {
  const availableConcepts = t<'openspace', ConceptTranslations>(
    'home.sections.features.concepts',
    { returnObjects: true }
  )
  const concepts = useMemo(() => {
    return Object.keys(availableConcepts).map((v) => ({
      ...availableConcepts[v as AvailableConcepts],
      icon: Icons[v as AvailableConcepts],
      concept: v as AvailableConcepts,
    }))
  }, [])
  return concepts
}
