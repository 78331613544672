import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) =>
  createStyles({
    brandText: {
      textTransform: 'uppercase',
      fontSize: '0.65rem',
      fontWeight: 500,
      letterSpacing: 1,
      marginTop: theme.spacing(2),
    },
  })
export default makeStylesWrapper(style)
