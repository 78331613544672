import {
  Box,
  Typography,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import ExpandMoreOutlined from '@material-ui/icons/ExpandMoreOutlined'
import clsx from 'clsx'
import Footer from 'components/Footer'

import { ReactNode, useMemo } from 'react'
import { i18n } from 'translate/i18n'
import useStyles from './FAQ.style'
import { useChainForIntl } from 'context/Chain'
interface ComponentsProps {
  children: ReactNode
  title?: string
  classContent?: string
  classTitle?: string
}
const FaqView = () => {
  const chain = useChainForIntl()
  const classes = useStyles()
  const Faq = ({
    title,
    children,
    classContent,
    classTitle,
  }: ComponentsProps) => (
    <Box className={classes.box}>
      <Box className={classes.faqIndexContainer}>
        <Typography
          component='span'
          className={clsx(classes.faqIndex, classTitle)}>
          {title}
        </Typography>
        <Typography
          component='span'
          className={clsx(classes.faqIndexContent, classContent)}>
          {children}
        </Typography>
      </Box>
    </Box>
  )

  const Accordion = ({ title, children }: ComponentsProps) => (
    <MuiAccordion>
      <AccordionSummary
        expandIcon={<ExpandMoreOutlined />}
        className={classes.muiAccordionSummary}>
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography className={classes.accordionContent}>{children}</Typography>
      </AccordionDetails>
    </MuiAccordion>
  )

  const { titles, descriptions } = useMemo(() => {
    return {
      titles: Object.values(
        i18n.t('unauthenticated.faqview.faq.accordion.title', {
          returnObjects: true,
          ...chain,
        })
      ) as string[],
      descriptions: Object.values(
        i18n.t('unauthenticated.faqview.faq.accordion.content', {
          returnObjects: true,
          ...chain
        })
      ) as string[],
    }
  }, [chain])

  return (
    <>
      <Box className={classes.container}>
        <Faq title='FAQ' classContent={classes.faqAccordion}>
          {titles.map((title, i) => (
            <Accordion key={title} title={title}>
              <div
                dangerouslySetInnerHTML={{
                  __html: descriptions[i],
                }}
              />
            </Accordion>
          ))}
        </Faq>
        <Footer />
      </Box>
    </>
  )
}
export default FaqView
