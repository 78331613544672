import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) =>
  createStyles({
    root: { display: 'flex' },
    avatar: {
      border: `2px solid ${theme.palette.grey[200]}`,
    },
    avatarDark: {
      border: `2px solid ${theme.palette.common.black}`,
    },
  })
export default makeStylesWrapper(style)
