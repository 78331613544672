import { i18n } from 'translate/i18n'
import { useHistory } from 'react-router-dom'
import EmptyCollectionView from './EmptyCollection.view'

const EmptyCollectionLogic = (props: {"data-testid"?: string}) => {
  const history = useHistory()

  const goToStore = () => history.push('/')

  return (
    <EmptyCollectionView
      actionName={i18n.t(
        'authenticated.myCollection.emptyCollection.goToStore'
      )}
      action={goToStore}
      {...props}
    />
  )
}

export default EmptyCollectionLogic
