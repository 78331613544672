import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const style = (_: Theme) =>
  createStyles({
    root: {
      position: 'initial',
    },
  })
export default makeStylesWrapper(style)
