import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { MouseEventHandler } from 'react'
import useStyles from './ShareItem.style'
import MenuItem from '@material-ui/core/MenuItem'

interface ShareItemViewProps {
  title: string
  onClick: MouseEventHandler<HTMLElement>
  Icon: import('@material-ui/icons').SvgIconComponent
}

const ShareItemView: React.FC<ShareItemViewProps> = ({ title, onClick, Icon }) => {
  const classes = useStyles()
  return (
    <MenuItem key={title} onClick={onClick}>
      <ListItemText primary={title} primaryTypographyProps={{ variant: 'body2', color: 'textPrimary', className: classes.item }} />
      <ListItemIcon>
        <Icon fontSize='small' color='primary' />
      </ListItemIcon>
    </MenuItem>
  )
}
export default ShareItemView
