import { Chains } from "context/Chain";

export default {
  POLYGON: {
    unauthenticated: {
      faqview: {
        faq: {
          accordion: {
            content: {
              10: `A rede {{name}} é uma das mais promissoras infraestruturas para NFT atualmente com uma grande comunidade de desenvolvedores, empresas e organizações dedicados a fortalecer e amadurecer a tecnologia e suas aplicações.`,
            }
          }
        }
      }
    }
  },
  MOONBEAM: {
    unauthenticated: {
      faqview: {
        faq: {
          accordion: {
            content: {
              10: `A {{name}} é uma parachain da rede Polkadot com foco em interoperabilidade e suporte a EVM. É a maior parachain em termos de volume e transações da Polkadot, por isso a escolhemos para lançar esta loja.`,
            }
          }
        }
      }
    }
  },
} as {
    [chainId in Chains]: {
      unauthenticated: {
        faqview: {
          faq: {
            accordion: {
              content: {
                10: string;
              }
            }
          }
        }
      }
    }
  }