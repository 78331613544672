import React from 'react'
import View, { IPOBannerView } from './IPO.view'
import { IPOProps } from './IPO.types'
import { useOfferUnits } from 'core/logic/counters/counters.hook'
import { useHistory } from 'react-router-dom'
import { usePurchaseAction } from 'core/logic/purchase/purchase.hook'
import FooterLogic from 'components/Footer/Footer.logic'
import { TO_DROP_DETAILS } from 'core/modules/router'
import { useIPOInformation } from './IPO.hooks'

export default function IPOLogic({ offer }: IPOProps) {
  const history = useHistory()
  const onPurchase = usePurchaseAction(offer)

  const reservedUnits = useOfferUnits(offer.id)
  const { goal, goalUnits } = useIPOInformation(offer)

  return (
    <>
      <View
        offer={offer}
        endAt={offer.expire!}
        goal={goal}
        onBack={history.goBack}
        onInvest={onPurchase}
        companyShare={offer.cvm88.companyShare}
        valuation={offer.cvm88.valuation}
        quotas={[offer.supply, goalUnits, reservedUnits]}
      />
      <FooterLogic />
    </>
  )
}

export function IPOBanner({ offer }: Pick<IPOProps, 'offer'>) {
  const history = useHistory()
  const goal = offer.cvm88.unitsGoal
  const reservedUnits = useOfferUnits(offer.id)
  return (
    <IPOBannerView
      goal={goal}
      offer={offer}
      onKnowMore={() => history.push(TO_DROP_DETAILS(offer.id))}
      quotas={[offer.supply, goal, reservedUnits]}
    />
  )
}
