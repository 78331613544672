import { makeStyles } from '@material-ui/core'
import { IconRarumTitle } from 'components/IconRarum'

const useBackButtonStyles = makeStyles<
  any,
  {
    icon?: IconRarumTitle
  }
>(() => ({
  root: {
    padding: '12px 24px',
    '& > :first-child': {
      transform: ({ icon }) => (icon === 'arrow' ? 'rotateY(180deg)' : ''),
    },
  },
}))

export default useBackButtonStyles
