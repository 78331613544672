import { useMemo } from 'react'
import { CurrenciesEnum } from 'core/types/CurrenciesEnum'
import {
  currencyFormatterFactory,
} from 'core/helpers/formatter'
import { t } from 'translate/i18n'

interface CurrencyProps {
  currency: CurrenciesEnum
  amount: number
}

export const Currency: React.FC<CurrencyProps> = ({ currency, amount }) => {
  const formatter = useMemo(
    () => currencyFormatterFactory(currency),
    [currency]
  )
  const formattedParts = useMemo(
    () => formatter.formatToParts(amount),
    [amount, formatter]
  )
  return (
    <span className='price'>
      {amount <= 0 ? (
        <span className='no-amount'>
          {t('components.offerPrice.noPrice')}
        </span>
      ) : (
        formattedParts.map(({ type, value }, key) => {
          return (
            <span key={key} className={type}>
              {value}
            </span>
          )
        })
      )}
    </span>
  )
}
