import { useTheme } from '@material-ui/core'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Fade from '@material-ui/core/Fade'
import ListItemText from '@material-ui/core/ListItemText'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import { copyButtonItem, ShareButtonClickHandler, ShareButtonItem, shareButtonItems } from 'core/logic/share/share/share.data'
import { mobileShare } from 'core/logic/share/share/share.logic'
import SquareButton from 'components/SquareButton'
import { MouseEvent, useRef, useState } from 'react'
import useStyles from './ShareButton.style'
import ShareItemView from './ShareItem'
import MenuItem from '@material-ui/core/MenuItem'
import { t } from 'translate/i18n'

type HandleCloseType = (event: MouseEvent<HTMLElement | Document>, onClick?: ShareButtonClickHandler) => void

// Curried function to map a shareButtonItem into a MenuItem
const menuItemMapper =
  (handleClose: HandleCloseType) =>
  ({ title, onClick, Icon }: ShareButtonItem) =>
    <ShareItemView title={title} Icon={Icon} onClick={(event) => handleClose(event, onClick)} />

export interface ShareButtonProps {
  id: string
  name: string
}

const ShareButtonView: React.FC<ShareButtonProps> = ({ id, name }) => {
  const theme = useTheme()
  const classes = useStyles()

  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)

  const shareButtonClickHandler = () => {
    // @ts-ignore: We need to check for navigator.share because it's only available on mobile devices
    if (navigator.share) {
      mobileShare({ id, title: name, url: window.location.href, text: name })
    } else {
      setOpen(true)
    }
  }

  // Popover close event handler
  const handleClose: HandleCloseType = (event, onClick) => {
    // Regarding the type assertion to Node: https://stackoverflow.com/a/43851475/4615065
    if (anchorRef.current && anchorRef.current.contains(event.target as Node)) {
      return
    }
    if (onClick) {
      onClick({ id, title: name, url: window.location.href, text: name })
    }
    setOpen(false)
  }

  const miMap = menuItemMapper(handleClose)

  return (
    <div ref={anchorRef}>
      <SquareButton active={open} icon='share' onClick={shareButtonClickHandler} />
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role='dialog'
        transition
        className={classes.popper}
        placement='bottom-end'
        popperOptions={{
          modifiers: {
            offset: {
              offset: `0,${theme.spacing(1)}`,
            },
          },
        }}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350} style={{ transformOrigin: 'center bottom' }}>
            <Paper elevation={3}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} disablePadding className={classes.menulist}>
                  <MenuItem disabled key='share-heading'>
                    <ListItemText
                      primary={t('generic.share')}
                      primaryTypographyProps={{ variant: 'body2', color: 'textSecondary', align: 'right' }}
                      className={classes.shareHeader}
                    />
                  </MenuItem>
                  {shareButtonItems.map(miMap)}
                  {miMap(copyButtonItem)}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  )
}

export default ShareButtonView
