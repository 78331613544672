import { builder } from 'core/helpers'
import * as DeliveryTypes from './delivery.types'

// == TYPES ==
const DELIVERY_EMITTER_SUBSCRIBE = 'delivery/emitter/SUBSCRIBE'
const DELIVERY_EMITTER_UNSUBSCRIBE = 'delivery/emitter/UNSUBSCRIBE'
const DELIVERY_FETCH_SUCCESS = 'delivery/fetch/SUCCESS'
const DELIVERY_FETCH_FAILED = 'delivery/fetch/FAILED'
const DELIVERIES_EMITTER_SUBSCRIBE = 'deliveries/emitter/SUBSCRIBE'
const DELIVERIES_EMITTER_UNSUBSCRIBE = 'deliveries/emitter/UNSUBSCRIBE'
const DELIVERIES_FETCH_SUCCESS = 'deliveries/fetch/SUCCESS'
const DELIVERIES_FETCH_FAILED = 'deliveries/fetch/FAILED'

export const types = Object.freeze({
  DELIVERY_EMITTER_SUBSCRIBE: DELIVERY_EMITTER_SUBSCRIBE,
  DELIVERY_EMITTER_UNSUBSCRIBE: DELIVERY_EMITTER_UNSUBSCRIBE,
  DELIVERY_FETCH_SUCCESS: DELIVERY_FETCH_SUCCESS,
  DELIVERY_FETCH_FAILED: DELIVERY_FETCH_FAILED,
  DELIVERIES_EMITTER_SUBSCRIBE: DELIVERIES_EMITTER_SUBSCRIBE,
  DELIVERIES_EMITTER_UNSUBSCRIBE: DELIVERIES_EMITTER_UNSUBSCRIBE,
  DELIVERIES_FETCH_SUCCESS: DELIVERIES_FETCH_SUCCESS,
  DELIVERIES_FETCH_FAILED: DELIVERIES_FETCH_FAILED,
})

// == ACTIONS ==
// Single delivery
const emitterDeliverySubscribe = (deliveryId: string) =>
  builder.actionCreator<DeliveryTypes.DeliveryActionsTypes, string>(
    DELIVERY_EMITTER_SUBSCRIBE,
    deliveryId
  )

const emitterDeliveryUnsubscribe = () =>
  builder.actionCreator<DeliveryTypes.DeliveryActionsTypes>(
    DELIVERY_EMITTER_UNSUBSCRIBE
  )

const fetchDeliverySuccess = (delivery: DeliveryTypes.DeliveryType) =>
  builder.actionCreator<
    DeliveryTypes.DeliveryActionsTypes,
    DeliveryTypes.DeliveryType
  >(DELIVERY_FETCH_SUCCESS, delivery)

const fetchDeliveryFailed = (errorKey: string) =>
  builder.actionCreator<DeliveryTypes.DeliveryActionsTypes, string>(
    DELIVERY_FETCH_FAILED,
    errorKey
  )

// All user deliveries
const emitterDeliveriesSubscribe = () =>
  builder.actionCreator<DeliveryTypes.DeliveryActionsTypes, string>(
    DELIVERIES_EMITTER_SUBSCRIBE
  )

const emitterDeliveriesUnsubscribe = () =>
  builder.actionCreator<DeliveryTypes.DeliveryActionsTypes>(
    DELIVERIES_EMITTER_UNSUBSCRIBE
  )

const fetchDeliveriesSuccess = (deliveries: DeliveryTypes.DeliveryType[]) =>
  builder.actionCreator<
    DeliveryTypes.DeliveryActionsTypes,
    DeliveryTypes.DeliveryType[]
  >(DELIVERIES_FETCH_SUCCESS, deliveries)

const fetchDeliveriesFailed = (errorKey: string) =>
  builder.actionCreator<DeliveryTypes.DeliveryActionsTypes, string>(
    DELIVERIES_FETCH_FAILED,
    errorKey
  )

export const actions = Object.freeze({
  emitterDeliverySubscribe,
  emitterDeliveryUnsubscribe,
  fetchDeliverySuccess,
  fetchDeliveryFailed,
  emitterDeliveriesSubscribe,
  emitterDeliveriesUnsubscribe,
  fetchDeliveriesSuccess,
  fetchDeliveriesFailed,
})

export const mapActions = Object.freeze({
  [DELIVERY_EMITTER_SUBSCRIBE]: emitterDeliverySubscribe,
  [DELIVERY_EMITTER_UNSUBSCRIBE]: emitterDeliveryUnsubscribe,
  [DELIVERY_FETCH_SUCCESS]: fetchDeliverySuccess,
  [DELIVERY_FETCH_FAILED]: fetchDeliveryFailed,
  [DELIVERIES_EMITTER_SUBSCRIBE]: emitterDeliverySubscribe,
  [DELIVERIES_EMITTER_UNSUBSCRIBE]: emitterDeliveryUnsubscribe,
  [DELIVERIES_FETCH_SUCCESS]: fetchDeliveriesSuccess,
  [DELIVERIES_FETCH_FAILED]: fetchDeliveriesFailed,
})

// == REDUCER ==
export const initialState: Readonly<DeliveryTypes.DeliveryState> =
  Object.freeze({
    delivery: null,
    deliveries: null,
    errorKey: '',
  })

export const reducer = (
  state: Readonly<DeliveryTypes.DeliveryState> = initialState,
  action: Readonly<DeliveryTypes.DeliveryAction>
): DeliveryTypes.DeliveryState => {
  const { type, payload } = action
  switch (type) {
    case DELIVERY_EMITTER_SUBSCRIBE:
      return { ...state, delivery: null }
    case DELIVERY_FETCH_SUCCESS:
      return { ...state, delivery: payload as DeliveryTypes.DeliveryType }
    case DELIVERY_FETCH_FAILED:
      return { ...state, errorKey: payload as string }
    case DELIVERIES_EMITTER_UNSUBSCRIBE:
      return { ...state, deliveries: null }
    case DELIVERIES_FETCH_SUCCESS:
      return { ...state, deliveries: payload as DeliveryTypes.DeliveryType[] }
    case DELIVERIES_FETCH_FAILED:
      return { ...state, errorKey: payload as string }
    default:
      return state
  }
}
