import clsx from 'clsx'
import { DROP_STATUS } from 'core/helpers/drop'
import { useMemo } from 'react'
import useStyles from './Chip.style'

type DROP_STYLE = {
  [k in DROP_STATUS]: string | undefined
}

export const useChipColor: () => DROP_STYLE = () => {
  const styles = useStyles()

  return useMemo<DROP_STYLE>(() => {
    const {
      availableBorder,
      availableText,
      unavailableBorder,
      unavailableText,
    } = styles

    return {
      scheduled: undefined,
      queue: clsx(availableText, availableBorder),
      open: clsx(availableText, availableBorder),
      expired: clsx(unavailableText, unavailableBorder),
      out: clsx(unavailableText, unavailableBorder),
    }
  }, [styles])
}
