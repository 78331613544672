import { ERC1155ABI, ERC20ABI, RARUMSALESABI } from 'core/constants'
import { useCallback, useEffect, useMemo, useState } from 'react'
import * as TokenTypes from './token.types'
import { OnChainOfferType, OnChainOfferCollectionType } from './token.types'
import { useUser } from '../user'
import { normalizePrice } from 'core/helpers/contract'
import { useMarketplace } from 'context/Marketplace'
import { AssetWithBalance, Sale } from '../asset/asset.types'
import useWeb3Provider, { useWeb3Providers } from 'hooks/useWeb3Provider'
import { useStable } from 'context/Chain'

export const useBatchBalances = (
  accounts: Array<string>,
  ids: Array<string>,
  nftAddress: string | undefined
) => {
  const Contract = useWeb3Provider(true)?.eth.Contract
  const [batchBalances, setBatchBalances] =
    useState<TokenTypes.NftBatchBalances>({})

  useEffect(() => {
    if (!Contract) return
    if (accounts.length && ids.length && nftAddress) {
      const contract = new Contract(ERC1155ABI, nftAddress)
      const balances: TokenTypes.NftBatchBalances = {}

      contract.methods
        .balanceOfBatch(accounts, ids)
        .call()
        .then((result: Array<string>) =>
          result.forEach((balance: string, index: number) => {
            if (!(accounts?.[index] in balances)) {
              balances[accounts?.[index]] = {
                balances: [],
                tokens: [],
              }
            }
            balances[accounts?.[index]].balances?.push(balance)
            balances[accounts?.[index]].tokens?.push(ids[index])
          })
        )
      setBatchBalances(balances)
    }
  }, [accounts, nftAddress, Contract])

  return { batchBalances }
}

export const useOnChainOffers = (
  salesAddress?: string,
  nftAddress?: string
) => {
  const { readWeb3, writeWeb3 } = useWeb3Providers()
  const Contract = readWeb3?.eth.Contract
  const [loading, setLoading] = useState<boolean>(true)
  const [offers, setOffers] = useState<OnChainOfferType[] | null>(null)
  const [collection, setCollection] =
    useState<OnChainOfferCollectionType | null>(null)
  const [fee, setFee] = useState(0)
  const [contractAddress, setContractAddress] = useState<string>('')

  const refreshOffers = useCallback(
    function () {
      if (salesAddress && nftAddress && Contract) {
        const rarumSalesAddress = salesAddress
        setContractAddress(rarumSalesAddress)
        const salesContract = new Contract(RARUMSALESABI, rarumSalesAddress)
        const now = Date.now() / 1000
        salesContract.methods
          .offersInCollection(nftAddress)
          .call()
          .then((result: OnChainOfferType[]) => {
            return setOffers(
              result
                .filter((a) => now < a.endTime)
                .filter((a) => a.active)
                .filter((a) => !a.fromTenant)
                .map((offer) => ({
                  ...offer,
                  deliveryOptions: {
                    ...offer.deliveryOptions,
                    tokenId: Number(offer.deliveryOptions.tokenId),
                  },
                  paymentOptions: {
                    ...offer.paymentOptions,
                    price: Number(offer.paymentOptions.price),
                  },
                }))
                .sort((a, b) => a.paymentOptions.price - b.paymentOptions.price)
            )
          })
          .catch((error: any) => console.error(error))

        salesContract.methods
          .authorizedCollections(nftAddress)
          .call()
          .then((result: OnChainOfferCollectionType) => {
            setFee(Number(result.fee) / 100)
            return setCollection(result)
          })
          .catch((error: any) => console.error(error))

        setLoading(false)
      }
      if (!salesAddress) {
        setOffers([])
        setLoading(false)
      }
    },
    [salesAddress, nftAddress, Contract]
  )

  useEffect(() => {
    refreshOffers()
  }, [refreshOffers])

  return {
    loading,
    offers,
    collection,
    fee,
    buy: (owner: string, tokenId: number, amount: number) => {
      if (!writeWeb3) return
      const salesContract = new writeWeb3.eth.Contract(
        RARUMSALESABI,
        contractAddress
      )

      return salesContract.methods.buy(owner, tokenId, amount).send()
    },
    refreshOffers,
  }
}

export const useStableBalance = (wallet?: string) => {
  const stables = useStable()
  const Contract = useWeb3Provider(true)?.eth.Contract
  const [stableBalance, setStableBalance] = useState<{
    [stable: string]: number
  }>({})
  const updateBalance = useCallback(
    function updateBalance() {
      if (!wallet) return
      stables.forEach((stable) => {
        if (stable.address && Contract) {
          const stableContract = new Contract(ERC20ABI, stable.address)
          stableContract.methods
            .balanceOf(wallet)
            .call()
            .then((result: number) =>
              setStableBalance((prev) => ({
                ...prev,
                [stable.symbol]: normalizePrice(result, stable.decimals),
              }))
            )
        }
      })
    },
    [wallet, Contract, stables]
  )

  return {
    stableBalance,
    updateBalance,
  }
}

/**
 * Returns the amount of items available for sale
 */
export const useRemainingAvailableTokens = (
  asset?: AssetWithBalance | null,
  offerId?: Sale['offerId']
): { availableForSale?: number; latestOfferId?: string } => {
  const { sales } = useMarketplace()
  const { profile } = useUser()
  return useMemo(() => {
    if (!asset || !sales.items) return {}

    const myAssetSales = sales.items?.filter(
      (i) =>
        i.wallet === profile?.wallet &&
        i.offerId !== offerId &&
        i.assetId === asset.id
    )

    const tokenSales = myAssetSales?.reduce((r, i) => r + 1, 0) || 0

    const balanceForToken = asset.balance
    const ret = {
      availableForSale:
        typeof balanceForToken === 'number'
          ? balanceForToken - tokenSales
          : undefined,
      latestOfferId:
        myAssetSales && myAssetSales.length > 0
          ? myAssetSales[myAssetSales.length - 1].offerId
          : undefined,
    }

    return ret
  }, [asset, sales.items, profile?.wallet, offerId])
}
