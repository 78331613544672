import { yupResolver } from '@hookform/resolvers/yup'
import useAsyncControl from '@onepercentio/one-ui/dist/hooks/useAsyncControl'
import { useFeatures } from 'context/Feature'
import { useToast } from 'context/ToastAlert'
import { useCEPFetcher } from 'core/logic/cep/cep.hook'
import { useTenant } from 'core/logic/tenant/tenant.hook'
import { RarumUserProfile } from 'core/logic/user/user.types'
import { profilePersonalDataSchema } from 'pages/Authenticated/Payment/Payment.data'
import { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useFirebase } from 'react-redux-firebase'
import { t } from 'translate/i18n'

export const useProfileForm = (profile: RarumUserProfile) => {
  const { tenant } = useTenant()
  const { showAlert } = useToast()
  const { kyc } = useFeatures()
  const firebase = useFirebase()
  const { updateProfile, loading, error } = useAsyncControl({
    updateProfile: firebase.updateProfile,
  })
  const { updateProfile: clearProfile, ...clear } = useAsyncControl({
    updateProfile: firebase.updateProfile,
  })
  const formData = useForm<
    Omit<NonNullable<RarumUserProfile['personalData']>, 'tenants'>
  >({
    defaultValues: {
      address: '',
      addressNumber: '',
      cep: '',
      city: '',
      cpf: '',
      email: '',
      name: '',
      state: '',
      ...profile.personalData,
    },
    resolver: yupResolver(profilePersonalDataSchema),
    reValidateMode: 'onChange',
    mode: 'onSubmit',
  })
  const { setValue } = formData

  const _updatePersonalData = useCallback(
    (newProfile: RarumUserProfile['personalData']) => {
      const sanitizedCep = newProfile?.cep.replace(/[^0-9]/g, '') || ''
      const sanitizedCpf = newProfile?.cpf.replace(/[^0-9]/g, '') || ''
      return updateProfile({
        personalData: {
          ...newProfile,
          cpf: sanitizedCpf,
          cep: sanitizedCep,
        },
      })
    },
    []
  )

  const updatePersonalData = useCallback<typeof _updatePersonalData>(
    (p) => {
      return _updatePersonalData(p)
        .then(() =>
          showAlert(`${t('authenticated.userProfile.sucess')}`, 'success')
        )
        .catch(() =>
          showAlert(`${t('authenticated.userProfile.error')}`, 'error')
        )
    },
    [_updatePersonalData, showAlert]
  )

  const clearPersonalData = useCallback(() => {
    clearProfile({
      personalData: {
        cpf: '',
        address: '',
        addressNumber: '',
        cep: '',
        city: '',
        email: '',
        name: '',
        state: '',
      },
    })
      .then(() => {
        showAlert(`${t('authenticated.userProfile.sucessClear')}`, 'success')
        for (let k in formData.control._fields) formData.setValue(k as any, '')
      })
      .catch(() =>
        showAlert(`${t('authenticated.userProfile.error')}`, 'error')
      )
  }, [_updatePersonalData, showAlert])

  const cepWatcher = formData.watch('cep')
  const [lockFields, fetchingCEP, cepResult, cepFetchError, fetchCepAgain] =
    useCEPFetcher({
      cep: cepWatcher,
      enable: !!formData.formState.dirtyFields.cep,
    })

  useEffect(() => {
    if (cepResult) {
      setValue('city', cepResult.cidade!)
      setValue('state', cepResult.estado!)
      setValue('address', cepResult.logradouro! || '')
    }
  }, [cepResult, setValue])

  const zipcode = formData.watch('cep')
  const state = formData.watch('state')
  const city = formData.watch('city')
  const address = formData.watch('address')
  const addressNumber = formData.watch('addressNumber')
  const isAddressComplete = useMemo(() => {
    const hasSomeContent =
      !!zipcode || !!state || !!city || !!address || !!addressNumber
    return hasSomeContent
      ? !!zipcode && !!state && !!city && !!address && !!addressNumber
      : true
  }, [zipcode, state, city, address, addressNumber])

  return {
    form: formData,
    updatePersonalData,
    clearPersonalData,
    loading: loading,
    error,
    clear: {
      ...clear,
      error: !!clear.error,
    },
    address: {
      loading: fetchingCEP,
      lockUpdates: lockFields,
      isComplete: isAddressComplete,
      error: cepFetchError,
      fetchCepAgain,
    },
    hasKyc: kyc && !!profile.migrated,
  }
}
