import { i18n } from 'translate/i18n'
import { useTheme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { Hidden } from 'augmentation/mui'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import { DropType } from 'core/logic/drop/drop.types'
import BackButton from 'components/BackButton'
import DropImage from 'components/DropImage'
import React, { useMemo } from 'react'
import TextTransition, { presets } from 'react-text-transition'
import { MessageType } from '../OpeningDrop.data'
import useStyles, { SPINNER_SIZE } from './InProgress.style'
import Fade from '@onepercentio/one-ui/dist/components/FadeIn'

const SPRING_CONFIG = presets.wobbly

export interface InProgressProps {
  drop?: DropType | null
  onBackIntent: () => void
  messages: MessageType[]
  messageIndex: number
  mode?: 'purchase' | 'claim'
  deliveryCover: string
}

const InProgress: React.FC<InProgressProps> = ({
  drop,
  onBackIntent,
  messages,
  messageIndex,
  mode,
  deliveryCover,
}) => {
  const theme = useTheme()
  const {
    back,
    titleWrapper,
    title,
    gridContainer,
    gridItem,
    gridLeft,
    gridRight,
    figure,
    image,
    spacing,
    spinner,
    headline,
    info,
  } = useStyles()

  const paddedMessageIndex = Math.min(messageIndex, messages.length - 1)

  const labels = useMemo<{
    title: TranslationCodesByTenant[keyof TranslationCodesByTenant] | ''
  }>(() => {
    switch (mode) {
      case 'claim':
        return { title: 'authenticated.openingDrop.inProgress.claiming' }
      case 'purchase':
        return { title: 'authenticated.openingDrop.inProgress.opening' }
      default:
        return { title: '' }
    }
  }, [mode])

  return (
    <>
      <Hidden smDown>
        <BackButton
          text={i18n.t('authenticated.openingDrop.inProgress.exit')}
          className={back}
          onClick={onBackIntent}
        />
      </Hidden>
      <Box className={titleWrapper}>
        <Typography variant='h1' component='h1' className={title}>
          {
            <Fade>
              {labels.title ? <>{i18n.t(labels.title)}</> : undefined}
            </Fade>
          }
          &nbsp;
        </Typography>
        <Typography variant='h1' component='h1' className={title}>
          {drop?.title}
        </Typography>
      </Box>
      <Container maxWidth='lg' disableGutters className={gridContainer}>
        <Grid container spacing={0}>
          <Grid item xs={12} md className={clsx(gridItem, gridLeft)}>
            <DropImage
              dark
              src={deliveryCover}
              figureClass={figure}
              imageClass={image}
              alt={drop?.title}
            />
          </Grid>
          <Grid item xs={12} md className={clsx(gridItem, gridRight)}>
            <CircularProgress
              size={SPINNER_SIZE}
              thickness={5}
              color='primary'
              className={clsx(spacing, spinner)}
            />
            <Typography component='span' className={clsx(spacing, headline)}>
              <strong>
                <TextTransition
                  text={messages[paddedMessageIndex].title}
                  springConfig={SPRING_CONFIG}
                  noOverflow
                />
              </strong>
            </Typography>
            <Typography component='span' className={clsx(spacing, info)}>
              <TextTransition
                text={messages[paddedMessageIndex].message}
                springConfig={SPRING_CONFIG}
                noOverflow
              />
            </Typography>
          </Grid>
        </Grid>
        <Box flexGrow={1} />
        <Hidden mdUp>
          <BackButton
            text={i18n.t('authenticated.openingDrop.inProgress.exit')}
            iconColor={theme.palette.common.white}
            className={back}
            onClick={onBackIntent}
          />
        </Hidden>
      </Container>
    </>
  )
}

export default InProgress
