import { actions as termsActions } from 'core/logic/terms/terms.reducer'
import { call, put } from 'redux-saga/effects'
import * as termsService from './terms.service'
import { TermsType } from './terms.types'

export function* startTermsFetch() {
  try {
    const result: TermsType = yield call(termsService.fetchTerms)
    yield put(termsActions.fetchTermsSuccess(result))
  } catch (e) {
    yield put(termsActions.fetchTermsFailed('terms.fetch'))
  }
}
