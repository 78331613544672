import { i18n } from 'translate/i18n'
export interface MessageType {
  number: number
  title: string
  message: string
}

export const messages: MessageType[] = [
  {
    number: 1,
    title: i18n.t('authenticated.openingDrop.title1'),
    message: i18n.t('authenticated.openingDrop.message1'),
  },
  {
    number: 2,
    title: i18n.t('authenticated.openingDrop.title2'),
    message: i18n.t('authenticated.openingDrop.message2'),
  },
  {
    number: 3,
    title: i18n.t('authenticated.openingDrop.title3'),
    message: i18n.t('authenticated.openingDrop.message3'),
  },
]
