import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down(476)]: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    containerGeral: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 273,
      height: '100%',
      maxHeight: 500,
      width: '100%',
      minWidth: 268,
      maxWidth: 720,
    },
    videoPlayer: {
      position: 'relative',
      width: '100%',
      height: '100%',
      borderRadius: '2%',
      overflow: 'hidden',
      '&:hover .playing:not(.hide)': {
        display: 'flex!important',
      },
    },
    video: {
      position: 'relative',
      width: '100%',
      height: '100%',
      maxHeight: 300,
      outline: 'none',
    },
    controls: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      height: 50,
      width: '100%',
      backgroundColor: 'rgb(0 0 0 / 71%)',
      boxShadow: '0 0 40px 10px rgb(0 0 0 / 25%)',
      zIndex: 3,
      transform: 'translateY(0)',
      '& .icon': {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        color: '#FFF',
        marginLeft: 8,
        marginRight: 5,
        [theme.breakpoints.down(476)]: {
          marginLeft: 5,
          marginRight: 5,
          fontSize: 24,
        },
      },
      '& .material-icons': {
        fontSize: 26,
        color: '#FFF',
        cursor: 'pointer',
        '&.volume': {
          [theme.breakpoints.down(476)]: {
            display: 'none',
          },
        },
      },
      '& .icon:hover .volume-range': {
        display: 'inline-block',
        width: 60,
        '&::-webkit-slider-thumb': {
          opacity: 1,
          'pointer-events': 'auto',
          transition: '0.5s',
        },
      },
      '&.hide': {
        display: 'none',
      },
    },
    progressArea: {
      width: '100%',
      height: 5,
      margin: 'auto',
      backgroundColor: '#f0f0f0',
      cursor: 'pointer',
      '&.hide': {
        display: 'none',
      },
    },
    progressBar: {
      position: 'relative',
      width: '0%',
      backgroundColor: 'rgb(255, 0, 0)',
      height: 'inherit',
      borderRadius: 'inherit',
      cursor: 'pointer',
      transition: '1s',
      transitionTimingFunction: 'linear',
      '&::before': {
        content: "''",
        position: 'absolute',
        width: 14,
        height: 14,
        borderRadius: '50%',
        right: -5,
        backgroundColor: 'rgb(255, 0, 0)',
        top: '50%',
        transform: 'translateY(-50%)',
      },
      '.hide': {
        display: 'none',
      },
    },
    controlsList: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '97%',
      height: 46,
      margin: '0 auto',
      alignItems: 'center',
      flexWrap: 'nowrap',
      '&.hide': {
        display: 'none',
      },
    },
    controlsLeft: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& .material-icons': {
        'user-select': 'none',
        '-webkit-user-select': 'none',
        cursor: 'pointer',
      },
      '&.hide': {
        display: 'none',
      },
    },
    timer: {
      display: 'inline-block',
      fontSize: 14,
      whiteSpace: 'nowrap',
      color: '#FFFFFF',
      marginLeft: 5,
      textAlign: 'center',
      '&.hide': {
        display: 'none',
      },
    },
    volumeRange: {
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      appearance: 'none',
      width: 0,
      height: 3,
      background: '#FFFFFF',
      color: '#FFFFFF',
      cursor: 'pointer',
      outline: 'none',
      border: 'none',
      transition: '0.4s',
      opacity: 1,
      [theme.breakpoints.down(476)]: {
        display: 'none',
      },
      '&::-webkit-slider-thumb': {
        '-webkit-appearance': 'none',
        appearance: 'none',
        width: 12,
        height: 12,
        borderRadius: '50%',
        border: 'none',
        outline: 'none',
        background: '#FFFFFF',
        color: '#FFFFFF',
        opacity: 0,
        transition: '0.3s',
      },
      '&::-moz-range-thumb': {
        '-moz-appearance': 'none',
        appearance: 'none',
        width: 12,
        height: 12,
        borderRadius: '50%',
        border: 'none',
        outline: 'none',
        background: '#FFFFFF',
        color: '#FFFFFF',
        opacity: 0,
        transition: '0.3s',
      },
      '&.hide': {
        display: 'none',
      },
    },
    playPause: {
      position: 'absolute',
      top: 'calc(50% - 24px)',
      left: 'calc(50% - 24px)',
      width: 48,
      height: 48,
      backgroundColor: 'rgb(0 0 0 / 71%)',
      boxShadow: '0 0 40px 10px rgb(0 0 0 / 25%)',
      zIndex: 3,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      '&.playing': {
        display: 'none',
      },
      '& .material-icons': {
        'user-select': 'none',
        '-webkit-user-select': 'none',
        cursor: 'pointer',
        fontSize: 26,
        color: '#FFF',
      },
      '&.hide': {
        display: 'none',
      },
    },
    overlayPlayer: {
      position: 'absolute',
      top: 0,
      left: 0,
      maxHeight: 'calc(100% - 52px)',
      height: '100%',
      width: '100%',
      cursor: 'pointer',
      '&.hide': {
        display: 'none',
      },
    },
  })

export default makeStylesWrapper(style)
