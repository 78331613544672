import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) => {
  return createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    qty: {
      width: '14ch',
    },
    recipient: {
      maxWidth: 'min(50ch, 90%) !important',
      width: 'min(50ch, 90%)',
    },
    formGutter: {
      marginLeft: 0,
      marginRight: 0,
    },
    extraSmallSpacing: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    smallSpacing: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    largeSpacing: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
    },
    contentText: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    actionsWrapper: {
      minHeight: 80,
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
    loadingWrapper: {
      minHeight: 80,
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      alignItems: 'center',
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        textAlign: 'left',
        flexDirection: 'row',
        alignItems: 'space-between',
      },
    },
    spinner: {
      marginBottom: theme.spacing(4),
      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(6),
        marginBottom: 0,
      },
    },
    actionButton: {
      marginBottom: theme.spacing(5),
      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(5),
        marginBottom: 0,
      },
    },
    cancelButton: {
      alignSelf: 'flex-start',
      [theme.breakpoints.up('md')]: {
        alignSelf: 'center',
      },
    },
    emailIcon: {
      stroke: theme.palette.text.secondary,
    },
    alert: {
      '&>.MuiAlert-icon': {
        alignSelf: 'center',
      },
    },
    extraInfo: {
      '&>li': {
        marginBottom: theme.spacing(4),
      },
    },
  })
}

export default makeStylesWrapper(style)
