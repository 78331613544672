import { actions as assetActions, types as assetActionTypes } from 'core/logic/asset/asset.reducer'
import { call, put, takeLatest } from 'redux-saga/effects'
import * as assetService from './asset.service'
import { AssetType } from './asset.types'

// ASSETS LIST ========

function* startAssetsFetch() {
  try {
    const result: AssetType[] = yield call(assetService.fetchAssets)
    yield put(assetActions.fetchAssetsSuccess(result))
  } catch (e) {
    yield put(assetActions.fetchAssetsFailed('assets.fetch'))
  }
}

export function* watchAssetsFetch() {
  yield takeLatest(assetActionTypes.ASSETS_FETCH, startAssetsFetch)
}

function* startAssetsByTokenFetch({ payload }: ReturnType<typeof assetActions.fetchAssetsByTokens>) {
  try {
    if (payload) {
      if (payload.length === 0) yield put(assetActions.fetchAssetsByTokensSuccess([]))
      else {
        const result: AssetType[] = yield call(assetService.fetchAssetsByTokens, { tokenIds: payload })
        yield put(assetActions.fetchAssetsByTokensSuccess(result))
      }
    }
  } catch (e) {
    yield put(assetActions.fetchAssetsByTokensFailed('assets.fetch'))
  }
}

export function* watchAssetsByTokenFetch() {
  yield takeLatest(assetActionTypes.ASSETS_BY_TOKEN_FETCH, startAssetsByTokenFetch)
}

// ASSETS ITEM ========

function* startAssetFetch({ payload }: ReturnType<typeof assetActions.fetchAsset>) {
  try {
    if (payload) {
      const result: AssetType = yield call(assetService.fetchAsset, { assetId: payload })
      yield put(assetActions.fetchAssetSuccess(result))
    }
  } catch (e) {
    yield put(assetActions.fetchAssetFailed('asset.fetch'))
  }
}

export function* watchAssetFetch() {
  yield takeLatest(assetActionTypes.ASSET_FETCH, startAssetFetch)
}
