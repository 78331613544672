import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { ReactComponent as IconCreditCard } from 'assets/icons/credit_card_fill.svg'
import { ReactComponent as FlagBR } from 'assets/icons/flagBR.svg'
import { i18n } from 'translate/i18n'
import useStyles from '../../Payment.style'
import { MethodProps } from './props'
import { useMemo } from 'react'
import { CurrenciesEnum } from 'core/types/CurrenciesEnum'

/**
 * A component that shows the card payment method
 */
export const CreditCard: React.FC<MethodProps<'CREDIT_CARD'>> = ({
  price,
  checked,
  flag,
  currency,
}) => {
  const classes = useStyles()

  const lbl = useMemo(() => {
    switch (currency) {
      case CurrenciesEnum.USD:
        return i18n.t('authenticated.payment.steps.stepMethod.creditCardUSD')
      case CurrenciesEnum.BRL:
        return i18n.t('authenticated.payment.steps.stepMethod.creditCardBRL')
      default:
        return i18n.t('authenticated.payment.steps.stepMethod.creditCardAny', {
          currency,
        })
    }
  }, [currency])

  return (
    <Box
      className={clsx(
        classes.paymentMethodLabel,
        checked ? classes.paymentMethodLabelChecked : undefined
      )}>
      <Box className={classes.paymentMethodIcon}>
        <IconCreditCard />
        {flag && <FlagBR className='br' data-testid='br-flag' />}
      </Box>
      <Box className={classes.paymentMethodText}>
        <Typography variant='body2'>{lbl}</Typography>
        <Typography variant='h4' className={classes.paymentMethodTextPrice}>
          {price}
        </Typography>
      </Box>
    </Box>
  )
}
