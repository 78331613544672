import { FIREBASE_CONFIG, RECAPTCHA_SITE_KEY, __DEV__ } from 'core/constants'
import _firebase from 'firebase/compat/app'
import 'firebase/compat/analytics'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/performance'
import 'firebase/compat/storage'
import 'firebase/compat/app-check'
import { ReCaptchaV3Provider } from 'firebase/app-check'
export * as service from './service'

const shouldUseEmulator =
  process.env.REACT_APP_USE_FIREBASE_EMULATORS || process.env.AUTOMATION

export const COLLECTIONS = Object.freeze({
  USERS: 'users',
})

// react-redux-firebase config
export const rrfConfig = Object.freeze({
  enableLogging: __DEV__,
  userProfile: COLLECTIONS.USERS,
  useFirestoreForProfile: true, // Firestore for   Profile instead of Realtime DB
})

// Initialize firebase instance
export const fireapp = _firebase.initializeApp(FIREBASE_CONFIG)

if (RECAPTCHA_SITE_KEY && !shouldUseEmulator)
  _firebase
    .appCheck()
    .activate(new ReCaptchaV3Provider(RECAPTCHA_SITE_KEY), true)

// Initialize other services on firebase instance
export let firestore = _firebase.firestore()
export let storage = _firebase.storage()
export let auth = _firebase.auth()

/**
 * REACT_APP_TENANT_IDENTITY_ID is the tenant id created at
 * https://console.cloud.google.com/customer-identity/tenants
 */
auth.tenantId = !process.env.AUTOMATION
  ? process.env.REACT_APP_TENANT_IDENTITY_ID!
  : (null as unknown as string)

export const functions = _firebase.functions()
export let firebase = _firebase

const enableExternalTools = !process.env.AUTOMATION && !shouldUseEmulator

export const analytics: any = enableExternalTools
  ? _firebase.analytics()
  : (new Proxy(
      {},
      {
        get(t, k) {
          return () => {}
        },
      }
    ) as ReturnType<typeof _firebase.analytics>)
export const perf = enableExternalTools ? _firebase.performance() : null

auth.useDeviceLanguage() // default -> should change on new locale

firestore.settings({
  experimentalForceLongPolling: true,
})

if (shouldUseEmulator) {
  window._firebase = {
    auth: auth,
  }
  functions.useEmulator(`${window.location.hostname}`, 5001)
  firestore.settings({
    host: `${window.location.hostname}:8090`,
    ssl: false,
    experimentalForceLongPolling: true,
  })
  storage.useEmulator('localhost', 9199)
  auth.useEmulator(`http://${window.location.hostname}:9099`)
}
export const setFirebaseInterface = (
  _firestore: any,
  _storage: any,
  _auth: any,
  _firebase: any
) => {
  firestore = _firestore
  storage = _storage
  auth = _auth
  firebase = _firebase
}
