
import CircularProgress from '@material-ui/core/CircularProgress'
import useTheme from '@material-ui/core/styles/useTheme'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import React, { useState } from 'react'
import Iframe from 'react-iframe'

type CoinbaseViewProps = {
  src: string
  onLoad?: () => void | Promise<void>
}
export const CoinbaseView: React.FC<CoinbaseViewProps> = ({
  src,
}) => {
  const theme = useTheme()
  const showSmall = useMediaQuery(theme.breakpoints.up('sm'))
  const [isLoading, setIsLoading] = useState(true)

  return (
    <>
      {isLoading && <CircularProgress size={20} />}
      <Iframe
        url={src}
        width={showSmall ? '600px' : '100%'}
        height={showSmall ? '700px' : '900px'}
        scrolling='no'
        frameBorder={0}
        overflow='scroll'
        position='relative'
        allowFullScreen
        onLoad={() => setIsLoading(false)}
      />
    </>
  )
}
