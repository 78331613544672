import { RootState } from 'core/modules/redux'
import { useSelector } from 'react-redux'
import { TermsState } from './terms.types'
import purify from 'dompurify'
import { useMemo } from 'react'
import { i18n } from 'translate/i18n'

export const useTerms = () => {
  const { terms } = useSelector<RootState, TermsState>(({ terms }) => terms)

  const content = useMemo(() => {
    if (terms) {
      return purify.sanitize(terms.content[i18n.language])
    } else return undefined
  }, [terms])

  return { terms: content }
}
