import { Box, Typography } from '@material-ui/core'
import { t } from 'translate/i18n'
import parse from 'html-react-parser'

/**
 * Displays the asset features
 **/
export default function FeaturesDisplay({ features }: { features?: string }) {
  return (
    <>
      {features && (
        <Box display='flex' flexDirection='column' alignItems='flex-start'>
          <Typography variant='body1' component='h2' color='textPrimary'>
            <strong>{t('unauthenticated.galleryDetail.description')}</strong>
          </Typography>
          <br />
          <Typography variant='body1' component='p' color='textPrimary'>
            {parse(features)}
          </Typography>
        </Box>
      )}
    </>
  )
}
