export interface CepResponse {
  cep: string
  state: string
  city: string
  neighborhood: string
  street: string
  service: string
}

const OPTIONS = { headers: { 'Content-Type': 'application/json' } }

/**
 * Uses API from https://brasilapi.com.br/docs#tag/CEP-V2
 * @param cep should receive an 8 digit string representing the CEP
 * @returns CEP information
 * @throws Throws an error when no address is associated with the CEP
 */
export const getAddressData = async (cep: string) => {
  try {
    const response = await fetch(`https://brasilapi.com.br/api/cep/v1/${cep}`, OPTIONS)
    if (!response.ok) throw new Error('Invalid CEP')
    return response.json() as Promise<CepResponse>
  } catch (error) {
    throw error
  }
}
