import React from 'react'
import Styles from './FeatureHead.module.scss'
import { useFeatures } from 'openspace/pages/Authenticated/Home/v2/Home/Features/Features.hook'
import { FigmaTypo } from 'containers/OneUIProvider/OneUIProvider.data'
import OneText from '@onepercentio/one-ui/dist/components/Text'
import useHero, {
  TRANSITION_FACTORY,
} from '@onepercentio/one-ui/dist/hooks/useHero'

const conceptImagesCtx = require.context('openspace/assets/png/home/concepts')
const i = (moduleName: string) =>
  conceptImagesCtx<
    typeof import('openspace/assets/png/home/concepts/marketplace.png')
  >(`./${moduleName}.png`).Scaled['0.5x']

export enum FeatureHeadMode {
  DETAILS,
  FEATURE_CARD,
  SMALL,
}

/**
 * Shows the name and short description of a feature
 **/
export default function FeatureHead({
  feature,
  mode = FeatureHeadMode.FEATURE_CARD,
}: {
  feature: ReturnType<typeof useFeatures>[number]
  mode?: FeatureHeadMode
}) {
  const { heroRef, heroComponentRef } = useHero(
    `feature_head_${feature.concept}`,
    {
      propsToTransition: ['boxShadow', 'borderRadius'],
    },
    TRANSITION_FACTORY.ACCELERATION({
      onHeroDetect(origin) {
        if (mode === FeatureHeadMode.SMALL) return false
        return true
      },
      onHeroStart(clone, origin, target) {
        //   const p = clone.lastElementChild! as HTMLDivElement
        //   p.style.maxHeight = '100px'
        target.classList.add(Styles.transitioning)
        origin.classList.add(Styles.transitioning)
      },
      onHeroEnd() {
        heroRef.current?.classList.remove(Styles.transitioning)
      },
    })
  )
  return (
    <header
      ref={heroRef}
      className={`${Styles.head} ${Styles[FeatureHeadMode[mode]]} dark`}
      style={{
        backgroundImage: `url(${i(feature.concept)})`,
      }}>
      <feature.icon />
      <OneText type={FigmaTypo.H2} className={Styles.title}>
        {feature.title}
      </OneText>
      {mode !== FeatureHeadMode.SMALL && (
        <OneText
          ref={heroComponentRef('description')}
          type='p20_v2'
          className={Styles.description}>
          {feature.description}
        </OneText>
      )}
    </header>
  )
}
