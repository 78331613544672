import { useTheme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { memo } from 'react'
import { i18n } from 'translate/i18n'
import IconRarum from '../IconRarum'
import useStyles from './DropUnavailable.style'

interface DropUnavailableViewProps {
  text?: string
}
const DropUnavailableView: React.FC<DropUnavailableViewProps> = ({
  text = `${i18n.t('components.dropUnavailable.notAvailable')}`,
}) => {
  const theme = useTheme()
  const classes = useStyles()

  return (
    <Box className={classes.box}>
      <IconRarum
        icon='alert'
        size={24}
        color={theme.palette.grey[300]}
        className={classes.icon}
      />
      <Typography
        variant='body2'
        component='p'
        style={{ color: theme.palette.grey[300] }}>
        {text}
      </Typography>
    </Box>
  )
}

export default memo(DropUnavailableView)
