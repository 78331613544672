import { i18n } from 'translate/i18n'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { DropType } from 'core/logic/drop/drop.types'
import DropDetails from 'pages/Authenticated/Checkout/DropDetails/'
import useStyles from './DropInfoMobile.style'

export interface DropInfoMobileViewProps {
  drop?: DropType | null
  price?: number
}

const DropInfoMobileView: React.FC<DropInfoMobileViewProps> = ({
  drop,
  price,
}) => {
  const classes = useStyles()

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'>
        <Typography variant='body2' component='span'>
          <b>
            {i18n.t('authenticated.payment.dropInfoMobile.purchaseDetails')}
          </b>
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetail}>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='flex-start'
          mt={2}
          mb={2}>
          <DropDetails vertical drop={drop} price={price} />
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

export default DropInfoMobileView
