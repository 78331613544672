import { builder } from 'core/helpers'
import * as AuthTypes from './auth.types'

// == TYPES ==
const AUTHENTICATED = 'auth/AUTHENTICATED'
const LOGIN_ATTEMPT_FACEBOOK = 'auth/login_facebook/ATTEMPT'
const LOGIN_ATTEMPT_GMAIL = 'auth/login_gmail/ATTEMPT'
const LOGIN_ATTEMPT_WEP = 'auth/login_wep/ATTEMPT'
const LOGIN_FAILED_WEP = 'auth/login_wep/FAILED'
const LOGIN_SUCCESS = 'auth/login/SUCCESS'
const UNAUTHENTICATED = 'auth/UNAUTHENTICATED'
const REGISTER_ATTEMPT_WEP = 'auth/register_wep/ATTEMPT'
const REGISTER_FAILED_WEP = 'auth/register_wep/FAILED'
const RESET_ERROR = 'auth/error/RESET'
export const types = Object.freeze({
  AUTHENTICATED,
  LOGIN_ATTEMPT_FACEBOOK,
  LOGIN_ATTEMPT_GMAIL,
  LOGIN_ATTEMPT_WEP,
  LOGIN_FAILED_WEP,
  LOGIN_SUCCESS,
  REGISTER_ATTEMPT_WEP,
  REGISTER_FAILED_WEP,
  RESET_ERROR,
  UNAUTHENTICATED,
})

// == ACTIONS ==
const authenticated = () => builder.actionCreator<AuthTypes.AuthActionsTypes>(AUTHENTICATED)
const loginAttemptFacebook = () => builder.actionCreator<AuthTypes.AuthActionsTypes>(LOGIN_ATTEMPT_FACEBOOK)
const loginAttemptGmail = () => builder.actionCreator<AuthTypes.AuthActionsTypes>(LOGIN_ATTEMPT_GMAIL)
const loginAttemptWEP = ({ email, password }: AuthTypes.Credentials) =>
  builder.actionCreator<AuthTypes.AuthActionsTypes, AuthTypes.Credentials>(LOGIN_ATTEMPT_WEP, {
    email,
    password,
  })
const loginFailedWEP = (errorKey: string) => builder.actionCreator<AuthTypes.AuthActionsTypes, string>(LOGIN_FAILED_WEP, errorKey)
const loginSuccess = () => builder.actionCreator<AuthTypes.AuthActionsTypes>(LOGIN_SUCCESS)
const registerAttemptWEP = ({ email, password }: AuthTypes.Credentials) =>
  builder.actionCreator<AuthTypes.AuthActionsTypes, AuthTypes.Credentials>(REGISTER_ATTEMPT_WEP, {
    email,
    password,
  })
const registerFailedWEP = (errorKey: string) => builder.actionCreator<AuthTypes.AuthActionsTypes, string>(REGISTER_FAILED_WEP, errorKey)
const resetError = () => builder.actionCreator<AuthTypes.AuthActionsTypes>(RESET_ERROR)
const unauthenticated = () => builder.actionCreator<AuthTypes.AuthActionsTypes>(UNAUTHENTICATED)

export const actions = Object.freeze({
  authenticated,
  loginAttemptFacebook,
  loginAttemptGmail,
  loginAttemptWEP,
  loginFailedWEP,
  loginSuccess,
  unauthenticated,
  registerAttemptWEP,
  registerFailedWEP,
  resetError,
})

export const mapActions = Object.freeze({
  [AUTHENTICATED]: authenticated,
  [LOGIN_ATTEMPT_FACEBOOK]: loginAttemptFacebook,
  [LOGIN_ATTEMPT_GMAIL]: loginAttemptGmail,
  [LOGIN_ATTEMPT_WEP]: loginAttemptWEP,
  [LOGIN_FAILED_WEP]: loginFailedWEP,
  [LOGIN_SUCCESS]: loginSuccess,
  [UNAUTHENTICATED]: unauthenticated,
  [REGISTER_ATTEMPT_WEP]: registerAttemptWEP,
  [REGISTER_FAILED_WEP]: registerFailedWEP,
  [RESET_ERROR]: resetError,
})

// == REDUCER ==
export const initialState: Readonly<AuthTypes.AuthState> = Object.freeze({
  // authentication happens in 3 steps
  // null = not initialized (initializing)
  // true = authenticathed (firebase properly set)
  // false = not authenticathed (logout/expired/storage removed/etc)
  authenticated: null,
  errorKey: '',
})
export const reducer = (
  state: Readonly<AuthTypes.AuthState> = initialState,
  action: Readonly<AuthTypes.AuthAction>
): AuthTypes.AuthState => {
  const { type, payload } = action
  switch (type) {
    case AUTHENTICATED:
      return { ...state, authenticated: true, errorKey: '' }

    case UNAUTHENTICATED:
      return { ...state, authenticated: false, errorKey: '' }

    case LOGIN_ATTEMPT_GMAIL:
    case LOGIN_ATTEMPT_FACEBOOK:
    case LOGIN_ATTEMPT_WEP:
    case REGISTER_ATTEMPT_WEP:
      return { ...state, errorKey: '' }

    case LOGIN_FAILED_WEP:
    case REGISTER_FAILED_WEP:
      return { ...state, errorKey: payload as string }

    case RESET_ERROR:
      return { ...state, errorKey: '' }

    default:
      return state
  }
}
