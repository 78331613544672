import React from 'react'
import { Box, Typography } from "@material-ui/core"
import TimeIcon from "@material-ui/icons/AccessTime"
import { describeTime } from "./functions"
import { OfferCountdownViewProps } from "./types"
import useStyle from './styles'
import { i18n } from 'translate/i18n'

export const OfferCountdownView: React.FC<OfferCountdownViewProps> = React.memo(({countdown, progress = 0, decorate}) => {
    const classes = useStyle()
    const [
        days,
        hours,
        minutes,
        seconds
    ] = describeTime(countdown)
    const progressFill = progress*100
    
    return (
        <Box>
            {
              decorate &&
              <Box className={classes.progress}>
                  <Box  className={classes.bar} style={{width: `${progressFill}%`}}></Box>
              </Box>
            }
            <Typography variant='caption' component='p' className={decorate ? classes.countdown : classes.nakedCountdown}>
            { progress > 0 &&
              <>
                  <TimeIcon fontSize='small' />&nbsp;{ i18n.t('components.offerCountdown.remaningTime', {days, hours, minutes, seconds}) }
              </>
            }
            </Typography>
        </Box>
    )
})
