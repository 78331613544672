import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { ReactComponent as IconPix } from 'assets/icons/icon_pix.svg'
import clsx from 'clsx'
import { i18n } from 'translate/i18n'
import useStyles from '../../Payment.style'
import { MethodProps } from './props'

export const PixBRL: React.FC<MethodProps<"BOLETO_PIX">> = ({price, checked}) => {
  const classes = useStyles()
  return (
    <Box className={clsx(classes.paymentMethodLabel, checked ? classes.paymentMethodLabelChecked : undefined)}>
      <Box className={classes.paymentMethodIcon}>
        <IconPix />
      </Box>
      <Box className={classes.paymentMethodText}>
        <Typography variant='body2'>
          {i18n.t('authenticated.payment.steps.stepMethod.pixBRL')}
        </Typography>
        <Typography variant='h4' className={classes.paymentMethodTextPrice}>{price}</Typography>
      </Box>
    </Box>
  )
}
