import { useTheme } from '@material-ui/core'
import Box, { BoxProps } from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import MorphAvatar from 'components/MorphAvatar'
import React, { MouseEventHandler } from 'react'
import useStyles from './ProfileAvatar.style'
export interface ProfileAvatarViewProps {
  photoURL?: string
  size?: number
  placeholder?: string
  onClick?: MouseEventHandler
  userName?: string
  hideUsername?: boolean
}

const ProfileAvatarView: React.FC<ProfileAvatarViewProps & BoxProps> = ({
  photoURL,
  size = 48,
  placeholder,
  onClick,
  userName,
  hideUsername,
  ...boxProps
}) => {
  const {
    palette: { type },
  } = useTheme()
  const isDark = type === 'dark'
  const { text, textDark } = useStyles()

  return (
    <Box display='flex' alignItems='center' {...boxProps}>
      {userName && !hideUsername ? (
        <>
          <Typography
            variant='body1'
            component='p'
            className={isDark ? text : textDark}>
            {userName}
          </Typography>
        </>
      ) : null}
      <MorphAvatar
        isStatic={!onClick}
        src={photoURL}
        placeholder={placeholder}
        onClick={onClick}
        avatarSize={size}
        dark={isDark}
      />
    </Box>
  )
}

export default ProfileAvatarView
