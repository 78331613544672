import { lazy } from 'react'
export const ClaimFlow = lazy(
  () =>
    import(
      /* webpackChunkName: "Claim" */ './ClaimViews'
    ).then(({ ClaimFlow }) => ({ default: ClaimFlow }))
)

export const ClaimSignUpFlow = lazy(
  () =>
    import(
      /* webpackChunkName: "Claim" */ './ClaimViews'
    ).then(({ SignUpFlow }) => ({ default: SignUpFlow }))
)
