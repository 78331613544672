import Typography from '@material-ui/core/Typography'
import { RARUM_DESCRIPTION } from 'core/constants'
import { analyticsEvent, SCREENS } from 'core/modules/analytics/events'
import { useState } from 'react'
import useStyles from './RarumHeader.style'
import RarumHeaderView from './RarumHeader.view'

interface RarumHeaderLogicProps {
  disableRarumText?: boolean
  clientLogoSrc?: string
  showRarumLogo?: boolean
}

const RarumHeaderLogic: React.FC<RarumHeaderLogicProps> = ({
  disableRarumText,
  ...rest
}) => {
  const classes = useStyles()

  // Rarum info modal state handling
  const [rarumModalOpen, setRarumModalOpen] = useState(false)
  const handleRarumModalOpen = () => {
    analyticsEvent.viewScreen({ name: SCREENS.ABOUT_RARUM })
    setRarumModalOpen(true)
  }
  const handleRarumModalClose = () => setRarumModalOpen(false)

  // Rarum info modal content
  const rarumModalContent = RARUM_DESCRIPTION.map((line, idx) => (
    <Typography
      key={`rarum-p-${idx}`}
      variant='body1'
      className={classes.bottomSpacing}>
      {line}
    </Typography>
  ))

  return (
    <RarumHeaderView
      modalContent={rarumModalContent}
      modalOpen={rarumModalOpen}
      handleModalOpen={handleRarumModalOpen}
      handleModalClose={handleRarumModalClose}
      disableRarumText={disableRarumText}
      {...rest}
    />
  )
}

export default RarumHeaderLogic
