import { createStyles, Theme } from '@material-ui/core'
import { darken, lighten } from '@material-ui/core/styles/colorManipulator'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) => {
  const fn = theme.palette.type === 'light' ? darken : lighten
  const factor = theme.palette.type === 'light' ? 0.05 : 0.1

  return createStyles<
    any,
    {
      mode: 'default' | 'marketplace'
    }
  >({
    float: {
      [theme.breakpoints.down(640)]: {
        backgroundColor: theme.palette.background.default,
        padding: 24,
      },
      width: '100%',
      maxWidth: '480px',
    },
    fab: {
      left: 'initial',
      top: 'initial',
      bottom: 24,
      right: 24,
      position: 'fixed',
      whiteSpace: 'nowrap',
    },
    video: {
      width: '100%',
      maxWidth: '400px',
    },
    main: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: theme.spacing(6),
    },
    upperContainer: {
      background: `linear-gradient(0deg, rgba(255, 255, 255, 0), ${fn(
        theme.palette.background.default,
        factor
      )})`,
      paddingBottom: theme.spacing(5),
    },
    itemWrapper: ({ mode }) => ({
      width: '100%',
      '& canvas': {
        minHeight: '30vh',
        maxHeight: '60vh',
      },
      '& > img':
        mode === 'marketplace'
          ? {
              maxWidth: '320px',
              marginRight: '32px',
            }
          : {
              maxWidth: '500px',
            },
    }),
    dropImageWrapper: {
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-start',
      },
    },
    media: {
      maxWidth: '100%',
      maxHeight: '100%',
    },
    divider: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
      },
    },
    title: {
      textTransform: 'uppercase',
      fontWeight: 800,
    },
    download: {
      color: theme.palette.primary.main,
    },
    avatar: {
      '& > p': {
        fontWeight: 400,
      },
    },
    topSpacing: {
      marginTop: theme.spacing(4),
    },
    bottomSpacing: {
      marginBottom: theme.spacing(4),
    },
    figure: {
      width: '100%',
      margin: 0,
      [theme.breakpoints.down('sm')]: {
        margin: `${theme.spacing(2)}px auto  ${theme.spacing(2.5)}px`,
      },
    },
    image: {
      maxWidth: '100%',
      [theme.breakpoints.down('sm')]: {
        maxHeight: '100%',
        maxWidth: '100%',
        display: 'flex',
      },
      borderRadius: 10,
    },
    pack: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '1rem',
      fontWeight: 600,
      color: theme.palette.text.primary,
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
    },
    thumbnail: {
      margin: `0 ${theme.spacing(1)}px 0 0`,
      width: 80,
      height: 80,
      marginRight: theme.spacing(2),
    },
  })
}

export default makeStylesWrapper(style)
