import { Channel, Task } from '@redux-saga/types'
import {
  actions as deliveryActions,
  types as deliveryActionTypes,
} from 'core/logic/delivery/delivery.reducer'
import { eventChannel } from 'redux-saga'
import { call, cancel, fork, put, take, takeLatest } from 'redux-saga/effects'
import * as deliveryService from './delivery.service'
import { DeliveryType } from './delivery.types'

// DELIVERIES LIST ========

export async function deliveriesUpdatesEmitter() {
  return eventChannel<DeliveryType[]>((emitter) => {
    const unsubscribe = deliveryService.fetchDeliveries({ emitter })
    return () => {
      unsubscribe()
    }
  })
}

export function* deliveriesSyncGenerator() {
  const emitterInterface: Channel<any> = yield call(deliveriesUpdatesEmitter)
  try {
    while (true) {
      const deliveriesChanged: DeliveryType[] = yield take(emitterInterface)
      yield put(deliveryActions.fetchDeliveriesSuccess(deliveriesChanged))
    }
  } catch (_e) {
    yield put(deliveryActions.fetchDeliveriesFailed('deliveries.subscribe'))
  } finally {
    emitterInterface.close()
  }
}

function* startDeliveriesSyncChannel({
  payload,
}: ReturnType<typeof deliveryActions.emitterDeliveriesSubscribe>) {
  const deliveriesSyncTask: Task = yield fork(deliveriesSyncGenerator)
  yield take(deliveryActionTypes.DELIVERIES_EMITTER_UNSUBSCRIBE)
  yield cancel(deliveriesSyncTask)
}

export function* watchDeliveriesSubscribe() {
  yield takeLatest(
    deliveryActionTypes.DELIVERIES_EMITTER_SUBSCRIBE,
    startDeliveriesSyncChannel
  )
}

// SINGLE DELIVERY ========

export async function deliveryUpdatesEmitter({
  deliveryId,
}: {
  deliveryId: string
}) {
  return eventChannel<DeliveryType>((emitter) => {
    const unsubscribe = deliveryService.fetchDelivery({ deliveryId, emitter })
    return () => {
      unsubscribe()
    }
  })
}

export function* deliverySyncGenerator({ deliveryId }: { deliveryId: string }) {
  const emitterInterface: Channel<any> = yield call(deliveryUpdatesEmitter, {
    deliveryId,
  })
  try {
    while (true) {
      const deliveryChanged: DeliveryType = yield take(emitterInterface)
      yield put(deliveryActions.fetchDeliverySuccess(deliveryChanged))
    }
  } catch (_e) {
    yield put(deliveryActions.fetchDeliveryFailed('delivery.subscribe'))
  } finally {
    emitterInterface.close()
  }
}

function* startDeliverySyncChannel({
  payload,
}: ReturnType<typeof deliveryActions.emitterDeliverySubscribe>) {
  if (!payload) throw new Error('Delivery ID missing')
  const deliverySyncTask: Task = yield fork(deliverySyncGenerator, {
    deliveryId: payload,
  })
  yield take([deliveryActionTypes.DELIVERY_EMITTER_SUBSCRIBE])
  yield cancel(deliverySyncTask)
}

export function* watchDeliverySubscribe() {
  yield takeLatest(
    deliveryActionTypes.DELIVERY_EMITTER_SUBSCRIBE,
    startDeliverySyncChannel
  )
}
