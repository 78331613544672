import React, {
  ElementRef,
  Fragment,
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { ClaimWarningViewProps } from './ClaimWarning.types'
import Styles from './ClaimWarning.module.scss'
import useRebound from '@onepercentio/one-ui/dist/hooks/useRebound'
import UncontrolledTransition from '@onepercentio/one-ui/dist/components/UncontrolledTransition'
import {
  AnimatedPaginationIndicator,
  PaginationIndicatorMode,
} from '@onepercentio/one-ui/dist/components/PaginationIndicator/PaginationIndicator'
import { i18n, t } from 'translate/i18n'
import Button from '@onepercentio/one-ui/dist/components/Button'
import Text from 'openspace/components/Text'
import Spacing from '@onepercentio/one-ui/dist/components/Spacing'
import { ReactComponent as ConfirmIcon } from 'openspace/assets/svg/challenge_confirm.svg'
import AssetDisplay from 'openspace/components/AssetDisplay'
import SectionLoader from 'openspace/components/SectionLoader'
import { TransitionAnimationTypes } from '@onepercentio/one-ui/dist/components/Transition/Transition'
import IconRarum from 'components/IconRarum'

/**
 * Warns the user that the items involved in claim, will be consumed
 **/
export default function ClaimWarningView({
  itemsThatWillDisappearFromCollection,
  galleriesToAuthorize,
  onConfirm,
  onCancel,
  state,
  onApprove,
  loading,
  error,
  onRetry,
}: ClaimWarningViewProps) {
  const steps = useMemo<ReactElement[]>(() => {
    if (state === 'loading')
      return [
        <div key={'loading'} className={Styles.step}>
          <SectionLoader />
          <Spacing size='16' />
          <Text type='h4'>&nbsp;</Text>
        </div>,
      ]
    if (state === 'error')
      return [
        <div key={'loading'} className={Styles.step}>
          <Text type='h4'>{t('generic.errorMsg')}</Text>
        </div>,
      ]
    if (state === 'claim')
      return [
        ...itemsThatWillDisappearFromCollection.map((a) => (
          <div key={`asset_${a.id}`} className={Styles.step}>
            <AssetDisplay asset={a} />
            <Spacing size='16' />
            <Text type='h4'>
              {t('features.challenge.claim.confirmation.deplete', {
                itemName: a[i18n.language].name,
              })}
            </Text>
          </div>
        )),
        ...galleriesToAuthorize.map((gallery) => {
          return (
            <div key={`gallery_${gallery.id}`} className={Styles.step}>
              <UncontrolledTransition
                transitionType={TransitionAnimationTypes.COIN_FLIP}>
                {!loading ? (
                  <Fragment key='gallery'>
                    {!gallery.approved ? (
                      <img
                        src={gallery.icon}
                        alt={gallery[i18n.language].title}
                      />
                    ) : (
                      <div
                        key='approved'
                        className={gallery.approved ? Styles.approved : ''}>
                        <IconRarum icon='checkmark' color='var(--ok-color)' />
                      </div>
                    )}
                  </Fragment>
                ) : (
                  <SectionLoader key='loading' />
                )}
              </UncontrolledTransition>
              <Spacing size='16' />
              <Text type='h4'>
                {t('features.challenge.claim.confirmation.approve_gallery', {
                  galleryName: gallery[i18n.language].title,
                })}
              </Text>
            </div>
          )
        }),
        <div key='default' className={Styles.step}>
          <ConfirmIcon />
          <Spacing size='32' />
          <Text
            code='features.challenge.claim.confirmation.warning'
            type='h4'
          />
          <Spacing size='32' />
          <Text
            code='features.challenge.claim.confirmation.disclaimer'
            type='p16'
          />
        </div>,
      ]
    return []
  }, [state, loading, error, galleriesToAuthorize])
  const [currStep, setCurrStep] = useState(0)
  const { current, setState } = useRebound(false)
  const uncRef = useRef<ElementRef<typeof UncontrolledTransition>>(null)

  useEffect(() => {
    setState(true)
  }, [currStep])

  const isLastStep = currStep === steps.length - 1
  const isFirstStep = currStep === 0

  const gallery = useMemo(() => {
    const currStepKey = steps[currStep].key!
    if (currStepKey.includes('gallery_')) {
      const galleryId = currStepKey.split('gallery_')[1]
      const relatedGallery = galleriesToAuthorize.find(
        (g) => g.id === galleryId
      )
      return relatedGallery
    } else {
      return undefined
    }
  }, [currStep, galleriesToAuthorize])

  const { label, onClick } = useMemo(() => {
    if (gallery) {
      return {
        onClick: error
          ? onRetry
          : () => {
              if (gallery.approved) setCurrStep((prev) => prev + 1)
              else onApprove(gallery)
            },
        label: gallery.approved
          ? t('generic.continue')
          : error
          ? t('generic.retryBtn')
          : t('features.challenge.claim.confirmation.actions.approve'),
      }
    } else {
      return {
        onClick: () => {
          if (isLastStep) onConfirm()
          else setCurrStep((prev) => prev + 1)
        },
        label: !isLastStep
          ? t('generic.continue')
          : t('unauthenticated.claim.actions.claim'),
      }
    }
  }, [gallery, isLastStep, isFirstStep])

  return (
    <>
      <Text
        code='features.challenge.claim.confirmation.title'
        type='h3'
        className={Styles.title}
      />
      <Spacing size='32' />
      <main className={Styles.main}>
        <UncontrolledTransition ref={uncRef}>
          {steps[currStep]}
        </UncontrolledTransition>
      </main>
      {steps.length > 1 && (
        <>
          <Spacing size='16' />
          <AnimatedPaginationIndicator
            className={Styles.pagination}
            pages={steps.length}
            page={currStep + 1}
            size={12}
            mode={PaginationIndicatorMode.CENTERED}
            duration={250}
          />
        </>
      )}
      <Spacing size='32' />
      <div className={Styles.actions}>
        <Button
          variant='outline'
          onClick={() => {
            uncRef.current!.setOrientation('backward')
            if (isFirstStep) onCancel()
            else setCurrStep((prev) => prev - 1)
          }}>
          {!isFirstStep ? t('generic.goBack') : t('generic.cancel')}
        </Button>
        <Button
          color='primary'
          disabled={current || loading}
          variant='filled'
          onClick={onClick}>
          {label}
        </Button>
      </div>
    </>
  )
}
