import React, {
  ForwardedRef,
  forwardRef,
  Fragment,
  ReactElement,
  RefObject,
  useMemo,
} from 'react'
import {
  GalleryDetailsSeeAllOption,
  GalleryDetailsViewProps,
} from './GalleryDetails.types'
import Styles from './GalleryDetails.module.scss'
import GalleryCard from 'openspace/components/GalleryCard'
import Container from 'openspace/components/Container'
import DropItem from 'openspace/components/DropItem'
import { t, tO } from 'translate/i18n'
import Section from 'openspace/components/Section'
import { BaseExpandedWidth } from 'openspace/components/DropItem/DropItem.view'
import BackButton from 'components/BackButton'
import OrderableList from '@onepercentio/one-ui/dist/components/OrderableList'
import useOrder from 'openspace/hooks/useOrder'
import Text from '@onepercentio/one-ui/dist/components/Text'
import { FigmaTypo } from 'containers/OneUIProvider/OneUIProvider'
import { DropItemViewMode } from 'openspace/components/DropItem/DropItem.types'
import NoOffersAvailable from './NoOffersAvailable/NoOffersAvailable'
import { useFeatures } from 'context/Feature'
import ChallengeSection from 'features/Challenge/ChallengeSection'

const AssetItemWidth = BaseExpandedWidth + 20

const Empty = () => (
  <div className={Styles.noItems}>
    <Text type={FigmaTypo.H3}>{tO('generic.noItemsFound')}</Text>
  </div>
)

/**
 * Shows the information and drops from a gallery
 **/
function GalleryDetailsView(
  {
    gallery,
    drops,
    expiredDrops,
    marketplaceAssets,
    onGoBack,
    onSeeAll,
    dropGroups,
  }: GalleryDetailsViewProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const { marketplace, challenges } = useFeatures()
  const { order, onChangeOrder } = useOrder('gallery-section-order')
  const dropGroupsViews = useMemo<ReactElement[]>(() => {
    return Object.keys(dropGroups).map((group) => (
      <Section
        key={`group-${group}`}
        jsx={{
          empty: null,
          loading: null,
          baseWidth: AssetItemWidth,
          factory: (a) => (
            <DropItem
              availability='open'
              drop={a}
              mode={DropItemViewMode.EXPANDED}
              heroSuffix={group}
            />
          ),
        }}
        src={dropGroups[group]}
        title={group}
      />
    ))
  }, [dropGroups])

  return (
    <>
      <div>
        <GalleryCard
          gallery={gallery}
          shouldExpandOnMount={false}
          mode={gallery.highlight ? 'highlight' : 'default'}
          banner={true}
        />
        <Container className={Styles.back}>
          <BackButton
            onClick={onGoBack}
            text={t('authenticated.payment.goBack')}
          />
        </Container>
      </div>
      <Container>
        <OrderableList
          className={Styles.container}
          keyOrder={order}
          onChangeKeyOrder={onChangeOrder}
          shrinkToOnOrder={46}>
          {[
            challenges ? (
              <ChallengeSection key='challenges' galleryId={gallery.id} />
            ) : (
              <Fragment key='no-challenge' />
            ),
            ...dropGroupsViews,
            <Section
              key={'a'}
              jsx={{
                empty: <NoOffersAvailable />,
                loading: null,
                baseWidth: AssetItemWidth,
                factory: (a) => (
                  <DropItem
                    availability='open'
                    drop={a}
                    mode={DropItemViewMode.EXPANDED}
                  />
                ),
              }}
              src={drops}
              title={tO('galleryDetails.items')}
              ref={ref as RefObject<HTMLDivElement>}
            />,
            <Section
              key={'m'}
              jsx={{
                empty: <Empty />,
                loading: null,
                baseWidth: AssetItemWidth,
                factory: (a) => (
                  <DropItem
                    availability='open'
                    drop={a}
                    mode={DropItemViewMode.EXPANDED}
                  />
                ),
              }}
              src={marketplaceAssets}
              title={tO(
                marketplace
                  ? 'galleryDetails.market'
                  : 'galleryDetails.noMarket'
              )}
              seeAll={{
                label: tO(
                  marketplace
                    ? 'galleryDetails.actions.seeAllMarketplace'
                    : 'galleryDetails.actions.seeAll'
                ),
                onClick: () =>
                  onSeeAll(
                    marketplace
                      ? GalleryDetailsSeeAllOption.MARKETPLACE
                      : GalleryDetailsSeeAllOption.ASSETS
                  ),
              }}
            />,
            !((expiredDrops.totalItems() || 0) > 0) ? (
              <Fragment key='e' />
            ) : (
              <Section
                key={'e'}
                jsx={{
                  empty: <Empty />,
                  loading: null,
                  baseWidth: AssetItemWidth,
                  factory: (a) => (
                    <DropItem
                      availability='expired'
                      drop={a}
                      mode={DropItemViewMode.EXPANDED}
                    />
                  ),
                }}
                src={expiredDrops}
                title={tO('galleryDetails.expiredItems')}
              />
            ),
          ]}
        </OrderableList>
        <br />
        <br />
      </Container>
    </>
  )
}

export default forwardRef(GalleryDetailsView)
