import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) =>
  createStyles({
    clientLogo: {
      borderRight: `2px solid ${theme.palette.grey[200]}`,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginRight: theme.spacing(2),
      '& > svg': {
        minHeight: 25,
        maxHeight: 40,
      },
    },
    rarumLogo: {
      '& > svg': {
        minHeight: 10,
        maxHeight: 25,
        minWidth: 100,
        maxWidth: 120,
      },
    },
    topBox: {
      marginTop: theme.spacing(2.5),
      marginBottom: theme.spacing(2.5),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    pointer: {
      cursor: 'pointer',
    },
    helpIcon: {
      marginRight: theme.spacing(1),
    },
    helpText: {
      whiteSpace: 'nowrap',
    },
    bottomSpacing: {
      marginBottom: theme.spacing(4),
    },
  })

export default makeStylesWrapper(style)
