import Dots from '@onepercentio/one-ui/dist/components/LoaderDotsIndicator'
import Portal, { PortalReceiver } from 'components/Portal/Portal'
import Transitions from 'components/Transitions'
import React, {
  ComponentProps,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useMemo,
} from 'react'
import { SettleOperationState } from './SettleOperation.types'
import useStyles from './SettleOperation.styles'
import { ButtonBase, Typography } from '@material-ui/core'
import IconRarum from 'components/IconRarum'
import useAsyncControl from '@onepercentio/one-ui/dist/hooks/useAsyncControl'
import { t } from 'translate/i18n'
import Button from '@onepercentio/one-ui/dist/components/Button'

const ContentWrapper = ({
  data,
  autoInvoke,
  clickAction,
  status,
  config,
}: {
  config: ConfigShape
  status: SettleOperationState
  data: ConfigShape['loading'] | ConfigShape['settled']
  autoInvoke: boolean
  clickAction?: () => Promise<void>
}) => {
  const loadingControl = useAsyncControl()
  const textContent = loadingControl.error ? config.error : data
  const isLoading = loadingControl.loading
  useEffect(() => {
    if (autoInvoke) loadingControl.process(clickAction!)
  }, [])
  const decoration =
    isLoading && status === SettleOperationState.LOADING ? (
      <Dots dotsCount={3} />
    ) : null
  return (
    <BaseSettleOperationView
      key={SettleOperationState[status]}
      data={{
        ...textContent,
        action: {
          ...data.action!,
          onClick: clickAction
            ? () => loadingControl.process(clickAction)
            : undefined,
        },
      }}
      loading={isLoading}
      loadingDecoration={decoration}
      error={loadingControl.error}
    />
  )
}

/**
 * This is a component that presents the feedback for similar operations over a offer
 *
 * It originated from the flow for creation and edition of an asset
 */
export default function SettleOperationView({
  autoInvoke,
  status,
  config,
  onBack,
}: {
  autoInvoke: boolean
  status: SettleOperationState
  config: ConfigShape
  onBack: () => void
}) {
  const data =
    status === SettleOperationState.LOADING ? config.loading : config.settled
  const clickAction = useMemo(() => {
    if (!data.action) return undefined
    if (Array.isArray(data.action)) return data.action[0].onClick
    else return data.action.onClick
  }, [data.action])

  return (
    <BaseSettleOperationModal onBack={onBack}>
      <ContentWrapper
        key={status}
        autoInvoke={autoInvoke}
        clickAction={clickAction}
        config={config}
        data={data}
        status={status}
      />
    </BaseSettleOperationModal>
  )
}

export function BaseSettleOperationModal({
  onBack,
  children,
}: PropsWithChildren<{ onBack: () => void }>) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <ButtonBase className={classes.close} onClick={onBack}>
        <IconRarum icon='close' size={40} />
      </ButtonBase>
      <Transitions>{children}</Transitions>
      <PortalReceiver name={PORTAL_KEY} />
    </div>
  )
}

export function BaseSettleOperationView({
  data,
  loading,
  loadingDecoration,
  error,
}: {
  data: ComponentProps<typeof SettleOperationView>['config']['loading']
  loadingDecoration?: any
  loading: boolean
  error: boolean
}) {
  const actions = useMemo(() => {
    if (!data.action) return []
    if (Array.isArray(data.action))
      return error ? data.action.slice(0, 1) : data.action
    else return [data.action]
  }, [error])

  return (
    <React.Fragment>
      <Typography variant='h5'>
        {data.title}
        {loadingDecoration}
      </Typography>
      <Typography variant='body1'>{data.description}</Typography>
      <Portal to={PORTAL_KEY}>
        <div>
          {actions.length &&
            actions.map((action) => (
              <>
                <Button
                  variant='filled'
                  onClick={action.onClick}
                  disabled={loading}
                  key={action.label}>
                  {error ? t('generic.retryBtn') : action.label}
                  {loadingDecoration}
                </Button>
                &nbsp;&nbsp; &nbsp;
              </>
            ))}
        </div>
      </Portal>
    </React.Fragment>
  )
}

const PORTAL_KEY = 'settle_operation_view'

type ConfigShape = {
  error: {
    title: string
    description: string | ReactElement
  }
  loading: {
    title: string
    description: string | ReactElement
    action?:
      | {
          label: string
          onClick?: () => Promise<void>
        }
      | { label: string; onClick?: () => Promise<void> }[]
  }
  settled: {
    title: string
    description: string | ReactElement
    action: {
      label: string
      onClick: () => Promise<void>
    }
  }
}
