import { i18n } from './i18n'

type Data = { [key: string]: any }

interface Translated {
  translated?: Data
  [key: string]: any
}

interface TranslateDocumentParameters<D> {
  data?: D | D[] | null
  fallback?: string
}

const move =
  (language: string, fallback: string) =>
  <D extends Translated>(doc: D): D => {
    if (!doc) {
      return doc
    }
    let translated = (doc as Data)[language]
    if (!translated && fallback) {
      translated = (doc as Data)[fallback]
    }
    return {
      ...doc,
      translated,
    }
  }

export const useTranslateDocument = <D extends Translated = Data>({
  data,
  fallback = 'en-US',
}: TranslateDocumentParameters<D>): D | D[] | null => {
  const moveTo = move(i18n.language, fallback)
  if (!data) {
    return null
  }
  if (Array.isArray(data)) {
    return data.map((doc) => moveTo<D>(doc))
  } else {
    return moveTo<D>(data)
  }
}
