import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) =>
  createStyles({
    units: {
      fontWeight: 600,
    },
    availableText: {
      color: 'var(--text-default-color)',
    },
    unavailableText: {
      color: theme.palette.error.main,
    },
  })
export default makeStylesWrapper(style)
