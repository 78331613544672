import { i18n } from 'translate/i18n'
import Box, { BoxProps } from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import React from 'react'
import useStyles from './LiveBadge.style'

const LiveBadgeView: React.FC<BoxProps> = ({ className, ...boxProps }) => {
  const classes = useStyles()

  return (
    <Box className={clsx(classes.wrapper, className)} {...boxProps}>
      <Typography variant='body2' component='span' className={classes.text}>
        <b>{i18n.t('unauthenticated.drops.list.dropItem.liveBadge.liveNow')}</b>
      </Typography>
    </Box>
  )
}

export default LiveBadgeView
