import { makeStyles } from '@material-ui/core'

const useUserProfileStyles = makeStyles((theme) => ({
  actionsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  actionButton: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(5),
      marginBottom: 0,
    },
  },
  cancelButton: {
    alignSelf: 'flex-start',
    [theme.breakpoints.up('md')]: {
      alignSelf: 'center',
    },
  },
}))

export default useUserProfileStyles
