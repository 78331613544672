import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) =>
  createStyles({
    availableText: {
      color: 'var(--text-default-color)',
    },
    unavailableText: {
      color: theme.palette.primary.light,
    },
    availableBorder: {
      borderColor: 'var(--text-default-color)',
      marginRight: '8px',
    },
    unavailableBorder: {
      borderColor: theme.palette.primary.light,
    },
  })
export default makeStylesWrapper(style)
