/// <reference path="../../../utility.d.ts"/>
/// <reference path="../../../globals.d.ts"/>

import {
  ComponentProps,
  ForwardedRef,
  PropsWithChildren,
  forwardRef,
  useMemo,
} from 'react'
import { t } from 'translate/i18n'
import OneText from '@onepercentio/one-ui/dist/components/Text'
import useHero from '@onepercentio/one-ui/dist/hooks/useHero'

const Text = forwardRef(
  (
    {
      ...textProps
    }: Pick<
      ComponentProps<typeof OneText>,
      'type' | 'style' | 'className' | 'color'
    > &
      (
        | {
            code: Parameters<typeof t>[0]
            format?: Parameters<typeof t>[1]
          }
        | PropsWithChildren<{}>
      ),
    ref: ForwardedRef<any>
  ) => {
    const content =
      'code' in textProps
        ? t(textProps.code, textProps.format)
        : textProps.children
    const propsToSpread = useMemo(() => {
      return {
        ...textProps,
        code: undefined,
      }
    }, [textProps])
    return (
      <OneText ref={ref} {...propsToSpread}>
        {content}
      </OneText>
    )
  }
)

/**
 * Utility to show text on screen
 **/
export default Text
export const HeroText = (
  props: ComponentProps<typeof Text> & { heroId: string }
) => {
  const { heroRef } = useHero(
    props.heroId,
    {
      propsToTransition: ['color', 'fontSize', 'lineHeight'],
    },
    {
      onHeroCloned(clone) {
        clone.style.setProperty('z-index', '1')
      },
    }
  )
  return <Text ref={heroRef} {...props} />
}
