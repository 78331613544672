import { makeStyles } from '@material-ui/core'

const useMainRouterStyles = makeStyles((t) => ({
  container: {
    alignItems: 'flex-start',
  },
  content: {
    height: '100vh',
    width: '100vw',
    overflow: 'auto',
    overflowX: 'hidden',
    paddingTop: 'var(--top-bar-height)',
    transition: 'padding-top 250ms linear',
    '& > *': {
      backgroundColor: 'initial',
      backgroundSize: '100vw 288vw',
      backgroundPosition: '0px -1452px',
      backgroundImage: 'var(--bg-image)',
      position: 'relative',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    '&:last-child': {
      animationDelay: '-100ms',
      pointerEvents: 'none',
    },
    '&:first-child': {
      pointerEvents: 'initial',
    },
  },
  noSpacing: {
    paddingTop: '0px',
  },
  transitionRoot: {
    minHeight: 'initial',
  },
}))

export default useMainRouterStyles
