import { PurchaseType } from 'core/logic/purchase/purchase.types'
import PixView from './Pix.view'
import { useMemo } from 'react'

export default function PixLogic({ purchase }: { purchase: PurchaseType }) {
  const qrcode = useMemo(() => {
    if (purchase.charge.paymentType === 'PIX')
      return purchase.charge.pix.qrcodeBase64
    else return purchase.charge.pix.payloadInBase64
  }, [purchase])

  const pixCopyPaste = useMemo(() => {
    switch (purchase.charge.paymentType) {
      case 'PIX':
        return purchase.charge.pix.qrcode
      default:
        const buf = Buffer.from(qrcode, 'base64')
        return buf.toString()
    }
  }, [qrcode, purchase.charge.paymentType, purchase.charge.pix.qrcode])
  if (purchase) {
  }
  return <PixView pixCopyPaste={pixCopyPaste} qrcode={qrcode} />
}
