import React, { useLayoutEffect, useRef } from 'react'
import Styles from './Banner.module.scss'
import Text from 'openspace/components/Text/Text'
import Button from '@onepercentio/one-ui/dist/components/Button'
import OneText from '@onepercentio/one-ui/dist/components/Text'
import { tO } from 'translate/i18n'
import SectionSkip from 'openspace/components/SectionSkip'
import Container from 'openspace/components/Container/Container'
import LabeledHR from 'openspace/components/LabeledHR/LabeledHR'
import { skipToNextSection } from 'openspace/components/SectionSkip/SectionSkip'
// import { ReactComponent as Hands } from 'openspace/assets/svg/home-banner.svg'
import Video from 'openspace/assets/videos/hands-effect.mp4'
import { useTheme } from '@material-ui/core'

/**
 * The banner for v2
 **/
export default function Banner() {
  const videoRef = useRef<HTMLVideoElement>(null)
  const theme = useTheme()
  useLayoutEffect(() => {
    const scrollable = document.querySelector('#scrollable')!
      .lastElementChild as HTMLDivElement
    const onScroll = () => {
      videoRef.current!.style.top = `${scrollable.scrollTop * 0.5}px`
    }
    scrollable.addEventListener('scroll', onScroll, { passive: true })
    return () => scrollable.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <section className={`${Styles.root} dark`}>
      <Container>
        <div className={Styles.content}>
          <h1 className={Styles.brand}>{tO('home.banner.v2.brand')}</h1>
          <LabeledHR
            label={
              <OneText type='p30_v2'>
                {tO('home.banner.v2.brandBenefit')}
              </OneText>
            }>
            <Text
              type='p30_v2'
              code='home.banner.v2.brandMotto'
              className={Styles.brandMotto}
            />
          </LabeledHR>
        </div>

        <Button
          variant='filled'
          className={Styles.features}
          onClick={({ target }) => skipToNextSection(target as HTMLDivElement)}>
          {tO('home.banner.v2.actions.features')}
        </Button>
        <SectionSkip />
      </Container>
      <video playsInline autoPlay muted ref={videoRef}>
        <source src={Video} />
      </video>
      {theme.palette.type === 'dark' ? (
        <span className={Styles.backdrop} />
      ) : null}
    </section>
  )
}
