import {
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { t } from 'translate/i18n'
import countryByCode from './countryByCode'
import useStyles from './styles'
import { OfferAuctionBidModalViewProps } from './types'
import CloseSVG from '@material-ui/icons/Close'
import { useTheme } from '@material-ui/core'
import useAsyncControl from '@onepercentio/one-ui/dist/hooks/useAsyncControl'
import Countdown from '@onepercentio/one-ui/dist/components/Countdown'
import { validateCode, validatePhone } from 'core/modules/firebase/service'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { useUser } from 'core/logic/user'
import Button from '@onepercentio/one-ui/dist/components/Button'
import ActionButton from 'components/ActionButton'

export type OfferAuctionBidPhoneConfirmationProps = {
  handlePhoneChange: OfferAuctionBidModalViewProps['handlePhoneChange']
  handleModal: OfferAuctionBidModalViewProps['handleModal']
  onNext: OfferAuctionBidModalViewProps['onNext']
}

export const OfferAuctionBidPhoneConfirmation = ({
  handlePhoneChange,
  handleModal,
  onNext,
}: OfferAuctionBidPhoneConfirmationProps) => {
  const userData = useUser()
  const phoneForm = useForm<{
    areaCode: string
    phone: string
    code: string
  }>({
    defaultValues: {
      areaCode: '55',
      code: '      ',
    },
  })
  const phoneRequestControl = useAsyncControl()
  const codeValidationControl = useAsyncControl<'INVALID_CODE'>()
  const [codeSent, setCodeSent] = useState(false)
  const [timeRemainingForNextCode, setTimeRemainingForNextCode] =
    useState<number>()
  const [canCodeSent, setCodeCanBeSent] = useState<boolean>(true)

  const phoneCode = phoneForm.watch('code')
  const areaCode = phoneForm.watch('areaCode')
  const phone = phoneForm.watch('phone')

  const classes = useStyles()

  const [formatter, setFormatter] =
    useState<import('google-libphonenumber').PhoneNumberUtil>()

  const { phoneFormat, valid } = useMemo(() => {
    const country = countryByCode[areaCode as keyof typeof countryByCode][1]
    if (!formatter)
      return {
        phoneFormat: phone,
        valid: !!phone,
      }
    try {
      return {
        phoneFormat: formatter.format(
          formatter.parseAndKeepRawInput(phone, country),
          2
        ),
        valid: true,
      }
    } catch (e) {
      return {
        phoneFormat: phone,
        valid: false,
      }
    }
  }, [phone, areaCode, formatter])
  useEffect(() => {
    import('google-libphonenumber').then((mod) => {
      setFormatter(mod.PhoneNumberUtil.getInstance())
    })
  }, [])

  function sendCode() {
    phoneRequestControl.process(async () => {
      try {
        await validatePhone('+' + areaCode + phone)
        setCodeCanBeSent(false)
        setTimeRemainingForNextCode(2 * 60 * 1000)
        setCodeSent(true)
      } catch (e: any) {
        throw e
      }
    })
  }

  function checkCode() {
    codeValidationControl.process(async () => {
      try {
        const result = await validateCode('+' + areaCode + phone, phoneCode)
        if (result.error) {
          codeValidationControl.setError('INVALID_CODE')
        } else {
          userData.profile!.phoneConfirmed = false
          onNext()
        }
      } catch (e) {
        throw e
      }
    })
  }

  const theme = useTheme()
  const SVGStyle = {
    position: 'absolute',
    right: 0,
    width: 48,
    padding: 12,
    height: '100%',
    backgroundColor: theme.palette.action.active,
    color: 'white',
  } as CSSProperties

  return (
    <>
      <form>
        <Grid item container direction='column' className={classes.box}>
          <Typography variant='h3'>
            <strong>
              {t('components.offerAuctionPhoneConfirmation.confirmPhone')}
            </strong>
          </Typography>
          <Box marginTop={2}>
            <Typography variant='caption'>
              {t('components.offerAuctionPhoneConfirmation.whyConfirmPhone')}
            </Typography>
          </Box>
          <Controller
            name='phone'
            control={phoneForm.control}
            rules={{ required: true }}
            render={({ field: { value, onChange }, fieldState }) => (
              <>
                <Box marginTop={2}>
                  <Typography variant='h4'>
                    <strong>
                      {t(
                        'components.offerAuctionPhoneConfirmation.phoneInputLabel'
                      )}
                    </strong>
                  </Typography>
                </Box>
                <Box marginTop={2}>
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <TextField
                        style={{ marginBottom: '0px' }}
                        InputProps={{
                          startAdornment: (
                            <Select
                              SelectDisplayProps={{
                                style: {
                                  padding: 0,
                                  paddingRight: 8,
                                },
                              }}
                              IconComponent={() => null}
                              className={classes.selector}
                              value={areaCode}
                              onChange={(e) => {
                                phoneForm.setValue(
                                  'areaCode',
                                  e.target.value as any
                                )
                              }}>
                              {Object.keys(countryByCode).map((code) => (
                                <MenuItem
                                  value={code}
                                  style={{ display: 'block' }}>
                                  {
                                    countryByCode[
                                      code as keyof typeof countryByCode
                                    ][0]
                                  }{' '}
                                  +{code}
                                </MenuItem>
                              ))}
                            </Select>
                          ),
                        }}
                        className={classes.field}
                        fullWidth
                        variant='outlined'
                        size='medium'
                        error={!!fieldState.error}
                        value={phoneFormat}
                        onChange={(e) =>
                          handlePhoneChange(e.target.value, onChange)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Button
                        variant='filled'
                        disabled={
                          !valid || !canCodeSent || phoneRequestControl.loading
                        }
                        onClick={sendCode}
                        style={{ height: '100%', width: '100%' }}>
                        {phoneRequestControl.error ? (
                          <>
                            {t(
                              'components.offerAuctionPhoneConfirmation.errors.action.tryAgain'
                            )}
                          </>
                        ) : (
                          <>
                            {t(
                              'components.offerAuctionPhoneConfirmation.sendCodeViaSMS'
                            )}
                            &nbsp;
                            {phoneRequestControl.loading && (
                              <CircularProgress size={20} />
                            )}
                            {timeRemainingForNextCode && (
                              <Countdown
                                onFinish={() => {
                                  setTimeRemainingForNextCode(undefined)
                                  setCodeCanBeSent(true)
                                }}
                                timeRemaining={timeRemainingForNextCode}
                              />
                            )}
                          </>
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </>
            )}
          />
          <Controller
            name='code'
            control={phoneForm.control}
            render={() => (
              <>
                <Box marginTop={2}>
                  <Typography variant='h4'>
                    <strong>
                      {t(
                        'components.offerAuctionPhoneConfirmation.informCodeLabel'
                      )}
                    </strong>
                  </Typography>
                </Box>
                <Box marginTop={2}>
                  <Typography variant='caption'>
                    {t(
                      'components.offerAuctionPhoneConfirmation.whyInformCode'
                    )}
                  </Typography>
                </Box>

                <Box maxWidth={40 * 9} marginTop={2}>
                  <Grid container>
                    {new Array(6).fill(undefined).map((_, i) => (
                      <Grid item xs={4} md={2}>
                        <Box marginRight={1} marginLeft={1} marginBottom={1}>
                          <TextField
                            inputProps={{
                              style: { minHeight: 35, textAlign: 'center' },
                            }}
                            disabled={codeValidationControl.loading}
                            value={phoneCode[i]}
                            onKeyDown={({ key, ...e }) => {
                              const els = phoneCode.split('')
                              if (!Number.isNaN(Number(key))) {
                                els[i] = key[0]
                                phoneForm.setValue('code', els.join(''))
                                if (i === 5)
                                  (e.target as HTMLInputElement).blur()
                                else
                                  (e.target as HTMLInputElement)
                                    .parentElement!.parentElement!.parentElement!.parentElement!.nextElementSibling!.querySelector(
                                      'input'
                                    )
                                    ?.focus()
                              } else if (key === 'Backspace') {
                                els[i] = ' '
                                phoneForm.setValue('code', els.join(''))
                                ;(e.target as HTMLInputElement)
                                  .parentElement!.parentElement!.parentElement!.parentElement!.previousElementSibling?.querySelector(
                                    'input'
                                  )
                                  ?.focus()
                              }
                            }}
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                  <Grid item xs={12}>
                    <Box marginTop={2}>
                      <Typography variant='caption' color='error'>
                        {codeValidationControl.error === 'INVALID_CODE'
                          ? t(
                              'components.offerAuctionPhoneConfirmation.errors.invalidConfirmationCode'
                            )
                          : codeValidationControl.error
                          ? t(
                              'components.offerAuctionPhoneConfirmation.errors.genericError'
                            )
                          : ''}
                      </Typography>
                    </Box>
                  </Grid>
                </Box>
              </>
            )}
          />
        </Grid>
        <hr />

        <Grid container className={classes.box} justifyContent='space-between'>
          <Grid item>
            <Button variant='transparent' onClick={handleModal(false)}>
              <CloseSVG />
              {t('components.offerAuctionPhoneConfirmation.actions.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <ActionButton
              onClick={checkCode}
              variant='outlined'
              light
              showSpinner={codeValidationControl.loading}
              disabled={
                !codeSent ||
                phoneCode.includes(' ') ||
                codeValidationControl.loading
              }
              text={
                codeValidationControl.error
                  ? t(
                      'components.offerAuctionPhoneConfirmation.errors.action.tryAgain'
                    )
                  : t(
                      'components.offerAuctionPhoneConfirmation.actions.validateAndContinue'
                    )
              }
            />
          </Grid>
        </Grid>
      </form>
    </>
  )
}
