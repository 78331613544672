import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Divider, Grid, Typography } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { Skeleton } from '@material-ui/lab'
import ActionButton from 'components/ActionButton'
import BackButton from 'components/BackButton'
import { useBalanceForBid, useBidInformation, useBids } from 'context/Auction'
import { currencyFormatter } from 'core/helpers/formatter'
import { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { i18n, t } from 'translate/i18n'
import { createBidSchema } from './schema'
import useStyles from './styles'
import { BidSchema, OfferAuctionBidModalFormProps } from './types'

export const OfferAuctionBidModalForm: React.FC<
  OfferAuctionBidModalFormProps
> = ({ onNext, handleModal }) => {
  const bids = useBids()
  const { information, loading } = useBidInformation()
  const { availableBalance } = useBalanceForBid()
  const range = information?.getBidRange(bids?.[0])
  const percentRange = information?.getBidPercentRange()
  const classes = useStyles()
  const formatter = useMemo(() => {
    return currencyFormatter(information?.currency)
  }, [information?.currency])
  // create Bid form
  const form = useForm<BidSchema>({
    defaultValues: {},
    resolver: yupResolver(
      createBidSchema(range?.[0]!, range?.[1]!, {
        min: i18n.t('components.offerAuctionBidModal.lowerThanMin', {
          min: formatter(range?.[0]!),
        }),
        max: i18n.t('components.offerAuctionBidModal.biggerThanMax', {
          max: formatter(range?.[1]!),
        }),
        required: i18n.t('components.offerAuctionBidModal.required'),
      })
    ),
    reValidateMode: 'onChange',
    mode: 'onSubmit',
  })
  const { control } = form
  const [latestBid, firstBid] = [
    bids?.[0]?.amount,
    bids?.[bids?.length - 1]?.amount,
  ]
  const value = form.watch('amount')
  const formattedValue = useMemo(() => {
    const onlyDigits = Number(value?.toFixed(2).replace(/[^0-9]+/g, ''))
    if (!Number.isNaN(onlyDigits)) return formatter(onlyDigits / 100)
    return ''
  }, [value, formatter])

  const canContinue = useMemo(() => {
    if (!range) return false
    const definedAmount = Number(value)
    const isValueInRange =
      definedAmount <= range[1] && definedAmount >= range[0]
    return isValueInRange
  }, [range, value])

  const currentError = useMemo(() => {
    if (value === undefined || availableBalance === undefined) return undefined
    if (value > availableBalance)
      return t(
        'components.offerAuctionBidModal.bidSettleConfirmation.onChain.errors.insufficientTokenBalance',
        {
          amount: formatter(value),
          available: formatter(availableBalance),
        }
      )
  }, [availableBalance, value, formatter])

  return (
    <form
      onSubmit={form.handleSubmit((e) => {
        onNext(e.amount)
      })}>
      <Grid item container direction='column' className={classes.box}>
        <Grid item>
          <Typography variant='h3'>
            <strong>{i18n.t('components.offerAuctionBidModal.newBid')}</strong>
          </Typography>
          <Box marginTop={2}>
            <Typography variant='caption'>
              {i18n.t('components.offerAuctionBidModal.instruction', {
                min_pct: percentRange?.[0],
                max_pct: percentRange?.[1],
              })}
            </Typography>
          </Box>
        </Grid>
        <Grid item container>
          <Grid item className={classes.bidBoundery}>
            <Typography variant='caption'>
              {i18n.t('components.offerAuctionBidModal.currentBid')}
            </Typography>
            <Typography variant='body1'>
              {loading ? (
                <Skeleton height={'1rem'} width={'5rem'} />
              ) : (
                <strong>{latestBid ? formatter(latestBid) : '--'}</strong>
              )}
            </Typography>
          </Grid>
          <Grid item className={classes.bidBoundery}>
            <Typography variant='caption'>
              {i18n.t('components.offerAuctionBidModal.initialBid')}
            </Typography>
            <Typography variant='body1'>
              {!information ? (
                <Skeleton height={'1rem'} width={'5rem'} />
              ) : (
                <strong>{firstBid ? formatter(firstBid) : '--'}</strong>
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid item lg={7} md={10} sm={12}>
          <Controller
            name='amount'
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange }, fieldState }) => (
              <TextField
                className={classes.field}
                fullWidth
                variant='outlined'
                size='medium'
                error={!!fieldState.error || !!currentError}
                helperText={
                  currentError ||
                  fieldState.error?.message ||
                  (range ? (
                    i18n.t(
                      'components.offerAuctionBidModal.tipMinimumAndMaximumBid',
                      {
                        min: formatter(range![0]),
                        max: formatter(range![1]),
                      }
                    )
                  ) : (
                    <Skeleton height={'1rem'} width={'15rem'} />
                  ))
                }
                label={i18n.t('components.offerAuctionBidModal.bidAmount')}
                value={formattedValue}
                onChange={(e) => {
                  const amount: number =
                    parseInt(
                      e.target.value?.match(/\d/gi)?.join('') || '0',
                      10
                    ) / 100
                  onChange(amount)
                }}
              />
            )}
          />
        </Grid>
        {/* {i18n.t('components.offerAuctionBidModal.minimumBid')} */}
      </Grid>
      <Divider />
      <Grid
        item
        container
        justifyContent='space-between'
        className={classes.box}>
        <BackButton
          icon='close'
          text={i18n.t('components.offerAuctionBidModal.cancel')}
          onClick={handleModal(false)}
        />
        <ActionButton
          text={i18n.t('components.offerAuctionBidModal.continue')}
          type='submit'
          disabled={!canContinue}
        />
      </Grid>
    </form>
  )
}
