/// <reference path="../../../../../node_modules/@muritavo/webpack-microfrontend-scripts/augmentation/ImageScales.d.ts"/>

import vwURL from 'openspace/assets/cases/sm/vw.png'
import chiefsURL from 'openspace/assets/cases/sm/chiefs.png'
import melissaURL from 'openspace/assets/cases/sm/melissa.png'
import StoreColors from './StoreColors'

const iconsCtx = require.context('openspace/assets/png/home/stores')

const i = (storeName: string) =>
  (
    iconsCtx(
      `./${storeName}.png`
    ) as typeof import('openspace/assets/png/bg.png')
  ).Scaled['0.5x']

export default [
  // {
  //   title: 'The NFT innovation that can save the Amazon',
  //   description:
  //     'In an unprecedented initiative, NFT buyers from the Amazon become owners of one hectare of land in the Amazon region.',
  //   storeLink: 'https://moss.rarum.io/',
  //   image: mossURL,
  //   dominantColor: StoreColors.moss.bg,
  //   textColor: StoreColors.moss.text,
  // },
  {
    title: 'BUILDING YOUR GARAGE ON WEB3',
    description:
      'The first auto industry in Latin America to launch its own gallery of NFTs and expand their relationship with its customers.',
    storeLink: 'https://digitalgaragevw.com/',
    image: vwURL,
    dominantColor: StoreColors.vw.bg,
    textColor: StoreColors.vw.text,
    thumbnail: i('vw'),
  },
  {
    title: 'UNLOCK EXPERIENCES AND NFT COLLECTIBLES IN FASHION',
    description:
      'Melissa launched the gallery of NFTs, a connection of digital assets to physical experiences!',
    storeLink: 'https://epocanegocios.globo.com/Tecnologia/noticia/2022/04/melissa-lanca-nfts-de-classicos-da-marca.html',
    image: melissaURL,
    dominantColor: StoreColors.melissa.bg,
    textColor: StoreColors.melissa.text,
    thumbnail: i('melissa'),
  },
  {
    title: 'TRANSFORM THE HR WORLD WITH NFTS',
    description:
      'First NFT Open Talent Platform for executive careers management in Latin America.',
    storeLink: 'https://www.chiefsgroup.com.br/',
    image: chiefsURL,
    dominantColor: StoreColors.chiefs.bg,
    textColor: StoreColors.chiefs.text,
    thumbnail: i('chiefs'),
  },
  {
    description: '',
    title: 'Boticário',
    dominantColor: StoreColors.boti.bg,
    textColor: StoreColors.boti.text,
    image: '',
    storeLink: 'https://nft.diadobatom.com.br/',
    thumbnail: i('boti'),
  },
  // {
  //   title: 'OWN THE BEST OF MOTORSPORT IN NFT',
  //   description:
  //     'Rarum MotorSports is the first token platform about motorsports in Brazil',
  //   storeLink: 'https://rarum-motorsports.com/',
  //   image: motorURL,
  //   dominantColor: StoreColors.motorsports.bg,
  //   textColor: StoreColors.motorsports.text,
  // },
] as {
  title: string
  description: string
  storeLink: string
  image: string
  dominantColor: string
  textColor: string
  thumbnail: string
}[]
