import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  root: {
    top: 0,
    [theme.breakpoints.down(640)]: {
      maxWidth: 'initial !important',
      zIndex: 1100,
      '& + div': {
        zIndex: 1101,
      },
    },
  },
}))
