import { makeStyles } from "@material-ui/core";

const useWalletStyles = makeStyles<any, {
    size: number
}>(({
    root: {
        width: ({ size }) => size,
        height: ({ size }) => size,
        "& > *": {
            width: ({ size }) => size,
            height: ({ size }) => size,
            borderRadius: ({ size }) => size / 4,
            minHeight: ({ size }) => `${size}px !important`,
        }
    }
}))
export default useWalletStyles