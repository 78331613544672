import { createStyles, Theme, withStyles } from '@material-ui/core'
import StepConnector from '@material-ui/core/StepConnector'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(4),
    },
    inputPane: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      minWidth: 0,
      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(4),
        minWidth: 'auto',
      },
    },
    formContainer: {
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      flexDirection: 'column',
      border: `1px solid ${theme.palette.grey[200]}`,
      borderRadius: 4,
      padding: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(5),
      },
    },
    stepTitle: {
      marginLeft: theme.spacing(2),
    },
    spacing: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    smallSpacing: { marginTop: theme.spacing(2) },
    topSpacing: { marginTop: theme.spacing(4) },
    field: {
      flexGrow: 1,
      maxWidth: '35ch',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
    buttons: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
      },
    },
    radioGroup: {
      backgroundColor: theme.palette.common.white,
      minWidth: '40%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    radioItem: {
      margin: 0,
      paddingRight: theme.spacing(1),
      borderRadius: 4,
      border: `1px solid ${theme.palette.grey[200]}`,
    },
    radioItemFullWidthLabel: {
      width: '100%',
    },
    paymentMethodGroup: {
      '& .MuiFormControlLabel-root': {
          marginLeft: 0,
          marginRight: 0,
      }
    },
    paymentMethodRadio: {
      display: 'none',
    },
    paymentMethodItem: {
      flexGrow: 1,
    },
    paymentMethodLabel: {
      display: 'flex',
      alignItems: 'center',
      padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
      borderRadius: theme.shape.borderRadius,
      minHeight: 80,
      borderColor: theme.palette.grey[300],
      borderWidth: 1,
      borderStyle: 'solid',
      marginBottom: theme.spacing(2),
    },
    paymentMethodLabelChecked: {
      borderColor: theme.palette.primary.main,
      boxShadow: `0 0 0 1px ${theme.palette.primary.main} inset`
    },
    paymentMethodIcon: {
      height: 24,
      position: 'relative',
      '& .br': {
        position: 'absolute',
        top: 3,
        right: -11,
      }
    },
    paymentMethodText: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      marginLeft: theme.spacing(3),
    },
    paymentMethodTextPrice: {
      fontSize: '0.85em'
    },
    pix: {
      flex: '0 0 25px',
      margin: '0 15px 0 15px',
      '& > img': {
        width: '100%',
        display: 'block',
      },
    },
    junoLabel: {
      justifyContent: 'center',
    },
    alert: {
      '&>.MuiAlert-icon': {
        alignSelf: 'center',
      },
    },
    actionsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
    actionButton: {
      marginBottom: theme.spacing(5),
      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(5),
        marginBottom: 0,
      },
    },
    cancelButton: {
      alignSelf: 'flex-start',
      [theme.breakpoints.up('md')]: {
        alignSelf: 'center',
      },
    },
    editButton: {
      padding: `${theme.spacing(2)}px ${theme.spacing(6)}px ${theme.spacing(2)}px 0`,
    },
  })
export default makeStylesWrapper(style)

export const LineConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 18,
    left: 'calc(-50% + 32px)',
    right: 'calc(50% + 32px)',
  },
  active: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  completed: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  line: {
    borderColor: theme.palette.grey[200],
    borderTopWidth: 2,
    borderRadius: 0,
  },
}))(StepConnector)
