import { useAsset } from 'core/logic/asset/asset.hook'
import { useParams } from 'react-router-dom'
import AssetDetail from './AssetDetail.view'
import { useMemo } from 'react'
import { AssetType, AssetWithBalance } from 'core/logic/asset/asset.types'
import useCustomHistory from '@onepercentio/one-ui/dist/hooks/useCustomHistory'
import ROUTES from 'core/modules/router'
import { i18n } from 'translate/i18n'
import {
  useUpToDateMyCollectionItems,
} from 'context/MyCollection'

const AssetDetailLogic = ({ marketplace }: { marketplace: boolean }) => {
  const history = useCustomHistory()
  const { assetId } = useParams<{ assetId: string }>()
  const { ownedItems } = useUpToDateMyCollectionItems()
  const { asset: singleAsset } = useAsset({ id: assetId })
  const onBackClickHandler = () =>
    history.goBackWithFallback(ROUTES.unauthenticated.homepage)
  const asset = useMemo<AssetWithBalance | AssetType | null>(() => {
    return ownedItems?.find((a) => a.id === assetId) || singleAsset || null
  }, [ownedItems, assetId, singleAsset])
  const intl = asset?.[i18n.language] || asset!

  return (
    asset && (
      <AssetDetail
        asset={asset}
        title={intl.name}
        description={intl.description}
        features={intl.features}
        assetUrl={asset?.media.imageUrl}
        onBack={onBackClickHandler}
        ipfs={asset?.ipfs.jsonFile}
        marketplace={marketplace}
      />
    )
  )
}

export default AssetDetailLogic
