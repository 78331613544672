import LinkIcon from '@material-ui/icons/Link'
import TwitterIcon from '@material-ui/icons/Twitter'
import copy from 'clipboard-copy'
import { SHARE_TITLE } from 'core/constants'
import { analyticsEvent } from 'core/modules/analytics/events'
import { shareTwitter } from './share.logic'

export type ShareButtonClickHandler = (props: { id: string; title?: string; url?: string; text?: string }) => void
export interface ShareButtonItem {
  title: string
  onClick: ShareButtonClickHandler
  Icon: import('@material-ui/icons').SvgIconComponent
}

export const shareButtonItems: ShareButtonItem[] = [
  // { title: 'Facebook', onClick: shareFacebook, Icon: FacebookIcon },
  { title: 'Twitter', onClick: shareTwitter, Icon: TwitterIcon },
  // { title: 'WhatsApp', onClick: shareWhatsApp, Icon: WhatsAppIcon },
]

export const copyButtonItem: ShareButtonItem = {
  title: 'Copiar link',
  onClick: ({ id, url, title }) => {
    analyticsEvent.share({ method: 'Clipboard', type: 'offer', id })
    copy(`${title || SHARE_TITLE}: ${url || window.location.href}`)
  },
  Icon: LinkIcon,
}
