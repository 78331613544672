import { makeStyles } from '@material-ui/core'

const useKYCStatusStyles = makeStyles((t) => ({
  container: {
    padding: '14px 24px',
    borderRadius: '8px',
    backgroundColor: t.palette.primary.main,
    color: t.palette.primary.contrastText,
    '& svg': {
      height: '2.5rem',
      marginRight: '0.5rem',
    },
    '& > section': {
      width: '100%',
      minHeight: 0,
      '& > div': {
        minHeight: '4.5rem',
        margin: '0px 16px',
        alignItems: 'center',
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        '& > :last-child': {
          minWidth: '220px',
        },
      },
    },
  },
  text: {
    flex: 1,
    '& > :first-child': {
      fontWeight: 'bold',
    },
  },
}))

export default useKYCStatusStyles
