import React from 'react'
import View from './UserProfile.view'
import { UserProfileProps } from './UserProfile.types'
import { useProfileForm } from './UserProfile.hook'
import { useUser } from 'core/logic/user'
import Loading from 'components/Loading'
import { RarumUserProfile } from 'core/logic/user/user.types'

export default function UserProfileLogicWrapper(props: UserProfileProps) {
  const { profile } = useUser()

  if (!profile || !profile.isLoaded) return <Loading />
  return <UserProfileLogic profile={profile} />
}

function UserProfileLogic({ profile }: { profile: RarumUserProfile }) {
  const {
    form,
    loading,
    error,
    updatePersonalData,
    clearPersonalData,
    hasKyc,
    address,
    clear,
  } = useProfileForm(profile)

  return (
    <View
      form={form}
      update={{ loading, error: !!error }}
      clear={clear}
      onSubmit={updatePersonalData}
      hasKyc={hasKyc}
      address={address}
      onClear={clearPersonalData}
    />
  )
}
