import darkThemeFactory from './mode/dark'
import lightThemeFactory from './mode/light'

export default lightThemeFactory('#7B61FF', '#654F9C', '#3C3466', {
  primary: 'Inter',
  secondary: 'Lexend',
})
export const defaultMode = 'light'
export const alternativeMode = darkThemeFactory(
  '#7B61FF',
  '#654F9C',
  '#3C3466',
  {
    primary: 'Open Sans',
    secondary: 'Lexend',
  }
)
