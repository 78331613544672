import { RootState } from 'core/modules/redux'
import { useDispatch, useSelector } from 'react-redux'
import { useWalletConnnectorContext } from '../wallet'
import { actions as mainActions } from './main.reducer'

const useMain = () => {
  const dispatch = useDispatch()
  const { web3 } = useWalletConnnectorContext()

  return {
    isLoading:
      useSelector<RootState, boolean>(({ main }) => main.isLoading) || !web3,
    startLoading: () => dispatch(mainActions.startLoading()),
    stopLoading: () => dispatch(mainActions.stopLoading()),
  }
}

export default useMain
