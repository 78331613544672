import Button from '@onepercentio/one-ui/dist/components/Button'
import { ComponentProps, PropsWithChildren } from 'react'
import QuickResumeBannerBanner from './QuickResumeBanner.view'

export default function QuickResumeBannerLogic({
  DecorationComp = () => null,
  actions = [],
  children,
}: PropsWithChildren<{
  DecorationComp?: React.FC
  actions?: ({
    type?: ComponentProps<typeof Button>['variant']
    label: string
  } & (
    | {
        onClick: () => void
      }
    | {
        linkTo: string
      }
  ))[]
}>) {
  return (
    <QuickResumeBannerBanner
      DecorationComp={DecorationComp}
      actions={actions.map((a) => ({
        action:
          'linkTo' in a ? () => window.open(a.linkTo, '_blank') : a.onClick,
        label: a.label,
        type: a.type || 'filled',
      }))}>
      {children}
    </QuickResumeBannerBanner>
  )
}
