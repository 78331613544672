import { Theme } from '@material-ui/core'
import createStyles from '@material-ui/styles/createStyles'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) => {
  return createStyles({
    box: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      alignSelf: 'flex-start',
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: 4,
      backgroundColor: 'transparent',
      padding: theme.spacing(2),
      cursor: 'not-allowed',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  })
}

export default makeStylesWrapper(style)
