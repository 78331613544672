import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    dynamicImg: {
        width: "40%",
        minWidth: "145px",
        height: "179px",
        marginRight: "10px",
        maxWidth: "250px",
        overflow: "hidden",
        display: "flex",
        justifyContent: "flex-end",
        "& img": {
            marginTop: "60px",
            height: "100%"
        },
        [theme.breakpoints.up("md")]: {
            height: "120px",
            padding: "0px 44px",
            "& img": {
                marginTop: "initial",
                marginRight: "30px",
            },
        },
    },
    root: {
        display: "flex",
        backgroundColor: theme.palette.nero,
        alignItems: "center",
        marginBottom: "24px"
    },
    content: {
        flex: 2,
        display: "flex",
        flexWrap: "wrap",
        "& > :first-child": {
            marginRight: "auto"
        },
        "& > :last-child": {
            minWidth: "110px",
            [theme.breakpoints.up("md")]: {
                minWidth: "213px",
            }
        }
    }
}))