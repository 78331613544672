import useRouteGalleryOrDefault from 'openspace/hooks/useRoutePathGallery'
import { useLayoutEffect } from 'react'
import { updateThemeColor } from 'utility/tags'

export default function HTMLTagUpdater() {
  const gallery = useRouteGalleryOrDefault()
  useLayoutEffect(() => {
    if (gallery?.predominantColor) {
      const oldColor = updateThemeColor(gallery.predominantColor)
      return () => {
        updateThemeColor(oldColor)
      }
    }
  }, [gallery])

  return null
}
