import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) =>
  createStyles({
    head: {
      padding: theme.spacing(2),
      '& .counter': {
        display: 'flex',
        alignItems: 'center',
      },
    },
    line: {
      display: 'flex',
      alignItems: 'center',
      '&>div': {
      },
      '& .bid': {
        width: 150,
      },
      '& .date': {
        flexGrow: 1,
        '&:first-letter': {
          textTransform: 'uppercase',
        },
      },
      '& .user': {
        width: 130,
        whiteSpace: 'nowrap',
        flexGrow: 0,
      },
    },
    title: {
      backgroundColor: theme.palette.background.default,
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    bid: {
      padding: theme.spacing(2),
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      '& .user': {
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '& .icon': {
          fontSize: '1.2rem',
          marginRight: theme.spacing(.5),
        },
      },
    }
  })

export default makeStylesWrapper(style)
