import { SHARE_FACEBOOK_APP_ID, SHARE_TEXT, SHARE_TITLE, SHARE_URL } from 'core/constants'
import { analyticsEvent } from 'core/modules/analytics/events'
import { ShareButtonClickHandler } from './share.data'

// https://developers.facebook.com/docs/sharing/reference/feed-dialog
export const shareFacebook: ShareButtonClickHandler = ({ id, url, title }) => {
  analyticsEvent.share({ method: 'Facebook', type: 'offer', id })
  window.open(
    `https://www.facebook.com/dialog/feed?app_id=${SHARE_FACEBOOK_APP_ID}&display=popup&link=${url || SHARE_URL}&redirect_uri=${
      url || SHARE_URL
    }`,
    title || SHARE_TITLE,
    'resizable=yes,width=600,height=400,scrollbars=yes'
  )
}

export const shareTwitter: ShareButtonClickHandler = ({ id, url, text }) => {
  analyticsEvent.share({ method: 'Twitter', type: 'offer', id })
  window.open(
    `http://twitter.com/intent/tweet?original_referer=${url || SHARE_URL}&text=${encodeURIComponent(text || SHARE_TEXT)}&url=${
      url || SHARE_URL
    }`
  )
}

export const shareWhatsApp: ShareButtonClickHandler = ({ id, text }) => {
  analyticsEvent.share({ method: 'WhatsApp', type: 'offer', id })
  window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(text || SHARE_TEXT)}`)
}

/**
 * Notice: mobile sharing with navigator only works with HTTPS enabled.
 * Set HTTPS=true in the .env file to turn it on during development.
 * Notice: Mobile Firefox only shares the URL field: https://github.com/mozilla-mobile/fenix/issues/10193
 */
export const mobileShare: ShareButtonClickHandler = async ({ id, title, url, text }) => {
  const shareData = {
    title: title || SHARE_TITLE,
    text: text || SHARE_TEXT,
    url: url || SHARE_URL,
  }
  if (navigator.share) {
    try {
      analyticsEvent.share({ method: 'Mobile Share', type: 'offer', id })
      await navigator.share(shareData)
    } catch (error) {
      console.error('Erro ao tentar compartilhar')
    }
  } else {
  }
}
