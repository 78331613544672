import { ContractType } from 'core/logic/contract/types/types'
import IMyCollectionFacade, {
  BalancesOfType,
  FacadeTypes,
} from 'service/IMyCollectionFacade'
import Web3 from 'web3'

export default abstract class ERC1155AbstractFacade<
  C
> extends IMyCollectionFacade {
  nftContractForRead!: C

  abstract getNormalizedBalance(forWallet: string): Promise<{
    balances: string[]
    tokens: string[]
  }>
  abstract getContractInstance(): C
  constructor(nftAddress: string, web3ForRead: Web3, web3ForWrite: Web3) {
    super(nftAddress, FacadeTypes.TOKENS, web3ForRead, web3ForWrite)
    this.nftContractForRead = this.getContractInstance()
  }
  async getOwnedItems(): Promise<BalancesOfType<ContractType.RarumNFT>[]> {
    const balances: BalancesOfType<ContractType.RarumNFT>[] = []
    for (let wallet of this.wallets) {
      const { balances: allBalances, tokens: allTokens } =
        await this.getNormalizedBalance(wallet)
      const tokensWithBalance = allBalances
        .map((balance, index) => ({
          token: Number(allTokens[index]),
          balance: Number(balance),
        }))
        .filter((a) => a.balance > 0)

      for (let tokenWithBalance of tokensWithBalance) {
        const tokenWithBalanceFromAnotherWallet = balances.find(
          (b) => b.tokenId === tokenWithBalance.token
        )
        if (tokenWithBalanceFromAnotherWallet) {
          tokenWithBalanceFromAnotherWallet.balance += tokenWithBalance.balance
        } else {
          balances.push({
            type: ContractType.RarumNFT,
            balance: tokenWithBalance.balance,
            tokenId: tokenWithBalance.token,
          })
        }
      }
    }
    return balances
  }
}
