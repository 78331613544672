export default function usePageScroll() {
  return (el: HTMLElement, [offsetTop]: [top: number] = [0]) => {
    const scrollable = document.querySelector('#scrollable')!.firstElementChild!
    scrollable.scrollTo({
      top: el.offsetTop - offsetTop,
      left: 0,
      behavior: 'smooth',
    })
  }
}
