import { AbiItem } from 'web3-utils'

export const RAW_CHALLENGE_ABI = [
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_recipeId',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_tokenId',
        type: 'uint256',
      },
    ],
    name: 'complete',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
] as const

export const CHALLENGE_ABI = RAW_CHALLENGE_ABI as unknown as AbiItem[]
