import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    small: {
      height: '24px',
      width: '24px',
    },
    normal: {
      height: '36px',
      width: '36px',
    },
    medium: {
      height: '48px',
      width: '48px',
    },
    text: {
      color: theme.palette.text.primary,
      fontWeight: 700,
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
    textDark: {
      color: theme.palette.common.black,
      fontWeight: 700,
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
  })

export default makeStylesWrapper(style)
