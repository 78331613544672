import { DROP_STATUS } from 'core/helpers/drop'
import {
  BaseAssetType,
  MarketplaceAssetType,
} from 'core/logic/asset/asset.types'
import { OfferType } from 'core/logic/drop/drop.types'
import { TenantType } from 'core/logic/tenant/tenant.types'

export enum DropItemViewMode {
  /** For When you only need the image to be shown */
  IMAGE = 'image',
  /** Shows only the title */
  SHORT = 'short',
  /** Shows all details */
  EXPANDED = 'expanded',
}

export type DropItemViewProps = {
  drop: OfferType | MarketplaceAssetType | BaseAssetType
  tenant: TenantType
  mode: DropItemViewMode
  counters?: {
    remaining?: number
  }
  /** Used to differentiate when 2 cars are shown for the same page */
  heroSuffix?: string
} & {
  onClick: () => void
}
export type DropItemProps = Omit<
  DropItemViewProps,
  'onClick' | 'counters' | 'tenant'
> & {
  availability: DROP_STATUS
}
