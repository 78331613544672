import { ToFirebaseType } from '@onepercentio/one-ui/dist/types'
import { groupBy } from 'core/helpers/arrays'
import { CountersStateType } from 'core/logic/counters/counters.types'
import {
  DropBidType,
  DropType,
  DropWithCounter,
} from 'core/logic/drop/drop.types'
import { isAfter, isBefore } from 'date-fns'

/*
  These are the lifecycle states of a Drop.
  States marked with '?' are optional.
  scheduled | queue? | open | out? | expired?
 */
export type DROP_STATUS = 'scheduled' | 'queue' | 'open' | 'out' | 'expired'

interface dropStatusProps {
  drop: DropType
  remaining?: number
  relativeTo?: Date
}
export const dropStatus = (
  { drop, remaining, relativeTo }: dropStatusProps,
  period?: Date[]
): DROP_STATUS => {
  const { eventStart } = drop
  const [begin, expire] = period || [drop.begin, drop.expire!]
  const now = relativeTo || new Date()

  const hasExpired = expire ? isBefore(expire, now) : false

  if (hasExpired) return 'expired'

  if (remaining !== undefined && remaining <= 0) return 'out'

  const opensInFuture = isAfter(begin, now)
  if (opensInFuture) {
    const queueHasStarted = eventStart ? isBefore(eventStart, now) : false
    if (queueHasStarted) return 'queue'
    else return 'scheduled'
  }

  return 'open'
}

export const scaleUnitPrice = (unitPrice: number) =>
  Number((unitPrice / 100).toFixed(2))

export const mapDropData = (drop: ToFirebaseType<DropType>, id?: string) => {
  const result: any = {
    ...drop,
    ...(id ? { id } : {}),
    unitPrice: scaleUnitPrice(drop.unitPrice),
    ...(drop.minPrice ? { minPrice: scaleUnitPrice(drop.minPrice) } : {}),
    created: new Date(drop.created as unknown as string),
    begin: new Date(drop.begin as unknown as string),
    ...(drop.prices
      ? {
          prices: Object.entries(drop.prices || {}).reduce(
            (obj, [currency, amount]) => ({
              ...obj,
              [currency]: scaleUnitPrice(amount || 0),
            }),
            {}
          ),
        }
      : {}),
    ...(drop.eventStart ? { eventStart: new Date(drop.eventStart) } : {}),
    ...(drop.expire ? { expire: new Date(drop.expire) } : {}),
  }
  if (drop.cvm88 && 'expire' in drop.cvm88) {
    result.cvm88.expire = drop.cvm88.expire.toDate()
  }

  return result
}

export const mapDropBidsData = (bid: DropBidType): DropBidType => ({
  ...bid,
  amount: scaleUnitPrice(bid.amount),
})

/**
 * This method groups drops by their status, according to the dropStatus helper function
 * @param param0 Drops, and the date to compare them against (usually now)
 * @returns Groupped drops
 */
export const groupDrops = ({
  drops,
  relativeTo,
}: {
  drops: DropType[]
  relativeTo: Date
}) => {
  // TODO: Analyze the possibility of replacing this logic (complexity: O(drops)) with direct Firestore queries

  // Group drops by their current status
  const grouped = groupBy(drops, (drop) =>
    dropStatus({ drop, relativeTo: relativeTo })
  )

  const open = grouped.open || []
  const queue = grouped.queue || []
  const scheduled = grouped?.scheduled || []
  const expired = grouped?.expired || []

  // Active drops are all which are not 'expired'
  const active = [...queue, ...open, ...scheduled]
  const activeIds = active.map((d) => d.id)

  // Inactive drops are all which are 'expired'
  const inactive = expired.reverse()

  return { active, activeIds, inactive }
}

/**
 * This method adds count to the active drops, and reorganizes the groups such that drops with count=0 go to the inactive group
 * @param param0 Active and inactive drops, as returned from groupDrops
 * @returns Reorganized drops with count
 */
export const organizeWithCounters = ({
  active,
  inactive,
  counters,
}: {
  active: DropType[]
  inactive: DropType[]
  counters: CountersStateType
}) => {
  // Adds counter to drops
  const activeCounters: DropWithCounter[] = active.map((d) => {
    const count = counters[d.id]! >= 0 ? d.supply - counters[d.id]! : undefined
    return { ...d, count }
  })

  // Due to the streamed nature of the counters snapshot, it is not trvial to know if it is loaded for the current drops. We do this by checking if any drop still has an undefined counter.
  const countersLoaded = !activeCounters.find((d) => d.count === undefined)

  // The final active drops are those that were active and still have a supply
  const finalActive = countersLoaded
    ? activeCounters.filter((d) => d.count && d.count > 0)
    : null

  // These are drops that have no more supply available for purchase
  const dropsWithoutSupply = countersLoaded
    ? activeCounters.filter((d) => d.count !== undefined && d.count <= 0)
    : null

  const finalInactive: DropWithCounter[] | null = dropsWithoutSupply && [
    ...dropsWithoutSupply,
    ...inactive,
  ]

  return { finalActive, finalInactive }
}
