import React from 'react'
import { HomeViewProps } from './Home.types'
import Banner from './Banner'
import Features from './Features/Features'
import GalleriesLogic from './Galleries/Galleries.logic'
import Believers from './Believers/Believers'
import Brand from './Brand/Brand'
import FAQ from './FAQ/FAQ'
import Footer from './Footer/Footer'
import FooterLogic from 'components/Footer/Footer.logic'
import ContractModels from './ContractModels/ContractModels'
import Creators from './Creators/Creators'
import Spacing from '@onepercentio/one-ui/dist/components/Spacing/Spacing'

/**
 * The organized elements for the new Home version
 **/
export default function HomeView({
  onScheduleDemo,
  onGoToCreators,
}: HomeViewProps) {
  return (
    <>
      <Banner />
      <Features onScheduleDemo={onScheduleDemo} />
      <ContractModels onContact={onScheduleDemo} />
      <Believers />
      <Creators mode='page' onKnowMore={onGoToCreators} />
      <Spacing size='32' />
      <GalleriesLogic />
      <Brand />
      <FAQ />
      <Footer onScheduleDemo={onScheduleDemo} />
      <FooterLogic />
    </>
  )
}
