import React, {
  ElementRef,
  Fragment,
  useEffect,
  useInsertionEffect,
  useMemo,
  useRef,
} from 'react'
import AnchoredTooltip from '@onepercentio/one-ui/dist/components/AnchoredTooltip/AnchoredTooltip'
import { ForCreatorsViewProps } from './ForCreators.types'
import Text from 'openspace/components/Text/Text'
import { FigmaTypo } from 'containers/OneUIProvider/OneUIProvider.data'
import Spacing from '@onepercentio/one-ui/dist/components/Spacing/Spacing'
import Button from '@onepercentio/one-ui/dist/components/Button/Button'
import useMouseHover from '@onepercentio/one-ui/dist/hooks/ui/useMouseHover'
import useRebound from '@onepercentio/one-ui/dist/hooks/useRebound'
import OneText from '@onepercentio/one-ui/dist/components/Text/Text'
import Container from 'openspace/components/Container/Container'
import LabeledHR from 'openspace/components/LabeledHR/LabeledHR'
import { i18n, tO } from 'translate/i18n'
import { AvailableConcepts } from 'openspace/pages/Authenticated/Home/v2/Home/Features/Features'
import IconRarum from 'components/IconRarum/IconRarum.view'

import Creators, {
  CreatorCard,
} from 'openspace/pages/Authenticated/Home/v2/Home/Creators/Creators'
import CreatorStyles from 'openspace/pages/Authenticated/Home/v2/Home/Creators/Creators.module.scss'
import Styles from './ForCreators.module.scss'
import Tag from 'components/Tag/Tag'
import { CreatorsSection, useCreatorsSection } from './ForCreators.hook'
import UncontrolledTransition from '@onepercentio/one-ui/dist/components/UncontrolledTransition/UncontrolledTransition'
import Scheduler, {
  SchedulerBanner,
} from 'openspace/components/Scheduler/Scheduler'
import { updateThemeColor } from 'utility/tags'
import { ForCreatorsCoreModules } from './ForCreators.data'

function HoverableAction({ onFeature }: { onFeature: () => void }) {
  const { uiEvents, hovering } = useMouseHover()
  const ref = useRef<HTMLButtonElement>(null)
  const { current, setState } = useRebound(false, 2)
  useEffect(() => {
    if (current === false) {
      setTimeout(() => {
        setState(true)
      }, 2000)
    }
  }, [current])

  return (
    <>
      <AnchoredTooltip
        anchorRef={ref}
        open={hovering}
        className={`${Styles.tooltip} dark`}>
        <Text
          type='p10'
          code='home.forCreators.actions.clickToKnowMore'
        />
      </AnchoredTooltip>
      <Button
        ref={ref}
        variant='transparent-link'
        onClick={() => onFeature()}
        {...(uiEvents as any)}>
        <IconRarum size={16} icon='help' />
      </Button>
    </>
  )
}

function CardRow({
  section,
  title,
  profile,
  k,
  onFeature,
}: {
  section: CreatorsSection
  k: string
  title: string
  profile: string[]
  onFeature: (feature: AvailableConcepts) => void
}) {
  const options = useMemo(() => {
    return i18n.t<
      'openspace',
      {
        proposal: string[]
      }
    >(`forCreators.${section}.sections.${k}` as any, {
      returnObjects: true,
    })
  }, [section, k])

  return (
    <LabeledHR label={''} disableAnimation>
      <Fragment>
        <Spacing size='16' />
        <div className={Styles.row}>
          <div>
            <CreatorCard
              k={k}
              title={title}
              profile={profile}
              section={section}
              enableHero
            />
          </div>
          <div>
            <Text type={FigmaTypo.P16} code='forCreators.valueProposition' />
            <Spacing size='16' />
            <ul className={Styles.proposition}>
              {options.proposal.map((p) => (
                <li dangerouslySetInnerHTML={{ __html: p }} />
              ))}
            </ul>
            <Text type={FigmaTypo.P16} code='forCreators.coreModules' />
            <Spacing size='16' />
            <div className={`dark ${Styles.modules}`}>
              {ForCreatorsCoreModules[section][k].map((m) => (
                <Tag>
                  <OneText type='p12'>
                    <b>{tO(`home.sections.features.concepts.${m}.title`)}</b>
                    &nbsp;
                  </OneText>
                  <HoverableAction onFeature={() => onFeature(m)} />
                </Tag>
              ))}
            </div>
          </div>
        </div>
        <Spacing size='32' />
      </Fragment>
    </LabeledHR>
  )
}

/**
 * Shows the information about creator
 **/
export default function ForCreatorsView({
  onChangedSection,
  onBack,
  onFeature,
}: ForCreatorsViewProps) {
  const [section] = useCreatorsSection()
  const options = useMemo(() => {
    return i18n.t<
      'openspace',
      {
        [id: string]: {
          title: string
          profile: string[]
        }
      }
    >(`home.forCreators.sections.${section}`, {
      returnObjects: true,
    })
  }, [section])

  const transitionRef = useRef<ElementRef<typeof UncontrolledTransition>>(null)
  useInsertionEffect(() => {
    if (section === CreatorsSection.BRAND)
      return () => transitionRef.current!.setOrientation('backward')
  }, [section])

  const schedulerRef = useRef<ElementRef<typeof Scheduler>>(null)
  useEffect(() => {
    const currColor =
      section === CreatorsSection.BRAND
        ? CreatorStyles.BrandColor
        : CreatorStyles.CreatorsColor
    const oldColor = updateThemeColor(currColor)
    return () => {
      updateThemeColor(oldColor)
    }
  }, [section])

  return (
    <>
      <Creators
        mode='heading'
        onChangedSection={onChangedSection}
        onBack={onBack}
      />
      <UncontrolledTransition className={Styles.transition} ref={transitionRef}>
        <Container
          key={section}
          className={`${Styles.content} ${CreatorStyles[section]} ${Styles[section]}`}>
          <Spacing size='32' />
          <Text
            className={Styles.description}
            type={FigmaTypo.H3}
            code={`forCreators.${section}.description`}
          />
          <Spacing size='32' />
          <Text type={FigmaTypo.P16} code='forCreators.mainSolutions' />

          <Fragment>
            {Object.keys(options).map((k) => (
              <CardRow
                key={k}
                k={k}
                title={options[k].title}
                profile={options[k].profile}
                section={section}
                onFeature={onFeature}
              />
            ))}
          </Fragment>
        </Container>
      </UncontrolledTransition>
      <SchedulerBanner onSchedule={() => schedulerRef.current!.open()} />
      <Scheduler ref={schedulerRef} />
      <Spacing size='32' />
      <Spacing size='32' />
    </>
  )
}
