import { DropType } from 'core/logic/drop/drop.types'
import { useUser } from 'core/logic/user'
import DropDetails from './DropDetails.view'

interface DropDetailsLogicProps {
  drop?: DropType | null
  /**
   * If passed will override the single drop price
   */
  price?: number
  title?: boolean
  vertical?: boolean
}
const DropDetailsLogic: React.FC<DropDetailsLogicProps> = ({ drop, title, vertical, price }) => {
  const { detail } = useUser()

  return <DropDetails user={detail} drop={drop} title={title} vertical={vertical} price={price} />
}

export default DropDetailsLogic
