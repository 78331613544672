import { builder } from 'core/helpers'
import * as MainTypes from './main.types'

// == TYPES ==
const START_LOADING = 'main/loading/START'
const STOP_LOADING = 'main/loading/STOP'
export const types = Object.freeze({
  START_LOADING,
  STOP_LOADING,
})

// == ACTIONS ==
const startLoading = () => builder.actionCreator<MainTypes.MainActionsTypes>(START_LOADING)
const stopLoading = () => builder.actionCreator<MainTypes.MainActionsTypes>(STOP_LOADING)

export const actions = Object.freeze({
  startLoading,
  stopLoading,
})

export const mapActions = Object.freeze({
  [START_LOADING]: startLoading,
  [STOP_LOADING]: stopLoading,
})

// == REDUCER ==
export const initialState: Readonly<MainTypes.MainState> = Object.freeze({
  isLoading: false,
})
export const reducer = (
  state: Readonly<MainTypes.MainState> = initialState,
  action: Readonly<MainTypes.MainAction>
): MainTypes.MainState => {
  const { type } = action
  switch (type) {
    case START_LOADING:
      return { ...state, isLoading: true }
    case STOP_LOADING:
      return { ...state, isLoading: false }
    default:
      return state
  }
}
