import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'
import { Colors } from 'augmentation'

const style = (
  theme: Theme & {
    palette: Required<Colors>
  }
) =>
  createStyles({
    img: {
      height: 'auto',
      width: '100%',
    },
    container: {},

    box: {
      maxWidth: theme.breakpoints.values.lg,

      width: '100%',
      display: 'block',
      boxSizing: 'border-box',
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: `0 ${theme.spacing(4)}px`,
      [theme.breakpoints.up('lg')]: {
        padding: `0 ${theme.spacing(22)}px`,
      },
    },
    containerLogo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      paddingTop: theme.spacing(13),
    },
    digitalGarageDescription: {
      fontSize: '1.375rem',
      textAlign: 'center',
      color: theme.palette.primary.light,
      marginTop: 2,
    },
    faqIndexContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    faqIndex: {
      fontSize: '3rem',
      fontWeight: 'bold',
      position: 'relative',
      lineHeight: '80px',
    },
    faqIndexContent: {
      marginLeft: 5,
      fontSize: '1.375rem',
    },
    faqPreviewContainer: {
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% auto',
      [theme.breakpoints.up('md')]: {
        backgroundImage: 'url(/faq/previewBackground.svg)',
      },
      [theme.breakpoints.up('lg')]: {
        backgroundPosition: '0 -8vh',
        marginTop: theme.spacing(12),
      },
    },
    faqPreviewContent: {
      '& img': {
        width: '100%',
        height: 'auto',
      },
      maxWidth: theme.breakpoints.values.lg,
      boxSizing: 'border-box',
      marginLeft: 'auto',
      marginRight: 'auto',
      [theme.breakpoints.up('lg')]: {
        padding: `0 ${theme.spacing(22)}px`,
      },
    },
    faqPreviewItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundPosition: 'center',
      '& img': {
        width: '100%',
        height: 'auto',
        maxWidth: 320,
      },
    },
    faqPreviewItemContent: {
      color: theme.palette.grey['200'],
      fontSize: '0.875rem',
      paddingLeft: 20,
      paddingRight: 20,
    },
    faqPreviewContainerGtRare: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& .previewBackground': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: 'auto',
        zIndex: 1,
      },
      '& .packgt2': {
        position: 'absolute',
        top: 0,
        width: '60%',
        zIndex: 0,
      },
      '& .packgt3': {
        position: 'absolute',
        top: 0,
        width: '60%',
        left: 0,
        zIndex: 2,
      },
    },
    muiAccordionSummary: {
      width: '100%',
      padding: `0 ${theme.spacing(3)}px`,
      '& .MuiAccordionSummary-content': {
        margin: `${theme.spacing(4)}px 0`,
      },
    },
    title: {
      fontSize: '2rem',
      fontWeight: 'bold',
      lineHeight: '40px',
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(6),
      },
    },
    accordionContent: {
    },
    faqAccordion: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: theme.spacing(2),
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(14),
    },
    footer: {
      padding: 0,
      [theme.breakpoints.up('lg')]: {
        paddingLeft: theme.spacing(12),
        paddingRight: theme.spacing(12),
      },
    },
    packgtImg: {
      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(6),
      },
    },
    cardsFootnote: {
      marginTop: theme.spacing(9),
      marginLeft: theme.spacing(2),
      fontSize: 10,
    }
  })
export default makeStylesWrapper(style)
