import { Theme } from '@material-ui/core'
import createStyles from '@material-ui/styles/createStyles'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) => {
  return createStyles({
    oneui: {
      position: 'relative',
    },
    root: {
      textTransform: 'uppercase',
      fontWeight: 600,
      border: `2px solid ${theme.palette.primary.main}`,
      backgroundColor: 'transparent',
      padding: 0,
      transition: 'opacity 250ms linear',
      borderColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        '& > .MuiButton-label': {
          '& .MuiBox-root': {
            color: theme.palette.primary.contrastText,
          },
          '& > .MuiButton-endIcon': {
            borderLeft: `2px solid ${theme.palette.common.white}`,
          },
        },
      },
      '&.Mui-disabled': {
        opacity: '0.4',
        borderWidth: 2,
      },
      '& span': {
        whiteSpace: 'nowrap',
      },
    },
    filledRoot: {
      textTransform: 'uppercase',
      fontWeight: 600,
      border: `2px solid ${theme.palette.primary.main}`,
      backgroundColor: theme.palette.primary.main,
      padding: 0,
      borderColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        '& > .MuiButton-label': {
          '& .MuiBox-root': {
            color: theme.palette.primary.contrastText,
          },
          '& > .MuiButton-endIcon': {
            backgroundColor: theme.palette.primary.light,
          },
        },
      },
    },
    noEndIcon: {
      minHeight: 56,
      '&>.MuiButton-label': {
        marginRight: theme.spacing(4),
      },
    },
    progress: {
      color: theme.palette.background.default,
    },
    labelLight: {
      letterSpacing: 1,
      color: theme.palette.common.white,
      marginLeft: theme.spacing(4),
    },
    label: {
      letterSpacing: 1,
      color: theme.palette.primary.main,
      marginLeft: theme.spacing(4),
    },
    endIcon: {
      transition: `border ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}, background-color ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeInOut}`,
      marginLeft: theme.spacing(4),
      marginRight: -1,
      padding: 17,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderLeft: '2px solid transparent',
    },
  })
}

export default makeStylesWrapper(style)
