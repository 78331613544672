import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'
import parse from 'html-react-parser'
import useStyles from './Description.style'

export interface DescriptionViewProps {
  title?: string
  features?: string
}

const DescriptionView: React.FC<DescriptionViewProps> = ({ title, features }) => {
  const classes = useStyles()

  let description
  if (features) {
    description = (
      <Typography variant='body1' component='p' className={classes.line}>
        {parse(features)}
      </Typography>
    )
  } else {
    description = <Skeleton variant='text' height={20} />
  }

  return (
    <>
      <Divider className={classes.divider} />
      <Typography variant='body1' component='h3' className={classes.title}>
        {title ? title : <Skeleton variant='text' height={20} />}
      </Typography>
      <Box className={classes.description}>{description}</Box>

      <Divider className={classes.divider} />
    </>
  )
}

export default DescriptionView
