import { Box, Divider, Grid, Typography } from '@material-ui/core'
import ActionButton from 'components/ActionButton'
import BackButton from 'components/BackButton'
import { i18n } from 'translate/i18n'
import useStyles from './styles'
import { OfferAuctionBidModalConfirmProps } from './types'

export const OfferAuctionBidModalConfirm: React.FC<
  OfferAuctionBidModalConfirmProps
> = ({ onPrev }) => {
  const classes = useStyles()
  return (
    <>
      <Grid item container direction='column' className={classes.box}>
        <Grid item>
          <Box marginBottom={4}>
            <Typography variant='h3'>
              <strong>
                {i18n.t('components.offerAuctionBidModal.bidAmount')}
              </strong>
            </Typography>
            <Grid></Grid>
            <Grid></Grid>
          </Box>
        </Grid>
      </Grid>
      <Divider />
      <Grid
        item
        container
        justifyContent='space-between'
        className={classes.box}>
        <BackButton
          icon='close'
          text={i18n.t('authenticated.checkout.cancel')}
          onClick={onPrev}
        />
        <ActionButton text={i18n.t('authenticated.checkout.continue')} />
      </Grid>
    </>
  )
}
