import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) =>
  createStyles({
    container: {
      maxWidth: 1060,
      marginBottom: theme.spacing(6),
    },
    figure: {
      width: '100%',
      height: '100%',
      maxWidth: 415,
      margin: 0,
    },
    dropImageWrapper: {
      display: 'flex',
      overflow: 'hidden',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      marginBottom: theme.spacing(3),
      '&::after': {
        content: '""',
        display: 'block',
        paddingBottom: '100%',
      }
    },
    image: {
      maxHeight: 400,
      maxWidth: '100%',
    },
    description: {
      marginBottom: theme.spacing(12),
    },
    availability: {
      [theme.breakpoints.up('md')]: {
        flexBasis: 0,
        minWidth: 300,
      },
    },
    divider: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(7.5),
        marginBottom: theme.spacing(7.5),
      },
    },
    iframe: {
      border: 'none',
      width: '100%',
      display: 'block',
    },
  })

export default makeStylesWrapper(style)
