import React, { ComponentProps, useMemo } from 'react'
import View from './ClaimWarning.view'
import { ClaimWarningProps } from './ClaimWarning.types'
import { AssetType } from 'core/logic/asset/asset.types'
import { useAuthorizations, useChallengeAssets } from 'context/Challenge'
import { useBalanceForAssets } from 'context/MyCollection'
import WalletRequiredWrapper from 'components/WalletRequiredWrapper'

export default function ClaimWarningLogic({
  challenge,
  ...props
}: ClaimWarningProps) {
  const { assets: challengeAssets } = useChallengeAssets(challenge)
  const { assetsWithBalance } = useBalanceForAssets(challengeAssets)
  const itemsThatWillDisappearFromCollection = useMemo(() => {
    const itemsThatWillBeRemoved: AssetType[] = []
    for (let req of challenge.requirements) {
      const assetWithBalance = assetsWithBalance.find(
        (a) => a.id === req.assetId
      )!
      if (req.amount === assetWithBalance.balance) {
        itemsThatWillBeRemoved.push(assetWithBalance)
      }
    }
    return itemsThatWillBeRemoved
  }, [])

  const { loading, galleriesToAuthorize, loadedAllApprovals, approve } =
    useAuthorizations(challenge)
  const galleryApprovalControl = useAuthorizations(challenge)

  const state = useMemo<ComponentProps<typeof View>['state']>(() => {
    if (!loadedAllApprovals) {
      if (galleryApprovalControl.error) return 'error'
      return 'loading'
    } else return 'claim'
  }, [loadedAllApprovals, loading])

  return (
    <WalletRequiredWrapper allowCustody>
      <View
        itemsThatWillDisappearFromCollection={
          itemsThatWillDisappearFromCollection
        }
        galleriesToAuthorize={galleriesToAuthorize}
        state={state}
        onApprove={approve}
        loading={galleryApprovalControl.loading}
        error={galleryApprovalControl.error}
        onRetry={galleryApprovalControl.retry}
        {...props}
      />
    </WalletRequiredWrapper>
  )
}
