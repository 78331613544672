import { i18n } from 'translate/i18n'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import { Hidden } from 'augmentation/mui'
import { DropType } from 'core/logic/drop/drop.types'
import ActionButton from 'components/ActionButton'
import BackButton from 'components/BackButton'
import RarumHeader from 'components/RarumHeader'
import SimpleTopBar from 'components/SimpleTopBar'
import React from 'react'
import DropDetails from './DropDetails'
import useStyles from './OpenDrop.style'

interface ActionsProps {
  onContinue: () => void
  onBack: () => void
}

// Actions area component
const Actions: React.FC<ActionsProps> = ({ onContinue, onBack }) => {
  const { actionsWrapper, actionButton, cancelButton } = useStyles()
  return (
    <Box className={actionsWrapper}>
      <BackButton
        icon='close'
        text={i18n.t('authenticated.openDrop.close')}
        className={cancelButton}
        onClick={onBack}
      />
      <Hidden smDown>
        <ActionButton
          text={i18n.t('authenticated.openDrop.openNow')}
          className={actionButton}
          onClick={onContinue}
        />
      </Hidden>
    </Box>
  )
}

export interface OpenDropProps {
  drop?: DropType | null
  onBack: () => void
  onOpen: () => void
}

const OpenDrop: React.FC<OpenDropProps> = ({ drop, onBack, onOpen }) => {
  const classes = useStyles()

  return (
    <>
      <SimpleTopBar />
      <main className={classes.root}>
        <Container maxWidth='md'>
          <Card className={classes.card}>
            <CardContent>
              <RarumHeader showRarumLogo={false}/>
            </CardContent>
            <Divider className={classes.divider} />
            <CardContent>
              <Box
                display='flex'
                flexDirection='column'
                alignItems='flex-start'
                mt={2}
                mb={2}>
                <DropDetails drop={drop}>
                  <Hidden mdUp>
                    <Box display='flex' flexDirection='column'>
                      <ActionButton
                        text={i18n.t('authenticated.openDrop.openNow')}
                        className={classes.actionButton}
                        onClick={onOpen}
                      />
                    </Box>
                  </Hidden>
                </DropDetails>
              </Box>
            </CardContent>
            <Divider className={classes.divider} />
            <CardContent>
              <Box
                display='flex'
                className={classes.actionsSection}
                mt={2}
                mb={2}>
                <Actions onContinue={onOpen} onBack={onBack} />
              </Box>
            </CardContent>
          </Card>
        </Container>
      </main>
    </>
  )
}

export default OpenDrop
