import LoadingView from 'components/Loading'
import TopBar from 'components/TopBar'
import Headers from 'containers/Headers'
import { BROWSER, PAGE_TRANSITION_DURATION, PLATFORM } from 'core/constants'
import { usePageTracking } from 'core/logic/analytics/analytics.hook'
import routes, { GALLERY_BOUND_ROUTES } from 'core/modules/router'
import { Location } from 'history'
import MyCollection from 'pages/Authenticated/MyCollection'
import CollectionItemDetail from 'pages/Authenticated/MyCollection/CollectionItemDetail'
import OpenDrop from 'pages/Authenticated/OpenDrop'
import OpeningDrop from 'pages/Authenticated/OpeningDrop'
import Payment from 'pages/Authenticated/Payment/V2'
import PendingPayment from 'pages/Authenticated/PendingPayment'
import UserProfile from 'pages/Authenticated/UserProfile'
import WaitingRoom from 'pages/Authenticated/WaitingRoom'
import ConnectView from 'pages/Unauthenticated/Connect'
import Deletion from 'pages/Unauthenticated/Deletion'
import Details from 'pages/Unauthenticated/Drops/Details'
import ListView from 'pages/Unauthenticated/Drops/List/'
import AssetList from 'pages/Unauthenticated/Asset'
import AssetDetail from 'pages/Unauthenticated/Asset/AssetDetail'
import Terms from 'pages/Unauthenticated/Terms'
import FaqView from 'pages/Unauthenticated/FAQ'
import TransferTokensFlow from 'components/TransferTokensFlow/TransferTokensFlow.logic'
import React, {
  Suspense,
  lazy,
  useInsertionEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react'
import {
  Route,
  Switch,
  useLocation,
  matchPath,
  useRouteMatch,
} from 'react-router-dom'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import PrivateRoute from './GuardedRoute/GuardedRoute.view'
import { useStorePreviousLocation } from './MainRouter.hook'
import MarketplaceLogic from 'pages/Unauthenticated/Marketplace/Marketplace.logic'
import BuyOffer from 'pages/Authenticated/Marketplace/BuyOffer'
import CreateOffer from 'pages/Authenticated/Marketplace/CreateOffer'
import { useFeatures } from 'context/Feature'
import { ClaimFlow, ClaimSignUpFlow } from 'core/lazyViews'
import AuthenticatedView from 'pages/Authenticated/Authenticated.view'
import ExtraRoutes from './ExtraRoutes/ExtraRoutes'
import UncontrolledTransition from '@onepercentio/one-ui/dist/components/UncontrolledTransition'
import useMainRouterStyles from './MainRouter.style'
import { TransitionAnimationTypes } from '@onepercentio/one-ui/dist/components/Transition/Transition'
import GalleryDetails from 'openspace/pages/Unauthenticated/GalleryDetails'
import useLanguage from 'openspace/hooks/useLanguage'
import useCustomScrollBar from '@onepercentio/one-ui/dist/hooks/ui/useCustomScrollbar'
import SCSSStyles from './MainRouter.module.scss'
import { debounce } from 'lodash'
import FeatureDetails from 'openspace/pages/Unauthenticated/FeatureDetails/FeatureDetails'
import Creators from 'openspace/pages/Unauthenticated/ForCreators'
import ROUTES from 'core/modules/router'
import ChallengeClaim from 'openspace/pages/Unauthenticated/ChallengeClaim'

const LazyRouteComp = lazy(() =>
  import('./AsyncRoutes/AsyncRoutes').then((r) => ({
    default: ({ route }: { route: keyof typeof r }) => {
      const Comp = r[route]
      return <Comp />
    },
  }))
)

export interface AppViewProps {
  /**
   * Show the block loading app view
   */
  isLoading: boolean
  /**
   * Determines wheter a root level transition should be animated
   */
  animateRoot: (location: Location) => number
}

export interface MainRouterViewProps {
  /**
   * Determines wheter a root level transition should be animated
   */
  animateRoot: (location: Location) => number
}

const AppView: React.FC<AppViewProps> = ({ isLoading, animateRoot }) => {
  return (
    <Suspense fallback={<LoadingView />}>
      <Headers />
      <MainRouterView animateRoot={animateRoot} />
    </Suspense>
  )
}

const goToTop = () => window.scrollTo(0, 0)

const ROUTES_WITHOUT_TOPBAR = [
  routes.authenticated.transferTokens,
  '/user',
  routes.unauthenticated.terms,
  routes.unauthenticated.regulation,
  routes.unauthenticated.deletion,
  routes.unauthenticated.signIn,
  routes.unauthenticated.signUp,
  routes.authenticated.waitingRoom,
  routes.authenticated.checkout,
  routes.authenticated.payment,
  routes.authenticated.pendingPayment,
  routes.authenticated.open,
  routes.authenticated.opening,
  routes.unauthenticated.claimSignup,
  routes.unauthenticated.claim,
]

const MainRouterView: React.FC<MainRouterViewProps> = ({ animateRoot }) => {
  const lng = useLanguage()
  const location = useLocation<Location>()
  const pathPattern = matchPath(location.pathname, [
    ROUTES.unauthenticated.creators,
  ])?.path
  const pathKey = pathPattern || location.pathname
  const salt = useMemo(() => pathKey + Math.random() + lng, [pathKey, lng])

  // Persists the previous location on Local Storage.
  useStorePreviousLocation()

  // Analytics
  usePageTracking()

  const { elRef } = useCustomScrollBar({
    propagate: PLATFORM === 'windows' || PLATFORM === 'linux',
  })

  useInsertionEffect(() => {
    ;(elRef as any).current = document.body
  }, [])

  const isMarketplaceEnabled = useFeatures().marketplace
  const classes = useMainRouterStyles()

  const shouldIncludePadding = !useRouteMatch(ROUTES_WITHOUT_TOPBAR)?.isExact

  return (
    <>
      <Switch location={location}>
        <Route path={ROUTES_WITHOUT_TOPBAR}>{null}</Route>
        <Route path='/'>
          <TopBar marketplace={isMarketplaceEnabled} />
        </Route>
      </Switch>
      <UncontrolledTransition
        id='scrollable'
        className={`${classes.container} ${BROWSER}`}
        transitionType={TransitionAnimationTypes.FADE}
        contentClassName={`${classes.content} ${
          !shouldIncludePadding ? classes.noSpacing : ''
        }`}>
        <RoutesWrapper key={salt} location={location} />
      </UncontrolledTransition>
    </>
  )
}

function RoutesWrapper({
  location,
}: {
  location: ReturnType<typeof useLocation>
}) {
  const nodeRef = useRef<HTMLDivElement>(null)
  const scrollableRef = useRef<HTMLDivElement>(null)
  const isMarketplaceEnabled = useFeatures().marketplace

  useLayoutEffect(() => {
    const el = scrollableRef.current!.parentElement!
    const recover = debounce(() => {
      el.classList.remove(SCSSStyles.blockHover)
    }, 600)
    const cb = () => {
      el.classList.add(SCSSStyles.blockHover)
      recover()
    }
    el.addEventListener('scroll', cb, {
      passive: true,
    })
    return () => {
      el.removeEventListener('scroll', cb)
    }
  }, [])

  return (
    <div ref={scrollableRef}>
      <Switch location={location}>
        <PrivateRoute path={routes.authenticated.transferTokens}>
          <TransferTokensFlow />
        </PrivateRoute>
        <PrivateRoute path='/user'>
          <AuthenticatedView />
        </PrivateRoute>

        <Route path={routes.unauthenticated.faq}>
          <FaqView />
        </Route>
        <Route path={routes.unauthenticated.terms}>
          <Terms termsType='terms' />
        </Route>
        <Route path={routes.unauthenticated.risk}>
          <Terms termsType='risk' />
        </Route>
        <Route path={routes.unauthenticated.deletion}>
          <Deletion />
        </Route>
        <Route path={routes.unauthenticated.creators}>
          <Creators />
        </Route>
        <Route path={routes.unauthenticated.signIn}>
          <ConnectView />
        </Route>
        <Route path={routes.unauthenticated.signUp}>
          <ConnectView />
        </Route>
        <PrivateRoute path={routes.authenticated.waitingRoom}>
          <WaitingRoom
            dropId={location.pathname.substring(
              location.pathname.lastIndexOf('/') + 1
            )}
          />
        </PrivateRoute>
        <PrivateRoute path={routes.authenticated.checkout}>
          <LazyRouteComp route='Checkout' />
        </PrivateRoute>
        <PrivateRoute path={routes.authenticated.payment}>
          <Payment />
        </PrivateRoute>
        <PrivateRoute path={routes.authenticated.pendingPayment}>
          <PendingPayment />
        </PrivateRoute>
        <PrivateRoute path={routes.authenticated.open}>
          <OpenDrop />
        </PrivateRoute>
        <PrivateRoute path={routes.authenticated.opening}>
          <OpeningDrop />
        </PrivateRoute>
        <Route path={routes.unauthenticated.claimSignup}>
          <ClaimSignUpFlow />
        </Route>
        <Route path='/'>
          <Switch key={location.pathname}>
            {ExtraRoutes}
            <Route exact path={'/'}>
              <ListView />
            </Route>
            <SwitchTransition>
              <CSSTransition
                nodeRef={nodeRef}
                key={location.key ?? 'initial'}
                classNames='my-fade'
                timeout={PAGE_TRANSITION_DURATION}
                onEnter={goToTop}>
                <div ref={nodeRef} className='fullHeight scroll-root'>
                  <Switch location={location}>
                    <Route path={routes.unauthenticated.claim}>
                      <ClaimFlow />
                    </Route>
                    <Route path={routes.unauthenticated.featureDetails}>
                      <FeatureDetails />
                    </Route>
                    <Route path={routes.unauthenticated.challengeDetails}>
                      <ChallengeClaim />
                    </Route>
                    <PrivateRoute
                      path={GALLERY_BOUND_ROUTES.authenticated.editOffer}>
                      <CreateOffer />
                    </PrivateRoute>
                    <PrivateRoute
                      path={GALLERY_BOUND_ROUTES.authenticated.offerItemDetail}>
                      <CollectionItemDetail />
                    </PrivateRoute>
                    <Route path={routes.unauthenticated.dropDetails}>
                      <Details />
                    </Route>
                    <PrivateRoute path={routes.authenticated.userAccount} exact>
                      <UserProfile />
                    </PrivateRoute>
                    <PrivateRoute
                      exact
                      path={routes.authenticated.myCollection}>
                      <MyCollection marketplace={isMarketplaceEnabled} />
                    </PrivateRoute>
                    <PrivateRoute
                      exact
                      path={
                        GALLERY_BOUND_ROUTES.authenticated.marketplaceOfferBuy
                      }>
                      <BuyOffer />
                    </PrivateRoute>
                    <PrivateRoute
                      path={
                        GALLERY_BOUND_ROUTES.authenticated.collectionItemDetail
                      }>
                      <CollectionItemDetail />
                    </PrivateRoute>
                    <PrivateRoute
                      path={GALLERY_BOUND_ROUTES.authenticated.sellMarketplace}>
                      <CreateOffer />
                    </PrivateRoute>
                    <Route
                      path={[
                        routes.unauthenticated.assetDetail,
                        GALLERY_BOUND_ROUTES.unauthenticated.assetDetail,
                      ]}>
                      <AssetDetail marketplace={false} />
                    </Route>
                    <Route
                      path={
                        GALLERY_BOUND_ROUTES.unauthenticated
                          .marketplaceAssetDetail
                      }>
                      <AssetDetail marketplace={isMarketplaceEnabled} />
                    </Route>
                    <Route
                      path={[
                        routes.unauthenticated.asset,
                        GALLERY_BOUND_ROUTES.unauthenticated.asset,
                      ]}>
                      <AssetList marketplace={false} />
                    </Route>
                    <Route exact path={routes.unauthenticated.galleryDetail}>
                      <GalleryDetails />
                    </Route>
                    <Route
                      exact
                      path={GALLERY_BOUND_ROUTES.unauthenticated.marketplace}>
                      <MarketplaceLogic />
                    </Route>
                  </Switch>
                </div>
              </CSSTransition>
            </SwitchTransition>
          </Switch>
        </Route>
      </Switch>
    </div>
  )
}

export default AppView
