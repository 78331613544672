import { service } from 'core/modules/firebase'
import { CountersStateType } from './counters.types'

export const fetch = ({
  dropIds,
  emitter,
}: {
  dropIds: string[]
  emitter: (input: CountersStateType) => void
}) => {
  return service.fetchCounters({
    dropIds,
    onSnapshot: (snap) => {
      emitter(snap)
    },
  })
}
