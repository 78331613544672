import React, {
  ComponentProps,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import View from './ClaimConfirmation.view'
import {
  ClaimConfirmationMode,
  ClaimConfirmationProps,
} from './ClaimConfirmation.types'
import { useClaimBenefit } from 'context/MyCollection'
import { Benefit, BenefitWithStatus } from '../Benefits.types'

export default function ClaimConfirmationLogic({
  benefit,
  onClaimed,
  onCancel,
  startingMode,
  open,
}: ClaimConfirmationProps) {
  const [viewMode, setViewMode] =
    useState<ComponentProps<typeof View>['mode']>()
  useLayoutEffect(() => {
    if (open) setViewMode(startingMode)
  }, [open])
  const { claimBenefit, error } = useClaimBenefit()
  const [isCreatingBlockchainTransactionForBenefit, setWaiting] = useState<{
    [k: Benefit['code']]: boolean
  }>({})
  const benefitWithStatus = benefit as BenefitWithStatus

  /** Indicates API has been called and is now waiting for the snapshot to be written */
  const [waitingForSnapshot, setIsWaitingForSnapshot] = useState(false)
  const isCreatingBlockchainTransaction =
    isCreatingBlockchainTransactionForBenefit[benefit.code]

  /** When the snapshot is read (maybe there is a delay because of network) it indicates it is not waiting for the snapshot anymore */
  useEffect(() => {
    if (benefitWithStatus.status) setIsWaitingForSnapshot(false)
  }, [benefitWithStatus.status, waitingForSnapshot])

  useEffect(() => {
    if (open) setIsWaitingForSnapshot(false)
  }, [open])

  const action = useMemo(() => {
    if (viewMode === ClaimConfirmationMode.DETAILS)
      return () => setViewMode(ClaimConfirmationMode.CLAIM)
    if (!benefitWithStatus.status) {
      return () => {
        setWaiting((prev) => ({
          ...prev,
          [benefit.code]: true,
        }))
        setIsWaitingForSnapshot(true)
        claimBenefit(benefitWithStatus)
          .catch(() => {
            setIsWaitingForSnapshot(false)
          })
          .finally(() =>
            setWaiting((prev) => ({
              ...prev,
              [benefit.code]: false,
            }))
          )
      }
    } else return () => onClaimed()
  }, [benefitWithStatus?.status, claimBenefit, viewMode])

  const claimed = useMemo(() => {
    return benefitWithStatus?.status === 'CONFIRMED'
  }, [benefitWithStatus?.status])

  const isLoading = useMemo(() => {
    return (
      waitingForSnapshot ||
      isCreatingBlockchainTransaction ||
      (!!benefitWithStatus.status && benefitWithStatus.status === 'AWAITING_CONFIRMATION')
    )
  }, [isCreatingBlockchainTransaction, benefitWithStatus.status, waitingForSnapshot])


  return (
    <View
      onAction={action}
      loading={isLoading}
      error={error}
      claimed={claimed}
      benefit={benefit}
      onCancel={onCancel}
      mode={viewMode}
      open={open}
      onClosed={() => setViewMode(undefined)}
    />
  )
}
