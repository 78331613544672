import { useEffect, useState } from "react"
import { COUNTDOWN_INTERVAL } from "./contants";
import { OfferCountdownView } from "./OfferCountdownView"
import { OfferCountdownProps } from "./types"

export const OfferCountdown: React.FC<OfferCountdownProps> = ({ expire, begin, decorate = true }) => {
    const [countdown, setCountdown] = useState(0)
    const [progress, setProgress] = useState(0)
    useEffect(() => {
        const timeout = setTimeout(() => {
            const seconds = Math.floor(expire.getTime() - Date.now()) / 1000
            setCountdown(seconds)
            setProgress( seconds / (Math.abs(expire.getTime() - begin.getTime()) / 1000) )
        }, COUNTDOWN_INTERVAL)
        return () => clearTimeout(timeout)
    }, [expire, countdown, begin])
    return (
        <OfferCountdownView countdown={countdown} progress={progress} decorate={decorate} />
    )
}
