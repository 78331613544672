import { useMyCollectionItems } from 'context/MyCollection'
import { actions as assetActions } from 'core/logic/asset/asset.reducer'
import { RootState } from 'core/modules/redux'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AssetState, AssetType } from './asset.types'
import { useItemFromListOrSingle } from '../shared/shared.hook'

export const useAsset = ({ id }: { id?: string }) => {
  const dispatch = useDispatch()
  const { ownedItems } = useMyCollectionItems()
  const { asset, assets } = useSelector<RootState, AssetState>(
    ({ asset }) => asset
  )
  const contextAsset = useMemo(
    () => ownedItems?.find((i) => i.id === id),
    [id, ownedItems]
  )
  const assetItem = useItemFromListOrSingle(
    id,
    asset || contextAsset,
    assets,
    () => {
      dispatch(assetActions.fetchAsset(id!))
    }
  )

  return { asset: assetItem }
}

export const useAssets = () => {
  const dispatch = useDispatch()
  const { galleryId } = useParams<{ galleryId?: string }>()

  const { storeAssets: allAssets, errorKey } = useSelector<
    RootState,
    AssetState
  >(({ asset }) => asset)
  const loading = allAssets === null && !errorKey
  useEffect(() => {
    dispatch(assetActions.fetchAssets())
  }, [dispatch])

  const assets = useMemo(() => {
    return galleryId
      ? allAssets?.filter((a) => a.galleryId === galleryId)
      : allAssets
  }, [allAssets, galleryId])

  return { assets, loading }
}

export const useAssetsByTokens = ({
  tokens,
}: {
  tokens?: AssetType['tokenId'][]
}) => {
  const dispatch = useDispatch()
  const { assets } = useSelector<RootState, AssetState>(({ asset }) => asset)
  useEffect(() => {
    if (tokens) {
      dispatch(assetActions.fetchAssetsByTokens(tokens))
    }
  }, [dispatch, tokens])

  return { assets }
}
