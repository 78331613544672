import { service as mainService } from 'core/logic/main'

const useOffline = () => {
  const { redirect } = mainService
  return {
    redirect,
  }
}

export default useOffline
