import { Box, Grid, Paper, Typography } from '@material-ui/core'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import clsx from 'clsx'
import useStyle from './styles'
import { OfferAuctionBidListViewProps } from './types'
import { i18n, t } from 'translate/i18n'
import { useUser } from 'core/logic/user'
import AnimatedEntrance from '@onepercentio/one-ui/dist/components/AnimatedEntrance'
import { useAuction } from 'context/Auction'
import OnChainAuctionFacade from 'service/IAuctionFacade/OnChainAuctionFacade'
import WalletAvatar from 'components/WalletAvatar/WalletAvatar'
import { shortenWallet } from 'core/helpers/wallet'

export const OfferAuctionBidListView: React.FC<
  OfferAuctionBidListViewProps
> = ({ bids, isExpanded, toogleExpand }) => {
  const classes = useStyle()
  const user = useUser()
  const auction = useAuction()
  const isOnChain = auction instanceof OnChainAuctionFacade

  return (
    <Paper>
      <Grid container>
        <Grid
          item
          container
          justifyContent='space-between'
          alignItems='center'
          className={classes.head}>
          <Grid item>{i18n.t('components.offerAuctionBidList.totalBids')}</Grid>
          <Grid item className='counter'>
            <Box marginRight={1}>
              <Typography variant='h4'>{bids?.length}</Typography>
            </Box>
            <AccountCircleIcon />
            <span onClick={toogleExpand}>
              {isExpanded ? (
                <KeyboardArrowUpIcon fontSize='small' />
              ) : (
                <KeyboardArrowDownIcon fontSize='small' />
              )}
            </span>
          </Grid>
        </Grid>
        {bids && isExpanded && (
          <Grid item container direction='column'>
            <Grid item className={clsx(classes.line, classes.title)}>
              <div className='bid'>
                {i18n.t('components.offerAuctionBidList.bids')}
              </div>
              <div className='date'>
                {i18n.t(
                  isOnChain
                    ? 'components.offerAuctionBidList.wallet'
                    : 'components.offerAuctionBidList.date'
                )}
              </div>
              <div className='bidder'>
                {i18n.t('components.offerAuctionBidList.user')}
              </div>
            </Grid>
            <AnimatedEntrance>
              {bids.slice(0, 10).map((bid, i) => {
                const blockiesSeed =
                  'wallet' in bid.user ? bid.user.wallet : bid.user.id
                return (
                  <Grid
                    item
                    className={clsx(classes.line, classes.bid)}
                    key={bid.amount}>
                    <div className='bid'>{bid.amount}</div>
                    <div className='date'>
                      {isOnChain
                        ? auction.isBidOwned(user.profile, bid as any)
                          ? t('components.offerAuctionBidList.myBid')
                          : 'wallet' in bid.user
                          ? shortenWallet(bid.user.wallet)
                          : ''
                        : bid.created}
                    </div>
                    <div className='bidder'>
                      {auction?.isBidOwned(user.profile, bid) ? (
                        <WalletAvatar wallet={blockiesSeed} />
                      ) : isOnChain ? (
                        <WalletAvatar wallet={blockiesSeed} />
                      ) : null}
                    </div>
                  </Grid>
                )
              })}
            </AnimatedEntrance>
          </Grid>
        )}
      </Grid>
    </Paper>
  )
}
