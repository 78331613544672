import { useQuery } from '@apollo/client'
import { useContextControl } from '@onepercentio/one-ui/dist/context/ContextAsyncControl'
import useUniqueEffect from '@onepercentio/one-ui/dist/hooks/utility/useUniqueEffect'
import { AssetType } from 'core/logic/asset/asset.types'
import {
  fetchAssetsByCIDs,
  fetchAssetsByIDs,
  fetchAssetsByTokens,
} from 'core/modules/firebase/service'
import { TOKEN_OWNERS } from 'core/modules/graphql'
import {
  createContext,
  useContext,
  PropsWithChildren,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react'

type QueryFieldByType = {
  token: AssetType['tokenId'][]
  cid: AssetType['ipfs']['jsonHash'][]
  id: AssetType['id'][]
}

export type AssetContextShape = {
  assets: AssetType[]
  loadAssets(
    ...args:
      | [by: 'token', tokenIds: QueryFieldByType['token']]
      | [by: 'cid', cids: QueryFieldByType['cid']]
      | [by: 'id', ids: QueryFieldByType['id']]
  ): Promise<void>
}
export const AssetContext = createContext<AssetContextShape>(null as any)

type QueryMap = { tokenIds: number[]; cids: string[]; ids: string[] }
/**
 * Manages asset objects
 * @param param0
 * @returns
 */
export default function AssetProvider({ children }: PropsWithChildren<{}>) {
  const { current: inexistingAssets } = useRef<typeof existingAssets>({
    tokenIds: [],
    cids: [],
    ids: [],
  })
  const [loadedAssets, setLoadedAssets] = useState<AssetType[]>([])
  const existingAssets = useMemo(
    () =>
      loadedAssets.reduce(
        (r, a) => ({
          tokenIds: [...r.tokenIds, a.tokenId],
          cids: [...r.cids, a.ipfs?.jsonHash],
          ids: [...r.ids, a.id],
        }),
        { tokenIds: [], cids: [], ids: [] } as QueryMap
      ),
    [loadedAssets]
  )
  const fetchAssets = useCallback<AssetContextShape['loadAssets']>(
    async (by, query) => {
      let newAssets: AssetType[]
      const matchBy: <Q extends keyof QueryMap>(key: Q) => QueryMap[Q] = (
        k
      ) => {
        const filter = query.filter(
          (match) =>
            !existingAssets[k].includes(match as never) &&
            !inexistingAssets[k].includes(match as never)
        ) as any[]

        return filter
      }
      switch (by) {
        case 'token':
          newAssets = await fetchAssetsByTokens({
            tokenIds: matchBy('tokenIds'),
          })
          break
        case 'cid':
          newAssets = await fetchAssetsByCIDs(matchBy('cids'))
          break
        case 'id':
          newAssets = await fetchAssetsByIDs(matchBy('ids'))
          break
      }
      if (newAssets.length) setLoadedAssets((prev) => [...prev, ...newAssets])
      switch (by) {
        case 'cid':
          const newCids = newAssets.map((a) => a.ipfs.jsonHash)
          inexistingAssets.cids.push(
            ...query.filter((cid) => !newCids.includes(cid))
          )
          break
        case 'id':
          const newIds = newAssets.map((a) => a.id)
          inexistingAssets.ids.push(
            ...query.filter((cid) => !newIds.includes(cid))
          )
          break
        case 'token':
          const newTokens = newAssets.map((a) => a.tokenId)
          inexistingAssets.tokenIds.push(
            ...query.filter((cid) => !newTokens.includes(cid))
          )
          break
      }
    },
    [existingAssets]
  )
  return (
    <AssetContext.Provider
      value={{
        assets: loadedAssets,
        loadAssets: fetchAssets,
      }}>
      {children}
    </AssetContext.Provider>
  )
}

interface UseAsset {
  (by: 'token', tokenId: number): AssetType | undefined
  (by: 'id', assetId: string): AssetType | undefined
}

export const useAsset: UseAsset = (by, tokenId) => {
  const { assets } = useContext(AssetContext)
  switch (by) {
    case 'token':
      return assets.find((a) => a.tokenId === tokenId)
    case 'id':
      return assets.find((a) => a.id === tokenId)
    default:
      throw new Error(`Can't filter token by "${by}"`)
  }
}

export const useAssets = (assetIds: AssetType['id'][]) => {
  const assets = useContext(AssetContext).assets
  const filteredAssets = useMemo(
    () => assets.filter((a) => assetIds.includes(a.id)),
    [assetIds, assets]
  )
  return filteredAssets
}

export function useAssetsQuery<Q extends 'id' | 'token' | 'cid'>(by: Q) {
  const ctx = useContext(AssetContext)
  const control = useContextControl(`load-assets-${by}`, {
    loadAssets: (match: QueryFieldByType[Q]) =>
      ctx.loadAssets(by, match as any),
  })

  return control
}

export function useAssetsBy(
  ...args:
    | [by: 'token', tokenIds: AssetType['tokenId'][]]
    | [by: 'cid', cids: AssetType['ipfs']['jsonHash'][]]
    | [by: 'id', ids: AssetType['id'][]]
) {
  const ctx = useContext(AssetContext)

  const { assets, assetsToLoad } = useMemo(() => {
    let remainingMatches = [...args[1]] as any[]
    const resultingAssets = [] as AssetType[]

    if (ctx.assets)
      for (let asset of ctx.assets) {
        let matchWith: any
        switch (args[0]) {
          case 'token':
            matchWith = asset.tokenId
            break
          case 'cid':
            matchWith = asset.ipfs?.jsonHash
            break
          case 'id':
            matchWith = asset.id
        }
        let found: boolean = remainingMatches.includes(matchWith)
        if (found) {
          resultingAssets.push(asset)
          remainingMatches = remainingMatches.filter((a) => a !== matchWith)
        }
      }

    return {
      assets: resultingAssets,
      assetsToLoad: remainingMatches as (typeof args)[1],
    }
  }, [ctx.assets, ...args])

  const allAssetsLoaded = useMemo(() => {
    return assets.length === args[1].length
  }, [assets, args[1]])

  const loadAssetBy = useAssetsQuery(args[0])
  useUniqueEffect(
    `asset-load-${args[0]}`,
    () => {
      if (assetsToLoad.length && !loadAssetBy.loading)
        loadAssetBy.loadAssets(assetsToLoad as any)
    },
    [assetsToLoad.length, loadAssetBy.loading]
  )

  return {
    assets,
    allAssetsLoaded,
    loading: loadAssetBy.loading,
    error: loadAssetBy.error,
    retry: () => loadAssetBy.loadAssets(assetsToLoad as any),
  }
}

export function useAssetOwners() {
  const control = useQuery<{
    tokens: { holders: { wallet: { id: string } }[]; id: string }[]
  }>(TOKEN_OWNERS)
  const ownersByTokenId = useMemo(() => {
    if (!control.data) return control.data
    return control.data.tokens.reduce(
      (map, token) => ({
        ...map,
        [token.id]: token.holders.map((h) => h.wallet.id),
      }),
      {} as {
        [tokenId: string]: string[]
      }
    )
  }, [control.data])
  return {
    ownersByTokenId,
    loading: control.loading,
    error: control.error,
    retry: control.refetch,
  }
}
