export type ClaimAuctionItemProps = {
};
export type ClaimAuctionItemViewProps = {
    status: ClaimAuctionStatus
    onAction?: () => void
    loading: boolean,
    error: boolean
};
export enum ClaimAuctionStatus {
    /** When the user has not connected it's wallet yet */
    DISCONNECTED_WALLET = 'dw',
    /** Used when an action is pending */
    LOADING = 'l',
    /** Used when is not winner yet  */
    NOT_WINNER = 'nw',
    /** Pending token allowance approval */
    PENDING_ALLOWANCE = 'pa',
    /** Pending claim of the NFT */
    CLAIMABLE = 'c',
    /** The user has already claimed */
    CLAIMED = 'cd'
}