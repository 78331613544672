import { i18n } from 'translate/i18n'
import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import { formattedTime } from 'core/helpers/formatter'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import { Hidden } from 'augmentation/mui'
import Typography from '@material-ui/core/Typography'
import { DropType } from 'core/logic/drop/drop.types'
import { PurchaseType } from 'core/logic/purchase/purchase.types'
import { differenceInSeconds, isFuture } from 'date-fns'
import PaymentExpired from 'assets/images/payment-expired.png'
import BackButton from 'components/BackButton'
import StripeContainer from 'containers/Stripe/Stripe.container'
import React, { useEffect, useState } from 'react'
import DropInfoMobile from '../Payment/DropInfoMobile'
import Pix from './Methods/Pix.logic'
import Crypto from './Methods/Crypto.view'
import useStyles from './PendingPayment.style'
import OfferPaymentBanner from 'openspace/components/OfferPaymentBanner'

const calculateTimeLeft = (date: Date) => {
  if (isFuture(date)) return differenceInSeconds(date, new Date())
  else return 0
}

const loading = (
  <Box data-testid='loader' display='flex' flexGrow={1} alignItems='center'>
    <CircularProgress />
  </Box>
)
export interface PaymentViewProps {
  drop?: DropType | null
  purchase?: PurchaseType | null
  uuid: string
  onMyCollection: () => void
}

const PaymentView: React.FC<PaymentViewProps> = ({
  drop,
  purchase,
  onMyCollection,
  uuid,
}) => {
  const classes = useStyles()
  const expired = (
    <Box
      display='flex'
      flexDirection='column'
      flexGrow={1}
      justifyContent='center'
      alignItems='center'
      className={classes.expiredContainer}
      p={6}>
      <Box mb={4}>
        <img
          width={116}
          height={147}
          src={PaymentExpired}
          title={i18n.t('authenticated.pendingPayment.expiredPayment')}
          alt={i18n.t('authenticated.pendingPayment.expiredPayment')}
        />
      </Box>
      <Typography
        variant='body1'
        component='p'
        align='center'
        className={classes.spacing}>
        <strong>
          {i18n.t('authenticated.pendingPayment.expiredPurchase')}
        </strong>
      </Typography>
      <Typography variant='body1' component='p' align='center'>
        {i18n.t('authenticated.pendingPayment.tryAgain')}
      </Typography>
    </Box>
  )

  const [timeLeft, setTimeLeft] = useState<number>()

  useEffect(() => {
    const timeout = undefined
    if (purchase) {
      if (!timeLeft) {
        setTimeLeft(calculateTimeLeft(purchase.expireAt))
      } else if (timeLeft > 0) {
        setTimeout(() => {
          setTimeLeft(calculateTimeLeft(purchase.expireAt))
        }, 1000)
      } else {
        // TODO: Handle expired purchase
      }
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [purchase, timeLeft])

  const [content, setContent] = useState<
    'loading' | 'pix' | 'pix_mp' | 'credit' | 'crypto' | 'expired'
  >('loading')

  useEffect(() => {
    if (timeLeft === undefined || purchase?.paymentStatus === 'PROCESSING') {
      setContent('loading')
    } else {
      if (timeLeft <= 0) {
        setContent('expired')
      } else if (purchase && purchase.charge.paymentType === 'BOLETO_PIX') {
        setContent('pix')
      } else if (purchase && purchase.charge.paymentType === 'PIX') {
        setContent('pix_mp')
      } else if (purchase && purchase.charge.paymentType === 'CREDIT_CARD') {
        setContent('credit')
      } else if (purchase && purchase.charge.paymentType === 'CRYPTO') {
        setContent('crypto')
      }
    }
  }, [timeLeft, purchase])

  return (
    <>
      <Container className={classes.container}>
        <main style={{ display: 'flex' }}>
          <div className={classes.paymentPane}>
            <Box className={classes.spacing}>
              <Typography variant='h2' component='h1'>
                {purchase &&
                  ['BOLETO_PIX', 'PIX'].includes(purchase.charge.paymentType) &&
                  i18n.t('authenticated.pendingPayment.payWithPix')}
                {purchase &&
                  purchase.charge.paymentType === 'CREDIT_CARD' &&
                  i18n.t('authenticated.pendingPayment.payWithCreditCard')}
                {purchase &&
                  purchase.charge.paymentType === 'CRYPTO' &&
                  i18n.t('authenticated.pendingPayment.payWithCryptoCoin')}
                &nbsp;
              </Typography>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              flexGrow={1}>
              <Box className={classes.spacing}>
                {content !== 'crypto' && content !== 'loading' && (
                  <Chip
                    variant='outlined'
                    label={
                      timeLeft
                        ? `${i18n.t(
                            'authenticated.pendingPayment.timeLeft'
                          )} ${formattedTime(timeLeft)}`
                        : i18n
                            .t('authenticated.pendingPayment.expiredPayment')
                            .toLowerCase()
                    }
                  />
                )}
              </Box>
              {content === 'loading' && loading}
              {['pix', 'pix_mp'].includes(content) && purchase && (
                <Pix purchase={purchase} />
              )}
              {content === 'credit' && purchase && (
                <StripeContainer purchase={purchase} />
              )}
              {content === 'crypto' && purchase && (
                <Crypto
                  purchaseCode={purchase.charge.code}
                  uuid={uuid}
                  onModalClose={onMyCollection}
                />
              )}
              {content === 'expired' && expired}
            </Box>
            <Hidden mdUp>
              <Box mt={2} mb={2}>
                <DropInfoMobile drop={drop} />
              </Box>
            </Hidden>
          </div>
          <Hidden smDown>
            <OfferPaymentBanner
              asset={drop?.asset}
              paymentProvider={purchase?.provider}
            />
          </Hidden>
        </main>
        <Box alignSelf='flex-start'>
          <BackButton
            text={i18n.t('authenticated.pendingPayment.myCollection')}
            className={classes.spacing}
            onClick={onMyCollection}
          />
        </Box>
      </Container>
    </>
  )
}

export default PaymentView
