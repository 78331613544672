import {
  FormControlLabel,
  Grid,
  Typography,
  Checkbox,
} from '@material-ui/core'
import { BaseSettleOperationView } from 'pages/Authenticated/Marketplace/SettleOperation/SettleOperation.view'
import { useMemo } from 'react'
import { t } from 'translate/i18n'
import useStyles from './TransferTokensFlow.styles'
import { TransferTokensFlowStates } from './TransferTokensFlow.types'
import useSettleOperationStyles from 'pages/Authenticated/Marketplace/SettleOperation/SettleOperation.styles'
import { RarumUserProfile } from 'core/logic/user/user.types'
import clsx from 'clsx'
import Button from '@onepercentio/one-ui/dist/components/Button'

export default function TransferTokensFlowView({
  status,
  actions,
  understood,
  user,
  understoodDanger,
}: {
  user: RarumUserProfile
  status: TransferTokensFlowStates
  actions: {
    onStartOperation: () => void
    onGoToMarketplace: () => void
    onUnderstoodLimitations: (understood: boolean) => void
    onUnderstoodDanger: (u: boolean) => void
    onSettleTransfer: () => void
    onCancel: () => void
  }
  understood: boolean
  understoodDanger: boolean
}) {
  const classes = useSettleOperationStyles()
  const viewClasses = useStyles()
  const content = useMemo(() => {
    switch (status) {
      case TransferTokensFlowStates.REQUIRED_CONFIRMATION:
      case TransferTokensFlowStates.CONFIRMED_OPERATION:
        const baseTranslation =
          status === TransferTokensFlowStates.REQUIRED_CONFIRMATION
            ? 'waitingConfirmation'
            : 'operationStarted'
        return (
          <div className={`${classes.root} ${viewClasses.reset}`}>
            <BaseSettleOperationView
              loading={false}
              error={false}
              data={{
                title: t(
                  `walletRequiredControl.transferWallet.${baseTranslation}.title`
                ),
                description: t(
                  `walletRequiredControl.transferWallet.${baseTranslation}.description`,
                  {
                    email: user.email,
                  }
                ),
                action: {
                  label: t(
                    `walletRequiredControl.transferWallet.${baseTranslation}.actions.understood`
                  ),
                  onClick: () => Promise.resolve(actions.onGoToMarketplace()),
                },
              }}
            />
          </div>
        )
      case TransferTokensFlowStates.FROM_EMAIL:
        return (
          <>
            <Typography variant='h2'>
              {t('walletRequiredControl.transferWallet.fromEmailLink.title')}
            </Typography>
            <Typography variant='body1'>
              {t(
                'walletRequiredControl.transferWallet.fromEmailLink.description'
              )}
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  color='primary'
                  onChange={({ target: { checked } }) => {
                    actions.onUnderstoodLimitations(checked)
                  }}
                />
              }
              className={viewClasses.form}
              label={t(
                'walletRequiredControl.transferWallet.fromEmailLink.actions.understoodLimitations'
              )}
            />
            <FormControlLabel
              control={
                <Checkbox
                  onChange={({ target: { checked } }) =>
                    actions.onUnderstoodDanger(checked)
                  }
                />
              }
              className={clsx(viewClasses.form, viewClasses.error)}
              label={t(
                'walletRequiredControl.transferWallet.fromEmailLink.actions.understoodDanger'
              )}
            />
            <Grid container wrap='wrap' justifyContent='center'>
              <Grid item>
                <Button variant='transparent' onClick={actions.onCancel}>
                  {t(
                    'walletRequiredControl.transferWallet.fromEmailLink.actions.cancel'
                  )}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant='filled'
                  disabled={!understood || !understoodDanger}
                  onClick={actions.onSettleTransfer}>
                  {t(
                    'walletRequiredControl.transferWallet.fromEmailLink.actions.confirm'
                  )}
                </Button>
              </Grid>
            </Grid>
          </>
        )
      case TransferTokensFlowStates.TUTORIAL:
        return (
          <>
            <Typography variant='h2'>
              {t('walletRequiredControl.migrationRequired.title')}
            </Typography>
            <Typography variant='body1'>
              {t('walletRequiredControl.migrationRequired.description')}
            </Typography>
            <Grid container wrap='wrap' justifyContent='center'>
              <Grid item>
                <Button variant='transparent' onClick={actions.onCancel}>
                  {t(
                    'walletRequiredControl.transferWallet.intro.actions.cancel'
                  )}
                </Button>
              </Grid>
              <Grid item>
                <Button variant='filled' onClick={actions.onStartOperation}>
                  {t(
                    'walletRequiredControl.transferWallet.intro.actions.startOperation'
                  )}
                </Button>
              </Grid>
            </Grid>
          </>
        )
    }
  }, [status, understood, understoodDanger])
  return <div className={viewClasses.root}>{content}</div>
}
