import useFirestoreWatch from '@onepercentio/one-ui/dist/hooks/useFirestoreWatch'
import { ChatReply, ChatThread } from 'features/ChatRoom/ChatRoom.types'
import { PropsWithChildren, createContext, useContext, useMemo } from 'react'
import { createThread, watchChatRoom } from 'core/modules/firebase/service'
import useAsyncControl from '@onepercentio/one-ui/dist/hooks/useAsyncControl'

export type ChatRoomContextShape = {
  postThread(thread: Omit<ChatThread, 'id' | 'roomId'>): Promise<void>
  reply(reply: Omit<ChatReply, 'id' | 'roomId'>): Promise<void>
  roomId: string;
  threads: ChatThread[] | undefined
}
export const ChatRoomContext = createContext<ChatRoomContextShape>(null as any)

export const ChatRoomProvider = ({
  roomId,
  children,
}: PropsWithChildren<{ roomId: string }>) => {
  const chatEntries = useFirestoreWatch(watchChatRoom, [roomId])
  
  const threads = useMemo(() => {
    if (!chatEntries) return undefined
    const { replies, threads } = chatEntries.reduce(
      (r, e) => {
        if ('threadId' in e) r.replies.push(e)
        else r.threads.push(e)
        return r
      },
      { replies: [] as ChatReply[], threads: [] as ChatThread[] }
    )
    return threads.map((t) => ({
      ...t,
      replies: replies.filter((r) => r.threadId === t.id),
    }))
  }, [chatEntries])

  return (
    <ChatRoomContext.Provider
      value={{
        roomId,
        threads: threads,
        postThread: async (thread) => {
          await createThread({
            ...thread,
            roomId,
          })
        },
        reply: async (thread) => {
          await createThread({
            ...thread,
            roomId,
          })
        },
      }}>
      {children}
    </ChatRoomContext.Provider>
  )
}

export function useChatRoom() {
  return useAsyncControl(useContext(ChatRoomContext))
}
