import React, { useRef } from 'react'
import Styles from './Features.module.scss'
import StaticScroller from '@onepercentio/one-ui/dist/components/StaticScroller'
import Container from 'openspace/components/Container/Container'
import Text from 'openspace/components/Text/Text'
import { FigmaTypo } from 'containers/OneUIProvider/OneUIProvider'
import LabeledHR from 'openspace/components/LabeledHR/LabeledHR'
import Button from '@onepercentio/one-ui/dist/components/Button'
import { EscapedTranslation, tO } from 'translate/i18n'
import { Controls } from 'openspace/components/BottomPaginationControls/BottomPaginationControls'
import Spacing from '@onepercentio/one-ui/dist/components/Spacing/Spacing'
import { ReactComponent as Deco } from 'openspace/assets/svg/icons/big-arrow-right.svg'
import useSnapToViewport from '@onepercentio/one-ui/dist/hooks/ui/useSnapToViewport'
import FeatureHead from 'openspace/components/FeatureHead/FeatureHead'
import { useFeatures } from './Features.hook'
import { useHistory } from 'react-router-dom'
import { TO_FEATURE_DETAILS } from 'core/modules/router'

export type AvailableConcepts =
  | 'marketplace'
  | 'membership'
  | 'social'
  | 'gamification'
  | 'smart_tickets'
  | 'digital_twins'
  | 'shared_ownership'
  | 'loyalty_coins'

/**
 * Shows the features our application provides
 **/
export default function Features({
  onScheduleDemo,
}: {
  onScheduleDemo: () => void
}) {
  const ref = useRef<HTMLDivElement>(null)

  const scrollerRef = useSnapToViewport(8)

  const concepts = useFeatures()
  const history = useHistory()

  return (
    <section ref={ref} className={Styles.root}>
      <Container>
        <StaticScroller ref={scrollerRef}>
          <div className={Styles.intro}>
            <Text type={FigmaTypo.P12} code='topbar.menus.about' />
            <Spacing size='32' />
            <b className={Styles.introTitle}>
              <Text
                type={FigmaTypo.H1}
                code='home.sections.features.description'
              />
            </b>
            <LabeledHR label=''>
              <b>
                <Text type={FigmaTypo.H2} code='generic.brandMotto' />
              </b>
            </LabeledHR>
            <Spacing size='32' />
            <p>
              <EscapedTranslation
                className={Styles.benefitLabel}
                code='home.sections.features.benefit'
              />
            </p>
            <Deco />
          </div>
          {concepts.map((concept) => (
            <div className={`${Styles.featureCard} light`}>
              <FeatureHead feature={concept} />
              <main>
                <Text
                  type={FigmaTypo.P12}
                  code='home.sections.features.mainFeatures'
                />
                <ul>
                  {concept.features.map((f) => (
                    <li className={Styles.feature}>{f}</li>
                  ))}
                </ul>
                <Button
                  variant='transparent'
                  onClick={() =>
                    history.push(TO_FEATURE_DETAILS(concept.concept))
                  }
                  color='primary'>
                  {tO('generic.knowMore')}
                </Button>
              </main>
            </div>
          ))}
        </StaticScroller>
        <div className={Styles.actions}>
          <Button variant='filled' onClick={() => onScheduleDemo()}>
            {tO('home.sections.features.actions.schedule')}
          </Button>
          <Controls
            onClick={(direction) => {
              const scrollerDiv = ref.current!.firstChild!
                .firstChild as HTMLDivElement
              const firstChild = scrollerDiv.children[0] as HTMLDivElement
              const secondChild = scrollerDiv.children[1] as HTMLDivElement
              if (scrollerDiv.scrollLeft === 0 && direction === 'right')
                scrollerDiv.scrollTo({
                  left: scrollerDiv.scrollLeft + firstChild.clientWidth,
                  behavior: 'smooth',
                })
              else if (
                scrollerDiv.scrollLeft <=
                  firstChild.clientWidth + secondChild.clientWidth &&
                direction === 'left'
              )
                scrollerDiv.scrollTo({
                  left: -scrollerDiv.scrollLeft,
                  behavior: 'smooth',
                })
              else
                scrollerDiv.scrollTo({
                  left:
                    scrollerDiv.scrollLeft +
                    (direction === 'right'
                      ? secondChild.clientWidth + 22
                      : -(secondChild.clientWidth + 22)),
                  behavior: 'smooth',
                })
            }}
          />
        </div>
      </Container>
    </section>
  )
}
