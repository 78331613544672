import { OfferType } from 'core/logic/drop/drop.types'
import { fetchCounters } from 'core/modules/firebase/service'
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

type CountersContextShape = {
  watchCounters(offerIds: OfferType['id'][]): () => void
  counters: {
    [offerId: OfferType['id']]: number | undefined
  }
  watching: OfferType['id'][]
}

export const CountersContext = createContext<CountersContextShape>(null as any)

export default function CountersProvider({ children }: PropsWithChildren<{}>) {
  const [toWatch, setToWatch] = useState<CountersContextShape['watching']>([])
  const [counters, setCounters] = useState<CountersContextShape['counters']>({})
  const watchCounters = useCallback<CountersContextShape['watchCounters']>(
    (offerIds) => {
      setToWatch((prev) => Array.from(new Set([...prev, ...offerIds])))
      return () =>
        setToWatch((prev) =>
          prev.filter((watching) => !offerIds.includes(watching))
        )
    },
    []
  )

  useEffect(() => {
    return fetchCounters({
      dropIds: toWatch,
      onSnapshot(snap) {
        setCounters((prev) => ({
          ...prev,
          ...snap,
        }))
      },
    })
  }, [toWatch.join('')])

  return (
    <CountersContext.Provider
      value={{ watchCounters, watching: toWatch, counters }}>
      {children}
    </CountersContext.Provider>
  )
}

export function useCounterForOffer(offerId: OfferType['id']) {
  const { watchCounters, counters } = useContext(CountersContext)
  useEffect(() => {
    return watchCounters([offerId])
  }, [])

  return counters[offerId]
}
