import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'
import emptyImage from 'assets/images/empty-collection.webp'

const style = (theme: Theme) =>
  createStyles({
    figure: {
      background: `url(${emptyImage}) no-repeat center center`,
      backgroundSize: 'contain',
      width: '100%',
      height: 400,
    },
    heading: {
      marginTop: theme.spacing(2),
    },
    button: {
      marginTop: theme.spacing(6),
      width: 300,
      maxWidth: '100%',
    },
  })

export default makeStylesWrapper(style)
