import { ReactElement } from 'react'

export function useTenantRequirements(): {
  checked: boolean
  requirements: ReactElement[]
  isCheckRequired: boolean
} {
  return {
    checked: true,
    requirements: [],
    isCheckRequired: false
  }
}
