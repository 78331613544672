import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  btn: {
    display: 'flex',
    alignItems: 'center',
  },
  root: {
    padding: '32px 0px',
    minHeight: '100%',
    width: '100%',
    alignItems: 'flex-start',
    '& > *': {
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  innerContent: {
    '& button': {
      padding: '8px 34px',
      margin: '0px 10px',
    },
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  content: {
    '& > p': {
      maxWidth: '482px',
      textAlign: 'center',
    },
    '& > a': {
      marginTop: '40px',
      marginBottom: '70px',
      color: theme.palette.text.primary,
      fontSize: '1rem',
    },
    '& > h2': {
      marginBottom: '16px',
    },
  },
}))
