import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) =>
  createStyles({
    closeButton: {
      alignSelf: 'flex-start',
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    title: {
      fontSize: '1.25rem',
      marginBottom: theme.spacing(4),
    },
    modalParagraph: {
      marginBottom: theme.spacing(4),
    },
    termsBody: {
      whiteSpace: "pre-wrap"
    }
  })
export default makeStylesWrapper(style)
