import { useState } from 'react'

import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import ActionButton from 'components/ActionButton'
import {
  Box,
  Grid,
  useTheme,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import useStyles from '../../pages/Authenticated/PendingPayment/PendingPayment.style'
import { Alert } from '@material-ui/lab'
import IconRarum from 'components/IconRarum'
import Snackbar from '@material-ui/core/Snackbar'
import { i18n } from 'translate/i18n'
interface CheckoutFormProps {
  purchaseId: string
}
const CheckoutForm = ({ purchaseId }: CheckoutFormProps) => {
  const stripe = useStripe()
  const elements = useElements()
  const theme = useTheme()
  const classes = useStyles()
  const [message, setMessage] = useState<any>()
  const [isLoading, setIsLoading] = useState(false)
  const [isStripeReady, setIsStripeReady] = useState(false)
  const [snackOpen, setSnackOpen] = useState(false)

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    if (!stripe || !elements) {
      return
    }

    setIsLoading(true)

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/payment/${purchaseId}?payed=true`,
      },
    })

    setSnackOpen(true)
    setMessage('Pagamento efetuado com sucesso!')

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message)
    } else {
      setMessage('Um erro inesperado ocorreu.')
    }
    setIsLoading(false)
  }

  return (
    <>
      <div className={classes.formContainer}>
        <Box m={2} p={3} paddingBottom={3} className={classes.spacing}>
          <Typography variant='h4' component='h1'>
            <b>{i18n.t('authenticated.payment.data.creditCardSubtitle')}</b>
          </Typography>
          
        </Box>
        <Box marginTop={2} alignSelf='center'>
          <form id='payment-form' onSubmit={handleSubmit}>
            <main style={{ display: 'flex' }}>
              <div className={classes.stripePaymentPane}>
                {!isStripeReady && (
                  <CircularProgress size={24} className={classes.spinner} />
                )}
                <PaymentElement
                  id='payment-element'
                  onReady={() => setIsStripeReady(true)}
                />
              </div>
            </main>
            <Grid container direction='row' justifyContent='center' xl>
              <Grid item>
                <Box p={5} paddingBottom={3}>
                  <ActionButton
                    type='submit'
                    text={i18n.t('authenticated.payment.data.confirmAndPay')}
                    disabled={
                      !isStripeReady || isLoading || !stripe || !elements
                    }
                    id='submit'></ActionButton>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </div>
      <Snackbar
        open={snackOpen}
        key='snack-error-purchase'
        onClose={() => setSnackOpen(false)}
        message={message}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={4000}>
        <Alert
          icon={
            <IconRarum
              icon='alert'
              color={theme.palette.error.main}
              size={24}
            />
          }
          onClose={() => setSnackOpen(false)}
          severity='error'
          classes={{ root: classes.alert }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  )
}

export default CheckoutForm
