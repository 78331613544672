import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) =>
  createStyles({
    section: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      '&>:first-child': {
        alignSelf: 'flex-end',
      },
    },
    divider: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    title: {
      marginBottom: theme.spacing(4),
    },
    units: {
      fontWeight: 600,
    },
    description: {
      marginBottom: theme.spacing(4),
    },
    price: {
      marginBottom: theme.spacing(4),
    },
    action: {
      alignSelf: 'flex-start',
    },
  })

export default makeStylesWrapper(style)
