import { makeStyles } from '@material-ui/core'

const useBuyOfferStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '720px',
    backgroundColor: theme.palette.background.paper,
    '& h2': {
      marginBottom: '32px',
    },
    '& h4:not(:first-child)': {
      marginTop: '20px',
    },
    '& > *': {
      padding: '60px 40px',
      '&:first-child, &:last-child': {
        flexWrap: 'wrap',
        padding: '40px',
      },
      ' &:last-child': {
        justifyContent: 'flex-start',
        '& > *': {
          width: '100%',
          maxWidth: '250px',
          marginRight: '16px',
        },
      },
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  oneMoreCustomButton: {
    padding: '20px',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderTop: `2px solid ${theme.palette.sky}`,
    borderBottom: `2px solid ${theme.palette.sky}`,
    '& > :last-child': {
      alignItems: 'flex-start',
    },
  },
}))

export default useBuyOfferStyles
