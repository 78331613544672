import React, { useState } from 'react'
import Styles from './Contact.module.scss'
import Loader from '@onepercentio/one-ui/dist/components/Loader/Loader'
import UncontrolledTransition from '@onepercentio/one-ui/dist/components/UncontrolledTransition/UncontrolledTransition'
import OneText from '@onepercentio/one-ui/dist/components/Text/Text'
import { EscapedTranslation, i18n } from 'translate/i18n'
import Text from 'openspace/components/Text/Text'
import { FigmaTypo } from 'containers/OneUIProvider/OneUIProvider'
import { TransitionAnimationTypes } from '@onepercentio/one-ui/dist/components/Transition/Transition'
import HSForms from '@onepercentio/one-ui/dist/components/HSForms/HSForms'
import { useOneUIConfig } from '@onepercentio/one-ui/dist/context/OneUIProvider'

const ContactForm: {
  [l in LanguageCodes]: string
} = {
  'en-US': '60824a70-0294-4a05-8247-5d0f5b1423c0',
  'es-ES': 'c5a577b2-5b56-401c-b5cf-84810b613d07',
  'pt-BR': '0990d663-d2f9-454d-9a38-4c0e05976512',
}

/**
 * Shows the contact form
 **/
export default function Contact() {
  const [loaded, setLoaded] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const classForInput = useOneUIConfig('component.text.className.content')
  const classForP16 = useOneUIConfig('component.text.className.content')
  const classForButton = useOneUIConfig('component.button.className.filled')

  return (
    <section className={Styles.root}>
      <Text type='p40_v2' code='home.sections.contactUs.form.title' />
      <Text type='content' code='home.sections.contactUs.form.description' />
      {!loaded ? <Loader key='l' /> : null}
      <UncontrolledTransition
        transitionType={TransitionAnimationTypes.FADE}
        className={Styles.transition}>
        {submitted ? (
          <OneText key='s' type={FigmaTypo.H4} className={Styles.center}>
            <EscapedTranslation code='home.sections.contactUs.success' />
          </OneText>
        ) : (
          <HSForms
            key='f'
            region='na1'
            partialId='22250887'
            formId={ContactForm[i18n.language as LanguageCodes]}
            onFormSubmitted={() => {
              setSubmitted(true)
            }}
            onFormReady={(_div, form) => {
              setLoaded(true)
              const rootDiv = (form as HTMLFormElement[])[0]!
              for (let font of document.fonts)
                rootDiv.ownerDocument.fonts.add(font)
              const els = Array.from(
                (rootDiv.ownerDocument as Document).head.querySelectorAll(
                  'style'
                )!
              )
              els.forEach((el) => el.remove())
              const allCssRules = Array.from(document.styleSheets).reduce(
                (r, st) => [...r, ...Array.from(st.cssRules)] as CSSStyleRule[],
                [] as CSSStyleRule[]
              )
              let finalStyle = `form, form > * {display: flex; flex-direction: column; align-items: stretch}
form {padding: 32px 0px;}
input, textarea, select {width: 100%; padding: 3px 12px; border-radius: 15px; border: 0px; box-shadow: var(--card-elevation); background-color: rgb(255,255,255,0.4)}
textarea {min-height: 10em}
select {font-size: 12px !important; padding: 6px 12px}
input[type="submit"] {margin: 32px 0px;}
ul {list-style-type: none;margin: 0;padding: 0;}
.hs_submit {margin-right: auto}
.hs-error-msg {color: red;}
label {margin-top: 12px}\n`
              for (let [baseCls, whatComponentThatClassReplaces] of [
                [classForP16, 'label'],
                [classForInput, 'input, textarea, select'],
                [classForButton, 'input[type="submit"]'],
              ]) {
                const classesToSearchFor = baseCls.split(' ')
                for (let cls of classesToSearchFor) {
                  const rulesForClass = allCssRules.filter((rule) =>
                    rule.selectorText?.includes(cls)
                  )
                  const stylesAsText = rulesForClass
                    .map(({ cssText }) =>
                      cssText.replace(`.${cls}`, whatComponentThatClassReplaces)
                    )
                    .join('\n')
                  finalStyle += `${stylesAsText}\n`
                }
              }
              const rootStyle = getComputedStyle(
                document.body.firstElementChild as any
              )
              const variables = Array.from(
                new Set(finalStyle.match(/--[a-zA-Z\\-]+/g))
              ).concat('--digital-blue')

              for (let varName of variables) {
                rootDiv.style.setProperty(
                  varName,
                  rootStyle.getPropertyValue(varName)
                )
              }

              const styleTag = document.createElement('style')
              styleTag.type = 'text/css'
              styleTag.innerHTML = finalStyle
              rootDiv.ownerDocument.head.appendChild(styleTag)
            }}
          />
        )}
      </UncontrolledTransition>
    </section>
  )
}
