import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import React from 'react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import InProgress from './InProgress'
import ErrorModal from './Modals/ErrorModal.view'
import ExitModal from './Modals/ExitModal.view'
import TimeoutModal from './Modals/TimeoutModal.view'
import Opened from './Opened/Opened.view'
import useStyles from './OpeningDrop.style'
import { OpeningDropProps } from './OpeningDrop.types'

const OpeningDrop: React.FC<OpeningDropProps> = (props) => {
  const { drop, assets, isDropOpen } = props
  const { onBackIntent, onBack, onStay, onOpeningTimeoutCancel } = props
  const { messages, messageIndex } = props
  const { backModalState, errorModal, openingTimeout } = props
  const { main, pulse, light, x1, x2, x3, x4, x5, x6, x7, x8, x9 } = useStyles()
  const rootNodeRef = React.useRef(null)
  const mode = props.delivery
    ? props.delivery?.status === 'CLAIMED'
      ? 'claim'
      : 'purchase'
    : undefined

  return (
    <main className={main}>
      <div className={clsx(light, x1)}></div>
      <div className={clsx(light, x2)}></div>
      <div className={clsx(light, x3)}></div>
      <div className={clsx(light, x4)}></div>
      <div className={clsx(light, x5)}></div>
      <div className={clsx(light, x6)}></div>
      <div className={clsx(light, x7)}></div>
      <div className={clsx(light, x8)}></div>
      <div className={clsx(light, x9)}></div>
      <Box className={pulse}>
        <SwitchTransition>
          <CSSTransition
            nodeRef={rootNodeRef}
            key={isDropOpen ? 'drop-opened' : 'drop-opening'}
            classNames='long-fade'
            timeout={1200}>
            <div
              ref={rootNodeRef}
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}>
              {isDropOpen ? (
                <Opened
                  mode={mode}
                  packTitle={drop?.title || ''}
                  assets={assets}
                  onBackIntent={onBackIntent}
                />
              ) : (
                <InProgress
                  mode={mode}
                  drop={drop}
                  onBackIntent={onBackIntent}
                  messages={messages}
                  messageIndex={messageIndex}
                  deliveryCover={props.delivery?.coverImage || drop?.image!}
                />
              )}
            </div>
          </CSSTransition>
        </SwitchTransition>
      </Box>
      <ExitModal open={backModalState} onStay={onStay} onLeave={onBack} />
      <ErrorModal open={errorModal} onLeave={onBack} />
      <TimeoutModal
        open={openingTimeout}
        onStay={onOpeningTimeoutCancel}
        onLeave={onBack}
      />
    </main>
  )
}

export default OpeningDrop
