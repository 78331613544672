import { makeStyles } from "@material-ui/core";

const useCreateOfferStyles = makeStyles((theme) => ({
    fab: {
        position: "fixed",
        bottom: 16,
        right: 16
    },
    disclaimer: {
        textAlign: "right",
    },
    backButton: {
        padding: "40px 0px",
    },
    row: {
        flexDirection: "row",
        display: "flex",
        "& > :first-child": {
            marginRight: 40,
            flex: 1,
            "& button": {
                maxWidth: "250px",
                width: "100%",
                textTransform: "none",
                display: "inline-block",
                color: theme.palette.text.primary,
                maxHeight: "4rem",
                border: "2px solid " + theme.palette.text.primary,
                background: "none",
                padding: "0px",
                height: "56px",
                marginRight: "2rem",
                "&.Mui-disabled": {
                    border: `2px dashed ${theme.palette.sky}`,
                    "& > * > :first-child": {
                        color: theme.palette.sky
                    }
                }
            },
            "& h6": {
                fontSize: "1.125rem",
                marginBottom: "12px",
                color: theme.palette.grey[200]
            },
            "& .MuiTypography-body1": {
                color: theme.palette.text.primary,
            },
            "& .MuiTypography-body2": {
                color: theme.palette.sky,
            },
            "& .MuiSelect-root": {
                minWidth: "111px"
            },
            "& label": {
                display: "block"
            },
            "& hr": {
                margin: "24px 0px"
            },
            "& td, & th": {
                verticalAlign: "top",
                padding: 0,
                border: "none",
                textAlign: "left"
            },
            "& th": {
                padding: "16px 0px",
            },
            "& .MuiTable-root": {
                width: "auto"
            },
            "& tbody td:first-child > *": {
                display: "flex",
                alignItems: "center",
                marginRight: "24px",
                paddingRight: "16px",
                border: `1px solid ${theme.palette.sky}`,
                borderRadius: "4px"
            },
            "& .MuiInputBase-root span": {
                color: theme.palette.sky
            }
        },
    },
    float: {
        paddingLeft: "24px",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: theme.palette.background.paper,
        paddingTop: theme.spacing(3),
        "& > h3": {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        paddingBottom: theme.spacing(3),
    },
    buttons: {
        marginTop: "24px",
        marginBottom: "24px",
        display: "flex",
        justifyContent: "flex-end",
        "& button": {
            paddingRight: "65px",
            paddingLeft: "65px",
            marginLeft: "24px"
        }
    },
}))

export default useCreateOfferStyles;