import { useGalleries } from 'core/logic/gallery/gallery.hook'
import { GalleryType } from 'core/logic/gallery/gallery.types'
import { useTenant } from 'core/logic/tenant/tenant.hook'
import { DeprecatedTenantType } from 'core/logic/tenant/tenant.types'
import ROUTES from 'core/modules/router'
import { useMemo } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

export default function useRouteGalleryOrDefault() {
  const { tenant } = useTenant()
  const galleries = useGalleries().galleries
  const defaultGallery = useMemo(() => {
    const headlessGallery = galleries?.find(
      (g) => g.id === tenant?.headlessGalleryId
    )
    if (headlessGallery) return headlessGallery
    const deprecatedTenant = tenant as DeprecatedTenantType
    return deprecatedTenant as unknown as GalleryType
  }, [tenant, galleries])

  return useRouteGallery() || defaultGallery
}

export function useRouteGallery() {
  const { pathname } = useLocation()
  const { galleryId } =
    matchPath<{ galleryId: string }>(pathname, {
      path: ROUTES.unauthenticated.galleryDetail,
    })?.params || {}
  const galleries = useGalleries().galleries
  return useMemo(() => {
    return galleries?.find((g) => g.id === galleryId)
  }, [galleries, galleryId])
}
