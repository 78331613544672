import { Dialog, DialogContent } from '@material-ui/core'
import WalletRequiredWrapper from 'components/WalletRequiredWrapper'
import {
  ComponentProps,
  useCallback,
  useMemo,
  useState,
} from 'react'
import View, { WalletConnectedView } from './WalletDisplayButton.view'
import { useUser } from 'core/logic/user'
import { BaseWalletConnectionWrapper } from '@onepercentio/one-ui/dist/components/WalletConnectionWrapper'
import useStyles from './WalletDisplayButton.style'
import { WalletConnectionProps } from '@onepercentio/one-ui/dist/components/WalletConnectionWrapper/WalletConnectionWrapper'
import { useChain } from 'context/Chain'

function WalletWrapper({ onClose }: { onClose: () => void }) {
  const chain = useChain()
  const Wrapper = useMemo<
    ComponentProps<typeof BaseWalletConnectionWrapper>['Content']
  >(
    () =>
      ({ wallet, disconnect }) =>
        (
          <WalletConnectedView
            wallet={wallet!}
            onDisconnect={disconnect}
            onClose={onClose}
          />
        ),
    []
  )
  return <BaseWalletConnectionWrapper chain={chain!} Content={Wrapper} />
}

export default function WalletDisplayButton() {
  const chain = useChain()
  const { profile, detail } = useUser()
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  const onBack = useCallback(() => setOpen(false), [])
  const R = useCallback(
    ({ wallet }: WalletConnectionProps) => {
      return (
        <View
          onClick={() => setOpen(true)}
          username={profile?.name || detail?.displayName || ''}
          wallet={wallet || undefined}
        />
      )
    },
    [profile, detail]
  )
  return (
    <>
      <BaseWalletConnectionWrapper chain={chain!} Content={R} />

      <Dialog
        classes={{
          paper: classes.content,
        }}
        open={open}
        onClose={() => setOpen(false)}>
        <DialogContent>
          <WalletRequiredWrapper onBack={onBack} requiresMigration={false}>
            <WalletWrapper onClose={() => setOpen(false)} />
          </WalletRequiredWrapper>
        </DialogContent>
      </Dialog>
    </>
  )
}
