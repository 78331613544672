import CssBaseline from '@material-ui/core/CssBaseline'
import React, { lazy } from 'react'
import AllProviders from '../AllProviders'
import GlobalStyle from '../GlobalStyle'
import MainRouter from '../MainRouter'
import HTMLTagUpdater from 'components/HTMLTagUpdater'

const LazyCookies = lazy(() =>
  import('./DelayedModules/DelayedModules').then((r) => ({
    default: r.CookiesConsent,
  }))
)

function App(): React.ReactElement {
  return (
    <AllProviders>
      <CssBaseline />
      <GlobalStyle />
      <MainRouter />
      <LazyCookies />
      <HTMLTagUpdater />
    </AllProviders>
  )
}

export default App
