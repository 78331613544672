import { useState } from 'react'
import {
  IRequirementInterface,
  RequirementTypes,
} from './OfferRequirements.types'
import Styles from './OfferRequirements.module.scss'
import { Checkbox, Divider } from '@material-ui/core'
import { EscapedTranslation, i18n, t } from 'translate/i18n'
import Text from '@onepercentio/one-ui/dist/components/Text'
import Button from '@onepercentio/one-ui/dist/components/Button'
import { FormControlLabel } from '@material-ui/core'
import ActionButton from 'components/ActionButton'
import BackButton from 'components/BackButton'
import CVM88 from 'features/CVM88'

/**
 * Implements the logic for the requirements that an auction needs do cover before continuing to the settlement
 **/
export const RequirementViews: {
  [k in RequirementTypes]: IRequirementInterface['requirementView']
} = {
  cvm88: CVM88,
  regulation: function RegulationView({ onFullfill, auction, onCancel }) {
    const [checked, setChecked] = useState(false)
    return (
      <div className={Styles.root}>
        <Text type='promo-label'>
          {t('components.offerRequirements.regulation.check', {
            offer_type_action: t(
              `components.offerRequirements.regulation.checkTypes.${auction.type}`
            ),
          })}
        </Text>

        <Button
          variant='transparent'
          onClick={() => {
            if (typeof auction.regulation === 'object')
              window.open(
                auction.regulation[i18n.language as LanguageCodes],
                '_blank'
              )
            else window.open(auction.regulation!, '_blank')
          }}>
          <span className={Styles.link}>
            {t('components.offerRequirements.regulation.action.viewRegulation')}
          </span>
        </Button>

        <FormControlLabel
          className={Styles.check}
          control={
            <Checkbox
              checked={checked}
              onChange={() => setChecked((a) => !a)}
            />
          }
          label={
            <EscapedTranslation code='components.offerRequirements.regulation.action.check' />
          }
        />
        <div className={Styles.divider}>
          <Divider />
        </div>
        <div>
          <BackButton
            icon='close'
            text={t('components.offerAuctionBidModal.cancel')}
            onClick={onCancel}
          />
          <ActionButton
            disabled={!checked}
            text={t(
              `components.offerRequirements.regulation.action.continue.${auction.type}`
            )}
            onClick={onFullfill}
          />
        </div>
      </div>
    )
  },
}
