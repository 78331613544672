import View from './ChallengeDetailsBanner.view'
import {
  ChallengeDetailsBannerProps,
  ChallengeDetailsMode,
} from './ChallengeDetailsBanner.types'
import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { TO_CHALLENGE } from 'core/modules/router'

export default function ChallengeDetailsBannerLogic(
  props: ChallengeDetailsBannerProps
) {
  const { challenge, mode } = props
  const history = useHistory()
  const action = useMemo(() => {
    if (challenge) {
      if (props.mode === ChallengeDetailsMode.BANNER) {
        return () => {
          history.push(TO_CHALLENGE(challenge.id))
        }
      } else {
        return props.onAction
      }
    }
  }, [challenge])
  return (
    <View
      challenge={challenge}
      loading={false}
      error={null}
      onRetry={() => {}}
      mode={mode}
      onAction={action}
    />
  )
}
