import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { scroller } from 'react-scroll'
import useUseScrollStyle from './useScroll.style'

export default function useScrollTo(
  config: {
    withHighlight: boolean
  } = {
    withHighlight: false,
  }
) {
  const classes = useUseScrollStyle()
  const { state }: any = useLocation()
  const scrollTo = (elementId: string) => {
    const el = document.getElementById(elementId)!
    setTimeout(() => {
      scroller.scrollTo(elementId, {
        smooth: true,
        offset: 5,
      })
      if (config.withHighlight) {
        el.style.fontWeight = 'bold'
        el.addEventListener('animationend', () => {
          el.classList.remove(classes.highlight)
        })
        el.classList.add(classes.highlight)
      }
    }, 1000)
  }

  useEffect(() => {
    const hash = state?.hash || window.location.hash
    if (!!hash) {
      const possibleElementId = hash.replace('#', '')
      const el = document.getElementById(possibleElementId)
      if (!!el) {
        scrollTo(possibleElementId)
      }
    } else {
      window.scrollTo(0, 0)
    }
  }, [state, classes.highlight])

  return {
    scrollTo,
  }
}
