import { Fragment } from 'react'
import View, {
  ChallengeClaimSectionIds,
  ChallengeClaimViewContainer,
} from './ChallengeClaim.view'
import { ChallengeClaimProps } from './ChallengeClaim.types'
import { useHistory, useParams } from 'react-router-dom'
import {
  useChallenge,
  useChallengeClaim,
  useChallengeClaimState,
} from 'context/Challenge'
import useCustomHistory from '@onepercentio/one-ui/dist/hooks/useCustomHistory'
import {
  SIGN_IN,
  TO_FIREBASE,
  TO_MAINPAGE,
  TO_MARKETPLACE_ASSET_DETAILS,
} from 'core/modules/router'
import ChallengeDetailsBannerView from 'openspace/components/ChallengeDetailsBanner/ChallengeDetailsBanner.view'
import { Challenge } from 'core/logic/challenges/challenges.types'
import ChallengeDetailsBanner from 'openspace/components/ChallengeDetailsBanner'
import { ChallengeDetailsMode } from 'openspace/components/ChallengeDetailsBanner/ChallengeDetailsBanner.types'
import usePageScroll from 'openspace/hooks/usePageScroll'

export default function ChallengeClaimLogic(props: ChallengeClaimProps) {
  const { goBackWithFallback } = useCustomHistory()
  const { challengeId } = useParams<{ challengeId: string }>()
  const { challenge, ...control } = useChallenge(challengeId)
  const { retry, error } = control
  const scrollTo = usePageScroll()

  return (
    <ChallengeClaimViewContainer
      banner={
        error ? (
          <ChallengeDetailsBannerView status='error' onAction={retry} />
        ) : !challenge ? (
          <ChallengeDetailsBannerView status='loading' />
        ) : (
          <ChallengeDetailsBanner
            challenge={challenge}
            mode={ChallengeDetailsMode.INFO}
            onAction={() =>
              scrollTo(
                document.getElementById(ChallengeClaimSectionIds.CLAIM_INFO)!
              )
            }
          />
        )
      }
      details={
        challenge ? (
          <ViewWrapper key='challenge' challenge={challenge} />
        ) : (
          <Fragment key='no_challenge' />
        )
      }
      onGoBack={() => goBackWithFallback(TO_MAINPAGE())}
    />
  )
}

/**
 * Wrapped in a component because of hooks that should be called only when the challenge is available
 */
function ViewWrapper({ challenge }: { challenge: Challenge }) {
  const history = useHistory()
  const claimChallenge = useChallengeClaim(challenge)
  const challengClaimState = useChallengeClaimState(challenge)

  return (
    <View
      challenge={challenge}
      actions={{
        onSignin: () => history.push(TO_FIREBASE[SIGN_IN]),
        onSeeAsset: (asset) =>
          history.push(TO_MARKETPLACE_ASSET_DETAILS(asset)),
      }}
      state={challengClaimState}
      onClaimChallenge={() => claimChallenge.claimChallenge()}
    />
  )
}
