import { service } from 'core/modules/firebase'
import { TermsType } from './terms.types'

export const fetchTerms = async (): Promise<TermsType | null> => {
  const query = await service.fetchTerms()
  const data = query.docs[query.docs.length-1].data()

  if (!data) return null

  return {
    ...(data as TermsType),
    created: new Date(data.created),
  }
}
