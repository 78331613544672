import { i18n } from 'translate/i18n'
import Box from '@material-ui/core/Box'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import useStyles, { LineConnector } from './PixInfo.style'

const PixInfoView: React.FC = () => {
  const classes = useStyles()

  return (
    <Box className={classes.wrapper}>
      <Box mb={4}>
        <Typography variant='body1' component='p'>
          <strong>
            {i18n.t('authenticated.pendingPayment.pixInfo.howToPay')}
          </strong>
        </Typography>
      </Box>
      <Stepper nonLinear connector={<LineConnector />} orientation='vertical'>
        <Step active>
          <StepLabel>
            {i18n.t('authenticated.pendingPayment.pixInfo.qrCode')}
          </StepLabel>
        </Step>
        <Step active>
          <StepLabel>
            {i18n.t('authenticated.pendingPayment.pixInfo.checkOutData')}
          </StepLabel>
        </Step>
        <Step active>
          <StepLabel>
            {i18n.t('authenticated.pendingPayment.pixInfo.waitingMessage')}
          </StepLabel>
        </Step>
      </Stepper>
    </Box>
  )
}

export default PixInfoView
