const themeColorTag = document.querySelector('meta[name="theme-color"]')!
const maskColorTag = document.querySelector('link[rel="mask-icon"]')
const appleColorTag = document.querySelector(
  'meta[name="apple-mobile-web-app-status-bar-style"]'
)

export function updateThemeColor(toColor: string) {
  const oldColor = themeColorTag.getAttribute('content')!
  themeColorTag.setAttribute('content', toColor)
  appleColorTag?.setAttribute('content', toColor)
  maskColorTag?.setAttribute('color', toColor)

  return oldColor
}
