/// <reference path="../../../globals.d.ts"/>

import { TenantType } from 'core/logic/tenant/tenant.types'
import { FirebaseReducer } from 'react-redux-firebase'

export type TopBarProps = {}
export type TopBarViewProps = {
  /** The tenant */
  tenant: TenantType | null
  /** Sign in action */
  onSignIn: () => void
  /** Sign up action */
  onSignUp: () => void
  /** One of the menu options/logo click */
  onClickMenu: (menu: MenuOptions | 'home' | AvatarMenuOptions) => void

  /**
   * The current authenticated user
   *
   *     undefined - When the user has not been loaded yet
   *          null - When there is no user authenticated
   *        object - When the user exists
   */
  user?: FirebaseReducer.AuthState | null

  /** The current menu option */
  currentOption?: MenuOptions | AvatarMenuOptions

  onChangeLanguage: (language: LanguageCodes) => void
}

export enum MenuOptions {
  MARKET = 'market',
  GALLERIES = 'galleries',
  ABOUT = 'about',
  HOW_IT_WORKS = 'how_it_works',
  CREATORS = 'creators',
  BRAND = 'brand',
  FAQ = 'faq',
}

export enum AvatarMenuOptions {
  PROFILE = 'profile',
  COLLECTION = 'collection',
  LOGOUT = 'logout',
}
