import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import clsx from 'clsx'
import IconRarum, { IconRarumTitle } from 'components/IconRarum'
import useStyles from './SquareButton.style'
export interface SquareButtonViewProps {
  icon: IconRarumTitle
  className?: string
  onClick: () => void
  active?: boolean
}
const SquareButtonView: React.FC<SquareButtonViewProps> = ({
  icon,
  onClick,
  active = false,
  className,
}) => {
  const { wrapper, button, active: activeCls, hover } = useStyles()

  return (
    <Box className={wrapper} boxShadow={active ? 3 : 0}>
      <IconButton
        className={clsx(button, active ? activeCls : null, hover, className)}
        onClick={onClick}>
        <IconRarum icon={icon} size={24} />
      </IconButton>
    </Box>
  )
}

export default SquareButtonView
