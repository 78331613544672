import { StrictEffect, Task } from '@redux-saga/types'
import { ServiceResult } from 'core/app/types'
import { types as AuthTypes } from 'core/logic/authentication'
import { actions as authActions, types as authActionTypes } from 'core/logic/authentication/auth.reducer'
import { actions as mainActions } from 'core/logic/main/main.reducer'
import * as mainService from 'core/logic/main/main.service'
import { call, cancel, cancelled, CancelledEffect, fork, put, take, takeLatest } from 'redux-saga/effects'
import * as authService from './auth.service'
import { Credentials } from './auth.types'

// service - login with email and password
export const _loginWithEmailAndPassword = (credentials: Credentials) => {
  return authService.login(credentials)
}

// redirecting
export function* _redirect() {
  if (mainService.redirect.isLogin()) {
    yield call(mainService.redirect.reset)
    yield put(mainActions.stopLoading())
  }
}

// watch / detect auto logout (delete app data, user disabled, session expired)
export function* watchAutoLogout() {
  yield takeLatest(authActionTypes.UNAUTHENTICATED, _redirect)
}
// watch / detect auto login (redirect)
export function* watchLogin() {
  yield takeLatest(authActionTypes.AUTHENTICATED, _redirect)
}

// attempt to login
export function* _processLoginWithUserAndPassword(
  credentials: Credentials
): Generator<StrictEffect, void, ServiceResult | CancelledEffect> {
  try {
    const loginResult = yield call(_loginWithEmailAndPassword, credentials)
    const result = loginResult as ServiceResult
    if (result.ok) {
      yield put(authActions.loginSuccess())
    } else {
      yield put(authActions.loginFailedWEP(result.errorKey))
    }
  } catch (e) {
    yield put(authActions.loginFailedWEP('loginWEP.exception'))
  } finally {
    const didCancel = yield cancelled()
  }
}

// watch user manual login
export function* watchLoginWEP() {
  while (true) {
    // capture the login attempt using email and password
    const { payload: credentials } = yield take(authActionTypes.LOGIN_ATTEMPT_WEP)
    // spawn an async task that will try to log in
    const attemptTask: Task = yield fork(_processLoginWithUserAndPassword, credentials)
    // capture an eventual logout or an error raised from the login attempt
    const action: AuthTypes.AuthAction = yield take([authActionTypes.LOGIN_FAILED_WEP, authActionTypes.UNAUTHENTICATED])
    switch (action.type) {
      // if is a logout should cancel a previous attempt  to log in
      case authActionTypes.UNAUTHENTICATED:
        yield cancel(attemptTask)
        break
      default:
        break
    }
  }
}

export function* _registerWithEmailAndPassword(
  action: ReturnType<typeof authActions.registerAttemptWEP>
): Generator<StrictEffect, void, ServiceResult> {
  const { payload: credentials } = action
  if (credentials) {
    const registerResult = yield call(authService.register, credentials)
    if (!registerResult.ok) {
      yield put(authActions.registerFailedWEP(registerResult.errorKey))
    }
  }
}

export function* watchRegister(): Generator<StrictEffect, void, ServiceResult> {
  // capture the register attempt using email and password
  yield takeLatest(authActionTypes.REGISTER_ATTEMPT_WEP, _registerWithEmailAndPassword)
}

export function* watchLoginWithGmail(): Generator<StrictEffect, void, ServiceResult> {
  // capture the register attempt using gmail provider
  yield takeLatest(authActionTypes.LOGIN_ATTEMPT_GMAIL, authService.gmail)
}

export function* watchLoginWithFacebook(): Generator<StrictEffect, void, ServiceResult> {
  // capture the register attempt using gmail provider
  yield takeLatest(authActionTypes.LOGIN_ATTEMPT_FACEBOOK, authService.facebook)
}
