import { AssetType, OwnOfferAsset } from 'core/logic/asset/asset.types'
import { DeliveryType } from 'core/logic/delivery/delivery.types'
import { PendingPurchase } from 'core/logic/purchase/purchase.types'

export enum CollectionItemType {
  PURCHASE,
  DELIVERY,
  ASSET,
}

export type CollectionItemViewProps = {
  id: string
  last: boolean
  onClick?: (id: string) => void
  itemName?: string
  itemImage?: string
  itemAnimation?: string
  itemCount?: number
  orientation?: string
  itemDescription?: string
  offerDetails?: OwnOfferAsset['offerResume']
  expiration?: Date
  asset?: AssetType
} & (
  | {
      type: CollectionItemType.ASSET
    }
  | {
      type: CollectionItemType.DELIVERY
      delivery: DeliveryType
    }
  | {
      type: CollectionItemType.PURCHASE
      purchase: PendingPurchase
    }
)
