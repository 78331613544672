import { i18n } from 'translate/i18n'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'
import clsx from 'clsx'
import { DropType } from 'core/logic/drop/drop.types'
import DropImage from 'components/DropImage'
import ProfileAvatar from 'components/ProfileAvatar'
import React from 'react'
import { FirebaseReducer } from 'react-redux-firebase'
import OfferPrice, { offerPriceParameters } from 'components/offer/OfferPrice'
import useStyles from './DropDetails.style'
import { useTenant } from 'core/logic/tenant/tenant.hook'

interface DropDetailsProps {
  drop?: DropType | null
  price?: number
  title?: boolean
  user?: FirebaseReducer.AuthState
  vertical?: boolean
}
const DropDetails: React.FC<DropDetailsProps> = ({
  drop,
  title = true,
  user,
  vertical = false,
  price,
}) => {
  const classes = useStyles()
  const { tenant } = useTenant()

  const dropData = drop?.[i18n.language] ?? drop

  const figureClass = vertical
    ? classes.figure
    : clsx(classes.figure, classes.figureHor)

  return (
    <>
      {title && (
        <Typography variant='subtitle2' paragraph>
          {i18n.t('authenticated.checkout.dropDetails.details')}
        </Typography>
      )}
      <Grid container spacing={0}>
        <Grid item xs={12} md={vertical ? 12 : 6}>
          <Box display='flex' justifyContent='center'>
            <DropImage
              src={drop?.image}
              imageClass={classes.image}
              figureClass={figureClass}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={vertical ? 12 : 6}>
          <Typography variant='body2'>
            {i18n.t('authenticated.checkout.dropDetails.recipient')}
          </Typography>
          <Box
            display='flex'
            alignItems='center'
            className={classes.bottomSpacing}>
            <Box mr={1}>
              <ProfileAvatar showUserName={false} />
            </Box>
            <Typography variant='body2' className={classes.userData}>
              {user ? (
                <>
                  <b>{user.displayName}</b>
                  <br />
                  <b>{user.email}</b>
                </>
              ) : (
                <>
                  <Skeleton variant='text' width='100%' height={25} />
                  <Skeleton variant='text' width='100%' height={25} />
                </>
              )}
            </Typography>
          </Box>
          <Typography variant='body2'>
            {i18n.t('authenticated.checkout.dropDetails.itemName')}
          </Typography>
          <Typography variant='body2' className={classes.bottomSpacing}>
            {dropData ? (
              <b>{dropData?.title}</b>
            ) : (
              <Skeleton variant='text' width='80%' height={45} />
            )}
          </Typography>
          <Typography variant='body2'>
            {i18n.t('authenticated.checkout.dropDetails.value')}
          </Typography>
          <OfferPrice
            {...offerPriceParameters({
              currency: drop?.currency,
              unitPrice: drop?.unitPrice,
              prices: drop?.prices,
            })}
            size='sm'
            acceptedCurrencies={tenant?.currencies?.accepted}
            preferredCurrency={tenant?.currencies?.preferredCurrency}
          />
        </Grid>
      </Grid>
      <Box textAlign='center'></Box>
    </>
  )
}
export default DropDetails
