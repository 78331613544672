import { MarketplaceContextShape } from 'context/Marketplace'
import { RARITIES } from 'core/constants'
import { AssetType } from 'core/logic/asset/asset.types'
import { useCallback, useMemo, useState } from 'react'
import {
  Filter,
  MarketplaceAssetFilter,
  MarketplaceFilter,
  MarketplaceOrderFilter,
} from './AssetFilters.types'

export function useFilteredAssets<T extends AssetType>(
  filter: Filter | MarketplaceFilter,
  assets: T[] | null,
  marketplaceAssets?: MarketplaceContextShape['salesResume']
) {
  return useMemo(() => {
    if (!assets) return null
    return assets
      .slice(0)
      .sort((a, b) => {
        const diff = RARITIES.indexOf(a.class) - RARITIES.indexOf(b.class)

        if (!('currency' in filter) || !marketplaceAssets) return diff
        else {
          const fallback =
            filter.order === MarketplaceOrderFilter.PRICE_ASC
              ? Number.POSITIVE_INFINITY
              : Number.NEGATIVE_INFINITY
          const salesResumeForAsset =
            marketplaceAssets[a.id]?.price[filter.currency!]?.lowest || fallback
          const salesResumeForAsset2 =
            marketplaceAssets[b.id]?.price[filter.currency!]?.lowest || fallback
          switch (filter.order) {
            case MarketplaceOrderFilter.PRICE_ASC:
              return salesResumeForAsset - salesResumeForAsset2
            case MarketplaceOrderFilter.PRICE_DES:
              return -(salesResumeForAsset - salesResumeForAsset2)
            default:
              return diff
          }
        }
      })
      .filter((asset) => (filter.rarity ? asset.class === filter.rarity : true))
      .filter((asset) =>
        filter.trait
          ? asset.attributes.filter(
              (trait) =>
                trait.trait_type in filter.trait! &&
                trait.value === filter.trait![trait.trait_type]
            ).length === Object.keys(filter.trait).length
          : true
      )
      .filter((asset) => {
        if (
          'currency' in filter &&
          filter.asset === MarketplaceAssetFilter.ONLY_ON_SALE
        ) {
          const salesResumeForAsset = marketplaceAssets?.[asset.id]
          return (
            !!salesResumeForAsset && salesResumeForAsset.price[filter.currency!]
          )
        } else return true
      })
  }, [filter, assets, marketplaceAssets])
}

export function useAssetFilter<F extends Filter | MarketplaceFilter>(
  filterInit: F
) {
  const [filter, updateFilter] = useState<typeof filterInit>({
    ...filterInit,
  })

  const handleField = useCallback(
    (field: keyof MarketplaceFilter) => (filter: string) => {
      updateFilter((prev) => ({ ...prev, [field]: filter }))
    },
    []
  )

  const handleNoFilter = useCallback(() => {
    updateFilter((prev) => ({ ...prev, ...filterInit }))
  }, [])

  const handleTraitFilter = useCallback(
    (key: string, _value: string | unknown) => {
      if (!_value) {
        updateFilter((prev) => {
          const _traitFilter = { ...prev.trait }
          delete _traitFilter[key]
          return {
            ...prev,
            trait: {
              ..._traitFilter,
            },
          }
        })
        return
      }

      const value: string = typeof _value === 'string' ? _value : ''
      updateFilter((prev) => ({
        ...prev,
        trait: {
          ...prev.trait,
          [key]: value,
        },
      }))
    },
    []
  )

  const controls = useMemo(
    () => ({
      onRarityFilter: handleField('rarity'),
      onCurrencyFilter: handleField('currency'),
      onAssetFilter: handleField('asset'),
      onOrderFilter: handleField('order'),
      onTraitFilter: handleTraitFilter,
      onNoFilter: handleNoFilter,
    }),
    [handleField, handleTraitFilter, handleNoFilter]
  )

  return [filter as F, controls] as const
}
