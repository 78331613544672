import React, { ComponentProps } from 'react'
import Styles from './RarumTabs.module.scss'
import Tabs, { TabType } from '@onepercentio/one-ui/dist/components/Tabs/Tabs'

/**
 * Customizes the one-ui tabs to rarum model
 **/
export default function RarumTabs({
  className,
  ...props
}: Omit<ComponentProps<typeof Tabs>, 'type' | 'itemClassName'> & {
  size: 'large' | 'compact'
}) {
  return (
    <div
      className={`${Styles.toggler} ${Styles[props.size]} ${className || ''}`}>
      <Tabs
        className={`${Styles.tabContainer} dark`}
        itemClassName={Styles.tab}
        type={TabType.FULL}
        {...props}
      />
    </div>
  )
}
