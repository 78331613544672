import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) =>
  createStyles({
    countdown: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      display: 'inline-flex',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    nakedCountdown: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      display: 'inline-flex',
    },
    progress: {
      display: 'flex',
      backgroundColor: theme.palette.grey[300],
    },
    bar: {
        width: '0%',
        animation: `${theme.transitions.duration.short} ${theme.transitions.easing.easeIn}`,
        height: 4,
        backgroundColor: theme.palette.primary.main,
    }
  })

export default makeStylesWrapper(style)
