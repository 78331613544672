import { i18n } from 'translate/i18n'
import { yupResolver } from '@hookform/resolvers/yup'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import FormField from 'components/FormFields/FormField.view'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { paymentStepUserDataSchema } from '../Payment.data'
import useStyles from '../Payment.style'
import { IPaymentStepUserDataInputs } from '../Payment.types'
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core'

export interface PaymentViewProps {
  data: IPaymentStepUserDataInputs
  onNext: (data: IPaymentStepUserDataInputs) => void
}

const Step1View: React.FC<PaymentViewProps> = ({ data, onNext }) => {
  const classes = useStyles()
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<IPaymentStepUserDataInputs>({
    defaultValues: data,
    resolver: yupResolver(paymentStepUserDataSchema),
    reValidateMode: 'onChange',
    mode: 'onSubmit',
  })
  const onSubmit = (data: IPaymentStepUserDataInputs) => {
    onNext(data)
  }
  const isForeigner = watch('foreigner')

  return (
    <form id='payment-form' onSubmit={handleSubmit(onSubmit)}>
      <Box mb={4}>
        <Typography variant='body1' component='p' className={classes.stepTitle}>
          <strong>
            {i18n.t('authenticated.payment.steps.stepUserData.personalData')}
          </strong>
        </Typography>
      </Box>
      <FormField
        control={control}
        errors={errors}
        fieldPath='userName'
        label={i18n.t('authenticated.payment.steps.stepUserData.name')}
        autoComplete='name'
        required
      />

      <Box flexBasis='100%'>
        <FormField
          control={control}
          errors={errors}
          fieldPath='email'
          label='E-mail'
          type='email'
          autoComplete='email'
          required
        />
      </Box>
      <Grid container alignItems='center'>
        <Grid item>
          {isForeigner ? (
            <FormField
              control={control}
              errors={errors}
              fieldPath='cpf'
              label={i18n.t(
                'authenticated.payment.steps.stepUserData.foreignerId'
              )}
              inputMode='numeric'
            />
          ) : (
            <FormField
              control={control}
              errors={errors}
              fieldPath='cpf'
              label={i18n.t('authenticated.payment.steps.stepUserData.cpf')}
              inputMode='numeric'
              mask={'999.999.999-99'}
              hideMaskPlaceholder
              required
            />
          )}
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Controller
                name='foreigner'
                render={({ field: { value, ref, onChange, onBlur } }) => (
                  <Checkbox
                    onChange={onChange}
                    onBlur={onBlur}
                    checked={value}
                    inputRef={ref}
                  />
                )}
                control={control}
              />
            }
            label={i18n.t('authenticated.payment.steps.stepUserData.foreigner')}
          />
        </Grid>
      </Grid>
    </form>
  )
}

export default Step1View
