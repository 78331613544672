import React, {
  ElementRef,
  Fragment,
  useInsertionEffect,
  useMemo,
  useRef,
} from 'react'
import Container from 'openspace/components/Container/Container'
import Card from 'components/Card/Card'
import Text from 'openspace/components/Text/Text'
import { FigmaTypo } from 'containers/OneUIProvider/OneUIProvider.data'
import Tabs, { TabType } from '@onepercentio/one-ui/dist/components/Tabs/Tabs'
import OneText from '@onepercentio/one-ui/dist/components/Text/Text'
import OneButton from '@onepercentio/one-ui/dist/components/Button/Button'
import { i18n, t, tO } from 'translate/i18n'
import Spacing from '@onepercentio/one-ui/dist/components/Spacing/Spacing'
import UncontrolledTransition from '@onepercentio/one-ui/dist/components/UncontrolledTransition/UncontrolledTransition'

import { Scaled as CreatorBG } from 'openspace/assets/creators/creators/bg.jpg'
import { Scaled as BrandBG } from 'openspace/assets/creators/brands/bg.jpg'
import { TransitionAnimationTypes } from '@onepercentio/one-ui/dist/components/Transition/Transition'
import useHero from '@onepercentio/one-ui/dist/hooks/useHero'
import BackButtonView from 'components/BackButton/BackButton.view'
import Styles from './Creators.module.scss'
import {
  CreatorsSection,
  useCreatorsSection,
} from 'openspace/pages/Unauthenticated/ForCreators/ForCreators.hook'

const CasesCtx = require.context('openspace/assets/creators/cases')

export function CreatorCard({
  k,
  title,
  profile,
  section,
  enableHero,
}: {
  k: string
  title: string
  profile: string[]
  section: CreatorsSection
  enableHero?: boolean
}) {
  const { heroRef } = useHero(
    `creator-card-${k}`,
    {},
    {
      onHeroDetect() {
        return !!enableHero
      },
      onHeroSkip() {
        return false
      },
    }
  )
  return (
    <Card
      ref={heroRef}
      className={`${Styles.creatorCard} dark ${Styles[section]}`}
      style={{
        backgroundImage: `url(${CasesCtx(`./${k}.jpg`).Scaled['0.5x']})`,
      }}>
      <OneText type={FigmaTypo.H2}>{title}</OneText>
      <div>
        <Text type='p10' code='home.forCreators.profile' color='light' className={Styles.labelProfile}/>
        <OneText type='p12'>
          <b>{profile.join(', ')}</b>
        </OneText>
      </div>
    </Card>
  )
}

/**
 * Shows who uses Rarum
 **/
export default function Creators({
  mode,
  onBack,
  onChangedSection,
  onKnowMore,
}: {
  mode: 'heading' | 'page'
  onBack?: () => void
  onChangedSection?: (section: CreatorsSection) => void
  onKnowMore?: (section: CreatorsSection) => void
}) {
  const [currOption, setCurrOption] = useCreatorsSection()
  const { heroRef, heroComponentRef } = useHero(
    'creators-container',
    {
      propsToTransition: ['borderRadius'],
    },
    {
      onHeroStart(clone) {
        clone.classList.remove(Styles.page, Styles.heading)
        clone.classList.add(Styles[mode])
      },
    }
  )
  const transitionRef = useRef<ElementRef<typeof UncontrolledTransition>>(null)
  useInsertionEffect(() => {
    if (mode === 'page' && currOption === CreatorsSection.BRAND)
      return () => transitionRef.current!.setOrientation('backward')
  }, [currOption])

  const options = useMemo(() => {
    return i18n.t<
      'openspace',
      {
        [id: string]: {
          title: string
          profile: string[]
        }
      }
    >(`home.forCreators.sections.${currOption}`, {
      returnObjects: true,
    })
  }, [currOption])

  const Wrapper = useMemo(
    () => (mode === 'heading' ? Fragment : Container),
    [mode]
  )

  return (
    <section>
      <Wrapper>
        <Card className={`${Styles.root} ${Styles[mode]}`} ref={heroRef}>
          {mode === 'heading' && (
            <Container
              className={`${Styles.back} dark`}
              ref={heroComponentRef('back-button')}>
              <BackButtonView onClick={onBack!} text={t('generic.goBack')} />
            </Container>
          )}
          <UncontrolledTransition
            className={`${Styles.bgTransition} ${Styles[currOption]}`}
            transitionType={TransitionAnimationTypes.FADE}>
            <div
              style={{
                backgroundImage: `url(${
                  currOption === CreatorsSection.BRAND
                    ? BrandBG['0.5x']
                    : CreatorBG['0.5x']
                })`,
                backgroundColor: 'green',
              }}
              key={currOption}
            />
          </UncontrolledTransition>
          <Text
            className={`${Styles.headingLabel} dark`}
            code='home.forCreators.heading'
            type='p12'
          />
          <Spacing size='32' />
          <Text
            code='home.forCreators.title'
            type={FigmaTypo.H2}
            className={`${Styles.title} dark`}
          />
          <Spacing size='32' />
          <div className={`${Styles.toggler} dark`}>
            <OneText type={FigmaTypo.H2}>
              <Tabs
                className={Styles.tabContainer}
                selected={currOption}
                itemClassName={Styles.tab}
                options={[
                  {
                    id: CreatorsSection.CREATOR,
                    label: tO('home.forCreators.tabs.0'),
                  },
                  {
                    id: CreatorsSection.BRAND,
                    label: tO('home.forCreators.tabs.1'),
                  },
                ]}
                onSelect={(s) => {
                  if (onChangedSection) onChangedSection(s)
                  setCurrOption(s as CreatorsSection)
                }}
                type={TabType.FULL}
              />
            </OneText>
          </div>
          {mode === 'page' && (
            <div
              className={Styles.collapsable}
              ref={heroComponentRef('creators-profiles')}>
              <Spacing size='32' />
              <UncontrolledTransition
                ref={transitionRef}
                className={Styles.creatorCards}>
                <div
                  key={currOption}
                  className={`${Styles.cases} ${Styles[currOption]} `}>
                  {Object.keys(options).map((k) => (
                    <CreatorCard
                      k={k}
                      title={options[k].title}
                      profile={options[k].profile}
                      section={currOption}
                    />
                  ))}
                </div>
              </UncontrolledTransition>
              <Spacing size='32' />
              <OneButton
                variant='filled'
                onClick={() => onKnowMore!(currOption)}>
                {tO('home.forCreators.actions.knowMore')}
              </OneButton>
              <Spacing size='32' />
            </div>
          )}
        </Card>
      </Wrapper>
    </section>
  )
}
