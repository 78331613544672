import { gql } from '@apollo/client'
import { ApolloClient, InMemoryCache } from '@apollo/client'

export const qlClient = new ApolloClient({
  cache: new InMemoryCache(),
  uri: process.env.REACT_APP_GRAPHQL_GRAPH_URL,
})

export const TOKEN_OWNERS = gql`
  query {
    tokens {
      holders(where: { amount_gt: "0" }) {
        wallet {
          id
        }
      }
      id
    }
  }
`
