import React from 'react'
import Styles from './SectionSkip.module.scss'
import Button from '@onepercentio/one-ui/dist/components/Button'
import { ReactComponent as Skip } from 'openspace/assets/svg/section-skip-btn.svg'

export function skipToNextSection(origin: HTMLDivElement) {
  const nextSection = origin.closest('section')!.nextSibling as HTMLDivElement

  if (process.env.NODE_ENV === 'development')
    if (!nextSection)
      throw new Error(
        `When using this component, sections should be adjacent to each other.`
      )

  const scrollable = nextSection.parentElement!.closest('section')!
    .firstChild as HTMLDivElement

  scrollable.scrollTo({
    top: nextSection.offsetTop,
    behavior: 'smooth',
  })
}

/**
 * A button to jump to the next section
 **/
export default function SectionSkip() {
  return (
    <Button
      variant='transparent'
      className={Styles.root}
      onClick={({ target }) => {
        skipToNextSection(target as HTMLDivElement)
      }}>
      <Skip />
    </Button>
  )
}
