import React, { useEffect, useMemo, useState } from 'react'
import View from './Benefits.view'
import {
  useBenefits,
  useUpToDateMyCollectionItems,
} from 'context/MyCollection'
import { useParams } from 'react-router-dom'
import {
  AssetType,
  BaseAssetType,
  BaseAssetWithBalance,
} from 'core/logic/asset/asset.types'
import UncontrolledTransition from '@onepercentio/one-ui/dist/components/UncontrolledTransition'
import { TransitionAnimationTypes } from '@onepercentio/one-ui/dist/components/Transition/Transition'
import Loader from '@onepercentio/one-ui/dist/components/Loader'
import useQuery from '@onepercentio/one-ui/dist/hooks/utility/useQuery'
import { Benefit, BenefitWithStatus, ClaimableBenefit } from './Benefits.types'
import ClaimConfirmation from './ClaimConfirmation'
import useFreeze from '@onepercentio/one-ui/dist/hooks/useFreeze'
import {
  ClaimConfirmationMode,
  ClaimConfirmationProps,
} from './ClaimConfirmation/ClaimConfirmation.types'
import { i18n } from 'translate/i18n'
import ClaimConfirmationView from './ClaimConfirmation/ClaimConfirmation.view'

function Wrapper({ asset }: { asset: BaseAssetWithBalance }) {
  const { benefits, refreshBenefits, claims: allClaims } = useBenefits(asset)
  const [_selectedBenefit, setBenefitToClaim] =
    useState<[ClaimableBenefit, ClaimConfirmationProps['startingMode']]>()

  const benefitToClaim = useMemo(
    () =>
      benefits?.find(
        (b) =>
          b.code === _selectedBenefit?.[0].code &&
          b.tokenId === _selectedBenefit[0].tokenId
      ),
    [_selectedBenefit, benefits]
  )
  const claims = useMemo(() => {
    return allClaims?.filter((c) => c.benefit === benefitToClaim?.code)
  }, [allClaims, benefitToClaim])
  const watchConfirmationIndex = useMemo(() => {
    const futureConfirmationIndex = claims?.length || 0
    return claims?.[futureConfirmationIndex - 1]?.status ===
      'AWAITING_CONFIRMATION'
      ? futureConfirmationIndex - 1
      : futureConfirmationIndex
  }, [benefitToClaim?.code])
  const confirmationToWatch = useMemo(() => {
    return claims?.[watchConfirmationIndex]
  }, [claims, watchConfirmationIndex])
  const benefitWithStatus = useMemo(() => {
    return !benefitToClaim
      ? null
      : ({
          ...benefitToClaim,
          status: confirmationToWatch?.status,
        } as BenefitWithStatus)
  }, [confirmationToWatch, benefitToClaim])
  const frozenBenefit = useFreeze(benefitWithStatus)

  const isLoading = useMemo(() => {
    return benefits?.find((c) => c.status === 'AWAITING_CONFIRMATION')
  }, [benefits])
  useEffect(() => {
    if (isLoading)
      return () => {
        refreshBenefits()
      }
  }, [isLoading])

  const predefinedBenefitCode = useQuery<{ b: Benefit['code'] }>().get('b')
  useEffect(() => {
    if (predefinedBenefitCode) {
      const predefinedBenefit = benefits?.find(
        (b) => b.code === predefinedBenefitCode
      )
      if (predefinedBenefit)
        setBenefitToClaim([predefinedBenefit, ClaimConfirmationMode.DETAILS])
    }
  }, [predefinedBenefitCode, benefits])

  return (
    <>
      <View
        benefits={benefits || []}
        onCollect={(b) => setBenefitToClaim([b, ClaimConfirmationMode.CLAIM])}
        onDetails={(b: ClaimableBenefit) =>
          setBenefitToClaim([b, ClaimConfirmationMode.DETAILS])
        }
      />
      {frozenBenefit && (
        <ClaimConfirmation
          benefit={frozenBenefit!}
          onClaimed={() => {
            setBenefitToClaim(undefined)
          }}
          onCancel={() => {
            setBenefitToClaim(undefined)
          }}
          startingMode={_selectedBenefit?.[1]!}
          open={!!benefitToClaim}
        />
      )}
    </>
  )
}

export default function BenefitsLogic() {
  const { assetId } = useParams<{ assetId: BaseAssetType['id'] }>()
  const query = useQuery<{
    tokenId: BaseAssetType['tokenId']
  }>()
  const { ownedItems } = useUpToDateMyCollectionItems()
  const relatedAsset = ownedItems?.find(
    (a) => a.id === assetId && a.tokenId === Number(query.get('tokenId'))
  )

  return (
    <UncontrolledTransition transitionType={TransitionAnimationTypes.FADE}>
      {!relatedAsset ? (
        <Loader key={'l'} />
      ) : (
        <Wrapper asset={relatedAsset} key={'b'} />
      )}
    </UncontrolledTransition>
  )
}

export function ReadOnlyBenefits({ asset }: { asset: AssetType }) {
  const [selectedBenefit, setSelectedBenefit] = useState<Benefit>()
  const benefitsInfo = useMemo(() => {
    return asset.attributes_extension!.map((b) => {
      const intl = b[i18n.language]
      return {
        description: intl.description,
        details: intl.details_html,
        title: intl.title,
        code: b.trait_type,
      }
    }) as Benefit[]
  }, [asset])

  const frozenBenefit = useFreeze(selectedBenefit)

  return (
    <>
      <View benefits={benefitsInfo} onDetails={setSelectedBenefit} />

      {frozenBenefit && (
        <ClaimConfirmationView
          benefit={frozenBenefit!}
          mode={ClaimConfirmationMode.READ_ONLY}
          open={!!selectedBenefit}
          claimed={false}
          error={null}
          loading={false}
          onAction={() => setSelectedBenefit(undefined)}
          onCancel={() => setSelectedBenefit(undefined)}
          onClosed={() => setSelectedBenefit(undefined)}
        />
      )}
    </>
  )
}
