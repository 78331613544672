import ReactDOM from "react-dom";
import { PropsWithChildren } from "react";
import { useEffect } from "react";
import { useState } from "react";
import Styles from "./Portal.module.scss";

/**
 * A react portal implementation with current page fallback
 **/
export default function Portal({
  to,
  children,
}: PropsWithChildren<{ to: string }>) {
  const [target, setTarget] = useState<Element | null>();

  useEffect(() => {
    setTarget(document.querySelector(`[data-one-portal="${to}"]`) || null);
  }, [to]);

  return target === undefined ? null : (
    <>{target === null ? children : ReactDOM.createPortal(children, target)}</>
  );
}

export function PortalReceiver({ name }: { name: string }) {
  return <div className={Styles.portal} data-one-portal={name} />;
}
