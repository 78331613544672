import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const style = (_: Theme) =>
  createStyles({
    accordionDetail: {
      justifyContent: 'center',
    },
  })
export default makeStylesWrapper(style)
