import { i18n } from 'translate/i18n'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import ActionButton from 'components/ActionButton'
import InfoModal from 'components/InfoModal'
import React from 'react'
import useStyles from '../OpeningDrop.style'

interface ActionsProps {
  onExit: () => void
}

// Error modal actions area component
const Actions: React.FC<ActionsProps> = ({ onExit }) => {
  const { actionsWrapper, actionButton } = useStyles()
  return (
    <Box className={actionsWrapper}>
      <ActionButton
        text={i18n.t(
          'authenticated.openingDrop.modals.errorModal.myCollection'
        )}
        className={actionButton}
        onClick={onExit}
      />
    </Box>
  )
}
export interface ErrorModalProps {
  open: boolean
  onLeave: () => void
}

// Close modal content
const modalContent = (
  <Typography variant='body1'>
    {i18n.t('authenticated.openingDrop.modals.errorModal.openingError')}
    <br />
    {i18n.t('authenticated.openingDrop.modals.errorModal.tryAgain')}
  </Typography>
)

const ErrorModal: React.FC<ErrorModalProps> = ({ open, onLeave }) => {
  return (
    <InfoModal
      title={i18n.t(
        'authenticated.openingDrop.modals.errorModal.openingErrorInfoModal'
      )}
      open={open}
      handleClose={onLeave}
      content={modalContent}
      actions={<Actions onExit={onLeave} />}
      disableBackdropClick
    />
  )
}

export default ErrorModal
