import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'
import emptyImage from 'assets/images/empty-collection.webp'

const style = (theme: Theme) =>
  createStyles({
    title: {
      textTransform: 'uppercase',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    card: {
      backgroundColor: theme.palette.background.paper,
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    divider: {
      backgroundColor: theme.palette.grey[200],
      alignSelf: 'stretch',
      height: 2,
    },
    pointer: {
      cursor: 'pointer',
    },
    highlight: {
      color: theme.palette.primary.main,
    },
    legalWrapper: {
      maxWidth: 472,
    },
    modalParagraph: {
      marginBottom: theme.spacing(4),
    },
    figure: {
      background: `url(${emptyImage}) no-repeat center center`,
      backgroundSize: 'contain',
      width: '100%',
      height: 400,
      marginRight: theme.spacing(4)
    },
  })
export default makeStylesWrapper(style)
