import { i18n } from 'translate/i18n'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { memo } from 'react'
import CarbonNeutralBadgeView from './CarbonNeutralBadge/CarbonNeutralBadge.view'
import useStyles from './Certificate.style'

const CertificateView = () => {
  const classes = useStyles()

  return (
    <Container maxWidth='md' disableGutters className={classes.container}>
      <CarbonNeutralBadgeView className={classes.image} />
      <Typography variant='body1' component='p' className={classes.text}>
        {i18n.t('components.certificate')}
      </Typography>
    </Container>
  )
}

export default memo(CertificateView)
