import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
      display: 'flex',
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
    },
    mobileCloseContainer: {
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        display: 'flex',
      },
    },
    mobileCloseButton: {
      marginTop: theme.spacing(3),
    },
    leftContainer: {
      marginTop: theme.spacing(10),
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(2),
      },
    },
    boxDescription: {
      padding: theme.spacing(8),
      border: '1px solid #E4E5E9',
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(5),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(5),
      },
    },
    description: {
      marginTop: theme.spacing(2),
      color: '#6D6E71',
      fontWeight: 500,
    },
    boxDropDetails: {
      padding: theme.spacing(8),
      borderLeft: '1px solid #E4E5E9',
      borderRight: '1px solid #E4E5E9',
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(5),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(5),
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    dropFigure: {
      width: '100%',
      maxWidth: 180,
      margin: 0,
      paddingRight: theme.spacing(1),
      [theme.breakpoints.down('md')]: {
        paddingTop: theme.spacing(1),
        order: 1,
        padding: 0,
      },
    },
    dropDescription: {
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
        order: 0,
      },
    },
    boxEventDetails: {
      display: 'block',
      width: '100%',
    },
    boxEventDetailsText: {
      display: 'block',
      paddingTop: theme.spacing(8),
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
      paddingBottom: theme.spacing(5),
      border: '1px solid #E4E5E9',

      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(5),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(4),
      },
    },
    boxEventDetailsTime: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    waitingIcon: {
      marginRight: theme.spacing(1),
    },
    timer: {
      [theme.breakpoints.up('lg')]: {
        marginLeft: theme.spacing(3),
      },
    },
    rightContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: theme.spacing(7),
      marginTop: theme.spacing(10),
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(0),
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(4),
        height: 'auto',
      },
    },
    closeButton: {
      alignSelf: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    mediaDescription: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
    boxExit: {
      marginTop: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    exitButton: {
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(4),
      },
    },
    exitText: {
      fontWeight: 600,
    },
    boxExitMobile: {
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    header: {
      textTransform: 'uppercase',
      fontWeight: 800,
    },
  })

export default makeStylesWrapper(style)
