import { Challenge } from 'core/logic/challenges/challenges.types'

export enum ChallengeDetailsMode {
  /** Displays an action */
  BANNER,

  /** Do not display information */
  INFO,
}
export type ChallengeDetailsBannerProps = {
  challenge: Challenge
} & (
  | { mode: ChallengeDetailsMode.BANNER }
  | { mode: ChallengeDetailsMode.INFO; onAction: () => void }
)
export type ChallengeDetailsBannerViewProps = Partial<
  ClickActions<'onAction'>
> &
  (
    | (Controllable<{
        challenge: Challenge
      }> &
        Pick<ChallengeDetailsBannerProps, 'mode'>)
    | {
        status: 'loading' | 'error'
      }
  )
