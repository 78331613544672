import { useCallback } from 'react'
import useModule from "@onepercentio/one-ui/dist/hooks/utility/useModule";

const EIP712Domain = [
  { name: 'name', type: 'string' },
  { name: 'version', type: 'string' },
  { name: 'chainId', type: 'uint256' },
  { name: 'verifyingContract', type: 'address' },
]

const CreateOffer = [
  { name: 'owner', type: 'address' },
  { name: 'authorization', type: 'bytes32' },
  { name: 'supply', type: 'uint256' },
  { name: 'nft', type: 'address' },
  { name: 'tokenId', type: 'uint256' },
  { name: 'price', type: 'uint256' },
  { name: 'paymentToken', type: 'address' },
  { name: 'beneficiary', type: 'address' },
]

const Permit = [
  { name: 'owner', type: 'address' },
  { name: 'operator', type: 'address' },
  { name: 'approved', type: 'bool' },
  { name: 'nonce', type: 'bytes32' },
  { name: 'deadline', type: 'uint256' },
]

export default function useWeb3Utils() {
  const web3Utils = useModule(() => import('web3-utils'))

  const buildMessage = useCallback(
    (
      chainId: number,
      verifyingContract: string | undefined,
      message: any,
      schemaName: 'CreateOffer' | 'Permit',
      domainName: 'GenericTypedMessage' | 'https://rarum.io'
    ) => ({
      primaryType: schemaName,
      types: { EIP712Domain, CreateOffer, Permit },
      domain: { name: domainName, version: '1', chainId, verifyingContract },
      message,
    }),
    []
  )

  const signMessage = useCallback(
    async (message: any, from?: string) => {
      const method = 'eth_signTypedData_v4'
      const result: any = await window.ethereum!.request({
        method,
        params: [from, JSON.stringify(message)],
      })

      const signature = result.substr(2) //remove 0x
      const r = '0x' + signature.slice(0, 64)
      const s = '0x' + signature.slice(64, 128)
      const v = '0x' + signature.slice(128, 130)
      const v_decimal = web3Utils!.toDecimal(v)

      return { v: v_decimal, r, s }
    },
    [web3Utils]
  )

  return {
    ...web3Utils!,
    buildMessage,
    signMessage,
  }
}
