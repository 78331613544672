import { yupResolver } from '@hookform/resolvers/yup'
import { useCEPFetcher } from 'core/logic/cep/cep.hook'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { paymentStepAddressSchema } from '../Payment.data'
import { IPaymentStepAddressInputs } from '../Payment.types'
import StepAddress from './StepAddress.view'

export interface StepAddressLogicProps {
  data: IPaymentStepAddressInputs
  onNext: (data: IPaymentStepAddressInputs) => void
}


const StepAddressLogic: React.FC<StepAddressLogicProps> = ({ data, onNext }) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    watch,
  } = useForm<IPaymentStepAddressInputs>({
    defaultValues: data,
    resolver: yupResolver(paymentStepAddressSchema),
    reValidateMode: 'onChange',
    mode: 'onSubmit',
  })

  const onSubmit = (data: IPaymentStepAddressInputs) => {
    onNext(data)
  }

  const isNonResident = watch('nonResident')
  const watchCEP = watch('cep')
  const [editLocked, fetchingCEP, res] = useCEPFetcher({ cep: watchCEP, enable: !isNonResident && isDirty })
  const { estado, cidade, logradouro } = res || {}


  useEffect(() => {
    if (estado) setValue('estado', estado)
    if (cidade) setValue('cidade', cidade)
    if (logradouro) setValue('logradouro', logradouro)
  }, [setValue, estado, cidade, logradouro])

  return (
    <StepAddress
      errors={errors}
      control={control}
      handleSubmit={handleSubmit(onSubmit)}
      editLocked={editLocked.city}
      fetchingCEP={fetchingCEP}
      isNonResident={isNonResident}
    />
  )
}

export default StepAddressLogic
