import Button from '@onepercentio/one-ui/dist/components/Button'
import {
  PaginationIndicatorMode,
  PaginationIndicatorView,
} from '@onepercentio/one-ui/dist/components/PaginationIndicator/PaginationIndicator'
import { useOneUIConfig } from '@onepercentio/one-ui/dist/context/OneUIProvider'
import React, { useEffect, useRef, useState } from 'react'
import Container from '../Container'
import Styles from './BottomPaginationControls.module.scss'
import { BottomPaginationControlsTestIds } from './BottomPaginationControls.e2e'

export function Controls({
  onClick,
}: {
  onClick: (dir: 'left' | 'right') => void
}) {
  const { LeftControl, RightControl } = useOneUIConfig(
    'hook.ui.usePaginationControls'
  )
  return (
    <>
      <div className={Styles.btns}>
        <Button
          variant='transparent'
          data-testid={BottomPaginationControlsTestIds.LEFT_CONTROL}
          onClick={() => onClick('left')}>
          <LeftControl />
        </Button>
        <Button
          variant='transparent'
          data-testid={BottomPaginationControlsTestIds.RIGHT_CONTROL}
          onClick={() => onClick('right')}>
          <RightControl />
        </Button>
      </div>
    </>
  )
}

/**
 * To provide a control for switching pages on a container
 **/
export default function BottomPaginationControls({
  pages,
  currentPage,
  onPageClick,
}: {
  pages: number
  currentPage: number
  onPageClick: (page: number) => void
}) {
  const [currentPageProgress, setProgress] = useState(currentPage)
  const prevCurrentPage = useRef<number>()
  useEffect(() => {
    if (prevCurrentPage.current === undefined)
      return () => {
        prevCurrentPage.current = currentPage
      }
    const interval = setInterval(() => {
      const step = (currentPage - prevCurrentPage.current!) / 60
      setProgress((prev) => {
        const op = step > 0 ? Math.floor : Math.ceil
        if (op(prev) === currentPage) {
          clearInterval(interval)
          return op(prev)
        } else return prev + step
      })
    }, 250 / 60)

    return () => {
      clearInterval(interval)
      prevCurrentPage.current = currentPage
    }
  }, [currentPage])
  return (
    <>
      <Container className={Styles.controls}>
        <PaginationIndicatorView
          pages={pages}
          page={currentPageProgress}
          size={24}
          className=''
          onClickPage={onPageClick}
          mode={PaginationIndicatorMode.START}
        />
        <Controls
          onClick={(dir) => {
            if (dir === 'left') {
              onPageClick(currentPage === 1 ? pages : currentPage - 1)
            } else {
              onPageClick(currentPage === pages ? 1 : currentPage + 1)
            }
          }}
        />
      </Container>
    </>
  )
}
