import { createStyles, Theme, withStyles } from '@material-ui/core'
import StepConnector from '@material-ui/core/StepConnector'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) =>
  createStyles({
    wrapper: {
      border: `1px solid ${theme.palette.grey[200]}`,
      borderRadius: 4,
      padding: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(5),
      },
    },
  })

export default makeStylesWrapper(style)

export const LineConnector = withStyles((theme) => ({
  vertical: {
    marginLeft: 18,
    padding: `${theme.spacing(1)}px 0`,
  },
  line: {
    borderColor: theme.palette.grey[200],
    borderLeftWidth: 2,
    borderRadius: 0,
  },
}))(StepConnector)
