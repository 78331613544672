import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import metamaskLogo from 'assets/images/metamask.png'
import React from 'react'
import { t } from 'translate/i18n'
import ActionButton from '../ActionButton'
import useStyles from './Metamask.style'

const MetamaskView = () => {
  const classes = useStyles()
  return (
    <Container maxWidth='xs'>
      <Paper elevation={3}>
        <Box display='flex' flexDirection='column' alignItems='center' p={4}>
          <Box maxHeight={150} maxWidth={150}>
            <img src={metamaskLogo} loading='lazy' title='Metamask' alt='Metamask Logo' className={classes.logo} />
          </Box>
          <Box mt={10} display='flex'>
            <ActionButton text={t('wallet.connect')} />
          </Box>
        </Box>
      </Paper>
    </Container>
  )
}

export default MetamaskView
