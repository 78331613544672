import { Fab } from '@material-ui/core'
import MutableHamburgerButton from '@onepercentio/one-ui/dist/components/MutableHamburgerButton/MutableHamburgerButton'
import Styles from './Control.module.scss'

export default function Control({
  open,
  ...props
}: {
  open: boolean
  'data-testid'?: string
}) {
  return (
    <Fab data-testid={props['data-testid']} className={Styles.fab}>
      <MutableHamburgerButton state={open ? 'closed' : 'search'} size={32} />
    </Fab>
  )
}
