import { useEffect, useState } from 'react'
import { Airdrop } from './airdrop.types'
import useAsyncControl from '@onepercentio/one-ui/dist/hooks/useAsyncControl'
import { getAirdrop } from 'core/modules/firebase/service'

export function useAirdrop(airdropId?: string) {
  const [airdrop, setAirdrop] = useState<Airdrop>()
  const { process, error, loading } = useAsyncControl()
  function _getAirdrop() {
    process(async () => {
      setAirdrop(await getAirdrop(airdropId!))
    })
  }
  useEffect(() => {
    if (airdropId) _getAirdrop()
  }, [airdropId])

  return {
    airdrop,
    error,
    loading,
    retry: _getAirdrop,
  }
}
