import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) =>
  createStyles({
    clockIcon: {
      marginRight: theme.spacing(1),
    },
    header: {
      marginBottom: theme.spacing(2),
      textTransform: 'uppercase',
      fontWeight: 800,
    },
    paragraph: {
      marginBottom: theme.spacing(2),
    },
  })
export default makeStylesWrapper(style)
