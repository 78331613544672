import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    video: {
      width: '100%',
    },
    image: {
      height: '50%',
    },
  })
export default makeStylesWrapper(style)
