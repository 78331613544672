import { useTheme } from '@material-ui/core'
import Box, { BoxProps } from '@material-ui/core/Box'
import { Platform } from 'core/logic/authentication/auth.types'
import IconRarum from 'components/IconRarum'
import React, { memo } from 'react'
import { authIcons } from './AuthSocialButtons.data'
import Button from '@onepercentio/one-ui/dist/components/Button'

export interface AuthSocialButtonsViewProps {
  /**
   * Text prefix for aria-label i.e Login with
   */
  labelPrefix?: string
  /**
   * What should be invoked when selection an option
   */
  clickHandler: (platform: Platform) => void
  /**
   * Enables only the given platforms
   */
  enableOnly?: Platform[]
  /**
   * Check disabled
   */
  disabled: boolean
}

const AuthSocialButtonsView: React.FC<
  AuthSocialButtonsViewProps & BoxProps
> = ({ labelPrefix, clickHandler, enableOnly, disabled, ...props }) => {
  const theme = useTheme()
  return (
    <Box display='flex' flexDirection='column' {...props}>
      {authIcons.map(({ label, icon, platform }) => {
        const isDisabled =
          enableOnly &&
          enableOnly.length > 0 &&
          !enableOnly.find((p) => p === platform) &&
          !disabled

        return (
          <>
            <Button
              disabled={isDisabled}
              className='icon'
              variant='outline'
              onClick={() => clickHandler(platform)}>
              <IconRarum
                icon={icon}
                size={26}
                color={
                  !isDisabled
                    ? theme.palette.primary.main
                    : theme.palette.grey[200]
                }
              />
              {`${labelPrefix} ${label}`}
            </Button>
            <br />
          </>
        )
      })}
    </Box>
  )
}

export default memo(AuthSocialButtonsView)
