import { CurrenciesEnum } from 'core/types/CurrenciesEnum'
import { OfferPriceType } from 'core/types/OfferPriceType'
import { Currency } from './Currency'
import { OfferPriceView } from './OfferPrice.view'

export type OfferPriceProps = {
  prices: OfferPriceType
  preferredCurrency?: CurrenciesEnum,
  acceptedCurrencies?: CurrenciesEnum[],
  size?: 'sm' | 'md' | 'lg',
  className?: string,
  /**
   * This controls the appearence of the text container
   *
   *      "styled" // For a styles text, with preset fonr, color, etc...
   *        "text" // For text only, allowing external customization
   */
  mode?: 'styled' | 'text'
}

export const OfferPriceController: React.FC<OfferPriceProps> = ({
  prices,
  preferredCurrency  = CurrenciesEnum.BRL,
  acceptedCurrencies = [],
  ...props
}) => {

  const alternatives = acceptedCurrencies?.filter(c => c !== preferredCurrency) || []
  /** Exists a price in prices based off the prefered currency */
  let hasMain: boolean = !!(preferredCurrency && typeof prices?.[preferredCurrency] === 'number')
  let hasAlternative: boolean = !!(alternatives[0] && typeof prices?.[alternatives[0]] === 'number')
  if( !hasMain &&  hasAlternative) {
    preferredCurrency = alternatives[0]
    hasMain = true
    hasAlternative = false
  }
  return (
    <OfferPriceView
      {...props}
      main={
        hasMain ? (
          <Currency
            currency={preferredCurrency}
            amount={prices[preferredCurrency]!}
          />
        ) : undefined
      }
      secondary={
        hasAlternative ? (
          <Currency
            currency={alternatives[0]}
            amount={prices[alternatives[0]] as number}
          />
        ) : undefined
      }
    />
  )
}
