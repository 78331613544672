import { useEffect, useMemo, useState } from 'react'
import TransferTokensFlowView from './TransferTokensFlow.view'
import { TransferTokensFlowStates } from './TransferTokensFlow.types'
import {
  useConfirmation,
  useSettle,
} from 'components/TransferTokensFlow/TransferTokensFlow.hooks'
import { useUser } from 'core/logic/user'
import { useHistory, useLocation } from 'react-router-dom'
import { TO_MY_COLLECTION } from 'core/modules/router'
import Transitions from 'components/Transitions'
import useStyles from './TransferTokensFlow.styles'
import { RarumUserProfile } from 'core/logic/user/user.types'
import WalletRequiredWrapperLogic from 'components/WalletRequiredWrapper/WalletRequiredWrapper.logic'

function TransferTokensFlowLogic({
  state,
  setState,
}: {
  state: TransferTokensFlowStates
  setState: (newState: TransferTokensFlowStates) => void
}) {
  const { confirmOperation } = useConfirmation()
  const { settleTransfer } = useSettle()
  const { profile = {} as RarumUserProfile } = useUser()
  const history = useHistory()
  const [understood, setUnderstood] = useState(false)
  const [understoodDanger, setUnderstoodDanger] = useState(false)

  useEffect(() => {
    if (state === TransferTokensFlowStates.REQUIRED_CONFIRMATION)
      confirmOperation()
    if (state === TransferTokensFlowStates.CONFIRMED_OPERATION) settleTransfer()
  }, [state, confirmOperation, settleTransfer])

  return (
    <TransferTokensFlowView
      key={state}
      status={state}
      user={profile}
      actions={{
        onGoToMarketplace: () => history.replace(TO_MY_COLLECTION()),
        onStartOperation: () =>
          setState(TransferTokensFlowStates.REQUIRED_CONFIRMATION),
        onUnderstoodLimitations: setUnderstood,
        onUnderstoodDanger: setUnderstoodDanger,
        onSettleTransfer: () =>
          setState(TransferTokensFlowStates.CONFIRMED_OPERATION),
        onCancel: () => history.replace(TO_MY_COLLECTION()),
      }}
      understood={understood}
      understoodDanger={understoodDanger}
    />
  )
}

function TransferTokensFlowLogicWrapper() {
  const loc = useLocation()
  const params = useMemo(() => new URLSearchParams(loc.search), [loc.search])
  const { w, s } = { w: params.get('w'), s: params.get('s') }
  const [state, setState] = useState(() => {
    if (w && s) return TransferTokensFlowStates.FROM_EMAIL
    else return TransferTokensFlowStates.TUTORIAL
  })
  const viewClasses = useStyles()
  return (
    <div className={viewClasses.main}>
      <Transitions>
        <TransferTokensFlowLogic
          key={state}
          state={state}
          setState={setState}
        />
      </Transitions>
    </div>
  )
}

export default function TransferTokensFlowLogicWalletRequired() {
  return (
    <>
      <WalletRequiredWrapperLogic requiresMigration={false}>
        <TransferTokensFlowLogicWrapper />
      </WalletRequiredWrapperLogic>
    </>
  )
}
