import BigNumber from 'bignumber.js'
import BN from 'bn.js'
import { useMemo } from 'react'
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from 'react-query'
import { AssetType } from '../asset/asset.types'
import { useWalletConnnectorContext } from '../wallet'
import { RAW_ERC20_OLD as ERC20 } from './abi/ERC20'
import { RAW_NFT_OLD as NFT } from './abi/NFT'
import { RAW_OLD_SINGLE_SALE as SingleSaleNFT } from './abi/SingleSaleNFT'

export function useSingleSaleNFTContracts(
  saleAddress: string,
  paymentAddress: string,
  nftAddress: string
) {
  const { web3 } = useWalletConnnectorContext()

  const sale = useMemo<Contract.Sales_Old>(
    () =>
      web3
        ? (new web3.eth.Contract(SingleSaleNFT as any, saleAddress) as any)
        : undefined,
    [saleAddress, web3]
  )

  const payment = useMemo<Contract.IERC20_Old>(
    () =>
      web3
        ? (new web3.eth.Contract(ERC20 as any, paymentAddress) as any)
        : undefined,
    [paymentAddress, web3]
  )

  const nft = useMemo<Contract.NFT_Old>(
    () =>
      web3 ? (new web3.eth.Contract(NFT as any, nftAddress) as any) : undefined,
    [nftAddress, web3]
  )

  return {
    sale,
    payment,
    nft,
  }
}

export function useSingleSaleNFTGetPrice(
  saleContract: Contract.Sales_Old,
  options?: UseQueryOptions<BigNumber, Error>
) {
  const query = useQuery({
    queryKey: [saleContract.options.address, 'price'],
    queryFn: async () => {
      if (!saleContract.options.address) return new BigNumber(0)
      const price = await saleContract.methods.price().call()

      return new BigNumber(price)
    },
    ...options,
  })

  return query
}

export function useSingleSaleNFTGetUnitsAvailable(
  saleContract: Contract.Sales_Old,
  nftContract: Contract.NFT_Old,
  tokenId?: AssetType['tokenId'],
  options?: UseQueryOptions<BigNumber, Error>
) {
  const query = useQuery({
    queryKey: [saleContract.options.address, 'unitsAvailable'],
    queryFn: async () => {
      if (!saleContract.options.address) return new BigNumber(0)
      if (tokenId === undefined) return new BigNumber(0)
      const [owner, unitsAvailable] = await Promise.all([
        saleContract.methods.owner().call(),
        saleContract.methods.unitsAvailable().call(),
      ])

      const ownerBalance = await nftContract.methods
        .balanceOf(owner, new BN(tokenId) as any)
        .call()

      const supply =
        ownerBalance > unitsAvailable ? unitsAvailable : ownerBalance

      return new BigNumber(supply)
    },
    ...options,
  })

  return query
}

export type ERC20Approve = {
  spender: string
  value?: string
}
export function useSingleSaleNFTApprove(
  paymentContract: Contract.IERC20_Old,
  userWalletAddress: string,
  options?: UseMutationOptions<any, Error, ERC20Approve>
) {
  const mutation = useMutation<any, Error, ERC20Approve>({
    mutationFn: async ({
      spender, // sale
      value = Number.MAX_SAFE_INTEGER.toString(), // that is not enough
    }: ERC20Approve) => {
      if (!paymentContract.options.address) return
      await paymentContract.methods.approve(spender, value).send({
        from: userWalletAddress,
        gasPrice: '500000000000',
      })
    },
    ...options,
  })

  return mutation
}

export function useSingleSaleNFTBuy(
  saleContract: Contract.Sales_Old,
  userWalletAddress: string,
  options?: UseMutationOptions<any, Error>
) {
  const mutation = useMutation<any, Error>({
    mutationFn: async () => {
      await saleContract.methods.buy().send({
        from: userWalletAddress,
        gasPrice: '500000000000',
      })
    },
    ...options,
  })

  return mutation
}

export function useSingleSaleNFTGetAllowance(
  paymentContract: Contract.IERC20_Old,
  owner: string,
  spender: string,
  options?: UseQueryOptions<BigNumber, Error>
) {
  const query = useQuery({
    queryKey: [paymentContract.options.address, spender, owner, 'allowance'],
    queryFn: async () => {
      if (!paymentContract.options.address) return new BigNumber(0)
      const allowance = await paymentContract.methods
        .allowance(owner, spender)
        .call()
      return new BigNumber(allowance)
    },
    ...options,
  })

  return query
}

export function useSingleSaleNFTGetDecimals(
  paymentContract: Contract.IERC20_Old,
  options?: UseQueryOptions<number, Error>
) {
  const query = useQuery({
    queryKey: [paymentContract.options.address, 'decimals'],
    queryFn: async () => {
      if (!paymentContract.options.address) return 0
      const decimals = await paymentContract.methods.decimals().call()
      return Number(decimals)
    },
    ...options,
  })

  return query
}

export function useSingleSaleNFTGetSymbol(
  paymentContract: Contract.IERC20_Old,
  options?: UseQueryOptions<string, Error>
) {
  const query = useQuery({
    queryKey: [paymentContract.options.address, 'symbol'],
    queryFn: async () => {
      if (!paymentContract.options.address) return ''
      return await paymentContract.methods.symbol().call()
    },
    ...options,
  })

  return query
}
