import { lazy, Suspense } from 'react'
import Styles from './lazyLogos.module.scss'

const Lazy = lazy(async () => {
  const LogoSVG = (await import('./Logos')).OneLogo
  return {
    default: () => <LogoSVG className={Styles.logo} />,
  }
})

export default function RarumByOne() {
  return (
    <Suspense fallback={null}>
      <Lazy />
    </Suspense>
  )
}
