import { ToggleButton } from '@material-ui/lab'
import CurrencyLabel from 'components/CurrencyLabel/CurrencyLabel.view'
import useStyles from './CurrencySelector.style'

export default function CurrencySelector({
  currencies,
  onSelected,
  selected,
}: {
  currencies: string[]
  onSelected: (currency: string) => void
  selected: string
}) {
  const classes = useStyles()
  return (
    <>
      <div>
        {currencies.map((currency) => (
          <ToggleButton
            classes={{
              root: classes.tag,
            }}
            selected={selected === currency}
            onClick={() => onSelected(currency)}
            value={currency}>
            <CurrencyLabel currency={currency} />
          </ToggleButton>
        ))}
      </div>
    </>
  )
}
