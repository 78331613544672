import { useTenant } from 'core/logic/tenant/tenant.hook'
import { PropsWithChildren, useEffect } from 'react'
import { i18n } from 'translate/i18n'

export default function LanguageProvider({ children }: PropsWithChildren<{}>) {
  const { tenant } = useTenant()
  useEffect(() => {
    if (tenant?.languages)
      if (!tenant.languages.includes(i18n.language))
        i18n.changeLanguage(tenant.languages[0])
  }, [tenant])
  return children
}
