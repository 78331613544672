import { makeStyles, Theme } from '@material-ui/core'
import { ActionButtonStyle } from './MultiStepOperation.template'

const useMultiStepStyles = makeStyles<Theme, { error?: boolean }>((t) => ({
  root: {
    display: 'flex',
    fontSize: '1rem',
    lineHeight: '2.14rem',
    minHeight: '85px',
    color: ({ error }) =>
      error ? t.palette.error.main : t.palette.text.primary,
    '--mutable-hamburger-background': ({ error }) =>
      error ? t.palette.error.main : t.palette.text.primary,
    alignItems: 'flex-start',
    position: 'relative',
    '& > :nth-child(1)': {
      fontSize: '1.5rem',
      marginRight: '14px',
      width: '1rem',
      minWidth: '1rem',
    },
    '& > :nth-child(2)': {
      width: '2rem',
      height: '2rem',
      minWidth: '2rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '1rem',
      border: ({ error }) =>
        `0.07rem solid ${
          error ? t.palette.error.main : t.palette.text.primary
        }`,
      marginRight: '20px',
    },
    '& > :nth-child(3)': {
      display: 'flex',
      flexDirection: 'column',
      '& button': {
        margin: '1.115rem 0px',
        paddingRight: '24px',
        paddingLeft: '24px',
        minWidth: '220px',
      },
    },
    '&:not(:last-child)::after': {
      position: 'absolute',
      content: "' '",
      height: 'calc(100% - 3.6rem)',
      width: '0.1rem',
      backgroundColor: ({ error }) =>
        error ? t.palette.error.main : t.palette.text.primary,
      left: `${1 + 0.875 + 0.07 + 1 - 0.08}rem`,
      top: '0px',
      marginTop: '2.8rem',
      marginBottom: '2.8rem',
    },
    '& button': {
      ...ActionButtonStyle(t),
    },
  },
  error: {},
}))

export default useMultiStepStyles
