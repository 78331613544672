export default {
  POLYGON: {
    unauthenticated: {
      faqview: {
        faq: {
          accordion: {
            content: {
              10: `La red {{name}} es una de las infraestructuras más prometedoras para NFT en la actualidad, con una gran comunidad de desarrolladores, empresas y organizaciones dedicadas a fortalecer y madurar la tecnología y sus aplicaciones.`,
            }
          }
        }
      }
    }
  },
  MOONBEAM: {
    unauthenticated: {
      faqview: {
        faq: {
          accordion: {
            content: {
              10: `{{name}} es una paracadena de Polkadot que se centra en la interoperabilidad y la compatibilidad con EVM. Es la parachain más grande en términos de volumen y transacciones en Polkadot, por eso la elegimos para lanzar esta tienda.`,
            }
          }
        }
      }
    }
  },
} as typeof import('./chain.pt.ts').default;