import { useEffect, useRef } from 'react'
import useWalletStyles from './WalletAvatar.styles'
const { createIcon } = require('@download/blockies')

export default function WalletAvatar({
  wallet,
  size = 32,
}: {
  wallet: string
  size?: number
}) {
  const container = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const ref = container.current!
    setTimeout(() => {
      ref.appendChild(
        createIcon({
          seed: wallet,
          size: 10,
        })
      )
    }, 1000)
    return () => {
      ref.children[0]?.remove()
    }
  }, [wallet])
  const classes = useWalletStyles({
    size,
  })
  return <div ref={container} className={classes.root} />
}
