import { SINGLE_TYPE } from 'core/constants'
import { usePurchase } from 'core/logic/purchase/purchase.hook'
import { analyticsEvent } from 'core/modules/analytics/events'
import {
  TO_DROP_OPEN,
  TO_DROP_OPENING,
  TO_MY_COLLECTION,
} from 'core/modules/router'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import PaymentView from './PendingPayment.view'
import useQuery from '@onepercentio/one-ui/dist/hooks/utility/useQuery'
import { PurchaseType } from 'core/logic/purchase/purchase.types'

const PendingPaymentLogic = () => {
  const history = useHistory()
  const goToPackOpen = useCallback(
    (deliveryId: string, TO_DROP: (dId: string) => string) =>
      history.push(TO_DROP(deliveryId)),
    [history]
  )
  const onLeaveClickHandler = () => history.push(TO_MY_COLLECTION())
  const uuid = uuidv4()

  const { purchaseId } = useParams<{ purchaseId: string }>()
  const { purchase } = usePurchase({ purchaseId })
  const deliveryId = purchase?.deliveryId
  const drop = purchase?.offer
  const payed = useQuery<{ payed: boolean }>().get('payed')

  const _purchase = useMemo(() => {
    if (!purchase) return purchase
    return {
      ...purchase,
      paymentStatus: payed
        ? purchase.paymentStatus === 'ACTIVE'
          ? 'PROCESSING'
          : purchase.paymentStatus
        : purchase.paymentStatus,
    } as PurchaseType
  }, [purchase, payed])

  const gotToPackSingle = useCallback(
    (type: string, deliveryId: string) => {
      return type === SINGLE_TYPE
        ? goToPackOpen(deliveryId, TO_DROP_OPENING)
        : goToPackOpen(deliveryId, TO_DROP_OPEN)
    },
    [goToPackOpen]
  )

  // Holds whether payment has already been confirmed or not
  const [paymentReceived, setPaymentReceived] = useState(false)

  // Listens for the deliveryId being added to the purchase, goes to opening when it does
  useEffect(() => {
    if (deliveryId && !paymentReceived) {
      setPaymentReceived(true)
      if (drop) {
        analyticsEvent.paymentConfirmed({ deliveryId, offer: drop })
        gotToPackSingle(drop.type, deliveryId)
      }
    }
  }, [gotToPackSingle, drop, deliveryId, paymentReceived])

  return (
    <PaymentView
      drop={drop}
      purchase={_purchase}
      onMyCollection={onLeaveClickHandler}
      uuid={uuid}
    />
  )
}

export default PendingPaymentLogic
