import Container from '@material-ui/core/Container'

const AuthenticatedView = () => {
  return (
    <main>
      <Container disableGutters={true} maxWidth='xl'>
        HI dear user
      </Container>
    </main>
  )
}

export default AuthenticatedView
