import useZoomable from '@onepercentio/one-ui/dist/hooks/ui/useZoomable'
import { useMergeRefsFunc } from '@onepercentio/one-ui/dist/hooks/useMergeRefs'
import { ForwardedRef, createRef, forwardRef, useMemo } from 'react'

export default forwardRef(
  (
    props: { src: string; className?: string },
    ref: ForwardedRef<HTMLImageElement>
  ) => {
    const id = useMemo(() => Date.now().toString(), []);
    const _ref = useMemo(() => ref || createRef(), [])
    const { zoomableEl } = useZoomable(id)
    const mergedRef = useMergeRefsFunc(_ref, zoomableEl)
    return (
      <img
        ref={mergedRef as any}
        alt=''
        src={props.src}
        className={props.className}
      />
    )
  }
)
