import React, {
  CSSProperties,
  ForwardedRef,
  PropsWithChildren,
  forwardRef,
} from 'react'
import Styles from './Card.module.scss'

/**
 * A simple card
 **/
function Card(
  {
    children,
    className = '',
    style,
  }: PropsWithChildren<{ className?: string; style?: CSSProperties }>,
  ref: ForwardedRef<HTMLDivElement>
) {
  return (
    <div className={`${Styles.root} ${className}`} style={style} ref={ref}>
      {children}
    </div>
  )
}

export default forwardRef(Card)
