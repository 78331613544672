import Skeleton from '@material-ui/lab/Skeleton'
import clsx from 'clsx'
import LiveBadge from 'pages/Unauthenticated/Drops/List/DropItem/LiveBadge'
import { DEFAULT_KEEP_PROPORTIONS, RARITY_OVERLAY } from './DropImage.data'
import useStyles from './DropImage.style'
import { DropImageProps } from './DropImage.types'
import ZoomableImage from 'components/ZoomableImage/ZoomableImage'

const DropImage: React.FC<DropImageProps> = ({
  src,
  live = false,
  figureClass,
  imageClass,
  dark = false,
  rarity,
  type = 'square',
  keepProportions = DEFAULT_KEEP_PROPORTIONS,
  zoomable = false,
}) => {
  const {
    figure,
    image,
    imageHor,
    imageVert,
    skeleton,
    darkSkeleton,
    liveBadge,
    imageRadius,
    overlay,
    imageOriginalProportion,
  } = useStyles({
    keepProportions,
  })

  const cardImage = `url('${src}')`
  let overlayImage
  if (rarity) {
    const overlay = RARITY_OVERLAY[rarity]
    overlayImage = `url('${overlay}')`
  }

  return (
    <figure className={clsx(figure, figureClass)}>
      {live && <LiveBadge className={liveBadge} />}
      {src ? (
        !keepProportions ? (
          <div
            className={clsx(
              image,
              type === 'square' ? imageHor : imageVert,
              imageClass,
              rarity ? imageRadius : null
            )}
            style={{ backgroundImage: cardImage }}>
            {overlayImage ? (
              <div
                className={clsx(
                  image,
                  type === 'square' ? imageHor : imageVert,
                  overlay,
                  imageClass
                )}
                style={{ backgroundImage: overlayImage }}
              />
            ) : null}
          </div>
        ) : zoomable ? (
          <ZoomableImage src={src} className={imageOriginalProportion} />
        ) : (
          <img src={src} className={imageOriginalProportion} />
        )
      ) : (
        <Skeleton
          variant='rect'
          className={clsx(
            skeleton,
            dark ? darkSkeleton : undefined,
            imageClass
          )}>
          {type === 'square' && <svg viewBox='0 0 1 1'></svg>}
          {type === 'vertical' && (
            <svg display='block' viewBox='0 0 1 1.475'></svg>
          )}
        </Skeleton>
      )}
    </figure>
  )
}

export default DropImage
