import { i18n } from 'translate/i18n'
import { useTheme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { Hidden } from 'augmentation/mui'
import Typography from '@material-ui/core/Typography'
import ClientLogo from 'components/ClientLogo'
import IconRarum from 'components/IconRarum'
import InfoModal from 'components/InfoModal'
import Logo from 'components/Logo'
import React, { ReactNode } from 'react'
import useStyles from './RarumHeader.style'

export interface RarumHeaderViewProps {
  modalContent: ReactNode
  modalOpen: boolean
  handleModalOpen: () => void
  handleModalClose: () => void
  disableRarumText?: boolean
  clientLogoSrc?: string
  showRarumLogo?: boolean
}
// modal content = texto explicativo
const RarumHeaderView: React.FC<RarumHeaderViewProps> = ({
  modalContent,
  modalOpen,
  handleModalOpen,
  handleModalClose,
  disableRarumText,
  clientLogoSrc,
  showRarumLogo = true,
}) => {
  const theme = useTheme()
  const classes = useStyles()

  // Client and Rarum logos
  const logos = (
    <Box display='flex' maxWidth='50%'>
      <ClientLogo clientLogoSrc={clientLogoSrc} />
      {showRarumLogo && (
        <Logo
          color={theme.palette.text.primary}
          className={classes.rarumLogo}
        />
      )}
    </Box>
  )

  // Rarum information button
  const help = (
    <Box
      display='flex'
      alignItems='center'
      ml={4}
      className={classes.pointer}
      onClick={handleModalOpen}>
      <IconRarum size={24} icon='help' className={classes.helpIcon} />
      {!disableRarumText ? (
        <Hidden smDown>
          <Typography
            variant='body2'
            color='textSecondary'
            className={classes.helpText}>
            {i18n.t('components.rarumHeader.whatIsRarum')}
          </Typography>
        </Hidden>
      ) : null}
    </Box>
  )

  return (
    <>
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        className={classes.topBox}>
        {logos}
        {help}
      </Box>
      <InfoModal
        title={i18n.t('components.rarumHeader.whatIsRarum')}
        open={modalOpen}
        handleClose={handleModalClose}
        content={modalContent}
      />
    </>
  )
}

export default RarumHeaderView
