import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import InfoModal from 'components/InfoModal'
import useStyles from '../Transfer.style'

interface TransferInfoProps {
  isOpen: boolean
  handleClose: () => void
}

const MoreInfoModal: React.FC<TransferInfoProps> = ({ isOpen, handleClose }) => {
  const classes = useStyles()

  const content = (
    <>
      <Typography variant='body1' className={classes.formGutter}>
        Preencha os campos a seguir com a quantidade de NFTs a transferir, e com o endereço de recebimento de sua carteira digital para
        fazer a transferência. Recomendamos que você verifique a seção Perguntas Frequentes caso tenha alguma dúvida sobre qual é o endereço
        de recebimento ou se sua carteira atual pode ser utilizada para receber este NFT.
      </Typography>
      <Typography variant='h3' className={clsx(classes.formGutter, classes.smallSpacing)}>
        Resgatando seu NFT
      </Typography>
      <Typography variant='body1' className={classes.formGutter}>
        <ol>
          <li>
            Baixe, instale e crie uma conta da carteira{' '}
            <a href='https://metamask.io/' target='_blank' rel='noreferrer'>
              MetaMask
            </a>{' '}
            em seu celular, disponível na Play Store ou Apple Store;
          </li>
          <li>Ao abrir sua carteira, você encontrará seu endereço de recebimento abaixo do nome da sua conta. Copie esse endereço;</li>
          <li>
            A seguir, pegue esse endereço e cole na caixa de texto que se encontra no site do seu NFT, aquele que você acessou através do
            QRCode. Clique em Transferir;
          </li>
        </ol>
      </Typography>
      <Typography variant='h3' className={clsx(classes.formGutter, classes.smallSpacing)}>
        Visualizando seu NFT
      </Typography>
      <Typography variant='body1' className={classes.formGutter}>
        <ol>
          <li>
            Pronto, você já está com seu NFT em sua carteira digital. Agora, para poder visualizá-lo, você deverá baixar um aplicativo
            chamado OpenSea, também disponível na{' '}
            <a href='https://apple.co/3lM8ACB' target='_blank' rel='noreferrer'>
              App Store
            </a>{' '}
            e na{' '}
            <a href='https://bit.ly/3CuqTTh' target='_blank' rel='noreferrer'>
              Play Store
            </a>
            ;
          </li>
          <li>
            Neste aplicativo, clique em Profile e, logo em seguida, selecione a carteira MetaMask para fazer a validação. Sua carteira
            MetaMask irá abrir automaticamente e solicitar uma permissão para conectar ao OpenSea, clique em conectar.
          </li>
          <li>Volte ao aplicativo OpenSea e pronto! Você já poderá visualizar seu NFT da RARUM!</li>
        </ol>
      </Typography>
    </>
  )
  return <InfoModal title='Transferência de NFT' open={isOpen} handleClose={handleClose} content={content} />
}
export default MoreInfoModal
