import { builder } from 'core/helpers'
import * as TenantTypes from './tenant.types'

// == TYPES ==
const TENANT_FETCH = 'tenant/fetch/FETCH'
const TENANT_FETCH_SUCCESS = 'tenant/fetch/SUCCESS'
const TENANT_FETCH_FAILED = 'tenant/fetch/FAILED'

export const types = Object.freeze({
  TENANT_FETCH: TENANT_FETCH,
  TENANT_FETCH_SUCCESS: TENANT_FETCH_SUCCESS,
  TENANT_FETCH_FAILED: TENANT_FETCH_FAILED,
})

// == ACTIONS ==
const fetchTenant = () => builder.actionCreator<TenantTypes.TenantActionsTypes>(TENANT_FETCH)

const fetchTenantSuccess = (tenant: TenantTypes.TenantType) => {
  localStorage.setItem('tenantAlias', tenant.displayName)
  return builder.actionCreator<TenantTypes.TenantActionsTypes, TenantTypes.TenantType>(TENANT_FETCH_SUCCESS, tenant)
}

const fetchTenantFailed = (errorKey: string) => builder.actionCreator<TenantTypes.TenantActionsTypes, string>(TENANT_FETCH_FAILED, errorKey)

export const actions = Object.freeze({
  fetchTenant,
  fetchTenantSuccess,
  fetchTenantFailed,
})

export const mapActions = Object.freeze({
  [TENANT_FETCH]: fetchTenant,
  [TENANT_FETCH_SUCCESS]: fetchTenantSuccess,
  [TENANT_FETCH_FAILED]: fetchTenantFailed,
})

// == REDUCER ==
export const initialState: Readonly<TenantTypes.TenantState> = Object.freeze({
  tenant: null,
  errorKey: '',
})

export const reducer = (
  state: Readonly<TenantTypes.TenantState> = initialState,
  action: Readonly<TenantTypes.TenantAction>
): TenantTypes.TenantState => {
  const { type, payload } = action
  switch (type) {
    case TENANT_FETCH:
      return { ...state, tenant: initialState.tenant }
    case TENANT_FETCH_SUCCESS:
      return { ...state, tenant: payload as TenantTypes.TenantType }
    case TENANT_FETCH_FAILED:
      return { ...state, errorKey: payload as string }
    default:
      return state
  }
}
