import React from 'react'
import View from './ChatThread.view'
import { ChatThreadProps } from './ChatThread.types'
import { useChatRoom } from 'context/ChatRoom'
import { useUser } from 'core/logic/user'
import useLocalStorage from '@onepercentio/one-ui/dist/hooks/persistence/useLocalStorage'
import { useHistory } from 'react-router-dom'
import { TO_SIGNIN } from 'core/modules/router'

export default function ChatThreadLogic(props: ChatThreadProps) {
  const history = useHistory()
  const { reply, loading, error } = useChatRoom()
  const { profile, detail } = useUser()
  const [storedMessage, setStoredMessage] = useLocalStorage(
    `chat-reply-${props.id}`,
    ''
  )
  return (
    <View
      {...props}
      loading={loading}
      error={error}
      initialMessage={storedMessage}
      onReply={(replyInfo) => {
        if (!profile) {
          history.push(TO_SIGNIN())
          setStoredMessage(replyInfo)
          return Promise.resolve()
        } else {
          return reply({
            text: replyInfo,
            threadId: props.id,
            user: {
              img: detail!.photoURL! || '',
              name: profile!.name,
            },
            createdAt: new Date(),
          })
        }
      }}
    />
  )
}
