import { makeStyles } from '@material-ui/core'

export default makeStyles((t) => ({
  content: {
    maxWidth: '640px',
    padding: '24px',
  },
  btn: {
    whiteSpace: 'pre',
    textAlign: 'right',
    padding: '12px 20px',
    lineHeight: '0.8rem',
    fontSize: '0.8rem',
    marginLeft: '24px',
    color: t.palette.oneui?.button.outline.default || t.palette.primary.contrastText,
  },
  modal: {
    padding: '24px',
    textAlign: 'center',
    '& button': {
      minWidth: '150px',
    },
  },
}))
