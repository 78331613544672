import { Divider, Grid } from '@material-ui/core'
import RarumHeader from 'components/RarumHeader'
import { useMemo } from 'react'
import OfferRequirements from '../OfferRequirements'
import { getStepComponent } from './stepMap'
import useStyles from './styles'
import { OfferAuctionBidModalViewProps } from './types'

export const OfferAuctionBidModalView: React.FC<
  OfferAuctionBidModalViewProps
> = ({ open, currentStep, ...props }) => {
  const classes = useStyles()
  const StepComponent = useMemo(
    () => getStepComponent(currentStep),
    [currentStep]
  )
  return (
    <Grid container direction='column'>
      <Grid item className={classes.head}>
        <RarumHeader showRarumLogo={false} />
      </Grid>
      <Divider />
      <OfferRequirements
        auction={props.offer}
        onCancel={props.handleModal(false)}>
        <StepComponent {...props} />
      </OfferRequirements>
    </Grid>
  )
}
