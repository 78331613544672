import { makeStyles } from '@material-ui/styles'

const _DEFAULT_TRANSITION =
  'transform 250ms linear, background-color 250ms linear, color 250ms linear, border-color 250ms linear'

export default makeStyles((theme) => ({
  'promo-title': {
    fontSize: '2.25em',
    lineHeight: '1.2em',
    letterSpacing: '0em',
    textAlign: 'left',
    fontWeight: 'bold',
  },
  'promo-body': {
    fontSize: '1.25em',
    lineHeight: '1.2em',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  'promo-label': {
    fontSize: '1em',
    lineHeight: '1.2em',
    letterSpacing: '0px',
    textAlign: 'left',
  },
  tooltip: {
    '--tooltip-background-color': theme.palette.background.paper,
    color: theme.palette.text.primary,
    zIndex: 10000000,
    pointerEvents: 'none !important' as any,
    '& > *': {
      pointerEvents: 'all',
      padding: '20px',
      textAlign: 'center',
      borderRadius: '16px',
      boxShadow: `0px 0px 8px -4px ${theme.palette.text.primary}`,
    },
  },
  control: {
    width: '55px',
    borderRadius: '50%',
    boxShadow: '0px 0px 12px -4px black',
  },
  selectDropdown: {
    backgroundColor: 'var(--secondary-background) !important',
    boxShadow: 'var(--card-elevation)',
    '--bg-color': 'var(--secondary-background)',
    borderRadius: '8px',
    zIndex: '1000000 !important' as any,
    '& > *': {
      padding: '8px 0px',
    },
  },
  selectItem: {
    textAlign: 'left',
    paddingRight: '16px',
    paddingLeft: '16px',
    backgroundColor: 'var(--bg-color) !important',
  },
  selectedItem: {
    backgroundColor: 'var(--digital-blue) !important',
    color: 'var(--digital-blue-contrast) !important',
  },
  controlContainer: {
    zIndex: 10000,
    cursor: 'pointer',
    transition:
      'transform 250ms linear, opacity 250ms linear, filter 250ms linear',
    pointerEvents: 'none',
    '& > *': {
      pointerEvents: 'all',
    },
    '&:hover': {
      transform: 'scaleY(1.5) scaleX(1.5)',
      filter: 'drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7))',
    },
    '&:active': {
      transform: 'scaleY(1.2) scaleX(1.2)',
    },
  },
  left: {
    transform: 'rotate(180deg)',
  },
  boldTitleBig: {
    fontFamily: theme.typography.font.secondary,
    fontSize: '32px',
    fontWeight: 'bold',
    lineHeight: '40px',
    letterSpacing: '0px',
  },
  subtitle: {
    fontFamily: theme.typography.font.primary,
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '24px',
    letterSpacing: '0px',
  },
  title: {
    fontFamily: theme.typography.font.primary,
    fontSize: '24px',
    fontWeight: 'bold',
    lineHeight: '24px',
    letterSpacing: '0px',
  },
  caption: {
    fontFamily: theme.typography.font.primary,
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '18px',
    letterSpacing: '0px',
  },
  content: {
    fontFamily: theme.typography.font.primary,
    fontSize: '16px',
    fontWeight: 'normal',
    lineHeight: '24px',
    letterSpacing: '0px',
  },
  h1: {
    fontFamily: theme.typography.font.secondary,
    fontSize: '48px',
    fontWeight: 'normal',
    lineHeight: '56px',
    letterSpacing: '0px',
    textAlign: 'left',
  },
  p12: {
    fontFamily: theme.typography.font.secondary,
    fontSize: '13px',
    lineHeight: '20px',
    letterSpacing: '0px',
  },
  p10: {
    fontFamily: theme.typography.font.primary,
    fontSize: '10px',
    lineHeight: '16px',
    letterSpacing: '0px',
    fontWeight: 'bold',
  },
  btnBase: {
    boxShadow: 'var(--button-box-shadow)',
    '&.icon': {
      display: 'flex',
      flexDirection: 'row',
      paddingLeft: 0,
      paddingRight: 0,
      justifyContent: 'space-between',
      '&:not(.right)>:first-child': {
        width: '3em',
        margin: '-3em 0.5em -3em 1em',
        height: '3em',
      },
      '&:not(.right)::after': {
        content: '" "',
        width: '0',
        margin: '-3em 0.5em -3em 1em',
        height: '3em',
      },
      '&.right': {
        '&>:last-child': {
          width: '3em',
          margin: '-3em 1em -3em 0.5em',
          height: '3em',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        '&::before': {
          content: '" "',
          width: '0',
          margin: '-3em 1em -3em 0.5em',
          height: '3em',
        },
      },
    },
    '--button-min-width': '0px',
    padding: '12px 32px',
    borderRadius: 32,
    fontSize: '0.8rem',
    minHeight: 0,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.oneui!.button.background,
    color: theme.palette.oneui!.button.textColor,
    fontFamily: theme.typography.font.primary,
    fontWeight: 'bold !important' as any,
    transition: _DEFAULT_TRANSITION,
    borderWidth: '2px',
    borderColor: 'transparent',
    '&:not(:disabled):active': {
      transform: 'scale(0.95)',
    },
    '& svg': {
      '--svg-color': theme.palette.oneui!.button.textColor,
      transition: 'fill 250ms linear',
    },
    letterSpacing: '1px',
    animationFillMode: 'forwards',
    '&:not(:disabled):hover': {
      animation: '$spaceSpring 200ms linear',
    },
    '&.fullWidth': {
      width: '100%',
    },
  },
  '@keyframes spaceSpring': {
    // '0%': { letterSpacing: '1px' },
    '50%': { transform: 'scaleX(1.1)' },
    '100%': { letterSpacing: 'scaleX(1)' },
  },
  btnFilled: {
    backgroundColor: theme.palette.oneui!.button.background,
    '&[color="primary"]:not(:hover)': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.oneui!.button.primary.textColor,
      '--loader--indicator-bg': theme.palette.oneui!.button.primary.textColor,
      '& svg': {
        color: theme.palette.oneui!.button.primary.textColor,
        '--svg-color': theme.palette.oneui!.button.primary.textColor,
      },
    },
    '&[color="error"]:not(:hover)': {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      '--loader--indicator-bg': theme.palette.error.main,
      '& svg': {
        color: theme.palette.error.contrastText,
        '--svg-color': theme.palette.error.contrastText,
      },
    },
    '&:not(:disabled):hover': {
      backgroundColor: theme.palette.oneui!.button.filled.hover.background,
      borderColor: theme.palette.oneui!.button.outline.default,
      '&': {
        color: theme.palette.oneui!.button.filled.hover.textColor,
      },
      '& svg': {
        '--svg-color': theme.palette.oneui!.button.filled.hover.textColor,
      },
      borderWidth: '2px',
    },
  },
  btnOutline: {
    backgroundColor: 'var(--button-transparent-bg)',
    '&:not(:hover)': {
      backgroundColor: '#0000',
      borderColor: theme.palette.oneui!.button.outline.default,
      color: theme.palette.oneui!.button.outline.default,
    },
    '&[color="primary"]': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main,
      '& svg': {
        '--svg-color': theme.palette.primary.main,
        color: theme.palette.primary.main,
      },
    },
    '&[color="inverse"]:not(:hover)': {
      borderColor:
        theme.palette.oneui!.button.inverseTextColor ||
        theme.palette.oneui!.button.textColor,
      color:
        theme.palette.oneui!.button.inverseTextColor ||
        theme.palette.oneui!.button.textColor,
    },
  },
  btnLightOutline: {
    backgroundColor: 'var(--button-transparent-bg)',
    borderColor: theme.palette.oneui!.button.outline.light.background,
    color: theme.palette.oneui!.button.outline.light.text,
    border: '2px solid',
    fontWeight: 'bold',
  },
  btnTransparent: {
    backgroundColor: '#0000',
    boxShadow: 'initial',
    '& svg': {
      '--svg-color': theme.palette.oneui!.button.outline.light.text,
    },
    '&:not(:hover):not(.active)': {
      color: theme.palette.oneui!.button.outline.light.text,
      '&[color="primary"]': {
        color: theme.palette.primary.main,
        '& svg': {
          '--svg-color': theme.palette.primary.main,
        },
      },
      '& svg': {
        '--svg-color': theme.palette.oneui!.button.outline.light.text,
      },
      '&[color="inverse"]': {
        color: theme.palette.oneui!.button.textColor,
      },
    },
    '&[color="inverse"]': {
      color: theme.palette.oneui!.button.textColor,
    },
    '&:not(:disabled):hover': {
      color: theme.palette.oneui!.button.outline.hover.textColor,
    },
    '&:not(:disabled):hover svg': {
      '--svg-color': theme.palette.oneui!.button.outline.hover.textColor,
    },
    '&[color="primary"]': {
      color: theme.palette.primary.main,
      '& svg': {
        '--svg-color': theme.palette.primary.main,
      },
    },
  },
  btnTransparentLink: {
    padding: '0px',
    '& > *': {
      pointerEvents: 'none',
    },
  },
  btnPromo: {
    color: theme.palette.background.default,
    background: `linear-gradient(265.91deg, ${theme.palette.promo.main} -14.23%, ${theme.palette.promo.secondary} 93.53%)`,
    padding: '20px 84px',
    '&:disabled': {
      opacity: 0.4,
    },
  },
  btnHighlight: {
    transition: `background 5s linear, ${_DEFAULT_TRANSITION}`,
    overflow: 'hidden',
    position: 'relative',
    background: 'transparent',
    color: theme.palette.oneui!.button.highlight.text,
    zIndex: 1,
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '120%',
      paddingBottom: '120%',
      zIndex: -1,
      background: `linear-gradient(268.68deg, ${
        theme.palette.oneui!.button.highlight.primary
      } 5.49%, ${
        theme.palette.oneui!.button.highlight.secondary
      } 70.95%) 0 0 repeat`,
      transition: 'transform 500ms ease-out',
      top: '-100%',
      left: '-10%',
    },
    '&:not(:disabled):hover::before': {
      transform: 'rotateZ(180deg)',
    },
  },
}))
