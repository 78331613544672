import { actions as deliveryActions } from 'core/logic/delivery/delivery.reducer'
import { RootState } from 'core/modules/redux'
import { useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DeliveryState } from './delivery.types'

export const useDeliveries = () => {
  const dispatch = useDispatch()
  const { deliveries } = useSelector<RootState, DeliveryState>(
    ({ delivery }) => delivery
  )
  useEffect(() => {
    dispatch(deliveryActions.emitterDeliveriesSubscribe())
    return () => {
      dispatch(deliveryActions.emitterDeliveriesUnsubscribe())
    }
  }, [dispatch])

  return { deliveries }
}

export const useDelivery = ({ deliveryId }: { deliveryId: string }) => {
  const dispatch = useDispatch()
  const { delivery } = useSelector<RootState, DeliveryState>(
    ({ delivery }) => delivery
  )
  useLayoutEffect(() => {
    dispatch(deliveryActions.emitterDeliverySubscribe(deliveryId))
    return () => {
      dispatch(deliveryActions.emitterDeliveryUnsubscribe())
    }
  }, [dispatch, deliveryId])

  return { delivery: delivery?.id === deliveryId ? delivery : null }
}
