import { Container } from '@material-ui/core'
import Button from '@onepercentio/one-ui/dist/components/Button'
import { PropsWithChildren, RefObject } from 'react'
import useStyles from './QuickResumeBanner.style'

export default function QuickResumeBannerBanner({
  actions,
  DecorationComp = () => null,
  children,
}: PropsWithChildren<{
  actions: {
    type?: OnepercentUtility.UIElements.ButtonVariants
    label: string
    action: () => void
    ref?: RefObject<HTMLElement>
  }[]
  DecorationComp?: React.FC
}>) {
  const classes = useStyles()
  return (
    <Container maxWidth='lg' className={classes.root}>
      <DecorationComp />
      <div className={classes.content}>
        {children}
        <div>
          {actions.map((a) => (
            <Button
              ref={a.ref as any}
              onClick={a.action}
              variant={a.type || 'filled'}>
              {a.label}
            </Button>
          ))}
        </div>
      </div>
    </Container>
  )
}
