import { AssetType } from 'core/logic/asset/asset.types'
import AssetItemView from './AssetItem.view'
import AssetItemShimmer from './Shimmer/AssetItemShimmer.view'
import { MarketplaceInfoResumeView, OfferInfo } from './AssetItem.type'
import { useHistory } from 'react-router-dom'
import ROUTES, { TO_ASSET_DETAIL } from 'core/modules/router'
import { i18n } from 'translate/i18n'

export interface AssetItemLogicProps {
  asset?: AssetType | null
  last?: boolean
  disabled?: boolean
  remainingSupply?: number
  marketplaceResume?: MarketplaceInfoResumeView
  offerInfo?: OfferInfo
  enableInteraction?: boolean
  'data-testid'?: string
  className?: string
}

const AssetItemLogic: React.FC<AssetItemLogicProps> = ({
  asset,
  last = false,
  marketplaceResume,
  offerInfo,
  enableInteraction = true,
  className = '',
  ...props
}) => {
  const history = useHistory()
  if (!asset) {
    return <AssetItemShimmer data-testid={props['data-testid']} last={last} />
  }

  const { id } = asset

  function goToOfferPage() {
    const route = ROUTES.unauthenticated.marketplaceAssetDetail.replace(
      ':assetId',
      asset!.id
    )
    history.push(route)
  }

  const intl = asset[i18n.language] || asset

  return (
    <AssetItemView
      className={className}
      data-testid={props['data-testid']}
      itemName={intl.name}
      itemImage={asset?.media.imageUrl}
      itemAnimation={asset?.media?.animationUrl}
      itemDescription={intl.description}
      last={last}
      id={id}
      marketplaceInfo={marketplaceResume}
      onGoToGallery={() => {
        if (enableInteraction) {
          if (marketplaceResume) goToOfferPage()
          else if (id) history.push(TO_ASSET_DETAIL(id))
        }
      }}
      offerInfo={offerInfo}
      onGoToMarketplace={goToOfferPage}
    />
  )
}

export default AssetItemLogic
