import React, { ElementRef, useRef } from 'react'
import View from './Home.view'
import { HomeProps } from './Home.types'
import Scheduler from 'openspace/components/Scheduler/Scheduler'
import { useHistory } from 'react-router-dom'
import { TO_CREATORS_SECTION } from 'core/modules/router'

export default function HomeLogic(props: HomeProps) {
  const schedulerRef = useRef<ElementRef<typeof Scheduler>>(null)
  const history = useHistory()
  return (
    <>
      <View
        onScheduleDemo={() => schedulerRef.current!.open()}
        onGoToCreators={(s) => history.push(TO_CREATORS_SECTION(s))}
      />
      <Scheduler ref={schedulerRef} />
    </>
  )
}
