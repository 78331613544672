import { OwnOfferAsset, AssetWithBalance } from 'core/logic/asset/asset.types'
import { i18n } from 'translate/i18n'
import CollectionItemView from './CollectionItem.view'
import CollectionItemShimmer from './Shimmer/CollectionItemShimmer.view'
import { PendingPurchase } from 'core/logic/purchase/purchase.types'
import { DeliveryType } from 'core/logic/delivery/delivery.types'
import { useMemo } from 'react'
import { DropTypeBase } from 'core/logic/drop/drop.types'
import { CollectionItemType } from './CollectionItem.types'

export interface DropItemLogicProps {
  assetOrDeliveryOrPurchase?:
    | AssetWithBalance
    | OwnOfferAsset
    | PendingPurchase
    | DeliveryType
  last?: boolean
  disabled?: boolean
  showStickyTop?: boolean
  orientation?: string
  onClick?: (id: string) => void
}

const CollectionItemLogic: React.FC<DropItemLogicProps> = ({
  assetOrDeliveryOrPurchase,
  last = false,
  orientation,
  onClick,
}) => {
  const { asset, type } = useMemo(() => {
    if (!assetOrDeliveryOrPurchase) return {}
    if (
      'type' in assetOrDeliveryOrPurchase &&
      assetOrDeliveryOrPurchase.type === 'airdrop'
    ) {
      return {
        asset: assetOrDeliveryOrPurchase.asset!,
        type: CollectionItemType.DELIVERY,
      }
    }
    if (
      'offer' in assetOrDeliveryOrPurchase &&
      !!assetOrDeliveryOrPurchase.offer
    ) {
      const offer = assetOrDeliveryOrPurchase.offer as DropTypeBase
      return {
        asset: offer.asset,
        type:
          'paymentStatus' in assetOrDeliveryOrPurchase
            ? CollectionItemType.PURCHASE
            : CollectionItemType.DELIVERY,
      }
    } else {
      return {
        asset: assetOrDeliveryOrPurchase,
        type: CollectionItemType.ASSET,
      }
    }
  }, [assetOrDeliveryOrPurchase])

  const purchaseOrDeliveryInfo = useMemo(() => {
    return {
      ...(type === CollectionItemType.DELIVERY
        ? {
            type,
            delivery: assetOrDeliveryOrPurchase as DeliveryType,
          }
        : type === CollectionItemType.PURCHASE
        ? {
            type,
            purchase: assetOrDeliveryOrPurchase as PendingPurchase,
          }
        : {
            type: type!,
          }),
    }
  }, [type, assetOrDeliveryOrPurchase])

  if (!asset) {
    return <CollectionItemShimmer last={last} />
  }

  const { name, description } = asset[i18n.language] ?? asset

  const {
    media: { coverUrl, animationUrl, imageUrl },
    balance,
    id,
  } = asset

  return (
    <CollectionItemView
      itemName={name}
      itemImage={coverUrl || imageUrl}
      itemAnimation={animationUrl}
      itemDescription={description}
      itemCount={balance}
      last={last}
      id={id}
      orientation={orientation}
      onClick={onClick}
      offerDetails={asset.offerResume}
      asset={asset}
      {...purchaseOrDeliveryInfo}
    />
  )
}

export default CollectionItemLogic
