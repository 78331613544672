import IAuctionFacade from 'service/IAuctionFacade'
import * as dropService from 'core/logic/drop/drop.service'
import { OfferType } from 'core/logic/drop/drop.types'
import { TenantType } from 'core/logic/tenant/tenant.types'
import { doBid } from 'core/modules/firebase/service'
import Web3 from 'web3'

export default class OffChainAuctionFacade extends IAuctionFacade {
  private tenant: TenantType
  constructor(
    drop: OfferType,
    tenant: TenantType,
    web3ForRead: Web3,
    web3ForWrite: Web3
  ) {
    super(drop, web3ForRead, web3ForWrite)
    this.tenant = tenant
  }
  getAuctionPeriod: IAuctionFacade['getAuctionPeriod'] = () => {
    return Promise.resolve([this.offerType.begin, this.offerType.expire!])
  }
  watchBids: IAuctionFacade['watchBids'] = (onBids) => {
    return dropService.fetchBidsDrop({
      dropId: this.offerType.id,
      emitter: (bids) => {
        onBids(
          bids?.map((bid) => ({
            ...bid,
            currency: this.tenant.currencies!.preferredCurrency,
          }))
        )
      },
    })
  }
  getInformation: IAuctionFacade['getInformation'] = async () => {
    return {
      currency: this.tenant.currencies!.preferredCurrency,
      getBidRange: (latestBid) => {
        const limit = {
          min: 100 / (this.offerType.auction.minPercentageIncrement * 100),
          max: 100 / (this.offerType.auction.maxPercentageIncrement * 100),
        }
        return this.calculateRange(
          this.offerType.minPrice!,
          latestBid?.amount ? latestBid?.amount : undefined,
          limit
        ) as [number, number]
      },
      getBidPercentRange: () => {
        return [
          this.offerType.auction.minPercentageIncrement * 100,
          this.offerType.auction.maxPercentageIncrement * 100,
        ]
      },
    }
  }
  getBalance: IAuctionFacade['getBalance'] = async () => {
    return Number.POSITIVE_INFINITY
  }
  createBid: IAuctionFacade['createBid'] = async (amount, phoneNumber) => {
    return doBid({
      amount: Math.trunc(amount * 100),
      offerId: this.offerType.id,
      phoneNumber,
    }).then((response) => {
      if (response.error) {
        let error = new Error(response.error.message)
        ;(error as any).details = {
          code: response.error.code,
        }
        throw error
      }
    })
  }
  isBidOwned: IAuctionFacade['isBidOwned'] = (user, bid) => {
    return bid.user.id === user?.id
  }
}
