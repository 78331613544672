import { useTheme } from '@material-ui/core'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles'
import useLocalStorage from '@onepercentio/one-ui/dist/hooks/persistence/useLocalStorage'
import OneUIProvider from 'containers/OneUIProvider'
import theme, { alternativeMode, defaultMode } from 'core/modules/mui/theme'
import { PropsWithChildren, createContext, useContext, useMemo } from 'react'

type ThemeModes = 'dark' | 'light'

export const ThemeContext = createContext<{
  switchTheme(mode: ThemeModes): void
}>(null as any)

export default function ThemeProvider({ children }: PropsWithChildren<{}>) {
  const _superTheme = useTheme() as any
  const superTheme =
    Object.keys(_superTheme ?? {}).length === 0 ? undefined : _superTheme
  const superSwitch = useThemeContext()
  const [mode, setMode] = useLocalStorage<ThemeModes>('theme-mode', defaultMode)

  const currentTheme = useMemo(() => {
    if (mode !== defaultMode) return alternativeMode
    else return theme
  }, [mode])

  const switchValue = useMemo(() => {
    if (superSwitch) return superSwitch
    return { switchTheme: setMode }
  }, [superSwitch, setMode])

  return (
    <ThemeContext.Provider value={switchValue}>
      <MuiThemeProvider theme={superTheme ?? currentTheme}>
        <OneUIProvider>{children}</OneUIProvider>
      </MuiThemeProvider>
    </ThemeContext.Provider>
  )
}

export function useThemeContext() {
  return useContext(ThemeContext)
}
