import { makeStyles } from '@material-ui/core'

const useMarketplaceDetailsStyle = makeStyles((theme) => ({
  row: {
    display: 'flex',
    whiteSpace: 'nowrap',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  small: {
    fontSize: '0.75rem',
  },
  big: {
    fontSize: '1.50rem',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'right',
  },
  chip: {
    borderRadius: '4px',
    borderColor: 'white',
    minWidth: '160px',
    fontStyle: 'initial',
  },
  button: {
    maxWidth: '250px',
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.background.default,
    borderRadius: '32px',
    margin: 'auto',
    marginTop: '38px',
    marginBottom: '24px',
    width: '100%',
    fontStyle: 'initial',
  },
}))

export default useMarketplaceDetailsStyle
