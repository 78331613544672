import { builder } from 'core/helpers'
import * as CountersTypes from './counters.types'

// == TYPES ==
const COUNTERS_EMITTER_SUBSCRIBE = 'counters/emitter/SUBSCRIBE'
const COUNTERS_EMITTER_UNSUBSCRIBE = 'counters/emitter/UNSUBSCRIBE'
const COUNTERS_FETCH_SUCCESS = 'counters/fetch/SUCCESS'
const COUNTERS_FETCH_FAILED = 'counters/fetch/FAILED'

export const types = Object.freeze({
  COUNTERS_EMITTER_SUBSCRIBE: COUNTERS_EMITTER_SUBSCRIBE,
  COUNTERS_EMITTER_UNSUBSCRIBE: COUNTERS_EMITTER_UNSUBSCRIBE,
  COUNTERS_FETCH_SUCCESS: COUNTERS_FETCH_SUCCESS,
  COUNTERS_FETCH_FAILED: COUNTERS_FETCH_FAILED,
})

// == ACTIONS ==
const emitterCountersSubscribe = (dropIds: string[]) =>
  builder.actionCreator<CountersTypes.CountersActionsTypes, string[]>(
    COUNTERS_EMITTER_SUBSCRIBE,
    dropIds
  )

const emitterCountersUnsubscribe = () =>
  builder.actionCreator<CountersTypes.CountersActionsTypes>(
    COUNTERS_EMITTER_UNSUBSCRIBE
  )

const fetchSuccess = (counters: CountersTypes.CountersStateType) =>
  builder.actionCreator<
    CountersTypes.CountersActionsTypes,
    CountersTypes.CountersStateType
  >(COUNTERS_FETCH_SUCCESS, counters)

const fetchFailed = (errorKey: string) =>
  builder.actionCreator<CountersTypes.CountersActionsTypes, string>(
    COUNTERS_FETCH_FAILED,
    errorKey
  )

export const actions = Object.freeze({
  emitterCountersSubscribe,
  emitterCountersUnsubscribe,
  fetchSuccess,
  fetchFailed,
})

export const mapActions = Object.freeze({
  [COUNTERS_EMITTER_SUBSCRIBE]: emitterCountersSubscribe,
  [COUNTERS_EMITTER_UNSUBSCRIBE]: emitterCountersUnsubscribe,
  [COUNTERS_FETCH_FAILED]: fetchFailed,
  [COUNTERS_FETCH_SUCCESS]: fetchSuccess,
})

// == REDUCER ==
export const initialState: Readonly<CountersTypes.CountersState> =
  Object.freeze({
    counters: {},
    errorKey: '',
  })

export const reducer = (
  state: Readonly<CountersTypes.CountersState> = initialState,
  action: Readonly<CountersTypes.CountersAction>
): CountersTypes.CountersState => {
  const { type, payload } = action
  switch (type) {
    case COUNTERS_FETCH_SUCCESS:
      return {
        ...state,
        counters: (payload || {}) as CountersTypes.CountersStateType,
      }
    case COUNTERS_FETCH_FAILED:
      return { ...state, errorKey: payload as string }
    default:
      return state
  }
}
