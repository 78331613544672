import { useUser } from 'core/logic/user'
import { auth } from 'core/modules/firebase'
import { IdTokenResult } from 'firebase/auth'
import {
  PropsWithChildren,
  useMemo,
  useState,
  useEffect,
  createContext,
  useContext,
} from 'react'

const ProfileJWTContext = createContext<IdTokenResult | undefined>(null as any)

/**
 * This context implements the wrapper around the Profile operations and automation
 */
export default function ProfileContextWrapper({
  children,
}: PropsWithChildren<{}>) {
  const [tokenData, setTokenData] = useState<IdTokenResult>()
  const { profile } = useUser()

  useMemo(() => {
    if (profile?.isLoaded && auth.currentUser)
      auth.currentUser.getIdTokenResult().then(setTokenData)
  }, [profile?.isLoaded])

  /** This guarantees the token has the updated information */
  useEffect(() => {
    if (
      profile?.isLoaded &&
      tokenData &&
      profile.wallet &&
      tokenData.claims.wallet !== profile.wallet
    ) {
      setTimeout(() => {
        if (auth.currentUser)
          auth.currentUser.getIdTokenResult(true).then(setTokenData)
      }, 5000)
    }
  }, [tokenData, profile])

  return (
    <ProfileJWTContext.Provider value={tokenData}>
      {children}
    </ProfileJWTContext.Provider>
  )
}

export function useJwtWallet() {
  return useContext(ProfileJWTContext)?.claims.wallet as string | undefined
}

/** This effect only runs when the JWT wallet is defined */
export function useJwtWalletRequiredEffect(
  effect: Parameters<typeof useEffect>[0],
  deps: Parameters<typeof useEffect>[1] = []
) {
  const jwtWallet = useJwtWallet()
  const { profile } = useUser()

  useEffect(() => {
    if (jwtWallet && profile?.wallet === jwtWallet) {
      return effect()
    }
  }, [jwtWallet, profile?.wallet, ...deps])
}
