import useLocalStorage from '@onepercentio/one-ui/dist/hooks/persistence/useLocalStorage'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

export enum CreatorsSection {
  CREATOR = 'creators',
  BRAND = 'brand',
}

export function useCreatorsSection() {
  const [currOption, setCurrOption] = useLocalStorage(
    'creator_option',
    CreatorsSection.CREATOR
  )
  const { section } = useParams<{
    section?: CreatorsSection
  }>()

  useEffect(() => {
    if (section) setCurrOption(section as CreatorsSection)
  }, [section])

  return [section || currOption, setCurrOption] as const
}
