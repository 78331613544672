import { Channel, Task } from '@redux-saga/types'
import {
  actions as countersActions,
  types as countersActionTypes,
} from 'core/logic/counters/counters.reducer'
import { eventChannel } from 'redux-saga'
import { call, cancel, put, take, takeLatest } from 'redux-saga/effects'
import * as countersService from './counters.service'
import { CountersStateType } from './counters.types'

export async function countersUpdatesEmitter({
  dropIds,
}: {
  dropIds: string[]
}) {
  return eventChannel<CountersStateType>((emitter) => {
    const unsubscribe = countersService.fetch({ dropIds, emitter })
    return () => {
      unsubscribe()
    }
  })
}

export function* countersSyncGenerator({ dropIds }: { dropIds: string[] }) {
  const emitterInterface: Channel<any> = yield call(countersUpdatesEmitter, {
    dropIds,
  })
  try {
    while (true) {
      const countersChanged: CountersStateType = yield take(emitterInterface)
      yield put(countersActions.fetchSuccess(countersChanged))
    }
  } catch (_e) {
    yield put(countersActions.fetchFailed('counters.subscribe'))
  } finally {
    emitterInterface.close()
  }
}

function* startCountersSyncChannel({
  payload,
}: ReturnType<typeof countersActions.emitterCountersSubscribe>) {
  if (!payload) throw new Error('Drop IDs missing')
  const countersSyncTask: Task = yield call(countersSyncGenerator, {
    dropIds: payload,
  })
  yield take(countersActionTypes.COUNTERS_EMITTER_UNSUBSCRIBE)
  yield cancel(countersSyncTask)
}

export function* watchCountersSubscribe() {
  yield takeLatest(
    countersActionTypes.COUNTERS_EMITTER_SUBSCRIBE,
    startCountersSyncChannel
  )
}
