import { Box, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { DROP_STATUS } from 'core/helpers/drop'
import { numberFormatter } from 'core/helpers/formatter'
import { DropType } from 'core/logic/drop/drop.types'
import React from 'react'
import { i18n } from 'translate/i18n'
import useStyles from './Availability.style'

export interface AvailabilityProps {
  availability?: DROP_STATUS
  issued?: number
  remaining?: number
  color?: string
  type?: DropType['type']
}

const Availability: React.FC<AvailabilityProps> = ({
  color,
  issued,
  remaining,
  availability,
  type,
}) => {
  const { units, availableText, unavailableText } = useStyles()

  const isExpired = availability === 'expired' || availability === 'out'
  const isOpen = availability === 'open'
  const isOutOfSupply = availability === 'out'

  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
      {issued && type !== 'onchain' && (
        <Typography
          variant='body2'
          color='textSecondary'
          className={isExpired ? clsx(unavailableText, units) : units}
          style={{ color }}>
          {`${i18n.t('components.availability.units')}: ${numberFormatter(
            issued
          )}`}
        </Typography>
      )}
      {(isOpen || isOutOfSupply) && remaining !== undefined && (
        <Typography
          variant='body2'
          className={clsx(isOpen ? availableText : unavailableText, units)}
          style={{ color }}>
          {`${i18n.t('components.availability.remaining')}: ${numberFormatter(
            remaining
          )}`}
        </Typography>
      )}
    </Box>
  )
}
export default Availability
