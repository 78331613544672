import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(4),
    },
    qrcode: {
      backgroundColor: theme.palette.common.white,
      borderRadius: 8,
    },
    alert: {
      '&>.MuiAlert-icon': {
        alignSelf: 'center',
      },
    },
    spacing: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    paymentPane: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(4),
      },
    },
    stripePaymentPane: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      justifyItems: 'center',
    },
    spinner: {
      alignSelf: 'center',
    },
    field: {
      flexGrow: 1,
      maxWidth: '35ch',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
    card: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
    },
    cardContent: {
      width: '600px',
    },
    formContainer: {
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      flexDirection: 'column',
      border: `1px solid ${theme.palette.grey[200]}`,
      borderRadius: 4,
      padding: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(5),
      },
    },
    expiredContainer: {
      width: '100%',
      border: `1px solid ${theme.palette.grey[200]}`,
      borderRadius: 4,
    },
  })

export default makeStylesWrapper(style)
