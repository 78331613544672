import { DropType } from 'core/logic/drop/drop.types'
import { DeliveryType } from 'core/logic/delivery/delivery.types'
import { PaymentMethods } from 'pages/Authenticated/Payment/Payment.types'
import { analytics } from '../firebase'

enum EVENT {
  EXCEPTION = 'exception',
  LOGIN = 'login',
  SIGN_UP = 'sign_up',
  PAYMENT = 'add_payment_info',
  PURCHASE = 'purchase',
  BEGIN_CHECKOUT = 'begin_checkout',
  CHECKOUT = 'checkout_progress',
  SCREEN_VIEW = 'screen_view',
  SHARE = 'share',
  VIEW_ITEM = 'view_item',
  OPEN_PACK = 'open_pack',
  CLICK_BANNER = 'click_banner',
  UNKNOWN_DROP = 'unknown_drop',
}

export enum SCREENS {
  ABOUT_RARUM = 'About Rarum',
}

const e = (...args: any[]) => analytics.logEvent(...args)

const mapOfferToItem = (offer: DropType) => ({
  item_id: offer.id,
  item_name: offer.title,
  price: offer.unitPrice,
  quantity: 1,
})

export const analyticsEvent = {
  // To be fired whenever a blocking error is shown to the user
  error: ({ description, fatal }: { description: string; fatal?: boolean }) =>
    e(EVENT.EXCEPTION, {
      description,
      fatal,
      tenant: localStorage.getItem('tenantAlias'),
    }),
  // When user successfully logs in
  login: ({ method }: { method: 'google' | 'facebook' | 'user_password' }) =>
    e(EVENT.LOGIN, {
      method,
      tenant: localStorage.getItem('tenantAlias'),
    }),
  // When user clicks a sign-up button
  signUp: ({
    method,
  }: {
    method: 'google' | 'facebook' | 'user_password' | 'metamask'
  }) =>
    e(EVENT.SIGN_UP, {
      method,
      tenant: localStorage.getItem('tenantAlias'),
    }),
  // When user sends credit card info
  doPayment: ({ offer }: { offer: DropType }) =>
    e(EVENT.PAYMENT, {
      items: [mapOfferToItem(offer)],
      tenant: localStorage.getItem('tenantAlias'),
    }),
  // When payment is confirmed and the purchase is finished
  paymentConfirmed: ({
    deliveryId,
    offer,
  }: {
    deliveryId: string
    offer: DropType
  }) =>
    e(EVENT.PURCHASE, {
      transaction_id: deliveryId,
      value: offer.unitPrice,
      currency: offer.currency,
      items: [mapOfferToItem(offer)],
      tenant: localStorage.getItem('tenantAlias'),
    }),
  // When user enters the checkout process
  doCheckout: ({ offer }: { offer: DropType }) =>
    e(EVENT.BEGIN_CHECKOUT, {
      currency: offer.currency,
      value: offer.unitPrice,
      items: [mapOfferToItem(offer)],
      tenant: localStorage.getItem('tenantAlias'),
    }),
  // During the checkout steps
  checkoutStep: ({
    step,
    metodo,
    offer,
  }: {
    step: number
    metodo?: PaymentMethods
    offer: DropType
  }) =>
    e(EVENT.CHECKOUT, {
      currency: offer.currency,
      ...(metodo ? { checkout_option: metodo } : {}),
      checkout_step: step,
      value: offer.unitPrice,
      items: [mapOfferToItem(offer)],
      tenant: localStorage.getItem('tenantAlias'),
    }),
  // When user views screens that do not have exclusive routes.
  viewScreen: ({ name }: { name: SCREENS }) =>
    e(EVENT.SCREEN_VIEW as never, {
      app_name: process.env.REACT_APP_TENANT_IDENTITY_ID,
      screen_name: name,
      tenant: localStorage.getItem('tenantAlias'),
    }),
  // When user shares content
  share: ({
    method,
    type,
    id,
  }: {
    method: 'Facebook' | 'Twitter' | 'WhatsApp' | 'Clipboard' | 'Mobile Share'
    type: 'offer'
    id: string
  }) =>
    e(EVENT.SHARE, {
      method,
      content_type: type,
      content_id: id,
      tenant: localStorage.getItem('tenantAlias'),
    }),
  // When user views an offer
  viewOffer: ({ offer }: { offer: DropType }) =>
    e(EVENT.VIEW_ITEM, {
      items: [mapOfferToItem(offer)],
      tenant: localStorage.getItem('tenantAlias'),
    }),
  // When user opens a pack
  openPack: ({ pack }: { pack: DeliveryType }) =>
    e(EVENT.OPEN_PACK, {
      ...(pack.assets
        ? {
            items: pack.assets.map((asset) => ({
              id: asset.id,
              name: asset.name,
            })),
          }
        : {}),
      tenant: localStorage.getItem('tenantAlias'),
    }),
  // When user clicks the homepage banner, optionally for a specific offer
  clickBanner: ({ offer }: { offer?: DropType | null }) =>
    e(EVENT.CLICK_BANNER, {
      ...(offer ? { offer_id: offer.id } : {}),
      tenant: localStorage.getItem('tenantAlias'),
    }),

  unknownDrop: (dropId: string) => {
    e(EVENT.UNKNOWN_DROP, {
      dropId,
      tenant: process.env.REACT_APP_TENANT_IDENTITY_ID,
    })
  },
}
