/**
 * Groups elements. Based on: https://gist.github.com/robmathers/1830ce09695f759bf2c4df15c29dd22d#gistcomment-3553222
 * The order is preserved inside each group.
 * @param arr Array of elements to be grouped
 * @param iteratee Accessor used to define each group
 * @returns An object with groups
 */
export const groupBy = <T, K>(arr: T[], iteratee: (el: T) => K): { [key: string]: T[] } => {
  return arr.reduce((storage, item) => {
    const accessor = `${iteratee(item)}`
    if (storage[accessor]) {
      storage[accessor].push(item)
    } else {
      storage[accessor] = [item]
    }
    return storage
  }, {} as { [key: string]: T[] })
}

/**
 * Splits an array into multiple chunks
 * From: https://stackoverflow.com/a/68395393/4615065
 * @param arr Input array
 * @param bulkSize Chunk size
 * @returns Array of arrays
 */
export function splitToChunks<T>(arr: T[], bulkSize: number = 20): T[][] {
  const bulks: T[][] = []
  for (let i = 0; i < Math.ceil(arr.length / bulkSize); i++) {
    bulks.push(arr.slice(i * bulkSize, (i + 1) * bulkSize))
  }
  return bulks
}
