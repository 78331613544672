import { AssetsListViewProps } from './AssetsList.types'
import DropItem from 'openspace/components/DropItem'
import { BaseShortAssetWidth } from '../GalleryCard.view'
import PaginableContainer from 'openspace/components/PaginableContainer'
import { DropItemViewMode } from 'openspace/components/DropItem/DropItem.types'

/**
 * Shows the list of assets based off a gallery
 **/
export default function AssetsListView({ src }: AssetsListViewProps) {
  return (
    <PaginableContainer
      src={src}
      jsx={{
        width: BaseShortAssetWidth,
        factory: (i) => (
          <DropItem
            key={i.id}
            drop={i}
            mode={DropItemViewMode.SHORT}
            availability='open'
          />
        ),
      }}
    />
  )
}
