import { builder } from 'core/helpers'
import * as UserTypes from './user.types'

// == TYPES ==
export const LOGOUT = 'user/LOGOUT'
const REMOVE = 'user/REMOVE'
export const types = Object.freeze({
  LOGOUT,
  REMOVE,
})

// == ACTIONS ==
const logout = () => builder.actionCreator<UserTypes.UserActionsTypes>(LOGOUT)
const remove = () => builder.actionCreator<UserTypes.UserActionsTypes>(REMOVE)

export const actions = Object.freeze({
  logout,
  remove,
})

export const userMapActions = Object.freeze({
  [LOGOUT]: logout,
  [REMOVE]: remove,
})

// == REDUCER ==
export const initialState: Readonly<UserTypes.UserState> = Object.freeze({})
export const reducer = (
  state: Readonly<UserTypes.UserState> = initialState,
  action: Readonly<UserTypes.UserAction>
): UserTypes.UserState => {
  const { type } = action
  switch (type) {
    default:
      return state
  }
}
