import { useTheme } from '@material-ui/core'
import BucketFill from '@onepercentio/one-ui/dist/components/BucketFill'
import { IgnoreFill } from '@onepercentio/one-ui/dist/components/BucketFill/BucketFill'
import Button from '@onepercentio/one-ui/dist/components/Button'
import MutableHamburgerButton from '@onepercentio/one-ui/dist/components/MutableHamburgerButton'
import { ComponentProps, ReactNode } from 'react'
import useMultiStepStyles from './MultiStepOperation.style'

export function MultiStepOperation({
  currentStep,
  children,
}: {
  currentStep: number
  children: ReactNode[]
}) {
  const background = useTheme().palette.background.default
  return (
    <BucketFill fillTo={currentStep} background={background}>
      {children as any}
    </BucketFill>
  )
}
type BaseProps = {
  error?: boolean
  stepNum: number
  stepName: string
  stepDescription?: ReactNode
}
type ActionProps = {
  status: ComponentProps<typeof MutableHamburgerButton>['state']
  action: {
    label: string
    onClick: () => void
    disabled: boolean
  }
}

function BaseStep({
  stepNum,
  stepName,
  action,
  status,
  error,
  stepDescription,
}: BaseProps & Partial<ActionProps>) {
  const classes = useMultiStepStyles({
    error,
  })
  return (
    <div className={classes.root}>
      <span>{stepNum}</span>
      <span>
        <MutableHamburgerButton state={status} size={16} />
      </span>
      <span>
        <span>{stepName}</span>
        {stepDescription}
        {action && (
          <IgnoreFill>
            <Button
              variant='filled'
              onClick={action.onClick}
              disabled={action.disabled}>
              {action.label}
            </Button>
          </IgnoreFill>
        )}
      </span>
    </div>
  )
}

export function StaticStep(props: BaseProps) {
  return <BaseStep {...props} status='checkmark' />
}

export function ActionStep(props: BaseProps & ActionProps) {
  return <BaseStep {...props} />
}
