import React from 'react'
import { AssetDisplayMode, AssetDisplayViewProps } from './AssetDisplay.types'
import Styles from './AssetDisplay.module.scss'
import Player from 'components/Player'
import useHero from '@onepercentio/one-ui/dist/hooks/useHero'
import ZoomableImage from 'components/ZoomableImage/ZoomableImage'

type HeroImgProps = {
  src: string
  uniqueId: string
  zoomable?: boolean
}

function HeroImg({ src, uniqueId, zoomable }: HeroImgProps) {
  const { heroRef } = useHero(uniqueId, undefined, {
    onHeroDetect: (el) => {
      if (
        el.parentElement!.parentElement!.getAttribute('data-hero') ===
        heroRef.current!.parentElement!.parentElement!.getAttribute('data-hero')
      )
        return false
      const isInsideZoomable =
        !!el.parentElement?.getAttribute('data-rmiz-wrap') &&
        !!document.querySelector('[data-rmiz-overlay="true"]')
      return !isInsideZoomable
    },
  })

  return zoomable ? (
    <ZoomableImage ref={heroRef as any} src={src} className={Styles.root} />
  ) : (
    <img ref={heroRef as any} src={src} alt='' className={Styles.root} />
  )
}

/**
 * Displays the asset image in a specific manner defined by how the resource has been configured
 **/
export default function AssetDisplayView(props: AssetDisplayViewProps) {
  switch (props.mode) {
    case AssetDisplayMode.VIDEO:
      return (
        <Player
          controls={true}
          poster={props.thumbnail}
          src={props.src}
          className={Styles.root}
        />
      )
    case AssetDisplayMode.IMAGE:
      return (
        <HeroImg
          src={props.src}
          uniqueId={String(props.uniqueId)}
          zoomable={props.zoomable}
        />
      )
    case AssetDisplayMode.ANIMATION:
      return (
        <video
          controls={false}
          autoPlay
          playsInline
          muted
          loop
          src={props.src}
          className={Styles.root}
        />
      )
  }
  return <></>
}
