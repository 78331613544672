import { Theme } from '@material-ui/core'
import createStyles from '@material-ui/styles/createStyles'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) => {
  return createStyles({
    item: {
      textAlign: 'right',
      fontWeight: 600,
      marginRight: 8,
      padding: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px ${theme.spacing(8)}px`,
    },
  })
}
export default makeStylesWrapper(style)
