import { EscapedTranslation, i18n, t } from 'translate/i18n'
import {
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Alert from '@material-ui/lab/Alert'
import clsx from 'clsx'
import { types as AuthTypes } from 'core/logic/authentication'
import { Platform } from 'core/logic/authentication/auth.types'
import ActionButton from 'components/ActionButton'
import AuthSocialButtons from 'components/AuthSocialButtons'
import InfoModal from 'components/InfoModal'
import RarumHeader from 'components/RarumHeader'
import Headers from 'containers/Headers'
import React, { useState } from 'react'
import useStyles from './SocialSignIn.style'
import { mapPlatformToLabel } from './SocialSignIn.data'
import { TenantType } from 'core/logic/tenant/tenant.types'
import { useTenantRequirements } from './SocialSignin.template'
import ROUTES from 'core/modules/router'
import { useTerms } from 'core/logic/terms/terms.hook'
import BackButtonView from 'components/BackButton/BackButton.view'
import { useHistory } from 'react-router-dom'

export interface SocialSignInViewProps {
  /**
   * Heading text above the login buttons
   */
  heading: string
  /**
   * Waiting the request to respond
   */
  isLoading: boolean
  /**
   * Error message
   */
  error?: string
  /**
   * Already existing platform, should be enforced
   */
  existingPlatform?: AuthTypes.Platform
  /**
   * When selecting a 3rd party auth provider what should be invoked
   */
  providerHandler: (platform: Platform) => void
  /**
   * Whether data consent checkbox is selected or not
   */
  dataConsent: boolean
  /**
   * User authorization to share their data with the tenant
   */
  dataConsentHandler: (checked: boolean) => void
  /**
   * Tenant
   */
  tenant: TenantType | null
}
const SocialSignInView: React.FC<SocialSignInViewProps> = ({
  heading,
  isLoading,
  existingPlatform,
  providerHandler,
  dataConsent,
  dataConsentHandler,
  tenant,
}) => {
  const { goBack } = useHistory()
  const classes = useStyles()
  // Terms of use modal
  const [termsModalOpen, setTermsModalOpen] = useState(false)
  const handleTermsModalOpen = () => setTermsModalOpen(true)
  const handleTermsModalClose = () => setTermsModalOpen(false)
  const { checked, requirements, isCheckRequired } = useTenantRequirements()

  // Main login buttons area
  const actions = (
    <>
      <Typography color='textSecondary' variant='subtitle2' paragraph>
        {heading}
      </Typography>
      <AuthSocialButtons
        labelPrefix={`${i18n.t('unauthenticated.socialSign.continueWith')}`}
        clickHandler={providerHandler}
        disabled={!checked || (isCheckRequired ? !dataConsent : false)}
        enableOnly={
          isLoading ? [] : existingPlatform ? [existingPlatform] : undefined
        }
      />
    </>
  )

  const bottom = (
    <Box textAlign='center' className={classes.legalWrapper}>
      <Typography variant='body2' color='textSecondary' paragraph>
        {`${i18n.t('unauthenticated.socialSign.rarumInscription')} `}
        <b
          onClick={handleTermsModalOpen}
          className={clsx(classes.pointer, classes.highlight)}>
          {i18n.t('unauthenticated.socialSign.termOfUse')}
        </b>
      </Typography>
    </Box>
  )

  const { terms } = useTerms()

  return (
    <>
      <Headers screenTitle='Login' />
      <BackButtonView
        text={t('unauthenticated.socialSign.noAuth.goBack')}
        onClick={goBack}
      />
      <Card className={classes.card}>
        <CardContent>
          <RarumHeader showRarumLogo={false} clientLogoSrc='/logo.svg' />
        </CardContent>
        <Divider className={classes.divider} />
        <CardContent>
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            mt={5.5}
            mb={5.5}>
            {actions}
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={dataConsent}
                  color='primary'
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
                    dataConsentHandler(ev.target.checked)
                  }
                />
              }
              label={
                <EscapedTranslation
                  code='unauthenticated.socialSign.dataConsent'
                  props={{
                    link: `${ROUTES.unauthenticated.terms}#terms-78`,
                    tenantName: tenant?.displayName,
                  }}
                />
              }
            />
            {requirements}
          </Box>
          {existingPlatform ? (
            <Alert severity='info'>
              {i18n.t('unauthenticated.socialSign.alert', {
                existingPlatform: `${mapPlatformToLabel[existingPlatform]}`,
              })}
            </Alert>
          ) : null}
        </CardContent>
        <Divider className={classes.divider} />
        <CardContent>
          <Box
            mt={3}
            mb={2.5}
            display='flex'
            flexDirection='column'
            alignItems='center'>
            {bottom}
          </Box>
        </CardContent>
      </Card>
      <InfoModal
        open={termsModalOpen}
        title={`${i18n.t('unauthenticated.socialSign.termOfUse')}`}
        handleClose={handleTermsModalClose}
        content={
          <Typography
            key={`terms-p`}
            variant='body1'
            color='textSecondary'
            className={classes.modalParagraph}
            dangerouslySetInnerHTML={{ __html: terms! }}
          />
        }
        actions={
          <>
            <ActionButton
              text={`${i18n.t('generic.continue')}`}
              onClick={() => handleTermsModalClose()}
            />
          </>
        }
      />
    </>
  )
}

export default SocialSignInView
