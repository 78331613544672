import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
    root: {
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        minHeight: "465px",
        width: "100%",
        maxWidth: "626px",
        padding: "108px 80px 80px 80px",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        "& h5": {
            fontSize: "1.25rem",
            marginBottom: "25px",
            alignSelf: "flex-start",
        },
        "& p": {
            alignSelf: "flex-start",
            fontSize: "1.0rem"
        },
        "& section": {
            minHeight: "initial"
        },
        "& > * > button, & > button:last-child": {
            minWidth: "233px",
            alignSelf: "flex-start"
        },
        [theme.breakpoints.down(400)]: {
            padding: "108px 36px 36px 36px",
        }
    },
    close: {
        minWidth: "initial",
        position: "absolute",
        top: "34px",
        left: "34px",
        width: "40px",
        height: "40px",
    }
})); 