import { LS_REDIRECT_URI } from 'core/constants'
import { SIGN_IN, SIGN_UP, TO_FIREBASE } from 'core/modules/router'
import { Location } from 'history'
import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

const IGNORED_ROUTES = [TO_FIREBASE[SIGN_UP], TO_FIREBASE[SIGN_IN]]

/**
 * Persists the previous location on Local Storage.
 * Trick to obtain the previous location, based on: https://usehooks.com/usePrevious/
 */
export const useStorePreviousLocation = () => {
  const { pathname } = useLocation<Location>()

  const ref = useRef<string>()

  useEffect(() => {
    ref.current = pathname
  }, [pathname])

  if (ref.current && !IGNORED_ROUTES.includes(ref.current)) {
    localStorage.setItem(LS_REDIRECT_URI, ref.current)
  }
}
