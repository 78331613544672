import { makeStyles } from "@material-ui/core";

export default makeStyles(t => ({
    reset: {
        textAlign: "left"
    },
    error: {
        '&, & span': {
            color: t.palette.error.main
        }
    },
    form: {
        textAlign: 'left',
        alignSelf: 'flex-start',
    },
    root: {
        textAlign: "center",
        maxWidth: "700px",
        "& h2": {
            marginBottom: "48px"
        },
        "& button": {
            minWidth: "200px"
        },
        "& > :last-child": {
            marginTop: "30px"
        },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    main: {
        margin: "auto",
    }
}))