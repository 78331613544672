import { i18n, t } from 'translate/i18n'
import { Fab, useTheme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import BackButton from 'components/BackButton'
import Certificate from 'components/Certificate'
import Footer from 'components/Footer'
import Headers from 'containers/Headers'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import useStyles from './AssetDetail.style'
import { AssetType, AssetWithBalance } from 'core/logic/asset/asset.types'
import RarumAdaptiveSidebar from 'components/RarumAdaptiveSidebar/RarumAdaptiveSidebar'
import CloseIcon from '@material-ui/icons/CloseOutlined'
import MarketplaceInformation from './MarketplaceInformation'
import AdaptiveContainer from '@onepercentio/one-ui/dist/components/AdaptiveContainer'
import { Fragment } from 'react'
import AssetDisplayView from 'openspace/components/AssetDisplay/AssetDisplay.view'
import { AssetDisplayMode } from 'openspace/components/AssetDisplay/AssetDisplay.types'
import { ReadOnlyBenefits } from 'components/Benefits/Benefits.logic'
import FeaturesDisplay from 'components/Asset/FeaturesDisplay/FeaturesDisplay'

export type AssetDetailViewProps = {
  asset: AssetType
  assetUrl?: string | null
  description?: string
  features?: string
  onBack: () => void
  ipfs?: string
} & (
  | {
      marketplace: true
      title?: string
    }
  | {
      marketplace: false
      title: string
    }
)

const AssetDetailView: React.FC<AssetDetailViewProps> = ({
  asset,
  assetUrl,
  title,
  description,
  features,
  onBack,
  marketplace,
}) => {
  const theme = useTheme()
  const classes = useStyles({ mode: marketplace ? 'marketplace' : 'default' })

  if (!assetUrl)
    return (
      <main className={classes.main}>
        <Box
          display='flex'
          flexGrow={1}
          alignItems='center'
          justifyContent='center'>
          <CircularProgress />
        </Box>
      </main>
    )

  return (
    <>
      <Headers screenTitle={title} />
      <main className={classes.main}>
        <div className={classes.upperContainer}>
          <Container>
            <Box pt={4} mb={4}>
              <BackButton
                onClick={onBack}
                text={i18n.t('unauthenticated.galleryDetail.backToCards')}
              />
            </Box>
            {!marketplace && (
              <>
                <Typography
                  variant='h2'
                  component='h1'
                  color='textPrimary'
                  align='center'
                  className={clsx(classes.title, classes.bottomSpacing)}>
                  {title}
                </Typography>
                {description && (
                  <Typography
                    variant='body1'
                    component='p'
                    color='textPrimary'
                    align='center'
                    className={classes.bottomSpacing}>
                    {description}
                  </Typography>
                )}
              </>
            )}
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
              className={classes.itemWrapper}>
              {!asset.media?.animationUrl && (
                <AssetDisplayView
                  mode={AssetDisplayMode.IMAGE}
                  src={assetUrl}
                  uniqueId={asset.id}
                  zoomable
                />
              )}
              {asset.media?.animationUrl && (
                <Zoom overlayBgColorEnd={theme.palette.background.default}>
                  <video className={classes.video} autoPlay loop>
                    <source src={asset.media?.animationUrl} />
                  </video>
                </Zoom>
              )}
              {marketplace && (
                <RarumAdaptiveSidebar
                  className={classes.float}
                  visibilityControlComponent={({ open }) => (
                    <Fab className={classes.fab} variant='extended'>
                      <AdaptiveContainer>
                        {open ? (
                          <CloseIcon key='x' />
                        ) : (
                          <Fragment key='l'>
                            {t('marketplace.assetDetails.actions.listOffers')}
                          </Fragment>
                        )}
                      </AdaptiveContainer>
                    </Fab>
                  )}>
                  <MarketplaceInformation asset={asset as AssetWithBalance} />
                </RarumAdaptiveSidebar>
              )}
            </Box>
          </Container>
        </div>
        <Container maxWidth='md'>
          <Typography
            variant='h2'
            component='h2'
            color='textPrimary'
            className={clsx(classes.title, classes.bottomSpacing)}>
            {i18n.t('unauthenticated.galleryDetail.itemsDetail')}
          </Typography>
          <FeaturesDisplay features={features} />
        </Container>

        {asset.attributes_extension?.length && (
          <ReadOnlyBenefits asset={asset} />
        )}

        <Container maxWidth='md'>
          <Divider className={classes.divider} />

          <Box display='flex' justifyContent='center'>
            <Certificate />
          </Box>
        </Container>
      </main>
      <Footer />
    </>
  )
}

export default AssetDetailView
