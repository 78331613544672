/* eslint-disable react-hooks/exhaustive-deps */
import { MetaMaskInpageProvider } from '@metamask/providers'
import React, { useEffect } from 'react'
import { useMutation, UseMutationOptions } from 'react-query'
import { t } from 'translate/i18n'
import { useCustomSnackbar } from '../snackbar'
import { useUser } from '../user'
import { useWalletConnnectorContext } from './wallet.context'
import {
  CHAINS_METADATA,
  ERROR_MISSING_CHAIN,
  ERROR_MISSING_PROVIDER,
  ERROR_WRONG_CHAIN,
  PERSIST_ADDRESS_KEY,
} from './wallet.data'
import { ConnectInfo, SignMessageParams } from './wallet.types'

export function useWalletConnector() {
  const { provider, web3 } = useWalletConnnectorContext()
  const [accounts, setAccounts] = React.useState<Array<string>>([])
  const [chainId, setChainId] = React.useState('')
  const [connected, setConnected] = React.useState<boolean>(false)
  const [address, setAddress] = React.useState('')

  const refresh = React.useCallback(() => {
    void (async () => {
      if (provider?.request) {
        const [ethAccounts, ethChainId] = await Promise.all([
          provider.request({
            method: 'eth_accounts',
          }) as unknown as Array<string>,
          provider.request({ method: 'eth_chainId' }) as unknown as string,
        ])
        const newAccounts = ethAccounts || []
        const newChainId = ethChainId || ''
        const hasAccounts = !!newAccounts.length
        setConnected(hasAccounts)
        setAccounts(newAccounts)
        if (hasAccounts) setAddress(newAccounts[0])
        if (newChainId) setChainId(newChainId)
        web3!.setProvider(provider as any)
      }
    })()
  }, [])

  useEffect(() => {
    refresh()
  }, [])

  useEffect(() => {
    const handlerAccountsChange = (accounts: unknown) => {
      const newAccounts = (accounts as string[]) || []
      const hasAccounts = !!newAccounts.length
      setAccounts(newAccounts)
      setConnected(hasAccounts)
      if (hasAccounts) setAddress(newAccounts[0])
    }
    const handlerChainChange = (chainId: unknown) => {
      setChainId((chainId as string) || '')
    }
    const handlerConnect = async (connect: unknown) => {
      setChainId((connect as ConnectInfo)?.chainId || '')
      try {
        const newAccounts =
          ((await provider?.request({
            method: 'eth_accounts',
          })) as string[]) || []
        const hasAccounts = !!newAccounts.length
        setConnected(hasAccounts)
        setAccounts(newAccounts)
        if (hasAccounts) setAddress(newAccounts[0])
      } catch (err) {
        console.error(err)
      }
    }
    const handlerDisconnect = async () => {
      try {
        const accounts =
          ((await provider?.request({
            method: 'eth_accounts',
          })) as string[]) || []
        setConnected(!!accounts.length)
      } catch (err) {
        console.error(err)
      }
    }

    if (provider?.on) {
      provider.on('accountsChanged', handlerAccountsChange)
      provider.on('chainChanged', handlerChainChange)
      provider.on('connect', handlerConnect)
      provider.on('disconnect', handlerDisconnect)

      return () => {
        provider.removeListener('accountsChanged', handlerAccountsChange)
        provider.removeListener('chainChanged', handlerChainChange)
        provider.removeListener('connect', handlerConnect)
        provider.removeListener('disconnect', handlerDisconnect)
      }
    }
  }, [provider, web3])

  return {
    refresh,
    web3,
    address,
    provider,
    accounts,
    chainId,
    connected,
    hasProvider: !!provider,
  }
}

export function useConnect(options?: UseMutationOptions<any, Error>) {
  const { provider, web3 } = useWalletConnnectorContext()

  const onlyFn = React.useCallback(async () => {
    if (!provider?.request) throw new Error(ERROR_MISSING_PROVIDER)
    const authorize = await provider.request({
      method: 'eth_requestAccounts',
    })
    web3!.setProvider(provider as any)
    return authorize
  }, [provider])

  const mutation = useMutation<any, Error>({
    mutationFn: onlyFn,
    ...options,
  })

  return {
    ...mutation,
    connect: mutation.mutate,
    connectFn: onlyFn,
  }
}

export function useChangeChain(
  options?: UseMutationOptions<any, Error, string>
) {
  const { provider: _provider } = useWalletConnnectorContext()
  const provider = _provider as MetaMaskInpageProvider | undefined

  const onlyFn = React.useCallback(
    async (desiredChainId: string) => {
      if (!provider || !provider.request)
        throw new Error(ERROR_MISSING_PROVIDER)
      const ethChangeChain = () =>
        provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: desiredChainId }],
        })
      const ethReadChain = () =>
        provider.request({ method: 'eth_chainId' }) as unknown as string
      let ethChainId = ''

      try {
        await ethChangeChain()
        ethChainId = await ethReadChain()
      } catch (err) {
        if ((err as any).code === 4902 || (err as any).code === -32603) {
          const chainMetadata = CHAINS_METADATA.get(desiredChainId)
          if (chainMetadata) {
            await provider.request({
              method: 'wallet_addEthereumChain',
              params: [chainMetadata],
            })
            ethChainId = await ethReadChain()
            if (ethChainId !== desiredChainId) {
              await ethChangeChain()
              ethChainId = await ethReadChain()
            }
          } else {
            throw new Error(ERROR_MISSING_CHAIN)
          }
        }
      }
      if (ethChainId !== desiredChainId) {
        throw new Error(ERROR_WRONG_CHAIN)
      }
    },
    [provider]
  )

  const mutation = useMutation<any, Error, string>({
    mutationFn: onlyFn,
    ...options,
  })

  return {
    ...mutation,
    changeChain: mutation.mutate,
    changeChainFn: onlyFn,
  }
}

export function useSignMessage(
  options?: UseMutationOptions<any, Error, SignMessageParams>
) {
  const { web3 } = useWalletConnnectorContext()

  const onlyFn = React.useCallback(
    async ({ message, address }: SignMessageParams) => {
      if (!web3) throw new Error(ERROR_MISSING_PROVIDER)
      return await web3.eth.personal.sign(message, address, '')
    },
    [web3]
  )

  const mutation = useMutation<any, Error, SignMessageParams>({
    mutationFn: onlyFn,
    ...options,
  })

  return {
    ...mutation,
    signMessage: mutation.mutate,
    signMessageFn: onlyFn,
  }
}

export function usePersistentAddress() {
  const { address } = useWalletConnector()
  const storedAddress = localStorage.getItem(PERSIST_ADDRESS_KEY)
  const { logout } = useUser()
  const { enqueueClickCloseableSnackbar } = useCustomSnackbar()

  useEffect(() => {
    if (address) {
      if (!storedAddress) {
        localStorage.setItem(PERSIST_ADDRESS_KEY, address)
      } else {
        if (storedAddress !== address) {
          localStorage.setItem(PERSIST_ADDRESS_KEY, '')
          logout()
          enqueueClickCloseableSnackbar(t('wallet.switchingAccount.warning'), {
            variant: 'warning',
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'top',
            },
          })
        }
      }
    }
  }, [address])

  return {
    address,
  }
}
