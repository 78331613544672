import Box from '@material-ui/core/Box'
import useStyle from './OfferPrice.style'
import { OfferPriceProps } from './OfferPrice.controller'
import clsx from 'clsx'
import { Typography } from '@material-ui/core'
import { Variant } from '@material-ui/core/styles/createTypography'
import { Skeleton } from '@material-ui/lab'
import { OfferPriceTestIds } from './OfferPrice.e2e'
import { Fragment } from 'react'

interface OfferPriceViewProps extends Partial<OfferPriceProps> {
  main?: JSX.Element
  secondary?: JSX.Element
}

export const OfferPriceView: React.FC<OfferPriceViewProps> = ({
  main,
  secondary,
  size = 'lg',
  className,
  mode = 'styled',
}) => {
  const classes = useStyle()
  let mainVariant: Variant = 'h1'
  let secondVariant: Variant = 'body1'
  switch (size) {
    case 'sm':
      mainVariant = 'body1'
      secondVariant = 'body2'
      break
    case 'md':
      mainVariant = 'h4'
      secondVariant = 'body2'
      break
  }
  const MainVariant = mainVariant === 'body1' ? 'span' : mainVariant
  switch (mode) {
    case 'styled':
      return (
        <Box className={clsx(className, classes.container)}>
          {main ? (
            <Typography
              className={clsx('main', `price-${size}`, classes.main)}
              variant={mainVariant}
              component='span'>
              {main}
            </Typography>
          ) : (
            <MainVariant>
              <Skeleton
                data-testid={OfferPriceTestIds.SKELETON}
                variant='text'
                width={64}
                height={'1em'}
              />
            </MainVariant>
          )}
          {secondary && (
            <Typography
              className={clsx('secondary', `price-${size}`, classes.secondary)}
              variant={secondVariant}
              component='span'>
              ({secondary})
            </Typography>
          )}
        </Box>
      )
    case 'text':
      return (
        <Fragment>
          {main ? (
            <span className={clsx('main', `price-${size}`, classes.main)}>
              {main}
            </span>
          ) : (
            <span>
              <Skeleton
                data-testid={OfferPriceTestIds.SKELETON}
                variant='text'
                width={64}
                height={'1em'}
              />
            </span>
          )}
          {secondary && (
            <span className={classes.sub}>&nbsp;({secondary})</span>
          )}
        </Fragment>
      )
  }
}
