import View, {
  BaseAssetInnerPadding,
  BaseAssetWidth,
  BaseWidth,
} from './GalleryCard.view'
import { GalleryCardProps } from './GalleryCard.types'
import { useHistory } from 'react-router-dom'
import { TO_GALLERY_DETAIL } from 'core/modules/router'
import AssetsList from './AssetsList'
export {
  BaseAssetInnerPadding,
  BaseAssetWidth,
  BaseWidth,
} from './GalleryCard.view'

export const BASE_EXPANDED_GALLERY_WIDTH =
  BaseWidth + BaseAssetInnerPadding * 2 + BaseAssetWidth * 3

export default function GalleryCardLogic(props: GalleryCardProps) {
  const history = useHistory()

  return (
    <View
      {...props}
      gallery={props.gallery}
      willExpand={props.shouldExpandOnMount}
      onOpenGallery={() => {
        if (!props.banner) history.push(TO_GALLERY_DETAIL(props.gallery.id))
      }}
      banner={!!props.banner}
      className={props.className}
      ExpandedContent={AssetsList}
      version="v2"
    />
  )
}
