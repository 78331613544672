import { useDelivery } from 'core/logic/delivery/delivery.hook'
import { TO_DROP_OPENING, TO_MY_COLLECTION } from 'core/modules/router'
import { useHistory, useParams } from 'react-router-dom'
import OpenDropView from './OpenDrop.view'

const OpenDropLogic = () => {
  const history = useHistory()
  const { deliveryId } = useParams<{ deliveryId: string }>()
  const { delivery } = useDelivery({ deliveryId })

  const drop = delivery ? delivery.offer : undefined

  const onBackClickHandler = () => history.push(TO_MY_COLLECTION())

  const onOpenHandler = () => history.push(TO_DROP_OPENING(deliveryId))

  switch (drop?.type) {
    default:
      return (
        <OpenDropView
          drop={drop}
          onBack={onBackClickHandler}
          onOpen={onOpenHandler}
        />
      )
  }
}

export default OpenDropLogic
