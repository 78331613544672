import { types as AuthTypes } from 'core/logic/authentication'
import { service } from 'core/modules/firebase'

export const login = (data: AuthTypes.Credentials) => service.loginByEmail(data)

export const register = (data: AuthTypes.Credentials) => service.registerByEmail(data)

export const gmail = () => service.loginByProvider(AuthTypes.Platform.gmail)

export const facebook = () => service.loginByProvider(AuthTypes.Platform.facebook)
