import { i18n } from 'translate/i18n'
import { useTheme } from '@material-ui/core'
import Box, { BoxProps } from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'
import { format as dateFormat } from 'date-fns'
import { ptBR, enUS, es } from 'date-fns/locale'
import IconRarum from 'components/IconRarum'
import useStyles from './Availability.style'

interface ClockProps {
  date: Date
  color?: string
  format?: string
}

export interface AvailabilityViewProps {
  startDate?: Date
  endDate?: Date | null
  format?: string
}

const Clock: React.FC<ClockProps & BoxProps> = ({
  date,
  color,
  format,
  ...props
}) => {
  const classes = useStyles()

  let lang

  switch (i18n.language) {
    case 'pt-BR':
      lang = ptBR
      break;
    case 'en-US':
      lang = enUS
      break;
    case 'es-ES':
      lang = es
      break;
    default:
      lang = enUS
      break;
  }

  return (
    <Box display='flex' alignItems='center' {...props}>
      <IconRarum
        icon='time'
        size={24}
        className={classes.clockIcon}
        color={color}
      />
      <Typography variant='body2' component='p'>
        {dateFormat(date, format ?? i18n.t('core.constants.longDateFormat'), {
          locale: lang,
        })}
      </Typography>
    </Box>
  )
}

const AvailabilityView: React.FC<AvailabilityViewProps> = ({
  startDate,
  endDate,
  format,
}) => {
  const theme = useTheme()
  const classes = useStyles()

  return (
    <Box display='flex'>
      <Box display='flex' flexDirection='column' mr={1}>
        <Typography variant='h2' component='span' className={classes.header}>
          {i18n.t('unauthenticated.drops.details.availability.packageDetail')}
        </Typography>
        <Typography variant='body1' component='p' className={classes.paragraph}>
          {i18n.t(
            'unauthenticated.drops.details.availability.packageAvailable'
          )}
        </Typography>
        {startDate ? (
          <Clock
            date={startDate}
            color={theme.palette.primary.main}
            format={format}
            mb={1}
          />
        ) : (
          <Skeleton variant='text' width='70%' height={30} />
        )}
        {endDate ? (
          <Clock
            date={endDate}
            color={theme.palette.error.main}
            format={format}
          />
        ) : endDate === undefined ? (
          <Skeleton variant='text' width='70%' height={30} />
        ) : null}
      </Box>
    </Box>
  )
}

export default AvailabilityView
