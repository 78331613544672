import { ChallengeBannerAlternatorViewProps } from './ChallengeBannerAlternator.types'
import UncontrolledTransition from '@onepercentio/one-ui/dist/components/UncontrolledTransition'
import ChallengeDetailsBanner from '../ChallengeDetailsBanner'
import useAlternating from '@onepercentio/one-ui/dist/hooks/ui/useAlternating'
import { ChallengeDetailsMode } from '../ChallengeDetailsBanner/ChallengeDetailsBanner.types'

/**
 * Keeps rotating between the available challenges
 **/
export default function ChallengeBannerAlternatorView(
  props: ChallengeBannerAlternatorViewProps
) {
  const challenge = useAlternating(props.challenges)
  return (
    <UncontrolledTransition transitionType={props.transitionType}>
      <ChallengeDetailsBanner
        key={challenge.id}
        challenge={challenge}
        mode={ChallengeDetailsMode.BANNER}
      />
    </UncontrolledTransition>
  )
}
