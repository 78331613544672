import { createContext, PropsWithChildren, useContext } from 'react'

/**
 * This was created to propagate the real locatio through the application as the transition
 * blocks the real location from reaching the childs until the transition finishes
 */
const RealLocaCtx = createContext<{
  loc: string
}>(null as any)

export function RealLocationProvider({
  currentLocation,
  children,
}: PropsWithChildren<{ currentLocation: string }>) {
  return (
    <RealLocaCtx.Provider
      value={{
        loc: currentLocation,
      }}>
      {children}
    </RealLocaCtx.Provider>
  )
}

export function useRealLocation() {
  return useContext(RealLocaCtx).loc
}
