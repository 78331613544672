import { i18n } from 'translate/i18n'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { DATA_DELETION_INSTRUCTIONS } from 'core/constants'
import BackButton from 'components/BackButton'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import useStyle from './Deletion.style'

const DeletionView: React.FC = () => {
  const classes = useStyle()
  const history = useHistory()
  const goHome = useCallback(() => {
    history.push('/')
  }, [history])

  // Deletion of use modal content
  const content = DATA_DELETION_INSTRUCTIONS.map((line, idx) => (
    <Typography
      key={`deletion-p-${idx}`}
      variant='body1'
      color='textSecondary'
      className={classes.modalParagraph}>
      {line}
    </Typography>
  ))

  return (
    <Container maxWidth='lg' disableGutters>
      <BackButton
        icon='arrow'
        onClick={goHome}
        className={classes.closeButton}
        text={i18n.t('unauthenticated.deletion.return')}
      />
      <Container maxWidth='md' disableGutters>
        <Box ml={4} mr={4}>
          <Typography variant='h4' component='h3' className={classes.title}>
            {i18n.t('unauthenticated.deletion.remotionInstruction')}
          </Typography>
          {content}
        </Box>
      </Container>
    </Container>
  )
}
export default DeletionView
