import AdaptiveDialog from '@onepercentio/one-ui/dist/components/AdaptiveDialog/AdaptiveDialog'
import Contact from 'openspace/pages/Authenticated/Home/v2/Home/Contact/Contact'
import React, {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react'
import Container from '../Container/Container'
import Text from '@onepercentio/one-ui/dist/components/Text'
import { EscapedTranslation, t } from 'translate/i18n'
import Spacing from '@onepercentio/one-ui/dist/components/Spacing/Spacing'
import Button from '@onepercentio/one-ui/dist/components/Button'
import Styles from './Scheduler.module.scss'
/**
 * Schedule modal
 **/
function Scheduler(_: any, ref: ForwardedRef<ClickActions<'open'>>) {
  const [showDemoModal, setShow] = useState(false)
  useImperativeHandle(ref, () => ({ open: () => setShow(true) }), [])
  return (
    <AdaptiveDialog
      variant='custom'
      open={showDemoModal}
      onClickOut={() => setShow(false)}
      onClose={() => setShow(false)}>
      <Contact />
    </AdaptiveDialog>
  )
}

export function SchedulerBanner({ onSchedule }: { onSchedule: () => void }) {
  return (
    <div className={`${Styles.contact} dark`}>
      <Container>
        <Text type='p40_v2'>
          <EscapedTranslation code='featureDetails.demo.title' />
        </Text>
        <Spacing size='16' />
        <Button variant='filled' onClick={onSchedule}>
          {t('featureDetails.demo.actions.schedule')}
        </Button>
      </Container>
    </div>
  )
}

export default forwardRef(Scheduler)
