import React from 'react'
import View from './ForCreators.view'
import {
  TO_CREATORS_SECTION,
  TO_FEATURE_DETAILS,
  TO_MAINPAGE,
} from 'core/modules/router'
import useCustomHistory from '@onepercentio/one-ui/dist/hooks/useCustomHistory'

export default function ForCreatorsLogic() {
  const history = useCustomHistory()
  return (
    <View
      onChangedSection={(section) => {
        history.replace(TO_CREATORS_SECTION(section))
      }}
      onBack={() => history.goBackWithFallback(TO_MAINPAGE())}
      onFeature={(f) => window.open(TO_FEATURE_DETAILS(f), '_blank')}
    />
  )
}
