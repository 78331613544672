import React, { useMemo } from 'react'
import View from './GalleryDetails.view'
import { GalleryDetailsSeeAllOption } from './GalleryDetails.types'
import { Redirect, useParams } from 'react-router-dom'
import { GalleryType } from 'core/logic/gallery/gallery.types'
import { useGalleries } from 'core/logic/gallery/gallery.hook'
import { useGalleryDrops } from 'context/Gallery'
import useElementFit from '@onepercentio/one-ui/dist/hooks/useElementFit'
import { BaseAssetWidth } from 'openspace/components/GalleryCard'
import useCustomHistory from '@onepercentio/one-ui/dist/hooks/useCustomHistory'
import ROUTES from 'core/modules/router'
import { useMarketplaceAssets } from 'context/Marketplace'
import Footer from 'components/Footer'
import SectionLoader from 'openspace/components/SectionLoader/SectionLoader'

function DropsWrapper({ gallery }: { gallery: GalleryType }) {
  const { itemsToShow, ref } = useElementFit(BaseAssetWidth)
  const { active, inactive, groups } = useGalleryDrops(gallery.id, itemsToShow || 0)
  const history = useCustomHistory()
  const marketplacePages = useMarketplaceAssets(itemsToShow || 0)

  return (
    <>
      <View
        gallery={gallery}
        drops={active}
        ref={ref}
        onGoBack={() =>
          history.goBackWithFallback(ROUTES.unauthenticated.allGalleries)
        }
        expiredDrops={inactive}
        marketplaceAssets={marketplacePages}
        onSeeAll={(w) => {
          switch (w) {
            case GalleryDetailsSeeAllOption.MARKETPLACE:
              history.push(ROUTES.unauthenticated.marketplace)
              break
            case GalleryDetailsSeeAllOption.ASSETS:
              history.push(ROUTES.unauthenticated.asset)
              break
          }
        }}
        dropGroups={groups}
      />
    </>
  )
}

export default function GalleryDetailsLogic() {
  const { galleries } = useGalleries()
  const { galleryId } = useParams<{ galleryId: GalleryType['id'] }>()
  const relatedGallery = useMemo(
    () => galleries?.find((g) => g.id === galleryId),
    [galleryId, galleries]
  )

  return (
    <>
      {relatedGallery === undefined ? (
        galleries === undefined || galleries === null ? (
          <SectionLoader />
        ) : (
          <Redirect to={'/'} />
        )
      ) : (
        <DropsWrapper gallery={relatedGallery} />
      )}
      <Footer />
    </>
  )
}
