import {
  MarketplaceUserContextShape,
  useMarketplaceUser,
} from 'context/MarketplaceUser'
import { useMemo, useState } from 'react'
import { t } from 'translate/i18n'
import {
  SettleOperationState,
  SettleOperationType,
} from './SettleOperation.types'
import View from './SettleOperation.view'

type AvailablePreffixes<X extends any> =
  X extends `${infer AA}.inProgress.actions.wait` ? AA : never

type FunctionsFromContext = keyof Omit<
  MarketplaceUserContextShape,
  NonFunctionPropertyNames<MarketplaceUserContextShape>
>
type CONFIG<F extends FunctionsFromContext> = {
  functionToCall: F
  preffix: AvailablePreffixes<TranslationCodes>
  needsConfirmation?: boolean
}
const CONFIGS_BY_TYPE = {
  [SettleOperationType.MARKETPLACE_ITEM_CREATION]: () =>
    ({
      functionToCall: 'createOffer',
      preffix: 'marketplace.offerCreation.creation',
    } as CONFIG<'createOffer'>),
  [SettleOperationType.MARKETPLACE_ITEM_EDITION]: () =>
    ({
      functionToCall: 'updateOffer',
      preffix: 'marketplace.offerCreation.edition',
    } as CONFIG<'updateOffer'>),
  [SettleOperationType.MARKETPLACE_ITEM_REMOVAL]: () =>
    ({
      functionToCall: 'removeOffer',
      preffix: 'marketplace.offerCreation.removal',
      needsConfirmation: true,
    } as CONFIG<'removeOffer'>),
} as const

export default function SettleOperationLogic<T extends SettleOperationType>({
  type,
  params,
  onFinish,
  onBack,
}: {
  type: T
  params: Parameters<
    MarketplaceUserContextShape[ReturnType<
      typeof CONFIGS_BY_TYPE[T]
    >['functionToCall']]
  >
  onFinish: (
    ret: Awaited<
      ReturnType<
        MarketplaceUserContextShape[ReturnType<
          typeof CONFIGS_BY_TYPE[T]
        >['functionToCall']]
      >
    >
  ) => void
  onBack: () => void
}) {
  const context = useMarketplaceUser()
  const config = useMemo(() => CONFIGS_BY_TYPE[type](), [type])
  const [result, setResult] = useState<Parameters<typeof onFinish>[0]>()

  function invokeOperation() {
    // @ts-ignore
    return context[config.functionToCall](...params).then((result) =>
      // @ts-ignore
      setResult(result || true)
    )
  }

  return (
    <View
      autoInvoke={!config.needsConfirmation && !result}
      onBack={onBack}
      status={
        !result ? SettleOperationState.LOADING : SettleOperationState.SETTLED
      }
      config={{
        error: {
          description: t(`${config.preffix}.error.description`),
          title: t(`${config.preffix}.error.title`),
        },
        loading: {
          action: {
            label: context.control.error
              ? t('generic.retryBtn')
              : t(`${config.preffix}.inProgress.actions.wait`),
            onClick: () => invokeOperation(),
          },
          description: t(`${config.preffix}.inProgress.description`),
          title: t(`${config.preffix}.inProgress.title`),
        },
        settled: {
          action: {
            label: t(`${config.preffix}.finished.actions.seeItem`),
            onClick: () => Promise.resolve(onFinish(result!)),
          },
          description: t(`${config.preffix}.finished.description`),
          title: t(`${config.preffix}.finished.title`),
        },
      }}
    />
  )
}
