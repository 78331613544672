import { isValidEthereumWallet } from 'core/helpers/validators'
import { object as Yobject, string as Ystring } from 'yup'
import { i18n } from 'translate/i18n'

export const transferFormSchema = (balance: number) =>
  Yobject().shape({
    qty: Ystring()
      .required(i18n.t('authenticated.transfer.errors.amountRequired'))
      .test('qty', i18n.t('authenticated.transfer.errors.invalidAmount'), (value) => {
        const n = Number(value)
        return n >= 1 && n <= balance
      }),
    recipient: Ystring()
      .required(i18n.t('authenticated.transfer.errors.walletRequired'))
      .test('recipient', i18n.t('authenticated.transfer.errors.invalidWallet'), (value) => isValidEthereumWallet(value ?? '')),
  })

export interface TransferFormInputs {
  qty?: number
  recipient?: string
}
