import React from 'react'
import Styles from './Footer.module.scss'
import Container from 'openspace/components/Container/Container'
import OneText from '@onepercentio/one-ui/dist/components/Text'
import { EscapedTranslation, tO } from 'translate/i18n'
import LabeledHR from 'openspace/components/LabeledHR/LabeledHR'
import { ReactComponent as Instagram } from 'openspace/assets/svg/icons/social/instagram.svg'
import { ReactComponent as Linkedin } from 'openspace/assets/svg/icons/social/linkedin.svg'
import { ReactComponent as Twitter } from 'openspace/assets/svg/icons/social/twitter.svg'
import Text from 'openspace/components/Text/Text'
import Spacing from '@onepercentio/one-ui/dist/components/Spacing/Spacing'
import Button from '@onepercentio/one-ui/dist/components/Button'
import FooterBG from 'openspace/assets/png/bg-footer.png'
import { SOCIAL_LINKS } from 'core/constants'
/**
 * Shows contact
 **/
export default function Footer({
  onScheduleDemo,
}: {
  onScheduleDemo: () => void
}) {
  return (
    <section
      style={{ backgroundImage: `url(${FooterBG})` }}
      className={`${Styles.root} light`}>
      <div>
        <Container>
          <OneText type='p40_v2'>
            <EscapedTranslation code='home.sections.contactUs.v2.title' />
          </OneText>
          <LabeledHR
            className={Styles.footerLine}
            label={
              <>
                <div className={Styles.social}>
                  <a
                    href={SOCIAL_LINKS.instagram.link}
                    title={SOCIAL_LINKS.instagram.title}
                    rel='noopener noreferrer'
                    target='_blank'>
                    <Instagram />
                  </a>
                  <a
                    href={SOCIAL_LINKS.linkedin.link}
                    title={SOCIAL_LINKS.linkedin.title}
                    rel='noopener noreferrer'
                    target='_blank'>
                    <Linkedin />
                  </a>
                  <a
                    href={SOCIAL_LINKS.twitter.link}
                    title={SOCIAL_LINKS.twitter.title}
                    rel='noopener noreferrer'
                    target='_blank'>
                    <Twitter />
                  </a>
                </div>
                <br />
                <Text
                  code='home.sections.contactUs.v2.description'
                  type='p24_v2'
                />
              </>
            }>
            <>
              <Spacing size='32' />
              <Button variant='filled' onClick={onScheduleDemo}>
                {tO('home.sections.contactUs.v2.actions.schedule')}
              </Button>
            </>
          </LabeledHR>
        </Container>
      </div>
    </section>
  )
}
