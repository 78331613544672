import { Hidden } from 'augmentation/mui'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import BackButton from 'components/BackButton'
import Certificate from 'components/Certificate'
import DropImage from 'components/DropImage'
import Footer from 'components/Footer'
import Headers from 'containers/Headers'
import { DROP_STATUS } from 'core/helpers/drop'
import { DropType } from 'core/logic/drop/drop.types'
import { MouseEventHandler } from 'react'
import { i18n } from 'translate/i18n'
import Availability from './Availability'
import Description from './Description'
import useStyles from './Details.style'
import Info from './Info'
import Player from 'components/Player'
export interface DetailsViewProps {
  drop?: DropType | null
  availability?: DROP_STATUS | null
  onBack: MouseEventHandler<HTMLButtonElement>
}

const DetailsView: React.FC<DetailsViewProps> = ({
  drop,
  onBack,
  availability,
}) => {
  const classes = useStyles()
  let endDate
  if (drop) {
    const { expire } = drop
    endDate = expire ? expire : null
  }

  return (
    <>
      <Headers screenTitle={drop?.title} />
      <main>
        <Container className={classes.container}>
          <Box pt={4} mb={4}>
            <BackButton
              onClick={onBack}
              text={i18n.t('unauthenticated.drops.details.packages')}
            />
          </Box>
          {drop ? (
            <Grid container spacing={0} direction='column'>
              <Grid container spacing={3}>
                <Grid item xs={12} md={5} lg={4} direction='column'>
                  <Grid item className={classes.dropImageWrapper}>
                    {!drop?.asset.media.animationUrl && (
                      <DropImage
                        src={drop?.image}
                        live={availability === 'queue'}
                        imageClass={classes.image}
                        figureClass={classes.figure}
                      />
                    )}
                    {drop?.asset.media.animationUrl && (
                      <Player
                        controls={true}
                        poster={drop?.image}
                        src={drop?.asset.media.animationUrl}
                        className={classes.figure}
                      />
                    )}
                  </Grid>
                  <Hidden smDown>
                    <Grid item sm={12} className={classes.availability}>
                      <Availability startDate={drop?.begin} endDate={endDate} />
                    </Grid>
                  </Hidden>
                </Grid>
                <Grid item sm={12} md={7} lg={8}>
                  <Info drop={drop} availability={availability} />
                  <Description
                    title={drop[i18n.language]?.highlight ?? drop?.highlight}
                    features={drop[i18n.language]?.features ?? drop?.features}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md className={classes.description}>
                <Certificate />
              </Grid>
            </Grid>
          ) : null}
        </Container>
      </main>
      <Footer />
    </>
  )
}

export default DetailsView
