import { AssetType } from 'core/logic/asset/asset.types'

export type AssetDisplayProps = {
  asset: Pick<AssetType, 'media' | 'tokenId' | 'id'>
  uniqueId?: string
  zoomable?: boolean
}
export enum AssetDisplayMode {
  /**
   * A static image with the aspect kept as the same as the src image
   */
  IMAGE = 'image',
  /**
   * Shows the animation with a controllable video player
   */
  VIDEO = 'video',
  /**
   * Shows the animation without controls and in loop
   */
  ANIMATION = 'animation',
}
export type AssetDisplayViewProps = {
  zoomable?: boolean
} & (
  | {
      mode: AssetDisplayMode.VIDEO
      thumbnail: string
      src: string
    }
  | {
      mode: Exclude<AssetDisplayMode, AssetDisplayMode.VIDEO>
      src: string
      uniqueId: string
    }
)
