import { makeStyles, Theme } from '@material-ui/core'

const useFormFieldStyles = makeStyles<
  Theme,
  {
    margin: number
  }
>((theme) => ({
  field: {
    flex: '1 1 100%',
    maxWidth: '30ch',
    marginTop: ({ margin }) => theme.spacing(margin),
    marginBottom: ({ margin }) => theme.spacing(margin),
    marginLeft: ({ margin }) => theme.spacing(margin),
    marginRight: ({ margin }) => theme.spacing(margin),
  },
}))

export default useFormFieldStyles
