import Transitions from 'components/Transitions'
import { useAsset } from 'core/logic/asset/asset.hook'
import { useTenant } from 'core/logic/tenant/tenant.hook'
import { useRemainingAvailableTokens } from 'core/logic/token/token.hook'
import { useUser } from 'core/logic/user'
import { storage } from 'core/modules/firebase'
import ROUTES, {
  TO_ASSET_DETAIL,
  TO_MARKETPLACE_ASSET_DETAILS,
  TO_MARKETPLACE_OFFER_EDIT,
  TO_MARKETPLACE_SELL,
  TO_MY_COLLECTION,
} from 'core/modules/router'
import { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { i18n } from 'translate/i18n'
import CollectionItemDetail from './CollectionItemDetail.view'
import SettleOperationLogic from 'pages/Authenticated/Marketplace/SettleOperation'
import { SettleOperationType } from 'pages/Authenticated/Marketplace/SettleOperation/SettleOperation.types'
import { useFeatures } from 'context/Feature'
import { CircularProgress } from '@material-ui/core'
import useQuery from '@onepercentio/one-ui/dist/hooks/utility/useQuery'
import {
  useUpToDateMyCollectionItems,
} from 'context/MyCollection'
import NoBalance from './NoBalance/NoBalance'

const inferMediaType = (url: string): string | null => {
  const images = ['.jpg', 'jpeg', '.gif', '.tif', '.tiff', '.png', '.webp']
  const videos = ['.webm', 'mp4', '.ogv']
  const audios = ['.mp3', '.wav', '.oga', '.ogg']
  const models = ['.gltf', '.glb']

  if (videos.find((video: string) => url.includes(video))) {
    return 'video'
  } else if (audios.find((audio: string) => url.includes(audio))) {
    return 'audio'
  } else if (images.find((image: string) => url.includes(image))) {
    return 'image'
  } else if (models.find((model: string) => url.includes(model))) {
    return 'model'
  } else {
    return null
  }
}

const CollectionItemDetailLogic = ({
  onRemove,
}: {
  onRemove: (offerId: string) => void
}) => {
  const isMarketplaceEnabled = useFeatures().marketplace
  const history = useHistory()
  const { assetId, offerId } = useParams<{
    assetId: string
    offerId?: string
  }>()
  const tokenId = useQuery<{ tokenId: string }>().get('tokenId')
  const { asset } = useAsset({ id: assetId })
  const onBackClickHandler = () => history.push(TO_MY_COLLECTION())
  const { tenant } = useTenant()
  const { profile } = useUser()
  const { ownedItems, facadeType } = useUpToDateMyCollectionItems()

  const assetWithBalance = useMemo(() => {
    if (tokenId) return ownedItems?.find((i) => i.tokenId === Number(tokenId))
    return ownedItems?.find((i) => i.id === assetId)
  }, [tokenId, ownedItems, assetId])

  const { availableForSale, latestOfferId } =
    useRemainingAvailableTokens(assetWithBalance)

  const removeTime = (date = new Date()) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate())
  }
  const transferBaseDate = removeTime(
    new Date(profile?.quarantinedUntil as string)
  )
  const userAllowedToTransfer: boolean = isNaN(transferBaseDate.getTime())
    ? true
    : transferBaseDate.getTime() - removeTime().getTime() <= 0

  const balance = assetWithBalance?.balance

  let assetUrl: string | undefined
  let assetDownload: string | undefined
  let type: string | null | undefined = undefined
  if (asset) {
    if (asset.media.animationUrl) {
      type = inferMediaType(asset.media.animationUrl)
      assetUrl = asset.media.animationUrl
      assetDownload = asset.media.originalImageUrl ?? asset.media.imageUrl
    } else {
      type = 'image'
      assetUrl = asset.media.imageUrl
      assetDownload = asset.media.originalImageUrl ?? asset.media.imageUrl
    }
  }

  const assetData = asset?.[i18n.language as LanguageCodes] ?? asset
  const assetFeatures = asset?.[i18n.language].features || asset?.features
  const offerIdDetail = offerId || latestOfferId

  const downloadHandler = async () => {
    if (assetDownload) {
      const dURL = await (async () => {
        try {
          return await storage.refFromURL(assetDownload).getDownloadURL()
        } catch (error) {
          return assetDownload
        }
      })()
      const extension = new URL(dURL).pathname.split('.').slice(-1)[0]
      const download = (url: string) => {
        const a = document.createElement('a')
        a.href = url
        a.target = '_blank'
        a.download = `${assetData!.name}.${extension}`
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
      download(dURL)
    }
  }

  useEffect(() => {
    if (asset && assetWithBalance?.balance === 0)
      history.replace(TO_ASSET_DETAIL(asset.id))
  }, [asset, history, assetWithBalance])

  if (
    /** Owned items have been obtained */
    ownedItems &&
    /** The asset information have been loaded */
    asset &&
    /** But there is no balance for the specified asset */
    !assetWithBalance
  )
    return <NoBalance asset={asset} />
  if (!assetWithBalance) return <CircularProgress />
  return (
    <CollectionItemDetail
      collectionItemType={facadeType}
      asset={assetWithBalance}
      latestOfferId={offerIdDetail}
      isSpecificOffer={!!offerId}
      availableForSale={availableForSale}
      assetId={assetId}
      type={type}
      title={assetData?.name ?? ''}
      description={assetData?.description ?? ''}
      features={assetFeatures ?? ''}
      assetUrl={assetUrl}
      onBack={onBackClickHandler}
      onDownload={downloadHandler}
      ipfs={asset?.ipfs.jsonFile}
      balance={balance}
      hideTransferForm={
        !!(tenant?.disableUserTransfers === undefined
          ? true
          : tenant?.disableUserTransfers)
      } /** @TODO Refactor the transfer flow to consider metamask */
      userAllowedToTransfer={userAllowedToTransfer}
      transferBaseDate={transferBaseDate}
      marketplace={isMarketplaceEnabled}
      offerDetails={offerIdDetail ? { offerId: offerIdDetail } : undefined}
      actions={{
        onSell: () => {
          history.push(TO_MARKETPLACE_SELL(asset!.id))
        },
        onEditOffer: () => {
          history.push(TO_MARKETPLACE_OFFER_EDIT(asset!.id, offerIdDetail!))
        },
        onRemoveOffer: () => onRemove(offerIdDetail!),
        onSeeOffer: () => {
          history.push(TO_MARKETPLACE_ASSET_DETAILS(asset!))
        },
      }}
    />
  )
}

export default function CollectionItemDetailLogicWrapper() {
  const [removal, setRemoval] = useState<string>()
  const history = useHistory()
  const content = useMemo(() => {
    if (!removal)
      return (
        <CollectionItemDetailLogic key={'offerForm'} onRemove={setRemoval} />
      )
    else
      return (
        <SettleOperationLogic
          key={'removal_flow'}
          type={SettleOperationType.MARKETPLACE_ITEM_REMOVAL}
          onBack={() => setRemoval(undefined)}
          onFinish={() => {
            history.replace(ROUTES.unauthenticated.marketplace)
          }}
          params={[removal]}
        />
      )
  }, [removal, history])
  return <Transitions center={!!removal}>{content}</Transitions>
}
