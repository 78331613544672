import { builder } from 'core/helpers'
import * as AssetTypes from './asset.types'

// == TYPES ==
const ASSET_FETCH = 'asset/fetch/FETCH'
const ASSET_FETCH_SUCCESS = 'asset/fetch/SUCCESS'
const ASSET_FETCH_FAILED = 'asset/fetch/FAILED'
const ASSETS_FETCH = 'assets/fetch/FETCH'
const ASSETS_FETCH_SUCCESS = 'assets/fetch/SUCCESS'
const ASSETS_FETCH_FAILED = 'assets/fetch/FAILED'
const ASSETS_BY_TOKEN_FETCH = 'assets/fetch_by_token/FETCH'
const ASSETS_BY_TOKEN_FETCH_SUCCESS = 'assets/fetch_by_token/SUCCESS'
const ASSETS_BY_TOKEN_FETCH_FAILED = 'assets/fetch_by_token/FAILED'

export const types = Object.freeze({
  ASSET_FETCH: ASSET_FETCH,
  ASSET_FETCH_SUCCESS: ASSET_FETCH_SUCCESS,
  ASSET_FETCH_FAILED: ASSET_FETCH_FAILED,
  ASSETS_FETCH: ASSETS_FETCH,
  ASSETS_FETCH_SUCCESS: ASSETS_FETCH_SUCCESS,
  ASSETS_FETCH_FAILED: ASSETS_FETCH_FAILED,
  ASSETS_BY_TOKEN_FETCH: ASSETS_BY_TOKEN_FETCH,
  ASSETS_BY_TOKEN_FETCH_SUCCESS: ASSETS_BY_TOKEN_FETCH_SUCCESS,
  ASSETS_BY_TOKEN_FETCH_FAILED: ASSETS_BY_TOKEN_FETCH_FAILED,
})

// == ACTIONS ==
const fetchAsset = (assetId: string) =>
  builder.actionCreator<AssetTypes.AssetActionsTypes, string>(
    ASSET_FETCH,
    assetId
  )

const fetchAssetSuccess = (asset: AssetTypes.AssetType) =>
  builder.actionCreator<AssetTypes.AssetActionsTypes, AssetTypes.AssetType>(
    ASSET_FETCH_SUCCESS,
    asset
  )

const fetchAssetFailed = (errorKey: string) =>
  builder.actionCreator<AssetTypes.AssetActionsTypes, string>(
    ASSET_FETCH_FAILED,
    errorKey
  )

const fetchAssets = () =>
  builder.actionCreator<AssetTypes.AssetActionsTypes>(ASSETS_FETCH)

const fetchAssetsSuccess = (asset: AssetTypes.AssetType[]) =>
  builder.actionCreator<AssetTypes.AssetActionsTypes, AssetTypes.AssetType[]>(
    ASSETS_FETCH_SUCCESS,
    asset
  )

const fetchAssetsFailed = (errorKey: string) =>
  builder.actionCreator<AssetTypes.AssetActionsTypes, string>(
    ASSETS_FETCH_FAILED,
    errorKey
  )

const fetchAssetsByTokens = (assetIds: AssetTypes.AssetType['tokenId'][]) =>
  builder.actionCreator<
    AssetTypes.AssetActionsTypes,
    AssetTypes.AssetType['tokenId'][]
  >(ASSETS_BY_TOKEN_FETCH, assetIds)

const fetchAssetsByTokensSuccess = (assets: AssetTypes.AssetType[]) =>
  builder.actionCreator<AssetTypes.AssetActionsTypes, AssetTypes.AssetType[]>(
    ASSETS_BY_TOKEN_FETCH_SUCCESS,
    assets
  )

const fetchAssetsByTokensFailed = (errorKey: string) =>
  builder.actionCreator<AssetTypes.AssetActionsTypes, string>(
    ASSETS_BY_TOKEN_FETCH_FAILED,
    errorKey
  )

export const actions = Object.freeze({
  fetchAsset,
  fetchAssetSuccess,
  fetchAssetFailed,
  fetchAssets,
  fetchAssetsSuccess,
  fetchAssetsFailed,
  fetchAssetsByTokens,
  fetchAssetsByTokensSuccess,
  fetchAssetsByTokensFailed,
})

export const mapActions = Object.freeze({
  [ASSET_FETCH]: fetchAsset,
  [ASSET_FETCH_SUCCESS]: fetchAssetSuccess,
  [ASSET_FETCH_FAILED]: fetchAssetFailed,
  [ASSETS_FETCH]: fetchAssets,
  [ASSETS_FETCH_SUCCESS]: fetchAssetsSuccess,
  [ASSETS_FETCH_FAILED]: fetchAssetsFailed,
  [ASSETS_BY_TOKEN_FETCH]: fetchAssetsByTokens,
  [ASSETS_BY_TOKEN_FETCH_SUCCESS]: fetchAssetsByTokensSuccess,
  [ASSETS_BY_TOKEN_FETCH_FAILED]: fetchAssetsByTokensFailed,
})

// == REDUCER ==
export const initialState: Readonly<AssetTypes.AssetState> = Object.freeze({
  asset: null,
  storeAssets: null,
  assets: null,
  errorKey: '',
})

export const reducer = (
  state: Readonly<AssetTypes.AssetState> = initialState,
  action: Readonly<AssetTypes.AssetAction>
): AssetTypes.AssetState => {
  const { type, payload } = action
  switch (type) {
    case ASSET_FETCH:
      return { ...state, asset: initialState.asset }
    case ASSET_FETCH_SUCCESS:
      return { ...state, asset: payload as AssetTypes.AssetType }
    case ASSETS_FETCH:
      return { ...state, storeAssets: initialState.storeAssets }
    case ASSETS_FETCH_SUCCESS:
      return { ...state, storeAssets: payload as AssetTypes.AssetType[] }
    case ASSETS_BY_TOKEN_FETCH:
      return { ...state, assets: state.assets || initialState.assets }
    case ASSETS_BY_TOKEN_FETCH_SUCCESS:
      return { ...state, assets: payload as AssetTypes.AssetType[] }
    case ASSET_FETCH_FAILED:
    case ASSETS_FETCH_FAILED:
    case ASSETS_BY_TOKEN_FETCH_FAILED:
      return { ...state, errorKey: payload as string }
    default:
      return state
  }
}
