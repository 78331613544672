import IMyCollectionFacade, {
  BalancesOfType,
  FacadeTypes,
} from 'service/IMyCollectionFacade'
import Web3 from 'web3'
import { ContractType } from 'core/logic/contract/types/types'
import { ERC20ABI } from 'core/constants'
import { fromWei } from 'web3-utils'

export default class ERC20OnchainFacade extends IMyCollectionFacade {
  nftContractForRead!: Contract.IERC20

  constructor(
    nftContractAddress: string,
    web3ForRead: Web3,
    web3ForWrite: Web3
  ) {
    super(nftContractAddress, FacadeTypes.BENEFITS, web3ForRead, web3ForWrite)

    this.nftContractForRead = new this.web3ForRead.eth.Contract(
      ERC20ABI,
      this.contractAddress
    ) as any
  }
  async getOwnedItems(): Promise<BalancesOfType<ContractType.Fungible>[]> {
    const nftBalancesByWallet = await Promise.all(
      this.wallets.map((wallet) =>
        this.nftContractForRead.methods.balanceOf(wallet).call()
      )
    )

    return [
      {
        type: ContractType.Fungible,
        balance: nftBalancesByWallet.reduce(
          (acc, val) => acc + Number(fromWei(val)),
          0
        ),
      },
    ]
  }
}
