import { service } from 'core/modules/firebase'
import { AssetType } from './asset.types'

export const fetchAssetsByTokens = async ({
  tokenIds,
}: {
  tokenIds: (string | number)[]
}): Promise<AssetType[]> => {
  const docs = await service.fetchAssetsByTokens({
    tokenIds: tokenIds,
  })
  return docs.map((doc) => {
    const id = doc.id
    return {
      ...(doc as AssetType),
      id,
      created: new Date(doc.created),
    }
  })
}

export const fetchAssets = async () => {
  const res = await service.fetchAssets()
  return res.docs.map((doc) => {
    const data = doc.data()
    const id = doc.id
    return {
      ...(data as AssetType),
      id,
      created: new Date(data.created),
    }
  })
}

export const fetchAsset = async ({
  assetId,
}: {
  assetId: string
}): Promise<AssetType | null> => {
  const doc = await service.fetchAsset({ assetId })
  const data = doc.data()

  if (!data) return null

  const id = doc.id
  return {
    ...(data as AssetType),
    id,
    created: new Date(data.created),
  }
}
