import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    root: {
        "& th": {
            whiteSpace: "nowrap"
        },
        "& td, & th": {
            padding: "16px",
            textAlign: "left",
            "&:last-child": {
                textAlign: "right"
            },
            [theme.breakpoints.down(640)]: {
                "&:nth-child(2)": {
                    display: "none"
                }
            },
        }
    },
    heading: {
        "& h3, & p": {
            margin: "16px 0px"
        },
    },
    actionable: {
        backgroundColor: theme.palette.background.paper,
        "& th": {
            backgroundColor: theme.palette.background.default,
        },
        padding: "16px",
        "& button": {
            minWidth: "150px",
            [theme.breakpoints.down(640)]: {
                minWidth: "initial",
            },
        },
    },
    inline: {
        display: "flex",
    },
    whenSmall: {
        [theme.breakpoints.up(640)]: {
            display: "none",
        },
    },
    big: {
        fontSize: "2rem"
    },
    row: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap"
    }
}))