import Web3 from 'web3'
import Web3Factory from './Web3Factory'
import { ContractType } from 'core/logic/contract/types/types'

export enum FacadeTypes {
  BENEFITS,
  TOKENS,
}

export type Balances =
  | {
      type: ContractType.RarumNFT
      tokenId: number
      balance: number
    }
  | {
      type: ContractType.Loyalty
      cid: string
      balance: number
      tokenId: number
    }
  | {
      type: ContractType.Fungible
      balance: number
    }
export type BalancesOfType<C extends ContractType> = Balances & { type: C }

export default abstract class IMyCollectionFacade extends Web3Factory {
  wallets!: string[]
  contractAddress!: string
  facadeType!: FacadeTypes
  setWallets(...wallets: string[]) {
    this.wallets = wallets.filter(Boolean)
  }
  constructor(
    nftContractAddress: string,
    facadeType: FacadeTypes,
    web3ForRead: Web3,
    web3ForWrite: Web3
  ) {
    super(web3ForRead, web3ForWrite)
    this.contractAddress = nftContractAddress
    this.facadeType = facadeType
  }
  abstract getOwnedItems(): Promise<Balances[]>
}
