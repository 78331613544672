import React, { Fragment, useMemo, useRef, useState } from 'react'
import AdaptiveDialog from '@onepercentio/one-ui/dist/components/AdaptiveDialog/AdaptiveDialog'
import { IPOViewProps } from './IPO.types'
import Container from 'openspace/components/Container/Container'
import BackButtonView from 'components/BackButton/BackButton.view'
import { t, tO } from 'translate/i18n'
import AssetDisplayLogic from 'openspace/components/AssetDisplay/AssetDisplay.logic'
import OneText from '@onepercentio/one-ui/dist/components/Text/Text'
import { FigmaTypo } from 'containers/OneUIProvider/OneUIProvider.data'
import Card from 'components/Card/Card'
import Text from 'openspace/components/Text/Text'
import OfferPrice from 'components/offer/OfferPrice'
import { CurrenciesEnum } from 'core/types/CurrenciesEnum'
import Button from '@onepercentio/one-ui/dist/components/Button/Button'
import Countdown, {
  CountdownTextModel,
} from '@onepercentio/one-ui/dist/components/Countdown/Countdown'
import RarumTabs from 'openspace/components/RarumTabs/RarumTabs'
import Spacing from '@onepercentio/one-ui/dist/components/Spacing/Spacing'
import { ONE_DAY_DELAY } from 'utility/timer'
import parse from 'html-react-parser'
import { OfferType } from 'core/logic/drop/drop.types'
import IconRarum from 'components/IconRarum/IconRarum.view'
import InfinityScroll from '@onepercentio/one-ui/dist/components/InfinityScroll/InfinityScroll'
import useElementFit from '@onepercentio/one-ui/dist/hooks/useElementFit'
import Avatar from '@onepercentio/one-ui/dist/components/Avatar/Avatar'
import { ReactComponent as Linkedin } from 'openspace/assets/svg/icons/social/linkedin.svg'
import ChatRoomLogic from 'features/ChatRoom/ChatRoom.logic'
import { ChatRoomProvider } from 'context/ChatRoom'
import { PortalReceiver } from 'components/Portal/Portal'
import { ChatRoomPortal } from 'features/ChatRoom/ChatRoom.view'
import LabeledHR from 'openspace/components/LabeledHR/LabeledHR'
import Styles from './IPO.module.scss'
import ProgressBar from '@onepercentio/one-ui/dist/components/ProgressBar/ProgressBar'
import { ReactComponent as Guide } from 'openspace/assets/svg/icons/arrow-down.svg'
import {
  currencyNumberFormatter,
  numberFormatter,
} from 'core/helpers/formatter'
import Loader from '@onepercentio/one-ui/dist/components/Loader/Loader'
import usePageScroll from 'openspace/hooks/usePageScroll'
import { useIPODetails } from './IPO.hooks'

export enum IPO_SECTIONS {
  ABOUT,
  DOCUMENTS,
  KPI,
  FOUNDERS,
  FORUM,
}

function Documents({
  documents,
}: {
  documents: OfferType['cvm88']['documents']
}) {
  return (
    <>
      <div className={Styles.documents}>
        {documents.map(({ name, link }) => (
          <a href={link} rel='noreferrer' target='_blank'>
            <Button variant='filled' color='primary'>
              {name}&nbsp;
              <IconRarum icon='download' size={24} />
            </Button>
          </a>
        ))}
      </div>
    </>
  )
}

function Founders({ founders }: { founders: OfferType['cvm88']['founders'] }) {
  const { itemsToShow, ref } = useElementFit(320)
  return (
    <>
      <InfinityScroll
        ref={ref}
        pageSize={itemsToShow || 1}
        items={founders.map((f) => (
          <Card>
            <div>
              <Avatar name={f.name} imgSrc={f.profilePicture} size={56} />
              <Spacing size='16' />
              <OneText type={FigmaTypo.H3}>{f.name}</OneText>
              <Spacing size='16' />
              <OneText type={FigmaTypo.P16}>{f.resume}</OneText>
              <Spacing size='32' />
              {f.links[0] && (
                <Button
                  variant='transparent-link'
                  onClick={() => window.open(f.links[0].url, '_blank')}>
                  <Linkedin />
                  &nbsp;
                  {tO('offerDetails.actions.seeProfile', {
                    social: f.links[0].type,
                  })}
                </Button>
              )}
            </div>
          </Card>
        ))}
        className={Styles.founderContainer}
      />
    </>
  )
}

function Forum({ offer }: { offer: OfferType }) {
  const [showForum, setShowForum] = useState(false)
  return (
    <div className={Styles.forum}>
      <Text code='offerDetails.forum.description' type={FigmaTypo.P16} />
      <Spacing size='32' />
      <Button
        variant='filled'
        color='primary'
        onClick={() => setShowForum(true)}>
        {tO('offerDetails.forum.actions.access')}
      </Button>
      <AdaptiveDialog
        open={showForum}
        className={Styles.forumDialog}
        onClose={() => setShowForum(false)}
        onClickOut={() => setShowForum(false)}>
        <div className={Styles.forumModal}>
          <div className={Styles.pad}>
            <Text code={`offerDetails.sections.FORUM`} type={FigmaTypo.H3} />
          </div>
          <Spacing size='32' />
          <PortalReceiver name={ChatRoomPortal.INPUT} />
          <Spacing size='32' />
          <div className={Styles.pad}>
            <Text code={`offerDetails.forum.posts`} type={FigmaTypo.H4} />
            <Spacing size='16' />
            <LabeledHR label='' />
            <Spacing size='32' />
            <ChatRoomProvider roomId={`ipo-${offer.id}`}>
              <ChatRoomLogic />
            </ChatRoomProvider>
          </div>
        </div>
      </AdaptiveDialog>
    </div>
  )
}

/**
 * An all new drop view layout for displaying IPO offering information
 **/
export default function IPOView({
  onBack,
  offer,
  onInvest,
  goal,
  endAt,
  companyShare: percentValuation,
  valuation: preValuation,
  quotas,
}: IPOViewProps) {
  const scrollTo = usePageScroll()
  const sectionsRef = useRef<HTMLDivElement>(null)
  const [, , reservedQuotas] = quotas
  const { currencyFormatter, intl, quotasProgress } = useIPODetails(
    offer,
    quotas
  )
  const [now, setNow] = useState(Date.now)
  const remaining = useMemo(() => endAt.getTime() - now, [now, endAt])
  const daysRemaining = useMemo(() => {
    const days = Math.floor(remaining / ONE_DAY_DELAY)
    return days
  }, [remaining])
  const hoursRemaining = useMemo(() => {
    return remaining - ONE_DAY_DELAY * daysRemaining
  }, [remaining, daysRemaining])
  function refreshTime() {
    setTimeout(() => {
      setNow(Date.now())
    }, 1000)
  }

  const sectionFactory = (currentSection: IPO_SECTIONS) => {
    const content = (() => {
      switch (currentSection) {
        case IPO_SECTIONS.ABOUT:
          return <>{parse(intl.features!)}</>
        case IPO_SECTIONS.KPI:
          return <>{parse(offer.cvm88.kpi!)}</>
        case IPO_SECTIONS.DOCUMENTS:
          return <Documents documents={offer.cvm88.documents} />
        case IPO_SECTIONS.FOUNDERS:
          return <Founders founders={offer.cvm88.founders} />
        default:
          return <Forum offer={offer} />
      }
    })()
    return (
      <div key={IPO_SECTIONS[currentSection]}>
        <Text
          code={`offerDetails.sections.${
            IPO_SECTIONS[currentSection] as 'ABOUT'
          }`}
          type={FigmaTypo.H2}
        />
        <Spacing size='32' />
        {content}
      </div>
    )
  }

  return (
    <>
      <Container>
        <BackButtonView
          className={Styles.back}
          onClick={onBack}
          text={t('generic.goBack')}
        />
        <div className={Styles.main}>
          <AssetDisplayLogic asset={offer.asset} />
          <div>
            <OneText type={FigmaTypo.H2}>{intl.title}</OneText>
            <Spacing size='16' />
            <OneText type={FigmaTypo.P14}>{intl.description}</OneText>
            <Spacing size='16' />
            <Card className={Styles.investmentCard}>
              <div className={Styles.investmentInfo}>
                <div>
                  <Text code='offerDetails.goal' type={FigmaTypo.H4} />
                  <OneText type={FigmaTypo.H2}>
                    {currencyFormatter.format(goal)}
                  </OneText>
                </div>
                <div>
                  <Text code='offerDetails.minInvestment' type={FigmaTypo.H4} />
                  <OneText type={FigmaTypo.H2}>
                    {
                      <OfferPrice
                        mode='text'
                        prices={offer.prices!}
                        preferredCurrency={CurrenciesEnum.BRL}
                      />
                    }
                  </OneText>
                </div>
              </div>
              <Spacing size='16' />
              <div
                className={Styles.goalProgress}
                style={
                  { '--goal-progress': `${quotasProgress.toGoal}%` } as any
                }>
                <ProgressBar
                  progress={quotasProgress.toTotal || 0}
                  size={14}
                  mode='gauge'
                />
                {reservedQuotas !== undefined ? (
                  <OneText type={FigmaTypo.P12}>
                    {goalReachedLabel(offer.cvm88.type, quotasProgress)}
                  </OneText>
                ) : (
                  <Loader />
                )}
                <Guide />
              </div>
            </Card>
            <Spacing size='32' />
            <Button variant='filled' color='primary' onClick={onInvest}>
              {tO('offerDetails.actions.invest')}
            </Button>
          </div>
        </div>
      </Container>
      <Spacing size='32' />
      <div className={`${Styles.ribbon} dark`}>
        <Container>
          <div>
            <Text code='offerDetails.timeRemaining' type='p12' />
            <OneText type={FigmaTypo.H3}>
              {daysRemaining === 0 ? (
                <Countdown
                  timeRemaining={remaining}
                  model={CountdownTextModel.SHORT}
                  onFinish={refreshTime}
                />
              ) : (
                t('generic.day', { count: daysRemaining })
              )}
            </OneText>
            <OneText type={FigmaTypo.P14}>
              {daysRemaining === 0 ? null : (
                <Countdown
                  timeRemaining={hoursRemaining}
                  model={CountdownTextModel.SHORT}
                  onFinish={refreshTime}
                />
              )}
            </OneText>
          </div>
          <div>
            <Text code='offerDetails.offer' type='p12' />
            <OneText type={FigmaTypo.H3}>{percentValuation}%</OneText>
            <Text
              code='offerDetails.companyParticipation'
              type={FigmaTypo.P14}
            />
          </div>
          <div>
            <Text code='offerDetails.preMoneyValuation' type='p12' />
            <OneText type={FigmaTypo.H3}>
              {currencyFormatter.format(preValuation)}
            </OneText>
          </div>
        </Container>
      </div>
      <Spacing size='32' />
      <Container>
        <div>
          <RarumTabs
            onSelect={(v) => {
              const section = sectionsRef.current!.children.item(
                v as number
              )! as HTMLDivElement
              scrollTo(section, [
                document.querySelector(`.${Styles.tabs}`)!.clientHeight + 32,
              ])
            }}
            options={Object.values(IPO_SECTIONS)
              .filter((s) => typeof s === 'number')
              .map((s) => ({
                id: s as IPO_SECTIONS,
                label: tO(
                  `offerDetails.sections.${IPO_SECTIONS[s as any] as 'ABOUT'}`
                ),
              }))}
            selected={undefined}
            size='large'
            className={Styles.tabs}
          />
          <Spacing size='32' />
          <div ref={sectionsRef} className={Styles.sections}>
            {sectionFactory(IPO_SECTIONS.ABOUT)}
            {sectionFactory(IPO_SECTIONS.DOCUMENTS)}
            {sectionFactory(IPO_SECTIONS.KPI)}
            {sectionFactory(IPO_SECTIONS.FOUNDERS)}
            {sectionFactory(IPO_SECTIONS.FORUM)}
          </div>
        </div>
      </Container>
    </>
  )
}

export function IPOBannerView({
  offer,
  onKnowMore,
  quotas,
  goal,
}: ClickActions<'onKnowMore'> &
  Pick<IPOViewProps, 'offer' | 'quotas' | 'goal'>) {
  const [, , reservedQuotas] = quotas
  const { currencyFormatter, intl, quotasProgress } = useIPODetails(
    offer,
    quotas
  )
  return (
    <Card className={Styles.banner}>
      <div className={Styles.main}>
        <AssetDisplayLogic asset={offer.asset} />
        <div>
          <OneText type={FigmaTypo.H2}>{intl.title}</OneText>
          <Spacing size='16' />
          <OneText type={FigmaTypo.P14}>{intl.description}</OneText>
          <Spacing size='16' />
          <Card className={Styles.investmentCard}>
            <div className={Styles.investmentInfo}>
              <div>
                <Text code='offerDetails.goal' type={FigmaTypo.H4} />
                <OneText type={FigmaTypo.H2}>
                  {currencyFormatter.format(goal)}
                </OneText>
              </div>
              <div>
                <Text code='offerDetails.minInvestment' type={FigmaTypo.H4} />
                <OneText type={FigmaTypo.H2}>
                  {
                    <OfferPrice
                      mode='text'
                      prices={offer.prices!}
                      preferredCurrency={CurrenciesEnum.BRL}
                    />
                  }
                </OneText>
              </div>
            </div>
            <Spacing size='16' />
            <div
              className={Styles.goalProgress}
              style={{ '--goal-progress': `${quotasProgress.toGoal}%` } as any}>
              <ProgressBar
                progress={quotasProgress.toTotal || 0}
                size={14}
                mode='gauge'
              />
              {reservedQuotas !== undefined ? (
                <OneText type={FigmaTypo.P12}>
                  {quotasProgress.toReserved === 0
                    ? tO('offerDetails.goalReached_0')
                    : quotasProgress.toReserved! >= 100
                    ? tO('offerDetails.goalReached_beyond')
                    : tO('offerDetails.goalReached', {
                        percent: currencyNumberFormatter(
                          quotasProgress.toReserved!
                        ),
                      })}
                </OneText>
              ) : (
                <Loader />
              )}
              <Guide />
            </div>
          </Card>
          <Spacing size='32' />
          <Button variant='filled' color='primary' onClick={onKnowMore}>
            {tO('offerDetails.actions.moreInfo')}
          </Button>
        </div>
      </div>
    </Card>
  )
}

export function goalReachedLabel(
  offerType: OfferType['cvm88']['type'],
  quotasProgress: ReturnType<typeof useIPODetails>['quotasProgress']
) {
  let tag!: 'offerDetails.goalReached' | 'offerDetails.sold'
  switch (offerType) {
    case 'equity':
      tag = 'offerDetails.goalReached'
      break
    case 'fixed':
    case 'receivable':
      tag = 'offerDetails.sold'
      break
  }
  if (quotasProgress.toReserved === undefined) return <Loader />
  return (
    <>
      {quotasProgress.toReserved === 0
        ? tO(`${tag}_0`)
        : quotasProgress.toReserved! >= 100
        ? tO(`${tag}_beyond`)
        : tO(`${tag}`, {
            percent: numberFormatter(
              Number(quotasProgress.toReserved!.toFixed(0))
            ),
          })}
    </>
  )
}
