import React, { ComponentProps } from 'react'
import Styles from './SectionLoader.module.scss'
import Container from '../Container/Container'
import { ReactComponent as Loader } from 'openspace/assets/svg/loader.svg'

/**
 * A Loader customized for loading a section, centered and padded
 **/
export default function SectionLoader(props: ComponentProps<typeof Loader>) {
  return (
    <Container className={Styles.root}>
      <Loader {...props} className={Styles.loader} />
    </Container>
  )
}
