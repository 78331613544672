import AssetDisplayLogic from 'openspace/components/AssetDisplay/AssetDisplay.logic'
import { AssetType } from 'core/logic/asset/asset.types'
import Text from '@onepercentio/one-ui/dist/components/Text'
import Button from '@onepercentio/one-ui/dist/components/Button'
import { FigmaTypo } from 'containers/OneUIProvider/OneUIProvider'
import { t } from 'translate/i18n'
import { useHistory } from 'react-router-dom'
import { TO_MY_COLLECTION } from 'core/modules/router'

/**
 * A message that indicates the user is out of balance for the specified item
 **/
export default function NoBalance({ asset }: { asset: AssetType }) {
  const { replace } = useHistory()
  return (
    <>
      <br />
      <div style={{ maxWidth: 300 }}>
        <AssetDisplayLogic asset={asset} />
      </div>
      <br />
      <Text type={FigmaTypo.H2}>
        {t('authenticated.myCollection.collectionItemDetails.noBalance.title')}
      </Text>
      <br />
      <Button variant='outline' onClick={() => replace(TO_MY_COLLECTION())}>
        {t(
          'authenticated.myCollection.collectionItemDetails.noBalance.action.backToMyCollection'
        )}
      </Button>
    </>
  )
}
