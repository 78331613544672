import { Fragment, ReactElement, useEffect, useMemo, useState } from 'react'
import { ClaimAuctionStatus } from './ClaimAuctionItem.types'
import { useAuction } from 'context/Auction'
import OnChainAuctionFacade from 'service/IAuctionFacade/OnChainAuctionFacade'
import useAsyncControl from '@onepercentio/one-ui/dist/hooks/useAsyncControl'
import { useUser } from 'core/logic/user'
import { useWallet } from 'context/Wallet'
import KYCActionWrapper from '../KYCActionWrapper'
import { t } from 'translate/i18n'
import useWeb3Utils from 'hooks/useWeb3Utils'

export default function ClaimAuctionItemLogic() {
  const web3Utils = useWeb3Utils()
  const { profile } = useUser()
  const auctionFacade = useAuction() as OnChainAuctionFacade
  const { loading, error, process } = useAsyncControl({})
  const { isConnected } = useWallet()
  const [allowance, setAllowance] = useState<number>()
  const [winningBid, setWinningBid] =
    useState<Awaited<ReturnType<OnChainAuctionFacade['getWinningBid']>>>()
  const [isOfferSettled, setIsOfferSettled] = useState<boolean>()

  function initInfo() {
    process(() =>
      !winningBid
        ? auctionFacade.getWinningBid().then(setWinningBid)
        : Promise.resolve()
    )
      .then(() =>
        allowance === undefined
          ? process(() => auctionFacade.getTokenAllowance()).then(setAllowance)
          : Promise.resolve()
      )
      .then(() =>
        isOfferSettled === undefined
          ? process(() => auctionFacade.isOfferSettled()).then(
              setIsOfferSettled
            )
          : Promise.resolve()
      )
  }

  const status = useMemo(() => {
    if (!winningBid || !profile || !profile.isLoaded || allowance === undefined)
      return ClaimAuctionStatus.LOADING
    if (
      web3Utils.toChecksumAddress(winningBid.bidder) !==
      web3Utils.toChecksumAddress(profile.wallet!)
    )
      // The user wallet is not the winner
      return ClaimAuctionStatus.NOT_WINNER

    if (isOfferSettled)
      // The user has already claimed
      return ClaimAuctionStatus.CLAIMED

    if (!isConnected) return ClaimAuctionStatus.DISCONNECTED_WALLET
    if (allowance < winningBid.amount)
      // The allowance is lower than the amount required
      return ClaimAuctionStatus.PENDING_ALLOWANCE
    // The user is ok to claim
    else return ClaimAuctionStatus.CLAIMABLE
  }, [profile, allowance, winningBid, isOfferSettled, isConnected])

  const action = useMemo(() => {
    switch (status) {
      case ClaimAuctionStatus.CLAIMABLE:
        return () =>
          process(() => auctionFacade.claim()).then(() =>
            setIsOfferSettled(true)
          )
      case ClaimAuctionStatus.PENDING_ALLOWANCE:
        return () =>
          process(() =>
            auctionFacade.approveAllowance(winningBid!.amount)
          ).then(() =>
            process(() => auctionFacade.getTokenAllowance()).then(setAllowance)
          )
      default:
        return error ? initInfo : undefined!
    }
  }, [status, auctionFacade, error])

  useEffect(() => {
    initInfo()
  }, [auctionFacade])

  const isLoading = useMemo(() => {
    return (
      allowance === undefined ||
      isOfferSettled === undefined ||
      !winningBid ||
      loading ||
      (status === ClaimAuctionStatus.LOADING && !error)
    )
  }, [allowance, isOfferSettled, winningBid, loading, status, error])

  const text = useMemo<ReactElement>(() => {
    if (status === ClaimAuctionStatus.LOADING) return null!
    if (error)
      return (
        <Fragment key={status + Date.now()}>{t(`generic.retryBtn`)}</Fragment>
      )
    else
      return (
        <Fragment key={status + Date.now()}>
          {t(`components.offerAuctionRace.result.status.${status}`)}
        </Fragment>
      )
  }, [status, error])

  return (
    <KYCActionWrapper
      disabled={false}
      loading={isLoading}
      onAction={action}
      text={text}
      error={error}
    />
  )
}
