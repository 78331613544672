import { Snackbar, useTheme } from '@material-ui/core'
import IconRarum from 'components/IconRarum'
import {
  ComponentProps,
  createContext,
  lazy,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react'
import useFreeze from '@onepercentio/one-ui/dist/hooks/useFreeze'
const LazyAlert = lazy(() =>
  import('containers/App/DelayedModules/DelayedModules').then((d) => ({
    default: d.Alert,
  }))
)

type ContextShape = {
  showAlert: (
    message: string,
    severity: NonNullable<ComponentProps<typeof LazyAlert>['severity']>
  ) => void
}

const Context = createContext<ContextShape>(null as any)

export function ToastProvider({ children }: PropsWithChildren<{}>) {
  const [toasts, setToasts] = useState<
    (
      | {
          severity: NonNullable<ComponentProps<typeof LazyAlert>['severity']>
          message: string
        }
      | undefined
    )[]
  >([])
  const latestToast = useFreeze(toasts[0])
  const theme = useTheme()

  const dismiss = useCallback(() => {
    setToasts(([_prev, ...other]) => other)
  }, [])

  const showAlert = useCallback<ContextShape['showAlert']>(
    (...c) =>
      setToasts((prev) => [
        ...prev,
        {
          message: c[0],
          severity: c[1],
        },
      ]),
    []
  )

  return (
    <Context.Provider
      value={{
        showAlert,
      }}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!toasts.length}
        onClose={dismiss}
        style={{ zIndex: 10000000 }}>
        <LazyAlert
          severity={latestToast?.severity}
          icon={
            <IconRarum
              icon='alert'
              color={
                latestToast
                  ? theme.palette[latestToast.severity].main
                  : undefined
              }
              size={24}
            />
          }>
          {latestToast?.message}
        </LazyAlert>
      </Snackbar>
    </Context.Provider>
  )
}

export function useToast() {
  return useContext(Context)
}
