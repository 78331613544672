import { useUser } from 'core/logic/user'
import useRouteGalleryOrDefault from 'openspace/hooks/useRoutePathGallery'
import NftInfoView from './NftInfo.view'

export interface NftInfoLogicProps {
  ipfs?: string
}

const NftInfoLogic: React.FC<NftInfoLogicProps> = ({ ipfs }) => {
  const gallery = useRouteGalleryOrDefault()
  const { detail, profile } = useUser()

  return (
    <NftInfoView
      contract={gallery?.smartContract?.address}
      wallet={profile?.wallet}
      ipfs={ipfs}
      userName={detail?.displayName}
    />
  )
}

export default NftInfoLogic
