import React, { ComponentProps, Fragment, useMemo, useState } from 'react'
import { KYCStatusViewProps } from './KYCStatus.types'
import useKYCStatusStyles from './KYCStatus.styles'
import { Dialog, DialogContent, Typography, useTheme } from '@material-ui/core'
import KYC from 'components/KYC'

import { t } from 'translate/i18n'
import UncontrolledTransition from '@onepercentio/one-ui/dist/components/UncontrolledTransition'
import { TransitionAnimationTypes } from '@onepercentio/one-ui/dist/components/Transition/Transition'
import IconRarum from 'components/IconRarum'
import { CONTACT_US, METAMAP_CONFIG } from 'core/constants'
import DialogTitleWithCloseIconView from 'components/DialogTitleWithCloseIcon'
import Freeze from '@onepercentio/one-ui/dist/components/Freeze'
import HSForms from '@onepercentio/one-ui/dist/components/HSForms'
import Button from '@onepercentio/one-ui/dist/components/Button'

/**
 * Shows the status of the KYC related to the current profile
 **/
export default function KYCStatusView({ status }: KYCStatusViewProps) {
  const classes = useKYCStatusStyles()
  const [contactUs, setContactUs] = useState(false)

  const config = useMemo<{
    icon: ComponentProps<typeof IconRarum>['icon']
    title: string
    description: string
    btn?:
      | true
      | {
          label: string
          onClick: () => void
        }
  }>(() => {
    switch (status) {
      case 'awaiting_metamap':
      case 'awaiting_confirmation':
      case 'verified':
      case 'verification_completed':
      case 'verification_updated':
      case 'verification_inputs_completed':
      case 'verification_started':
        return {
          description: t(
            'authenticated.userProfile.kyc.awaiting_metamap.description'
          ),
          title: t('authenticated.userProfile.kyc.awaiting_metamap.title'),
          icon: 'time',
        }
      case 'rejected':
      case 'reviewNeeded':
      case 'error_on_blockchain':
      case 'inactive':
        return {
          description: t('authenticated.userProfile.kyc.rejected.description'),
          title: t('authenticated.userProfile.kyc.rejected.title'),
          icon: 'close',
          btn: {
            label: t('generic.contactUs'),
            onClick: () => setContactUs(true),
          },
        }
      case 'active':
        return {
          description: t('authenticated.userProfile.kyc.active.description'),
          title: t('authenticated.userProfile.kyc.active.title'),
          icon: 'checkmark',
        }
      case 'initiated':
      default:
        return {
          description: t('authenticated.userProfile.kyc.pending.description'),
          icon: 'security',
          title: t('authenticated.userProfile.kyc.pending.title'),
          btn: true,
        }
    }
  }, [status])

  const iconColor = useTheme().palette.primary.contrastText
  const dismiss = () => setContactUs(false)

  return (
    <div className={classes.container}>
      <UncontrolledTransition transitionType={TransitionAnimationTypes.FADE}>
        <Fragment key={config.title}>
          <IconRarum icon={config.icon} size={48} color={iconColor} />
          <div className={classes.text}>
            <Typography>{config.title}</Typography>
            <Typography>{config.description}</Typography>
          </div>
          {config.btn &&
            (typeof config.btn === 'object' ? (
              <Button style={{ color: iconColor }} onClick={config.btn.onClick}>
                {config.btn.label}
              </Button>
            ) : (
              <KYC
                config={METAMAP_CONFIG}
                onCancel={() => {}}
                onFinish={() => {}}
                extraInformation={{}}
              />
            ))}
          <Dialog open={contactUs} onClose={dismiss}>
            <DialogTitleWithCloseIconView
              title={
                <Freeze>
                  {typeof config.btn === 'object'
                    ? config.btn.label
                    : undefined}
                </Freeze>
              }
              onClose={dismiss}
            />
            <DialogContent>
              <HSForms
                formId={CONTACT_US.formId}
                partialId={CONTACT_US.portalId}
                region='na1'
              />
            </DialogContent>
          </Dialog>
        </Fragment>
      </UncontrolledTransition>
    </div>
  )
}
