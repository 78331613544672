import React, {
  ComponentProps,
  ElementRef,
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { PaymentFlowView } from './Payment.view'
import { PaymentProps } from './Payment.types'
import { IsolatedPaymentMethodSelector } from '../Steps/StepMethod.view'
import { PaymentMethods } from '../Payment.types'
import { useTenant } from 'core/logic/tenant/tenant.hook'
import SectionLoader from 'openspace/components/SectionLoader'
import { CurrenciesEnum } from 'core/types/CurrenciesEnum'
import { useDrop } from 'core/logic/drop/drop.hook'
import { useHistory, useParams } from 'react-router-dom'
import { PaymentsProvider } from 'core/logic/tenant/tenant.types'
import PaymentView from './Payment.view'
import { t, tO } from 'translate/i18n'
import FlowPrerequirementsProvider, {
  PrereqStep,
  Prerequireable,
  useFormForStep,
  usePendingSteps,
  usePrerequirements,
  useSteps,
  useUpdatePrereqAnswers,
} from 'context/FlowPrerequirements'
import { OfferType } from 'core/logic/drop/drop.types'
import { ALL_POSSIBLE_ANSWERS } from 'features/FlowPrerequirements/StandalonePrereq/StandalonePrereq.types'
import { FormQuestions } from 'features/CVM88/Form/Form.view'
import { FormMode } from 'features/CVM88/Form/Form.types'
import ActionsContainer from 'openspace/components/ActionsContainer'
import Button from '@onepercentio/one-ui/dist/components/Button'
import { pick } from 'lodash'
import UncontrolledTransition from '@onepercentio/one-ui/dist/components/UncontrolledTransition'
import { useGallery } from 'context/Gallery'
import Card from 'components/Card'
import Text from 'openspace/components/Text'
import { TransitionAnimationTypes } from '@onepercentio/one-ui/dist/components/Transition/Transition'
import Styles from './Payment.module.scss'
import Spacing from '@onepercentio/one-ui/dist/components/Spacing'
import useCustomHistory from '@onepercentio/one-ui/dist/hooks/useCustomHistory'
import { TO_DROP_DETAILS, TO_DROP_PENDING_PAYMENT } from 'core/modules/router'
import { useDoPayment } from 'context/Payment'
import ProgressBar from '@onepercentio/one-ui/dist/components/ProgressBar'
import Container from 'openspace/components/Container'
import { AnswerByField } from '@onepercentio/one-ui/dist/components/Form/v2/FormField/FormField.types'

export default function PaymentLogic(props: PaymentProps) {
  const { tenant } = useTenant()
  const [selectedPaymentMethod, setPaymentMethod] =
    useState<ComponentProps<typeof PaymentView>['paymentMethod']>()
  const { dropId } = useParams<{ dropId: string }>()
  const { drop } = useDrop({ dropId })
  const uncRef = useRef<ElementRef<typeof UncontrolledTransition>>(null)
  const history = useCustomHistory()

  const step = (() => {
    if (!tenant || !drop) return <SectionLoader key='loader' />
    if (selectedPaymentMethod === undefined)
      return (
        <IsolatedPaymentMethodSelector
          key='payment-method'
          acceptedCurrencies={tenant.currencies!.accepted}
          onNext={(method) => {
            setPaymentMethod([
              method.provider!,
              method.metodo!,
              method.currency!,
            ])
          }}
          drop={drop}
          onBack={() => history.goBackWithFallback(TO_DROP_DETAILS(dropId))}
        />
      )
    return (
      <PaymentFlowWrapper
        key={'payment'}
        offer={drop as OfferType}
        onBackToMethod={() => {
          uncRef.current!.setOrientation('backward')
          setPaymentMethod(undefined)
        }}
        paymentMethod={selectedPaymentMethod[1]}
        currency={selectedPaymentMethod[2]}
        provider={selectedPaymentMethod[0]}
      />
    )
  })()
  return (
    <PaymentView
      onBack={() => history.goBackWithFallback(TO_DROP_DETAILS(dropId))}
      offer={drop as OfferType}
      paymentMethod={selectedPaymentMethod}>
      <UncontrolledTransition
        ref={uncRef}
        transitionType={TransitionAnimationTypes.SLIDE}>
        {step}
      </UncontrolledTransition>
    </PaymentView>
  )
}

function PaymentFlowWrapper({
  offer,
  ...props
}: {
  offer: OfferType
  paymentMethod: PaymentMethods
  currency: CurrenciesEnum
  provider: PaymentsProvider
} & ClickActions<'onBackToMethod'>) {
  const { tenant } = useTenant()
  const { provider } = props
  const paymentMethodRequirements = useMemo(() => {
    const config =
      tenant!.paymentProviders![provider].methods![props.paymentMethod]
    if (typeof config === 'boolean') {
      return {} as Prerequireable
    } else {
      return config
    }
  }, [tenant, props.paymentMethod, provider])

  const mergedRequirements = usePrerequirements(
    offer,
    paymentMethodRequirements
  )

  return (
    <FlowPrerequirementsProvider requirement={mergedRequirements}>
      <PaymentFlow offer={offer} {...props} />
    </FlowPrerequirementsProvider>
  )
}

function PaymentFlow({
  offer,
  onBackToMethod,
  paymentMethod,
  currency,
  provider,
}: ComponentProps<typeof PaymentFlowWrapper>) {
  const steps = useSteps()
  const pendingSteps = usePendingSteps(undefined)
  const gallery = useGallery(offer.galleryId!)!

  const stepsToComplete = useMemo(() => {
    return [...steps!, 'confirm', 'payment'] as const
  }, [steps])

  const [currStep, setCurrStep] = useState<(typeof stepsToComplete)[number]>(
    pendingSteps.length === 0 ? 'confirm' : pendingSteps[0]
  )
  const [storedAnswers, setAnswers] = useState<ALL_POSSIBLE_ANSWERS>({})
  const [paymentInformation, setPaymentInformation] =
    useState<ALL_POSSIBLE_ANSWERS>()

  const CurrScreenComp = useMemo(() => {
    switch (currStep) {
      case 'confirm':
        return () => {
          const availableSteps = Object.values(PrereqStep)
          const stepsAnswers = availableSteps.map((step, idx) =>
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useFormForStep(storedAnswers, step, idx === 0, {
              gallery,
              isRequired: false,
            })
          )

          return (
            <>
              <Card>
                <Text
                  code='authenticated.payment.review.title'
                  type='h3heading'
                />
                <Spacing size='16' />
                <Text
                  code='authenticated.payment.review.description'
                  type='p16'
                />
                <Spacing size='16' />
                <div className={Styles.resumesContainer}>
                  {availableSteps.map(
                    (step, idx) =>
                      stepsToComplete.includes(step) && (
                        <div>
                          <Text
                            code={`flowRequirements.steps.${step}.title`}
                            type='caption'
                          />
                          {Object.entries(stepsAnswers[idx].answers).map(
                            ([questionId, answer]) => {
                              const question = stepsAnswers[idx].questions.find(
                                (a) => a.id === questionId
                              )
                              if (!question) return null
                              switch (question.type) {
                                case 'address':
                                  const addressAnswer =
                                    answer as unknown as AnswerByField<{
                                      type: 'address'
                                    }>
                                  return Object.values(addressAnswer[0]).map(
                                    (a) => (
                                      <Text type='caption'>
                                        <b>{a}</b>
                                      </Text>
                                    )
                                  )
                                default:
                                  return (
                                    <Text type='caption'>
                                      <b>{answer}</b>
                                    </Text>
                                  )
                              }
                            }
                          )}
                          <Button
                            onClick={() => setCurrStep(step)}
                            variant='transparent-link'
                            color='primary'>
                            {t('generic.actions.edit')}
                          </Button>
                        </div>
                      )
                  )}
                  <div>
                    <Text
                      code={`authenticated.payment.review.formOfPayment`}
                      type='caption'
                    />
                    <Text type='caption'>
                      <b>
                        {t(
                          `authenticated.payment.steps.methodName.${paymentMethod}-${currency}`
                        )}
                      </b>
                    </Text>
                    <Button
                      onClick={() => onBackToMethod()}
                      variant='transparent-link'
                      color='primary'>
                      {t('generic.actions.edit')}
                    </Button>
                  </div>
                </div>
              </Card>
              <Spacing size='16' />
              <ActionsContainer>
                <Button
                  variant='outline'
                  onClick={() => {
                    if (currStep === stepsToComplete[0]) onBackToMethod()
                    else
                      setCurrStep(
                        (curr) =>
                          stepsToComplete[stepsToComplete.indexOf(curr) - 1]
                      )
                  }}>
                  {t('generic.goBack')}
                </Button>
                <Button
                  variant='filled'
                  color='primary'
                  onClick={() => {
                    setPaymentInformation(
                      stepsAnswers.reduce(
                        (all, ans) => ({ ...all, ...ans.answers }),
                        {} as ALL_POSSIBLE_ANSWERS
                      )
                    )
                    setCurrStep('payment')
                  }}>
                  {t('generic.continue')}
                </Button>
              </ActionsContainer>
            </>
          )
        }
      case 'payment':
        return () => (
          <CreatePayment
            currency={currency}
            offer={offer}
            paymentData={paymentInformation!}
            paymentMethod={paymentMethod}
            provider={provider}
          />
        )
      default:
        return function FormStep() {
          const {
            answers,
            errors,
            onAnswerAction,
            sections,
            isQuestionsAnswered,
            setShowErrors,
            questions,
          } = useFormForStep(
            storedAnswers,
            currStep,
            currStep === stepsToComplete[0],
            { gallery, isRequired: false }
          )

          return (
            <>
              <Card>
                <FormQuestions
                  answers={answers}
                  errors={errors}
                  mode={FormMode.WRITE}
                  onAnswer={(q, id, ans) => onAnswerAction(q as any, id, ans)}
                  sections={sections as any}
                  step={0}
                />
              </Card>
              <Spacing size='16' />
              <ActionsContainer>
                <Button
                  variant='outline'
                  onClick={() => {
                    if (currStep === steps![0]) onBackToMethod()
                    else
                      setCurrStep(
                        (curr) =>
                          stepsToComplete[stepsToComplete.indexOf(curr) - 1]
                      )
                  }}>
                  {currStep === steps![0]
                    ? t(`generic.cancel`)
                    : t('generic.goBack')}
                </Button>
                <Button
                  variant='filled'
                  color='primary'
                  onClick={() => {
                    if (!isQuestionsAnswered) setShowErrors(true)
                    else {
                      setAnswers((prev) => ({
                        ...prev,
                        ...pick(answers, ...questions.map((q) => q.id)),
                      }))
                      setCurrStep(
                        (prev) =>
                          stepsToComplete[stepsToComplete.indexOf(prev) + 1]
                      )
                    }
                  }}>
                  {t('generic.continue')}
                </Button>
              </ActionsContainer>
            </>
          )
        }
    }
  }, [currStep])

  const uncRef = useRef<ElementRef<typeof UncontrolledTransition>>(null)
  const prevStep = useRef<typeof currStep>(currStep)

  useLayoutEffect(() => {
    if (
      stepsToComplete.indexOf(currStep) <
      stepsToComplete.indexOf(prevStep.current)
    )
      uncRef.current!.setOrientation('backward')
    prevStep.current = currStep
  }, [currStep])

  return (
    <PaymentFlowView
      steps={{
        current: stepsToComplete.indexOf(currStep),
        all: [
          ...steps!.map((s) => tO(`flowRequirements.steps.${s}.title`)),
          t('authenticated.payment.data.revision'),
          t('authenticated.payment.data.payment'),
        ].map((t) => ({ title: t })),
      }}>
      <UncontrolledTransition ref={uncRef}>
        <CurrScreenComp key={currStep} />
      </UncontrolledTransition>
    </PaymentFlowView>
  )
}

function Countdown({ onFinished }: ClickActions<'onFinished'>) {
  const [progress, setProgress] = useState(100)

  useEffect(() => {
    const fps = 1000 / 24
    const howManySeconds = 2.5
    const howManyPercentPerSecond = 100 / howManySeconds
    const howManyPercentPerFrame = howManyPercentPerSecond / fps
    const intervalId = setInterval(() => {
      requestAnimationFrame(() => {
        setProgress((prev) => {
          const next = prev - howManyPercentPerFrame
          if (next < 0) return 0
          return next
        })
      })
    }, fps)
    return () => {
      clearInterval(intervalId)
    }
  }, [progress === 0])

  useEffect(() => {
    if (progress === 0) onFinished()
  }, [progress === 0])

  return <ProgressBar mode='gauge' progress={progress} size={48} />
}

function CreatePayment({
  offer,
  currency,
  paymentMethod,
  paymentData,
  provider,
}: Omit<ComponentProps<typeof PaymentFlowWrapper>, 'onBackToMethod'> & {
  paymentData: ALL_POSSIBLE_ANSWERS
}) {
  const steps = useSteps()
  const gallery = useGallery(offer.galleryId!)!
  const [paymentId, setPaymentId] = useState<string>()
  const history = useHistory()
  const { doPayment } = useDoPayment()
  const { updateProfile } = useUpdatePrereqAnswers(gallery)

  const createPayment = useCallback(async () => {
    await updateProfile(steps!, paymentData)
    const result = await doPayment(offer, paymentMethod, provider, currency, {
      document: paymentData.document!,
      email: paymentData.email!,
      isForeign: !!paymentData.isForeign?.[0],
      name: paymentData.name!,
    })
    setPaymentId(result.id)
  }, [])

  useEffect(() => {
    createPayment()
  }, [])

  return (
    <Card>
      <UncontrolledTransition>
        {!paymentId ? (
          <SectionLoader key={'l'} />
        ) : (
          <Container key='f' className={Styles.finished}>
            <Text code='authenticated.payment.finished.title' type='h3' />
            <Spacing size='16' />
            <Text
              code='authenticated.payment.finished.description'
              type='content'
            />
            <Spacing size='16' />
            <div style={{ maxWidth: 200, width: '100%' }}>
              <Countdown
                onFinished={() => {
                  setTimeout(() => {
                    history.push(TO_DROP_PENDING_PAYMENT(paymentId))
                  }, 1000)
                }}
              />
            </div>
            <Spacing size='32' />
            <Button
              variant='filled'
              color='primary'
              onClick={() => {
                history.push(TO_DROP_PENDING_PAYMENT(paymentId))
              }}>
              {t('authenticated.payment.finished.actions.goNow')}
            </Button>
          </Container>
        )}
      </UncontrolledTransition>
    </Card>
  )
}
