import { useCallback, useState } from 'react'

/**
 * Provides an generalized set of properties to control the order of elements of OrderableList
 */
export default function useOrder(id: string) {
  const [defaultOrder] = useState<string[] | undefined>(() => {
    const storedOrder = window.localStorage.getItem(id)
    if (storedOrder) return storedOrder.split(';')
    return undefined
  })

  const onChangeOrder = useCallback(
    (order: string[]) => window.localStorage.setItem(id, order.join(';')),
    [id]
  )

  return { order: defaultOrder, onChangeOrder }
}
