import { DataStatus } from '@onepercentio/one-ui/dist/components/Form/v2/Form.types'
import { OfferType } from 'core/logic/drop/drop.types'
import { PrereqTo } from 'features/FlowPrerequirements/StandalonePrereq/StandalonePrereq.types'
import Styles from 'openspace/assets/styles/TextStyles.module.scss'
import { MenuOptions } from 'openspace/components/TopBar/TopBar.types'
import { AvailableConcepts } from 'openspace/pages/Authenticated/Home/v2/Home/Features/Features'
import { IPO_SECTIONS } from 'pages/Unauthenticated/Drops/Details/Models/IPO/IPO.view'

export type ConceptTranslations = {
  [F in AvailableConcepts]: {
    title: string
    description: string
    features: string[]
  }
}

const messages = {
  'pt-BR': {
    translations: {
      flowRequirements: {
        steps: {
          personalData: {
            title: 'Dados pessoais',
            questions: {
              name: 'Nome completo',
              email: 'Email',
              phone: 'Telefone',
              document: 'CPF',
              documentForeign: 'Documento',
              isForeign: '',
              confirm:
                'Aceito compartilhar meus dados com <b>({{gallery}})</b> para fins de marketing',
            },
            error: {
              invalidCPF: 'Documento inválido',
            },
          },
          address: {
            title: 'Endereço',
          },
        },
        standalone: {
          hi: 'Olá, {{name}}',
          to: {
            claim: {
              title: 'Complete o cadastro para continuar com o resgate',
              action: 'Resgatar',
              loading: 'Por favor aguarde, estamos encaminhando para o resgate',
            },
          } as {
            [t in PrereqTo]: {
              title: string
              action: string
              loading: string
            }
          },
        },
      },
      generic: {
        noItemsFound: 'Nenhum item encontrado',
        brandMotto: 'Isso é raro. Isso é Rarum.',
        knowMore: 'Saiba mais',
      },
      forum: {
        postedAt: 'Postado {{time}} atrás',
        noItems:
          'Está com dúvidas? \nUtilize o campo acima e seja o primeiro a perguntar',
        post: {
          placeholder: 'Escreva sua dúvida aqui',
          cancelConfirmation: 'Deseja descartar a mensagem escrita?',
          loggedOutUser: 'Olá, é necessário login para interagir',
          actions: {
            cancel: 'Cancelar',
            post: 'Postar',
          },
        },
        actions: {
          reply: 'Responder',
        },
      },
      offerDetails: {
        goal: 'Meta',
        minInvestment: 'Investimento mínimo',
        minInvestmentShort: 'Invest. mínimo',
        investors: 'Investidores',
        reachedPrice: 'Total captado',
        beTheFirstToInvest: 'Seja o 1º a investir',
        receivable: {
          soldUnits: 'Total vendido',
          availability: 'Disponibilidade',
          expiration: 'Vencimento',
          sold: 'Vendido',
        },
        cvm: {
          performance: '{{performance}} ao mês',
          types: {
            equity: 'Equity',
            fixed: 'Renda fixa',
            receivable: 'Recebíveis',
          } satisfies {
            [k in OfferType['cvm88']['type']]: string
          },
        },
        goalReached: '{{percent}}% da meta alcançada',
        goalReached_0: 'Sem cotas reservadas',
        goalReached_beyond: 'Meta alcançada',
        sold: '{{percent}}% vendido',
        sold_0: 'Sem vendas',
        sold_beyond: 'Meta alcançada',
        limit: 'Limite',
        timeRemaining: 'TEMPO RESTANTE',
        offer: 'OFERTA',
        preMoneyValuation: 'VALUATION',
        companyParticipation: 'participação da empresa',
        sections: {
          ABOUT: 'Sobre o investimento',
          DOCUMENTS: 'Documentos',
          KPI: "KPI's",
          FOUNDERS: 'Fundadores',
          FORUM: 'Forum',
        } satisfies {
          [k in keyof typeof IPO_SECTIONS]: string
        },
        forum: {
          description:
            'Acesse o forum para conversar e tirar dúvidas com outros investidores e especialistas',
          posts: 'Postagens',
          actions: {
            access: 'Acessar Forum',
          },
        },
        actions: {
          invest: 'Investir',
          seeProfile: 'Ver perfil no {{social}}',
          moreInfo: 'Mais informações',
        },
      },
      components: {
        drop: {
          soldout: 'Unidades esgotadas',
          remaining: 'Último item disponível',
          remaining_plural: '{{count}} unidades disponíveis',
        },
        benefits: {
          title: 'Benefícios',
          subtitle: 'Veja e gerencie seus benefícios',
          claim: 'Resgatar',
          expired: 'Benefício expirado',
          claiming: 'Resgatando benefício',
          claimed: 'Benefício resgatado',
          expireIn: 'Expira em:',
          days_plural: '{{count}} dias',
          days: '{{count}} dia',
          remaining: '{{count}} unidade restante',
          remaining_plural: '{{count}} unidades restantes',
          claimConfirmation: {
            details: {
              title: 'Detalhe do benefício',
              details: 'Mais detalhes',
              actions: {
                claim: 'Resgatar benefício',
              },
            },
            title: 'Confirmar resgate',
            actions: {
              claim: 'Resgatar',
            },
            success: {
              title: 'Coleta confirmada!',
              subtitle:
                'Você tem certeza que deseja resgatar esse benefício? <br/>Essa ação não poderá ser desfeita',
              description:
                'Em breve você receberá instruções e mais informações sobre essa coleta no seu email cadastrado {{email}}',
              pending:
                'Esta transação pode levar alguns minutos. Assim que confirmado o resgate, você receberá instruções e mais informações no seu email cadastrado <b>{{email}}</b>',
              actions: {
                understood: 'Entendi',
              },
            },
          },
        },
        form: {
          dataStatus: {
            COMPLETE: 'Concluído',
            INCOMPLETE: 'Incompleto',
            PENDING: 'Pendente',
            ERROR: 'Com erro',
          } satisfies {
            [status in keyof typeof DataStatus]: string
          },
        },
        galleryCard: {
          tokenEmitter: {
            totalVolume: 'Volume total',
            projects: '{{count}} projeto',
            projects_plural: '{{count}} projetos',
          },
        },
      },
      home: {
        banner: {
          title: `ATIVOS DIGITAIS <br/><span class="${Styles.gradient}">SUSTENTÁVEIS E PARA <br/>TODOS</span>`,
          subtitle:
            'Mercado de ativos tokenizados customizados para a sua comunidade',
          action: {
            create: 'Crie agora sua loja!',
          },
          v2: {
            brand: 'rarum',
            brandMotto: 'Community Engagement as a Service',
            brandBenefit: 'Nós valorizamos o que é raro: sua comunidade.',
            actions: {
              features: 'Conheça nossa plataforma Whitelabel',
            },
          },
        },
        sections: {
          highlights: {
            title: 'Ofertas abertas',
          },
          gallery: {
            heading: 'Conheça as Galerias da Rarum',
            title: 'Últimas galerias',
            action: {
              seeAll: 'Ver todas galerias',
            },
          },
          latest: {
            title: 'Últimas ofertas',
            action: {
              seeAll: 'Ver todos itens',
            },
          },
          store: {
            title: 'Lojas',
          },
          brand: {
            title: '100% Carbon Neutral',
            description: `Compensamos todas as emissões de carbono da plataforma e dos ativos comercializados.

A Rarum também opera em blockchains que são eficientes nas suas emissões de gás de efeito estufa.`,
          },
          create: {
            title:
              'Crie sua própria galeria ou loja e comece a vender seus itens!',
            actions: {
              knowMore: 'Saiba mais',
            },
            highlight: {
              create: 'Crie seus ativos digitais',
              easy: 'Fácil para comprar e vender',
              secure: 'Segura, transparente e carbono neutro',
            },
          },
          partnership: {
            title: 'Carbono Neutro',
            description:
              'Em nossa busca por práticas mais sustentáveis, reduzimos significativamente nossas emissões de carbono e implementamos tecnologias blockchain de baixo impacto ambiental.',
            actions: {
              knowMore: 'Saiba mais',
            },
          },
          news: {
            title: 'Notícias',
          },
        },
      },
      allGalleries: {
        title: 'GALERIAS',
        sections: {
          highlight: {
            title: 'Galerias em destaque',
          },
          createYourOwn: {
            title:
              'Crie sua própria galeria ou loja e comece a vender seus ativos digitais!',
            action: {
              knowMore: 'Saiba mais',
            },
          },
        },
      },
      myCollection: {
        actions: {
          seeAllGallery: 'Ver todos',
          hideAllGallery: 'Voltar a lista',
        },
      },
      galleryDetails: {
        items: 'Ofertas ativas',
        expiredItems: 'Ofertas encerradas',
        market: 'Itens do mercado',
        noMarket: 'Itens da galeria',
        noAvailable: `Fique de olho!
Em breve teremos ofertas ativas nesta galeria`,
        actions: {
          seeAllMarketplace: 'Ver todos no mercado',
          seeAll: 'Ver todos os items',
        },
      },
      topbar: {
        menus: {
          [MenuOptions.ABOUT]: 'SOBRE A RARUM',
          [MenuOptions.FAQ]: 'FAQ',
          [MenuOptions.GALLERIES]: 'GALERIAS',
          [MenuOptions.MARKET]: 'MERCADO',
          [MenuOptions.HOW_IT_WORKS]: 'COMO FUNCIONA',
          [MenuOptions.BRAND]: 'MARCAS',
          [MenuOptions.CREATORS]: 'CRIADORES',
        } satisfies {
          [k in MenuOptions]: string
        },
        auth: {
          login: 'Acessar conta',
          signup: 'Cadastre-se',
        },
      },
    },
  },
}

export type RarumIntlShape = (typeof messages)['pt-BR']
export type AllRarumMessagesIntlShape = RarumIntlShape &
  typeof import('translate/languages/async/cvm88/pt')['default']['pt-BR']
export default messages
