import ROUTES from 'core/modules/router'
import AllGalleries from 'openspace/pages/Unauthenticated/AllGalleries'
import { ReactElement } from 'react'
import { Route } from 'react-router-dom'

const OpenspaceRoutes: ReactElement[] = [
  <Route key='root' exact path={ROUTES.unauthenticated.allGalleries}>
    <AllGalleries />
  </Route>,
]

export default OpenspaceRoutes
