import Box from '@material-ui/core/Box'
import React, { memo } from 'react'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'

export interface IdenticonViewProps {
  address: string
  diameter?: number
}

export const IdenticonView: React.FC<IdenticonViewProps> =
  memo<IdenticonViewProps>(({ address, diameter = 16 }) => {
    return (
      <Box ml={1} width={diameter}>
        <Jazzicon diameter={diameter} seed={jsNumberForAddress(address)} paperStyles={{display: 'flex'}}/>
      </Box>
    )
  })
