import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) =>
  createStyles({
    container: {},
    block: {
      borderRadius: 4,
      '&:not(:first-child)': {
        marginTop: theme.spacing(1),
      },
      '&:not(:last-child)': {
        marginBottom: theme.spacing(1),
      },
    },
  })
export default makeStylesWrapper(style)
