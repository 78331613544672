import {
  DataStatus,
  StepStatus,
} from '@onepercentio/one-ui/dist/components/Form/v2/Form.types'
import { Fragment, ReactElement, useMemo } from 'react'
import Styles from './Step.module.scss'
import OneText from '@onepercentio/one-ui/dist/components/Text'
import { FigmaTypo } from 'containers/OneUIProvider/OneUIProvider.data'
import { tO } from 'translate/i18n'
import UncontrolledTransition from '@onepercentio/one-ui/dist/components/UncontrolledTransition'
import { TransitionAnimationTypes } from '@onepercentio/one-ui/dist/components/Transition/Transition'
import useBreakpoints from '@onepercentio/one-ui/dist/hooks/ui/useBreakpoint'
import ProgressBar from '@onepercentio/one-ui/dist/components/ProgressBar'

/**
 * A step to show the progress of a sequence of steps
 **/
export default function Step({
  stepNumber,
  stepName,
  dataStatus,
  stepStatus,
}: {
  stepNumber: number
  stepName: string
  dataStatus: DataStatus
  stepStatus: StepStatus
}) {
  const badge = useMemo(() => {
    return (
      <OneText
        type={FigmaTypo.H4}
        className={`${Styles.badge} ${Styles[StepStatus[stepStatus]]}`}>
        {stepNumber}
      </OneText>
    )
  }, [stepStatus, stepNumber])
  const status = useMemo(() => {
    const lbl = tO(
      `components.form.dataStatus.${
        DataStatus[dataStatus] as keyof typeof DataStatus
      }`
    )
    switch (dataStatus) {
    }
    return <div>{lbl}</div>
  }, [dataStatus])
  return (
    <div className={`${Styles.stepContainer}`}>
      <UncontrolledTransition
        transitionType={TransitionAnimationTypes.COIN_FLIP}>
        <Fragment
          key={`step_${
            stepStatus === StepStatus.CURRENT ||
            stepStatus === StepStatus.COMPLETED
              ? 'primary'
              : 'secondary'
          }`}>
          {badge}
        </Fragment>
      </UncontrolledTransition>
      <OneText type={FigmaTypo.P14}>{stepName}</OneText>
      <OneText type={FigmaTypo.P12}>{status}</OneText>
    </div>
  )
}

export function StepsWrapper({
  steps,
  currStep,
  children,
  forceMobile,
  className = '',
}: {
  currStep: number
  steps: number
  /** Steps components */
  children: ReactElement[]
  forceMobile?: boolean
  className?: string
}) {
  const isMobile = useBreakpoints(640) || forceMobile
  return (
    <div
      className={`${Styles.headings} ${isMobile ? Styles.mobile : className}`}>
      {isMobile ? (
        <>
          <ProgressBar
            progress={((currStep + 1) * 100) / steps}
            size={24}
            mode='gauge'
          />
          <div>
            <UncontrolledTransition
              transitionType={TransitionAnimationTypes.COIN_FLIP}>
              {children[currStep]}
            </UncontrolledTransition>
          </div>
        </>
      ) : (
        children
      )}
    </div>
  )
}
