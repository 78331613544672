import React, { Fragment, useMemo, useState } from 'react'
import { UserProfileViewProps } from './UserProfile.types'
import { Box, Container, Typography, useTheme } from '@material-ui/core'
import { t } from 'translate/i18n'
import ProfileForm from '../ProfileForm'
import KYCStatus from './KYCStatus'
import ActionButton from 'components/ActionButton'
import BackButton from 'components/BackButton'
import AdaptiveContainer from '@onepercentio/one-ui/dist/components/AdaptiveContainer'
import Loader from '@onepercentio/one-ui/dist/components/Loader'
import InfoModal from 'components/InfoModal'
import useUserProfileStyles from './UserProfile.styles'

/**
 * The new version of the user profile implementation
 **/
export default function UserProfileView({
  form,
  onSubmit,
  update,
  clear,
  hasKyc,
  address,
  onClear,
}: UserProfileViewProps) {
  const classes = useUserProfileStyles()
  const [showClearWarning, setShowClearWarning] = useState(false)
  const {
    palette: {
      error: { main: errorColor },
    },
  } = useTheme()

  const action = useMemo(() => {
    return (
      <ActionButton
        text={
          <AdaptiveContainer>
            {update.loading ? (
              <Loader key={'loading' + Math.random()} />
            ) : update.error ? (
              <Fragment key={'error' + Math.random()}>
                {t('generic.retryBtn')}
              </Fragment>
            ) : (
              <Fragment key={'action' + Math.random()}>
                {t('authenticated.userProfile.saveData')}
              </Fragment>
            )}
          </AdaptiveContainer>
        }
        type='submit'
        form='user-form'
        disabled={
          !address.isComplete || !form.formState.isDirty || update.loading
        }
        showSpinner={update.loading}
      />
    )
  }, [update.loading, update.error, address.isComplete, form.formState.isDirty])

  const clearAction = useMemo(() => {
    return (
      <BackButton
        icon='close'
        iconColor={errorColor}
        disabled={clear.loading}
        text={
          <AdaptiveContainer>
            {clear.loading ? (
              <Loader key={'loading' + Math.random()} />
            ) : clear.error ? (
              <Fragment key={'error' + Math.random()}>
                {t('generic.retryBtn')}
              </Fragment>
            ) : (
              <Fragment key={'action' + Math.random()}>
                {t('authenticated.userProfile.deleteData')}
              </Fragment>
            )}
          </AdaptiveContainer>
        }
        onClick={() => setShowClearWarning(true)}
      />
    )
  }, [clear.error, clear.loading, errorColor])

  return (
    <>
      <Container style={{ maxWidth: '1000px', margin: '2rem auto' }}>
        <Typography variant='h2'>
          {t('authenticated.userProfile.myData')}
        </Typography>
        <br />
        <Typography variant='body1'>
          {t('authenticated.userProfile.dataBelow')}
        </Typography>
        {hasKyc && (
          <>
            <br />
            <KYCStatus />
          </>
        )}
        <br />
        <ProfileForm
          {...form}
          fetchingCEP={address.loading}
          lockFields={address.lockUpdates}
          errors={form.formState.errors}
          handleSubmit={form.handleSubmit(onSubmit)}
          version='v2'
        />
        <Box
          display={'flex'}
          flexWrap={'wrap'}
          justifyContent={'space-between'}>
          {action}
          {clearAction}
        </Box>
        <InfoModal
          title={t('authenticated.userProfile.wantToDelete')}
          open={showClearWarning}
          handleClose={() => setShowClearWarning(false)}
          content={t('authenticated.userProfile.sureDelete')}
          actions={
            <Box className={classes.actionsWrapper}>
              <ActionButton
                text={t('authenticated.userProfile.eraseData')}
                className={classes.actionButton}
                onClick={() => {
                  setShowClearWarning(false)
                  onClear()
                }}
              />
              <BackButton
                icon='close'
                text={t('authenticated.userProfile.cancel')}
                className={classes.cancelButton}
                onClick={() => setShowClearWarning(false)}
              />
            </Box>
          }
        />
      </Container>
    </>
  )
}
