import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const style = (_: Theme) =>
  createStyles({
    logo: {
      height: '100%',
      width: '100%',
    },
  })
export default makeStylesWrapper(style)
