import { DropType } from 'core/logic/drop/drop.types'
import { useUser } from 'core/logic/user'
import React, { useMemo } from 'react'
import { MessageType } from '../OpeningDrop.data'
import InProgressView from './InProgress.view'

export interface InProgressLogicProps {
  drop?: DropType | null
  onBackIntent: () => void
  messages: MessageType[]
  messageIndex: number
  mode?: 'purchase' | 'claim'
  deliveryCover: string
}

const InProgress: React.FC<InProgressLogicProps> = ({
  drop,
  onBackIntent,
  messages,
  messageIndex,
  mode,
  deliveryCover
}) => {
  const { detail } = useUser()

  const messagesWithUserName = useMemo(
    () =>
      messages.map((m) => ({
        ...m,
        title: m.title.replace(/{name}/g, detail?.displayName || ''),
      })),
    [messages, detail?.displayName]
  )

  return (
    <InProgressView
      mode={mode}
      drop={drop}
      onBackIntent={onBackIntent}
      messages={messagesWithUserName}
      messageIndex={messageIndex}
      deliveryCover={deliveryCover}
    />
  )
}

export default InProgress
