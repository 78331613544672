import { MetaMaskInpageProvider } from '@metamask/providers'
import { useChain } from 'context/Chain'
import { useEffect, useMemo, useState } from 'react'
import Web3 from 'web3'

const eth = window.ethereum

export default function useWeb3Provider<W extends boolean = false>(
  readOnly: boolean = false,
  returnWeb3?: W
): W extends true
  ? {
      provider?: MetaMaskInpageProvider
      web3?: Web3
    }
  : Web3 | undefined {
  const chainConfig = useChain()

  const [Web3, setWeb3] = useState<typeof import('web3').default>()
  const web3MetamaskProvider = useMemo(() => {
    const readonlyProvider = () => {
      if (
        process.env.AUTOMATION ||
        (process.env.NODE_ENV === 'development' && (window as any).Cypress)
      )
        return (eth as any) || window.ethereum
      return new Web3!.providers.HttpProvider(chainConfig!.providerAddress)
    }
    return !Web3 || !chainConfig
      ? undefined
      : returnWeb3
      ? eth
      : readOnly
      ? new Web3(readonlyProvider())
      : new Web3(
          (eth as any) ||
            (chainConfig.providerAddress.startsWith('ws')
              ? new Web3.providers.WebsocketProvider(
                  chainConfig.providerAddress
                )
              : new Web3.providers.HttpProvider(chainConfig.providerAddress))
        )
  }, [readOnly, Web3, returnWeb3, chainConfig])

  const web3 = useMemo(() => {
    if (!Web3) return
    const i = new Web3()
    return i
  }, [Web3])

  useEffect(() => {
    if (web3) web3.setProvider(window.ethereum as any)
  }, [web3])

  useEffect(() => {
    import('web3').then((m) =>
      setWeb3(() => {
        return m.default
      })
    )
  }, [])

  return (
    returnWeb3
      ? {
          provider: web3MetamaskProvider,
          web3,
        }
      : web3MetamaskProvider
  ) as any
}

export function useWeb3Providers():
  | {
      readWeb3: Web3
      writeWeb3: Web3
      loaded: true
    }
  | {
      readWeb3: undefined
      writeWeb3: undefined
      loaded: false
    } {
  const readWeb3 = useWeb3Provider(true)
  const writeWeb3 = useWeb3Provider(false)

  return {
    readWeb3,
    writeWeb3,
    loaded: !!readWeb3 && !!writeWeb3,
  } as any
}
