import Web3Factory from './Web3Factory'
import { estimateGasFeeFromEnv } from 'utility/gas'

export default class OnChainBase extends Web3Factory {
  private gasFeeEndpoint?: string

  setGasFeeEndpoint(endpoint: string | undefined) {
    this.gasFeeEndpoint = endpoint
  }

  protected async calculateGas() {
    if (process.env.AUTOMATION)
      return {
        gas: 90000000,
        gasPrice: '90000000000',
      }

    return {
      maxPriorityFeePerGas: await estimateGasFeeFromEnv(this.gasFeeEndpoint),
    }
  }
}
