import React from 'react'
import { i18n } from 'translate/i18n'
import { Box, Typography } from '@material-ui/core'
import useStyles from '../PendingPayment.style'
import { Coinbase } from './Coinbase'

interface CryptoProps {
  purchaseCode: string
  uuid: string
  onModalClose: () => void
}

const Crypto: React.FC<CryptoProps> = ({ purchaseCode, uuid, onModalClose }: CryptoProps) => {
  const classes = useStyles()

  return (
    <div className={classes.formContainer}>
      <Box m={2} p={3} paddingBottom={3} className={classes.spacing}>
        <Typography variant='h4' component='h1' color='textSecondary'>
          {i18n.t('authenticated.payment.data.creditCardSubtitle')}
        </Typography>
      </Box>
      <Box marginTop={2} alignSelf='center'>
        <Coinbase purchaseCode={purchaseCode} uuid={uuid} onModalClose={onModalClose}/>
      </Box>
    </div>
  )
}

export default Crypto
