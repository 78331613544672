import { ERC1155ABI } from 'core/constants'
import ERC1155AbstractFacade from './ERC1155AbstractFacade'

export default class ERC1155RarumNFTFacade extends ERC1155AbstractFacade<Contract.ERC1155> {
  async getNormalizedBalance(forWallet: string) {
    const r = await this.nftContractForRead.methods.balancesOf(forWallet).call()
    return r
  }
  getContractInstance() {
    return new this.web3ForRead.eth.Contract(
      ERC1155ABI,
      this.contractAddress
    ) as unknown as Contract.ERC1155
  }
}
