import { useState } from 'react'
/**
 * Similar to https://github.com/streamich/react-use/blob/master/src/useEffectOnce.ts
 * but avoids the exhaustive-deps linter rule
 * @param callback Callback function to be called only once
 * @param trigger Enables the function to be called. If omitted, the function will be invoked immediately
 */
export const useOnce = (callback: () => void, trigger: boolean = true) => {
  const [fired, setFired] = useState(false)
  if (trigger && !fired) {
    callback()
    setFired(true)
  }
}
