import { builder } from 'core/helpers'
import * as PurchaseTypes from './purchase.types'

// == TYPES ==
const PURCHASE_EMITTER_SUBSCRIBE = 'purchase/emitter/SUBSCRIBE'
const PURCHASE_EMITTER_UNSUBSCRIBE = 'purchase/emitter/UNSUBSCRIBE'
const PURCHASE_FETCH_SUCCESS = 'purchase/fetch/SUCCESS'
const PURCHASE_FETCH_FAILED = 'purchase/fetch/FAILED'
const PURCHASES_EMITTER_SUBSCRIBE = 'purchases/emitter/SUBSCRIBE'
const PURCHASES_EMITTER_UNSUBSCRIBE = 'purchases/emitter/UNSUBSCRIBE'
const PURCHASES_FETCH_SUCCESS = 'purchases/fetch/SUCCESS'
const PURCHASES_FETCH_FAILED = 'purchases/fetch/FAILED'

export const types = Object.freeze({
  PURCHASE_EMITTER_SUBSCRIBE: PURCHASE_EMITTER_SUBSCRIBE,
  PURCHASE_EMITTER_UNSUBSCRIBE: PURCHASE_EMITTER_UNSUBSCRIBE,
  PURCHASE_FETCH_SUCCESS: PURCHASE_FETCH_SUCCESS,
  PURCHASE_FETCH_FAILED: PURCHASE_FETCH_FAILED,
  PURCHASES_EMITTER_SUBSCRIBE: PURCHASES_EMITTER_SUBSCRIBE,
  PURCHASES_EMITTER_UNSUBSCRIBE: PURCHASES_EMITTER_UNSUBSCRIBE,
  PURCHASES_FETCH_SUCCESS: PURCHASES_FETCH_SUCCESS,
  PURCHASES_FETCH_FAILED: PURCHASES_FETCH_FAILED,
})

// == ACTIONS ==
// Single purchase
const emitterPurchaseSubscribe = (purchaseId: string) =>
  builder.actionCreator<PurchaseTypes.PurchaseActionsTypes, string>(PURCHASE_EMITTER_SUBSCRIBE, purchaseId)

const emitterPurchaseUnsubscribe = () => builder.actionCreator<PurchaseTypes.PurchaseActionsTypes>(PURCHASE_EMITTER_UNSUBSCRIBE)

const fetchPurchaseSuccess = (purchase: PurchaseTypes.PurchaseType) =>
  builder.actionCreator<PurchaseTypes.PurchaseActionsTypes, PurchaseTypes.PurchaseType>(PURCHASE_FETCH_SUCCESS, purchase)

const fetchPurchaseFailed = (errorKey: string) =>
  builder.actionCreator<PurchaseTypes.PurchaseActionsTypes, string>(PURCHASE_FETCH_FAILED, errorKey)

// All user purchases
const emitterPurchasesSubscribe = () => builder.actionCreator<PurchaseTypes.PurchaseActionsTypes, string>(PURCHASES_EMITTER_SUBSCRIBE)

const emitterPurchasesUnsubscribe = () => builder.actionCreator<PurchaseTypes.PurchaseActionsTypes>(PURCHASES_EMITTER_UNSUBSCRIBE)

const fetchPurchasesSuccess = (purchases: PurchaseTypes.PurchaseType[]) =>
  builder.actionCreator<PurchaseTypes.PurchaseActionsTypes, PurchaseTypes.PurchaseType[]>(PURCHASES_FETCH_SUCCESS, purchases)

const fetchPurchasesFailed = (errorKey: string) =>
  builder.actionCreator<PurchaseTypes.PurchaseActionsTypes, string>(PURCHASES_FETCH_FAILED, errorKey)

export const actions = Object.freeze({
  emitterPurchaseSubscribe,
  emitterPurchaseUnsubscribe,
  fetchPurchaseSuccess,
  fetchPurchaseFailed,
  emitterPurchasesSubscribe,
  emitterPurchasesUnsubscribe,
  fetchPurchasesSuccess,
  fetchPurchasesFailed,
})

export const mapActions = Object.freeze({
  [PURCHASE_EMITTER_SUBSCRIBE]: emitterPurchaseSubscribe,
  [PURCHASE_EMITTER_UNSUBSCRIBE]: emitterPurchaseUnsubscribe,
  [PURCHASE_FETCH_SUCCESS]: fetchPurchaseSuccess,
  [PURCHASE_FETCH_FAILED]: fetchPurchaseFailed,
  [PURCHASES_EMITTER_SUBSCRIBE]: emitterPurchaseSubscribe,
  [PURCHASES_EMITTER_UNSUBSCRIBE]: emitterPurchaseUnsubscribe,
  [PURCHASES_FETCH_SUCCESS]: fetchPurchasesSuccess,
  [PURCHASES_FETCH_FAILED]: fetchPurchasesFailed,
})

// == REDUCER ==
export const initialState: Readonly<PurchaseTypes.PurchaseState> = Object.freeze({
  purchase: null,
  purchases: null,
  errorKey: '',
})

export const reducer = (
  state: Readonly<PurchaseTypes.PurchaseState> = initialState,
  action: Readonly<PurchaseTypes.PurchaseAction>
): PurchaseTypes.PurchaseState => {
  const { type, payload } = action
  switch (type) {
    case PURCHASE_EMITTER_UNSUBSCRIBE:
      return { ...state, purchase: null }
    case PURCHASE_FETCH_SUCCESS:
      return { ...state, purchase: payload as PurchaseTypes.PurchaseType }
    case PURCHASE_FETCH_FAILED:
      return { ...state, errorKey: payload as string }
    case PURCHASES_EMITTER_UNSUBSCRIBE:
      return { ...state, purchases: null }
    case PURCHASES_FETCH_SUCCESS:
      return { ...state, purchases: payload as PurchaseTypes.PendingPurchase[] }
    case PURCHASES_FETCH_FAILED:
      return { ...state, errorKey: payload as string }
    default:
      return state
  }
}
