import CustomBrowserRouter from '@onepercentio/one-ui/dist/context/CustomBrowserRouter'
import ROUTES, { TO_GALLERY } from 'core/modules/router'
import { matchPath } from 'react-router-dom'
import { PropsWithChildren } from 'react'

export default function CustomRouter({ children }: PropsWithChildren<{}>) {
  return (
    <CustomBrowserRouter
      beforeRouteChange={(r, history) => {
        const { galleryId } =
          matchPath<{ galleryId: string }>(history.location.pathname, {
            path: ROUTES.unauthenticated.galleryDetail,
          })?.params || {}

        if (!galleryId) return r

        const galleryBoundRoute = [
          ROUTES.unauthenticated.marketplace,
          ROUTES.authenticated.sellMarketplace,
          ROUTES.authenticated.marketplaceOfferBuy,
          ROUTES.unauthenticated.marketplaceAssetDetail,
          ROUTES.authenticated.offerItemDetail,
          ROUTES.authenticated.collectionItemDetail,
          ROUTES.unauthenticated.assetDetail
        ].find(
          (route) =>
            matchPath<{ galleryId: string }>(r, {
              path: route,
            })?.isExact
        )

        if (galleryBoundRoute) return TO_GALLERY(galleryId)(r)
        return r
      }}>
      {children}
    </CustomBrowserRouter>
  )
}
