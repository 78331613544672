import { useTheme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import Logo from 'components/Logo'
import React, { memo } from 'react'

export interface BlockLoadingViewProps {
  /**
   * Is show it?
   */
  isOpen: boolean
}

const BlockLoadingView: React.FC<BlockLoadingViewProps> = ({ isOpen }) => {
  const {
    palette: { type },
  } = useTheme()
  const logoColor = type === 'light' ? '#000' : '#fff'

  return (
    <Dialog fullScreen open={isOpen}>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        mt='auto'
        mb='auto'>
        <Box>
          <CircularProgress />
        </Box>
        <Box mt={1}>
          <Logo logoSize={16} color={logoColor} />
        </Box>
      </Box>
    </Dialog>
  )
}

export default memo(BlockLoadingView)
