import { PropsWithChildren } from 'react'
import useStyles from './OneUIProvider.style'
import OneProvider from '@onepercentio/one-ui/dist/context/OneUIProvider'
import Loading from 'components/Loading'
import { t } from 'translate/i18n'
import MutableHamburgerButton from '@onepercentio/one-ui/dist/components/MutableHamburgerButton'
import { ReactComponent as PaginationControl } from 'openspace/assets/svg/icons/pagination_control.svg'
import SCSSStyles from './OneUIProvider.module.scss'
import { FigmaTypo, Typo } from './OneUIProvider.data'
import Compact from '@onepercentio/one-ui/dist/components/FileInput/View/Compact'
export { FigmaTypo, Typo }

export default function OneUIProvider(props: PropsWithChildren<{}>) {
  const classes = useStyles()
  const TextStylex = {
    [FigmaTypo.H2]: classes.boldTitleBig,
    [FigmaTypo.H4]: classes.subtitle,
    [FigmaTypo.H3]: classes.title,
    [FigmaTypo.P14]: classes.caption,
    [FigmaTypo.P16]: classes.content,
    [FigmaTypo.H1]: classes.h1,
    [FigmaTypo.P10]: classes.p10,
  }
  return (
    <OneProvider
      config={{
        hook: {
          ui: {
            usePaginationControls: {
              LeftControl: () => (
                <PaginationControl
                  data-testid='page-l'
                  className={`${classes.control} ${classes.left}`}
                />
              ),
              RightControl: () => (
                <PaginationControl
                  data-testid='page-r'
                  className={classes.control}
                />
              ),
              className: classes.controlContainer,
            },
          },
        },
        component: {
          form: {
            requiredLabel: t('generic.fieldRequired'),
            extensions: {
              address: {
                Input: () => <></>,
                validator: (
                  [_, isAddressComplete, error] = [] as any,
                  question
                ) =>
                  question.optional
                    ? {
                        isValid: true,
                        error: undefined,
                      }
                    : {
                        isValid: isAddressComplete,
                        error: isAddressComplete
                          ? undefined
                          : (error as string),
                      },
              },
            },
          },
          spacing: {
            variants: {
              '8': SCSSStyles.spacing_8,
              '16': SCSSStyles.spacing_16,
              '32': SCSSStyles.spacing_32,
              '40': SCSSStyles.spacing_40,
            },
          },
          collapsable: {
            className: SCSSStyles.collapsable,
          },
          input: {
            border: false,
            className: SCSSStyles.input,
          },
          fileInput: {
            View: (props) => (
              <Compact className={SCSSStyles.fileInput} {...props} />
            ),
          },
          adaptiveDialog: {
            dialogClassName: SCSSStyles.adaptiveDialogRoot,
            backdropClassName: `${SCSSStyles.zIndexIncrease} ${SCSSStyles.adaptiveDialogBackdrop}`,
            variant: {
              custom: SCSSStyles.custom,
            },
          },
          adaptiveSidebar: {
            className: SCSSStyles.zIndexIncrease,
            controlClassName: SCSSStyles.zIndexIncrease2,
          },
          select: {
            StateIndicator: ({ open }) => (
              <MutableHamburgerButton
                state={!open ? 'arrow-down' : 'arrow-up'}
                size={24}
              />
            ),
            className: {
              dropdown: classes.selectDropdown,
              item: classes.selectItem,
              selectedItem: classes.selectedItem,
            },
          },
          button: {
            className: {
              transparent: `${classes.btnBase} ${TextStylex[FigmaTypo.P14]} ${
                classes.btnTransparent
              }`,
              filled: `${classes.btnBase} ${TextStylex[FigmaTypo.P14]} ${
                classes.btnFilled
              }`,
              outline: `${classes.btnBase} ${TextStylex[FigmaTypo.P14]} ${
                classes.btnOutline
              }`,
              'light-outline': `${classes.btnBase} ${
                TextStylex[FigmaTypo.P14]
              } ${classes.btnLightOutline}`,
              'promo-promotion': `${classes.btnBase} ${classes.btnPromo}`,
              highlight: `${classes.btnBase} ${classes.btnHighlight}`,
              'transparent-link': `${classes.btnBase} ${
                TextStylex[FigmaTypo.P14]
              } ${classes.btnTransparent} ${classes.btnTransparentLink}`,
            },
          },
          text: {
            className: Object.entries({
              ...SCSSStyles,
              ...TextStylex,
              ...classes,
              ...{
                h4: classes[FigmaTypo.H4],
                h2: classes[FigmaTypo.H2],
                p16: classes[FigmaTypo.P16],
                h3: classes[FigmaTypo.H3],
              },
            }).reduce((r, [key, v]) => {
              return {
                ...r,
                [key]: `${SCSSStyles.textBase} ${v}`,
              }
            }, {}),
            htmlTag: {
              boldTitle: 'h1',
              boldTitleBig: 'h1',
              p30_v2: 'h1',
              p40_v2: 'h1',
              title: 'h1',
              title_v2: 'h1',
              subtitle: 'h2',
              p20_v2: 'h2',
              p24_v2: 'h2',
              'promo-title': 'h3',
              h3heading: 'h3',
            },
          },
          tooltip: { className: classes.tooltip },
          asyncWrapper: {
            LoadingComponent: Loading as any,
            messages: {
              error: {
                retryBtn: t('generic.retryBtn'),
                title: t(
                  'components.offerAuctionPhoneConfirmation.errors.genericError'
                ),
              },
            },
          },
        },
      }}>
      {props.children as any}
    </OneProvider>
  )
}
