import { makeStyles } from '@material-ui/core'

export default makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center"
  },
  img: {
    width: 18,
    height: 18,
    marginRight: '0.75rem',
  },
}))
