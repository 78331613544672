import React, { ReactElement, useLayoutEffect, useRef } from 'react'
import Styles from './LabeledHR.module.scss'
import Text from '@onepercentio/one-ui/dist/components/Text'
import { FigmaTypo } from 'containers/OneUIProvider/OneUIProvider'
import { ReactComponent as Line } from 'openspace/assets/svg/labeled-hr-line.svg'
import useIntersection from '@onepercentio/one-ui/dist/hooks/useIntersection'
/**
 * An horizontal line that grows when entering the page and keeps a label by the right
 **/
export default function LabeledHR({
  label,
  children,
  disableAnimation,
  className,
}: {
  label: string | ReactElement
  children?: ReactElement
  disableAnimation?: boolean
  className?: string
}) {
  const ref = useRef<HTMLDivElement>(null)
  const { observe, unobserve } = useIntersection()
  useLayoutEffect(() => {
    if (disableAnimation) return
    const el = ref.current!

    observe(el, () => {
      el.classList.add(Styles.animate)
      unobserve(el)
    })

    return () => unobserve(el)
  }, [])
  return (
    <>
      <div
        ref={ref}
        className={`${Styles.root} ${
          disableAnimation ? Styles.noAnimation : ''
        } ${className}`}>
        <div className={Styles.content}>
          {<Text type={FigmaTypo.H4}>{<Line className={Styles.line} />}</Text>}
          {
            <Text type={FigmaTypo.H4} className={Styles.hiddenMd}>
              {label}
            </Text>
          }
          {children}
        </div>
        <div className={Styles.label}>
          {<Text type={FigmaTypo.H4}>{label}</Text>}
        </div>
      </div>
    </>
  )
}
