import useAsyncControl from "@onepercentio/one-ui/dist/hooks/useAsyncControl";
import { doTransferTokensConfirm, doTransferTokensSettle } from "core/modules/firebase/service";
import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { i18n } from "translate/i18n";

/**
 * This function is used for confirming the start of the operation
 */
export function useConfirmation() {
    const confirmationControl = useAsyncControl();

    return {
        ...confirmationControl as Omit<typeof confirmationControl, "process">,
        confirmOperation: useCallback(() => {
            doTransferTokensConfirm(i18n.language)
        }, [])
    }
}

/**
 * This function is used for settling the assets confirmation
 */
export function useSettle() {
    const settleControl = useAsyncControl();
    const loc = useLocation()
    const params = useMemo(() => new URLSearchParams(loc.search), [loc.search])
    const { w, s } = { w: params.get('w')!, s: params.get('s')! }

    return {
        ...settleControl as Omit<typeof settleControl, "process">,
        settleTransfer: useCallback(() => {
            doTransferTokensSettle(i18n.language, w, s);
        }, [w, s])
    }

}