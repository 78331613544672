import Box from '@material-ui/core/Box'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import clsx from 'clsx'
import React, { ReactElement } from 'react'
import useStyles from './DialogTitleWithCloseIcon.style'

export interface DialogTitleWithCloseIconViewProps {
  /**
   * Spacing padding bottom
   */
  paddingBottom?: number
  /**
   * What should be invoked on close
   */
  onClose: (e: React.SyntheticEvent) => void
  /**
   * Whether to disable or not the close button
   */
  disabled?: boolean
  /**
   * Dialog title
   */
  title: string | ReactElement
  /**
   * Whether to disable all padding
   */
  disablePadding?: boolean
}

const DialogTitleWithCloseIconView: React.FC<DialogTitleWithCloseIconViewProps> = ({
  paddingBottom,
  onClose,
  disabled,
  title,
  disablePadding,
}) => {
  const theme = useTheme()
  const classes = useStyles()
  return (
    <DialogTitle
      className={(clsx({ [classes.noPaddingHorizontal]: disablePadding }), classes.fullWidth)}
      style={{
        paddingBottom: paddingBottom ? (paddingBottom && disablePadding ? 0 : paddingBottom) : theme.spacing(3),
        paddingTop: disablePadding ? 0 : theme.spacing(2),
      }}
      disableTypography>
      <Box display='flex' justifyContent='space-between'>
        <Typography variant='h5'>{title}</Typography>
        <IconButton onClick={onClose} className={classes.topRight} disabled={disabled} aria-label='Close'>
          <CloseIcon />
        </IconButton>
      </Box>
    </DialogTitle>
  )
}

export default DialogTitleWithCloseIconView
