import { Theme } from '@material-ui/core'
import createStyles from '@material-ui/styles/createStyles'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) => {
  return createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '300px',
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
    image: {
      flex: '0 0 auto',
      maxHeight: 120,
    },
    text: {
      flex: 1,
      marginTop: theme.spacing(4),
      textAlign: 'left',
      [theme.breakpoints.up('md')]: {
        marginTop: 0,
        marginLeft: theme.spacing(4),
      },
    },
  })
}

export default makeStylesWrapper(style)
