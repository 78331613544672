import React, {
  ComponentProps,
  Fragment,
  ReactElement,
  useMemo,
  useState,
} from 'react'
import { ChallengeClaimViewProps, ChallengeState } from './ChallengeClaim.types'
import Styles from './ChallengeClaim.module.scss'
import { i18n, t } from 'translate/i18n'
import UncontrolledTransition from '@onepercentio/one-ui/dist/components/UncontrolledTransition'
import SectionLoader from 'openspace/components/SectionLoader'
import Text from 'openspace/components/Text'
import IconRarum from 'components/IconRarum'
import Button from '@onepercentio/one-ui/dist/components/Button'
import Collapsable from '@onepercentio/one-ui/dist/components/Collapsable'
import { useAsset } from 'context/Asset'
import { Challenge } from 'core/logic/challenges/challenges.types'
import Container from 'openspace/components/Container'
import Spacing from '@onepercentio/one-ui/dist/components/Spacing'
import {
  useChallengeAssets,
  useChallengeBalances,
  useChallengeClaim,
} from 'context/Challenge'
import { TransitionAnimationTypes } from '@onepercentio/one-ui/dist/components/Transition/Transition'
import Tag from 'components/Tag'
import AssetDisplay from 'openspace/components/AssetDisplay'
import { useBalanceForAssets } from 'context/MyCollection'
import Footer from 'components/Footer'
import AdaptiveContainer from '@onepercentio/one-ui/dist/components/AdaptiveContainer'
import BackButtonView from 'components/BackButton/BackButton.view'
import ClaimWarning from './ClaimWarning'
import AdaptiveDialog from '@onepercentio/one-ui/dist/components/AdaptiveDialog'
import { ChallengClaimTestIds } from './ChallengeClaim.e2e'

/**
 * Shows the details and progress to the challenge claim
 **/
function ChallengeClaimDetails(
  props: ChallengeClaimViewProps & ClickActions<'onClaim'>
) {
  const { challenge } = props
  return (
    <>
      <section className={`${Styles.section} ${Styles.state}`}>
        <ChallengeClaimState {...props} onClaim={props.onClaim} />
      </section>
      <section className={Styles.section}>
        <Container>
          <Text type='h2' code='features.challenge.claim.more_details' />
          <Spacing size='16' />
          <Collapsable
            className='bulky'
            title={
              <Fragment>
                <Text type='h3'>
                  {t('features.challenge.claim.requirements')}
                </Text>
                <IconRarum size={16} icon='chevronDown' />
              </Fragment>
            }>
            <span
              dangerouslySetInnerHTML={{ __html: challenge.requirementsText }}
            />
          </Collapsable>
          <Spacing size='16' />
          <Collapsable
            className='bulky'
            title={
              <Fragment>
                <Text type='h3'>
                  {t('features.challenge.claim.reward_rules')}
                </Text>
                <IconRarum size={16} icon='chevronDown' />
              </Fragment>
            }>
            <span dangerouslySetInnerHTML={{ __html: challenge.rules }} />
          </Collapsable>
        </Container>
      </section>
    </>
  )
}

export function ChallengeClaimViewContainer({
  onGoBack,
  banner,
  details,
}: ClickActions<'onGoBack'> & { banner: ReactElement; details: ReactElement }) {
  return (
    <>
      <main className={Styles.root}>
        <Spacing size='32' />
        <Container>
          <BackButtonView onClick={onGoBack} text={t('generic.goBack')} />
        </Container>
        <Spacing size='32' />
        {banner}
        <Spacing size='32' />
        <AdaptiveContainer
          direction='v'
          id={ChallengeClaimSectionIds.CLAIM_INFO}>
          {details}
        </AdaptiveContainer>
      </main>
      <Footer />
    </>
  )
}

export default function ChallengeClaimView({
  challenge,
  state,
  onClaimChallenge,
  actions,
}: {
  challenge: Challenge
  state: ChallengeState
} & ClickActions<'onClaimChallenge'> &
  Pick<ChallengeClaimViewProps, 'actions'>) {
  const [showClaimConfirmation, setShowClaimConfirmation] = useState(false)

  return (
    <>
      <ChallengeClaimDetails
        challenge={challenge}
        state={state}
        actions={actions}
        onClaim={() => setShowClaimConfirmation(true)}
      />
      <AdaptiveDialog
        open={showClaimConfirmation}
        onClose={() => setShowClaimConfirmation(false)}
        onClickOut={() => setShowClaimConfirmation(false)}
        className={Styles.modal}>
        <ClaimWarning
          challenge={challenge}
          onCancel={() => {
            setShowClaimConfirmation(false)
          }}
          onConfirm={() => {
            setShowClaimConfirmation(false)
            onClaimChallenge()
          }}
        />
      </AdaptiveDialog>
    </>
  )
}

function ClaimableView({
  state,
  challenge,
  onClaim,
  actions,
}: {
  state: Extract<ChallengeState, 'claimable' | 'claiming' | 'unavailable'>
  challenge: Challenge
  onClaim: () => void | undefined
} & Pick<ComponentProps<typeof ItemRequirementView>, 'actions'>) {
  const stateView = state === 'claiming' ? 'claimable' : state
  return (
    <>
      <ChallengeStateView state={stateView} />
      <Spacing size='16' />
      <Text type='h4' code='features.challenge.claim.follow_instructions' />
      <Spacing size='16' />
      <div className={Styles.requirementsList}>
        {challenge.requirements.map((req) => {
          return (
            <ItemRequirementView
              key={req.assetId}
              requirement={req}
              actions={actions}
              state={state}
            />
          )
        })}
      </div>
      <Spacing size='16' />
      <Button
        disabled={state === 'unavailable' || !onClaim}
        variant={state !== 'unavailable' ? 'highlight' : 'outline'}
        onClick={() => onClaim()}>
        {t(`features.challenge.claim.actions.status.${state}`)}
      </Button>
    </>
  )
}

function ChallengeClaimState({
  challenge,
  state,
  actions,
  onClaim,
}: ChallengeClaimViewProps & ClickActions<'onClaim'>) {
  const { retry: retryAssets, error: assetsError } =
    useChallengeAssets(challenge)
  const { retry: retryClaim, error: claimError } = useChallengeClaim(challenge)
  const { retry: retryBalances, error: balanceError } =
    useChallengeBalances(challenge)
  const retry = useMemo(() => {
    if (assetsError) return () => retryAssets()
    if (claimError) return () => retryClaim()
    if (balanceError) return () => retryBalances()
  }, [claimError, assetsError, balanceError])

  const content = useMemo(() => {
    switch (state) {
      case 'claiming':
        return (
          <Container
            key={state}
            className={Styles.challengeDetails}
            data-testid={ChallengClaimTestIds.CLAIMING_SECTION}>
            <ClaimableView
              state={state}
              actions={actions}
              challenge={challenge}
              onClaim={onClaim}
            />
            <div className={Styles.overlay}>
              <div>
                <SectionLoader />
                <Text
                  code='features.challenge.claim.claiming.title'
                  type='h3'
                />
                <Text
                  code='features.challenge.claim.claiming.description'
                  type='p16'
                />
              </div>
            </div>
          </Container>
        )
      case 'loading':
        return (
          <div key={'loading'} className={Styles.box}>
            <SectionLoader />
            <Text type='h3' code='features.challenge.claim.loading.title' />
            <Text
              type='p16'
              code='features.challenge.claim.loading.description'
            />
          </div>
        )
      case 'readonly':
        return (
          <Container
            key={state}
            className={Styles.challengeDetails}
            data-testid={ChallengClaimTestIds.READ_ONLY}>
            <Text
              type='h4'
              code='features.challenge.claim.follow_instructions'
            />
            <Spacing size='16' />
            <div className={Styles.requirementsList}>
              {challenge.requirements.map((req) => {
                return (
                  <ItemRequirementView
                    requirement={req}
                    actions={actions}
                    state={state}
                  />
                )
              })}
            </div>
            <Spacing size='16' />
          </Container>
        )
      case 'error':
        return (
          <div key={'error'} className={Styles.box}>
            <Text code='generic.errorMsg' type='h2' />
            <Button onClick={retry}>{t('generic.retryBtn')}</Button>
          </div>
        )
      case 'unavailable':
      case 'claimable':
        return (
          <Container
            key={state}
            className={Styles.challengeDetails}
            data-testid={ChallengClaimTestIds.PROGRESS_SECTION}>
            <ClaimableView
              state={state}
              actions={actions}
              challenge={challenge}
              onClaim={onClaim}
            />
          </Container>
        )
      default:
        return <Fragment key={'null'} />
    }
  }, [state, retry])
  const title = useMemo<Parameters<typeof t>[0]>(() => {
    if (state === 'claiming')
      return 'features.challenge.claim.confirmation.title_claiming'
    else return 'features.challenge.claim.title'
  }, [state])

  return (
    <>
      <Text type='h2'>{t(title)}</Text>
      <Spacing size='32' />
      <UncontrolledTransition transitionType={TransitionAnimationTypes.FADE}>
        {content}
      </UncontrolledTransition>
    </>
  )
}

function ItemRequirementView({
  requirement,
  actions: { onSeeAsset },
  state,
}: {
  requirement: Challenge['requirements'][number]
} & Pick<ChallengeClaimViewProps, 'actions' | 'state'>) {
  const View = useMemo(() => {
    if (state === 'readonly') {
      return function ViewAssetsOnly() {
        const asset = useAsset('id', requirement.assetId)!

        return (
          <div className={`${Styles.requirement} ${Styles.complete}`}>
            <div className='dark'>
              <AssetDisplay asset={asset} uniqueId={`challenge_${asset.id}`} />
              <div>
                <Text type='h4'>{asset[i18n.language].name}</Text>
                <Text type='p16'>
                  {t('authenticated.myCollection.collectionItem.unitsNew', {
                    count: requirement.amount,
                  })}
                </Text>
              </div>
            </div>
            <Button variant='transparent' onClick={() => onSeeAsset(asset)}>
              {t('features.challenge.claim.actions.seeItem')}
            </Button>
          </div>
        )
      }
    } else {
      return function BalanceStatus() {
        const asset = useAsset('id', requirement.assetId)!
        const assetBalance = useBalanceForAssets([asset]).assetsWithBalance[0]
          ?.balance

        const state = useMemo(() => {
          if (assetBalance >= requirement.amount) return 'complete'
          else return 'incomplete'
        }, [assetBalance])

        return (
          <div className={`${Styles.requirement} ${Styles[state]}`}>
            <div className='dark'>
              <AssetDisplay asset={asset} uniqueId={`challenge_${asset.id}`} />
              <div>
                <Text type='h4'>{asset[i18n.language].name}</Text>
                <Text
                  type='p16'
                  color={state === 'incomplete' ? 'error' : undefined}>
                  {assetBalance}/
                  {t('authenticated.myCollection.collectionItem.unitsNew', {
                    count: requirement.amount,
                  })}
                </Text>
                <Text type='p16'>
                  <IconRarum
                    icon={state === 'complete' ? 'checkmark' : 'security'}
                    size={16}
                  />
                  &nbsp;
                  {t(`features.challenge.claim.requirement.status.${state}`)}
                </Text>
              </div>
            </div>
            <Button variant='transparent' onClick={() => onSeeAsset(asset)}>
              {t('features.challenge.claim.actions.seeItem')}
            </Button>
          </div>
        )
      }
    }
  }, [state])

  return <View />
}

export function ChallengeStateView({
  state,
}: {
  state: 'claimable' | 'unavailable'
}) {
  return (
    <Tag color={state === 'claimable' ? 'primary' : 'default'} size='small'>
      <IconRarum
        icon={state === 'claimable' ? 'checkmark' : 'security'}
        size={10}
      />
      &nbsp;
      <Text type='p10'>{t(`features.challenge.claim.status.${state}`)}</Text>
    </Tag>
  )
}

export enum ChallengeClaimSectionIds {
  CLAIM_INFO = 'challenge-claim-info',
}
