import FAQBanner from './Banner/FAQ/FAQ.view'
import Header from './Banner/Header/Header.view'
import { t } from 'translate/i18n'

export default function MarketplaceView() {
  return (
    <>
      <Header />
      <FAQBanner
        onKnowMore={() => {
          window.open(t('marketplace.banner.faq.targetGuide'), '_blank')
        }}
      />
    </>
  )
}
