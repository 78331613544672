import React, { ComponentProps, useMemo } from 'react'
import { DropItemViewMode, DropItemViewProps } from './DropItem.types'
import Styles from './DropItem.module.scss'
import useHero from '@onepercentio/one-ui/dist/hooks/useHero'
import Text from '@onepercentio/one-ui/dist/components/Text'
import { i18n, tO } from 'translate/i18n'
import { FigmaTypo } from 'containers/OneUIProvider/OneUIProvider'
import OfferPrice, { offerPriceParameters } from 'components/offer/OfferPrice'
import UncontrolledTransition from '@onepercentio/one-ui/dist/components/UncontrolledTransition'
import { TransitionAnimationTypes } from '@onepercentio/one-ui/dist/components/Transition/Transition'
import Loader from '@onepercentio/one-ui/dist/components/Loader'
import { OfferType } from 'core/logic/drop/drop.types'
import {
  useIPODetails,
  useIPOInformation,
} from 'pages/Unauthenticated/Drops/Details/Models/IPO/IPO.hooks'
import BigNumber from 'bignumber.js'
import { useCounterForOffer } from 'context/Counters'
import { goalReachedLabel } from 'pages/Unauthenticated/Drops/Details/Models/IPO/IPO.view'
import Button from '@onepercentio/one-ui/dist/components/Button'
import { useAssetOwners } from 'context/Asset'
import { formatDateOnly, numberFormatter } from 'core/helpers/formatter'
import Countdown from '@onepercentio/one-ui/dist/components/Countdown'
import { CountdownTextModel } from '@onepercentio/one-ui/dist/components/Countdown/Countdown'
import Tag from 'components/Tag'
import LoaderDotsIndicator from '@onepercentio/one-ui/dist/components/LoaderDotsIndicator'
import Spacing from '@onepercentio/one-ui/dist/components/Spacing'
import { DROP_ITEM_TEST_IDS } from './DropItem.e2e'

const CONTAINER_MDOES = [Styles.expanded, Styles.short, Styles.image]

/**
 * Shows a drop item
 **/
export default function DropItemView(props: DropItemViewProps) {
  const intl = props.drop[i18n.language as LanguageCodes] || props.drop
  const image =
    'asset' in props.drop
      ? props.drop.media?.image || props.drop.image
      : props.drop.media.imageUrl
  const { currency, price, prices } = useMemo(() => {
    let currency: string | undefined
    let price: number | undefined
    let prices: { [c: string]: number } | undefined
    if ('unitPrice' in props.drop) {
      currency = props.drop.currency
      price = props.drop.unitPrice
      prices = props.drop.prices!
    } else if ('price' in props.drop) {
      currency = Object.keys(props.drop.price)[0]
      price = props.drop.price[currency]!.lowest!
      prices = {
        [currency]: price,
      }
    }
    return {
      currency,
      price,
      prices,
    }
  }, [])
  if ('type' in props.drop && props.drop.type === 'cvm88')
    return (
      <IPODropItem
        offer={props.drop}
        mode={props.mode}
        heroSuffix={props.heroSuffix}
        onClick={props.onClick}
      />
    )
  else
    return (
      <GenericDropItem
        {...props}
        image={image}
        currency={currency}
        prices={prices}
        price={price}
        intl={intl}
      />
    )
}

export function IPODropItem({
  offer,
  onClick,
  mode,
  heroSuffix,
}: { offer: OfferType } & Pick<
  ComponentProps<typeof DropItemView>,
  'onClick' | 'mode' | 'heroSuffix'
>) {
  const { heroRef } = useGalleryHero(offer, mode, heroSuffix)
  const reservedUnits = useCounterForOffer(offer.id)
  const { goal, goalUnits, unitPrice } = useIPOInformation(offer)

  const { currencyFormatter, intl, quotasProgress } = useIPODetails(offer, [
    offer.supply,
    goalUnits,
    reservedUnits,
  ])

  const goalInPrice = useMemo(() => {
    return quotasProgress.toReserved === undefined
      ? undefined
      : {
          current: new BigNumber(goal)
            .multipliedBy(
              new BigNumber(quotasProgress.toReserved!).dividedBy(100)
            )
            .toNumber(),
        }
  }, [quotasProgress, goal])

  const assetOwners = useAssetOwners().ownersByTokenId
  const ownersForThisAsset = assetOwners
    ? assetOwners[offer.asset.tokenId]?.length ?? 0
    : assetOwners

  const dataTable = useMemo(() => {
    const investorsSection = [
      tO('offerDetails.investors'),
      ownersForThisAsset === undefined ? (
        <LoaderDotsIndicator dotsCount={5} />
      ) : ownersForThisAsset === 0 ? (
        tO('offerDetails.beTheFirstToInvest')
      ) : (
        numberFormatter(ownersForThisAsset!)
      ),
    ] as const
    switch (offer.cvm88.type) {
      case 'equity':
        return [
          [
            tO('offerDetails.reachedPrice'),
            goalInPrice === undefined ? (
              <LoaderDotsIndicator dotsCount={5} />
            ) : (
              currencyFormatter.format(goalInPrice.current)
            ),
          ],
          [tO('offerDetails.goal'), currencyFormatter.format(goal)],
          [
            tO('offerDetails.minInvestmentShort'),
            currencyFormatter.format(unitPrice),
          ],
          investorsSection,
        ] as const
      case 'fixed':
      case 'receivable':
        const timeRemaining = offer.cvm88.expire.getTime() - Date.now()
        return [
          [
            tO('offerDetails.receivable.soldUnits'),
            goalInPrice === undefined ? (
              <LoaderDotsIndicator dotsCount={5} />
            ) : (
              currencyFormatter.format(goalInPrice.current)
            ),
          ],
          [
            tO('offerDetails.receivable.availability'),
            currencyFormatter.format(goal),
          ],
          investorsSection,
          [
            tO('offerDetails.receivable.expiration'),
            [
              formatDateOnly(offer.cvm88.expire),
              <Countdown
                timeRemaining={timeRemaining}
                model={CountdownTextModel.SHORT}
              />,
            ],
          ],
        ] as const
      default:
        throw new Error(
          `The cvm88 type "${(offer as any).cvm88.type}" is not configured yet`
        )
    }
  }, [offer, ownersForThisAsset])

  const { goalCls, goalActionLbl } = useMemo(() => {
    if (quotasProgress.toReserved! >= 100)
      return {
        goalCls: Styles.complete,
        goalActionLbl: 'offerDetails.actions.moreInfo',
      } as const
    return {
      goalCls: '',
      goalActionLbl: 'offerDetails.actions.invest',
    } as const
  }, [quotasProgress.toReserved])

  return (
    <div
      ref={heroRef}
      className={`${Styles.asset} ${Styles[mode]} ${Styles.cvm}`}>
      <div style={{ backgroundImage: `url(${offer.media.image})` }}>
        <div>
          <img src={offer.media.icon} alt={intl.title} />
          <div>
            <Text type='h3' className={'dark'}>
              {intl.title}
            </Text>
            <Spacing size='8' />
            <Tag className={`${Styles[offer.cvm88.type]} dark`} size='small'>
              <Text type='p12'>
                {tO(`offerDetails.cvm.types.${offer.cvm88.type}`)}
              </Text>
            </Tag>
          </div>
        </div>
        <div className={`${Styles.goalProgress} ${goalCls} dark`}>
          <Text type='p14'>
            <b>{goalReachedLabel(offer.cvm88.type, quotasProgress)}</b>
            {'estimatedReturn' in offer.cvm88 && (
              <span>
                {tO('offerDetails.cvm.performance', {
                  performance: `${numberFormatter(
                    offer.cvm88.estimatedReturn
                  )}%`,
                })}
              </span>
            )}
          </Text>
        </div>
      </div>
      <div>
        {dataTable.map(([lbl, val]) => (
          <div className={Styles.row} key={lbl}>
            <Text type='p14'>{lbl}</Text>
            <div>
              {Array.isArray(val) ? (
                <>
                  {val.map((val) => (
                    <Text type='p14'>
                      <b>{val}</b>
                    </Text>
                  ))}
                </>
              ) : (
                <Text type='p14'>
                  <b>{val}</b>
                </Text>
              )}
            </div>
          </div>
        ))}
        <Button onClick={onClick} color='primary' variant='filled'>
          {tO(goalActionLbl)}
        </Button>
      </div>
    </div>
  )
}

export function GenericDropItem({
  image,
  intl,
  price,
  prices,
  currency,
  ...props
}: ComponentProps<typeof DropItemView> & {
  image: string | undefined
  intl: { name: string } | { title: string }
  prices:
    | {
        [c: string]: number
      }
    | undefined
  price: number | undefined
  currency: string | undefined
}) {
  const { heroRef } = useGalleryHero(props.drop, props.mode, props.heroSuffix)
  return (
    <div
      ref={heroRef}
      className={`${Styles.asset} ${Styles[props.mode]}`}
      style={{ backgroundImage: `url(${image})` }}
      data-testid={DROP_ITEM_TEST_IDS.CLICKABLE}
      onClick={props.onClick}>
      <div className={Styles.content}>
        <div>
          <Text type={FigmaTypo.H4}>
            {'name' in intl ? intl.name : intl.title}
          </Text>
          {prices && (
            <>
              <Text type={FigmaTypo.P16}>
                <OfferPrice
                  {...offerPriceParameters({
                    currency,
                    unitPrice: price,
                    prices,
                  })}
                  size='md'
                  acceptedCurrencies={props.tenant.currencies!.accepted}
                  preferredCurrency={currency as any}
                  mode='text'
                />
              </Text>
              {!props.counters ? null : (
                <UncontrolledTransition
                  transitionType={TransitionAnimationTypes.COIN_FLIP}>
                  {props.counters.remaining === undefined ? (
                    <Loader key='loading' />
                  ) : (
                    <Text key={props.counters.remaining} type={FigmaTypo.P14}>
                      {tO(
                        props.counters.remaining === 0
                          ? 'components.drop.soldout'
                          : 'components.drop.remaining',
                        {
                          count: props.counters.remaining,
                        }
                      )}
                    </Text>
                  )}
                </UncontrolledTransition>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

function useGalleryHero(
  offer: DropItemViewProps['drop'],
  mode: DropItemViewMode,
  suffix: string = ''
) {
  return useHero(`drop-${offer.id}-${suffix || ''}`, undefined, {
    onHeroDetect: (h) => {
      if (
        document.querySelectorAll('[data-orderableid="orderable-list-clone"]')
          .length
      )
        return false
      return true
    },
    onHeroStart: (clone) => {
      clone.style.margin = '0px'
      const toAdd = Styles[mode]
      const toRemove = CONTAINER_MDOES.filter((a) => a !== toAdd)
      for (let cls of toRemove) clone.classList.remove(cls)
      clone.classList.add(toAdd)
    },
  })
}

export const BaseExpandedWidth = Number(Styles.baseExpandedWidth)
export const BaseAssetWidth = Number(Styles.baseAssetWidth)
