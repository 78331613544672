import { PAGE_TRANSITION_DURATION } from 'core/constants'
import { analytics } from 'core/modules/firebase'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const usePageTracking = () => {
  const location = useLocation()

  useEffect(() => {
    const page_path = location.pathname + location.search
    analytics.setCurrentScreen(page_path)
    /* setTimeout as a workaround for Helmet page title delay. The time is long also due to the page transition effect. See: https://github.com/nfl/react-helmet/issues/189#issuecomment-336292754 */
    setTimeout(() => analytics.logEvent('page_view', { page_path }), PAGE_TRANSITION_DURATION + 100)
  }, [location])
}
