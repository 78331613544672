import { BoxProps } from '@material-ui/core/Box'
import { Identicon } from 'components/Identicon'
import { Platform } from 'core/logic/authentication/auth.types'
import { useTenant } from 'core/logic/tenant/tenant.hook'
import { useUser } from 'core/logic/user'
import { getPlatformfromProviderId } from 'core/modules/firebase/service'
import { MouseEventHandler } from 'react'
import { FirebaseReducer } from 'react-redux-firebase'
import ProfileAvatarView from './ProfileAvatar.view'

export interface ProfileAvatarLogicProps {
  onClick?: MouseEventHandler
  showUserName?: boolean
  size?: number
  hideUsername?: boolean
}

const byPlatform =
  (provider: Platform) =>
  ({ providerId }: { providerId: string }) => {
    const platform = getPlatformfromProviderId(providerId)
    return platform === provider
  }

const getProviderPhoto = (
  providerData?: FirebaseReducer.AuthState['providerData']
) => {
  if (providerData) {
    const fbProvider = providerData.find(byPlatform(Platform.facebook))
    if (fbProvider) return `${fbProvider.photoURL}?height=320`
    const gmailProvider = providerData.find(byPlatform(Platform.gmail))
    if (gmailProvider) return gmailProvider.photoURL?.replace('s96-c', 's320-c')
  }
}

const ProfileAvatarLogic: React.FC<ProfileAvatarLogicProps & BoxProps> = ({
  onClick,
  showUserName = true,
  size,
  hideUsername,
  ...boxProps
}) => {
  const { detail } = useUser()
  const { hasWallet } = useTenant()
  const userName = detail?.displayName || ''
  const photoURL =
    getProviderPhoto(detail?.providerData) || detail?.photoURL || undefined
  const placeholder = detail?.email?.charAt(0).toUpperCase() || undefined

  if (hasWallet) return <Identicon diameter={42} />

  return (
    <ProfileAvatarView
      {...(showUserName ? { userName } : {})}
      photoURL={photoURL}
      size={size}
      placeholder={placeholder}
      onClick={onClick}
      hideUsername={hideUsername}
      {...boxProps}
    />
  )
}

export default ProfileAvatarLogic
