export enum MarketplaceAssetFilter {
    ALL = "all",
    ONLY_ON_SALE = "sale"
}

export enum MarketplaceOrderFilter {
    PRICE_ASC = 'price_asc',
    PRICE_DES = 'price_des'
}

export interface KeyValueType {
    [key: string]: string
}
export type Filter = {
    rarity: string | null
    trait: KeyValueType | null
}

export type MarketplaceFilter = Filter & {
    currency?: string
    asset: MarketplaceAssetFilter,
    order: MarketplaceOrderFilter
}

export interface TraitType {
  trait_type: string
  value: string
}

export type OptionsShape = {
  label: string
  value: string
}