import React from 'react'
import Styles from './ContractModels.module.scss'
import Card from 'components/Card/Card'
import Text from 'openspace/components/Text/Text'
import { FigmaTypo } from 'containers/OneUIProvider/OneUIProvider.data'
import Button from '@onepercentio/one-ui/dist/components/Button'
import { t } from 'translate/i18n'
import Container from 'openspace/components/Container/Container'
import IconRarum from 'components/IconRarum/IconRarum.view'
import Spacing from '@onepercentio/one-ui/dist/components/Spacing/Spacing'

/**
 * Describes how to contract RARUM
 **/
export default function ContractModels({
  onContact,
}: {
  onContact: () => void
}) {
  return (
    <>
      <div className={Styles.root}>
        <Container>
          <Text type='p12' code='topbar.menus.how_it_works' />
        </Container>
        <Spacing size='32' />
        <Container>
          <div>
            <Text
              type={FigmaTypo.H1}
              code='home.sections.contractModel.title'
            />
            <Spacing size='32' />
            <Text
              type={FigmaTypo.H3}
              code='home.sections.contractModel.subtitle'
            />
            <Spacing size='32' />
            <Button variant='filled' onClick={onContact}>
              {t('home.sections.contractModel.actions.contact')}
            </Button>
          </div>
          <div className={Styles.models}>
            <Card className={Styles.model}>
              <div className='dark'>
                <IconRarum icon='edit' size={30} />
                <Text
                  code='home.sections.contractModel.models.platform.title'
                  type={FigmaTypo.H2}
                />
              </div>
              <Text
                code='home.sections.contractModel.models.platform.subtitle'
                type={FigmaTypo.P16}
              />
            </Card>
            <Card className={Styles.model}>
              <div className='dark'>
                <IconRarum icon='puzzle' size={30} />
                <Text
                  code='home.sections.contractModel.models.api.title'
                  type={FigmaTypo.H2}
                />
              </div>
              <Text
                code='home.sections.contractModel.models.api.subtitle'
                type={FigmaTypo.P16}
              />
            </Card>
          </div>
        </Container>
      </div>
      <Spacing size='32' />
    </>
  )
}
