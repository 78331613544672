import { makeStyles } from "@material-ui/core";

const useUseScrollStyle = makeStyles(t => ({
    '@keyframes highlightSection': {
        from: { backgroundColor: t.palette.primary.light },
        to: { backgroundColor: "#0000" },
    },
    highlight: {
        animationName: "$highlightSection",
        animationDuration: '2000ms',
        animationFillMode: "forwards",
        margin: '0px -72px',
        padding: '0px 72px'
    }
}))
export default useUseScrollStyle;