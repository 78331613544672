import { Chip as MUIChip } from '@material-ui/core'
import { DROP_STATUS } from 'core/helpers/drop'
import React, { ReactElement } from 'react'

export interface ChipProps {
  availability?: DROP_STATUS
  beginDate?: Date
  endDate?: Date
  color?: string
  chipLabel?: string | ReactElement
  chipClass?: string
  variant?: 'outlined' | 'filled'
}

const Chip: React.FC<ChipProps> = ({
  color,
  chipLabel,
  chipClass,
  variant,
}) => {
  const _color = color === 'primary' ? 'var(--primary-color)' : color
  return (
    <MUIChip
      label={chipLabel}
      variant={variant === 'filled' ? 'default' : 'outlined'}
      className={chipClass}
      color={color === 'primary' ? 'primary' : undefined}
      style={
        color === 'primary' ? undefined : { color: _color, borderColor: _color }
      }
    />
  )
}
export default Chip
