import { RarumUserProfile } from 'core/logic/user/user.types'
import { RootState } from 'core/modules/redux'
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FirebaseReducer, useFirebase } from 'react-redux-firebase'
import { actions as userActions } from 'core/logic/user/user.reducer'
import { GalleryType } from 'core/logic/gallery/gallery.types'
import { get, has, set } from 'lodash'
import { DeepPartial } from '@onepercentio/one-ui/dist/types'
import useLanguage from 'openspace/hooks/useLanguage'

export type UserContextShape = ClickActions<
  'remove' | 'logout' | 'cancelAccount'
> & {
  isEmpty: boolean
  detail: FirebaseReducer.AuthState | undefined
  profile: FirebaseReducer.Profile<RarumUserProfile> | undefined
  setAcceptDataUsage: (accept: boolean, gallery?: GalleryType) => Promise<void>
  updateProfile: (
    rarumUserProfile: DeepPartial<RarumUserProfile>
  ) => Promise<void>
  isLoggedIn: boolean
  wallets: string[]
}
export const UserContext = createContext<UserContextShape>(null as any)

export default function UserProvider({ children }: PropsWithChildren<{}>) {
  const { updateProfile } = useFirebase()
  const dispatch = useDispatch()
  const state = useSelector<
    RootState,
    {
      auth?: FirebaseReducer.AuthState
      profile?: FirebaseReducer.Profile<RarumUserProfile>
      isEmpty: boolean
    }
  >(({ firebase: { auth, profile } }) => {
    const { isLoaded, isEmpty } = auth
    if (isLoaded && !isEmpty) {
      return { auth, profile, isEmpty }
    }
    return { isEmpty }
  })
  const setAcceptDataUsage = useCallback<
    UserContextShape['setAcceptDataUsage']
  >(
    (accepted, gallery) => {
      const profile = state.profile
      const userTenantConfig: NonNullable<
        NonNullable<RarumUserProfile['personalData']>['tenants']
      >[string] =
        profile?.personalData?.tenants &&
        profile?.personalData?.tenants[process.env.REACT_APP_TENANT]
          ? profile.personalData.tenants[process.env.REACT_APP_TENANT]
          : { dataConsent: [], galleries: {} }

      if (!gallery) {
        userTenantConfig.dataConsent.push({
          value: accepted,
          created: new Date(), // Important to collect user's date and time
        })
      } else {
        const dataConsentGalleryPath = `galleries.${gallery.id}.dataConsent`
        if (!has(userTenantConfig, dataConsentGalleryPath))
          set(userTenantConfig, `galleries.${gallery.id}.dataConsent`, [])
        const galleryDataConsents = get(
          userTenantConfig,
          dataConsentGalleryPath
        ) as typeof userTenantConfig.dataConsent
        galleryDataConsents.push({
          created: new Date(),
          value: accepted,
        })
      }
      return updateProfile({
        personalData: {
          ...profile?.personalData,
          tenants: {
            ...profile?.personalData?.tenants,
            [process.env.REACT_APP_TENANT!]: userTenantConfig,
          },
        },
      })
    },
    [state.profile, updateProfile]
  )
  const isLoggedIn = useMemo(() => {
    return !!state.auth?.uid
  }, [state.profile])

  const { profile } = state
  const wallets = useMemo(
    () =>
      [profile?.internalWallet, profile?.wallet].filter(Boolean) as string[],
    [profile?.internalWallet, profile?.wallet]
  )

  const language = useLanguage()
  useEffect(() => {
    if (profile && profile.isLoaded)
      if (language !== profile.personalData?.prefferedLanguage) {
        updateProfile({
          personalData: {
            ...profile?.personalData,
            prefferedLanguage: language,
          },
        })
      }
  }, [language, profile])

  const cancelAccount = () => {}
  return (
    <UserContext.Provider
      value={{
        remove: () => dispatch(userActions.remove()),
        logout: () => dispatch(userActions.logout()),
        isEmpty: state.isEmpty,
        detail: state.auth,
        profile,
        setAcceptDataUsage,
        cancelAccount,
        isLoggedIn,
        wallets,
        updateProfile,
      }}>
      {children}
    </UserContext.Provider>
  )
}

export function useUser() {
  return useContext(UserContext)
}
