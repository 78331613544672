import AdaptiveSidebar from '@onepercentio/one-ui/dist/components/AdaptiveSidebar'
import { ComponentProps } from 'react'
import useStyles from './RarumAdaptiveSidebar.style'

export default function RarumAdaptiveSidebar(
  props: ComponentProps<typeof AdaptiveSidebar>
) {
  const classes = useStyles()
  return (
    <AdaptiveSidebar
      {...props}
      className={`${classes.root} ${props.className || ''}`}
    />
  )
}
