import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import { memo, useMemo } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { LinksIntl } from './Footer.data'
import useStyles from './Footer.style'
import FooterView from './Footer.view'
import { LinkItemProps } from './Footer.types'

const LinkItem: React.FC<LinkItemProps> = ({ link }) => {
  const classes = useStyles()
  return (
    <Typography
      key={`footer-link-${link.title}`}
      variant='body2'
      component='p'
      className={classes.linkItem}>
      {'url' in link ? (
        <Link component={RouterLink} to={link.url}>
          {link.title}
        </Link>
      ) : (
        <Link onClick={() => window.open(link.file, '_blank')}>
          {link.title}
        </Link>
      )}
    </Typography>
  )
}

const FooterLogic = (props: any) => {
  const links = useMemo(LinksIntl, [])
  const linksLeft = links.left.map((link, idx) => (
    <LinkItem key={`left-links-${idx}`} link={link} />
  ))

  const linksRight = links.right.map((link, idx) => (
    <LinkItem key={`right-links-${idx}`} link={link} />
  ))

  return <FooterView linksLeft={linksLeft} linksRight={linksRight} {...props} />
}

export default memo<any>(FooterLogic)
