import { Box, Divider } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import clsx from 'clsx'
import React from 'react'
import useStyles from './AssetItemShimmer.style'

interface AssetItemShimmerProps {
  last?: boolean
  'data-testid'?: string
}
const AssetItemShimmer: React.FC<AssetItemShimmerProps> = ({ last = false, ...props }) => {
  const classes = useStyles()

  return (
    <>
      <Box data-testid={props['data-testid']} display='flex' flexDirection='column' className={classes.container}>
        {/* Img row */}
        <Skeleton variant='rect' className={clsx(classes.block, classes.image)} />
        {/* Bottom row */}
        <Skeleton variant='text' width='60%' height={27} className={classes.block} />
        <Skeleton variant='text' width='90%' height={20} />
        <Skeleton variant='text' width='30%' height={32} className={classes.block} />
      </Box>
      {!last && <Divider className={classes.divider} />}
    </>
  )
}

export default AssetItemShimmer
