import { ptBR, enUS, es } from 'date-fns/locale'
import { format as dateFormat } from 'date-fns'
import { i18n } from 'translate/i18n'
import { useChipColor } from './Chip.hook'
import ChipView, { ChipProps } from './Chip.view'

const ChipLogic: React.FC<ChipProps> = ({
  availability,
  beginDate,
  endDate,
  color,
}) => {
  const i18nlanguage = i18n.language
  let lang = ptBR
  switch (i18nlanguage) {
    case 'pt-BR':
      lang = ptBR
      break
    case 'en-US':
      lang = enUS
      break
    case 'es-ES':
      lang = es
      break
    default:
      lang = enUS
      break
  }
  const availabilityColors = useChipColor()
  let chipLabel = `${i18n.t('components.chip.chipLabelSoon')}`
  let chipClass
  if (availability && beginDate) {
    chipClass = availabilityColors[availability]
    switch (availability) {
      case 'scheduled':
        chipLabel = dateFormat(
          beginDate,
          i18n.t('core.constants.scheduledDateFormat'),
          { locale: lang }
        )
        break
      case 'queue':
        chipLabel = `${i18n.t('components.chip.chipLabelSoon')}`
        break
      case 'open':
        chipLabel = `${i18n.t('components.chip.chipLabelSale')}`
        break
      case 'expired':
        chipLabel = endDate
          ? dateFormat(beginDate, i18n.t('core.constants.expiredDateFormat'), {
              locale: lang,
            })
          : `${i18n.t('components.chip.chipLabelSaleFinished')}`
        break
      case 'out':
        chipLabel = `${i18n.t('components.chip.chipLabelSaleFinished')}`
        break
    }
  }

  return (
    <ChipView
      availability={availability}
      beginDate={beginDate}
      endDate={endDate}
      color={color}
      chipClass={chipClass}
      chipLabel={chipLabel}
    />
  )
}

export default ChipLogic
