import { Theme } from '@material-ui/core'
import createStyles from '@material-ui/styles/createStyles'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) => {
  return createStyles({
    popper: {
      backgroundColor: theme.palette.background.default,
      zIndex: 1100,
    },
    menulist: {
      paddingBottom: theme.spacing(2),
    },
    shareHeader: {
      paddingTop: theme.spacing(2),
    },
  })
}
export default makeStylesWrapper(style)
