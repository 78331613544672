import { useContextControl } from '@onepercentio/one-ui/dist/context/ContextAsyncControl'
import useAsyncControl from '@onepercentio/one-ui/dist/hooks/useAsyncControl'
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { WalletContextShape, useWallet } from './Wallet'
import { PartialTransaction } from 'core/logic/asset/asset.types'
import { waitForOkenTransactionToEnd } from 'core/modules/firebase/service'
import { waitForConfirmation } from 'core/helpers/contract'

export type ProcessData = {
  type: string
  data: any
}

export type OkenOrMiningProcess<ID extends string> = {
  type: ID
  data:
    | {
        type: Extract<WalletContextShape['mode'], 'custody'>
        transactionId: PartialTransaction['id']
      }
    | {
        type: Extract<WalletContextShape['mode'], 'provider'>
        txHash: string
      }
}

export type ProcessPoolContextShape<P extends ProcessData = ProcessData> = {
  /**
   * This takes an existing process and binds to a promise
   * @param processRecoveryData The information to be saved for recovery
   * @returns A function to be called with the process promise
   */
  bindToProcess: (
    processToBindTo: P
  ) => <T>(processPromise: Promise<T>) => Promise<T>

  /**
   * This register a process and binds to the created promise
   * @param processRecoveryData The information to be saved for recovery
   * @returns A function to be called with the process promise
   */
  registerProcess: (
    processRecoveryData: P
  ) => <T>(processPromise: Promise<T>) => Promise<T>

  /**
   * The processes that are currently running
   */
  ongoingProcesses: P[] | undefined

  control: {
    processPoolLoading: boolean
    processPoolLoadError: any
    reloadProcessPool: () => Promise<void>
  }
}

export const ProcessPoolContext = createContext<ProcessPoolContextShape>(
  null as any
)

export function ProcessPoolProvider({ children }: PropsWithChildren<{}>) {
  const [ongoingProcesses, setOngoingProcesses] = useState<ProcessData[]>()

  const persistence = useMemo(
    () => ({
      load: () =>
        JSON.parse(
          localStorage.getItem('process_pool') || '[]'
        ) as ProcessData[],
      save: (processes: ProcessData[]) =>
        localStorage.setItem('process_pool', JSON.stringify(processes)),
    }),
    []
  )

  const loadProcessPool = useCallback(() => {
    setOngoingProcesses(persistence.load())
    return Promise.resolve()
  }, [])

  const control = useAsyncControl({ loadProcessPool })
  useEffect(() => {
    control.loadProcessPool()
  }, [])
  useEffect(() => {
    persistence.save(ongoingProcesses || [])
  }, [ongoingProcesses])

  const _bindToProcess = useCallback<ProcessPoolContextShape['bindToProcess']>(
    (processRecoveryDataInstance) => (promise) => {
      promise.finally(() => {
        setOngoingProcesses((prev) =>
          prev?.filter((p) => p !== processRecoveryDataInstance)
        )
      })
      return promise
    },
    []
  )
  const _registerProcess = useCallback<
    ProcessPoolContextShape['bindToProcess']
  >((processRecoveryData) => {
    return (promise) => {
      setOngoingProcesses((prev = []) => [...prev, processRecoveryData])

      promise.finally(() =>
        setOngoingProcesses((prev) =>
          prev!.filter((p) => p !== processRecoveryData)
        )
      )
      return promise
    }
  }, [])

  return (
    <ProcessPoolContext.Provider
      value={{
        control: {
          processPoolLoading: control.loading,
          processPoolLoadError: control.error,
          reloadProcessPool: loadProcessPool,
        },
        bindToProcess: _bindToProcess,
        registerProcess: _registerProcess,
        ongoingProcesses,
      }}>
      {children}
    </ProcessPoolContext.Provider>
  )
}

export function useProcessPool<P extends ProcessData>() {
  const { ...ctx } = useContext<ProcessPoolContextShape<P>>(
    ProcessPoolContext as any
  )

  return ctx
}

/**
 * Checks for the ongoing process when mounting the component and when the processes are loaded
 * @param processType The type of processes to search for
 * @param whatToDoWithOngoingProcess The callback to process the ongoing processes
 *
 * @throws [DEV only] Error if there are more than one resulting process with the same type
 */
export function useProcessOnMount<P extends ProcessData>(
  processType: P['type'],
  whatToDoWithOngoingProcess: (process: P) => void
) {
  const { ongoingProcesses } = useContext(ProcessPoolContext)
  const ongoingProcess = ongoingProcesses?.find(
    (f) => f.type === processType
  ) as P
  useEffect(() => {
    if (process.env.NODE_ENV === 'development')
      if (
        ongoingProcesses &&
        ongoingProcesses.filter((f) => f.type === processType).length > 1
      )
        throw new Error(
          `There is more than 1 process of type ${processType}. Please, verify this cenario`
        )
    if (ongoingProcess) whatToDoWithOngoingProcess(ongoingProcess)
  }, [ongoingProcesses !== undefined])
}

export function useProcessTrigger<P extends ProcessData>(
  processRecoveryType: P['type']
) {
  return function useProcessTriggerIn<
    S extends (...args: any[]) => Promise<any>
  >(start: S, recover: (processRecoveryData: P, start: S) => Promise<any>) {
    const { registerProcess, bindToProcess } = useProcessPool()
    const startProcess = (extraData?: P['data'], ...args: Parameters<S>) => {
      return registerProcess({
        type: processRecoveryType,
        data: extraData,
      })(start(...args))
    }

    const control = useContextControl(processRecoveryType, {
      trigger: startProcess,
    })

    useProcessOnMount<P>(processRecoveryType, (processRecoveryData) => {
      bindToProcess(processRecoveryData)(
        control.process(() => recover(processRecoveryData, start))
      )
    })

    return control
  }
}

export function useOkenOrProviderTransactionProcess<
  P extends OkenOrMiningProcess<any>
>(id: P['type'], onSuccess: () => Promise<void>) {
  const walletMode = useWallet().mode!
  const control = useProcessTrigger<P>(id)(
    async (type: P['data']['type'], okenTxIdOrMiningHash: string) => {
      if (type === 'custody')
        await waitForOkenTransactionToEnd(okenTxIdOrMiningHash)
      else await waitForConfirmation(okenTxIdOrMiningHash)
      await onSuccess()
    },
    (recoveryData, recoveryFunction) => {
      const id =
        recoveryData.data.type === 'custody'
          ? recoveryData.data.transactionId
          : recoveryData.data.txHash
      return recoveryFunction(recoveryData.data.type, id)
    }
  )

  return {
    ...control,
    trigger: (okenTxIdOrMiningHash: string) =>
      control.trigger(
        walletMode === 'custody'
          ? {
              type: 'custody',
              transactionId: okenTxIdOrMiningHash,
            }
          : {
              type: 'provider',
              txHash: okenTxIdOrMiningHash,
            },
        walletMode,
        okenTxIdOrMiningHash
      ),
  }
}
