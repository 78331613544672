import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) =>
  createStyles({
    selector: {
      '& *': {
        border: '0px solid transparent !important',
      },
    },
    head: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
    box: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
      },
    },
    loadingBox: {
      height: 200,
    },
    bidBoundery: {
      marginTop: theme.spacing(3),
      marginRight: theme.spacing(4),
      marginBottom: theme.spacing(4),
      '& .MuiTypography-root': {
        fontSize: '1em',
      },
    },
    figure: {
      width: 144,
      height: 130,
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      '& img': {
        visibility: 'hidden',
      },
    },
    field: {
      marginBottom: theme.spacing(5),
    },
  })

export default makeStylesWrapper(style)
