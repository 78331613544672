import { makeStyles } from "@material-ui/core";
import { BG } from "./Header.template";

export default makeStyles((theme) => ({
    root: {
        marginBottom: "44px",
        "& > *": {
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            minHeight: "240px",
            "&:before": {
                display: "block",
                position: "absolute",
                left: "0px",
                content: '" "',
                width: "100%",
                height: "100%",
                background: BG,
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 150%",
                transform: "rotateY(-180deg)",
                zIndex: -1
            },
            "& p": {
                maxWidth: "400px"
            },
            "& img": {
                height: "302px",
            },
            [theme.breakpoints.down("sm")]: {
                "& img": {
                    display: "none"
                }
            }
        },
    }
}));