import { i18n } from 'translate/i18n'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import ActionButton from 'components/ActionButton'
import BackButton from 'components/BackButton'
import InfoModal from 'components/InfoModal'
import React from 'react'
import useStyles from '../OpeningDrop.style'

interface ActionsProps {
  onLeave: () => void
  onStay: () => void
}

// Close modal actions area component
const Actions: React.FC<ActionsProps> = ({ onLeave, onStay }) => {
  const { actionsWrapper, actionButton, cancelButton } = useStyles()
  return (
    <Box className={actionsWrapper}>
      <ActionButton
        text={i18n.t('authenticated.openingDrop.modals.exitModal.confirm')}
        className={actionButton}
        onClick={onLeave}
      />
      <BackButton
        icon='close'
        text={i18n.t('authenticated.openingDrop.modals.exitModal.cancel')}
        className={cancelButton}
        onClick={onStay}
      />
    </Box>
  )
}

export interface ExitModalProps {
  open: boolean
  onStay: () => void
  onLeave: () => void
}

// Close modal content
const modalContent = (
  <Typography variant='body1'>
    {i18n.t('authenticated.openingDrop.modals.exitModal.confirmToExit')}
    <br />
    {i18n.t('authenticated.openingDrop.modals.exitModal.confirmToExitMessage')}
  </Typography>
)

const ExitModal: React.FC<ExitModalProps> = ({ open, onStay, onLeave }) => {
  return (
    <InfoModal
      title={i18n.t('authenticated.openingDrop.modals.exitModal.wantExit')}
      open={open}
      handleClose={onStay}
      content={modalContent}
      actions={<Actions onLeave={onLeave} onStay={onStay} />}
    />
  )
}

export default ExitModal
