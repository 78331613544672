import { actions as tenantActions } from 'core/logic/tenant/tenant.reducer'
import { call, put } from 'redux-saga/effects'
import * as tenantService from './tenant.service'
import { TenantType } from './tenant.types'

export function* startTenantFetch() {
  try {
    const result: TenantType = yield call(tenantService.fetchTenant)
    yield put(tenantActions.fetchTenantSuccess(result))
  } catch (e) {
    yield put(tenantActions.fetchTenantFailed('tenant.fetch'))
  }
}
