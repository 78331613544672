import { i18n } from 'translate/i18n'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import ActionButton from 'components/ActionButton'
import BackButton from 'components/BackButton'
import InfoModal from 'components/InfoModal'
import React from 'react'
import useStyles from '../OpeningDrop.style'

interface ActionsProps {
  onLeave: () => void
  onStay: () => void
}

// Error modal actions area component
const Actions: React.FC<ActionsProps> = ({ onLeave, onStay }) => {
  const { actionsWrapper, actionButton, cancelButton } = useStyles()
  return (
    <Box className={actionsWrapper}>
      <ActionButton
        text={i18n.t(
          'authenticated.openingDrop.modals.timeOutModal.myCollection'
        )}
        className={actionButton}
        onClick={onLeave}
      />
      <BackButton
        icon='close'
        text={i18n.t('authenticated.openingDrop.modals.timeOutModal.waitMore')}
        className={cancelButton}
        onClick={onStay}
      />
    </Box>
  )
}

export interface TimeoutModalProps {
  open: boolean
  onStay: () => void
  onLeave: () => void
}

// Close modal content
const modalContent = (
  <Typography variant='body1'>
    {i18n.t('authenticated.openingDrop.modals.timeOutModal.waitingMessage1')}
    <br />
    {i18n.t('authenticated.openingDrop.modals.timeOutModal.waitingMessage2')}
  </Typography>
)

const TimeoutModal: React.FC<TimeoutModalProps> = ({
  open,
  onStay,
  onLeave,
}) => {
  return (
    <InfoModal
      title={i18n.t(
        'authenticated.openingDrop.modals.timeOutModal.timeOutMessage'
      )}
      open={open}
      handleClose={onStay}
      content={modalContent}
      actions={<Actions onLeave={onLeave} onStay={onStay} />}
      disableBackdropClick
    />
  )
}

export default TimeoutModal
