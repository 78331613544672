import { service } from 'core/modules/firebase'
import { GalleryType } from './gallery.types'

export const fetchGalleries = ({ emitter }: { emitter: (input: GalleryType[]) => void }) => {
  return service.fetchGalleries({
    onSnapshot: (snap) => {
      const galleriesUpdated = snap.docs.map((doc) => {
        const data = doc.data() as GalleryType
        const id = doc.id
        return {
          ...(data as GalleryType),
          id,
          created: new Date(data.created),
        }
      })
      emitter(galleriesUpdated)
    },
  })
}

export const fetchGallery = ({ galleryId, emitter }: { galleryId: string; emitter: (input: GalleryType) => void }) => {
  return service.fetchGallery({
    galleryId,
    onSnapshot: (snap) => {
      const data = snap.data() as GalleryType
      const id = snap.id
      if (data) {
        const galleryUpdated = {
          ...(data as GalleryType),
          id,
          created: new Date(data.created),
        }
        emitter(galleryUpdated)
      }
    },
  })
}
