import { mapDropData } from 'core/helpers/drop'
import { service } from 'core/modules/firebase'
import { DeliveryType } from './delivery.types'

export const fetchDeliveries = ({ emitter }: { emitter: (input: DeliveryType[]) => void }) => {
  return service.fetchDeliveries({
    onSnapshot: (snap) => {
      const deliveriesUpdated = snap.docs.map((c) => {
        const data = c.data()
        const id = c.id
        return {
          ...(data as DeliveryType),
          offer: data.offer ? mapDropData(data.offer) : undefined as any,
          id,
          created: new Date(data.created),
          expireAt: new Date(data.expireAt),
        }
      })
      emitter(deliveriesUpdated)
    },
  })
}

export const fetchDelivery = ({ deliveryId, emitter }: { deliveryId: string; emitter: (input: DeliveryType) => void }) => {
  return service.fetchDelivery({
    deliveryId,
    onSnapshot: (snap) => {
      const data = snap.data()
      const id = snap.id
      if (data) {
        const deliveryUpdated = {
          ...(data as DeliveryType),
          offer: data.offer ? mapDropData(data.offer) : undefined as any,
          id,
          created: new Date(data.created),
          expireAt: new Date(data.expireAt),
        }
        emitter(deliveryUpdated)
      }
    },
  })
}
