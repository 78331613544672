import Avatar from '@material-ui/core/Avatar'
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton'
import Skeleton from '@material-ui/lab/Skeleton'
import clsx from 'clsx'
import React, { memo, MouseEventHandler } from 'react'
import useStyles from './MorphAvatar.style'

export interface MorphAvatarViewProps {
  /**
   * How large should the button be?
   */
  avatarSize?: number
  /**
   * Image source
   */
  src?: string
  /**
   * Whether is static or actionable
   */
  isStatic?: boolean
  /**
   * Letter placeholder
   */
  placeholder?: string
  /**
   * Click handler
   */
  onClick?: MouseEventHandler
  /**
   * Sets a dark border
   */
  dark?: boolean
}

const MorphAvatarView: React.FC<MorphAvatarViewProps & IconButtonProps> = ({
  isStatic,
  avatarSize = 36,
  placeholder,
  src,
  onClick,
  dark,
  className,
  ...props
}) => {
  const { root, avatar, avatarDark } = useStyles()

  if (!src) {
    return <Skeleton variant='circle' width={avatarSize} height={avatarSize} />
  }

  return (
    <IconButton className={clsx(root, className)} edge='end' color='inherit' size='small' disabled={isStatic} onClick={onClick} {...props}>
      <Avatar className={dark ? avatarDark : avatar} src={src} style={{ height: avatarSize, width: avatarSize }}>
        {placeholder}
      </Avatar>
    </IconButton>
  )
}

export default memo(MorphAvatarView)
