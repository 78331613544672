import { i18n } from 'translate/i18n'
import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import React, { useState } from 'react'
import { mapPaymentMethodToName } from '../Payment.data'
import useStyles from '../Payment.style'
import { IPaymentInputs } from '../Payment.types'

export interface PaymentViewProps {
  data: IPaymentInputs
  onNext: (data: IPaymentInputs) => void
  onEdit?: (step: number) => void
}

const Step3View: React.FC<PaymentViewProps> = ({
  data: {
    userName,
    email,
    cpf,
    logradouro,
    numero,
    cidade,
    estado,
    cep,
    country,
    metodo,
    foreigner,
    nonResident,
  },
  onNext,
  onEdit,
}) => {
  const classes = useStyles()

  const [shouldPersist, setShouldPersist] = useState(true)
  const switchPersist = () => setShouldPersist(!shouldPersist)

  return (
    <form
      id='payment-form'
      onSubmit={(e) => {
        e.preventDefault()
        onNext({ persist: shouldPersist })
      }}>
      <Box mb={4}>
        <Typography variant='h3' component='p'>
          <strong>
            {i18n.t('authenticated.payment.steps.stepReview.dataReview')}
          </strong>
        </Typography>
        <Typography
          variant='body1'
          component='p'
          className={classes.smallSpacing}>
          {i18n.t('authenticated.payment.steps.stepReview.confirmMessage1')}
          <br />
          {i18n.t('authenticated.payment.steps.stepReview.confirmMessage2')}
        </Typography>
      </Box>
      <Box display='flex' flexDirection='column' alignItems='flex-start'>
        <Typography variant='body2'>
          {i18n.t('authenticated.payment.steps.stepReview.holderData')}
        </Typography>
        <Typography variant='body2'>
          <b>{userName}</b>
        </Typography>
        <Typography variant='body2'>
          <b>{email}</b>
        </Typography>
          <Typography variant='body2'>
          <b>
            { foreigner ? i18n.t('authenticated.payment.steps.stepReview.foreignerId') : i18n.t('authenticated.payment.steps.stepReview.cpf')} {cpf}
          </b>
        </Typography>
        {onEdit && (
          <Link
            component='button'
            variant='body2'
            type='button'
            onClick={() => onEdit(1)}
            className={classes.editButton}>
            <strong>
              {i18n.t('authenticated.payment.steps.stepReview.edit')}
            </strong>
          </Link>
        )}
        {metodo === 'CREDIT_CARD' && logradouro ? (
          <>
            <Typography variant='body2' className={classes.topSpacing}>
              {i18n.t('authenticated.payment.steps.stepReview.addressData')}
            </Typography>
            <Typography variant='body2'>
              <b>
                {logradouro} {numero}
              </b>
            </Typography>
            <Typography variant='body2'>
              <b>
                {cidade} - {estado} -{' '}
                {i18n.t('authenticated.payment.steps.stepReview.cep')} {cep}
              </b>
            </Typography>
            { nonResident && (
            <Typography variant='body2'>
              <b>
                {country}
              </b>
            </Typography>
            )}
            {onEdit && (
              <Link
                component='button'
                variant='body2'
                type='button'
                onClick={() => onEdit(2)}
                className={classes.editButton}>
                <strong>
                  {i18n.t('authenticated.payment.steps.stepReview.edit')}
                </strong>
              </Link>
            )}
          </>
        ) : null}
        <Typography variant='body2' className={classes.topSpacing}>
          {i18n.t('authenticated.payment.steps.stepReview.formOfPayment')}
        </Typography>
        <Typography variant='body2'>
          <b>{metodo ? mapPaymentMethodToName[metodo] : ''}</b>
        </Typography>
        {onEdit && (
          <Link
            component='button'
            variant='body2'
            type='button'
            onClick={() => onEdit(0)}
            className={classes.editButton}>
            <strong>
              {i18n.t('authenticated.payment.steps.stepReview.edit')}
            </strong>
          </Link>
        )}
        <FormControlLabel
          control={
            <Checkbox
              checked={shouldPersist}
              onChange={switchPersist}
              name='persist'
              color='primary'
            />
          }
          label={i18n.t('authenticated.payment.steps.stepReview.update')}
        />
      </Box>
    </form>
  )
}

export default Step3View
