import { CurrenciesEnum } from "core/types/CurrenciesEnum";
import { OfferPriceType } from "core/types/OfferPriceType";

type InputParameters = {
  currency?: string,
  unitPrice?: number,
  prices?: OfferPriceType
}

type ReturnParams = {
  prices: OfferPriceType
}

export const offerPriceParameters = ({
  prices,
  currency,
  unitPrice
}: InputParameters): ReturnParams => {
  let vintage: OfferPriceType | undefined = undefined
  if( currency && unitPrice !== undefined ) {
    vintage = {
      [currency as CurrenciesEnum]: unitPrice
    }
  }
  if ( !Object.keys(CurrenciesEnum).includes( Object.keys(prices||{})[0]?.toUpperCase() ) ) {
    return { prices: vintage! }
  }
  return {
    prices: prices || vintage as OfferPriceType
  }
}
