import { i18n } from 'translate/i18n'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { UF } from 'core/constants'
import FormField from 'components/FormFields/FormField.view'
import {
  IPaymentStepAddressInputs,
  IPaymentStepUserDataInputs,
} from 'pages/Authenticated/Payment/Payment.types'
import { FormEventHandler, useMemo } from 'react'
import { Control, FieldError } from 'react-hook-form'
import { useProfileForm } from '../V2/UserProfile.hook'
import { RarumUserProfile } from 'core/logic/user/user.types'

export type ProfileFormViewProps = {
  handleSubmit: FormEventHandler<HTMLFormElement>
  lockFields: {
    [k in 'city' | 'address' | 'state']: boolean
  }
  fetchingCEP: boolean
} & (
  | {
      errors: {
        [k in keyof (IPaymentStepUserDataInputs &
          IPaymentStepAddressInputs)]: FieldError
      }
      control: Control<IPaymentStepUserDataInputs & IPaymentStepAddressInputs>
      /**
       * Migration flag v2 should be the target
       */
      version?: 'v1'
    }
  | {
      errors: {
        [k in keyof NonNullable<RarumUserProfile['personalData']>]?: FieldError
      }
      control: ReturnType<typeof useProfileForm>['form']['control']
      version: 'v2'
    }
)

type MainVersionProps = ProfileFormViewProps & { version: 'v2' }

const estados = UF.map((uf) => ({
  key: `uf-${uf.sigla}`,
  value: uf.sigla,
  label: uf.nome,
}))

const ProfileFormView: React.FC<ProfileFormViewProps> = ({
  handleSubmit,
  lockFields,
  fetchingCEP,
  ...props
}) => {
  const { keys, control, errors, margin } = useMemo(() => {
    switch (props.version) {
      case 'v2':
        return {
          keys: {
            name: 'name',
            state: 'state',
            city: 'city',
            address: 'address',
            addressNumber: 'addressNumber',
          } as const,
          control: props.control as MainVersionProps['control'],
          errors: props.errors as MainVersionProps['errors'],
          margin: 0,
        }
      case 'v1':
      default:
        return {
          keys: {
            name: 'userName',
            state: 'estado',
            city: 'cidade',
            address: 'logradouro',
            addressNumber: 'numero',
          } as any,
          control: props.control as unknown as MainVersionProps['control'],
          errors: props.errors as unknown as MainVersionProps['errors'],
          margin: 2,
        }
    }
  }, [props.version, props.control, props.errors])
  return (
    <form id='user-form' onSubmit={handleSubmit}>
      <Box mb={4} ml={margin}>
        <Typography variant='body1' component='p'>
          <strong>
            {i18n.t('authenticated.userProfile.profileForm.identification')}
          </strong>
        </Typography>
      </Box>
      <Box
        display='flex'
        flexWrap='wrap'
        flexBasis='100%'
        justifyContent='space-between'>
        <FormField
          margin={margin}
          control={control}
          errors={errors}
          fieldPath={keys.name}
          label={i18n.t('authenticated.userProfile.profileForm.fullName')}
          autoComplete='name'
        />

        <FormField
          margin={margin}
          control={control}
          errors={errors}
          fieldPath='email'
          label='E-mail'
          type='email'
          autoComplete='email'
        />
      </Box>
      <Box
        display='flex'
        flexWrap='wrap'
        flexBasis='100%'
        justifyContent='space-between'>
        <FormField
          margin={margin}
          control={control}
          errors={errors}
          fieldPath='cpf'
          label={i18n.t('authenticated.userProfile.profileForm.cpf')}
          inputMode='numeric'
          mask='999.999.999-99'
          hideMaskPlaceholder
        />
      </Box>

      <Box mb={4} ml={margin}>
        <Typography variant='body1' component='p'>
          <strong>
            {i18n.t('authenticated.userProfile.profileForm.address')}
          </strong>
        </Typography>
      </Box>
      <Box display='flex' flexWrap='wrap' flexBasis='100%'>
        <FormField
          margin={margin}
          control={control}
          errors={errors}
          fieldPath='cep'
          label={i18n.t('authenticated.userProfile.profileForm.cep')}
          inputMode='numeric'
          autoComplete='postal-code'
          mask='99999-999'
          hideMaskPlaceholder
        />
      </Box>
      <Box
        display='flex'
        flexWrap='wrap'
        flexBasis='100%'
        justifyContent='space-between'>
        <FormField
          margin={margin}
          control={control}
          errors={errors}
          fieldPath={keys.state}
          label={i18n.t('authenticated.userProfile.profileForm.state')}
          autoComplete='address-level1'
          options={estados}
          disabled={fetchingCEP || lockFields.state}
        />

        <FormField
          margin={margin}
          control={control}
          errors={errors}
          fieldPath={keys.city}
          label={i18n.t('authenticated.userProfile.profileForm.city')}
          autoComplete='address-level2'
          disabled={fetchingCEP || lockFields.city}
        />
      </Box>
      <Box
        display='flex'
        flexWrap='wrap'
        flexBasis='100%'
        justifyContent='space-between'>
        <FormField
          margin={margin}
          control={control}
          errors={errors}
          fieldPath={keys.address}
          label={i18n.t('authenticated.userProfile.profileForm.place')}
          autoComplete='address-line1'
          disabled={fetchingCEP || lockFields.address}
        />

        <FormField
          margin={margin}
          control={control}
          errors={errors}
          fieldPath={keys.addressNumber}
          label={i18n.t('authenticated.userProfile.profileForm.number')}
          inputMode='numeric'
        />
      </Box>
    </form>
  )
}
export default ProfileFormView
