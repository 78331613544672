import Styles from './ResourceNotFound.module.scss'
import { ReactComponent as Zero } from 'assets/icons/zero.svg'
import { FigmaTypo } from 'containers/OneUIProvider/OneUIProvider'
import { t } from 'translate/i18n'
import Text from '@onepercentio/one-ui/dist/components/Text'
import Button from '@onepercentio/one-ui/dist/components/Button'
import FooterLogic from 'components/Footer/Footer.logic'

/**
 * When a resource is not found, provide a way to go back
 **/
export default function ResourceNotFound({
  onGoToMainPage,
}: {
  onGoToMainPage: () => void
}) {
  return (
    <>
      <div className={Styles.root}>
        <Zero />
        <Text type={FigmaTypo.P16}>{t('generic.notFound.title')}</Text>
        <Button onClick={onGoToMainPage}>
          {t('generic.notFound.actions.backToHome')}
        </Button>
      </div>
      <FooterLogic />
    </>
  )
}
