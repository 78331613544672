import { mapActions } from './auth.reducer'

export enum Platform {
  'gmail' = 'gmail',
  'facebook' = 'facebook',
  'metamask' = 'metamask'
}

export type Credentials = {
  email: string
  password: string
}

export type CheckingCode = {
  code: string
}

export type AuthState = {
  authenticated: boolean | null
  errorKey: string
}

export type AuthActions = typeof mapActions
export type AuthActionsTypes = keyof AuthActions
export type AuthAction = ReturnType<AuthActions[AuthActionsTypes]>
export type AuthPayload = Parameters<AuthActions[AuthActionsTypes]>
export type AuthActionReturn<T extends AuthActionsTypes> = ReturnType<AuthActions[T]>

export type AuthWithPOS = {
  signature: string
  message: {
    account: string,
    timestamp: string,
  }
}
