import {
  AnswerAction,
  BasicFormFields,
} from 'features/CVM88/Form/Form.types'

export type Address = {
  cep: string
  address: string
  addressNumber: string
  neighbourhood: string
  state: string
  city: string
  complement: string
}

export enum AddressFormMode {
  READ_ONLY,
  WRITE,
}

export type AddressErrors = { [k in keyof Address]?: string }

export type AddressFormProps = {
  address: Address
} & (
  | {
      mode: AddressFormMode.READ_ONLY
    }
  | {
      mode: AddressFormMode.WRITE
      showErrors: boolean
      onAddress: (
        address: Address,
        isComplete: boolean,
        errors: AddressErrors
      ) => void
    }
)
export type AddressFormViewProps = {
  onAddressFieldChange: AddressChangeAction<AddressFormField>
  address: Address
  mode: AddressFormMode
  errors: AddressErrors
  fields: AddressFormField[]
}

export type AddressFormField = {
  title: string
  id: keyof Address
  disabled: boolean
  error?: {
    label: string
    retry: () => void
  }
  optional?: true
} & BasicFormFields

export type AddressChangeAction<Q extends AddressFormField> = <
  T extends Q['type']
>(
  questionType: T,
  questionId: string,
  answer: AnswerAction<{
    type: T
  }>
) => void
