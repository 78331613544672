import { useEffect, useState } from 'react'
import { i18n } from 'translate/i18n'

export default function useLanguage() {
  const [lng, setLng] = useState(i18n.language)
  useEffect(() => {
    i18n.on('languageChanged', setLng)
  }, [])

  return lng
}
