import { Hidden } from 'augmentation/mui'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import ActionButton from 'components/ActionButton'
import { ApprovalStatus } from 'components/ApprovalStatus'
import DropAvailability from 'components/DropAvailability'
import DropUnavailable from 'components/DropUnavailable'
import { OfferAuctionRace } from 'components/offer/OfferAuctionRace'
import OfferPrice, { offerPriceParameters } from 'components/offer/OfferPrice'
import ShareButton from 'components/ShareButton'
import { DROP_STATUS } from 'core/helpers/drop'
import { DropType, OfferType } from 'core/logic/drop/drop.types'
import { useTenant } from 'core/logic/tenant/tenant.hook'
import { ReactElement, useMemo } from 'react'
import { i18n } from 'translate/i18n'
import useStyles from './Info.style'
export interface InfoViewProps {
  purchaseHandler: () => void
  availability: DROP_STATUS
  drop: DropType
  remainingSupply: number
  isLoading?: boolean
  ctaLabel?: string
  approved: boolean
  /**
   * Relevant when the @see DropType['type'] is 'onchain'
   *
   * Decides if the provider unavailable should or should not be visible
   */
  hasProvider: boolean
}

const InfoView: React.FC<InfoViewProps> = ({
  purchaseHandler,
  availability,
  drop,
  remainingSupply,
  isLoading,
  ctaLabel,
  approved,
  hasProvider,
}) => {
  const { tenant } = useTenant()
  const code = i18n.language
  const classes = useStyles()
  const isAvailable = availability === 'queue' || availability === 'open'
  const isExpired = availability === 'expired'
  const isScheduled = availability === 'scheduled'
  const isOutOfSupply = availability === 'out'
  const showApprovalStatus = drop && drop.type === 'onchain' && isAvailable
  const showMissingProvider = drop && drop.type === 'onchain' && !hasProvider

  const content = useMemo<ReactElement>(() => {
    switch (drop.type) {
      case 'cvm88':
        return <></>;
      case 'off_chain_auction':
      case 'auction':
        return <OfferAuctionRace offer={drop as OfferType} availability={availability} />
      case 'onchain':
      case 'pack':
      case 'single':
        return (
          <>
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              className={classes.price}>
              <OfferPrice
                {...offerPriceParameters({
                  currency: drop?.currency,
                  unitPrice: drop.unitPrice,
                  prices: drop.prices,
                })}
                acceptedCurrencies={tenant?.currencies?.accepted}
                preferredCurrency={tenant?.currencies?.preferredCurrency}
              />
              <Hidden smUp>
                <ShareButton
                  id={drop.id}
                  name={drop?.[code]?.title ?? drop?.title}
                />
              </Hidden>
            </Box>
            {isAvailable && !showMissingProvider && (
              <ActionButton
                onClick={purchaseHandler}
                text={
                  !ctaLabel
                    ? i18n.t('unauthenticated.drops.details.info.bookPackage')
                    : ctaLabel
                }
                className={classes.action}
                disabled={isLoading}
                showSpinner={isLoading}
              />
            )}
          </>
        )
    }
  }, [
    drop,
    availability,
    classes.action,
    classes.price,
    code,
    ctaLabel,
    isAvailable,
    isLoading,
    purchaseHandler,
    showMissingProvider,
    tenant?.currencies?.accepted,
    tenant?.currencies?.preferredCurrency,
  ])

  return (
    <section className={classes.section}>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <DropAvailability
          availability={availability}
          beginDate={drop.begin}
          endDate={drop.expire}
          issued={drop?.supply}
          remaining={remainingSupply}
          boxProps={{ flexGrow: 1 }}
          type={drop.type}
        />
        <Hidden xsDown>
          <Box ml={3}>
            <ShareButton
              id={drop.id}
              name={drop?.[code]?.title ?? drop?.title}
            />
          </Box>
        </Hidden>
      </Box>
      <Divider className={classes.divider} />
      <Typography
        variant='h2'
        component='h1'
        color='textPrimary'
        className={classes.title}>
        {drop?.[code]?.title ?? drop?.title}
      </Typography>
      <Typography
        variant='body1'
        component='h2'
        color='textSecondary'
        className={classes.description}>
        {drop?.[code]?.description ?? drop?.description}
      </Typography>
      {content}

      {drop.type !== 'auction' && (
        <>
          {showMissingProvider && (
            <DropUnavailable
              text={i18n.t(
                'unauthenticated.drops.details.info.missingProvider'
              )}
            />
          )}
          {showApprovalStatus && !showMissingProvider && (
            <ApprovalStatus approved={approved} />
          )}
          {isExpired && <DropUnavailable />}
          {isOutOfSupply && (
            <DropUnavailable
              text={i18n.t('unauthenticated.drops.details.info.notAvailable')}
            />
          )}
          {isScheduled && (
            <DropUnavailable
              text={i18n.t('unauthenticated.drops.details.info.waitEvent')}
            />
          )}
        </>
      )}
    </section>
  )
}

export default InfoView
