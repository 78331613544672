import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress'
import React, { memo } from 'react'
import { LoadingTestIds } from './Loading.e2e'
import useStyles from './Loading.style'

const LoadingView = () => {
  const classes = useStyles()
  return (
    <Box
      data-testid={LoadingTestIds.CONTAINER}
      top={0}
      left={0}
      display='flex'
      position='fixed'
      minHeight='4px'
      width='100%'
      overflow={"hidden"}>
      <LinearProgress className={classes.root} />
    </Box>
  )
}

export default memo(LoadingView)
