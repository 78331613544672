import { OfferAuctionBidModalForm } from './OfferAuctionBidModalForm'
import { OfferAuctionBidModalConfirm } from './OfferAuctionBidModalConfirm'
import { OfferAuctionBidModalFinish } from './OfferAuctionBidModalFinish'
import { BidStepsType, OfferAuctionBidModalFormProps, OfferAuctionBidModalConfirmProps } from './types'
import { OfferAuctionBidPhoneConfirmation } from './OfferAuctionBidPhoneConfirmation'

export const orderStepsMap: BidStepsType[] = [
    "phoneConfirmation",
    'form',
    'finish'
]

export const stepComponentMap: Record<BidStepsType, React.FC<any>> = {
  form: OfferAuctionBidModalForm,
  confirm: OfferAuctionBidModalConfirm,
  finish: OfferAuctionBidModalFinish,
  phoneConfirmation: OfferAuctionBidPhoneConfirmation
}

export const getStepComponent = (step: BidStepsType): React.FC<any> => {
    const Component = stepComponentMap[step]
    return (props: any) =>  <Component {...{...props, ...getPropsMap(step)}} />
}

export const stepPropsMap: Record<BidStepsType, (props: any) => any> = {
    phoneConfirmation: (props: OfferAuctionBidModalConfirmProps) => (props),
    form: ({
        amount,
        currentBid,
        handleAmountChange, 
        handleModal,
        handlePhoneChange, 
        initialBid, 
        offer,
        phone,
        onNext,
        onPrev,
        onFinish,
        onCancel
    }: OfferAuctionBidModalFormProps): OfferAuctionBidModalFormProps => ({
        amount,
        currentBid,
        handleAmountChange, 
        handleModal,
        handlePhoneChange, 
        initialBid, 
        offer,
        phone,
        onNext,
        onPrev,
        onFinish,
        onCancel
    }),
    confirm: ({
        offer,
        handleModal,
        onPrev, 
    }: OfferAuctionBidModalConfirmProps): OfferAuctionBidModalConfirmProps => ({
        offer,
        handleModal,
        onPrev,
    }),
    finish: () => {}
} 

export const getPropsMap = (step: BidStepsType) => {
    return stepPropsMap[step]
}
