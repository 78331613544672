import {
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import CurrencySelector from 'components/CurrencySelector'
import { AssetType, Sale } from 'core/logic/asset/asset.types'
import { i18n, t } from 'translate/i18n'
import useStyles from './MarketplaceInformation.style'
import { currencyNumberFormatter } from 'core/helpers/formatter'
import clsx from 'clsx'
import { PropsWithChildren } from 'react'
import { Skeleton } from '@material-ui/lab'
import { shortenWallet } from 'core/helpers/wallet'
import ShareButtonView from 'components/ShareButton/ShareButton.view'
import { MarketplaceInformationTestIds } from './MarketplaceInformation.e2e'
import WalletAvatar from 'components/WalletAvatar/WalletAvatar'
import Button from '@onepercentio/one-ui/dist/components/Button'

function LoaderWrapper({
  children,
  loading,
  height,
}: PropsWithChildren<{ loading: boolean; height: string | number }>) {
  return loading ? (
    <Skeleton
      data-testid={MarketplaceInformationTestIds.LOADER}
      style={{ transform: 'initial', marginBottom: 24 }}
      height={height}
    />
  ) : (
    (children as any)
  )
}

export default function MarketplaceInformationView({
  availableItens,
  asset,
  currencies,
  selectedCurrency,
  onCurrencySelected,
  offers,
  userOffers,
  onBuyOffer,
  onManageOffer,
  loading,
}: {
  asset: AssetType
  availableItens: number
  currencies: CryptoCurrencySymbol[]
  selectedCurrency?: CryptoCurrencySymbol
  offers?: Sale[]
  userOffers: Sale[]
  onBuyOffer: (offerId: string) => void
  onManageOffer: (assetId: string, offerId: string) => void
  loading: boolean

  onCurrencySelected: (c: CryptoCurrencySymbol) => void
}) {
  const classes = useStyles()
  const translatedAssetData = asset[i18n.language] ?? asset
  const [firstOffer, ...otherOffers] = offers || []
  const isOfferMine = (offer: Sale) => userOffers.includes(offer)
  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.row}>
          <Chip
            variant='outlined'
            label={t('marketplace.assetDetails.available', {
              count: availableItens,
            })}
          />
          <ShareButtonView id='marketplace' name={asset.name} />
        </Box>
        <br />
        <Divider />
        <Box className={classes.heading}>
          <Typography variant={'h3'}>{translatedAssetData.name}</Typography>
          <Typography variant={'body1'}>
            {translatedAssetData.description}
          </Typography>
        </Box>
        <Box className={classes.row}>
          <Typography variant='h5'>
            {t('marketplace.assetDetails.offers')}
          </Typography>

          <CurrencySelector
            currencies={currencies}
            onSelected={onCurrencySelected}
            selected={selectedCurrency || ''}
          />
        </Box>
        <br />
        <LoaderWrapper height={'7rem'} loading={loading}>
          {!firstOffer ? (
            <>
              {offers !== undefined ? (
                <Typography>
                  {t('marketplace.assetDetails.noOffers', {
                    currency: selectedCurrency,
                  })}
                </Typography>
              ) : null}
            </>
          ) : (
            <Box className={clsx(classes.row, classes.actionable)}>
              <Box>
                <Typography>
                  {t('marketplace.assetDetails.lowestPriceOffer')}
                </Typography>
                <span className={classes.big}>
                  {selectedCurrency}{' '}
                  {currencyNumberFormatter(firstOffer?.price!)}
                </span>
                <div className={classes.inline}>
                  <WalletAvatar wallet={firstOffer?.wallet} size={24} />
                  &nbsp;{' '}
                  {!isOfferMine(firstOffer)
                    ? shortenWallet(firstOffer.user)
                    : t('marketplace.assetDetails.you')}
                </div>
                <br />
              </Box>
              <Button
                variant='filled'
                onClick={
                  isOfferMine(firstOffer)
                    ? () => onManageOffer(asset.id, firstOffer.offerId)
                    : () => onBuyOffer(firstOffer.offerId)
                }>
                {!isOfferMine(firstOffer)
                  ? t('generic.buy')
                  : t('marketplace.assetDetails.actions.manageMyITem')}
              </Button>
            </Box>
          )}
        </LoaderWrapper>
        <LoaderWrapper height={'10rem'} loading={loading}>
          {!otherOffers.length ? null : (
            <Table className={classes.actionable}>
              <TableHead>
                <TableCell>
                  {t('marketplace.assetDetails.marketplaceValue')}
                </TableCell>
                <TableCell>{t('marketplace.assetDetails.seller')}</TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableHead>
              <TableBody>
                {otherOffers.map((offer) => {
                  const isMyOffer = userOffers.includes(offer)
                  return (
                    <TableRow>
                      <TableCell>
                        {selectedCurrency}{' '}
                        {currencyNumberFormatter(offer.price)}
                        <div
                          className={clsx(classes.inline, classes.whenSmall)}>
                          <WalletAvatar wallet={offer.wallet} size={24} />
                          &nbsp;
                          {!isMyOffer
                            ? shortenWallet(offer.user)
                            : t('marketplace.assetDetails.you')}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className={classes.inline}>
                          <WalletAvatar wallet={offer.wallet} size={24} />
                          &nbsp;
                          {!isMyOffer
                            ? shortenWallet(offer.user)
                            : t('marketplace.assetDetails.you')}
                        </div>
                      </TableCell>
                      <TableCell>
                        <Button
                          variant='filled'
                          onClick={
                            isMyOffer
                              ? () => onManageOffer(asset.id, offer.offerId)
                              : () => onBuyOffer(offer.offerId)
                          }>
                          {!isMyOffer
                            ? t('generic.buy')
                            : t(
                                'marketplace.assetDetails.actions.manageMyITem'
                              )}
                        </Button>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          )}
        </LoaderWrapper>
      </Box>
    </>
  )
}
