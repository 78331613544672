import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const PULSE_DURATION = 24

const style = (theme: Theme) =>
  createStyles({
    main: {
      display: 'flex',
      flexDirection: 'column',
      color: 'var(--text-default-color)',
      background: theme.palette.dropOpen?.bg,
      backgroundAttachment: 'fixed',
      height: '100%',
    },
    pulse: {
      height: '100%',
      width: '100%',
      backgroundColor: 'black',
      padding: `clamp(${theme.spacing(1)}px,5vmin,${theme.spacing(10)}px)`,
      animation: `$pulse ${PULSE_DURATION}s ease infinite`,
    },
    // Animations - going up
    light: {
      position: 'absolute',
      width: 0,
      backgroundColor: 'white',
      boxShadow: '#767093 0px 0px 10px 1px',
      opacity: 0,
      top: '100vh',
      bottom: 0,
      left: 0,
      right: 0,
      margin: 'auto',
    },
    x1: {
      animation: '$floatUp 12s infinite linear',
      animationDelay: '5s',
      transform: 'scale3d(1.0, 1.0, 1.0)',
    },
    x2: {
      animation: '$floatUp 21s infinite linear',
      animationDelay: '8s',
      transform: 'scale3d(1.6, 1.6, 1.0)',
      left: '15%',
    },
    x3: {
      animation: '$floatUp 7.5s infinite linear',
      animationDelay: '2s',
      transform: 'scale3d(.5, .5, 1.0)',
      left: '-15%',
    },
    x4: {
      animation: '$floatUp 13.5s infinite linear',
      animationDelay: '12s',
      transform: 'scale3d(1.2, 1.2, 1.0)',
      left: '-34%',
    },
    x5: {
      animation: '$floatUp 24s infinite linear',
      animationDelay: '3s',
      transform: 'scale3d(2.2, 2.2, 1.0)',
      left: '-57%',
    },
    x6: {
      animation: '$floatUp 9s infinite linear',
      animationDelay: '7s',
      transform: 'scale3d(.8, .8, 1.0)',
      left: '-81%',
    },
    x7: {
      animation: '$floatUp 15.9s infinite linear',
      animationDelay: '9s',
      transform: 'scale3d(3.2, 3.2, 1.0)',
      left: '37%',
    },
    x8: {
      animation: '$floatUp 14.1s infinite linear',
      animationDelay: '1s',
      transform: 'scale3d(1.7, 1.7, 1.0)',
      left: '62%',
    },
    x9: {
      animation: '$floatUp 12.3s infinite linear',
      animationDelay: '15s',
      transform: 'scale3d(0.9, 0.9, 1.0)',
      left: '85%',
    },
    '@keyframes pulse': {
      '0%': { backgroundColor: 'rgba(0,0,0,.5)' },
      '20%': { backgroundColor: 'rgba(0,0,0,0)' },
      '80%': { backgroundColor: 'rgba(0,0,0,0)' },
      '100%': { backgroundColor: 'rgba(0,0,0,.5)' },
    },
    '@keyframes floatUp': {
      '0%': { top: '100vh', opacity: 0 },
      '5%': { opacity: 1 },
      '10%': { top: '0vh', opacity: 0.8 },
      '15%': { opacity: 1 },
      '20%': { top: '-100vh', opacity: 0 },
      '100%': { top: '-100vh', opacity: 0 },
    },
    actionsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
    actionButton: {
      marginBottom: theme.spacing(5),
      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(5),
        marginBottom: 0,
      },
    },
    cancelButton: {
      alignSelf: 'flex-start',
      [theme.breakpoints.up('md')]: {
        alignSelf: 'center',
      },
    },
  })

export default makeStylesWrapper(style)
