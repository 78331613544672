import { useState } from 'react'
import { OfferAuctionBidListProps, OfferBidFormatterType } from './types'
import { OfferAuctionBidListView } from './OfferAuctionBidListView'
import { currencyFormatter, formatDistanceToNow } from 'core/helpers/formatter'
import { useTenant } from 'core/logic/tenant/tenant.hook'
export const OfferAuctionBidList: React.FC<OfferAuctionBidListProps> = ({
  bids,
}) => {
  // get tenant config
  const { tenant } = useTenant()
  const [isExpanded, setIsExpanded] = useState(true)
  const handleToogle = () => {
    setIsExpanded((state) => !state)
  }
  // create formatter by tenant or offer currency
  const formatter = currencyFormatter(
    bids?.[0]?.currency || tenant?.currencies?.preferredCurrency
  )
  
  const formattedBids: OfferBidFormatterType[] | undefined = bids?.map(
    (bid): OfferBidFormatterType => ({
      ...bid,
      created: bid.created
        ? formatDistanceToNow(new Date(bid.created))
        : undefined,
      amount: formatter(bid.amount),
    })
  )
  return (
    <OfferAuctionBidListView
      bids={formattedBids}
      isExpanded={isExpanded}
      toogleExpand={handleToogle}
    />
  )
}
