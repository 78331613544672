import BigNumber from 'bignumber.js'

const TEN = new BigNumber(10)

const convertToInteger = (value: string, decimals: string) => {
  if (decimals === '0') return value;

  const multiplier = TEN.pow(decimals);

  return new BigNumber(value, 10)
    .multipliedBy(multiplier)
    .decimalPlaces(0, BigNumber.ROUND_DOWN)
    .toString(10);
}

const convertToDecimal = (value: string, decimals: string) => {
  if (decimals === '0') return value;

  const multiplier = TEN.pow(decimals);

  return new BigNumber(value, 10)
    .decimalPlaces(0, BigNumber.ROUND_DOWN)
    .div(multiplier)
    .toString(10);
}

export { convertToInteger, convertToDecimal }
