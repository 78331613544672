import useStyles from './CurrencyLabel.style'
const cryptoSymbolCtx = require.context('assets/images/crypto')

function loadCurrencyIcon(currency: CryptoCurrencySymbol, imgClass: string) {
  const symbol = `./${currency.toLowerCase()}.svg`
  if (cryptoSymbolCtx.keys().includes(symbol))
    return (
      <img
        className={imgClass}
        src={cryptoSymbolCtx(symbol).default}
        alt='Crypto'
      />
    )
  return null
}

export default function CurrencyLabel({
  currency,
}: {
  currency: CryptoCurrencySymbol
}) {
  const classes = useStyles()
  return (
    <>
      <div className={classes.root}>
        {loadCurrencyIcon(currency, classes.img)} {currency}
      </div>
    </>
  )
}
