import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'baseline',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    main: {
      '&.price-sm': {},
      '&.price-md': {},
    },
    secondary: {
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
      '&.price-sm': {},
      '&.price-md, &.price-sm': {
        fontSize: '0.9em'
      },
    },
    sub: {
      fontSize: "0.75em"
    }
  })

export default makeStylesWrapper(style)
