import { useAssets } from 'core/logic/asset/asset.hook'
import { useEffect } from 'react'
import AssetView from './AssetList.view'
import { useMarketplace } from 'context/Marketplace'
import {
  MarketplaceAssetFilter,
  // MarketplaceFilter,
  MarketplaceOrderFilter,
} from 'components/AssetFilters/AssetFilters.types'
import {
  useAssetFilter,
  useFilteredAssets,
} from 'components/AssetFilters/AssetFilters.hook'

const INITIAL_FILTER = {
  rarity: null,
  trait: null,
}

const AssetLogic = ({ marketplace }: { marketplace: boolean }) => {
  const { assets, loading } = useAssets()
  const [filter, controls] = useAssetFilter({
    asset: MarketplaceAssetFilter.ALL,
    order: MarketplaceOrderFilter.PRICE_ASC,
    ...INITIAL_FILTER,
  })

  const { currencies, salesResume, salesResumeControl, sales } =
    useMarketplace() || {}

  useEffect(() => {
    if (currencies) controls.onCurrencyFilter(currencies[0])
  }, [currencies])

  const filteredAssets = useFilteredAssets(filter, assets || [], salesResume)
  useEffect(() => {
    sales.getAll()
  }, [])

  return (
    <AssetView
      assets={filteredAssets}
      allAvailableAssets={assets}
      filter={filter}
      loading={loading}
      marketplace={
        marketplace
          ? {
              currencies,
              sales: salesResume,
              ...salesResumeControl,
            }
          : undefined
      }
      {...controls}
    />
  )
}

export default AssetLogic
