import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) =>
  createStyles({
    container: {
      width: '302px',
      marginTop: theme.spacing(3),
      marginRight: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        width: '100%',
        minWidth: '100%',
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
      },
    },
    highlight: {
      backgroundColor: theme.palette.background.paper,
    },
    pointer: {
      cursor: 'pointer',
    },
    imageContainer: {
      position: 'relative',
    },
    figure: {
      width: '100%',
      marginTop: 0,
      marginBottom: theme.spacing(2.5),
      marginLeft: 0,
      marginRight: 0,
      [theme.breakpoints.down('sm')]: {
        margin: `0 auto ${theme.spacing(2.5)}px`,
      },
      transitionProperty: 'transform box-shadow',
      transitionDuration: `${theme.transitions.duration.complex}ms`,
      transitionTimingFunction: theme.transitions.easing.easeInOut,
      '&:hover': {
        transform: 'translate3d(0, -8px, 0)',
        boxShadow:
          theme.palette.type === 'light'
            ? `0 8px 12px -4px ${theme.palette.grey[300]}`
            : 'none',
        borderRadius: 11,
        zIndex: theme.zIndex.appBar - 1,
      },
    },
    image: {
      maxWidth: '100%',
      [theme.breakpoints.down('sm')]: {
        maxHeight: '100%',
        maxWidth: '100%',
        display: 'flex',
      },
      borderRadius: 10,
    },
    chip: {
      borderRadius: '4px',
      minWidth: '160px',
      fontStyle: 'initial',
      display: 'inline-flex',
      marginRight: 'auto',
      marginBottom: '16px',
      backgroundColor: 'transparent',
      border: `2px solid ${theme.palette.sky}`,
      color: theme.palette.sky,
    },
    big: {
      fontSize: '1.50rem',
    },
    descriptionContainer: {
      marginTop: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    description: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      flexGrow: 1,
    },
    divider: {
      display: 'none',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
      },
    },
    boxSticky: {
      zIndex: theme.zIndex.appBar,
      transition: 'opacity 1s, overflow 1s, max-height 3s',
      display: 'flex',
      background: 'white',
      visibility: 'hidden',
      position: 'fixed',
      left: 0,
      top: theme.mixins.toolbar.minHeight,
      [theme.breakpoints.down('sm')]: {
        visibility: 'hidden',
        width: '100%',
        maxHeight: 0,
        overflow: 'hidden',
      },
    },
    boxStickyOpen: {
      [theme.breakpoints.down('sm')]: {
        maxHeight: 500,
        opacity: 1,
        visibility: 'visible',
      },
    },
    boxStickyClosed: {
      maxHeight: '0',
      overflow: 'hidden',
      visibility: 'hidden',
      opacity: 0,
    },
    stickyImage: {
      width: '88px',
      marginLeft: theme.spacing(3),
    },
    closeIcon: {
      alignSelf: 'flex-start',
      alignItems: 'flex-start',
      margin: theme.spacing(1),
    },
    stickyChip: {
      color: theme.palette.success.main,
      borderColor: theme.palette.success.main,
      maxWidth: '120px',
      marginBottom: theme.spacing(1),
      '& > *': {
        fontSize: '12px',
      },
    },
    stickyTitle: {
      fontWeight: 700,
    },
    stickyDescription: {
      paddingTop: theme.spacing(2),
      flex: 1,
      paddingLeft: theme.spacing(2),
    },
    price: {
      fontSize: '1.25rem',
    },
  })

export default makeStylesWrapper(style)
