import { Container } from '@material-ui/core'
import { t, EscapedTranslation } from 'translate/i18n'
import { Decoration } from './FAQ.template'
import useStyles from './FAQ.style'
import Button from '@onepercentio/one-ui/dist/components/Button'

export default function FAQBanner({ onKnowMore }: { onKnowMore: () => void }) {
  const classes = useStyles()
  return !t('marketplace.banner.faq.targetGuide') ? null : (
    <Container maxWidth='lg' className={classes.root}>
      <Decoration />
      <div className={classes.content}>
        <p>
          <EscapedTranslation code='marketplace.banner.faq.title' />
        </p>
        <Button variant='filled' onClick={onKnowMore}>
          {t('marketplace.banner.faq.action')}
        </Button>
      </div>
    </Container>
  )
}
