import React from 'react'
import Styles from './TokenEmitterResume.module.scss'
import { GalleryType } from 'core/logic/gallery/gallery.types'
import { useEmitterGalleryInfo } from 'context/Gallery'
import Text from 'openspace/components/Text'
import Loader from '@onepercentio/one-ui/dist/components/Loader'
import Spacing from '@onepercentio/one-ui/dist/components/Spacing'

/**
 * Shows the information about total volume and projects in course
 **/
export default function TokenEmitterResume({
  gallery,
}: {
  gallery: GalleryType
}) {
  const { totalVolume, projects, formatter } = useEmitterGalleryInfo(gallery)
  return (
    <div className={Styles.tokenEmitterResume}>
      <div>
        <Text
          type='p14'
          code='components.galleryCard.tokenEmitter.totalVolume'
        />
        <Spacing size='8' />
        <Text type='h3'>
          {totalVolume === undefined ? (
            <Loader />
          ) : (
            formatter.format(totalVolume)
          )}
        </Text>
      </div>
      <div>
        {projects === undefined ? (
          <Loader />
        ) : (
          <>
            <span>
              {projects
                .slice(0, Math.min(4, projects.length))
                .map((i, idx, arr) => (
                  <img
                    style={{ zIndex: arr.length - idx }}
                    src={i.img}
                    alt={i.name}
                  />
                ))}
            </span>
            <Text
              type='p14'
              color='primary'
              code='components.galleryCard.tokenEmitter.projects'
              format={{ count: projects.length }}
            />
          </>
        )}
      </div>
    </div>
  )
}
