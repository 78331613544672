import { ReactElement, createContext, useContext } from 'react'
import clientSetup from 'assets/client/config'

export type ClientContextShape = {
  home?: {
    banner: string
  }
  Logotype: () => ReactElement
  typography?: {
    font: string
    headFont: string
  }
}

export const ClientContext = createContext<ClientContextShape>(clientSetup)

export function useClientConfig() {
  return useContext(ClientContext)
}
