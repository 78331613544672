import Loading from 'components/Loading'
import { LS_PENDING_CREDENTIAL, LS_REDIRECT_URI } from 'core/constants'
import SocialSignInView from 'pages/Unauthenticated/SocialSignIn/SocialSignIn.view'
import {
  types as AuthTypes,
  useAuthentication,
} from 'core/logic/authentication'
import { Platform } from 'core/logic/authentication/auth.types'
import { useOffline } from 'core/logic/offline'
import { useTenant } from 'core/logic/tenant/tenant.hook'
import useUser from 'core/logic/user/user.hook'
import { analyticsEvent } from 'core/modules/analytics/events'
import { auth } from 'core/modules/firebase'
import {
  FB_LOGIN_REDIRECT,
  getPlatformfromProviderId,
} from 'core/modules/firebase/service'
import firebase from 'firebase/compat/app'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import NoAuth from './NoAuth'

interface SocialSignInLogicProps {
  heading: string
  type: 'sign-up' | 'sign-in'
}

const SocialSignInLogic: React.FC<SocialSignInLogicProps> = ({
  heading,
  type,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const history = useHistory()
  const {
    authentication: { authenticated, errorKey },
    gmail,
    facebook,
  } = useAuthentication()
  const { profile, setAcceptDataUsage } = useUser()
  const { tenant } = useTenant()
  const { redirect } = useOffline()
  /**
   * We propose two variables to control whether user formaly consents use of their data
   * dataConsent (boolean) is to keep the checkbox state (checked/not chekcked)
   * userRequestedSign (boolean) is to control if the user really requested to sign into the platform
   *  before setting consent in the database and prevent to set data consent in case of a simple redirection
   */
  const [dataConsent, setDataConsent] = useState(true)
  const [userRequestedSign, setUserRequestedSign] = useState(false)
  const consentCreated = useRef(false)

  useEffect(() => {
    const redirect = () => {
      /* If the user navigated to this page via one of the top bar buttons before logging-in,
      LS_REDIRECT_URI will be set to the previous location the user was in. */
      const prevLocation = localStorage.getItem(LS_REDIRECT_URI)
      if (prevLocation) {
        localStorage.removeItem(LS_REDIRECT_URI)
        history.replace(prevLocation)
      } else {
        history.replace('/')
      }
    }
    if (profile?.created && !consentCreated.current) {
      if (userRequestedSign) {
        consentCreated.current = true
        setAcceptDataUsage(dataConsent)
          .then(() => {
            redirect()
          })
          .catch((err) => {
            console.error(err)
            redirect()
          })
      } else {
        redirect()
      }
    }
    if (errorKey) {
      setError(errorKey)
      setIsLoading(false)
    }
  }, [
    errorKey,
    setError,
    history,
    profile,
    dataConsent,
    userRequestedSign,
    setAcceptDataUsage,
  ])

  const [existingPlatform, setExistingPlatform] = useState<AuthTypes.Platform>()
  const [pendingCredential, setPendingCredential] = useState<string>()

  useEffect(() => {
    /* See https://firebase.google.com/docs/auth/web/facebook-login#handling-account-exists-with-different-credential-errors
    for more details on this provider linking flow */
    auth.getRedirectResult().catch((error) => {
      const email = error.email
      const credential: firebase.auth.AuthCredential = error.credential

      if (error.code === 'auth/account-exists-with-different-credential') {
        auth.fetchSignInMethodsForEmail(email).then(function (providers) {
          const recommendedProvider = providers[0]
          const platform = getPlatformfromProviderId(recommendedProvider)
          setExistingPlatform(platform)
          setPendingCredential(JSON.stringify(credential.toJSON()))
        })
      }
    })
  }, [])

  // Provider means login with redirect, therefore it will save the redirect info
  const providerHandler = useCallback(
    (platform: Platform) => {
      setIsLoading(true)
      setUserRequestedSign(true)
      setError('')
      if (pendingCredential)
        localStorage.setItem(LS_PENDING_CREDENTIAL, pendingCredential)
      redirect.set(FB_LOGIN_REDIRECT)
      if (platform === Platform.gmail) {
        const method = 'google'
        if (type === 'sign-up') analyticsEvent.signUp({ method })
        else analyticsEvent.login({ method })
        gmail()
      }
      if (platform === Platform.facebook) {
        const method = 'facebook'
        if (type === 'sign-up') analyticsEvent.signUp({ method })
        else analyticsEvent.login({ method })
        facebook()
      }
    },
    [pendingCredential, redirect, type, gmail, facebook]
  )

  const dataConsentHandler = (checked: boolean) => {
    setDataConsent(checked)
  }

  const instagram = navigator.userAgent.includes('Instagram')

  return authenticated === false ? (
    instagram ? (
      <NoAuth />
    ) : (
      <SocialSignInView
        heading={heading}
        isLoading={isLoading}
        error={error}
        existingPlatform={existingPlatform}
        providerHandler={providerHandler}
        dataConsent={dataConsent}
        dataConsentHandler={dataConsentHandler}
        tenant={tenant}
      />
    )
  ) : (
    <Loading />
  )
}

export default SocialSignInLogic
