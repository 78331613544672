import { eventChannel } from 'redux-saga'
import { Channel, Task } from '@redux-saga/types'
import { actions as galleryActions, types as galleryActionTypes } from 'core/logic/gallery/gallery.reducer'
import { call, put, take, takeLatest, fork, cancel } from 'redux-saga/effects'
import * as galleryService from './gallery.service'
import { GalleryType } from './gallery.types'

// GALLERIES LIST ========

export async function galleriesUpdatesEmitter() {
  return eventChannel<GalleryType[]>((emitter) => {
    const unsubscribe = galleryService.fetchGalleries({ emitter })
    return () => {
      unsubscribe()
    }
  })
}

export function* galleriesSyncGenerator() {
  const emitterInterface: Channel<any> = yield call(galleriesUpdatesEmitter)
  try {
    while (true) {
      const galleriesChanged: GalleryType[] = yield take(emitterInterface)
      yield put(galleryActions.fetchGalleriesSuccess(galleriesChanged))
    }
  } catch (_e) {
    yield put(galleryActions.fetchGalleriesFailed('galleries.subscribe'))
  } finally {
    emitterInterface.close()
  }
}

// GALLERIES ITEM ========

export async function galleryUpdatesEmitter({ galleryId }: { galleryId: string }) {
  return eventChannel<GalleryType>((emitter) => {
    const unsubscribe = galleryService.fetchGallery({ galleryId, emitter })
    return () => {
      unsubscribe()
    }
  })
}

export function* gallerySyncGenerator({ galleryId }: { galleryId: string }) {
  const emitterInterface: Channel<any> = yield call(galleryUpdatesEmitter, { galleryId })
  try {
    while (true) {
      const galleryChanged: GalleryType = yield take(emitterInterface)
      yield put(galleryActions.fetchGallerySuccess(galleryChanged))
    }
  } catch (_e) {
    yield put(galleryActions.fetchGalleryFailed('gallery.subscribe'))
  } finally {
    emitterInterface.close()
  }
}

function* startGallerySyncChannel({ payload }: ReturnType<typeof galleryActions.emitterGallerySubscribe>) {
  if (!payload) throw new Error('Gallery ID missing')
  const gallerySyncTask: Task = yield fork(gallerySyncGenerator, { galleryId: payload })
  yield take(galleryActionTypes.GALLERY_EMITTER_UNSUBSCRIBE)
  yield cancel(gallerySyncTask)
}

export function* watchGallerySubscribe() {
  yield takeLatest(galleryActionTypes.GALLERY_EMITTER_SUBSCRIBE, startGallerySyncChannel)
}
