import React, {
  ForwardedRef,
  Fragment,
  PropsWithChildren,
  ReactElement,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { GalleryCardViewProps } from './GalleryCard.types'
import Styles from './GalleryCard.module.scss'
import { HeroText, default as BasicText } from 'openspace/components/Text'
import { FigmaTypo } from 'containers/OneUIProvider/OneUIProvider'
import { i18n } from 'translate/i18n'
import AdaptiveContainer from '@onepercentio/one-ui/dist/components/AdaptiveContainer'
import useHero from '@onepercentio/one-ui/dist/hooks/useHero'
import chroma from 'chroma-js'
import SectionStyles from 'openspace/components/Section/Section.module.scss'
import AllGalleriesStyles from 'openspace/pages/Unauthenticated/AllGalleries/AllGalleries.module.scss'
import TokenEmitterResume from './TokenEmitterResume'
import { GalleryType } from 'core/logic/gallery/gallery.types'

export const BaseWidth = Number(Styles.baseWidth)
export const BaseShortAssetWidth = Number(Styles.baseShortAssetWidthdefault)
export const BaseAssetWidth = BaseShortAssetWidth
export const BaseAssetMargin = Number(Styles.baseAssetMargin)
export const BaseAssetInnerPadding = Number(Styles.baseAssetInnerPadding)

const SpaceRequiredForAtLeastOneAsset =
  BaseAssetWidth + BaseAssetInnerPadding * 2

enum DataProps {
  IS_HIGHLIGHT = 'data-highlight',
}

/**
 * A gallery preview and assets preview
 **/
function GalleryCardView({
  gallery,
  willExpand,
  onOpenGallery,
  banner,
  className = '',
  ExpandedContent,
  mode,
  shouldExpandOnMount,
  size = 'static',
  version = 'v1',
}: GalleryCardViewProps) {
  const {
    heroRef,
    getHerosOnScreen,
    heroComponentRef: _heroComponentRef,
  } = useHero(`gallery-${gallery.id}`, undefined, {
    onHeroDetect: (possibleHero) => {
      return document.querySelectorAll(
        '[data-orderableid="orderable-list-clone"]'
      ).length
        ? false
        : banner
        ? possibleHero.matches(':hover')
        : possibleHero.getAttribute(DataProps.IS_HIGHLIGHT) ===
            (mode === 'highlight' ? '1' : '0') ||
          possibleHero.classList.contains(Styles.banner)
    },
    onHeroEnd: () => {
      if (shouldExpandOnMount) setExpanded(true)
    },
    onHeroStart: (clone) => {
      function disableDynamicHeightAndSetupHeightTransition() {
        if (firstChild.classList.contains(Styles.dynamic)) {
          firstChild.classList.remove(Styles.dynamic)
          firstChild.style.height = 'auto'
          ;(
            firstChild.lastElementChild! as HTMLDivElement
          ).style.maxHeight = `${BaseWidth}px`
        }
      }
      const galleryRoot = clone!
      const firstChild = galleryRoot.firstElementChild! as HTMLDivElement
      clone.style.overflow = 'hidden'
      if (banner) {
        galleryRoot.classList.remove(Styles.readyForTransition)
        disableDynamicHeightAndSetupHeightTransition()
        galleryRoot.classList.add(Styles.banner)
      } else {
        disableDynamicHeightAndSetupHeightTransition()
        galleryRoot.classList.remove(Styles.banner)
        if (shouldExpandOnMount) {
          galleryRoot.classList.add(Styles.readyForTransition)
        } else {
          galleryRoot.classList.remove(Styles.readyForTransition)
        }
      }
    },
    onBeforeTransition: (origin, target) => {
      const findParent = (el: HTMLDivElement) => {
        return (
          el.closest(`.${SectionStyles.items}`) ||
          el.closest(`.${AllGalleriesStyles.orderableGalleries}`) ||
          window.visualViewport!
        )
      }
      return [findParent(origin), findParent(target)] as const
    },
    onHeroSkipped: () => {
      if (shouldExpandOnMount) setExpanded(true)
    },
  })
  /** Only enable nested hero animations for cvm88 */
  const { TextComponent, heroComponentRef } = useMemo<{
    TextComponent: typeof HeroText
    heroComponentRef: (
      ...args: Parameters<typeof _heroComponentRef>
    ) => ReturnType<typeof _heroComponentRef> | undefined
  }>(() => {
    if (gallery.type === 'cvm88')
      return { TextComponent: HeroText, heroComponentRef: _heroComponentRef }
    else
      return {
        TextComponent: ({ heroId, ...props }) => <BasicText {...props} />,
        heroComponentRef: () => undefined,
      }
  }, [gallery.type])
  const [expanded, setExpanded] = useState(() => {
    const isOrderingCard = !!document.querySelectorAll(
      '[data-orderableid="orderable-list-clone"]'
    ).length
    return (
      shouldExpandOnMount &&
      (isOrderingCard ||
        getHerosOnScreen().filter(
          (a) =>
            a.getAttribute(DataProps.IS_HIGHLIGHT) ===
            (mode === 'highlight' ? '1' : '0')
        ).length === 0)
    )
  })

  useEffect(() => {
    heroRef.current!.setAttribute(
      DataProps.IS_HIGHLIGHT,
      mode === 'highlight' ? '1' : '0'
    )
  }, [])

  const itemRef = heroRef
  const [maxExpandableWidth, setWidth] = useState<number>()
  const [direction, setDirection] = useState<'h' | 'v'>('h')
  useEffect(() => {
    const EXTRA_SPACING = version === 'v1' ? 48 : 0
    function calculateAvailableSpace() {
      const availableContainerWidth =
        itemRef.current!.parentElement!.parentElement!.clientWidth -
        EXTRA_SPACING
      const availableWidth =
        size === 'dynamic' || version === 'v2'
          ? availableContainerWidth
          : Math.min(
              availableContainerWidth,
              BaseWidth + BaseAssetWidth * 3 + BaseAssetInnerPadding * 2
            )

      const spaceAvailableForTheAssets = availableWidth - BaseWidth

      if (spaceAvailableForTheAssets < SpaceRequiredForAtLeastOneAsset) {
        setDirection('v')
        setWidth(availableContainerWidth)
      } else {
        setDirection('h')
        setWidth(spaceAvailableForTheAssets)
      }
    }
    calculateAvailableSpace()
    window.addEventListener('resize', calculateAvailableSpace)
    return () => window.removeEventListener('resize', calculateAvailableSpace)
  }, [])
  const pageSize = useMemo(() => {
    if (direction === 'v') return 1
    return (
      Math.floor(
        ((maxExpandableWidth || 0) - BaseAssetInnerPadding * 2) / BaseAssetWidth
      ) || 1
    )
  }, [direction, maxExpandableWidth])

  const Wrapper = useMemo<(p: PropsWithChildren<{}>) => ReactElement>(() => {
    if (size === 'dynamic' && direction === 'h') {
      const W = ({ children }: PropsWithChildren<{}>) => {
        return <div className={Styles.scrollable}>{children}</div>
      }
      return ({ children }) => (
        <AdaptiveContainer containerElement={W} direction='v'>
          {children as any}
        </AdaptiveContainer>
      )
    } else {
      return ({ children }) => children as ReactElement
    }
  }, [size, direction])

  const occupyAllWidth =
    mode === 'highlight' && direction === 'v' && shouldExpandOnMount
      ? Math.max(302, maxExpandableWidth!)
      : undefined

  const transparentize = useMemo(() => {
    if (version === 'v2') {
      return {
        color: '#fff',
        text: '#fff',
        contrast: 'var(--text-dark)',
      }
    }

    if (shouldExpandOnMount && gallery.predominantColor) {
      const transparentizedColor = chroma
        .hex(gallery.predominantColor)
        .mix(chroma.hex('#fff'), 0.5)

      return {
        color: transparentizedColor.hex(),
        text: transparentizedColor.luminance() <= 0.4 ? '#fff' : '#000',
        contrast: transparentizedColor.luminance() <= 0.4 ? '#0006' : '#fff6',
      }
    } else {
      return {
        color: '#fff',
        text: '#000',
        contrast: '#fff6',
      }
    }
  }, [gallery, shouldExpandOnMount])
  const intl = useMemo(() => gallery[i18n.language], [gallery])
  const { bottomContent, topContent } = useMemo(() => {
    if (gallery.type === 'cvm88' && !banner) {
      return {
        topContent: (
          <div
            ref={heroComponentRef('token-emitter-heading')}
            className={Styles.cvm88Heading}>
            <div>
              <GalleryThumbnail gallery={gallery} />
              <div>
                <TextComponent heroId={`gallery-${gallery.id}-title`} type='h3'>
                  {intl.title}
                </TextComponent>
                <TextComponent
                  heroId={`gallery-${gallery.id}-desc`}
                  type={FigmaTypo.P14}>
                  {intl.description}
                </TextComponent>
              </div>
            </div>
          </div>
        ),
        bottomContent: (
          <div
            className={Styles.tokenEmitterResume}
            ref={heroComponentRef('token-emitter-resume')}>
            <TokenEmitterResume gallery={gallery} />
          </div>
        ),
      }
    } else {
      return {
        topContent: null,
        bottomContent: (
          <>
            <GalleryThumbnail
              className={Styles.icon}
              ref={heroComponentRef('banner-icon')}
              gallery={gallery}
            />
            <div
              className={Styles.galleryInfo}
              ref={heroComponentRef('banner-info')}>
              <TextComponent
                heroId={`gallery-${gallery.id}-title`}
                type={FigmaTypo.H4}>
                {intl.title}
              </TextComponent>
              <TextComponent
                heroId={`gallery-${gallery.id}-desc`}
                type={FigmaTypo.P14}>
                {intl.description}
              </TextComponent>
            </div>
          </>
        ),
      }
    }
  }, [gallery, intl])
  return (
    <>
      <div
        className={`${Styles[version] || ''} ${Styles.root} ${
          Styles[direction]
        } ${Styles[gallery.type ?? 'default']} ${
          willExpand ? Styles.readyForTransition : ''
        } ${banner ? Styles.banner : ''} ${className}`}
        ref={itemRef}
        style={
          {
            width: occupyAllWidth,
            '--predominant-color': gallery.predominantColor,
            '--predominant-color-light': transparentize.color,
            '--text-contrast-to-gallery': transparentize.contrast,
            '--bg-image': `url("${gallery.banner}")`,
          } as any
        }>
        <div
          onClick={() => onOpenGallery(gallery)}
          className={`${Styles.gallery} ${expanded ? Styles.expanded : ''} ${
            size === 'dynamic' ? Styles.dynamic : ''
          }`}
          style={
            {
              width: occupyAllWidth,
              height: direction === 'v' ? 302 : undefined,
              '--text-default-color': transparentize.text,
            } as any
          }>
          {topContent}
          {bottomContent}
        </div>
        {maxExpandableWidth !== undefined && (
          <AdaptiveContainer direction={direction}>
            {expanded ? (
              <div
                key={'ass'}
                className={Styles.assets}
                style={{
                  minWidth: direction === 'v' ? 302 : undefined,
                  width:
                    direction === 'h' ? maxExpandableWidth : occupyAllWidth,
                  height:
                    direction === 'h'
                      ? '100%'
                      : size === 'dynamic'
                      ? undefined
                      : 355,
                }}>
                {ExpandedContent && (
                  <Wrapper>
                    <ExpandedContent
                      key='c'
                      pageSize={pageSize}
                      gallery={gallery}
                    />
                  </Wrapper>
                )}
              </div>
            ) : (
              <Fragment key={'null'} />
            )}
          </AdaptiveContainer>
        )}
      </div>
    </>
  )
}

export const GalleryThumbnail = forwardRef(
  (
    {
      gallery,
      className,
    }: {
      gallery: GalleryType
      className?: string
    },
    ref: ForwardedRef<HTMLImageElement>
  ) => {
    if (gallery.type === 'cvm88') {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { heroRef } = useHero(
        `gallery-${gallery.id}-icon`,
        {},
        { onHeroCloned: (h) => h.style.setProperty('z-index', '1') }
      )
      return (
        <img
          ref={(el) => {
            if (el) {
              if (ref) (ref as any)(el)
              ;(heroRef as any).current = el
            }
          }}
          className={className}
          src={gallery.icon}
          alt={gallery[i18n.language].title}
        />
      )
    } else {
      return (
        <img
          ref={ref}
          className={className}
          src={gallery.icon}
          alt={gallery[i18n.language].title}
        />
      )
    }
  }
)

export default GalleryCardView
