export const encodeURIParams = (params: any) => {
  let encoded = []
  let quote = window.encodeURIComponent
  for (let key in params) {
    if (params.hasOwnProperty(key)) {
      encoded.push(quote(key) + '=' + quote(params[key]))
    }
  }
  return encoded.join('&')
}
