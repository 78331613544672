import React, { useState } from 'react'
import Styles from './FAQ.module.scss'
import Container from 'openspace/components/Container/Container'
import Text from 'openspace/components/Text/Text'
import LabeledHR from 'openspace/components/LabeledHR/LabeledHR'
import { t, tO } from 'translate/i18n'
import OneText from '@onepercentio/one-ui/dist/components/Text'
import Collapsable from '@onepercentio/one-ui/dist/components/Collapsable/Collapsable'
import { AnchoredTooltipAlignment } from '@onepercentio/one-ui/dist/components/AnchoredTooltip/AnchoredTooltip'
import MutableHamburgerButton from '@onepercentio/one-ui/dist/components/MutableHamburgerButton/MutableHamburgerButton'
import { FAQTestIds } from './FAQ.e2e'

function QuestionRow(q: { question: string; answer: string }) {
  const [open, setOpen] = useState(false)
  return (
    <Collapsable
      open={open}
      onToggleOpen={setOpen}
      alignTo={AnchoredTooltipAlignment.LEFT}
      contentClassName={Styles.questionItem}
      title={
        <div
          className={`${Styles.heading} ${open ? Styles.open : ''}`}
          data-testid={FAQTestIds.QUESTION}>
          <OneText type='p24_v2'>
            <b>{q.question}</b>
          </OneText>

          <MutableHamburgerButton
            size={24}
            state={open ? 'arrow-up' : 'arrow-down'}
          />
        </div>
      }>
      <OneText type='content' className={Styles.answer}>
        {q.answer}
      </OneText>
    </Collapsable>
  )
}

/**
 * The list of faq
 **/
export default function FAQ() {
  const questions = t<
    'openspace',
    {
      question: string
      answer: string
    }[]
  >('home.sections.faq.questions', { returnObjects: true })
  return (
    <section className={Styles.root}>
      <Container>
        <Text type='title_v2' code='home.sections.faq.title' />
        <LabeledHR label={tO('home.sections.faq.description')}>
          <>
            <div className={Styles.questions}>
              {questions.map((q) => (
                <QuestionRow {...q} key={q.question} />
              ))}
            </div>
          </>
        </LabeledHR>
      </Container>
    </section>
  )
}
