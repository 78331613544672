import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) =>
  createStyles({
    container: {
      width: '302px',
      marginRight: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        width: '100%',
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
      },
    },
    divider: {
      display: 'none',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
      },
    },
    block: {
      borderRadius: 4,
      '&:not(:first-child)': {
        marginTop: theme.spacing(1),
      },
      '&:not(:last-child)': {
        marginBottom: theme.spacing(1),
      },
    },
  })

export default makeStylesWrapper(style)
