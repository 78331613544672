import { useSnackbar } from 'notistack'
import { useCallback } from 'react'

export const useCustomSnackbar = () => {
  const defaultOperations = useSnackbar()

  const enqueueClickCloseableSnackbar = useCallback<
    typeof defaultOperations['enqueueSnackbar']
  >(
    (message, config) => {
      const key = defaultOperations.enqueueSnackbar(message, {
        ...config,
        onClick: () => {
          defaultOperations.closeSnackbar(key)
        },
        style: {
          cursor: 'pointer',
        },
      })
      return key
    },
    [defaultOperations]
  )

  return {
    ...defaultOperations,
    enqueueClickCloseableSnackbar,
  }
}
