import { Box, BoxProps } from '@material-ui/core'
import Availability from 'components/Availability'
import Chip from 'components/Chip'
import { DROP_STATUS } from 'core/helpers/drop'
import { DropType } from 'core/logic/drop/drop.types'
import React from 'react'
import useStyles from './DropAvailability.style'
export interface DropAvailabilityProps {
  availability?: DROP_STATUS
  beginDate?: Date
  endDate?: Date
  issued?: number
  remaining?: number
  color?: string
  boxProps?: BoxProps
  type?: DropType['type']
  itemChipLabel?: string
}

const DropAvailability: React.FC<DropAvailabilityProps> = ({
  availability,
  beginDate,
  endDate,
  issued,
  remaining,
  color,
  boxProps,
  type,
  itemChipLabel,
}) => {
  const classes = useStyles()
  return (
    <Box
      display='flex'
      flexDirection='row'
      justifyContent={'space-between'}
      className={classes.root}
      alignItems={'center'}
      minHeight={40}
      {...boxProps}>
      <Chip
        availability={availability}
        beginDate={beginDate}
        endDate={endDate}
        color={color}
      />
      <Availability
        availability={availability}
        issued={issued}
        remaining={remaining}
        color={color}
        type={type}
      />
    </Box>
  )
}
export default DropAvailability
