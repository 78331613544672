import React from 'react'
import Styles from './Believers.module.scss'
import Container from 'openspace/components/Container/Container'
import Text from 'openspace/components/Text/Text'
import { EscapedTranslation, tO } from 'translate/i18n'
import LabeledHR from 'openspace/components/LabeledHR/LabeledHR'
import OneText from '@onepercentio/one-ui/dist/components/Text'
import StoresConfig from '../../../Stores.config'
import Section from 'openspace/components/Section/Section'
import Button from '@onepercentio/one-ui/dist/components/Button'
import Spacing from '@onepercentio/one-ui/dist/components/Spacing/Spacing'

/**
 * Our use cases
 **/
export default function Believers() {
  return (
    <section className={`${Styles.root}`}>
      <Container>
        <Text code='home.sections.believers.title' type='title_v2' />
        <LabeledHR label={tO('generic.brandMotto')}>
          <OneText type='p30_v2'>
            <EscapedTranslation code='home.sections.believers.description' />
          </OneText>
        </LabeledHR>
        <Spacing size='32' />
        <Section
          src={StoresConfig}
          jsx={{
            empty: null,
            loading: null,
            baseWidth: 344,
            factory: (b) => (
              <a
                key={b.title}
                title={b.title}
                href={b.storeLink}
                target='_blank'
                rel='noreferrer'>
                <Button className={Styles.store} variant='transparent'>
                  <img src={b.thumbnail} alt={b.title} />
                </Button>
              </a>
            ),
          }}
        />
      </Container>
    </section>
  )
}
