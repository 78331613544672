import { BaseWalletConnectionWrapper as WalletWrapper } from '@onepercentio/one-ui/dist/components/WalletConnectionWrapper'
import { WalletConnectionProps } from '@onepercentio/one-ui/dist/components/WalletConnectionWrapper/WalletConnectionWrapper'
import useCustomHistory from '@onepercentio/one-ui/dist/hooks/useCustomHistory'
import { useMarketplaceUser } from 'context/MarketplaceUser'
import { useUser } from 'core/logic/user'
import ROUTES from 'core/modules/router'
import { PropsWithChildren, useCallback, useEffect, useMemo } from 'react'
import { i18n } from 'translate/i18n'
import WalletRequiredWrapperView, {
  WalletConnectionStatusEnum,
} from './WalletRequiredWrapper.view'
import { useChain } from 'context/Chain'

function Content(
  props: PropsWithChildren<
    WalletConnectionProps & { onBack?: () => void; requiresMigration: boolean }
  >
) {
  const user = useUser()
  const { updateWallet } = useMarketplaceUser()
  const history = useCustomHistory()

  function routeToMetamaskInstall() {
    window.open('https://metamask.io/', '_blank')
  }
  function tryToConnect() {
    return props.connect()
  }

  const status = useMemo(() => {
    const hasMigrated = user.profile?.migrated

    if (!props.isProviderAvailable)
      return WalletConnectionStatusEnum.NO_PROVIDER
    else if (!props.isConnected) return WalletConnectionStatusEnum.NOT_CONNECTED
    else if (!props.isChainIdValid === undefined)
      return WalletConnectionStatusEnum.CONNECTING
    else if (props.isConnectedAndValidChain === false)
      return WalletConnectionStatusEnum.WRONG_NETWORK
    else if (
      user.profile?.wallet?.toLowerCase() === props.wallet?.toLowerCase() &&
      (props.requiresMigration ? hasMigrated : true)
    ) {
      return WalletConnectionStatusEnum.SHOW_CONTENT
    } else if (hasMigrated && props.wallet !== user.profile?.wallet) {
      return WalletConnectionStatusEnum.INVALID_WALLET
    } else if (hasMigrated === false) {
      return WalletConnectionStatusEnum.MIGRATION_REQUIRED
    } else {
      return WalletConnectionStatusEnum.CONNECTING
    }
  }, [
    props.isConnected,
    props.isConnectedAndValidChain,
    props.isProviderAvailable,
    props.wallet,
    user.profile?.wallet,
    user.profile?.internalWallet,
  ])

  useEffect(() => {
    if (
      status === WalletConnectionStatusEnum.CONNECTING &&
      props.wallet &&
      user.profile &&
      user.profile.isLoaded &&
      user.profile.wallet !== props.wallet
    ) {
      updateWallet(props.wallet)
    }
  }, [status, user.profile, user.profile?.wallet, props.wallet, updateWallet])

  return (
    <WalletRequiredWrapperView
      status={status}
      onDisconnect={props.disconnect}
      onCancel={
        props.onBack ||
        (() => history.goBackWithFallback(ROUTES.unauthenticated.homepage))
      }
      onConnectedDisplayed={() => {}}
      onDetectMetamask={() => {
        window.location.reload()
      }}
      onConnectToMetamask={
        !props.isProviderAvailable ? routeToMetamaskInstall : tryToConnect
      }
      onSwitchNetwork={props.changeChainId}
      links={{
        guideMetamask: i18n.t(`walletRequiredControl.metamaskGuideTarget`),
      }}
      onMigrate={() => history.push(ROUTES.authenticated.transferTokens)}
      connectedWallet={props.wallet}
      expectedWallet={user.profile?.wallet}>
      {props.children}
    </WalletRequiredWrapperView>
  )
}

export default function WalletRequiredWrapperLogic({
  children,
  onBack,
  allowCustody,
  requiresMigration = true,
}: PropsWithChildren<{
  onBack?: () => void
  allowCustody?: boolean
  requiresMigration?: boolean
}>) {
  const chain = useChain()
  const { profile: user } = useUser()
  // This is so the component doesn't remount all the time
  const CompWrapper = useCallback(
    (props: WalletConnectionProps) => (
      <Content
        {...props}
        onBack={onBack}
        requiresMigration={requiresMigration}
      />
    ),
    [onBack]
  )

  if (allowCustody && !user?.walletConnected) return children
  return (
    <WalletWrapper chain={chain!} Content={CompWrapper}>
      {children}
    </WalletWrapper>
  )
}
