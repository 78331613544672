import React, {
  ElementRef,
  Fragment,
  useCallback,
  useMemo,
  useRef,
} from 'react'
import {
  ClaimConfirmationMode,
  ClaimConfirmationViewProps,
} from './ClaimConfirmation.types'
import Styles from './ClaimConfirmation.module.scss'
import { FigmaTypo } from 'containers/OneUIProvider/OneUIProvider'
import Text from '@onepercentio/one-ui/dist/components/Text'
import { EscapedTranslation, t } from 'translate/i18n'
import {
  BenefitDetails,
  BenefitItem,
  BenefitStatusView,
} from '../Benefits.view'
import Button from '@onepercentio/one-ui/dist/components/Button'
import Spacing from '@onepercentio/one-ui/dist/components/Spacing'
import AdaptiveButton from '@onepercentio/one-ui/dist/components/AdaptiveButton'
import Loader from '@onepercentio/one-ui/dist/components/Loader'
import UncontrolledTransition from '@onepercentio/one-ui/dist/components/UncontrolledTransition'
import { useUser } from 'core/logic/user'
import { TransitionAnimationTypes } from '@onepercentio/one-ui/dist/components/Transition/Transition'
import Container from 'openspace/components/Container'
import IconRarum from 'components/IconRarum'
import AdaptiveContainer from '@onepercentio/one-ui/dist/components/AdaptiveContainer'
import AdaptiveDialog from '@onepercentio/one-ui/dist/components/AdaptiveDialog'
import { BenefitWithStatus } from '../Benefits.types'
import { ClaimConfirmationTestIds } from './ClaimConfirmation.e2e'

/**
 * Confirms the user wants to claim the item
 **/
export default function ClaimConfirmationView({
  benefit,
  loading,
  claimed,
  onAction: _onAction,
  onCancel: _onCancel,
  mode,
  open,
  onClosed,
  error,
}: ClaimConfirmationViewProps) {
  const benefitShowCardRef =
    useRef<ElementRef<typeof BenefitStatusView>['showCard']>(null)
  const onAction = useCallback(() => {
    if (claimed && benefitShowCardRef.current) benefitShowCardRef.current()
    _onAction()
  }, [_onAction, claimed])
  const onCancel = useCallback(() => {
    if (benefitShowCardRef.current) benefitShowCardRef.current()
    _onCancel()
  }, [_onCancel])
  const { detail } = useUser()
  const benefitInitialState = useMemo(
    () => benefit,
    [benefit.code, 'available' in benefit && benefit.available]
  )
  const { title, action, content, closeable, readOnly } = useMemo(() => {
    if (mode === ClaimConfirmationMode.READ_ONLY) {
      return {
        title: t('components.benefits.claimConfirmation.details.title'),
        action: t('generic.close'),
        content: <BenefitDetails key='details' benefit={benefit} />,
        closeable: false,
        readOnly: false,
      }
    }
    if (mode === ClaimConfirmationMode.DETAILS) {
      return {
        title: t('components.benefits.claimConfirmation.details.title'),
        action: t(
          'components.benefits.claimConfirmation.details.actions.claim'
        ),
        content: <BenefitDetails key='details' benefit={benefit} />,
        closeable: false,
        readOnly:
          ('available' in benefit && benefit.available === 0) || loading,
      }
    }
    if (claimed) {
      return {
        title: t('components.benefits.claimConfirmation.success.title'),
        action: t(
          'components.benefits.claimConfirmation.success.actions.understood'
        ),
        content: (
          <Text type={FigmaTypo.P14} key='mail'>
            {t('components.benefits.claimConfirmation.success.description', {
              email: detail!.email,
            })}
          </Text>
        ),
        closeable: true,
      }
    } else {
      return {
        title: t('components.benefits.claimConfirmation.title'),
        action: t('components.benefits.claimConfirmation.actions.claim'),
        content: (
          <Fragment key='benefit'>
            <Text type={FigmaTypo.P16}>
              <EscapedTranslation code='components.benefits.claimConfirmation.success.subtitle' />
            </Text>
            <Spacing size='32' />
            {'status' in benefit ? (
              <BenefitStatusView
                ref={(ref) => {
                  if (ref) (benefitShowCardRef as any).current = ref.showCard
                }}
                benefit={benefitInitialState as BenefitWithStatus}
              />
            ) : (
              <BenefitItem benefit={benefit} />
            )}
            <AdaptiveContainer direction='v'>
              {!loading ? (
                <Fragment key='l'></Fragment>
              ) : (
                <Fragment key='x'>
                  <Spacing size='32' />
                  <div className={Styles.alert}>
                    <IconRarum icon='alertBallon' size={24} />
                    <Text type={FigmaTypo.P12}>
                      <EscapedTranslation
                        code='components.benefits.claimConfirmation.success.pending'
                        props={{
                          email: detail!.email,
                        }}
                      />
                    </Text>
                  </div>
                </Fragment>
              )}
            </AdaptiveContainer>
          </Fragment>
        ),
        closeable: true,
      }
    }
  }, [claimed, detail, benefit, loading, mode])

  const seed = useMemo(() => Math.random(), [loading])
  return (
    <AdaptiveDialog
      className={Styles.claimConfirmation}
      open={open}
      onClose={onCancel}
      onClickOut={onCancel}
      onClosed={onClosed}>
      <div className={Styles.root}>
        <div>
          <Text type={FigmaTypo.H3}>{title}</Text>
          <Spacing size='32' />
          {mode !== undefined && (
            <UncontrolledTransition
              transitionType={TransitionAnimationTypes.FADE}>
              {content}
            </UncontrolledTransition>
          )}
          <Spacing size='32' />
          <Spacing size='16' />
          <Container
            className={`${Styles.buttons} ${closeable ? '' : Styles.single}`}>
            <Fragment key={closeable ? 'c' : 'x'}>
              {closeable && (
                <>
                  <Button variant='outline' onClick={onCancel} color='primary'>
                    {t('generic.close')}
                  </Button>
                  &nbsp;&nbsp;
                </>
              )}
              {!readOnly && (
                <AdaptiveButton
                  variant={'filled'}
                  color='primary'
                  disabled={loading}
                  onClick={onAction}
                  data-testid={ClaimConfirmationTestIds.CLAIM_BTN}>
                  {loading ? (
                    <Fragment key={'l' + seed}>
                      <Loader />
                    </Fragment>
                  ) : error ? (
                    <Fragment key={'r' + seed}>
                      {t('generic.retryBtn')}
                    </Fragment>
                  ) : (
                    <Fragment key={action + seed}>{action}</Fragment>
                  )}
                </AdaptiveButton>
              )}
            </Fragment>
          </Container>
        </div>
      </div>
    </AdaptiveDialog>
  )
}
