import { actions as countersActions } from 'core/logic/counters/counters.reducer'
import { CountersState } from 'core/logic/counters/counters.types'
import { RootState } from 'core/modules/redux'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { OfferType } from '../drop/drop.types'

export const useCounters = ({ dropIds }: { dropIds: string[] }) => {
  const dispatch = useDispatch()
  const { counters } = useSelector<RootState, CountersState>(
    ({ counters }) => counters
  )
  useEffect(() => {
    if (dropIds.length > 0) {
      dispatch(countersActions.emitterCountersSubscribe(dropIds))
      return () => {
        dispatch(countersActions.emitterCountersUnsubscribe())
      }
    }
  }, [dispatch, dropIds.join(",")])

  return { counters }
}

/** Returns how may units have been reserved */
export const useOfferUnits = (offerId: OfferType['id']) => {
  const { counters } = useCounters({ dropIds: [offerId] })
  const offerCounter = counters[offerId]

  return offerCounter
}
