import { types as AppTypes } from 'core/app'

export const actionCreator = <T, P = null>(type: T, payload?: P) => {
  return {
    type,
    payload: payload || null,
  }
}

export const adapterOK = <T = undefined>(data?: T): AppTypes.ServiceAnswerOK<T> => {
  if (data) {
    return {
      ok: true,
      data,
    }
  }
  return {
    ok: true,
  }
}

export const adapterError = (errorKey: AppTypes.ErrorKey): AppTypes.ServiceAnswerError => ({
  ok: false,
  errorKey,
})
