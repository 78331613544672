import Styles from 'openspace/assets/styles/TextStyles.module.scss'
import { MenuOptions } from 'openspace/components/TopBar/TopBar.types'
import { RarumIntlShape } from './pt'

const messages: {
  'es-ES': RarumIntlShape
} = {
  'es-ES': {
    translations: {
      flowRequirements: {
        steps: {
          personalData: {
            title: 'Datos personales',
            questions: {
              name: 'Nombre completo',
              email: 'Correo electrónico',
              phone: 'Teléfono',
              document: 'CPF',
              documentForeign: 'Documento',
              isForeign: '',
              confirm:
                'Acepto compartir mis datos con <b>({{gallery}})</b> con fines de marketing',
            },
            error: {
              invalidCPF: 'Documento inválido',
            },
          },
          address: {
            title: 'Dirección',
          },
        },
        standalone: {
          hi: 'Hola, {{name}}',
          to: {
            claim: {
              title: 'Completa el registro para continuar con el reclamo',
              action: 'Reclamar',
              loading: 'Por favor espera, estamos procediendo con el reclamo',
            },
          },
        },
      },
      forum: {
        postedAt: 'Publicado hace {{time}}',
        noItems:
          '¿Tienes preguntas? \nUtiliza el campo de arriba y sé el primero en preguntar',
        post: {
          placeholder: 'Escribe tu pregunta aquí',
          cancelConfirmation: '¿Quieres descartar el mensaje escrito?',
          loggedOutUser: 'Hola, es necesario iniciar sesión para interactuar',
          actions: {
            cancel: 'Cancelar',
            post: 'Publicar',
          },
        },
        actions: {
          reply: 'Responder',
        },
      },
      offerDetails: {
        goal: 'Meta',
        minInvestment: 'Inversión mínima',
        minInvestmentShort: 'Inv. mínima',
        investors: 'Investors',
        reachedPrice: 'Total raised',
        beTheFirstToInvest: 'Be the first to invest',
        receivable: {
          soldUnits: 'Total sold',
          availability: 'Availability',
          expiration: 'Expiration',
          sold: 'Sold',
        },
        cvm: {
          performance: '{{performance}} per month',
          types: {
            equity: 'Equity',
            fixed: 'Fixed income',
            receivable: 'Receivables',
          },
        },
        goalReached: '{{percent}}% de la meta alcanzada',
        goalReached_0: 'No hay cuotas reservadas en este momento',
        goalReached_beyond: 'Meta alcanzada',
        sold: '{{percent}}% vendido',
        sold_0: 'Sin ventas',
        sold_beyond: 'Meta alcanzada',
        limit: 'Límite',
        timeRemaining: 'TIEMPO RESTANTE',
        offer: 'OFERTA',
        preMoneyValuation: 'VALORACIÓN PREVIA',
        companyParticipation: 'participación de la empresa',
        sections: {
          ABOUT: 'Acerca de la Inversión',
          DOCUMENTS: 'Documentos',
          KPI: 'Indicadores clave de rendimiento (KPI)',
          FOUNDERS: 'Fundadores',
          FORUM: 'Foro',
        },
        forum: {
          description:
            'Accede al foro para conversar y hacer preguntas con otros inversores y expertos',
          posts: 'Publicaciones',
          actions: {
            access: 'Acceder al Foro',
          },
        },
        actions: {
          invest: 'Invertir',
          seeProfile: 'Ver perfil en {{social}}',
          moreInfo: 'Más información',
        },
      },
      components: {
        drop: {
          remaining: 'Último artículo disponible',
          remaining_plural: '{{count}} artículos restantes',
          soldout: 'Todos los artículos han sido vendidos',
        },
        benefits: {
          title: 'Beneficios',
          subtitle: 'Consulta y gestiona tus beneficios',
          claim: 'Reclamar',
          claiming: 'Reclamando beneficio',
          expired: 'Beneficio expirado',
          claimed: 'Beneficio reclamado',
          expireIn: 'Expira en:',
          days_plural: '{{count}} días',
          days: '{{count}} día',
          remaining: '{{count}} artículo disponible',
          remaining_plural: '{{count}} artículos disponible',
          claimConfirmation: {
            title: 'Reclamar beneficio',
            actions: {
              claim: 'Reclamar beneficio',
            },
            success: {
              title: 'Beneficio reclamado!',
              description:
                'Próximamente recibirá instrucciones y más información sobre este reclamo en su correo electrónico registrado {{email}}',
              actions: {
                understood: 'Entiendo',
              },
              subtitle:
                '¿Está seguro de que desea reclamar este beneficio? <br/>Esta acción no se puede deshacer.',
              pending:
                'Esta transacción puede tardar unos minutos. Una vez que se confirme el canje, recibirá instrucciones y más información en su correo electrónico registrado <b>{{email}}</b>',
            },
            details: {
              title: 'Detalle de beneficio',
              details: 'Más detalles',
              actions: {
                claim: 'Reclamar beneficio',
              },
            },
          },
        },
        form: {
          dataStatus: {
            COMPLETE: 'Completado',
            INCOMPLETE: 'Incompleto',
            PENDING: 'Pendiente',
            ERROR: 'Error',
          },
        },
        galleryCard: {
          tokenEmitter: {
            totalVolume: 'Volumen total',
            projects: '{{count}} proyecto',
            projects_plural: '{{count}} proyectos',
          },
        },
      },
      generic: {
        noItemsFound: 'No se encontraron artículos',
        brandMotto: 'Esto es raro. Este es Rarum.',
        knowMore: 'Sepa mas',
      },
      home: {
        banner: {
          v2: {
            brand: 'rarum',
            brandMotto: 'Community Engagement as a Service',
            brandBenefit: 'Valoramos lo que es raro: tu comunidad.',
            actions: {
              features: 'Descubre nuestra plataforma Whitelabel',
            },
          },
          title: `ACTIVOS DIGITALES <br/><span class="${Styles.gradient}">SUSTENTABLES Y PARA <br/>TODOS</span>`,
          subtitle:
            'Tenga un mercado de tokens personalizado para su comunidad',
          action: {
            create: '¡Crea tu tienda!',
          },
        },
        sections: {
          highlights: {
            title: 'Ofertas abiertas',
          },
          brand: {
            title: '100% Carbon Neutral',
            description: `Compensamos todas las emisiones de carbono de la plataforma y los activos negociados.

Rarum también opera en blockchains que son eficientes en sus emisiones de gases de efecto invernadero.`,
          },
          gallery: {
            heading: 'Descubre las Galerías Rarum',
            title: 'Últimas galerías',
            action: {
              seeAll: 'Ver todas las galerías',
            },
          },
          latest: {
            title: 'Tokens destacados',
            action: {
              seeAll: 'Ver todos',
            },
          },
          store: {
            title: 'Tiendas',
          },
          create: {
            title:
              '¡Crea tu propria galería o tienda y empieza a vender tus tokens!',
            actions: {
              knowMore: 'Sepa mas',
            },
            highlight: {
              create: 'Crea tus tokens',
              easy: 'Fácil de comprar y vender',
              secure: 'Seguro, transparente y carbon free',
            },
          },
          partnership: {
            title: 'Neutralidad de Carbono',
            description:
              'En nuestra búsqueda de prácticas más sostenibles, hemos reducido significativamente nuestras emisiones de carbono e implementado tecnologías blockchain de bajo impacto ambiental.',
            actions: {
              knowMore: 'Sepa mas',
            },
          },
          news: {
            title: 'Artículos',
          },
        },
      },
      allGalleries: {
        title: 'GALERÍAS',
        sections: {
          highlight: {
            title: 'Galerías destacadas',
          },
          createYourOwn: {
            title:
              'Crea tu propria galería o tienda y empieza a vender tus tokens?',
            action: {
              knowMore: 'Sepa mas',
            },
          },
        },
      },
      myCollection: {
        actions: {
          seeAllGallery: 'Mostrar todos',
          hideAllGallery: 'Volver a la lista',
        },
      },
      galleryDetails: {
        noMarket: 'Artículos de la galería',
        noAvailable: `Sigue visitando!
Luego tendremos nuevas ofertas en esta galería`,
        actions: {
          seeAllMarketplace: 'Ver todo en el mercado',
          seeAll: 'Ver todo los artículos',
        },
        expiredItems: 'Artículos cerrados',
        market: 'Artículos de mercado',
        items: 'Tokens',
      },
      topbar: {
        menus: {
          [MenuOptions.ABOUT]: 'ACERCA DE RARUM',
          [MenuOptions.FAQ]: 'FAQ',
          [MenuOptions.GALLERIES]: 'GALERÍAS',
          [MenuOptions.MARKET]: 'MERCADO',
          [MenuOptions.HOW_IT_WORKS]: 'CÓMO FUNCIONA',
          [MenuOptions.BRAND]: 'MARCAS',
          [MenuOptions.CREATORS]: 'CREADORES',
        },
        auth: {
          login: 'Iniciar sesión',
          signup: 'Crea tu cuenta',
        },
      },
    },
  },
}

export default messages
