import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  dynamicImg: {
    width: '40%',
    minWidth: '145px',
    height: '179px',
    marginRight: '10px',
    maxWidth: '250px',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'flex-end',
    '& img': {
      marginTop: '60px',
      height: '100%',
    },
    [theme.breakpoints.up('md')]: {
      height: '120px',
      padding: '0px 44px',
      '& img': {
        marginTop: 'initial',
        marginRight: '30px',
      },
    },
  },
  root: {
    minHeight: '120px',
    display: 'flex',
    backgroundColor: theme.palette.rarum?.banner.bg || theme.palette.nero,
    alignItems: 'center',
  },
  content: {
    flex: 2,
    display: 'flex',
    flexWrap: 'wrap',
    '& > :first-child': {
      marginRight: 'auto',
    },
    '& button': {
      marginLeft: '',
      minWidth: '110px',
      height: '100%',
      [theme.breakpoints.up('md')]: {
        marginLeft: '16px',
        minWidth: '213px',
      },
    },
  },
}))
