import {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import {
  AnswerAction,
  AnswerByField,
  AnswersMap,
  FormField,
  FormFieldView,
  FormMode,
  FormStep,
  INVESTOR_PROFILE,
  SerializableAnswerByField,
} from './Form.types'
import { i18n, t, tO } from 'translate/i18n'
import { formatDateOnly, parseDate } from 'core/helpers/formatter'
import { updateCVMForm, uploadCVMDocument } from 'core/modules/firebase/service'
import { UploadTask, UploadTaskSnapshot } from 'firebase/storage'
import { RarumUserProfile } from 'core/logic/user/user.types'
import { debounce } from 'lodash'
import ROUTES from 'core/modules/router'
import { Address } from 'features/Form/AddressForm/AddressForm.types'
import { useFirebase } from 'react-redux-firebase'
import { useUser } from 'core/logic/user'
import InvestmentWarning from './InvestmentWarning/InvestmentWarning'
import { OfferType } from 'core/logic/drop/drop.types'
import { TenantType } from 'core/logic/tenant/tenant.types'
import { useTenant } from 'core/logic/tenant/tenant.hook'
import { DOCUMENT_FIELD } from 'utility/fields'
import { getFlatTranslations } from 'translate/languages'

function toOptions(
  code: TranslationCodesByTenant['openspace'],
  props?: { [k: string]: string }
) {
  const values = i18n.t<'openspace', string[]>(code, {
    returnObjects: true,
    ...props,
  })
  if (!Array.isArray(values)) return []
  return values.map((p, i) => ({
    label: p,
    value: `${code}.${i}`,
  }))
}

const FORM_CONFIG: (
  offer: OfferType | undefined,
  answers: AnswersMap,
  tenant?: TenantType,
  cvmStatus?: RarumUserProfile['cvm88Status']
) => {
  [formStep in FormStep]: {
    sections: {
      title: string
      description?: string
      fields: FormField[]
    }[]
  }
} = (offer, answers, tenant, cvmStatus) => {
  const hasCVMResult = cvmStatus === 'approved' || cvmStatus === 'rejected'
  const availableOccupationOptions = toOptions(
    'cvm88.form.sections.additionalData.occupation'
  )
  const isSelectingAnotherOccupation =
    answers.occupation !== undefined &&
    answers.occupation === availableOccupationOptions[0].value

  return {
    [FormStep.PERSONAL_DATA]: {
      sections: [
        {
          title: tO('cvm88.form.sections.personalData.title'),
          fields: [
            { id: 'name', type: 'text', readonly: hasCVMResult },
            { id: 'surname', type: 'text', readonly: hasCVMResult },
            {
              ...DOCUMENT_FIELD('cpf'),
              readonly: hasCVMResult,
            },
            { id: 'rg', type: 'number' },
            { id: 'phone', type: 'phone', mask: '(99) 99999-9999' },
            {
              id: 'birthdate',
              type: 'date',
              readonly: hasCVMResult,
              validator: (date) => {
                function dateDiffInYears(dateold: Date, datenew: Date) {
                  var ynew = datenew.getFullYear()
                  var mnew = datenew.getMonth()
                  var dnew = datenew.getDate()
                  var yold = dateold.getFullYear()
                  var mold = dateold.getMonth()
                  var dold = dateold.getDate()
                  var diff = ynew - yold
                  if (mold > mnew) diff--
                  else {
                    if (mold == mnew) {
                      if (dold > dnew) diff--
                    }
                  }
                  return diff
                }
                const parsedDate = parseDate(date!)
                if (!parsedDate) return false
                const age = dateDiffInYears(parsedDate, new Date())

                if (age < 18)
                  return tO('cvm88.form.questions.errors.birthdate.underage')
                else return true
              },
            },
            { id: 'nationality', type: 'text' },
            {
              id: 'civilState',
              type: 'select',
              options: toOptions(
                'cvm88.form.sections.personalData.options.civilState'
              ),
            },
          ],
        },
      ],
    },
    [FormStep.ADDITIONAL_DATA]: {
      sections: [
        {
          title: tO('cvm88.form.sections.additionalData.title'),
          fields: [
            {
              type: 'select',
              options: availableOccupationOptions,
              id: 'occupation',
              filter: (item, term) => {
                const label = item.label as string
                return (
                  label.toLowerCase().includes(term.toLowerCase()) ||
                  item === availableOccupationOptions[0]
                )
              },
            },
            {
              type: 'text',
              id: 'otherOccupation',
              hidden: !isSelectingAnotherOccupation,
              optional: !isSelectingAnotherOccupation,
            },
          ],
        },
        {
          title: tO('cvm88.form.sections.additionalData.address'),
          fields: [
            {
              type: 'address',
              id: 'address',
            },
          ],
        },
      ],
    },
    [FormStep.DOCUMENTS]: {
      sections: [
        {
          title: tO('cvm88.form.sections.documents.title'),
          description: tO('cvm88.form.sections.documents.description'),
          fields: [
            {
              type: 'file',
              id: 'identification',
              extensions: ['jpg', 'png', 'pdf'],
              readonly: hasCVMResult,
            },
            {
              id: 'identificationType',
              type: 'radio',
              options: toOptions('cvm88.form.sections.documents.documentType'),
            },
            {
              type: 'file',
              id: 'residency',
              extensions: ['jpg', 'png', 'pdf'],
            },
          ],
        },
      ],
    },
    [FormStep.INVEST_PROFILE]: {
      sections: [
        {
          title: tO('cvm88.form.sections.investorProfile.title'),
          fields: [
            {
              type: 'currency',
              id: 'income',
            },
            {
              type: 'select',
              id: 'interest',
              options: toOptions(
                'cvm88.form.sections.investorProfile.interest'
              ),
            },
            {
              type: 'currency',
              id: 'investmentFixed',
              optional: true,
            },
            {
              type: 'currency',
              id: 'investmentVariable',
              optional: true,
            },
            {
              type: 'currency',
              id: 'investmentCrowdfunding',
              optional: true,
            },
            {
              type: 'check',
              options: toOptions(
                'cvm88.form.sections.additionalData.declaration',
                { company: tenant?.displayName! }
              ),
              id: 'declaration',
              optional: true,
            },
            {
              type: 'radio',
              id: 'otherInvestments',
              options: toOptions(
                'cvm88.form.sections.investorProfile.otherInvestments.options'
              ),
              footer: (val) =>
                !val ? (
                  <Fragment key='empty' />
                ) : (
                  <InvestmentWarning key={val} option={val as any} />
                ),
            },
          ],
        },
      ],
    },
    [FormStep.CONTRACTS]: {
      sections: [
        ...(() => {
          const investmentTypeAnswer =
            answers.otherInvestments as `cvm88.form.sections.investorProfile.otherInvestments.options.${
              | 0
              | 1
              | 2}`
          const investorType =
            investmentTypeAnswer ===
            'cvm88.form.sections.investorProfile.otherInvestments.options.0'
              ? INVESTOR_PROFILE.RESTRICTED
              : investmentTypeAnswer ===
                'cvm88.form.sections.investorProfile.otherInvestments.options.1'
              ? INVESTOR_PROFILE.LIMITED
              : INVESTOR_PROFILE.UNLIMITED

          return [
            {
              title: tO('cvm88.form.sections.investmentAmount.period.title'),
              description: tO(
                'cvm88.form.sections.investmentAmount.period.description'
              ),
              fields: [
                {
                  id: `checkRestrictions:${INVESTOR_PROFILE[investorType]}`,
                  type: 'rawcheck',
                  options: [
                    {
                      header: {
                        title: tO('cvm88.form.sections.investorProfile.title'),
                        description: tO(
                          `cvm88.form.sections.investmentAmount.period.checks.${INVESTOR_PROFILE[investorType]}`,
                          {
                            offerName: offer?.[i18n.language].title,
                          }
                        ),
                      },
                      value: `cvm88.form.sections.investmentAmount.period.checks.${INVESTOR_PROFILE[investorType]}`,
                      label: tO(
                        'cvm88.form.sections.investmentAmount.period.checks.actions.investorProfileCheck'
                      ),
                    },
                    {
                      value: tO(
                        'cvm88.form.sections.investmentAmount.period.checks.willUpdate',
                        { company: tenant?.displayName }
                      ),
                      label: tO(
                        'cvm88.form.sections.investmentAmount.period.checks.willUpdate',
                        { company: tenant?.displayName }
                      ),
                    },
                  ],
                  validator: (_val) => {
                    const checks = (_val as unknown as boolean[]) || []
                    return !!checks[0] && !!checks[1]
                      ? true
                      : tO(
                          'cvm88.form.sections.investmentAmount.error.bothChecks'
                        )
                  },
                } as FormField & { type: 'rawcheck' },
              ],
            },
          ]
        })(),
        {
          title: tO('cvm88.form.sections.investmentAmount.contracts'),
          description: tO(
            'cvm88.form.sections.investmentAmount.contractsDescription'
          ),
          fields: [
            {
              id: 'investmentContract',
              type: 'accept',
              accept: [
                {
                  label: tO(
                    'cvm88.form.sections.investmentAmount.acceptances.investmentTerm'
                  ),
                },
              ],
              link: {
                type: 'file',
                to: offer?.cvm88.contractFile!,
              },
            },
            {
              id: 'risk',
              type: 'accept',
              accept: [
                {
                  label: tO(
                    'cvm88.form.sections.investmentAmount.acceptances.risk'
                  ),
                },
              ],
              link: {
                type: 'route',
                to: ROUTES.unauthenticated.risk,
              },
            },
            {
              id: 'usageTerms',
              type: 'accept',
              accept: [
                {
                  label: tO(
                    'cvm88.form.sections.investmentAmount.acceptances.usageTerms'
                  ),
                },
                {
                  label: tO(
                    'cvm88.form.sections.investmentAmount.acceptances.marketing'
                  ),
                  optional: true,
                },
              ],
              link: {
                type: 'route',
                to: ROUTES.unauthenticated.terms,
              },
            },
          ],
        },
      ],
    },
  }
}

export function useCVMForm(
  answers: AnswersMap,
  offer?: OfferType,
  cvmStatus?: RarumUserProfile['cvm88Status']
) {
  const { tenant } = useTenant()
  const formConfig = useMemo(
    () => FORM_CONFIG(offer, answers, tenant!, cvmStatus),
    [answers]
  )
  function getQuestionsForStep(stepCode: FormStep) {
    const stepConfig = formConfig[stepCode]
    return stepConfig.sections.reduce(
      (acc, section) => [...acc, ...section.fields],
      [] as FormField[]
    )
  }

  return {
    formConfig,
    getQuestionsForStep,
  }
}

const ADDRESS_KEYS = [
  'cep',
  'address',
  'city',
  'neighbourhood',
  'state',
  'addressNumber',
] as const
const PERSONAL_DATA_KEYS = ['name', 'surname', 'cpf'] as const

export function useForm(
  offer: OfferType,
  step: FormStep,
  savedAnswers: AnswersMap,
  mode: FormMode,
  autoSave: boolean = true,
  cvmStatus?: RarumUserProfile['cvm88Status']
) {
  const { profile } = useUser()
  const { updateProfile } = useFirebase()
  const [answers, setAnswers] = useState<AnswersMap>(() => {
    const formConfig = FORM_CONFIG(offer, {}, undefined)
    const translations = getFlatTranslations('pt-BR')
    for (const _step in formConfig) {
      const step = _step as unknown as FormStep
      for (const section of formConfig[step].sections) {
        const enumBasedQuestions: (FormField & {
          type: 'check' | 'rawcheck' | 'select'
        })[] = section.fields.filter((field) => 'options' in field) as any
        for (const question of enumBasedQuestions) {
          const answer = savedAnswers[question.id]
          if (answer !== undefined)
            for (let questionOption of question.options) {
              const translatedOption = translations[questionOption.value as any]
              if (translatedOption === answer) {
                savedAnswers[question.id] = questionOption.value
              }
            }
        }
      }
    }

    const clone = {
      ...savedAnswers,
    }
    if (mode === FormMode.READ_ONLY) return clone

    const p =
      profile!.personalData ||
      ({} as NonNullable<RarumUserProfile['personalData']>)
    for (let key of PERSONAL_DATA_KEYS) clone[key] = clone[key] || p[key]
    if (p.cep) {
      if (!clone.address) clone.address = [{}, true] as any
      const address = clone.address![0] as any
      for (let key of ADDRESS_KEYS) address[key] = address[key] || p[key]
    }

    return clone
  })
  const { formConfig, getQuestionsForStep } = useCVMForm(
    answers,
    offer,
    cvmStatus
  )
  const currSections = useMemo(() => {
    const stepConfig = formConfig[step]
    const sections = stepConfig.sections.map(
      (section) => ({
        title: section.title,
        description: section.description,
        questions: section.fields.map((q) => ({
          ...q,
          title: tO(`cvm88.form.questions.${q.id as 'name'}`),
        })),
      }),
      [] as FormField[]
    )
    return sections
  }, [step, answers])

  const currentQuestions = useMemo(() => {
    return currSections.reduce(
      (acc, section) => [...acc, ...section.questions],
      [] as FormField[]
    )
  }, [currSections])

  const { isValid: isQuestionsAnswered, errorsMap } = useMemo(() => {
    return areAllQuestionsAnswered(currentQuestions, answers)
  }, [answers, currentQuestions])

  const onAnswerAction = <T extends FormField['type']>(
    questionType: T,
    id: string,
    answer:
      | AnswerAction<{
          type: T
        }>
      | undefined
  ) => {
    switch (questionType) {
      case 'file':
        setAnswers((prev: any) => {
          const file = answer as File | undefined
          if (file)
            return {
              ...prev,
              [id]: uploadCVMDocument(id as 'document', file),
            }
          delete prev[id]
          return { ...prev }
        })
        break
      default:
        setAnswers((prev) => ({
          ...prev,
          [id]: answer as string,
        }))
    }
  }

  const isFilesUploaded = useFileUploads(currentQuestions, answers)

  const _persistFormAnswers = useCallback<
    (
      formStep: FormStep,
      questions: FormField[],
      answers: Partial<{
        [questionId: string]: AnswerByField<any>
      }>,
      status?: RarumUserProfile['cvm88Status']
    ) => void
  >(
    (step, questions, answers, status) => {
      const serializable = serializeAnswersFor(questions, answers as any)
      updateCVMForm(
        FormStep[step] as keyof typeof FormStep,
        serializable,
        offer?.id!,
        status
      )
    },
    [step]
  )

  const debouncedStepUpdate = useMemo(
    () => debounce(_persistFormAnswers, 2000),
    [_persistFormAnswers]
  )
  useEffect(() => {
    if (autoSave) debouncedStepUpdate(step, currentQuestions, answers)
  }, [answers, step, autoSave])

  useEffect(() => () => debouncedStepUpdate.flush(), [debouncedStepUpdate])

  const [identification, residency] = [
    answers.identification,
    answers.residency,
  ] as unknown as AnswerByField<{
    type: 'file'
  }>[]
  const [initialIdentification, initialResidency] = useMemo(
    () =>
      [answers.identification, answers.residency] as unknown as AnswerByField<{
        type: 'file'
      }>[],
    [step]
  )
  const changedFile = initialIdentification !== identification

  useEffect(() => {
    if (step === FormStep.DOCUMENTS && changedFile) {
      _persistFormAnswers(step, currentQuestions, answers, 'pending')
    }
  }, [changedFile])

  const updateProfileWithPersonalData = useCallback(() => {
    const address = answers.address![0] as unknown as Address
    switch (step) {
      case FormStep.PERSONAL_DATA:
        return updateProfile({
          personalData: {
            name: answers.name,
            surname: answers.surname,
            cpf: answers.cpf!.replace(/[^0-9]/g, ''),
          },
        })
      case FormStep.ADDITIONAL_DATA:
        return updateProfile({
          personalData: {
            ...address,
          },
        })
      default:
        throw new Error(
          `There is no profile data to be saved at step ${FormStep[step]}`
        )
    }
  }, [answers, updateProfile])

  return {
    answers,
    formConfig,
    questionsForStep: getQuestionsForStep,
    sections: currSections,
    isQuestionsAnswered,
    errorsMap,
    isFilesUploaded,
    onAnswerAction,
    persistFormAnswers: _persistFormAnswers,
    questions: currentQuestions,
    persistPersonalData: updateProfileWithPersonalData,
  }
}

function useFileUploads(questions: FormField[], answers: AnswersMap) {
  const fileUploadQuestions = useMemo(
    () => questions.filter((a) => a.type === 'file'),
    [questions]
  )
  const [fileUploadStatus, setFileUploadStatus] = useState<{
    [questionID: string]: UploadTaskSnapshot['state']
  }>({})
  useLayoutEffect(() => {
    const unsubs: ReturnType<UploadTask['on']>[] = []
    for (let fileQuestion of fileUploadQuestions) {
      const uploadTask = answers[fileQuestion.id] as
        | AnswerByField<{
            type: 'file'
          }>
        | undefined

      if (!uploadTask)
        setFileUploadStatus((prev) => ({
          ...prev,
          [fileQuestion.id]: 'running',
        }))
      else if (typeof uploadTask === 'boolean') {
        setFileUploadStatus((prev) => ({
          ...prev,
          [fileQuestion.id]: 'success',
        }))
      } else
        unsubs.push(
          uploadTask.on('state_changed', (r) => {
            setFileUploadStatus((prev) => ({
              ...prev,
              [fileQuestion.id]:
                r.totalBytes === r.bytesTransferred ? 'success' : 'running',
            }))
          })
        )
    }

    return () => {
      unsubs.forEach((unsub) => unsub())
    }
  }, [answers, fileUploadQuestions])

  const areAllFileUploaded = useMemo(() => {
    return fileUploadQuestions.reduce((r, question) => {
      const uploadStatus = fileUploadStatus[question.id]
      return r && uploadStatus === 'success'
    }, true)
  }, [fileUploadStatus, fileUploadQuestions])

  return areAllFileUploaded
}

export function useFieldErrors<
  Q extends Readonly<
    Pick<FormField, 'type' | 'id' | 'optional' | 'validator'>[]
  >
>(currentQuestions: Q, answers: AnswersMap) {
  const errorMap = useMemo(() => {
    const ans = <T extends FormFieldView['type']>(
      question: Pick<FormField, 'id'> & {
        type: T
      }
    ) =>
      answers[question.id] as unknown as AnswerByField<{
        type: T
      }>
    const errorsMap: {
      [questionId: string]: string
    } = {}
    for (let question of currentQuestions) {
      const isValidated = () => {
        const _answer = ans(question)
        const answer = Array.isArray(_answer) ? _answer[0] : _answer
        if (!answer && !question.optional)
          return {
            isValid: false,
            error: t('generic.fieldRequired'),
          }
        if (!question.validator)
          if (Array.isArray(_answer)) {
            const [_, isValid, error] = _answer
            return {
              isValid,
              error,
            }
          } else {
            return {
              isValid: true,
              error: '',
            }
          }
        const validationResult = question.validator(ans(question))
        if (typeof validationResult === 'string')
          return {
            isValid: false,
            error: validationResult,
          }
        return {
          isValid: validationResult,
          error: '',
        }
      }

      const validation = isValidated()
      errorsMap[question.id] = validation.error
      switch (question.type) {
        case 'check':
        case 'rawcheck':
          if (question.validator)
            errorsMap[question.id] = question.validator(
              ans(question) as any
            ) as string
          break
        case 'currency':
          const validation = isValidated()
          if (validation.error) errorsMap[question.id] = validation.error
          break
        case 'date':
          const providedDate = ans(question) || ''
          const parsedDate = parseDate(providedDate)
          if (!parsedDate) {
            errorsMap[question.id] = t('generic.invalidDate')
          } else {
            const dateValidation = isValidated()
            if (dateValidation.error)
              errorsMap[question.id] = dateValidation.error
          }
          break
        case 'number':
        case 'text':
        case 'select':
        case 'radio':
        case 'file':
          const validationResult = isValidated()
          if (validationResult.error)
            errorsMap[question.id] = validationResult.error
      }
    }

    return errorsMap
  }, [currentQuestions, answers])

  return errorMap as { [k in Q[number]['id']]: string }
}

export function areAllQuestionsAnswered(
  currentQuestions: FormField[],
  answers: AnswersMap
) {
  const errorsMap: {
    [questionId: string]: string
  } = {}
  const isValid = currentQuestions.reduce((answeredAll, question) => {
    const ans = <T extends FormFieldView['type']>(
      question: FormField & {
        type: T
      }
    ) =>
      answers[question.id] as unknown as AnswerByField<{
        type: T
      }>

    const currencyAnswer = () => {
      const amount = ans(question)?.replace(/[^0-9]/g, '') || 0
      const decimal = Number(amount) / 100

      return decimal
    }

    const isValidated = () => {
      const _answer = ans(question)
      const answer = Array.isArray(_answer) ? _answer[0] : _answer
      if (question.type === 'currency')
        if (!question.optional && currencyAnswer() < 0.01)
          return {
            isValid: false,
            error: t('generic.fieldRequired'),
          }
      if (!answer && !question.optional)
        return {
          isValid: false,
          error: t('generic.fieldRequired'),
        }
      if (!question.validator)
        if (Array.isArray(_answer)) {
          const [_, isValid, error] = _answer
          return {
            isValid,
            error,
          }
        } else {
          return {
            isValid: true,
            error: '',
          }
        }
      const validationResult = question.validator(ans(question))
      if (typeof validationResult === 'string')
        return {
          isValid: false,
          error: validationResult,
        }
      return {
        isValid: validationResult,
        error: '',
      }
    }

    const validation = isValidated()
    errorsMap[question.id] = validation.error

    const result =
      answeredAll &&
      (() => {
        switch (question.type) {
          case 'accept':
            const checks = ans(question) || []
            return question.accept.reduce(
              (r, { optional }, idx) => r && (optional ? true : checks[idx]),
              true
            )
          case 'address':
            return ans(question)?.[1]
          case 'check':
          case 'rawcheck':
            if (question.optional) return true
            if (question.validator)
              return question.validator(ans(question) as any) as boolean
            const checkmarks = ans(question) || []
            return checkmarks.includes(true)
          case 'currency':
            if (!question.optional && currencyAnswer() < 0.01) return false
            const validation = isValidated()
            if (validation.error) errorsMap[question.id] = validation.error
            return validation.isValid
          case 'date':
            const providedDate = ans(question) || ''
            const parsedDate = parseDate(providedDate)
            if (!parsedDate) return false
            const dateValidation = isValidated()
            if (dateValidation.error)
              errorsMap[question.id] = dateValidation.error
            try {
              return (
                formatDateOnly(parsedDate, 'pt-BR') === providedDate &&
                dateValidation.isValid
              )
            } catch (e) {
              return false
            }
          case 'phone':
            return !!answers[question.id] && !!ans(question)[1] && answeredAll
          case 'number':
          case 'text':
          case 'select':
          case 'radio':
          case 'file':
            if (!answers[question.id]) return question.optional
            const validationResult = isValidated()
            if (validationResult.error)
              errorsMap[question.id] = validationResult.error
            return validationResult.isValid && answeredAll
          default:
            return false
        }
      })()
    return result
  }, true)

  return { isValid: !!isValid, errorsMap }
}

export function serializeAnswersFor(
  questions: FormField[],
  answersMap: AnswersMap
) {
  return questions.reduce(
    (r, question) => {
      if (!answersMap[question.id]) delete r[question.id]
      else
        switch (question.type) {
          case 'check':
            const checkedOptions =
              (answersMap[question.id] as boolean[] | undefined) || []
            /** The Array.from provides a way to deal with empty slot in arrays */
            r[question.id] = Array.from(checkedOptions).map((a) =>
              a ? true : false
            )
            break
          case 'file':
            const possibleUpload = answersMap[question.id] as
              | UploadTask
              | boolean
              | undefined
            r[question.id] =
              possibleUpload &&
              (typeof possibleUpload === 'boolean'
                ? true
                : possibleUpload.snapshot.bytesTransferred ===
                  possibleUpload.snapshot.totalBytes)
            break
          default:
            const translations = getFlatTranslations('pt-BR')
            const answer = answersMap[question.id] as keyof typeof translations
            if (typeof answer === 'string') {
              r[question.id] = translations[answer] || answer
            } else {
              r[question.id] = answer
            }
            break
        }
      return r
    },
    {} as Partial<{
      [questionId: string]: SerializableAnswerByField<any>
    }>
  )
}
