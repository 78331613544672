import { builder } from 'core/helpers'
import { LOGOUT } from '../user/user.reducer'
import * as TokenTypes from './token.types'

// == TYPES ==
const BALANCES_OF_NFT = 'token/balanceOfNft/BATCH'
const BALANCE_OF_ERC20 = 'token/balanceOfErc20'

export const types = Object.freeze({
  BALANCES_OF_NFT,
  BALANCE_OF_ERC20,
})

// == ACTIONS ==
const balancesOfNft = (nftBalances: TokenTypes.TokenNftBalance) =>
  builder.actionCreator<
    TokenTypes.TokenActionsTypes,
    TokenTypes.TokenNftBalance
  >(BALANCES_OF_NFT, nftBalances)

const balanceOfErc20 = (erc20Balance: TokenTypes.TokenErc20Balance) =>
  builder.actionCreator<
    TokenTypes.TokenActionsTypes,
    TokenTypes.TokenErc20Balance
  >(BALANCE_OF_ERC20, erc20Balance)

export const actions = Object.freeze({
  balancesOfNft,
  balanceOfErc20,
})

export const mapActions = Object.freeze({
  [BALANCES_OF_NFT]: balancesOfNft,
  [BALANCE_OF_ERC20]: balanceOfErc20,
})

// == REDUCER ==
export const initialState: Readonly<TokenTypes.TokenState> = Object.freeze({
  isLoading: true,
  nftBalances: {},
  erc20Balance: null,
})

export const reducer = (
  state: Readonly<TokenTypes.TokenState> = initialState,
  action: Readonly<TokenTypes.TokenAction>
): TokenTypes.TokenState => {
  const { type } = action
  switch (type) {
    case BALANCES_OF_NFT:
      return {
        ...state,
        nftBalances: action.payload as TokenTypes.TokenNftBalance,
        isLoading: false,
      }
    case BALANCE_OF_ERC20:
      return {
        ...state,
        erc20Balance: action.payload as TokenTypes.TokenErc20Balance,
        isLoading: false,
      }
    case LOGOUT:
      return initialState;
    default:
      return state
  }
}
