import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      borderRadius: 4,
      minHeight: 50,
    },
    icon: {
      flex: '0 0 auto',
    },
    text: {
      marginLeft: 10,
      whiteSpace: 'nowrap',
      flex: '0 1 auto',
    },
    figure: {
      borderRadius: 4,
      flex: '0 0 55px',
      margin: '0 0 0 5px',
      '& > img': {
        width: 50,
        display: 'block',
        borderRadius: 4,
      },
    },
  })

export default makeStylesWrapper(style)
