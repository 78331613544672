import { makeStyles, Theme } from '@material-ui/core'

export default makeStyles<
  Theme,
  {
    keepProportions?: boolean
  }
>((theme: Theme) => ({
  figure: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    alignItems: ({ keepProportions }) =>
      keepProportions ? 'flex-start' : undefined,
  },
  image: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    width: '100%',
  },
  imageHor: {
    paddingBottom: '100%',
  },
  imageVert: {
    paddingBottom: '147.5%',
  },
  skeleton: {
    width: '100%',
    height: 'initial',
  },
  darkSkeleton: {
    backgroundColor: 'rgba(13, 13, 13, 0.9)',
  },
  liveBadge: {
    position: 'absolute',
    top: theme.spacing(2),
    left: 0,
    zIndex: 1,
  },
  imageRadius: {
    borderRadius: 10,
  },
  overlay: {
    position: 'absolute',
    borderRadius: 8,
  },
  imageOriginalProportion: {
    maxWidth: '100%',
  },
}))
