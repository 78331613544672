import { OfferBidType, OfferType } from 'core/logic/drop/drop.types'
import { useTenant } from 'core/logic/tenant/tenant.hook'
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import OffChainAuctionFacade from 'service/IAuctionFacade/OffChainAuctionFacade'
import OnChainAuctionFacade from 'service/IAuctionFacade/OnChainAuctionFacade'
import IAuctionFacade from 'service/IAuctionFacade'
import useAsyncControl from '@onepercentio/one-ui/dist/hooks/useAsyncControl'
import { useWallet } from './Wallet'
import useWeb3Provider from 'hooks/useWeb3Provider'
import { useChain } from './Chain'

export type AuctionContextShape = IAuctionFacade | null
export const AuctionContext = createContext<AuctionContextShape>(
  undefined as any
)

/**
 * This provides the data related to the auction interface
 */
export function AuctionProvider({
  offer,
  children,
}: PropsWithChildren<{ offer?: OfferType }>) {
  const readWeb3 = useWeb3Provider(true)
  const writeWeb3 = useWeb3Provider(false)
  const upperFacade = useAuction()
  const chain = useChain()
  const { tenant } = useTenant()
  const { wallet } = useWallet()
  const facade = useMemo<null | IAuctionFacade>(() => {
    if (!offer || !tenant || !readWeb3 || !writeWeb3) return null
    if (
      process.env.AUTOMATION &&
      (upperFacade === null || (upperFacade && upperFacade.offerType === offer))
    )
      return upperFacade
    switch (offer.type) {
      case 'off_chain_auction':
        return new OffChainAuctionFacade(offer, tenant, readWeb3, writeWeb3)
      case 'auction':
        return new OnChainAuctionFacade(offer, tenant!.auction!.polygon, readWeb3, writeWeb3)
      default:
        return null
    }
  }, [offer, tenant, upperFacade, readWeb3, writeWeb3])

  useEffect(() => {
    if (facade instanceof OnChainAuctionFacade) facade.setWallet(wallet!)
  }, [wallet, facade])

  useEffect(() =>{
    if (chain && facade) facade.setGasFeeEndpoint(chain.gasFeeEndpoint)
  }, [chain, facade])

  return (
    <AuctionContext.Provider value={facade}>{children}</AuctionContext.Provider>
  )
}

export function useAuction() {
  return useContext(AuctionContext)
}

export function useBids() {
  const facade = useAuction()
  const [bids, setBids] = useState<OfferBidType[]>()

  useEffect(() => {
    if (facade) return facade.watchBids(setBids)
  }, [facade])

  return bids
}

export function useBidInformation() {
  const auction = useAuction()!
  const { getInformation, loading, error } = useAsyncControl({
    getInformation: (...args: any[]) => auction?.getInformation(...args),
    getBalance: () => auction?.getBalance(),
  })
  const [information, setInformation] =
    useState<Awaited<ReturnType<typeof getInformation>>>()

  useEffect(() => {
    getInformation().then(setInformation)
  }, [auction])

  return { information, loading, error }
}

export function useBalanceForBid() {
  const auction = useAuction()!
  const [availableBalance, setAvailableBalance] = useState<number>()
  const { information } = useBidInformation()
  const { loading, error, getBalance } = useAsyncControl({
    getBalance: () => auction.getBalance(),
  })
  useEffect(() => {
    if (information) getBalance().then(setAvailableBalance)
  }, [information])

  return { availableBalance, loading, error }
}

export function useAuctionPeriod() {
  const auctionFacade = useAuction()

  const [auctionPeriod, setAuctionDay] = useState<[Date, Date]>()
  useEffect(() => {
    if (auctionFacade)
      auctionFacade.getAuctionPeriod().then((period) => {
        if (period) setAuctionDay(period)
      })
  }, [auctionFacade])
  return auctionPeriod
}
