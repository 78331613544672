import { RootState } from 'core/modules/redux'
import React from 'react'
import { useSelector } from 'react-redux'
import { TenantState } from './tenant.types'

export const useTenant = () => {
  const { tenant } = useSelector<RootState, TenantState>(({ tenant }) => tenant)
  const hasWallet = useSelector<RootState, boolean>(({ tenant }) => Boolean(tenant?.tenant?.paymentProviders?.wallet?.active))
  const hasCoinbase = useSelector<RootState, boolean>(({ tenant }) => Boolean(tenant?.tenant?.paymentProviders?.coinbase?.active))
  const hasJuno = useSelector<RootState, boolean>(({ tenant }) => Boolean(tenant?.tenant?.paymentProviders?.juno?.active))
  const hasStripe = useSelector<RootState, boolean>(({ tenant }) => Boolean(tenant?.tenant?.paymentProviders?.stripe?.active))

  const toUserFriendlyLng = React.useCallback((languageISO: string) => languageISO.substring(0, languageISO.indexOf('-')).toLocaleUpperCase(), [])

  return { tenant, hasWallet, hasCoinbase, hasJuno, hasStripe, toUserFriendlyLng }
}
