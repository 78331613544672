import { AvailableConcepts } from 'openspace/pages/Authenticated/Home/v2/Home/Features/Features'
import { CreatorsSection } from './ForCreators.hook'

export const ForCreatorsCoreModules: {
  [section in CreatorsSection]: {
    [id: string]: AvailableConcepts[]
  }
} = {
  [CreatorsSection.BRAND]: {
    loyalty: ['loyalty_coins', 'marketplace'],
    nftGames: ['gamification', 'marketplace'],
    certificate: ['digital_twins', 'membership'],
  },
  [CreatorsSection.CREATOR]: {
    web3FanClub: ['marketplace', 'membership'],
    creator: ['shared_ownership', 'marketplace'],
  },
}
