import React, { Fragment, ReactElement, useMemo } from 'react'
import { Challenge } from 'core/logic/challenges/challenges.types'
import Container from 'openspace/components/Container'
import Text from 'openspace/components/Text'
import Styles from './ChallengeDetailsBanner.module.scss'
import Spacing from '@onepercentio/one-ui/dist/components/Spacing'
import Button from '@onepercentio/one-ui/dist/components/Button'
import { t } from 'translate/i18n'
import ShimmerSVG from 'openspace/assets/svg/shimmer.svg'
import Loader from '@onepercentio/one-ui/dist/components/Loader'
import useBreakpoint from '@onepercentio/one-ui/dist/hooks/ui/useBreakpoint'
import {
  ChallengeDetailsBannerViewProps,
  ChallengeDetailsMode,
} from './ChallengeDetailsBanner.types'
import useHero from '@onepercentio/one-ui/dist/hooks/useHero'
import { useChallengeClaimState } from 'context/Challenge'
import { ChallengeStateView as ChallengeState } from 'openspace/pages/Unauthenticated/ChallengeClaim/ChallengeClaim.view'
import { ChallengeDetailsBannerTestIds } from './ChallengeDetailsBanner.e2e'

const emptySpaces = (size: number, scale: number) =>
  new Array(size * scale)
    .fill(undefined)
    .map((_, i) => <Fragment key={i}>&nbsp;</Fragment>)

const ChallengeDetailsBannerView = ({
  ...props
}: ChallengeDetailsBannerViewProps) => {
  const { heroRef, heroComponentRef } = useHero(
    `challenge_${'challenge' in props ? props.challenge.id : props.status}`
  )
  const isSm = useBreakpoint(400)
  const anyP = props as any
  const content = useMemo(() => {
    if ('status' in props) {
      const scale = isSm ? 2 : 3
      const isLoading = props.status === 'loading'
      const baseClass = isLoading ? Styles.shimmer : Styles.error
      const bg = isLoading ? `url(${ShimmerSVG})` : undefined
      return {
        image: (
          <img
            alt={''}
            src={
              isLoading
                ? ShimmerSVG
                : 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
            }
            className={baseClass}
          />
        ),
        title: (
          <Text
            type='h2'
            className={baseClass}
            style={{
              background: bg,
            }}>
            {isLoading ? emptySpaces(10, scale) : t('generic.errorMsg')}
          </Text>
        ),
        description: (
          <Text type='p16' className={baseClass} style={{ background: bg }}>
            {emptySpaces(25, scale)}
          </Text>
        ),
        btnLabel:
          props.status === 'loading' ? <Loader /> : t('generic.retryBtn'),
      }
    } else {
      const { challenge } = props
      return {
        image: <img alt={challenge.title} src={challenge.cover} />,
        title: <Text type='h2'>{challenge.title}</Text>,
        description: <Text type='p16'>{challenge.description}</Text>,
        btnLabel:
          props.mode === ChallengeDetailsMode.INFO
            ? t('features.challenge.claim.confirmation.actions.goToClaim')
            : t('generic.knowMore'),
      }
    }
  }, [anyP.challenge, anyP.challenge, anyP.error, anyP.status, anyP.onRetry])

  return (
    <Container
      className={`${Styles.root} dark`}
      ref={heroRef}
      data-testid={
        'status' in props
          ? props.status === 'loading'
            ? ChallengeDetailsBannerTestIds.PLACEHOLDER
            : ChallengeDetailsBannerTestIds.ERROR
          : ChallengeDetailsBannerTestIds.CHALLENGE
      }>
      <div className={Styles.wrapper}>
        {content.image}
        <div>
          {content.title}
          <Spacing size='16' />
          {content.description}
          <Spacing size='40' />
          {'mode' in props && props.mode === ChallengeDetailsMode.BANNER ? (
            <Button
              ref={heroComponentRef('action') as any}
              variant='highlight'
              disabled={!props.onAction}
              onClick={props.onAction}>
              {content.btnLabel}
            </Button>
          ) : 'challenge' in props ? (
            <div ref={heroComponentRef('claim-state')}>
              <ChallengeClaimState
                challenge={props.challenge}
                onAction={props.onAction}
              />
            </div>
          ) : (
            <Button
              ref={heroComponentRef('action') as any}
              variant='highlight'
              disabled={!props.onAction}
              onClick={props.onAction}>
              {content.btnLabel}
            </Button>
          )}
        </div>
      </div>
    </Container>
  )
}

function ChallengeClaimState({
  challenge,
  onAction,
}: { challenge: Challenge } & Pick<
  ChallengeDetailsBannerViewProps,
  'onAction'
>) {
  const challengeClaimState = useChallengeClaimState(challenge)

  const {
    btn: { label, variant },
  } = useMemo<{
    btn: {
      label: string | ReactElement
      variant: OnepercentUtility.UIElements.ButtonVariants
    }
  }>(() => {
    if (challengeClaimState === 'loading')
      return { btn: { label: <Loader />, variant: 'outline' } }
    if (challengeClaimState === 'claimable')
      return {
        btn: {
          label: t('features.challenge.claim.confirmation.actions.goToClaim'),
          variant: 'highlight',
        },
      }
    else
      return {
        btn: {
          label: t('features.challenge.claim.confirmation.actions.viewDetails'),
          variant: 'outline',
        },
      }
  }, [challengeClaimState])

  return (
    <>
      <ChallengeState
        state={
          challengeClaimState !== 'claimable'
            ? 'unavailable'
            : challengeClaimState
        }
      />
      <Spacing size='16' />
      <Button variant={variant} onClick={onAction}>
        {label}
      </Button>
    </>
  )
}

export default ChallengeDetailsBannerView
