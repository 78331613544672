import React from 'react'
import View from './AssetsList.view'
import { AssetsListProps } from './AssetsList.types'
import { useGalleryDrops } from 'context/Gallery'

export default function AssetsListLogic({
  gallery,
  pageSize,
}: AssetsListProps) {
  const { active } = useGalleryDrops(gallery.id, pageSize)
  return <View src={active} />
}
