import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const EXTRA_SMALL_PAD = 1
const SMALL_PAD = 3
const MEDIUM_PAD = 7
const LARGE_PAD = 10

const style = (theme: Theme) =>
  createStyles({
    icon: {
      position: 'absolute',
      transform: 'translateY(-50%)',
      top: '0px',
      height: '250px',
      [theme.breakpoints.down(768)]: {
        '& + div': {
          marginTop: '60px',
        },
      },
    },
    footer: {
      backgroundColor:
        theme.palette.rarum?.footer.bgColor || theme.palette.primary.dark,
      paddingTop: theme.spacing(MEDIUM_PAD),
      paddingBottom: theme.spacing(MEDIUM_PAD),
      flexShrink: 0,
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      marginTop: 'auto',
    },
    logoWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: theme.spacing(4.5),
      zIndex: 1,
      [theme.breakpoints.up('md')]: {
        alignItems: 'flex-start',
      },
    },
    rarumText: {
      textTransform: 'uppercase',
      letterSpacing: 1,
      fontWeight: 500,
      fontSize: '0.75rem',
      marginTop: theme.spacing(2),
    },
    text: {
      color: theme.palette.grey[200],
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
        textAlign: 'left',
      },
    },
    companyItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: theme.spacing(6),
      [theme.breakpoints.up('md')]: {
        alignItems: 'flex-start',
      },
      [theme.breakpoints.up('lg')]: {
        marginBottom: 0,
      },
    },
    rarumItem: {
      marginLeft: theme.spacing(SMALL_PAD),
      marginRight: theme.spacing(SMALL_PAD),
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(MEDIUM_PAD),
        marginRight: theme.spacing(SMALL_PAD),
      },
      [theme.breakpoints.up('lg')]: {
        marginLeft: theme.spacing(LARGE_PAD),
        marginRight: theme.spacing(EXTRA_SMALL_PAD),
      },
    },
    clientItem: {
      marginLeft: theme.spacing(SMALL_PAD),
      marginRight: theme.spacing(SMALL_PAD),
      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(MEDIUM_PAD),
      },
      [theme.breakpoints.up('lg')]: {
        marginLeft: theme.spacing(EXTRA_SMALL_PAD),
        marginRight: theme.spacing(MEDIUM_PAD),
      },
    },
    linksContainer: {
      borderTopWidth: 2,
      borderTopStyle: 'solid',
      borderTopColor: theme.palette.grey[200],
      paddingTop: theme.spacing(6),
      paddingLeft: theme.spacing(SMALL_PAD),
      paddingRight: theme.spacing(SMALL_PAD),
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(MEDIUM_PAD),
        paddingRight: theme.spacing(MEDIUM_PAD),
      },
      [theme.breakpoints.up('lg')]: {
        marginBottom: 0,
        borderTopWidth: 0,
        paddingTop: 0,
        paddingLeft: theme.spacing(MEDIUM_PAD),
        paddingRight: theme.spacing(LARGE_PAD),
      },
    },
    linksColumnLeft: {
      paddingRight: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        paddingRight: theme.spacing(EXTRA_SMALL_PAD),
      },
    },
    linksColumnRight: {
      paddingLeft: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(EXTRA_SMALL_PAD),
      },
    },
    modalParagraph: {},
    linkItem: {
      textTransform: 'uppercase',
      letterSpacing: 1,
      fontWeight: 600,
      marginBottom: theme.spacing(4.5),
      '&:last-child': {
        marginBottom: 0,
      },
      '& > a': {
        color: theme.palette.grey[200],
      },
    },
  })

export default makeStylesWrapper(style)
