import React from 'react'
import View from './Galleries.view'
import { GalleriesProps } from './Galleries.types'
import { useGalleries } from 'core/logic/gallery/gallery.hook'
import { useHistory } from 'react-router-dom'
import ROUTES from 'core/modules/router'

export default function GalleriesLogic(props: GalleriesProps) {
  const history = useHistory()
  const { galleries } = useGalleries()
  return (
    <View
      galleries={galleries || undefined}
      onSeeAllGalleries={() =>
        history.push(ROUTES.unauthenticated.allGalleries)
      }
    />
  )
}
