import View from './ChatThreadCreator.view'
import { ChatThreadCreatorProps } from './ChatThreadCreator.types'
import { useChatRoom } from 'context/ChatRoom'
import { useUser } from 'core/logic/user'
import { useHistory } from 'react-router-dom'
import useLocalStorage from '@onepercentio/one-ui/dist/hooks/persistence/useLocalStorage'
import { TO_SIGNIN } from 'core/modules/router'

export default function ChatThreadCreatorLogic({
  threadId,
}: ChatThreadCreatorProps) {
  const history = useHistory()
  const { postThread, loading, error } = useChatRoom()
  const { profile, detail } = useUser()
  const [storedMessage, setStoredMessage] = useLocalStorage(
    `chat-message-${threadId}`,
    ''
  )

  return (
    <View
      loading={loading}
      error={error}
      initialMessage={storedMessage}
      onPost={async (t) => {
        setStoredMessage('')
        if (!profile) {
          history.push(TO_SIGNIN())
          setStoredMessage(t)
        } else
          await postThread({
            createdAt: new Date(),
            replies: [],
            text: t,
            user: {
              img: detail!.photoURL! || '',
              name: profile.name,
            },
          })
      }}
    />
  )
}
