import App from 'containers/App'
import { createRoot } from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import CustomRouter from 'components/CustomRouter/CustomRouter'
import './styles/InjectFonts'

createRoot(document.getElementById('root')!).render(
  <CustomRouter>
    <App />
  </CustomRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
