import { withStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles/createTheme'

const styles = (theme: Theme) => ({
  '@global': {
    body: {
      '--digital-blue': theme.palette.text.primary,
      '--mutable-hamburger-background': theme.palette.background.default,
    },
    /**
     * Disable the focus outline, which is default on some browsers like
     * chrome when focusing elements
     */
    '*:focus': {
      outline: 0,
    },
    b: {
      fontWeight: 600,
    },
    strong: {
      fontWeight: 700,
    },
    small: {
      fontSize: '0.7em',
    },
    '.text-white': {
      color: theme.palette.common.white,
    },
    '.listItemLeftPadding': {
      paddingTop: `${theme.spacing(1.75)}px !important`,
      paddingBottom: `${theme.spacing(1.75)}px !important`,
      paddingLeft: `${theme.spacing(4)}px !important`,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: `${theme.spacing(4)}px !important`,
      },
      '@media (max-width:  420px)': {
        paddingLeft: `${theme.spacing(1)}px !important`,
      },
    },
    '.container': {
      width: '100%',
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      marginRight: 'auto',
      marginLeft: 'auto',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 540,
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: 720,
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 1170,
      },
    },
    '.row': {
      display: 'flex',
      flexWrap: 'wrap',
      marginRight: -theme.spacing(2),
      marginLeft: -theme.spacing(2),
    },
    '.container-fluid': {
      width: '100%',
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      marginRight: 'auto',
      marginLeft: 'auto',
      maxWidth: 1370,
    },
    '.lg-mg-top': {
      marginTop: `${theme.spacing(20)}px !important`,
      [theme.breakpoints.down('md')]: {
        marginTop: `${theme.spacing(18)}px !important`,
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: `${theme.spacing(16)}px !important`,
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: `${theme.spacing(14)}px !important`,
      },
    },
    '.lg-mg-bottom': {
      marginBottom: `${theme.spacing(20)}px !important`,
      [theme.breakpoints.down('md')]: {
        marginBottom: `${theme.spacing(18)}px !important`,
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: `${theme.spacing(16)}px !important`,
      },
      [theme.breakpoints.down('xs')]: {
        marginBottom: `${theme.spacing(14)}px !important`,
      },
    },
    '.lg-p-top': {
      paddingTop: `${theme.spacing(20)}px !important`,
      [theme.breakpoints.down('md')]: {
        paddingTop: `${theme.spacing(18)}px !important`,
      },
      [theme.breakpoints.down('sm')]: {
        paddingTop: `${theme.spacing(16)}px !important`,
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: `${theme.spacing(14)}px !important`,
      },
    },
    // Route transitions
    '.my-fade-enter': {
      opacity: 0,
      //transform: scale(0.9);
    },
    '.my-fade-enter-active': {
      opacity: 1,
      //transform: translateX(0);
      transition: 'opacity 200ms, transform 200ms',
    },
    '.my-fade-exit': {
      opacity: 1,
    },
    '.my-fade-exit-active': {
      opacity: 0,
      //transform: scale(0.9);
      transition: 'opacity 200ms, transform 200ms',
    },
    // Long transitions
    '.long-fade-enter': {
      opacity: 0,
    },
    '.long-fade-enter-active': {
      opacity: 1,
      transition: 'opacity 1000ms, transform 1000ms',
    },
    '.long-fade-exit': {
      opacity: 1,
    },
    '.long-fade-exit-active': {
      opacity: 0,
      transition: 'opacity 1000ms, transform 1000ms',
    },
  },
})

function globalStyles() {
  return null
}

export default withStyles(styles, { withTheme: true })(globalStyles)
