import { createStyles } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const style = () =>
  createStyles({
    logo: {
      width: 'auto',
      height: 50,
      padding: 5,
    },
  })

export default makeStylesWrapper(style)
