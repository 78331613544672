import { i18n } from 'translate/i18n'
import { useTheme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import copy from 'clipboard-copy'
import ActionButton from 'components/ActionButton'
import IconRarum from 'components/IconRarum'
import React, { useState } from 'react'
import useStyles from '../PendingPayment.style'
import PixInfo from '../PixInfo'

interface PixViewProps {
  qrcode: string,
  pixCopyPaste: string
}
const PixView: React.FC<PixViewProps> = ({ qrcode, pixCopyPaste }) => {
  const theme = useTheme()
  const classes = useStyles()

  const [snackOpen, setSnackOpen] = useState(false)

  const onCopy = (text: string) => copy(text)

  const copyHandler = () => {
    onCopy(pixCopyPaste || '')
    setSnackOpen(true)
  }

  return (
    <>
      {' '}
      <Box className={classes.qrcode}>
        <img
          src={`data:image/png;base64,${
            qrcode || ''
          }`}
          alt='QR Code'
          height={200}
          width={200}
          style={{ display: 'block' }}
        />
      </Box>
      <ActionButton
        filled
        icon='none'
        text={i18n.t('authenticated.pendingPayment.methods.pix.copyCode')}
        className={classes.spacing}
        onClick={copyHandler}
      />
      <Box className={classes.spacing}>
        <PixInfo />
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackOpen}
        onClose={() => setSnackOpen(false)}
        message={i18n.t(
          'authenticated.pendingPayment.methods.pix.pixCodeCopied'
        )}
        key='snack-copy-pix-code'
        autoHideDuration={4000}>
        <Alert
          onClose={() => setSnackOpen(false)}
          severity='info'
          classes={{ root: classes.alert }}
          icon={
            <IconRarum
              icon='alert'
              color={theme.palette.common.white}
              size={24}
            />
          }>
          {i18n.t('authenticated.pendingPayment.methods.pix.success')}
        </Alert>
      </Snackbar>
    </>
  )
}

export default PixView
