import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) =>
  createStyles({
    bottomSpacing: {
      marginBottom: theme.spacing(3),
    },
    figure: {
      display: 'flex',
      justifyContent: 'center',
      margin: 0,
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        margin: 0,
        paddingRight: theme.spacing(2),
      },
    },
    image: {
      maxHeight: 400,
      maxWidth: '100%',
    },
    userData: {
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  })

export default makeStylesWrapper(style)
