import { builder } from 'core/helpers'
import * as AnalyticsTypes from './analytics.types'

// TYPES
const LOGIN_SUCCESS = 'analytics/LOGIN'
const SET_USER_ID = 'analytics/SET_USER_ID'
export const types = Object.freeze({
  LOGIN_SUCCESS,
  SET_USER_ID,
})

// ACTIONS
const loginSuccess = () => builder.actionCreator<AnalyticsTypes.AnalyticsActionsTypes>(LOGIN_SUCCESS)
const setUserId = () => builder.actionCreator<AnalyticsTypes.AnalyticsActionsTypes>(SET_USER_ID)
export const actions = Object.freeze({
  loginSuccess,
  setUserId,
})

export const mapActions = Object.freeze({
  [LOGIN_SUCCESS]: loginSuccess,
  [SET_USER_ID]: setUserId,
})
