import {
  Box,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
  Checkbox,
} from '@material-ui/core'
import useAsyncControl from '@onepercentio/one-ui/dist/hooks/useAsyncControl'
import ActionButton from 'components/ActionButton'
import BackButton from 'components/BackButton'
import {
  ActionStep,
  MultiStepOperation,
  StaticStep,
} from 'components/MultiStepOperation/MultiStepOperation.view'
import { useAuction, useBidInformation } from 'context/Auction'
import { currencyFormatter } from 'core/helpers/formatter'
import { useEffect, useMemo, useState } from 'react'
import OffChainAuctionFacade from 'service/IAuctionFacade/OffChainAuctionFacade'
import OnChainAuctionFacade from 'service/IAuctionFacade/OnChainAuctionFacade'
import { EscapedTranslation, i18n, t } from 'translate/i18n'
import useStyles from './styles'
import { OfferAuctionBidModalFinishProps } from './types'

export const OfferAuctionBidModalFinish: React.FC<
  OfferAuctionBidModalFinishProps
> = ({ phone, amount, onFinish, onCancel }) => {
  const classes = useStyles()
  const auctionCtx = useAuction()!
  const { loading, error, createBid } = useAsyncControl({
    createBid: auctionCtx.createBid,
  })
  const [result, setResult] = useState<boolean>()
  const isOnChain = auctionCtx instanceof OnChainAuctionFacade

  async function createBidFunc() {
    await createBid(amount, phone)
      .then(() => setResult(true))
      .catch(() => setResult(false))
  }

  useEffect(() => {
    if (auctionCtx instanceof OffChainAuctionFacade) createBidFunc()
  }, [auctionCtx])

  return loading ? (
    <Grid
      item
      container
      justifyContent='center'
      alignItems='center'
      className={classes.loadingBox}>
      <CircularProgress size={24} />
    </Grid>
  ) : (
    <>
      {result === undefined ? (
        isOnChain ? (
          <OnChainConfirmationLogic
            amount={amount}
            onBidCreated={() => setResult(true)}
          />
        ) : null
      ) : (
        <Grid item container direction='column' className={classes.box}>
          {result === true ? (
            <Grid item>
              <Typography variant='h4'>
                {i18n.t('components.offerAuctionBidModal.success')}
              </Typography>
              <Box marginTop={3}>
                <Typography variant='body1'>
                  {i18n.t('components.offerAuctionBidModal.congratulation')}
                </Typography>
              </Box>
            </Grid>
          ) : (
            <Grid item>
              <Typography variant='h4'>
                {i18n.t('components.offerAuctionBidModal.error')}
              </Typography>
              <Box marginTop={3}>
                <Typography variant='body1'>
                  {i18n.t('components.offerAuctionBidModal.tryLater')}
                </Typography>
              </Box>
              <Box marginTop={3}>
                <Typography variant='caption' color='error'>
                  <code>
                    {i18n.t(
                      `components.offerAuctionBidModal.errorMap.${
                        error.code as 'failed_sequential_bid'
                      }`
                    )}
                  </code>
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      )}
      <Divider />
      <Grid item container justifyContent='flex-end' className={classes.box}>
        {result === undefined ? (
          <Box marginRight={'auto'}>
            <BackButton
              icon='close'
              text={t('generic.cancel')}
              onClick={onCancel}
            />
          </Box>
        ) : result === true ? (
          <ActionButton
            text={i18n.t('components.offerAuctionBidModal.continue')}
            onClick={onFinish}
          />
        ) : (
          <ActionButton
            text={i18n.t('components.offerAuctionBidModal.gotIt')}
            onClick={onFinish}
          />
        )}
      </Grid>
    </>
  )
}

function OnChainConfirmationLogic(
  p: Pick<OfferAuctionBidModalFinishProps, 'amount'> & {
    onBidCreated: () => void
  }
) {
  const [allowance, setAllowance] = useState<number>()
  const auctionCtx = useAuction() as OnChainAuctionFacade
  const { getTokenAllowance, approveAllowance, createBid, loading, error } =
    useAsyncControl({
      getTokenAllowance: () => auctionCtx.getTokenAllowance(),
      approveAllowance: (...params: any[]) =>
        (auctionCtx.approveAllowance as any)(...params),
      createBid: (...params: any[]) => (auctionCtx.createBid as any)(...params),
    })
  useEffect(() => {
    getTokenAllowance().then(setAllowance)
  }, [])
  const isAllowanceApproved = (allowance || 0) >= p.amount
  function _approveAllowance() {
    approveAllowance(allowance! + 1000)
      .then(() => getTokenAllowance())
      .then(setAllowance)
  }
  function _createBid() {
    createBid(p.amount, '').then(p.onBidCreated)
  }
  return (
    <OnChainConfirmationView
      {...p}
      loading={loading}
      error={!!error}
      isAllowanceApproved={isAllowanceApproved}
      onApproveAllowance={_approveAllowance}
      onConfirmBid={_createBid}
      onRetry={() => {
        if (allowance === undefined) getTokenAllowance().then(setAllowance)
        else if (!isAllowanceApproved) _approveAllowance()
        else _createBid()
      }}
    />
  )
}

function OnChainConfirmationView({
  amount,
  loading,
  isAllowanceApproved,
  error,
  onApproveAllowance,
  onConfirmBid,
  onRetry,
}: Pick<OfferAuctionBidModalFinishProps, 'amount'> & {
  isAllowanceApproved: boolean
  loading: boolean
  error: boolean
  onApproveAllowance: () => void
  onConfirmBid: () => void
  onRetry: () => void
}) {
  const [acceptDisclaimer, setDisclaimer] = useState(false)
  const currency = useBidInformation().information?.currency
  const formatter = useMemo(() => currencyFormatter(currency), [currency])
  const currentStep = useMemo(() => {
    return isAllowanceApproved ? 3 : 2
  }, [isAllowanceApproved])
  const hasErrorOnAllowance = !isAllowanceApproved && error
  const hasErrorOnConfirm = isAllowanceApproved && error
  return (
    <Box margin={4}>
      <MultiStepOperation currentStep={currentStep}>
        <StaticStep
          stepNum={1}
          stepName={t(
            'components.offerAuctionBidModal.bidSettleConfirmation.onChain.title'
          )}
          stepDescription={formatter(amount)}
        />
        <ActionStep
          action={{
            label: t(
              hasErrorOnAllowance
                ? 'generic.retryBtn'
                : 'components.offerAuctionBidModal.bidSettleConfirmation.onChain.actions.approve'
            ),
            disabled: loading || isAllowanceApproved,
            onClick: hasErrorOnAllowance ? onRetry : onApproveAllowance,
          }}
          status={
            isAllowanceApproved
              ? 'checkmark'
              : hasErrorOnAllowance
              ? 'closed'
              : loading
              ? 'loading'
              : 'default'
          }
          stepNum={2}
          error={hasErrorOnAllowance}
          stepName={t(
            'components.offerAuctionBidModal.bidSettleConfirmation.onChain.approveTokens'
          )}
        />
        <ActionStep
          action={{
            label: t(
              hasErrorOnConfirm
                ? 'generic.retryBtn'
                : 'components.offerAuctionBidModal.bidSettleConfirmation.onChain.actions.confirm'
            ),
            disabled: !isAllowanceApproved || loading || !acceptDisclaimer,
            onClick: hasErrorOnConfirm ? onRetry : onConfirmBid,
          }}
          stepDescription={
            <FormControlLabel
              label={
                <EscapedTranslation code='components.offerAuctionBidModal.bidSettleConfirmation.onChain.disclaimer' />
              }
              disabled={!isAllowanceApproved || loading}
              control={
                <Checkbox
                  checked={acceptDisclaimer}
                  onClick={() => setDisclaimer((p) => !p)}
                />
              }
            />
          }
          status={
            !isAllowanceApproved
              ? 'default'
              : hasErrorOnConfirm
              ? 'closed'
              : loading
              ? 'loading'
              : 'default'
          }
          stepNum={3}
          error={hasErrorOnConfirm}
          stepName={t(
            'components.offerAuctionBidModal.bidSettleConfirmation.onChain.confirmBid'
          )}
        />
      </MultiStepOperation>
    </Box>
  )
}
