import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

export const SPINNER_SIZE = 'min(6vh,60px)'

const style = (theme: Theme) =>
  createStyles({
    back: {
      color: theme.palette.common.white,
      alignSelf: 'flex-start',
      '--svg-color': 'var(--text-default-color)'
    },
    titleWrapper: {
      zIndex: 1,
      marginBottom: '3vh',
      marginTop: '3vh',
    },
    title: {
      display: 'inline-block',
      fontSize: 'clamp(1.25rem, 5vw, 2.4rem)',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    gridContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      marginTop: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        marginTop: '5vh',
      },
    },
    gridItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    gridLeft: {
      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(2),
      },
    },
    gridRight: {
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(2),
      },
    },
    figure: {
      position: 'relative',
      zIndex: 0,
      margin: 0,
      height: 'clamp(100px, 50vmin, 415px)',
      width: 'clamp(100px, 50vmin, 415px)',
      // width: 'clamp(20vh,210px)',
      // [theme.breakpoints.up('md')]: {
      //   height: 'min(50vh,40vw,415px)',
      //   width: 'min(50vh,40vw,415px)',
      // },
      // [theme.breakpoints.up('lg')]: {
      //   height: 415,
      //   width: 415,
      // },
      '&:after': {
        position: 'absolute',
        content: '""',
        top: 0,
        left: 0,
        right: 0,
        zIndex: -1,
        height: '100%',
        width: '100%',
        margin: '0 auto',
        borderRadius: 2,
        // transform: 'scale(0.75)',
        filter: 'blur(30px)',
        background: `linear-gradient(270deg, #0fffc1, #7e0fff)`,
        backgroundSize: '200% 200%',
        animation: '$animateGlow 10s ease infinite',
      },
    },
    image: {
      maxWidth: '100%',
      borderRadius: 2,
    },
    spacing: {
      marginTop: '3vh',
      marginBottom: '3vh',
    },
    spinner: {
      color: theme.palette.primary.light,
    },
    headline: {
      fontSize: 'clamp(1rem,4vw,1.25rem)',
      textAlign: 'center',
    },
    info: {
      fontSize: 'clamp(0.8rem,3vw,0.875rem)',
      textAlign: 'center',
    },
    '@keyframes animateGlow': {
      '0%': { backgroundPosition: '0% 50%' },
      '50%': { backgroundPosition: '100% 50%' },
      '100%': { backgroundPosition: '0% 50%' },
    },
  })

export default makeStylesWrapper(style)
