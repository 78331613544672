import { createStyles, Theme } from '@material-ui/core'
import { darken, lighten } from '@material-ui/core/styles/colorManipulator'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) => {
  const fn = theme.palette.type === 'light' ? darken : lighten
  const factor = theme.palette.type === 'light' ? 0.05 : 0.1

  return createStyles({
    main: {
      marginBottom: theme.spacing(6),
      '&, & + footer': {
        alignSelf: 'stretch',
      },
    },
    sellBtn: {
      paddingRight: '24px',
      paddingLeft: '24px',
      marginLeft: '24px',
    },
    upperContainer: {
      background: `linear-gradient(0deg, rgba(255, 255, 255, 0), ${fn(
        theme.palette.background.default,
        factor
      )})`,
      paddingBottom: theme.spacing(5),
    },
    itemWrapper: {
      width: '100%',
      '& canvas': {
        minHeight: '30vh',
        maxHeight: '60vh',
      },
    },
    dropImageWrapper: {
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-start',
      },
    },
    media: {
      maxWidth: '100%',
      maxHeight: '100%',
    },
    divider: {
      width: '100%',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
      },
    },
    title: {
      textTransform: 'uppercase',
      fontWeight: 800,
    },
    avatar: {
      '& > p': {
        fontWeight: 400,
      },
    },
    spacing: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    bottomSpacing: {
      marginBottom: theme.spacing(4),
    },
    preview: {
      display: 'flex',
      width: 'inherit',
      '& img, & video': {
        width: 'inherit',
      },
    },
  })
}

const useCollectionItemDetailStyles = makeStylesWrapper(style)

export default useCollectionItemDetailStyles
