import { i18n } from 'translate/i18n'
import Box from '@material-ui/core/Box'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import { UF } from 'core/constants'
import FormField from 'components/FormFields/FormField.view'
import { FormEventHandler } from 'react'
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form'
import useStyles from '../Payment.style'
import { IPaymentStepAddressInputs } from '../Payment.types'

export interface PaymentViewProps {
  handleSubmit: FormEventHandler<HTMLFormElement>
  control: Control<IPaymentStepAddressInputs>
  errors: DeepMap<IPaymentStepAddressInputs, FieldError>
  editLocked: boolean
  fetchingCEP: boolean
  isNonResident?: boolean
}

const estados = UF.map((uf) => ({
  key: `uf-${uf.sigla}`,
  value: uf.sigla,
  label: uf.nome,
}))

const Step2View: React.FC<PaymentViewProps> = ({
  errors,
  handleSubmit,
  control,
  editLocked,
  fetchingCEP,
  isNonResident,
}) => {
  const classes = useStyles()

  return (
    <form id='payment-form' onSubmit={handleSubmit}>
      <Box mb={4}>
        <Typography variant='body1' component='p' className={classes.stepTitle}>
          <strong>
            {i18n.t('authenticated.payment.steps.stepAddress.addressData')}
          </strong>
        </Typography>
      </Box>
      <Box display='flex' flexWrap='wrap'>
        <Grid container alignItems='center'>
          <Grid item>
            {isNonResident ? (
              <FormField
                control={control}
                errors={errors}
                fieldPath='cep'
                label={i18n.t(
                  'authenticated.payment.steps.stepAddress.postalCode'
                )}
                inputMode='numeric'
                required
              />
            ) : (
              <FormField
                control={control}
                errors={errors}
                fieldPath='cep'
                label={i18n.t('authenticated.payment.steps.stepAddress.cep')}
                inputMode='numeric'
                autoComplete='postal-code'
                mask='99999-999'
                hideMaskPlaceholder
                required
              />
            )}
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Controller
                  name='nonResident'
                  render={({ field: { value, ref, onChange, onBlur } }) => (
                    <Checkbox
                      onChange={onChange}
                      onBlur={onBlur}
                      checked={value}
                      inputRef={ref}
                    />
                  )}
                  control={control}
                />
              }
              label={i18n.t(
                'authenticated.payment.steps.stepAddress.nonResident'
              )}
            />
          </Grid>
        </Grid>
        {isNonResident && (
          <Box>
            <FormField
              control={control}
              errors={errors}
              fieldPath='country'
              label={i18n.t('authenticated.payment.steps.stepAddress.country')}
              autoComplete='country'
              required
            />
          </Box>
        )}
        <Box display='flex' flexWrap='wrap' flexBasis='100%'>
          {isNonResident ? (
            <FormField
              control={control}
              errors={errors}
              fieldPath='estado'
              label={i18n.t('authenticated.payment.steps.stepAddress.state')}
              autoComplete='address-level1'
              required
            />
          ) : (
            <FormField
              control={control}
              errors={errors}
              fieldPath='estado'
              label={i18n.t('authenticated.payment.steps.stepAddress.state')}
              autoComplete='address-level1'
              options={estados}
              disabled={fetchingCEP || editLocked}
              required
            />
          )}

          <FormField
            control={control}
            errors={errors}
            fieldPath='cidade'
            label={i18n.t('authenticated.payment.steps.stepAddress.city')}
            autoComplete='address-level2'
            disabled={fetchingCEP}
            required
          />
        </Box>
        <Box display='flex' flexWrap='wrap' flexBasis='100%'>
          <FormField
            control={control}
            errors={errors}
            fieldPath='logradouro'
            label={i18n.t('authenticated.payment.steps.stepAddress.patio')}
            autoComplete='address-line1'
            disabled={fetchingCEP}
            required
          />
          <FormField
            control={control}
            errors={errors}
            fieldPath='numero'
            label={i18n.t('authenticated.payment.steps.stepAddress.number')}
            inputMode='numeric'
            required
          />
        </Box>
      </Box>
    </form>
  )
}

export default Step2View
