import Styles from 'openspace/assets/styles/TextStyles.module.scss'
import baseMessages from './en.ts'
import { merge } from 'lodash'

const messages = {
  'en-US': {
    translations: {
      forCreators: {
        brand: {
          description:
            'At Rarum, your brand can engage and retain your audience in an innovative way, creating active communities and generating new sources of revenue.',
          sections: {
            loyalty: {
              proposal: [
                '<b>Unique Web3 advantages:</b> enable users to easily trade the loyalty coins among themselves or change them for coins of other brands/coalitions, increasing the liquidity and value of your currency',
                'Monetize brand relationships with customers',
                'Connect easily with partners',
              ],
            },
            nftGames: {
              proposal: [
                '<b>Unique Web3 advantages:</b> smart contracts allow to configure challenges with greater flexibility and to automate their execution, reducing the cost of activating the user base',
                'Gamification layer for earning (brand challenges)',
                'NFT trading on the platform with other users (+ recurring transactions)',
                'List of redeemable benefits within the brand using earned/acquired NFT collections',
              ],
            },
            certificate: {
              proposal: [
                '<b>Unique Web3 advantages:</br> registration in blockchain allows robust product authentication and traceability of events during the product lifecycle',
                'Authenticity registration for product sales and throughout the product lifecycle with service monitoring (e.g., periodic review, callback, maintenance) and resale value',
                'Connection between physical and digital products through the use of NFC sensors and/or QR Codes',
              ],
            },
          },
        },
        creators: {
          description:
            'Content creators have a comprehensive solution in Rarum to transform audience engagement into revenue, both through their products and partnerships.',
          sections: {
            web3FanClub: {
              proposal: [
                '<b>Unique Web3 advantages:</b> verification of NFTs ownership is a reliable solution to control access to community’s benefits and allows users to resell their membership token or sell specific benefits separately, while keeping their membership token',
                'Monetize influencer relationships with fan bases',
                'Sale of time-limited membership access (e.g., 1 year) granting access to closed community and exclusive content',
              ],
            },
            creator: {
              proposal: [
                "<b>Unique Web3 advantages:</b> asset tokenization allows to divide the ownership of creators' financial assets into  into smaller units, that can be purchased by fans and investors",
                'Advance payment of future earnings to creators, with the lowest funding cost: their own fans',
                'Sale of NFT representing royalties on future cash flow and fan club benefits',
              ],
            },
          },
        },
        mainSolutions: 'MAIN SOLUTIONS',
        valueProposition: 'VALUE PROPOSITION',
        coreModules: 'CORE MODULE',
      },
      home: {
        forCreators: {
          heading: 'WHO IS RARUM FOR',
          title: 'Our solutions adapt to your profile',
          tabs: ['Creator', 'Brand'],
          profile: 'CUSTOMER PROFILE',
          sections: {
            brand: {
              loyalty: {
                title: 'Loyalty Coins',
                profile: [
                  'Brands who are looking to create their own currency to reward loyal customers',
                ],
              },
              nftGames: {
                title: 'Engage to Earn',
                profile: [
                  'Brands aiming to add gamification experiencies to their loyalty program, increasing user engagement and lifetime value',
                ],
              },
              certificate: {
                title: 'Product Certificate',
                profile: [
                  'Brands with durable consumer goods seeking to extend product-linked engagement (automakers, electronics, luxury retail)',
                ],
              },
            },
            creators: {
              web3FanClub: {
                title: 'Web3 Fan Club',
                profile: [
                  'Instagrammer',
                  'Digital Influencer',
                  'Journalist',
                  'Streamer',
                ],
              },
              creator: {
                title: 'Creator Investment Club',
                profile: ['Musician', 'Composer', 'Youtuber', 'Writer'],
              },
            },
          },
          actions: {
            knowMore: 'Learn More',
            clickToKnowMore: 'Clique para saber mais',
          },
        },
        sections: {
          contractModel: {
            section: 'HOW IT WORKS',
            title: 'You can hire Rarum in two formats:',
            subtitle:
              'Talk to our team and find out which format works best for your business.',
            card: 'Talk to our team and find out which format works best for your business.',
            models: {
              platform: {
                title: 'White Label Platform',
                subtitle:
                  'A user-friendly interface for your users, abstracting the complexities of Web3 and integrating primary and secondary markets and payment methods.',
              },
              api: {
                title: 'API',
                subtitle:
                  'The ideal solution to integrate your application or platform with Web3, quickly and securely.',
              },
            },
            actions: {
              contact: 'Contact Us',
            },
          },
          features: {
            title: 'WHAT WE OFFER',
            description: 'we turn engagement into business',
            benefit:
              'We developed a set of product modules that combine to create the engagement & monetization model that best suits your users and your business.',
            actions: {
              schedule: 'Schedule a demo',
            },
            concepts: {
              marketplace: {
                title: 'Marketplace',
                description: 'A customized platform for your company',
                features: [
                  'NFT Gallery',
                  'My collection',
                  'Sales',
                  'Integration with wallets',
                  'Auctions',
                ],
              },
              membership: {
                description:
                  'encourage the engagement of your community with new technologies',
                title: 'Membership',
                features: [
                  'Airdrops',
                  'Gated Content',
                  'Exclusive Live Events',
                  'Members Only',
                  'Community Events',
                ],
              },
              social: {
                description: 'Structured presence in new channels',
                title: 'Social',
                features: [
                  'Discord Channels',
                  'Telegram Groups',
                  'Twitch Integration',
                ],
              },
              gamification: {
                description: 'gamify the relationship with your community',
                title: 'Gamification',
                features: [
                  'QR-Code Activation',
                  'App Engagement Activation',
                  'Social Media Activation',
                  'Geofencing Activation',
                  'Challenges',
                ],
              },
              smart_tickets: {
                description:
                  'new spaces for your community and membership actions',
                title: 'Smart Tickets',
                features: [
                  'NFT tickets primary and secondary',
                  'Market',
                  'Event airdrops',
                  'Event access solution',
                  'Proof of Attendance',
                ],
              },
              digital_twins: {
                description:
                  'Generate new forms of revenue from physical assets',
                title: 'Digital Twins',
                features: [
                  'NFT + Physical Product Marketplace',
                  'QR-Code',
                  'NFC Tagging Solution',
                ],
              },
              shared_ownership: {
                description: 'Innovate and share ownership of your asset',
                title: 'Shared Ownership',
                features: [
                  'fractionable assets',
                  'Distribution of results',
                  'DeFi Connection',
                ],
              },
              loyalty_coins: {
                description:
                  'Encourage engagement from campaigns and engagement actions led by your community',
                title: 'Loyalty Coins',
                features: [
                  'Your own currency',
                  'Programmed distribution',
                  'Integration with partners',
                ],
              },
            },
            mainFeatures: 'MAIN FEATURES',
          },
          faq: {
            title: 'Frequently asked questions',
            description:
              "The most frequently asked questions from Customers and Brands who have already used Rarum's services.",
            questions: [
              {
                question: 'What is a Whitelabel platform?',
                answer:
                  "A Whitelabel platform is one where you can customize the experience of using the product with your brand and visual identity. That's how Rarum works, the best features with your visual!",
              },
              {
                question:
                  'What is the time taken for a store to be customized?',
                answer:
                  'This is a really quick process, with an organized checklist of actions. In a few weeks, your store will be live and available to your community.',
              },
              {
                question: 'How can I engage my audience using tokens?',
                answer:
                  'Before implementing your store, our team will present you with an engagement framework for you and your audience using tokens. This methodology is the result of extensive benchmarking work conducted with over 27 existing platforms and over 100 evaluation criteria.',
              },
              {
                question:
                  'In which blockchain network are my tokens generated?',
                answer:
                  'Our platform is compatible with all networks compatible with the Ethereum Virtual Machine (EVM) standard, which includes networks like Ethereum, Polygon, Celo, and many others!',
              },
              {
                question:
                  'What are the supported payment methods on the platform?',
                answer:
                  'We support payment through PIX, Credit Card, and Cryptocurrencies (USDC).',
              },
              {
                question: 'Do I need to know about crypto to use Rarum?',
                answer:
                  'With Rarum, access to the Web3 world is very simple. Our solution is capable of managing all the complexity of the blockchain for your brand and your audience, without requiring the use of wallets or cryptocurrencies to interact. Nevertheless, more advanced users can connect their wallets and use their cryptocurrencies if they wish.',
              },
            ],
          },
          believers: {
            title: 'Who believes with Rarum',
            description:
              'brands that transformed <b>Likes to ROI</b> with Rarum',
          },
          contactUs: {
            success: `<span class="${Styles.gradient}">Contact sent<br/>Now just wait</span>`,
            title: 'Contact us',
            description:
              "Fill out this form and let's discuss how we can help your business",
            form: {
              description:
                'Fill the form below and, if possible, explain to us the reason for your contact. We thank you for the interest.',
              title: 'Contact Rarum',
            },
            v2: {
              title: "Let's create solutions <b>with Rarum?</b>",
              description: 'Follow Rarum at OnePercent social medias',
              actions: {
                schedule: 'Schedule a demo',
              },
            },
          },
        },
      },
      featureDetails: {
        useCases: 'Use Cases',
        more: 'Other Modules',
        highlights: 'HIGHLIGHTS',
        actions: {
          visitCase: 'Visit the case',
        },
        demo: {
          title: 'Schedule a demo and <b>learn more about rarum</b>',
          actions: {
            schedule: 'Schedule a demo',
          },
        },
        details: {
          marketplace: {
            description:
              'With a token marketplace, you can make sales in the primary and secondary markets easily and quickly, integrated with traditional payment methods such as PIX and Credit Card.',
            features: [
              {
                name: 'NFT Galleries',
                description:
                  'Organize your offers by themes and areas of interest.',
              },
              {
                name: 'My Collection',
                description:
                  'Your users will have their items organized and easy to manage.',
              },
              {
                name: 'Sales',
                description:
                  'Various types of offerings (bundles, direct sales, airdrops), payment options, primary market (direct sales), and secondary market (sales between users), with fraud prevention.',
              },
              {
                name: 'Wallet Integration',
                description:
                  'Users can connect their crypto wallets to the product and access the best of Web3 world from your store.',
              },
              {
                name: 'Auctions',
                description:
                  'A different way to engage your audience and turn unique items into highly engaging and revenue-generating elements.',
              },
            ],
            cases: [
              {
                title: 'Digital Garage Volkswagen',
                subtitle:
                  'A major brand bringing its audience closer in an innovative way.',
                description:
                  'Launched in 2022, Digital Garage is a Marketplace for the sale of unique items related to the Volkswagen brand. The project has been a success since its launch, with both bundle sales and auctions of unique physical and digital parts.',
                link: 'https://digitalgaragevw.com',
                image:
                  'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fmarket%20volks.png?alt=media&token=544a59bf-3ec8-4a49-b2ee-ab95bf2a4df4',
                highlights: ['NFT Galleries', 'Sales', 'Auctions'],
              },
              {
                title: 'Dia do Batom',
                subtitle: 'Innovation and empowerment for a special date.',
                description:
                  "The brands of Grupo Boticário embraced the 'Dia do Batom' (Lipstick Day) with a campaign where each buyer would have their own lips immortalized in an NFT art (physical and digital), along with a multitude of benefits, such as discounts, exclusive content, and even a factory tour!",
                image:
                  'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fmarket%20dia%20batom.png?alt=media&token=35797b8e-471e-4e69-bd28-b31a9671a9d0',
                link: 'https://nft.diadobatom.com.br/',
                highlights: ['NFT Galleries', 'Sales', 'Auctions', 'Campaign'],
              },
            ],
          },
          membership: {
            description:
              'Use your tokens to bring your audience closer and represent belonging to your community with exclusive benefits.',
            features: [
              {
                name: 'Airdrops',
                description:
                  'Token issuance to encourage new user registration for the community.',
              },
              {
                name: 'Gated Content',
                description:
                  'Access exclusive content for members of your community, using the token as a key to access benefits.',
              },
              {
                name: 'Exclusive Live Events',
                description:
                  'Exclusive content for token holders on your platform.',
              },
              {
                name: 'Members Only',
                description:
                  'Use the token to conduct exclusive activities for members, both physical and online.',
              },
              {
                name: 'Community Events',
                description:
                  'Keep your community members updated with your events calendar.',
              },
            ],
            cases: [
              {
                title: 'Kofre Café',
                subtitle: 'Art and gastronomy in a single passport.',
                description:
                  'With the Kofre Café token, Kofre Club members can access unique experiences, both in gastronomy and culture, at the Espaço Santander Cultural in the city of Porto Alegre.',
                image:
                  'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fmember%20kofre.png?alt=media&token=6e85dcf9-2090-422c-9653-5e6b41695449',
                link: 'https://rarum.io/asset/eolW6ABKYvk9hhpso4Qp',
                highlights: [
                  'Engagement',
                  'Club',
                  'Experiences',
                  'Art',
                  'Gastronomy',
                ],
              },
              {
                title: 'Bored Apes*',
                subtitle: 'A club with a distinctive identity.',
                description:
                  'Desired by celebrities and personalities worldwide, Bored Apes is a club where token holders have access to exclusive benefits such as products, content, and physical and digital events. Each member is unique, and the symbol of each user is a distinctive image. Owners have full control over their access and can trade on the secondary market if they wish.',
                image:
                  'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fmember%20bored.png?alt=media&token=1fc92ace-6c17-4b43-9a91-54ce492c167c',
                link: 'https://boredapeyachtclub.com/',
                highlights: [
                  'Engagement',
                  'Club',
                  'Experiences',
                  'Exclusivity',
                ],
              },
            ],
          },
          social: {
            description:
              'Use tokens to encourage interaction among members of your community, strengthen engagement with your brand and content.',
            features: [
              {
                name: 'Discord',
                description:
                  'Use the token as access control to exclusive groups on Discord.',
              },
              {
                name: 'Telegram',
                description:
                  'Use the token as access control to exclusive groups on Telegram.',
              },
              {
                name: 'Twitch',
                description:
                  'Transform engagement on Twitch live streams into loyalty from your audience with rewards.',
              },
            ],
            cases: [
              {
                title: 'Universus',
                subtitle: 'A game with engagement and rewards for its members.',
                description:
                  'The Universus game combines Play to Earn elements with a fun game dynamic and exclusive content for token holders on its Discord channels.',
                image:
                  'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fsocial%20universus.png?alt=media&token=6b5a0286-4cc9-4ac1-a698-49a5f7e86d31',
                link: 'https://www.universus.gg/',
                highlights: ['Discord', 'Engagement', 'Games'],
              },
              {
                title: 'Bored Apes*',
                subtitle: 'A club with a distinctive identity.',
                description:
                  'Desired by celebrities and personalities worldwide, Bored Apes is a club where token holders have access to exclusive benefits such as products, content, and physical and digital events. Each member is unique, and the symbol of each user is a distinctive image. Owners have full control over their access and can trade on the secondary market if they wish.',
                image:
                  'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fmember%20bored.png?alt=media&token=1fc92ace-6c17-4b43-9a91-54ce492c167c',
                link: 'https://boredapeyachtclub.com/',
                highlights: [
                  'Engagement',
                  'Club',
                  'Experiences',
                  'Exclusivity',
                ],
              },
            ],
          },
          gamification: {
            description:
              'Use your tokens as a mechanism to incentivize user engagement with challenges and rewards.',
            features: [
              {
                name: 'QR-Code Activation',
                description:
                  'Exclusive links to be read as QR Codes to redeem tokens.',
              },
              {
                name: 'App Engagement Activation',
                description: 'Engagement campaigns for your application.',
              },
              {
                name: 'Social Media Activation',
                description:
                  'Increase engagement on social media to reward your followers.',
              },
              {
                name: 'Geofencing Activation',
                description:
                  "Token generation based on your users' geolocation.",
              },
              {
                name: 'Challenges',
                description:
                  'Create challenges that, when completed, reward your users.',
              },
            ],
            cases: [
              {
                title: 'Universus',
                subtitle: 'A game with engagement and rewards for its members.',
                description:
                  'The Universus game combines Play to Earn elements with a fun game dynamic and exclusive content for token holders on its Discord channels.',
                image:
                  'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fsocial%20universus.png?alt=media&token=6b5a0286-4cc9-4ac1-a698-49a5f7e86d31',
                link: 'https://www.universus.gg/',
                highlights: ['Discord', 'Engagement', 'Games'],
              },
              {
                title: 'Uptrip*',
                subtitle: 'Travel can be even better with exclusive benefits.',
                description:
                  'The startup Uptrip created a solution to engage travelers and airlines with tokens that recognize their actions, promote loyalty, and provide benefits during flights, such as Wi-Fi, VIP Area access, and seat upgrades.',
                image:
                  'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fgamification%20uptrip.png?alt=media&token=be884c8f-6c1d-41a9-b5e1-c0cd112c7b16',
                link: 'https://www.uptrip.app/',
                highlights: ['Engagement', 'Incentives', 'Benefits'],
              },
            ],
          },
          smart_tickets: {
            description:
              'Your tickets are now NFTs that deliver physical and digital experiences in a simple and secure way. From ticket purchase to event activations, to promote engagement.',
            features: [
              {
                name: 'NFT Tickets Primary and Secondary',
                description:
                  'Sale of tickets to users and between users, with performance and security.',
              },
              {
                name: 'Event Airdrops',
                description:
                  'Issue tokens to encourage audience participation in your event.',
              },
              {
                name: 'Event Access Solution',
                description:
                  'Control access to your event using smart tickets.',
              },
              {
                name: 'Proof of Attendance',
                description:
                  'Issue personalized participation certificates as proof of attendance at your event.',
              },
            ],
            cases: [
              {
                title: 'Simple Music',
                subtitle: 'Save friendliness! The event is yours.',
                description:
                  'The Simple Music project created tokens that provide access to a concert by the artist Jorbe Ben Jor, one of the most celebrated artists in Brazil. The tickets were sold in groups of 5, 20, and 40 units.',
                image:
                  'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fsmart%20simple.png?alt=media&token=b10bcb83-fed6-40b1-a51e-b569384cc41b',
                link: 'https://rarum.io/g/Xc1SgS4szJNFtgNJ3PMe',
                highlights: ['Events', 'Exclusive', 'Crowdfunding'],
              },
              {
                title: 'Ticketmaster*',
                subtitle: 'Benefit for the fan and the artist.',
                description:
                  'The event giant Ticketmaster launched a feature that allows batches of tickets to be sold exclusively to token owners of artists. This enables the traditional fan club model to now go digital, and engagement brings even more benefits to members.',
                image:
                  'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fsmart%20ticketmaster.png?alt=media&token=703edb10-c089-42e7-9d12-ebcf4079299d',
                link: 'https://business.ticketmaster.com/business-solutions/nft-token-gated-sales/',
                highlights: ['Events', 'Exclusive', 'Fan Club'],
              },
            ],
          },
          digital_twins: {
            description:
              'The possibilities of connecting physical and digital items are endless. Build unique experiences around your product and brand to engage the audience.',
            features: [
              {
                name: 'NFT + Physical Product Marketplace',
                description:
                  'Sell physical and digital products in primary and secondary markets.',
              },
              {
                name: 'QR-Code',
                description:
                  'From a QR Code on your physical product, your user will be taken to a unique digital experience.',
              },
              {
                name: 'NFC Tagging Solution',
                description:
                  'With a touch of the phone on your product, you can provide users with unique digital experiences related to your product.',
              },
            ],
            cases: [
              {
                title: 'Digital Garage Volkswagen',
                subtitle:
                  'A digital car that opens the doors to a historic moment.',
                description:
                  "The latest generation of Brazil's beloved car, the Gol, had a special edition in which one of its few units was auctioned on its digital platform. The auction winner received the vehicle and its blockchain registration in the form of an NFT, with exclusive art from the Volkswagen design team and the right to visit the factory.",
                link: 'https://digitalgaragevw.com',
                image:
                  'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fmarket%20volks.png?alt=media&token=544a59bf-3ec8-4a49-b2ee-ab95bf2a4df4',
                highlights: ['NFT Gallery', 'Sales', 'Auction'],
              },
              {
                title: 'Dia do Batom',
                subtitle: 'For a special date, innovation and empowerment.',
                description:
                  "Brands of the Boticário Group embraced 'Dia do Batom' (Lipstick Day) with a campaign where each buyer would have their own lips immortalized in an NFT art (physical and digital), along with a multitude of benefits such as discounts, exclusive content, and even a factory visit!",
                image:
                  'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Ftwins%20dia%20batom.png?alt=media&token=01a656b9-f187-4c25-88e3-f647c51d3098',
                link: 'https://nft.diadobatom.com.br/',
                highlights: ['NFT Gallery', 'Sales', 'Auction', 'Campaign'],
              },
            ],
          },
          shared_ownership: {
            description:
              'Create participation and profit-sharing models for your community using your tokens.',
            features: [
              {
                name: 'Fractional Assets',
                description:
                  'Unique items in fractional offers that generate gains for users.',
              },
              {
                name: 'Distribution of Results',
                description:
                  'Reward your users with token-based rewards based on the performance of your assets.',
              },
              {
                name: 'DeFi Integration',
                description:
                  'Create bridges to Decentralized Finance (DeFi) models using our solution.',
              },
            ],
            cases: [
              {
                title: 'Moss Amazônia',
                subtitle:
                  'Be part of the Amazon Rainforest protection movement.',
                description:
                  "In a model of NFT sales where each unit equals a portion of a preservation area in the forest, Moss allows its community to engage in actions to protect the 'lungs of the world' in a simple and secure way.",
                link: 'https://nft.moss.earth/',
                image:
                  'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fshared%20moss.png?alt=media&token=31bf7dcd-00bb-4047-9249-d21b71be8b72',
                highlights: ['Preservation', 'Amazon', 'ESG'],
              },
              {
                title: 'Royal*',
                subtitle:
                  'Generate returns for fans with the success of their favorite artists.',
                description:
                  'In fractional token sales representing music royalties, fans can support their favorite artists and earn returns from sales and streaming success, in an innovative and sustainable model.',
                image:
                  'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Fshared%20royal.png?alt=media&token=a792fd43-3a27-467f-bc14-66b97d092866',
                link: 'https://royal.io/',
                highlights: ['Royalties', 'Music', 'Crowdfunding'],
              },
            ],
          },
          loyalty_coins: {
            description:
              'Want to have your own currency and use it as a loyalty program for your users? This is the perfect solution for you!',
            features: [
              {
                name: 'Own Currency',
                description: 'Your token in ERC-20 format for loyalty.',
              },
              {
                name: 'Scheduled Distribution',
                description:
                  'Establish criteria for distributing tokens to your users.',
              },
              {
                name: 'Integration with Partners',
                description:
                  'Allow your tokens to be used by partners to deliver benefits and add value.',
              },
            ],
            cases: [
              {
                title: 'MercadoPago*',
                subtitle: 'Mercado Coin hits the market.',
                description:
                  'As part of its loyalty program, MercadoPago launches its own currency. It is distributed in the form of cashback to users, incentivizing platform usage and enabling its use for purchasing products and trading in the cryptocurrency market.',
                image:
                  'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Floyalty%20marcadopago.png?alt=media&token=f756689e-a8f2-44e5-8e25-ab0cf98704eb',
                link: 'https://meubolso.mercadopago.com.br/mercado-coin-criptomoeda-mercado-livre',
                highlights: ['Loyalty', 'Cashback', 'E-Commerce'],
              },
              {
                title: 'Nubank*',
                subtitle: 'Nucoin shakes up the market.',
                description:
                  'Created to build user loyalty to the Nubank platform, Nucoin functions as a cashback system where the more you use, the more you earn. The project includes benefits for token holders and was developed with an initial community of 2,000 brand enthusiasts engaged in shaping the project.',
                image:
                  'https://firebasestorage.googleapis.com/v0/b/prod-rarum.appspot.com/o/rarum.io%2Floyalty%20nubank.png?alt=media&token=856048df-0676-4f2e-9669-d4a7cb89183e',
                link: 'https://blog.nubank.com.br/nubank-cria-nucoin-moeda-digital-propria/',
                highlights: ['Loyalty', 'Bank', 'Cashback'],
              },
            ],
          },
        },
      },
    },
  },
} satisfies {
  'en-US': import('./pt.openspace').OpenspaceOverridesShape
}

export default merge(baseMessages, messages)
