import { Chip } from '@material-ui/core'
import clsx from 'clsx'
import { MarketplaceInfoResumeView } from '../AssetItem.type'
import { PropsWithChildren } from 'react'
import { t } from 'translate/i18n'
import MarketplaceDetailsStyles from './MarketplaceDetails.style'
import { currencyNumberFormatter } from 'core/helpers/formatter'
import Button from '@onepercentio/one-ui/dist/components/Button'

export enum MarketplaceDetailsTestId {
  CHIP = 'marketplace_highlight_chip',
  UNITS = 'marketplace_units',
  PRICE = 'marketplace_price',
}

export default function MarketplaceDetails({
  children,
  info,
  onGoToMarketplace,
}: PropsWithChildren<{
  info: MarketplaceInfoResumeView
  onGoToMarketplace: () => void
}>) {
  const classes = MarketplaceDetailsStyles()
  return (
    <>
      <div className={clsx(classes.row, classes.small)}>
        <Chip
          data-testid={MarketplaceDetailsTestId.CHIP}
          className={clsx(classes.chip, classes.small)}
          label={t('marketplace.remainingHighlight', {
            count: info.remaining,
          })}
          variant='outlined'
        />
        <div
          className={classes.col}
          data-testid={MarketplaceDetailsTestId.UNITS}>
          <span>{t('marketplace.totalUnits', { units: info.supply })}</span>
          <span>
            <b>{t('marketplace.remainingUnits', { units: info.remaining })}</b>
          </span>
        </div>
      </div>
      {children}
      <div className={classes.row}>
        <b>{t('marketplace.lowestPrice')}</b>
        <span
          data-testid={MarketplaceDetailsTestId.PRICE}
          className={classes.big}>
          {info.price.currency}{' '}
          <b>{currencyNumberFormatter(info.price.lowest)}</b>
        </span>
      </div>
      <Button
        variant='filled'
        color='primary'
        onClick={(e) => {
          e.stopPropagation()
          onGoToMarketplace()
        }}
        className={classes.button}>
        {t('marketplace.buyNow')}
      </Button>
    </>
  )
}
