import { i18n } from 'translate/i18n'
import { Box, Container, Grid, Typography, useTheme } from '@material-ui/core'
import { formattedTime } from 'core/helpers/formatter'
import { DropType } from 'core/logic/drop/drop.types'
import { TO_DROP_CHECKOUT } from 'core/modules/router'
import { differenceInSeconds, isFuture } from 'date-fns'
import BackButton from 'components/BackButton'
import DropImage from 'components/DropImage'
import IconRarum from 'components/IconRarum'
import SquareButton from 'components/SquareButton'
import Headers from 'containers/Headers'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Media from './Media'
import useStyles from './WaitingRoom.style'

const DESCRIPTION_TEXT = i18n.t('authenticated.waitingRoom.descriptionMessage')
const calculateTimeLeft = (date: Date) => {
  if (isFuture(date)) return differenceInSeconds(date, new Date())
  else return 0
}
export interface WaitingRoomViewProps {
  drop?: DropType
  onBack: () => void
}

const WaitingRoomView: React.FC<WaitingRoomViewProps> = ({ drop, onBack }) => {
  const classes = useStyles()
  const {
    palette: {
      error: { main: red },
    },
  } = useTheme()
  const history = useHistory()
  const [timeLeft, setTimeLeft] = useState<number>(
    calculateTimeLeft(drop!.begin)
  )

  useEffect(() => {
    if (!drop) onBack()
  })

  useEffect(() => {
    const timeout = undefined
    if (drop) {
      if (timeLeft > 0) {
        setTimeout(() => {
          setTimeLeft(calculateTimeLeft(drop.begin))
        }, 1000)
      } else {
        setTimeout(() => {
          history.push(TO_DROP_CHECKOUT(drop.id))
        }, 1000)
      }
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  })

  return drop ? (
    <>
      <Headers screenTitle={drop.title} />
      <main>
        <Container maxWidth='lg' className={classes.container}>
          <Grid container spacing={0}>
            <Grid item className={classes.mobileCloseContainer}>
              <SquareButton
                icon='close'
                onClick={onBack}
                className={classes.mobileCloseButton}
              />
            </Grid>
            {/*Left Row*/}
            <Grid item xs={12} md className={classes.leftContainer}>
              {/*Description box*/}
              <Box
                display='flex'
                flexDirection='column'
                className={classes.boxDescription}>
                <Typography variant='h2' className={classes.header}>
                  {i18n.t('authenticated.waitingRoom.waitingRoom')}
                </Typography>
                <Typography variant='body1' className={classes.description}>
                  {DESCRIPTION_TEXT}
                </Typography>
              </Box>
              <Box
                display='flex'
                className={classes.boxDropDetails}
                alignItems='center'>
                <DropImage src={drop?.image} figureClass={classes.dropFigure} />
                <Box className={classes.dropDescription}>
                  <Typography variant='h4'>{drop!.title}</Typography>
                  <Typography variant='body1' className={classes.description}>
                    {drop!.description}
                  </Typography>
                </Box>
              </Box>
              <Box display='flex' className={classes.boxEventDetails}>
                <Box
                  display='flex'
                  alignItems='center'
                  className={classes.boxEventDetailsText}>
                  {timeLeft ? (
                    <Box display='flex' className={classes.boxEventDetailsTime}>
                      <Box display='flex' alignItems='center'>
                        <IconRarum
                          icon='time'
                          size={24}
                          className={classes.waitingIcon}
                          color={red}
                        />
                        <Typography variant='body2'>
                          {i18n.t('authenticated.waitingRoom.eventStart')}
                        </Typography>
                      </Box>
                      <Typography variant='h2' className={classes.timer}>
                        {formattedTime(timeLeft)}
                      </Typography>
                    </Box>
                  ) : null}
                  <Typography variant='body2' className={classes.description}>
                    {i18n.t('authenticated.waitingRoom.lastUpdate')} <br />
                    {i18n.t('authenticated.waitingRoom.id')}
                  </Typography>
                </Box>
              </Box>
              <Box
                display='flex'
                alignItems='center'
                className={classes.boxExit}>
                <BackButton
                  icon='arrow'
                  text={i18n.t('authenticated.waitingRoom.exit')}
                  className={classes.exitButton}
                  onClick={onBack}
                />
              </Box>
            </Grid>
            {/*Right Row*/}
            <Grid item xs={12} md className={classes.rightContainer}>
              <SquareButton
                icon='close'
                onClick={onBack}
                className={classes.closeButton}
              />
              <Typography variant='h4' className={classes.mediaDescription}>
                Live name lorem ipsum dolor sit amet
              </Typography>
              <Media
                type={'livestream'}
                src={
                  'https://www.youtube.com/watch?v=q3L7XF1ZDxo&ab_channel=JovemNerd'
                }
              />
              <Box
                display='flex'
                alignItems='center'
                className={classes.boxExitMobile}>
                <BackButton
                  icon='arrow'
                  text={i18n.t('authenticated.waitingRoom.exit')}
                  className={classes.exitButton}
                  onClick={onBack}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </main>
    </>
  ) : (
    <div></div>
  )
}

export default WaitingRoomView
