import { DropBidListType, DropBidType, OfferBidType, OfferType } from "core/logic/drop/drop.types";
import { RarumUserProfile } from "core/logic/user/user.types";
import { CurrenciesEnum } from "core/types/CurrenciesEnum";
import Web3 from "web3";
import OnChainBase from "./OnChainBase";

type Unsubscribe = () => void;

/**
 * Interface for managing auction related operations
 */
export default abstract class IAuctionFacade extends OnChainBase {
    offerType: OfferType;
    constructor(offer: OfferType, web3ForRead: Web3, web3ForWrite: Web3) {
        super(web3ForRead, web3ForWrite);
        this.offerType = offer
    }

    protected calculateRange(min: number, currentBid: number | undefined, rates: {
        /** How many parts is considered an increment (100 for 1 part (1%)) (10 for 10 parts (10%)) (1 for 100 parts (100%)) */
        min: number,
        max: number
    }) {
        const _lastBid = currentBid || min;
        const _minIncrement = currentBid ? (_lastBid / rates.min) : 0
        return [
            Number(Math.trunc((_lastBid + _minIncrement) * 100) / 100),
            Number(Math.trunc((_lastBid + (_lastBid / rates.max)) * 100) / 100)
        ] as const;
    }

    abstract watchBids(onBids: (bidList: DropBidListType) => void): Unsubscribe;

    /**
     * 
     * @param drop The drop type (at the time of this code write) only matters for the offchain auctions. Each item can be auctioned individually.
     * @returns The period as a tuple (begin, end)
     */
    abstract getAuctionPeriod(): Promise<[Date, Date] | undefined>;

    /**
     * This returns the information required for a bid to be made
     */
    abstract getInformation(bidList?: DropBidType[]): Promise<{ currency: CurrenciesEnum, getBidRange: (latestBid?: DropBidType) => readonly [number, number], getBidPercentRange: () => readonly [number, number] }>

    /**
     * This persists the bid on the related chain
     */
    abstract createBid(amount: number, phoneNumber: string): Promise<void>

    /**
     * The balance the user is gonna use to bid
     */
    abstract getBalance(): Promise<number>

    /**
     * Returns if the bid belongs to the current user
     */
    abstract isBidOwned(user: RarumUserProfile | undefined, bid: Pick<OfferBidType, "user">): boolean
}