import { Box, Card, CardActions, CardContent, Divider, Fade } from '@material-ui/core'
import Backdrop from '@material-ui/core/Backdrop'
import Modal, { ModalProps } from '@material-ui/core/Modal'
import Typography from '@material-ui/core/Typography'
import SquareButton from 'components/SquareButton'
import React, { ReactNode, useState } from 'react'
import useStyles from './InfoModal.style'

export interface InfoModalViewProps {
  open: boolean
  handleClose: () => void
  title?: string
  description?: string
  content: ReactNode
  actions?: ReactNode
  disableBackdropClick?: boolean
  disableTopCloseButton?: boolean
}
const InfoModalView: React.FC<InfoModalViewProps> = ({
  open,
  handleClose,
  title,
  description,
  content,
  actions,
  disableBackdropClick,
  disableTopCloseButton = false,
}) => {
  const classes = useStyles()
  const [closing, setClosing] = useState(false)

  const handleFadeOut = () => {
    setClosing(true)
    handleClose()
  }
  const handleFadeOutEnding = () => {
    setClosing(false)
  }

  const closeHandler: ModalProps['onClose'] = (_, reason) => {
    if (disableBackdropClick && reason === 'backdropClick') return
    handleFadeOut()
  }

  // FIXME: Modal backdrop is not transitioning out like in https://material-ui.com/pt/components/modal/#transitions
  return (
    <Modal
      open={open || closing}
      onClose={closeHandler}
      aria-labelledby={title}
      aria-describedby={description}
      className={classes.modal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <div className={classes.paper}>
        <Fade in={open} exit onExited={handleFadeOutEnding}>
          <Card className={classes.card}>
            <Box className={classes.closeButton}>{!disableTopCloseButton && <SquareButton icon='close' onClick={handleFadeOut} />}</Box>
            <CardContent className={classes.modalContent}>
              <Typography variant='h4' component='h3' className={classes.title}>
                {title}
              </Typography>
              {content}
            </CardContent>
            {actions ? (
              <>
                <Divider className={classes.divider} />
                <CardActions className={classes.modalActions}>{actions}</CardActions>
              </>
            ) : null}
          </Card>
        </Fade>
      </div>
    </Modal>
  )
}

export default InfoModalView
