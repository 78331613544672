import { Box } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { t } from 'translate/i18n'
import useStyles from './Header.style'
import { Decoration } from './Header.template'

export default function Header() {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <Container>
        <div>
          <Typography variant='h2'>
            {t('marketplace.banner.header.title')}
          </Typography>
          <Typography variant='body1'>
            {t('marketplace.banner.header.description')}
          </Typography>
        </div>
        <Decoration />
      </Container>
    </Box>
  )
}
