import React, {
  ElementRef,
  Fragment,
  ReactElement,
  useMemo,
  useRef,
} from 'react'
import {
  KYCActionWrapperProps,
  KYCRequiredStatus,
} from './KYCActionWrapper.types'
import { useUser } from 'core/logic/user'
import { RarumUserProfile } from 'core/logic/user/user.types'
import KYC from 'components/KYC'
import { METAMAP_CONFIG } from 'core/constants'
import { t } from 'translate/i18n'
import Loader from '@onepercentio/one-ui/dist/components/Loader'
import ActionButton from 'components/ActionButton'
import { KYCRequiredDataTestIDs } from './KYCActionWrapper.e2e'
import AdaptiveContainer from '@onepercentio/one-ui/dist/components/AdaptiveContainer'

const REJECTION_STATUSES = [
  'reviewNeeded',
  'rejected',
  'error_on_blockchain',
  'inactive',
] as RarumUserProfile['kycStatus'][]
const PENDING_STATUSES = [
  'awaiting_confirmation',
  'awaiting_metamap',
  'verified',
  'verification_completed',
  'verification_updated',
  'verification_inputs_completed',
  'verification_started',
] as RarumUserProfile['kycStatus'][]

export default function KYCActionWrapperLogic(props: KYCActionWrapperProps) {
  const { profile, detail, isEmpty } = useUser()
  const kycRef = useRef<ElementRef<typeof KYC>>(null)

  const status = useMemo(() => {
    if (isEmpty)
      /** The user is not authenticated */
      return KYCRequiredStatus.AUTHENTICATE_REQUIRED;
    if (props.loading || !profile || !profile.isLoaded)
      // Missing some info
      return KYCRequiredStatus.LOADING

    if (!profile.kycStatus || profile.kycStatus === 'initiated')
      // The user has not completed KYC yet
      return KYCRequiredStatus.PENDING_KYC

    if (REJECTION_STATUSES.includes(profile.kycStatus))
      // The KYC has been rejected
      return KYCRequiredStatus.ERROR_KYC

    if (PENDING_STATUSES.includes(profile.kycStatus))
      // The KYC is pending
      return KYCRequiredStatus.LOADING_KYC

    return KYCRequiredStatus.KYC_OK
  }, [profile, props.loading, isEmpty])

  const action = useMemo(() => {
    switch (status) {
      case KYCRequiredStatus.PENDING_KYC:
        return () => kycRef.current!.element.click()
      default:
        return props.onAction
    }
  }, [status, props.onAction])

  const text = useMemo<ReactElement>(() => {
    if (props.error)
      return (
        <Fragment key={status + Date.now()}>{t(`generic.retryBtn`)}</Fragment>
      )
    else if (status === KYCRequiredStatus.LOADING)
      return <Loader key={status + Date.now()} />
    else if (status !== KYCRequiredStatus.KYC_OK)
      return (
        <Fragment key={status + Date.now()}>
          {t(`components.kycActionWrapper.${status}`)}
        </Fragment>
      )
    else return props.text
  }, [status, props.text, props.error])

  const disabled = useMemo(() => {
    switch (status) {
      case KYCRequiredStatus.LOADING:
      case KYCRequiredStatus.LOADING_KYC:
      case KYCRequiredStatus.ERROR_KYC:
        return true
      case KYCRequiredStatus.KYC_OK:
        return props.disabled
      default:
        return false
    }
  }, [status, props.disabled])

  const isLoading =
    useMemo(() => {
      return (
        [KYCRequiredStatus.LOADING, KYCRequiredStatus.LOADING_KYC].includes(
          status
        ) && !props.error
      )
    }, [status, props.error]) || props.loading

  return (
    <>
      <ActionButton
        data-testid={KYCRequiredDataTestIDs.BUTTON}
        disabled={disabled}
        text={<AdaptiveContainer>{text as any}</AdaptiveContainer>}
        showSpinner={isLoading}
        onClick={action}
      />
      {status === KYCRequiredStatus.PENDING_KYC && (
        <KYC
          ref={kycRef}
          config={METAMAP_CONFIG}
          extraInformation={{}}
          onCancel={() => {}}
          onFinish={() => {}}
          visible={false}
        />
      )}
    </>
  )
}
