import React from 'react'
import Styles from './Brand.module.scss'
import Container from 'openspace/components/Container/Container'
import Text from 'openspace/components/Text/Text'
import LabeledHR from 'openspace/components/LabeledHR/LabeledHR'
import { tO } from 'translate/i18n'
import brandBg from 'openspace/assets/png/bg-brand.png'

/**
 * Show our brand information
 **/
export default function Brand() {
  return (
    <section
      style={{ backgroundImage: `url(${brandBg})` }}
      className={`${Styles.root} dark`}>
      <Container>
        <Text code='home.sections.brand.title' type='title_v2' />
        <LabeledHR label={tO('home.sections.brand.description')} />
      </Container>
    </section>
  )
}
