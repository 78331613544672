import { MethodProps } from './props'
import { PixBRL } from './PixBRL'
import { CreditCard } from './CreditCard'
import { CryptoBRL } from './CryptoBRL'
import { PaymentMethods } from '../../Payment.types'

export const methodLabelComponent: {
  [k in PaymentMethods]: React.FC<MethodProps<k>>
} = {
  BOLETO_PIX: PixBRL,
  PIX: PixBRL,
  CREDIT_CARD: CreditCard,
  CRYPTO: CryptoBRL,
}
