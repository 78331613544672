import { makeStyles } from "@material-ui/core";

export default makeStyles((theme) => ({
    root: {
        minHeight: "100%",
        width: "100%",
        alignItems: "stretch",
        "& > *": {
            alignItems: "center",
            justifyContent: (props: any) => props.center ? "center" : "flex-start",
        }
    }
}))