import React, { useCallback } from 'react'
import View from './MyCollection.view'
import { MyCollectionLogicProps } from './MyCollection.types'
import { useHistory } from 'react-router-dom'
import {
  TO_COLLECTION_ITEM_DETAIL,
  TO_DROP_OPENING,
  TO_DROP_PENDING_PAYMENT,
  TO_GALLERY,
  TO_GALLERY_DETAIL,
} from 'core/modules/router'
import { useGalleries } from 'core/logic/gallery/gallery.hook'
import { useUpToDateMyCollectionItems } from 'context/MyCollection'
import { BaseAssetType } from 'core/logic/asset/asset.types'
import { useDeliveries } from 'core/logic/delivery/delivery.hook'
import { usePurchases } from 'core/logic/purchase/purchase.hook'
import { DeliveryType } from 'core/logic/delivery/delivery.types'
import { PendingPurchase } from 'core/logic/purchase/purchase.types'

export default function MyCollectionLogic(props: MyCollectionLogicProps) {
  const history = useHistory()
  const onClickAsset = useCallback(
    (asset: BaseAssetType | DeliveryType | PendingPurchase) => {
      if ('paymentStatus' in asset)
        history.push(TO_DROP_PENDING_PAYMENT(asset.id))
      else if ('type' in asset && asset.type === 'airdrop')
        history.push(TO_DROP_OPENING(asset.asset!.id))
      else if ('purchaseId' in asset) history.push(TO_DROP_OPENING(asset.id))
      else if (asset.galleryId)
        history.push(
          TO_GALLERY(asset.galleryId)(
            TO_COLLECTION_ITEM_DETAIL(asset.id, asset.tokenId)
          )
        )
      else history.push(TO_COLLECTION_ITEM_DETAIL(asset.id, asset.tokenId))
    },
    [history]
  )
  const { galleries } = useGalleries()
  const { ownedItems } = useUpToDateMyCollectionItems()
  const { deliveries } = useDeliveries()
  const { purchases: pending } = usePurchases()

  return (
    <View
      {...props}
      galleries={galleries}
      onOpenGallery={(g) => {
        history.push(TO_GALLERY_DETAIL(g.id))
      }}
      onClickAsset={onClickAsset}
      openedCollection={ownedItems}
      closedCollection={deliveries}
      pending={pending}
    />
  )
}
