import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import ActionButton from 'components/ActionButton'
import BackButton from 'components/BackButton'
import InfoModal from 'components/InfoModal'
import useStyles from '../Transfer.style'
import { i18n } from 'translate/i18n'
import { FinishTransferModalTestIds } from './FinishTransferModal.e2e'

const BLOCKCHAIN_TIME_LINK =
  'https://support.opensea.io/hc/en-us/articles/360063518233-Why-is-the-transaction-confirmation-time-taking-so-long-'

interface ActionsProps {
  onBack: () => void
  onTransfer: () => void
  loading: boolean
}

// Error modal actions area component
const Actions: React.FC<ActionsProps> = ({ onBack, onTransfer, loading }) => {
  const {
    actionsWrapper,
    loadingWrapper,
    spinner,
    cancelButton,
    actionButton,
  } = useStyles()
  return loading ? (
    <Box className={loadingWrapper}>
      <Box className={spinner}>
        <CircularProgress />
      </Box>
      <Typography variant='body2' component='p'>
        {i18n.t('authenticated.transfer.confirmation.warning')}
      </Typography>
    </Box>
  ) : (
    <Box className={actionsWrapper}>
      <ActionButton
        text={i18n.t('authenticated.transfer.confirmation.submit')}
        className={actionButton}
        onClick={onTransfer}
        data-testid={FinishTransferModalTestIds.TRANSFER}
      />
      <BackButton
        icon='close'
        text={i18n.t('authenticated.transfer.confirmation.cancel')}
        className={cancelButton}
        onClick={onBack}
      />
    </Box>
  )
}
interface FinishTransferModalProps {
  handleSubmit?: () => void
  isOpen: boolean
  handleClose: () => void
  loading: boolean
  transferDone: boolean
}

const FinishTransferModal: React.FC<FinishTransferModalProps> = ({
  handleSubmit,
  isOpen,
  handleClose,
  loading,
  transferDone,
}) => {
  const classes = useStyles()

  const content = (
    <>
      <Typography
        variant='body1'
        component='p'
        className={clsx(classes.formGutter, classes.smallSpacing)}>
        {i18n.t('authenticated.transfer.confirmation.description.part1')}
      </Typography>
      <Typography
        variant='body1'
        component='p'
        className={clsx(classes.formGutter, classes.smallSpacing)}>
        {i18n.t('authenticated.transfer.confirmation.description.part2')}
      </Typography>
      <Typography
        variant='body1'
        component='p'
        color='error'
        className={clsx(classes.formGutter, classes.smallSpacing)}>
        <strong>
          {i18n.t('authenticated.transfer.confirmation.description.part3')}
        </strong>
      </Typography>
    </>
  )

  const contentFinished = (
    <Box display='flex' flexDirection='column'>
      <Typography
        variant='body1'
        className={clsx(classes.formGutter, classes.extraSmallSpacing)}>
        {i18n.t('authenticated.transfer.finished.overview')}
      </Typography>
      <Typography
        variant='body1'
        className={clsx(classes.formGutter, classes.extraSmallSpacing)}>
        <strong>{i18n.t('authenticated.transfer.finished.attention')}</strong>
      </Typography>
      <Typography variant='body1' className={classes.formGutter}>
        <ul className={classes.extraInfo}>
          <li>
            {i18n.t('authenticated.transfer.finished.waitTime')}{' '}
            <a href={BLOCKCHAIN_TIME_LINK} target='_blank' rel='noreferrer'>
              {i18n.t('authenticated.transfer.finished.here')}
            </a>{' '}
            {i18n.t('authenticated.transfer.finished.forMore')}
          </li>
        </ul>
      </Typography>
    </Box>
  )

  if (handleSubmit) {
    return (
      <InfoModal
        title={
          transferDone
            ? i18n.t('authenticated.transfer.finished.finished')
            : i18n.t('authenticated.transfer.finished.submit')
        }
        open={isOpen}
        handleClose={handleClose}
        content={transferDone ? contentFinished : content}
        actions={
          !transferDone && (
            <Actions
              onBack={handleClose}
              onTransfer={handleSubmit}
              loading={loading}
            />
          )
        }
        disableBackdropClick
      />
    )
  }
  return null
}
export default FinishTransferModal
