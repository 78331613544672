import { REACT_APP_COINBASE_ORIGIN } from 'core/constants'
import { encodeURIParams } from 'core/helpers/coinbase'
import React, { useEffect } from 'react'
import { EventMessage, MessageData, SrcParams } from './Coinbase.types'
import { CoinbaseView } from './Coinbase.view'

const isMessageForUs = (message: EventMessage, uuid: string): boolean => {
  return (
    message.origin === REACT_APP_COINBASE_ORIGIN &&
    message.data.buttonId === uuid
  )
}

type CoinbaseLogicProps = {
  uuid: string
  purchaseCode: string
  onChargeSuccess?: (messageData: MessageData) => void
  onChargeFailure?: (messageData: MessageData) => void
  onPaymentDetected?: (messageData: MessageData) => void
  onError?: (messageData: MessageData) => void
  onModalClose?: () => void
}

export const Coinbase: React.FC<CoinbaseLogicProps> = ({
  uuid,
  purchaseCode,
  onChargeSuccess,
  onChargeFailure,
  onPaymentDetected,
  onError,
  onModalClose,
}) => {
  useEffect(() => {
    const handleMessage = (message: EventMessage) => {
      if (!isMessageForUs(message, uuid)) return
      switch (message.data.event) {
        case 'charge_confirmed':
          return onChargeSuccess?.(message.data)
        case 'charge_failed':
          return onChargeFailure?.(message.data)
        case 'payment_detected':
          return onPaymentDetected?.(message.data)
        case 'error_not_found':
          return onError?.(message.data)
        case 'checkout_modal_closed':
          return onModalClose?.()
        default:
          console.warn(
            `CoinbaseLogic::message for us, but not processed`,
            message.origin,
            message.data
          )
          return
      }
    }
    window.addEventListener('message', handleMessage)
    return () => window.removeEventListener('message', handleMessage)
  }, [
    onChargeFailure,
    onChargeSuccess,
    onError,
    onModalClose,
    onPaymentDetected,
    uuid,
  ])

  const params: SrcParams = {
    origin: `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? ':' + window.location.port : ''
    }/`,
    version: '',
    buttonId: uuid,
    cacheDisabled: true,
  }

  const src = `${REACT_APP_COINBASE_ORIGIN}/embed/charges/${encodeURI(
    purchaseCode
  )}?${encodeURIParams(params)}`

  return <CoinbaseView src={src} />
}
