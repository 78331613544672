import { Box, Chip, Divider, Grid, Typography } from '@material-ui/core'
import clsx from 'clsx'
import DropImage from 'components/DropImage'
import React, { PropsWithChildren, useMemo } from 'react'
import useStyles from './AssetItem.style'
import { MarketplaceInfoResumeView, OfferInfo } from './AssetItem.type'
import MarketplaceDetails from './MarketplaceDetails'
import { typographyColor } from './AssetItem.template'
import { t } from 'translate/i18n'
import { currencyNumberFormatter } from 'core/helpers/formatter'
import { KEEP_IMAGE_PROPORTIONS } from './AssetItem.data'

export type AssetItemViewProps = {
  itemName: string
  itemImage?: string
  itemAnimation?: string
  itemDescription?: string
  last: boolean
  id?: string
  onGoToGallery: () => void
  'data-testid'?: string
  className?: string
} & (
  | {
      marketplaceInfo: MarketplaceInfoResumeView
      onGoToMarketplace: () => void
    }
  | {
      offerInfo: OfferInfo
    }
  | {}
)

const AssetItemView: React.FC<AssetItemViewProps> = ({
  itemImage,
  itemAnimation,
  itemName,
  itemDescription,
  last,
  id,
  onGoToGallery,
  className,
  ...props
}) => {
  const classes = useStyles()

  const truncateString = (string: string) => {
    return string.replace(/(.{150})..+/, '$1...')
  }

  const marketplaceExtraProps =
    'marketplaceInfo' in props && props.marketplaceInfo ? props : undefined
  const offerInfo =
    'offerInfo' in props && props.offerInfo ? props.offerInfo : undefined
  const Wrapper = useMemo(() => {
    return marketplaceExtraProps?.marketplaceInfo
      ? ({ children }: PropsWithChildren<{}>) => (
          <MarketplaceDetails
            info={marketplaceExtraProps.marketplaceInfo}
            onGoToMarketplace={marketplaceExtraProps.onGoToMarketplace}>
            {children}
          </MarketplaceDetails>
        )
      : ({ children }: PropsWithChildren<{}>) => <>{children}</>
  }, [
    marketplaceExtraProps?.marketplaceInfo,
    marketplaceExtraProps?.onGoToMarketplace,
  ])

  return (
    <>
      <Box
        data-testid={props['data-testid']}
        display='flex'
        flexDirection='column'
        className={clsx(
          classes.container,
          id ? classes.pointer : null,
          !!marketplaceExtraProps ? classes.highlight : null,
          className
        )}
        onClick={onGoToGallery}>
        <Grid container item className={classes.imageContainer}>
          {!itemAnimation && (
            <DropImage
              src={itemImage}
              figureClass={classes.figure}
              imageClass={classes.image}
              alt={itemName}
              type='vertical'
              keepProportions={KEEP_IMAGE_PROPORTIONS}
            />
          )}
          {itemAnimation && (
            <video
              controls={false}
              poster={itemImage}
              autoPlay
              muted
              loop
              src={itemAnimation}
              className={classes.figure}
            />
          )}
        </Grid>
        <Wrapper>
          <Grid container item className={classes.descriptionContainer}>
            <Typography variant='h4' component='h3' color='textPrimary'>
              {itemName}
            </Typography>
            {itemDescription && (
              <Typography
                variant='body2'
                color={typographyColor}
                className={classes.description}>
                {truncateString(itemDescription)}
              </Typography>
            )}
          </Grid>
        </Wrapper>
        {!!offerInfo && (
          <>
            <Grid container item className={classes.descriptionContainer}>
              <Chip
                className={classes.chip}
                label={t(
                  offerInfo.supply !== 0
                    ? 'marketplace.unitsOnSale'
                    : 'marketplace.selectUnitsOnSale',
                  {
                    count: offerInfo.supply,
                  }
                )}
              />
              <Typography variant='body2'>
                {t('marketplace.salePrice')}
              </Typography>
              <Typography className={classes.big}>
                {offerInfo.currency}{' '}
                <b>{currencyNumberFormatter(offerInfo.price)}</b>
              </Typography>
            </Grid>
          </>
        )}
      </Box>
      {!last && <Divider className={classes.divider} />}
    </>
  )
}

export default AssetItemView
