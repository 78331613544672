import React, { Fragment } from 'react'
import View from './ChallengeBannerAlternator.view'
import { useHighlightedChallenges } from 'context/Challenge'
import ChallengeDetailsBannerView from '../ChallengeDetailsBanner/ChallengeDetailsBanner.view'
import UncontrolledTransition from '@onepercentio/one-ui/dist/components/UncontrolledTransition'
import { TransitionAnimationTypes } from '@onepercentio/one-ui/dist/components/Transition/Transition'
import useBreakpoint from '@onepercentio/one-ui/dist/hooks/ui/useBreakpoint'
import ChallengeDetailsStyles from '../ChallengeDetailsBanner/ChallengeDetailsBanner.module.scss'
import { GalleryType } from 'core/logic/gallery/gallery.types'

const breakInto = Number(
  ChallengeDetailsStyles.breakInto.replace(/[^0-9]+/g, '')
)
export default function ChallengeBannerAlternatorLogic({
  galleryId,
}: {
  galleryId: GalleryType['id'] | undefined
}) {
  const isSmall = useBreakpoint(breakInto)
  const { challenges, error, retry } = useHighlightedChallenges(galleryId)
  const transitionType = isSmall
    ? TransitionAnimationTypes.SLIDE
    : TransitionAnimationTypes.COIN_FLIP
  const baseTransitionType = isSmall
    ? TransitionAnimationTypes.FADE
    : TransitionAnimationTypes.COIN_FLIP
  return (
    <>
      <UncontrolledTransition transitionType={baseTransitionType}>
        {error ? (
          <ChallengeDetailsBannerView
            key='error'
            status='error'
            onAction={() => retry()}
          />
        ) : challenges === undefined ? (
          <ChallengeDetailsBannerView key='shimmer' status='loading' />
        ) : challenges.length === 0 ? (
          <Fragment key='empty' />
        ) : (
          <View
            key='list'
            challenges={challenges}
            transitionType={transitionType}
          />
        )}
      </UncontrolledTransition>
    </>
  )
}
