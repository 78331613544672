import React, { useMemo } from 'react'
import { ConnectorContext } from './wallet.types'
import useWeb3Provider from 'hooks/useWeb3Provider'

export const WalletConnectorContext = React.createContext<ConnectorContext>(
  null as any
)

export function WalletConnectorProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const { provider, web3 } = useWeb3Provider(undefined, true)
  const returnType = useMemo(
    () => ({
      web3: web3,
      provider: provider,
    }),
    [provider, web3]
  )

  return (
    <WalletConnectorContext.Provider value={returnType}>
      {children}
    </WalletConnectorContext.Provider>
  )
}

export function WalletConnectorBoundary({
  children,
  fallback,
}: {
  fallback?: React.ReactNode
  children: React.ReactNode
}) {
  const web3 = React.useContext(WalletConnectorContext)
  if (!web3) return <>{fallback}</>
  return <>{children}</>
}

export function useWalletConnnectorContext() {
  return React.useContext(WalletConnectorContext)
}
