import { Grid, Typography } from '@material-ui/core'
import { RARITIES } from 'core/constants'
import { AssetType } from 'core/logic/asset/asset.types'
import { useMemo } from 'react'
import { t, tDefault } from 'translate/i18n'
import { AssetFilterTestId } from './AssetFilters.e2e'
import {
  Filter,
  MarketplaceAssetFilter,
  MarketplaceFilter,
  MarketplaceOrderFilter,
  OptionsShape,
  TraitType,
} from './AssetFilters.types'
import useStyles from './AssetFilters.styles'
import { ToggleButton } from '@material-ui/lab'
import CurrencyLabel from 'components/CurrencyLabel/CurrencyLabel.view'
import Tabs from '@onepercentio/one-ui/dist/components/Tabs'
import Button from '@onepercentio/one-ui/dist/components/Button'
import Select from '@onepercentio/one-ui/dist/components/Select/Select'
import { AssetListTestId } from 'pages/Unauthenticated/Asset/AssetList.e2e'

export default function AssetFilters({
  origin,
  allAvailableAssets,
  onRarityFilter,
  onTraitFilter,
  onNoFilter,
  counters,
  ...otherProps
}: {
  origin: 'myCollection' | 'marketplace'
  allAvailableAssets: AssetType[]
  onRarityFilter: (filter: string) => void
  onTraitFilter: (key: string, _value: string | unknown) => void
  onNoFilter: () => void
  counters: {
    [k in MarketplaceAssetFilter]: number
  }
} & (
  | {
      marketplace: true
      filter: MarketplaceFilter
      onCurrencyFilter: (currency: string) => void
      onAssetFilter: (filter: MarketplaceAssetFilter) => void
      onOrderFilter: (order: MarketplaceOrderFilter) => void
      currencies: string[]
    }
  | {
      marketplace?: false
      filter: Filter
    }
)) {
  const classes = useStyles()
  const marketplace = otherProps.marketplace
  const filter = otherProps.filter
  const { rarity: rarityFilter, trait: traitFilter } = filter
  const rarityFilters = useMemo(() => {
    return RARITIES.filter((rarity: string) =>
      allAvailableAssets.find((asset: AssetType) => asset.class === rarity)
    )
  }, [allAvailableAssets])

  const RarityFilterButtons = useMemo(() => {
    if (rarityFilters.length === 0) return null
    if (!marketplace)
      return rarityFilters
        .map((_filter) => (
          <Button
            className={filter.rarity === _filter ? 'active' : ''}
            variant='transparent'
            onClick={() => onRarityFilter(_filter)}>
            {t(`unauthenticated.galleryCards.filter.${_filter}`)}
          </Button>
        ))
        .concat(<>&nbsp;&nbsp;&nbsp;</>)
    else
      return (
        <Select
          label={t('marketplace.filters.rarity.label')}
          onClick={({ value: rarity }) => onRarityFilter(rarity)}
          data-testid={`${AssetFilterTestId.DROPDOWN_SELECT_PREFIX}${t(
            'marketplace.filters.rarity.label'
          )}`}
          items={rarityFilters.map((r) => ({
            label: t(`marketplace.filters.rarity.types.${r}`) as string,
            value: r,
          }))}
          selected={rarityFilter as (typeof rarityFilters)[number]}
        />
      )
  }, [marketplace, rarityFilter, rarityFilters, onRarityFilter])

  const traitFilters: { [key: string]: OptionsShape[] } = {}
  allAvailableAssets?.forEach((asset: AssetType) =>
    asset?.attributes.forEach((trait: TraitType) =>
      trait.trait_type in traitFilters
        ? !traitFilters[trait.trait_type].some(
            (opt) => opt.value === trait.value
          ) &&
          traitFilters[trait.trait_type].push({
            label: trait.value,
            value: trait.value,
          })
        : (traitFilters[trait.trait_type] = [
            { label: trait.value, value: trait.value },
          ])
    )
  )

  const TraitFilterButtons = Object.keys(traitFilters).map((key) => (
    <Select
      key={key}
      selected={traitFilter ? traitFilter[key] : undefined}
      label={tDefault(`unauthenticated.galleryCards.filter.traits.${key}`, key)}
      data-testid={`${AssetListTestId.DROPDOWN_SELECT_PREFIX}${key}`}
      onClick={({ value }) => onTraitFilter(key, value)}
      items={traitFilters[key]}
    />
  ))

  return (
    <Grid
      container
      data-testid={AssetFilterTestId.FILTERS_ROW}
      className={classes.filters}>
      {marketplace && (
        <div className={classes.row}>
          <AssetModeSelector
            mode={origin}
            onSelected={otherProps.onAssetFilter}
            selected={otherProps.filter.asset}
            counters={counters}
          />
          {origin === 'marketplace' && (
            <OrderSelector
              onSelected={otherProps.onOrderFilter}
              selected={otherProps.filter.order}
            />
          )}
        </div>
      )}
      <div className={classes.wrap}>
        {!marketplace && (RarityFilterButtons || TraitFilterButtons) ? (
          <Button
            className={filter.rarity === null ? 'active' : ''}
            variant='transparent'
            onClick={() => onNoFilter()}>
            {t('unauthenticated.galleryCards.filter.all')}
          </Button>
        ) : null}
        {RarityFilterButtons}
        {TraitFilterButtons}

        {marketplace && origin === 'marketplace' && (
          <CurrencySelector
            currencies={otherProps.currencies}
            selected={otherProps.filter.currency || ''}
            onSelected={otherProps.onCurrencyFilter}
          />
        )}
      </div>
      {marketplace && (
        <Typography
          data-testid={AssetFilterTestId.CLEAR_FILTERS_BTN}
          component={'a'}
          onClick={onNoFilter}>
          {t('marketplace.filters.clear')}
        </Typography>
      )}
    </Grid>
  )
}
function OrderSelector({
  selected,
  onSelected,
}: {
  selected: MarketplaceOrderFilter
  onSelected: (s: MarketplaceOrderFilter) => void
}) {
  return (
    <Select
      onClick={({ value }) => onSelected(value)}
      items={[
        {
          value: MarketplaceOrderFilter.PRICE_ASC,
          label: t('marketplace.filters.order.lowestToHighest') as string,
        },
        {
          value: MarketplaceOrderFilter.PRICE_DES,
          label: t('marketplace.filters.order.highestToLowest') as string,
        },
      ]}
      selected={selected}
    />
  )
}

function AssetModeSelector({
  mode,
  selected,
  onSelected,
  counters,
}: {
  mode: 'myCollection' | 'marketplace'
  selected: MarketplaceAssetFilter
  onSelected: (filter: MarketplaceAssetFilter) => void
  counters: {
    [k in MarketplaceAssetFilter]: number
  }
}) {
  const classes = useStyles()
  const prefix =
    mode === 'myCollection'
      ? 'authenticated.myCollection.filter'
      : 'marketplace.filters.assets'
  return (
    <Tabs
      onSelect={onSelected}
      selected={selected}
      itemClassName={classes.tab}
      options={[
        {
          id: MarketplaceAssetFilter.ALL,
          label: t(`${prefix}.showAll`, {
            amount: counters[MarketplaceAssetFilter.ALL],
          }),
        },
        {
          id: MarketplaceAssetFilter.ONLY_ON_SALE,
          label: t(`${prefix}.cardsOnSale`, {
            amount: counters[MarketplaceAssetFilter.ONLY_ON_SALE],
          }),
        },
      ]}
    />
  )
}

function CurrencySelector({
  currencies,
  onSelected,
  selected,
}: {
  currencies: string[]
  onSelected: (currency: string) => void
  selected: string
}) {
  const classes = useStyles()
  return (
    <>
      <div className={classes.pushToRight} />
      <div>
        <Typography>{t('marketplace.filters.currency')}</Typography>
        <div>
          {currencies.map((currency) => (
            <ToggleButton
              key={currency}
              classes={{
                root: classes.tag,
              }}
              selected={selected === currency}
              onClick={() => onSelected(currency)}>
              <CurrencyLabel currency={currency} />
            </ToggleButton>
          ))}
        </div>
      </div>
    </>
  )
}
