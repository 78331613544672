import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '& p': {
      marginLeft: '0px',
    },
    marginBottom: '2rem',
  },
  wrap: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  filters: {
    alignItems: 'stretch',
    paddingLeft: 20,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-around',
    },
    flexDirection: 'column',
    '& > *': {
      display: 'flex',
    },
    '& a': {
      textDecoration: 'underline',
      cursor: 'pointer',
      marginRight: 'auto',
    },
    '& input': {
      marginBottom: 'auto',
    },
  },
  tag: {
    marginRight: '10px',
    padding: '10px',
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: theme.palette.text.primary,
      color: theme.palette.background.default,
    },
  },
  pushToRight: {
    marginLeft: 'auto',
  },
  tab: {
    marginRight: '24px',
  },
}))
