export default {
  POLYGON: {
    unauthenticated: {
      faqview: {
        faq: {
          accordion: {
            content: {
              10: `{{name}} network is one of the most promising infrastructures for NFT nowadays with a large community of developers, companies and organizations dedicated to strengthen and mature the technology and its applications.`,
            }
          }
        }
      }
    }
  },
  MOONBEAM: {
    unauthenticated: {
      faqview: {
        faq: {
          accordion: {
            content: {
              10: `{{name}} is a Polkadot parachain with a focus on interoperability and EVM support. It is the largest parachain in terms of volume and transactions on Polkadot, which is why we chose it to launch this store.`,
            }
          }
        }
      }
    }
  },
} as typeof import('./chain.pt.ts').default;