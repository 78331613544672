import * as dfns from 'date-fns'
import { ptBR, enUS, es } from 'date-fns/locale'
import { i18n } from 'translate/i18n'

/**
 * Formatter for Brazilian Real currency
 */
export const currencyFormatter = (currency?: string) => {
  return currencyFormatterFactory(currency).format
}

/**
 * Instanciate an currency formatter
 */

export const currencyFormatterFactory = (currency?: string) => {
  try {
    return new Intl.NumberFormat(i18n.language, {
      style: 'currency',
      currency: currency,
    })
  } catch (e) {
    const f = new Intl.NumberFormat(i18n.language, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })

    return {
      format: (num: number) => `${currency} ${currencyNumberFormatter(num)}`,
      formatToParts: (num: number) => [
        { type: 'currency', value: currency },
        { type: 'literal', value: ' ' },
        ...f.formatToParts(num),
      ],
    }
  }
}

/**
 * Formatter date
 */
export const formatDate = (date: Date, locale = i18n.language) => {
  return Intl.DateTimeFormat(locale, {
    dateStyle: 'short',
    timeStyle: 'short',
  }).format(date)
}

/**
 * Formatter date
 */
export const formatDateOnly = (date: Date, locale = i18n.language) => {
  return Intl.DateTimeFormat(locale, {
    dateStyle: 'short',
  }).format(date)
}

export const parseDate = (formattedDate: string) => {
  const providedDate = formattedDate
  const dateParts = providedDate.split('/').map(Number)
  if (dateParts.length < 2) return false
  const [day, month, year] = dateParts
  const parsedDate = new Date(year, month - 1, day)

  return parsedDate
}

export const formatDistanceToNow = (date: Date) =>
  dfns.formatDistanceToNow(date, {
    locale: [ptBR, enUS, es].find((a) => i18n.language.startsWith(a.code!)),
    addSuffix: true,
  })

/**
 * Formatter for numbers in the Brazilian locale format
 */
export const numberFormatter = new Intl.NumberFormat('pt-BR').format

/**
 * A formatter to format to double decimal
 */
export const currencyNumberFormatter = new Intl.NumberFormat('pt-BR', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
}).format

/**
 * Formatter for durations in seconds
 */
export const formattedTime = (value: number) => {
  const hours = Math.floor(value / 3600) // get hours
  const minutes = Math.floor((value - hours * 3600) / 60) // get minutes
  const seconds = value - hours * 3600 - minutes * 60 //  get seconds
  let hour = hours.toString(),
    minute = minutes.toString(),
    second = seconds.toString()
  // add 0 if value < 10; Example: 2 => 02
  if (hours < 10) {
    hour = '0' + hours
  }
  if (minutes < 10) {
    minute = '0' + minutes
  }
  if (seconds < 10) {
    second = '0' + seconds
  }
  if (hours > 0) return `${hour}:${minute}:${second}`
  else return `${minute}:${second}`
}
