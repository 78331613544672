import { i18n } from 'translate/i18n'
import { Grid } from '@material-ui/core'
import React from 'react'
import useStyles from './Media.styles'

export type MediaTypes = 'livestream' | 'image'

export interface MediaViewProps {
  type: MediaTypes
  src: string
}

const MediaView: React.FC<MediaViewProps> = ({ type, src }) => {
  const classes = useStyles()

  const renderType = () => {
    switch (type) {
      case 'livestream':
        return <video controls src={src} className={classes.video} />
      case 'image':
        return (
          <img
            src={src}
            loading='lazy'
            title={i18n.t('authenticated.waitingRoom.media.waitingRoom')}
            alt={i18n.t('authenticated.waitingRoom.media.waitingRoom')}
            className={classes.image}
          />
        )
    }
  }

  return (
    <Grid container className={classes.container} spacing={0}>
      {renderType()}
    </Grid>
  )
}

export default MediaView
