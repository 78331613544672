import { ChainMetadata } from './wallet.types'

export const ERROR_MISSING_PROVIDER =
  'You need to install or run inside metamask'
export const ERROR_MISSING_CHAIN = 'Accept to add the new network on metamask'
export const ERROR_WRONG_CHAIN = 'Accept to switch network on metamask'
export const PERSIST_ADDRESS_KEY = '@rarum/metamask/address'

export const CHAINS_METADATA: Map<string, ChainMetadata> = new Map([
  [
    '0x89',
    {
      chainId: '0x89',
      chainName: 'MATIC Mainnet',
      nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
      rpcUrls: ['https://polygon-rpc.com'],
      blockExplorerUrls: ['https://polygonscan.com/'],
      iconUrls: ['future'],
    },
  ],
  [
    '0x13881',
    {
      chainId: '0x13881',
      chainName: 'MATIC Mumbai',
      nativeCurrency: { name: 'tMATIC', symbol: 'tMATIC', decimals: 18 },
      rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],
      blockExplorerUrls: ['https://polygonscan.com/'],
      iconUrls: ['future'],
    },
  ],
])
