import React, { useCallback, useMemo } from 'react'
import View from './TopBar.view'
import { AvatarMenuOptions, MenuOptions } from './TopBar.types'
import { useTenant } from 'core/logic/tenant/tenant.hook'
import { useUser } from 'core/logic/user'
import { useHistory, useLocation } from 'react-router-dom'
import ROUTES, {
  SIGN_IN,
  SIGN_UP,
  TO_CREATORS_SECTION,
  TO_FIREBASE,
  TO_MAINPAGE,
} from 'core/modules/router'
import { useAuthentication } from 'core/logic/authentication'
import { i18n } from 'translate/i18n'
import FeaturesStyles from 'openspace/pages/Authenticated/Home/v2/Home/Features/Features.module.scss'
import ContractModelsStyles from 'openspace/pages/Authenticated/Home/v2/Home/ContractModels/ContractModels.module.scss'

const routes: {
  [m in
    | Exclude<MenuOptions, MenuOptions.HOW_IT_WORKS>
    | 'home'
    | Exclude<AvatarMenuOptions, AvatarMenuOptions.LOGOUT>]: string
} = {
  [MenuOptions.FAQ]: ROUTES.unauthenticated.faq,
  [MenuOptions.ABOUT]: ROUTES.unauthenticated.faq,
  [MenuOptions.GALLERIES]: ROUTES.unauthenticated.allGalleries,
  [MenuOptions.MARKET]: ROUTES.unauthenticated.marketplace,
  [AvatarMenuOptions.COLLECTION]: ROUTES.authenticated.myCollection,
  [AvatarMenuOptions.PROFILE]: ROUTES.authenticated.userAccount,
  [MenuOptions.BRAND]: TO_CREATORS_SECTION('brand'),
  [MenuOptions.CREATORS]: TO_CREATORS_SECTION('creators'),
  home: ROUTES.unauthenticated.homepage,
}
export default function TopBarLogic() {
  const { tenant } = useTenant()
  const { logout, detail } = useUser()
  const history = useHistory()
  const {
    authentication: { authenticated },
  } = useAuthentication()
  const { pathname } = useLocation()
  const selectedMenuOption = useMemo(() => {
    return Object.entries(routes).find(
      ([_MenuOption, route]) => route === pathname
    )?.[0] as MenuOptions
  }, [pathname])
  const scrollTo = useCallback(
    (atRoute: string, scrollElClass: string) => {
      if (pathname !== atRoute) history.push(atRoute)
      setTimeout(() => {
        document
          .getElementsByClassName(scrollElClass)!
          .item(0)!
          .scrollIntoView({
            behavior: 'smooth',
          })
      }, 250)
    },
    [pathname]
  )

  return (
    <View
      tenant={tenant}
      onSignIn={() => {
        logout()
        history.push(TO_FIREBASE[SIGN_IN])
      }}
      onSignUp={() => {
        logout()
        history.push(TO_FIREBASE[SIGN_UP])
      }}
      onClickMenu={(opt) => {
        switch (opt) {
          case AvatarMenuOptions.LOGOUT:
            logout()
            break
          case MenuOptions.ABOUT:
            scrollTo(TO_MAINPAGE(), FeaturesStyles.root)
            break
          case MenuOptions.HOW_IT_WORKS:
            scrollTo(TO_MAINPAGE(), ContractModelsStyles.root)
            break
          case MenuOptions.BRAND:
          case MenuOptions.CREATORS:
            if (
              selectedMenuOption === MenuOptions.BRAND ||
              selectedMenuOption === MenuOptions.CREATORS
            )
              history.replace(routes[opt])
            else history.push(routes[opt])
            break
          default:
            history.push(routes[opt])
        }
      }}
      user={authenticated === null ? undefined : authenticated ? detail : null}
      currentOption={selectedMenuOption}
      onChangeLanguage={(lng) => i18n.changeLanguage(lng)}
    />
  )
}
