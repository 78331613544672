import { useTheme } from '@material-ui/core'
import { ReactComponent as AlertIcon } from 'assets/icons/alert.svg'
import { ReactComponent as AlertBallon } from 'assets/icons/alert-ballon.svg'
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg'
import { ReactComponent as CartIcon } from 'assets/icons/cart.svg'
import { ReactComponent as CheckmarkIcon } from 'assets/icons/checkmark.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg'
import { ReactComponent as EmailIcon } from 'assets/icons/email.svg'
import { ReactComponent as FacebookIcon } from 'assets/icons/facebook.svg'
import { ReactComponent as FavoriteIcon } from 'assets/icons/favorite.svg'
import { ReactComponent as GoogleIcon } from 'assets/icons/google.svg'
import { ReactComponent as GraduationIcon } from 'assets/icons/graduation.svg'
import { ReactComponent as HelpIcon } from 'assets/icons/help.svg'
import { ReactComponent as ListHorizontalIcon } from 'assets/icons/listHorizontal.svg'
import { ReactComponent as ListVerticallIcon } from 'assets/icons/listVertical.svg'
import { ReactComponent as MenuIcon } from 'assets/icons/menu.svg'
import { ReactComponent as MetamaskIcon } from 'assets/icons/metamask.svg'
import { ReactComponent as MomentIcon } from 'assets/icons/moment.svg'
import { ReactComponent as SecurityIcon } from 'assets/icons/security.svg'
import { ReactComponent as ShareIcon } from 'assets/icons/share.svg'
import { ReactComponent as TimeIcon } from 'assets/icons/time.svg'
import { ReactComponent as TransferIcon } from 'assets/icons/transfer.svg'
import { ReactComponent as UserIcon } from 'assets/icons/user.svg'
import { ReactComponent as FileIcon } from 'assets/icons/file.svg'
import { ReactComponent as wallet } from 'assets/icons/wallet.svg'
import { ReactComponent as chevronRight } from 'assets/icons/chevron_right.svg'
import { ReactComponent as card } from 'openspace/assets/svg/icons/card.svg'
import { ReactComponent as investment } from 'assets/icons/investment.svg'
import { ReactComponent as upload } from 'assets/icons/upload.svg'
import { ReactComponent as edit } from 'assets/icons/edit.svg'
import { ReactComponent as puzzle } from 'assets/icons/puzzle.svg'
import { ReactComponent as chevronDown } from 'assets/icons/chevron-down.svg'
import clsx from 'clsx'

export const mapIconTitleToIcon = {
  alert: AlertIcon,
  arrow: ArrowIcon,
  cart: CartIcon,
  close: CloseIcon,
  download: DownloadIcon,
  email: EmailIcon,
  facebook: FacebookIcon,
  favorite: FavoriteIcon,
  google: GoogleIcon,
  help: HelpIcon,
  listHorizontal: ListHorizontalIcon,
  listVertical: ListVerticallIcon,
  menu: MenuIcon,
  metamask: MetamaskIcon,
  moment: MomentIcon,
  security: SecurityIcon,
  share: ShareIcon,
  time: TimeIcon,
  transfer: TransferIcon,
  user: UserIcon,
  checkmark: CheckmarkIcon,
  graduation: GraduationIcon,
  alertBallon: AlertBallon,
  file: FileIcon,
  wallet,
  chevronRight,
  card,
  investment,
  upload,
  edit,
  puzzle,
  chevronDown,
}

export type IconRarumTitle = keyof typeof mapIconTitleToIcon

export interface IconRarumProps {
  icon: IconRarumTitle
  size?: number
  color?: string
  className?: string
}

/**
 * Rarum icons. To add a new Rarum icon: update IconRarumTitle and mapIconTitleToIcon.
 * @param {Object} props - Component props.
 * @property {IconRarumTitle} props.icon - The title of the icon.
 * @property {number} props.size - The size of the icon (always a square).
 * @property {string} props.className - Class names for the icon will be applied directly to the \<svg\> component.
 * @returns A Rarum icon.
 */
const IconRarum: React.FC<IconRarumProps> = ({
  icon: title,
  size,
  color,
  className: classNameProp,
}) => {
  const theme = useTheme()
  const IconComponent = mapIconTitleToIcon[title]

  return (
    <IconComponent
      className={clsx(classNameProp)}
      style={
        {
          height: size,
          width: size,
          '--default-svg-color': theme.palette.primary.main,
          '--svg-color': color,
        } as any
      }
    />
  )
}

export default IconRarum
