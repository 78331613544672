import React from 'react'
import { GalleriesViewProps } from './Galleries.types'
import Styles from './Galleries.module.scss'
import Container from 'openspace/components/Container/Container'
import Section from 'openspace/components/Section/Section'
import { HomeTestIds } from '../../../Home.e2e'
import { tO } from 'translate/i18n'
import GalleryCardLogic from 'openspace/components/GalleryCard/GalleryCard.logic'
import Text from 'openspace/components/Text/Text'
import LabeledHR from 'openspace/components/LabeledHR/LabeledHR'
import Spacing from '@onepercentio/one-ui/dist/components/Spacing/Spacing'

/**
 * List the galleries
 **/
export default function GalleriesView({
  onSeeAllGalleries,
  galleries,
}: GalleriesViewProps) {
  return (
    <section className={Styles.root}>
      <Container>
        <Text code='home.sections.gallery.heading' type='title_v2' />
        <LabeledHR label={tO('generic.brandMotto')} />
        <Spacing size='32' />
        <>
          <Section
            data-testid={HomeTestIds.GallerySection}
            title={tO('home.sections.gallery.title')}
            seeAll={{
              onClick: onSeeAllGalleries,
              label: tO('home.sections.gallery.action.seeAll'),
            }}
            src={galleries}
            jsx={{
              empty: null,
              loading: null,
              baseWidth: 320,
              factory: (b) => (
                <GalleryCardLogic
                  gallery={b}
                  shouldExpandOnMount={false}
                  mode={b.highlight ? 'highlight' : 'default'}
                />
              ),
            }}
          />
        </>
        <Spacing size='32' />
      </Container>
    </section>
  )
}
