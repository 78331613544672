import { Platform } from 'core/logic/authentication/auth.types'
import { IconRarumTitle } from 'components/IconRarum'
import { flags } from 'core/logic/features/flags.proxy'
import { FFLoginProviders } from 'core/logic/features/flags.types'

type Icon = {
  icon: IconRarumTitle
}

type AuthIcon = Icon & {
  label: string
  platform: Platform
}

export const authIconsProviders = new Map<FFLoginProviders, AuthIcon>([
  [
    'google',
    {
      icon: 'google',
      label: 'Google',
      platform: Platform.gmail,
    },
  ],
  [
    'facebook',
    {
      icon: 'facebook',
      label: 'Facebook',
      platform: Platform.facebook,
    },
  ],
  [
    'metamask',
    {
      icon: 'metamask',
      label: 'Metamask',
      platform: Platform.metamask,
    },
  ],
])

export const authIcons: Readonly<Array<AuthIcon>> = Object.freeze(
  flags.loginProviders.map((provider) => {
    const authIcon = authIconsProviders.get(provider)
    if (!authIcon) throw new Error('Check login providers feature flag')
    return authIcon
  })
)
