import { ComponentProps, Suspense, lazy } from 'react'
import SectionLoader from 'openspace/components/SectionLoader/SectionLoader'

const LazyCVM = lazy(() => import('./CVM88.logic'))
const LazyCVMStep = lazy(() =>
  import('./CVM88.logic').then(({ CVM88StepLogic }) => ({
    default: CVM88StepLogic,
  }))
)

export default function LazyCVM88(props: ComponentProps<typeof LazyCVM>) {
  return (
    <Suspense fallback={null}>
      <LazyCVM {...props} />
    </Suspense>
  )
}

export function CVM88Section(props: ComponentProps<typeof LazyCVMStep>) {
  return (
    <Suspense fallback={<SectionLoader />}>
      <LazyCVMStep {...props} />
    </Suspense>
  )
}
