import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: theme.palette.primary.dark,
      borderBottomColor: theme.palette.grey[200],
      borderBottomStyle: 'solid',
      borderBottomWidth: 2,
      minHeight: 12,
    },
  })
export default makeStylesWrapper(style)
