import { useAirdrop } from 'core/logic/airdrop/airdrop.hook'
import { DeliveryType } from 'core/logic/delivery/delivery.types'
import { doPublicClaim, doSignedClaim } from 'core/modules/firebase/service'
import { createContext, PropsWithChildren, useContext, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export enum ClaimFailReasons {
  /**
   * When the user claims a second time
   */
  ALREADY_CLAIMED,

  /**
   * Other exceptions
   */
  UNEXPECTED_ERROR,
}

export class ClaimException {
  code: ClaimFailReasons
  assetId?: DeliveryType['id']

  constructor(code: ClaimFailReasons, assetId?: string) {
    this.code = code
    this.assetId = assetId
  }

  static fromFirebaseError(error: { message: 'Already claimed' }) {
    switch (error.message) {
      case 'Already claimed':
        return new ClaimException(ClaimFailReasons.ALREADY_CLAIMED)
      default:
        return new ClaimException(ClaimFailReasons.UNEXPECTED_ERROR)
    }
  }
}

export type ClaimContextShape = {
  claim: () => Promise<DeliveryType['id']>
  data: {
    /** When the claim is public/limited, there is no assetId */
    assetId?: string
    timestamp: number
    airdropId: string
  }
  sign: string | null
  airdrop: ReturnType<typeof useAirdrop>
}
export const ClaimContext = createContext<ClaimContextShape>(null as any)

type PublicClaimShape = {
  shape: Pick<ClaimContextShape['data'], 'airdropId'>
}
type SignedClaimShape = {
  shape: ClaimContextShape['data']
  sign: string
  rawMessage: string
  signature: string
}

export function ClaimProvider({ children }: PropsWithChildren<{}>) {
  const { search } = useLocation()
  const parentClaim = useClaim() as ClaimContextShape | null
  const claimShape = useMemo<SignedClaimShape | PublicClaimShape | null>(() => {
    const ISearch = new URLSearchParams(search)
    const signObjB64 = ISearch.get('sign')
    const airdropId = ISearch.get('airdropId')
    if (!signObjB64 && !airdropId) return null
    if (airdropId) {
      return {
        shape: {
          airdropId,
        },
      }
    } else {
      const signObj = JSON.parse(atob(signObjB64!))
      return {
        shape: JSON.parse(signObj.message),
        sign: signObjB64,
        rawMessage: signObj.message,
        signature: signObj.signature,
      }
    }
  }, [])
  const publicShape = claimShape as PublicClaimShape
  const signedShape = claimShape as SignedClaimShape
  const airdrop = useAirdrop(claimShape?.shape.airdropId)

  return (
    <ClaimContext.Provider
      value={{
        claim: () => {
          if (airdrop.airdrop!.type === 'public')
            return doPublicClaim(publicShape!.shape.airdropId!)
          else
            return doSignedClaim(
              signedShape!.rawMessage,
              signedShape!.signature
            )
        },
        data: signedShape?.shape!,
        sign: signedShape?.sign || null,
        airdrop,
        ...(parentClaim || {}),
      }}>
      {children}
    </ClaimContext.Provider>
  )
}

export function useClaim() {
  return useContext(ClaimContext)
}
