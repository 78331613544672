import PlayerView from './Player.view'

export interface PlayerLogicProps {
  autoplay?: boolean
  poster?: string
  controls?: boolean
  muted?: boolean
  loop?: boolean
  src: string
  className?: any
}

const PlayerLogic: React.FC<PlayerLogicProps> = ({
  autoplay = false,
  controls = false,
  muted = false,
  loop = false,
  poster,
  src,
  className,
}) => {
  return (
    <PlayerView
      autoplay={autoplay}
      controls={controls}
      muted={muted}
      loop={loop}
      poster={poster}
      src={src}
      className={className}
    />
  )
}

export default PlayerLogic
