import AdaptiveContainer from '@onepercentio/one-ui/dist/components/AdaptiveContainer'
import Text from '@onepercentio/one-ui/dist/components/Text'
import { FigmaTypo } from 'containers/OneUIProvider/OneUIProvider'
import React, { Fragment } from 'react'
import Styles from './TogglableTags.module.scss'

/**
 * A list of tags to toggle between
 **/
export default function TogglableTags<T extends string>({
  tags,
  onClick,
}: {
  tags: {
    active: boolean
    id: T
    label: string
  }[]
  onClick: (t?: T) => void
}) {
  return (
    <div className={Styles.container}>
      {tags.map((a) => (
        <button onClick={() => onClick(!a.active ? a.id : undefined)}>
          <AdaptiveContainer direction='h'>
            {!a.active ? (
              <Fragment key='notactive' />
            ) : (
              <span key='active'>OK</span>
            )}
          </AdaptiveContainer>
          {<Text type={FigmaTypo.P14}>{a.label}</Text>}
        </button>
      ))}
    </div>
  )
}
