import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) =>
  createStyles({
    wrapper: {
      backgroundColor: theme.palette.error.main,
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
      display: 'flex',
      alignItems: 'center',
      padding: '7px 10px 7px 18px',
      animation: '$flickerAnimation 2s infinite',
    },
    text: {
      whiteSpace: 'nowrap',
      color: theme.palette.common.white,
    },
    '@keyframes flickerAnimation': {
      '0%': { backgroundColor: theme.palette.error.main },
      '50%': { backgroundColor: theme.palette.error.dark },
      '100%': { backgroundColor: theme.palette.error.main },
    },
  })

export default makeStylesWrapper(style)
