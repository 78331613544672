import { i18n } from 'translate/i18n'
import Typography from '@material-ui/core/Typography'
import useStyles from './NftInfo.style'
import { useChain } from 'context/Chain'
export interface NftInfoViewProps {
  contract?: string
  wallet?: string
  ipfs?: string
  userName?: string | null
}

const NftInfoView: React.FC<NftInfoViewProps> = ({
  contract,
  wallet,
  ipfs,
  userName,
}) => {
  const classes = useStyles()
  const chain = useChain()

  return (
    <section className={classes.section}>
      <Typography
        variant='body1'
        component='h2'
        color='textPrimary'
        className={classes.bottomSpacing}>
        <strong>
          {i18n.t(
            'authenticated.myCollection.collectionItemDetails.nftInfo.blockchainRecords'
          )}
        </strong>
      </Typography>
      {ipfs && (
        <>
          <Typography variant='body2' component='h3' color='textPrimary'>
            <b>
              {i18n.t(
                'authenticated.myCollection.collectionItemDetails.nftInfo.metadate'
              )}
            </b>
          </Typography>
          <Typography
            variant='body2'
            component='span'
            color='textPrimary'
            className={classes.bottomSpacing}>
            <a
              href={ipfs}
              target='_blank'
              rel='noreferrer'
              className={classes.ellipsis}>
              <small>{ipfs}</small>
            </a>
          </Typography>
        </>
      )}
      {contract && (
        <>
          <Typography variant='body2' component='h3' color='textPrimary'>
            <b>Smart Contract:</b>
          </Typography>
          <Typography
            variant='body2'
            component='span'
            color='textPrimary'
            className={classes.bottomSpacing}>
            <a
              href={`${chain?.explorerUrl}/${contract}`}
              target='_blank'
              rel='noreferrer'
              className={classes.ellipsis}>
              <small>{`${chain?.explorerUrl}/${contract}`}</small>
            </a>
          </Typography>
        </>
      )}
      {userName && wallet && (
        <>
          <Typography variant='body2' component='h3' color='textPrimary'>
            <b>
              {i18n.t(
                'authenticated.myCollection.collectionItemDetails.nftInfo.wallet',
                { name: userName }
              )}
            </b>
          </Typography>
          <Typography
            variant='body2'
            component='span'
            color='textPrimary'
            className={classes.bottomSpacing}>
            <a
              href={`${chain?.explorerUrl}/${wallet}`}
              target='_blank'
              rel='noreferrer'
              className={classes.ellipsis}>
              <small>{`${chain?.explorerUrl}/${wallet}`}</small>
            </a>
          </Typography>
        </>
      )}
    </section>
  )
}

export default NftInfoView
