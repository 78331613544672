import React, { useMemo } from 'react'
import Styles from './InvestmentWarning.module.scss'
import Card from 'components/Card/Card'
import Text from 'openspace/components/Text/Text'
import OneText from '@onepercentio/one-ui/dist/components/Text/Text'
import { FigmaTypo } from 'containers/OneUIProvider/OneUIProvider.data'
import { EscapedTranslation } from 'translate/i18n'

/**
 * Shows how much the user can invest based off it's earnings
 **/
export default function InvestmentWarning({
  option,
}: {
  option: TranslationCodesByTenant[keyof TranslationCodesByTenant]
}) {
  const title = useMemo<typeof option>(() => {
    switch (option) {
      case 'cvm88.form.sections.investorProfile.otherInvestments.options.0':
        return 'cvm88.form.sections.investorProfile.otherInvestments.result.caution'
      case 'cvm88.form.sections.investorProfile.otherInvestments.options.1':
        return 'cvm88.form.sections.investorProfile.otherInvestments.result.limited'
      case 'cvm88.form.sections.investorProfile.otherInvestments.options.2':
      default:
        return 'cvm88.form.sections.investorProfile.otherInvestments.result.unlimited'
    }
  }, [option])
  return (
    <Card className={Styles.root}>
      <Text code={title} type={FigmaTypo.H4} />
      <OneText type={FigmaTypo.P14}>
        <br />
        <EscapedTranslation code='cvm88.form.sections.investorProfile.otherInvestments.result.detail' />
      </OneText>
    </Card>
  )
}
