import { createStyles, Theme } from '@material-ui/core'
import makeStylesWrapper from 'core/helpers/styles'

const style = (theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      display: 'flex',
      width: '75%',
      maxWidth: 840,
      maxHeight: '100%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '100%',
      },
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      [theme.breakpoints.down('sm')]: {
        borderRadius: 0,
        margin: 0,
      },
    },
    closeButton: {
      minHeight: 40,
      minWidth: 40,
      alignSelf: 'flex-start',
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    title: {
      fontSize: '1.25rem',
      marginBottom: theme.spacing(4),
    },
    paragraph: {
      marginBottom: theme.spacing(4),
    },
    modalContent: {
      maxHeight: '100%',
      overflow: 'auto',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    modalActions: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
    divider: {
      backgroundColor: theme.palette.grey[200],
      alignSelf: 'stretch',
      height: 2,
    },
  })

export default makeStylesWrapper(style)
